import "./App.css";
import "animate.css";
import GeneralMenuLeft from "./view/components/GeneralMenuLeft";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useLocation,
} from "react-router-dom";
import NotFound from "./view/NotFound";
import Profile from "./view/Profile";
import Home from "./view/Home";
import Topics from "./view/Topics";
import Jobs from "./view/Jobs";
import Discover from "./view/Discover";
import Communities from "./view/Communities";
import Bookmarks from "./view/Bookmarks";
import Professional from "./view/Professional";
import Settings from "./view/Settings";
import GetHelp from "./view/GetHelp";
import SendFeedback from "./view/SendFeedback";
import Vibe from "./view/Vibe";
import Notifications from "./view/Notifications";
import Messages from "./view/Messages";
import CreatePost from "./view/CreatePost";
import { useEffect, useState } from "react";
import Auth from "./view/Auth";
import MessagesMinPopup from "./view/Chat/MessagesMinPopup";
import Page from "./view/Page";
import Apps from "./Apps/Apps";
import Test from "./view/Test";
import CreatePage from "./view/CreatePage";
import CreateHub from "./view/CreateHub";
import Admin from "./view/Admin";
import HubAdmin from "./view/HubAdmin";
import MobileFooterMenu from "./view/components/MobileFooterMenu";
import app from "./config/app";
import Cookies from "js-cookie";
import CommunitiesTaxonimy from "./view/CommunitiesTaxonimy";
import PagesTaxonimy from "./view/PagesTaxonimy";
import { getSearchResults, socket } from "./db";
import Joyride from "react-joyride";

// also check if its a bot or a browser accessing the pages, if social bot, then proxy data from https://web.vibein.africa
// socket.on("newUpdateData", (data) => {
//   console.log(data);
//   alert("New data detected");
// });

function MainLoader() {
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [activeSearchType, setActiveSearchType] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [localUpdate, setLocalUpdate] = useState(null); // State to hold update data
  const [return_data, set_return_data] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isOnline, setIsOnline] = useState(true);

  const [steps] = useState([
    {
      target: ".left_list_cont .professional",
      content: "Finish setting up your professional profile",
    },
  ]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "localUpdate") {
        setLocalUpdate(JSON.parse(event.newValue));
      }
    };

    // Add the storage event listener to detect changes from other tabs
    window.addEventListener("storage", handleStorageChange);

    const checkCookieChange = () => {
      const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});

      const specificCookie = cookies["enabledDarkmode"];
      if (specificCookie !== localUpdate) {
        setLocalUpdate(specificCookie);
      }
      // also check internet connection
      const isOnlineStatus = window.navigator.onLine;
      setIsOnline(isOnlineStatus);
    };

    // Check the cookie every 1 second
    const cookieCheckInterval = setInterval(checkCookieChange, 1000);

    // Cleanup on component unmount or re-render

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(cookieCheckInterval);
    };
  }, [localUpdate]); // Add localUpdate as a dependency to re-run on its change

  // Function to manually update localStorage and trigger update in the current tab
  const updateLocalStorage = (newValue) => {
    localStorage.setItem("localUpdate", JSON.stringify(newValue));
    setLocalUpdate(newValue); // Trigger update manually in the same tab
  };

  useEffect(() => {
    if (localUpdate) {
      // console.log("Update data received:", localUpdate);
      // Handle update data (e.g., update state, refresh UI, etc.)
    }

    const darkModeSetting = Cookies.get("enabledDarkmode") === "true";
    if (darkModeSetting) {
      document.documentElement.classList.add("dark_mode");
      document.documentElement.classList.remove("light_mode");
    } else {
      document.documentElement.classList.add("light_mode");
      document.documentElement.classList.remove("dark_mode");
    }
  }, [localUpdate]);

  const searchFilterType = [
    {
      id: 1,
      title: "All",
      type: "all",
    },
    {
      id: 2,
      title: "Vibes",
      type: "vibe",
    },
    {
      id: 3,
      title: "Hubs",
      type: "hub",
    },
    {
      id: 4,
      title: "People",
      type: "user",
    },
    {
      id: 5,
      title: "Businesses",
      type: "page",
    },
    {
      id: 6,
      title: "Agencies",
      type: "agency",
    },
    {
      id: 7,
      title: "Schools",
      type: "school",
    },
    {
      id: 8,
      title: "Topics",
      type: "hashtag",
    },
    {
      id: 9,
      title: "Jobs",
      type: "job",
    },
  ];

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value);
    const res = await getSearchResults(value, activeSearchType);
    console.log("Search results", res);
    setSearchResult(res.data);
  };

  const searchList = (item) => (
    <Link
      to={item.route}
      onClick={() => setShowSearchResults(false)}
      key={item.id}
      className="py-2 px-2 flex align-center single_result rounded-xl hover"
    >
      <div className="min-w-10 min-h-10 bg-gray-100 rounded-full relative flex align-center justify-center">
        {item.thumb != "" ? (
          <img
            src={item.thumb}
            className="min-w-10 min-h-10 max-h-10 max-w-10 object-cover rounded-full"
            width={20}
            height={20}
          />
        ) : (
          <span className="material-icons-round m-auto">search</span>
        )}
      </div>
      <div className="w-full rounded-full px-3">
        <div className="">{item.title}</div>
        <div className="text-sm">{item.desc}</div>
      </div>
      <div className="w-auto flex align-center h-6 px-3 bg-gray-200 rounded-full text-sm">
        {item.type}
      </div>
    </Link>
  );

  return (
    <Router>
      <div className="main_app">
        <div className="min-h-screen flex left_cont">
          <div className="px-4 flex align-center headers justify-between header_logo border-none shadow-none">
            <Link
              to={"/"}
              className="h-10 w-full mr-3 rounded-full flex align-center brand_logo_cont"
            >
              <img src="/images/logo_black.png" className="brand_logo black" />
              <img src="/images/logo_white.png" className="brand_logo white" />
              <img src="/images/icon.png" className="brand_icon" />
            </Link>
            <div
              onClick={() => setShowSearchResults(true)}
              className="h-10 w-10 min-w-10 min-h-10 flex hover align-center justify-center rounded-full relative bg-gray-100"
            >
              <span className="material-icons-round">search</span>
            </div>
          </div>
          <GeneralMenuLeft local={localUpdate} />
        </div>
        {showSearchResults ? (
          <>
            <div className="absolute search_results_container px-0  py-3 bg-white">
              <div className="px-2 flex align-center headers header_logo gap-2">
                <div className="h-10 w-10 rounded-full flex align-center">
                  <img
                    src="/images/icon.png"
                    className="brand_logo object-cover"
                  />
                </div>
                <div
                  className="h-10 w-full rounded-full relative search_cont"
                  style={{ marginLeft: "0px !important" }}
                >
                  <span className="material-icons-round absolute left-3 top-3 m-auto z-10">
                    search
                  </span>
                  <input
                    className="h-10 w-full rounded-full px-5 pl-10"
                    onChange={handleSearch}
                    placeholder="Search VibeIn"
                    onFocus={() => setShowSearchResults(true)}
                  />
                </div>
                <div
                  className="w-10 min-w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center hover"
                  onClick={() => setShowSearchResults(false)}
                >
                  <span className="material-icons-round absolute right-3 top-3 m-auto z-10">
                    close
                  </span>
                </div>
              </div>

              <div className="flex align-center w-full px-2 gap-1 search_type_cont justify-center headers">
                {searchFilterType.map((item, index) => (
                  <div
                    key={item.id}
                    onClick={() => setActiveSearchType(item.type)}
                    className={
                      activeSearchType == item.type ? "active_tab" : ""
                    }
                  >
                    <div className="h-9 px-2 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
                      {item.title}
                    </div>
                  </div>
                ))}
              </div>
              <hr className="line" style={{ marginTop: -3 }} />
              <div className="w-full search_results_container_inn px-2">
                {searchResult.length > 0 && (
                  <>
                    <div className="title w-full px-2 my-3">Top results</div>
                    {searchResult.length > 0 &&
                      searchResult.map((item, index) => (
                        <>
                          {activeSearchType == "all"
                            ? searchList(item)
                            : item.type == activeSearchType
                            ? searchList(item)
                            : ""}
                        </>
                      ))}
                  </>
                )}

                {searchResult.length == 0 && (
                  <div className="text-center py-28">
                    <div className="flex align-center justify-center w-full">
                      <img
                        src="/images/search.gif"
                        className="w-20 h-20 m-auto"
                      />
                    </div>
                    <div>Search for something</div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="search_results_container_backdrop"
              onClick={() => setShowSearchResults(false)}
            ></div>
          </>
        ) : (
          ""
        )}

        {/* <MessagesMinPopup /> */}

        {/* the rest of the pages loads below */}

        <Routes>
          <Route path="/" element={<Home local={localUpdate} />} />
          <Route path="/Home" element={<Home local={localUpdate} />} />
          <Route path="/Home/:name" element={<Home local={localUpdate} />} />
          <Route
            path="/Home/vibe/:vibeid"
            element={<Home local={localUpdate} />}
          />

          <Route path="/Feeds" element={<Home local={localUpdate} />} />
          <Route path="/Feeds/:name" element={<Home local={localUpdate} />} />

          <Route
            path="/Profile/:username"
            element={<Profile local={localUpdate} />}
          />
          <Route
            path="/Profile/:username/:path"
            element={<Profile local={localUpdate} />}
          />

          <Route
            path="/Profile/:username/:path/:dataid"
            element={<Profile local={localUpdate} />}
          />

          <Route path="/Profile" element={<Profile local={localUpdate} />} />
          <Route path="/Topics" element={<Topics local={localUpdate} />} />
          <Route
            path="/Topics/:tagname"
            element={<Topics local={localUpdate} />}
          />
          <Route
            path="/Topics/:tagname/:vibeid"
            element={<Topics local={localUpdate} />}
          />
          <Route path="/Jobs" element={<Jobs local={localUpdate} />} />
          <Route path="/Jobs/:id" element={<Jobs local={localUpdate} />} />
          <Route
            path="/Jobs/:id/:focus"
            element={<Jobs local={localUpdate} />}
          />
          <Route
            path="/Jobs/:id:/:focus/:tab"
            element={<Jobs local={localUpdate} />}
          />
          <Route path="/Discover" element={<Discover local={localUpdate} />} />
          <Route
            path="/Discover/:name"
            element={<Discover local={localUpdate} />}
          />
          <Route
            path="/Communities"
            element={<Communities local={localUpdate} />}
          />
          <Route
            path="/Communities/:username"
            element={<Communities local={localUpdate} />}
          />
          <Route
            path="/Communities/:username/:focus"
            element={<Communities local={localUpdate} />}
          />

          <Route
            path="/Communities/:username/:focus/:focusid"
            element={<Communities local={localUpdate} />}
          />

          <Route
            path="/CommunitiesTaxonimy/:type"
            element={<CommunitiesTaxonimy local={localUpdate} />}
          />

          <Route
            path="/Bookmarks"
            element={<Bookmarks local={localUpdate} />}
          />
          <Route
            path="/Bookmarks/:name"
            element={<Bookmarks local={localUpdate} />}
          />

          <Route
            path="/Bookmarks/:name/:dataid"
            element={<Bookmarks local={localUpdate} />}
          />

          <Route
            path="/PagesTaxonimy"
            element={<PagesTaxonimy local={localUpdate} />}
          />
          <Route
            path="/PagesTaxonimy/:name"
            element={<PagesTaxonimy local={localUpdate} />}
          />
          <Route
            path="/Professional"
            element={<Professional local={localUpdate} />}
          />
          <Route
            path="/Professional/:name"
            element={<Professional local={localUpdate} />}
          />
          <Route
            path="/Professional/:name/:modal"
            element={<Professional local={localUpdate} />}
          />
          <Route path="/Settings" element={<Settings local={localUpdate} />} />
          <Route
            path="/Settings/:name"
            element={<Settings local={localUpdate} />}
          />
          <Route path="/GetHelp" element={<GetHelp local={localUpdate} />} />
          <Route
            path="/SendFeedback"
            element={<SendFeedback local={localUpdate} />}
          />
          <Route
            path="/Notifications"
            element={<Notifications local={localUpdate} />}
          />
          <Route
            path="/Notifications/:name"
            element={<Notifications local={localUpdate} />}
          />
          <Route path="/Messages" element={<Messages local={localUpdate} />} />
          <Route
            path="/Messages/:chatid"
            element={<Messages local={localUpdate} />}
          />
          <Route
            path="/Messages/:chatid/:activity"
            element={<Messages local={localUpdate} />}
          />
          <Route
            path="/Messages/:chatid/:activity/:focus"
            element={<Messages />}
          />
          <Route
            path="/Messages/:chatid/:activity/:focus/:tab"
            element={<Messages />}
          />
          <Route
            path="/CreatePost"
            element={<CreatePost local={localUpdate} />}
          />
          <Route
            path="/CreatePost/:name"
            element={<CreatePost local={localUpdate} />}
          />
          <Route path="/Page" element={<Page local={localUpdate} />} />
          <Route
            path="/Page/:username"
            element={<Page local={localUpdate} />}
          />
          <Route
            path="/Page/:username/:focus"
            element={<Page local={localUpdate} />}
          />
          <Route
            path="/Page/:username/:focus/:dataid"
            element={<Page local={localUpdate} />}
          />
          <Route path="/Test" element={<Test local={localUpdate} />} />

          {/* auth */}
          <Route path="/Auth" element={<Auth local={localUpdate} />} />
          <Route path="/Auth/:name" element={<Auth local={localUpdate} />} />

          {/* singles */}
          <Route path="/Vibe/:id" element={<Vibe local={localUpdate} />} />
          <Route path="/Vibe" element={<Vibe local={localUpdate} />} />

          {/* vibein apps */}
          <Route path="/apps" element={<Apps local={localUpdate} />} />
          <Route path="/apps/:name" element={<Apps local={localUpdate} />} />

          {/* page manager */}
          <Route
            path="/CreatePage"
            element={<CreatePage local={localUpdate} />}
          />
          <Route path="/Admin" element={<Admin local={localUpdate} />} />
          <Route path="/Admin/:id" element={<Admin local={localUpdate} />} />
          <Route
            path="/Admin/:id/:page"
            element={<Admin local={localUpdate} />}
          />
          <Route
            path="/Admin/:id/:page/:focus"
            element={<Admin local={localUpdate} />}
          />
          <Route
            path="/Admin/:id/:page/:focus/:focus_id"
            element={<Admin local={localUpdate} />}
          />

          {/* hub/communities manager */}
          <Route
            path="/CreateHub"
            element={<CreateHub local={localUpdate} />}
          />
          <Route path="/HubAdmin" element={<HubAdmin local={localUpdate} />} />
          <Route
            path="/HubAdmin/:id"
            element={<HubAdmin local={localUpdate} />}
          />
          <Route
            path="/HubAdmin/:id/:page"
            element={<HubAdmin local={localUpdate} />}
          />
          <Route
            path="/HubAdmin/:id/:page/:focus"
            element={<HubAdmin local={localUpdate} />}
          />

          {/* extras */}
          <Route path="*" element={<NotFound local={localUpdate} />} />
        </Routes>

        {/* <Joyride
          steps={steps}
          // continuous
          // scrollToFirstStep
          // showProgress
          // showSkipButton
        /> */}
        {/* mobile footer menu */}

        {window.innerWidth < app.mobile_width && <MobileFooterMenu />}
        {window.innerWidth > app.mobile_width && !isOnline && (
          <div className="fixed bottom-0 left-0 right-0 h-auto py-0.5 offline_indicator flex align-center justify-center z-10 text-sm">
            Seems like you are offline.
          </div>
        )}
      </div>
    </Router>
  );
}

export default MainLoader;
