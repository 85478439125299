import { ArrowRightIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import TableDummy from "../components/Dummies/TableDummy";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
// alerts
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import app from "../../config/app";
import LoaderBar from "../components/LoaderBar";
import { getHubFollowers } from "../../db";
const MySwal = withReactContent(Swal);

function Followers({ local }) {
  const navigate = useNavigate();
  let { id, page, focus } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const [followers, setFollowers] = useState([]);
  const [filteredFollowers, setFilteredFollowers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  useEffect(() => {
    filterFollowers();
  }, [searchTerm, followers]);

  const fetchData = async () => {
    try {
      const list = await getHubFollowers(id);
      console.log("followers - > ", list);
      setFollowers(list.followers);
      setLoading(false);
    } catch (error) {
      console.error("followers:", error);
    }
  };

  const filterFollowers = () => {
    const filtered = followers.filter((follower) =>
      follower.user_profile[0].fullname
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredFollowers(filtered);
  };

  return (
    <div>
      {loading ? <LoaderBar /> : ""}
      <div className="flex align-center justify-between py-3">
        <div className="text-xl font-semibold py-3">Followers</div>
      </div>

      <div className="w-full">
        <div className="overflow-hidden w-full bg-gray-100 rounded-xl py-5 pt-2">
          {/* all settings goes here */}
          <div className="applicants">
            <div className="table-content">
              <div className="wrap-applicants table-responsive">
                {!loading && followers.length === 0 && (
                  <div className="mt-2 mb-2 px-4">
                    <div className="flex align-center">
                      <img
                        src="/images/beam-woman-meditating-in-lotus-position.gif"
                        className="w-36 max-w-36 min-w-36 h-auto sm_image"
                        alt="No followers yet"
                      />
                      <div className="px-3">
                        <div className="font-bold text-lg">No follower</div>
                        <div>
                          When people follow your community, you will see them
                          here.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex align-center flex-wrap gap-5 rounded-full my-4 px-4">
                  <div className="search_table flex-1 w-full relative">
                    <span className="material-icons-round absolute top-3 left-4 m-auto h-auto">
                      search
                    </span>
                    <input
                      placeholder="Search followers"
                      className="has_icon form-control search_input w-full rounded-full"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                {loading && <TableDummy />}
                {!loading && filteredFollowers.length > 0 && (
                  <table className="table-auto">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Join date</th>
                        <th className="left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredFollowers.length > 0 &&
                        filteredFollowers.map((item, index) => (
                          <tr key={item.activity_id}>
                            <td>{index + 1}</td>
                            <td>{item.user_profile[0].fullname}</td>
                            <td>{item.prev}</td>
                            <td>{item.date_time}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Followers;
