const languages = [
    "English",
    "Swahili",
    "Español", // Spanish
    "Français", // French
    "Deutsch", // German
    "中文 (简体)", // Chinese (Simplified)
    "中文 (繁體)", // Chinese (Traditional)
    "日本語", // Japanese
    "العربية", // Arabic
    "Русский", // Russian
    "Português", // Portuguese
    "हिन्दी", // Hindi
    "Italiano",
    "Nederlands", // Dutch
    "한국어", // Korean
    "Türkçe", // Turkish
    "ไทย", // Thai
    "Tiếng Việt", // Vietnamese
    "Bahasa Indonesia", // Indonesian
    "Ελληνικά", // Greek
    "Čeština", // Czech
    "Dansk", // Danish
    "Suomi", // Finnish
    "Svenska", // Swedish
    "Norsk", // Norwegian
    "Polski", // Polish
    "Magyar", // Hungarian
    "Română", // Romanian
    "Українська", // Ukrainian
    "עברית", // Hebrew
    "Bahasa Melayu", // Malay
    "Filipino", // Filipino
    "বাংলা", // Bengali
    "ਪੰਜਾਬੀ", // Punjabi
    "தமிழ்", // Tamil
    "తెలుగు", // Telugu
    "සිංහල", // Sinhala
    "मराठी", // Marathi
    "ગુજરાતી", // Gujarati
    "ಕನ್ನಡ", // Kannada
    "اردو", // Urdu
    "Afrikaans"
];


export default languages;