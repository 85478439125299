import React, { useState, useEffect } from "react";
import emoji from "emoji-datasource";

const Categories = {
  recent: {
    symbol: "🕒",
    name: "Recent",
  },
  smileys: {
    symbol: "😀",
    name: "Smileys & Emotion",
  },
  people: {
    symbol: "🧑",
    name: "People & Body",
  },
  animals: {
    symbol: "🐶",
    name: "Animals & Nature",
  },
  food: {
    symbol: "🍔",
    name: "Food & Drink",
  },
  activities: {
    symbol: "⚽",
    name: "Activities",
  },
  travel: {
    symbol: "✈️",
    name: "Travel & Places",
  },
  objects: {
    symbol: "💡",
    name: "Objects",
  },
  symbols: {
    symbol: "🔣",
    name: "Symbols",
  },
  flags: {
    symbol: "🏳️‍🌈",
    name: "Flags",
  },
};

const charFromEmojiObject = (obj) =>
  String.fromCodePoint(...obj.unified.split("-").map((u) => "0x" + u));

const EmojiSelector = ({ onEmojiSelected }) => {
  const [emojis, setEmojis] = useState([]);
  const [filteredEmojis, setFilteredEmojis] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [recentEmojis, setRecentEmojis] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(Categories.smileys);

  useEffect(() => {
    setEmojis(emoji);
    loadRecentEmojis();
  }, []);

  const loadRecentEmojis = () => {
    const storedEmojis = localStorage.getItem("recent_emojis");
    if (storedEmojis) {
      setRecentEmojis(JSON.parse(storedEmojis));
    }
  };

  const saveRecentEmoji = (emoji) => {
    const updatedRecentEmojis = [
      emoji,
      ...recentEmojis.filter((e) => e.unified !== emoji.unified),
    ].slice(0, 20);
    setRecentEmojis(updatedRecentEmojis);
    localStorage.setItem("recent_emojis", JSON.stringify(updatedRecentEmojis));
  };

  const handleSearch = (text) => {
    setSearchQuery(text);
    if (text) {
      const filtered = emojis.filter((e) =>
        e.short_names.some((name) => name.includes(text.toLowerCase()))
      );
      setFilteredEmojis(filtered);
    } else {
      setFilteredEmojis([]);
    }
  };

  const handleEmojiSelect = (emoji) => {
    onEmojiSelected(charFromEmojiObject(emoji));
    saveRecentEmoji(emoji);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const getFilteredEmojis = () => {
    if (searchQuery) {
      return filteredEmojis;
    }
    if (selectedCategory.name === "Recent") {
      return recentEmojis;
    }
    return emojis.filter(
      (e) =>
        e.category.toLowerCase() === selectedCategory.name.toLowerCase() ||
        selectedCategory.name === "Smileys"
    );
  };

  return (
    <div
      style={styles.container}
      className="emoji_container animate__animated animate__slideInUp animate_fast z-10"
    >
      <div className="px-3 pt-2">
        <div style={styles.tabsContainer}>
          {Object.values(Categories).map((category) => (
            <button
              type="button"
              key={category.name}
              style={{
                ...styles.tab,
                ...(selectedCategory.name === category.name
                  ? styles.activeTab
                  : {}),
              }}
              onClick={() => handleCategorySelect(category)}
            >
              {category.symbol}
            </button>
          ))}
        </div>
        <input
          style={styles.searchBar}
          placeholder="Search emojis..."
          value={searchQuery}
          className="searchEmojiBar"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <h2 style={styles.categoryTitle}>{selectedCategory.name}</h2>
      </div>

      <div style={styles.emojiGrid}>
        {getFilteredEmojis().map((emoji) => (
          <button
            type="button"
            key={emoji.unified}
            style={styles.emojiButton}
            onClick={() => handleEmojiSelect(emoji)}
          >
            {charFromEmojiObject(emoji)}
          </button>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    // backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  searchBar: {
    margin: "5px 0",
    padding: "5px",
    paddingLeft: 0,
    borderRadius: "0px",
    borderBottom: "1px solid #d3d3d33b",
    width: "100%",
  },
  tabsContainer: {
    display: "flex",
    overflowX: "auto",
    marginBottom: "0px",
  },
  tab: {
    background: "none",
    border: "none",
    fontSize: "22px",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    paddingBottom: 5,
  },
  activeTab: {
    borderBottom: "2px solid #007AFF",
  },
  categoryTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  emojiGrid: {
    display: "flex",
    // gridTemplateColumns: "repeat(20, 1fr)",
    gap: "5px",
    flexDirection: "row",
    flexWrap: "wrap",
    maxHeight: 181,
    overflowY: "auto",
    padding: 10,
  },
  emojiButton: {
    fontSize: "25px",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
};

export default EmojiSelector;
