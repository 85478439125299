import React, { useEffect, useState } from "react";
import { getPageFollowersByUsername } from "../../../db";
import TableDummy from "../../components/Dummies/TableDummy";
import FollowHorizontal from "../../components/FollowHorizontal";

function PageFollowers({ username }) {
  const [followers, setFollowers] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, [username]);

  useEffect(() => {
    filterFollowers();
  }, [searchTerm, followers]);

  const fetchData = async () => {
    try {
      const list = await getPageFollowersByUsername(username, 100, 0);
      console.log("followers - > ", list);
      setFollowers(list.followers);
      setLoading(false);
    } catch (error) {
      console.error("followers:", error);
    }
  };

  const filterFollowers = () => {
    const filtered = followers.filter((follower) =>
      follower.user_profile[0].fullname
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFiltered(filtered);
  };

  return (
    <div className="align-center justify-center flex flex-col feed_list_cont">
      <div className="align-center justify-center flex flex-col">
        <div className="flex align-center flex-wrap gap-5 rounded-full my-4 w-full sm-p-1">
          <div className="search_table flex-1 w-full relative">
            <span className="material-icons-round absolute top-3 left-4 m-auto h-auto">
              search
            </span>
            <input
              placeholder="Search people"
              className="has_icon form-control search_input w-full rounded-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        {loading && <TableDummy />}
        <div className="">
          {filtered.length > 0 &&
            filtered.map((item, index) => (
              <FollowHorizontal
                data={item.user_profile[0]}
                className={"hover rounded-xl px-2"}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default PageFollowers;
