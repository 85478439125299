import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import ProfileCard from "./components/ProfileCard";
import FollowList from "./components/FollowList";
import JoinHubList from "./components/JoinHubList";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slider from "./components/Slider";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import app from "../config/app";
import MainJobsListPage from "./Jobs/MainJobsListPage";
import JobDetails from "./Jobs/JobDetails";
import { getUserJobList, searchJobList } from "../db";
import JobsListDummy from "./components/Dummies/JobsListDummy";

function Jobs({ local }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let { id, focus, tab } = useParams();

  const [jobList, setJobList] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  // alert(id);
  // Access individual query parameters
  const categoryParam = searchParams.get("category");
  const typeParam = searchParams.get("type");
  const qualificationParam = searchParams.get("qualification");

  const [activeCategory, setActiveCategory] = useState("");
  const [activeJobType, setActiveJobType] = useState("");
  const [activeQualification, setActiveQualification] = useState("");
  const [searchText, setSearchText] = useState("");

  const jobtypeOptions = [
    { value: "", label: "All types" },
    { value: "On-site", label: "On-site" },
    { value: "Remote fulltime", label: "Remote fulltime" },
    { value: "Freelance", label: "Freelance" },
    { value: "Seasonal", label: "Seasonal" },
  ];

  const qualificationOptions = [
    { value: "", label: "All qualification" },
    { value: "O Level", label: "O Level" },
    { value: "Form 4 Leaver", label: "Form 4 Leaver" },
    { value: "Certificate Level", label: "Certificate Level" },
    { value: "Diploma Level", label: "Diploma Level" },
    { value: "Bachelor Level", label: "Bachelor Level" },
    { value: "Master Degree Level", label: "Master Degree Level" },
    { value: "PHD Level", label: "PHD Level" },
  ];

  useEffect(() => {
    document.body.classList.add("wide_body");
    return () => {
      document.body.classList.remove("wide_body");
    };
  }, []);

  useEffect(() => {
    // update jobs list details
    // alert();
  }, [categoryParam, typeParam, qualificationParam]);

  useEffect(() => {
    const fetchDetails = async () => {
      const res = await getUserJobList("all", 30, 0);
      console.log("Jobs", res);
      if (res.job_list.length > 0) {
        setJobList(res.job_list);
      } else {
        setError(true);
      }
      setLoading(false);
    };
    fetchDetails();
  }, [local]);

  const searchJob = async () => {
    const res = await searchJobList(
      "all",
      30,
      0,
      searchText,
      activeCategory,
      activeJobType,
      activeQualification
    );
    console.log("Jobs", res);
    if (res.job_list.length > 0) {
      setJobList(res.job_list);
    } else {
      setError(true);
    }
    setLoading(false);
    setShowFilter(false);
  };

  const handleTextChange = async (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const toggleFilter = () => {
    showFilter ? setShowFilter(false) : setShowFilter(true);
  };

  const filterContainer = (
    <div
      className={`flex align-center gap-2 sticky top-0 px-4 w-full job_filter_cont ${
        showFilter ? "show" : "hide"
      }`}
    >
      <div className="w-full h-10 bg-gray-100 rounded-full">
        <input
          className="w-full h-10 bg-gray-100 rounded-full w-full form-control search_input"
          placeholder="Job title or keyword"
          onChange={handleTextChange}
          value={searchText}
        />
      </div>

      <Menu>
        <MenuButton className="w-full hover h-10 border-1 rounded-full flex align-center justify-between px-3">
          <div>{activeCategory == "" ? "Category" : activeCategory}</div>
          <span class="material-icons-round">keyboard_arrow_down</span>
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom start"
          className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
        >
          <MenuItem>
            <div
              onClick={() => (
                setActiveCategory(""),
                navigate(
                  `/Jobs?category=&type=${activeJobType}&qualification=${activeQualification}`
                )
              )}
              className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
            >
              All categories
            </div>
          </MenuItem>

          {app.jobCategories.map((item, index) => (
            <MenuItem>
              <div
                onClick={() => (
                  setActiveCategory(item),
                  navigate(
                    `/Jobs?category=${item}&type=${activeJobType}&qualification=${activeQualification}`
                  )
                )}
                className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
              >
                {item}
              </div>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>

      <Menu>
        <MenuButton className="w-full hover h-10 border-1 rounded-full flex align-center justify-between px-3">
          <div>{activeJobType == "" ? "Job type" : activeJobType}</div>
          <span class="material-icons-round">keyboard_arrow_down</span>
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom start"
          className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
        >
          {jobtypeOptions.map((item, index) => (
            <MenuItem>
              <div
                onClick={() => (
                  setActiveJobType(item.value),
                  navigate(
                    `/Jobs?category=${activeCategory}&type=${item.value}&qualification=${activeQualification}`
                  )
                )}
                className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
              >
                {item.label}
              </div>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
      <Menu>
        <MenuButton className="w-full hover h-10 border-1 rounded-full flex align-center justify-between px-3">
          <div>
            {activeQualification == "" ? "Qualification" : activeQualification}
          </div>
          <span class="material-icons-round">keyboard_arrow_down</span>
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom start"
          className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
        >
          {qualificationOptions.map((item, index) => (
            <MenuItem>
              <div
                onClick={() => (
                  setActiveQualification(item.value),
                  navigate(
                    `/Jobs?category=${activeCategory}&type=${activeJobType}&qualification=${item.value}`
                  )
                )}
                className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
              >
                {item.label}
              </div>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
      <button className="btn" onClick={() => searchJob()}>
        Search
      </button>
    </div>
  );

  return (
    <>
      <div className="flex-1">
        <div className="px-4 flex align-center headers">
          <div
            className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
            </svg>
          </div>
          <div className="h-10 w-full mr-3 rounded-full align-center flex gap-3 justify-between">
            <div className="font-bold text-xl no-wrap">Find jobs</div>
            <div
              className="w-8 h-8 bg-gray-100 rounded-full hover flex align-center justify-full toggleFilter"
              onClick={() => toggleFilter()}
            ></div>
            {window.innerWidth > app.mobile_width && filterContainer}
          </div>
        </div>
        <div className="">
          {window.innerWidth < app.mobile_width && filterContainer}
        </div>
        {!id ? (
          <>
            {loading && (
              <div className="grid grid-cols-2 py-6 gap-3 grid_jobs px-4">
                <JobsListDummy />
              </div>
            )}
            {!loading && <MainJobsListPage local={local} jobs={jobList} />}
          </>
        ) : (
          <JobDetails id={id} local={local} jobs={jobList} />
        )}
      </div>
    </>
  );
}

export default Jobs;
