function FollowHorizontalDummy() {
  return (
    <>
      <div className="flex align-start px-4 mb-4 animate__animated animate__zoomIn animate_fast">
        <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
        <div className="w-full px-3">
          <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
          <div className="h-6 w-2/3 bg-gray-200 loader mt-1 rounded-full"></div>
          <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
        </div>
        <div className="h-10 w-40 bg-gray-200 loader rounded-full"></div>
      </div>
      <div className="flex align-start px-4 mb-4 animate__animated animate__zoomIn animate_fast">
        <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
        <div className="w-full px-3">
          <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
          <div className="h-6 w-2/3 bg-gray-200 loader mt-1 rounded-full"></div>
          <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
        </div>
        <div className="h-10 w-40 bg-gray-200 loader rounded-full"></div>
      </div>
      <div className="flex align-start px-4 mb-4 animate__animated animate__zoomIn animate_fast">
        <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
        <div className="w-full px-3">
          <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
          <div className="h-6 w-2/3 bg-gray-200 loader mt-1 rounded-full"></div>
          <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
        </div>
        <div className="h-10 w-40 bg-gray-200 loader rounded-full"></div>
      </div>
    </>
  );
}

export default FollowHorizontalDummy;
