import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import React, { useEffect, useState } from "react";

function Portfolio({ data }) {
  const [slideshowOpen, setSlideshowOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [portfolioList, setPortfolioList] = useState([]);

  useEffect(() => {
    setPortfolioList(data);
  }, [data]);

  const openSlideshow = (images, index) => {
    setCurrentImages(images);
    setCurrentImageIndex(index);
    setSlideshowOpen(true);
  };

  const closeSlideshow = () => {
    setSlideshowOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === currentImages.length - 1 ? 0 : currentIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === 0 ? currentImages.length - 1 : currentIndex - 1
    );
  };

  return (
    <div>
      {data.length == 0 && (
        <div className="flex-1 xl:space-y-6 space-y-3 gap-2.5 uk-active grid_top_parent">
          <div className="mt-2 mb-2">
            <div className="flex align-center">
              <img
                src="/images/willowy-people-chatting-on-zoom-meeting-1.png"
                className="w-48 h-auto object-contain"
                with={50}
              />
              <div className="px-3 w-full">
                <div>There is no portfolio added yet.</div>
                <div>When they are created or added, they will appear here</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.map((item) => (
        <div key={item.id} className="h-auto rounded-xl relative gap-5">
          <div className="flex align-center justify-between">
            <div className="text-lg flex-1 w-full py-0 py-3 font-semibold">
              {item.title}
            </div>
          </div>
          <div className="overflow-auto">
            <div className="flex align-center gap-5 flex-wrap">
              {item.images.map((image, index) => (
                <div className="hover w-40 h-40 rounded-xl">
                  <img
                    key={index}
                    src={image}
                    className="object-cover w-40 h-40 rounded-xl cursor-pointer portfolio_image"
                    alt="Portfolio"
                    onClick={() => openSlideshow(item.images, index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <hr className="line" />
        </div>
      ))}

      {slideshowOpen && (
        <Dialog
          className="relative z-10"
          open={slideshowOpen}
          onClose={closeSlideshow}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl"
              >
                <div className="bg-white">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="text-left w-full">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 mb-6 border-bottom p-2 px-5 flex align-center justify-between"
                      >
                        <div>Portfolio</div>
                        <div
                          className="w-10 h-10 rounded-full bg-gray-100 hover flex align-center justify-center"
                          onClick={closeSlideshow}
                        >
                          <span className="material-icons-round">close</span>
                        </div>
                      </DialogTitle>
                      <div className="p-5 py-3 flex justify-center">
                        <img
                          src={currentImages[currentImageIndex]}
                          alt={`Slideshow ${currentImageIndex}`}
                          className="max-h-96 max-w-full object-cover"
                        />
                      </div>
                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6 flex justify-center gap-2">
                        <button
                          onClick={prevImage}
                          className="inline-flex  gap-2 justify-center rounded-full hover bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 sm:ml-3 sm:w-1/5"
                        >
                          <span class="material-symbols-outlined">west</span>
                          <span>Previous</span>
                        </button>
                        {/* <div className="flex-grow"></div> */}
                        <button
                          onClick={nextImage}
                          className="inline-flex gap-2 justify-center rounded-full hover bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 sm:mr-3 sm:w-1/5"
                        >
                          <span>Next</span>
                          <span class="material-symbols-outlined">east</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default Portfolio;
