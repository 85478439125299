import React, { useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import Feed from "./components/Feed";
import DiscoverJobs from "./DiscoverPaths.js/DiscoverJobs";
import DiscoverHubs from "./DiscoverPaths.js/DiscoverHubs";
import DiscoverPeople from "./DiscoverPaths.js/DiscoverPeople";
import DiscoverBusiness from "./DiscoverPaths.js/DiscoverBusiness";
import DiscoverTopcs from "./DiscoverPaths.js/DiscoverTopcs";
import DiscoverAll from "./DiscoverPaths.js/DiscoverAll";
import app from "../config/app";
import FollowListReused from "./components/FollowListReused";
import JoinHubsListReused from "./components/JoinHubsListReused";
import CopyRight from "./components/CopyRight";
import DiscoverAgencies from "./DiscoverPaths.js/DiscoverAgencies";
import DiscoverSchools from "./DiscoverPaths.js/DiscoverSchools";

const Discover = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useParams();
  const currentPath = location.pathname;

  // Define the paths array as a constant
  const paths = useMemo(
    () => [
      { id: 1, title: "People", path: "/discover/people" },
      { id: 3, title: "Hubs", path: "/discover/hubs" },
      { id: 5, title: "Businesses", path: "/discover/businesses" },
      { id: 6, title: "Agencies", path: "/discover/agencies" },
      { id: 7, title: "Schools", path: "/discover/schools" },
      // { id: 9, title: "Who is Hiring?", path: "/discover/jobs" },
    ],
    []
  );

  // Simplified component selection
  const componentMap = {
    all: <DiscoverAll />,
    jobs: <DiscoverJobs />,
    hubs: <DiscoverHubs />,
    people: <DiscoverPeople />,
    businesses: <DiscoverBusiness />,
    agencies: <DiscoverAgencies />,
    schools: <DiscoverSchools />,
  };

  const component = componentMap[name] || <DiscoverPeople />;

  // Menu JSX Memoized
  const menu = useMemo(
    () => (
      <div className="p-0 sticky_profile_header flex align-center">
        <div className="px-4 flex align-center headers w-full">
          {paths.map((item) => (
            <Link
              key={item.id}
              to={item.path}
              className={currentPath === item.path ? "active_tab" : ""}
            >
              <div className="h-10 px-4 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
                {item.title}
              </div>
            </Link>
          ))}
        </div>
      </div>
    ),
    [currentPath, paths]
  );

  return (
    <div className="min-h-screen flex-1">
      <div className="px-4 headers p-0 pl-1">
        <div
          className="h-full w-full flex align-center"
          style={{ width: "100%" }}
        >
          <div
            className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center ml-2"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
            </svg>
          </div>
          <div className="h-10 w-full mr-3 rounded-full align-center flex">
            <div className="font-bold text-xl">Discover</div>
          </div>
        </div>
        {window.innerWidth > app.mobile_width && <TopUserMenu />}
      </div>
      <div className="headers"> {menu}</div>
      {/* <div className="py-3 px-4">
        <div className="w-full">
          <input
            className="search_input form-control px-2 rounded-full text-md w-full"
            placeholder={`Search ${name || "people"}`}
          />
        </div>
      </div> */}
      <div className="p-0 mt-4">
        {/* Render the appropriate component */}
        {component}
      </div>
    </div>
  );
});

export default Discover;
