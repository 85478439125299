import React, { useState } from "react";
import OneSlider from "./OneSlider";
import { Link } from "react-router-dom";
import app from "../../config/app";
import VideoPlayer from "./VideoPlayer";
import Loader from "./Loader";

function MediaFeed({ data, allowVibeLinks, from }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Helper function to render image or video
  const renderMedia = (item) => {
    const isImage = item.row.mediatype === "image";
    const smallImageUrl = `${app.media_url}posts/${
      allowVibeLinks ? "xlsm/" : ""
    }${item.row.filename}`; // Small image URL
    const largeImageUrl = `${app.media_url}posts/${
      allowVibeLinks ? "large/" : ""
    }${item.row.filename}`; // Large image URL

    return isImage ? (
      <div className="relative">
        {/* Small image (loaded first) */}

        <img
          key={`${item.id}-small`}
          src={smallImageUrl}
          className={`round-sm post_image ${!imageLoaded && "loading_image"}`}
          alt="small media"
        />

        {/* Large image (loaded in the background) */}
        <img
          key={`${item.id}-large`}
          src={largeImageUrl}
          className={`round-sm post_image absolute top-0 left-0 transition-opacity duration-300 ${
            imageLoaded ? "opacity-100" : "opacity-0"
          }`}
          alt="large media"
          onLoad={() => setImageLoaded(true)} // When the large image is loaded
        />

        {/* Loading indicator */}
        {!imageLoaded && (
          <div className="flex align-center justify-center absolute top-0 bottom-0 left-0 right-0 m-auto media_loader_overlay">
            <Loader />
          </div>
        )}
      </div>
    ) : (
      <VideoPlayer
        key={item.id}
        thumbnailSrc={app.media_url + "posts/" + item.row.thumb_filename}
        videoSrc={app.media_url + "posts/" + item.row.filename}
      />
    );
  };

  // Helper function to render media with optional link for images only
  const renderMediaWithLink = (item) => {
    const isImage = item.row.mediatype === "image";
    const mediaContent = renderMedia(item);

    return isImage && allowVibeLinks ? (
      <Link
        className="min-w-full"
        to={`${from ? from : "/home/vibe/"}${data?.row?.activity_id}`}
        key={item.id}
      >
        {mediaContent}
      </Link>
    ) : (
      mediaContent
    );
  };

  return (
    <div className="flex mt-2 gap-3 overflow-hidden post_img_cont">
      {data.uploads.length > 1 ? (
        <OneSlider>
          {data.uploads.map((item) => renderMediaWithLink(item))}
        </OneSlider>
      ) : (
        renderMediaWithLink(data.uploads[0])
      )}
    </div>
  );
}

export default MediaFeed;
