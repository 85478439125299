import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { acceptJobApplication, rejectJobApplication } from "../../../../db";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";

const MySwal = withReactContent(Swal);

function Applications({ data, onRefreshData }) {
  console.log("Lising ->", data);
  let { id, page, focus, focus_id } = useParams();
  const acceptApplication = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Please confirm to accept this application",
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm",
    });

    if (result.isConfirmed) {
      try {
        const response = await acceptJobApplication({
          business: id,
          activity_id: activity_id,
        });
        console.log(response);
        onRefreshData();
        MySwal.fire(
          "Accepted!",
          "Details saved and applicant notified",
          "success"
        );
      } catch (error) {
        console.error("Failed to archive the job:", error);
        MySwal.fire("Error!", "There was an error archiving the job.", "error");
      }
    }
  };

  const rejectApplication = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Please confirm to reject this application",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm",
    });

    if (result.isConfirmed) {
      try {
        const response = await rejectJobApplication({
          business: id,
          activity_id: activity_id,
        });

        console.log(response);
        onRefreshData();
        MySwal.fire(
          "Archived!",
          "Details saved and applicant notified",
          "success"
        );
      } catch (error) {
        console.error("Failed:", error);
        MySwal.fire("Error!", "There was an error archiving the job.", "error");
      }
    }
  };

  return (
    <div>
      {data.length == 0 && (
        <div className="mt-2 mb-2">
          <div className="flex align-center">
            <img
              src="/images/beam-woman-meditating-in-lotus-position.gif"
              className="w-36 h-auto sm_image"
              with="50"
            />
            <div className="px-3">
              <div className="font-bold text-lg">No applications</div>
              <div>
                When this user apply for a job on this page it will be shown
                here.
              </div>
            </div>
          </div>
        </div>
      )}
      <table className="table-auto mt-3 table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Job name</th>
            <th>Status</th>
            <th className="center">Date applied</th>
            <th>Action</th>
          </tr>
        </thead>
        {data.length > 0 && (
          <tbody>
            {data.length > 0 &&
              data.map((item, index) => (
                <tr>
                  <td>
                    <div className="mt-2">{index + 1}</div>
                  </td>
                  <td>
                    <div className="">{item.job_details[0].row.job_name}</div>
                    <div className="text-sm">{item.row.about}</div>
                  </td>
                  <td style={{ textTransform: "capitalize" }}>
                    {item.row.status == "false" ? "Pending" : item.row.status}
                  </td>
                  <td>{item.row.date_time}</td>
                  <td>
                    <Menu>
                      <MenuButton className="h-10 w-10 bg-gray-100 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-3 post_attachment start_audio_recording">
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </MenuButton>

                      <MenuItems
                        transition
                        anchor="bottom end"
                        className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                      >
                        <MenuItem>
                          <Link
                            to={`/admin/${id}/jobapplication/${item?.row?.activity_id}`}
                            className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                          >
                            View application
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link
                            to={`/admin/${id}/jobdetails/${item.job_details[0].row.activity_id}`}
                            className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                          >
                            About job
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <a
                            target="_blank"
                            href={`/jobs/${item.job_details[0].row.activity_id}`}
                            className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                          >
                            <div className="">Job details (In search)</div>
                            <ArrowTopRightOnSquareIcon />
                          </a>
                        </MenuItem>
                        <hr className="line_sm" />
                        {(item.row.status == "false" ||
                          item.row.status == "rejected") && (
                          <MenuItem>
                            <div
                              onClick={() =>
                                acceptApplication(item.row.activity_id)
                              }
                              className="group text-green-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                            >
                              Accept application
                            </div>
                          </MenuItem>
                        )}

                        {item.row.status == "accepted" && (
                          <MenuItem>
                            <div
                              onClick={() =>
                                rejectApplication(item.row.activity_id)
                              }
                              className="group text-red-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                            >
                              Reject application
                            </div>
                          </MenuItem>
                        )}
                      </MenuItems>
                    </Menu>
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default Applications;
