import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import {
  saveBusinessAccessToIndexedDB,
  saveBusinessToIndexedDB,
  updateLocalStorage,
} from "../db";
import Responce from "./components/Responce";
import app from "../config/app";
import Loader from "./components/Loader";

function CreatePage() {
  const navigate = useNavigate();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    page_type: "",
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    document.body.classList.add("wide_body");
    return () => {
      document.body.classList.remove("wide_body");
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let business_id = Date.now().toString() + "@" + app.current_user;

    const submit_data = {
      id: business_id,
      data: formData,
    };

    const responce = await saveBusinessToIndexedDB(submit_data);
    updateLocalStorage({ type: "updateBusiness", data: formData });
    console.log("Responce", responce);
    console.log(responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      const save_responce = await saveBusinessAccessToIndexedDB(responce);
      navigate("/admin/" + business_id);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex-1 w-full">
        <div className="px-4 flex align-center headers w-full">
          <div className="px-4 flex align-center w-full">
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Create a page</div>
            </div>
          </div>
          <TopUserMenu />
        </div>

        <div className="w-full flex align-center justify-center py-7 pb-0 sm-p-1">
          {/* create content here */}
          <div className="m-0 align-center justify-center flex w-full px-10 sm-p-0">
            <div className="box w-full rounded-xl px-10 py-5 w-full flex align-start gap-5 sm-w-full sm-p-0">
              <div className="illustration w-2/4 flex align-center justify-center sm-d-none">
                <img src="/images/beam-shopping-girl.png" className="w-2/3" />
              </div>
              <div className="w-2/4 border-left pl-10 pr-4 relative sm-p-1 sm-w-full sm-border-none">
                {loading && (
                  <div className="absolute w-full flex align-center justify-center h-full top-0 auth_loader_cont">
                    <Loader />
                  </div>
                )}
                <h4 className="text-2xl font-bold my-4">
                  VibeIn Business page
                </h4>
                <div className="explainer text-mute">
                  Unlock the power of VibeIn's business page to effortlessly
                  enhance your online presence. Seamlessly manage your
                  business's vibes, job opportunities, and remote workforce with
                  advanced efficiency
                </div>
                <form
                  method="POST"
                  id="createBusinessPage"
                  onSubmit={handleSubmit}
                >
                  <div className="w-full flex flex-column gap-3 mt-5">
                    <label className="full">
                      Page name<span className="inline ml-2">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Business name"
                      name="name"
                      required=""
                      className="form-control px-5"
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </div>
                  <div className="w-full flex flex-column gap-3 mt-5">
                    <label>
                      Business type<span className="inline ml-2">*</span>
                    </label>
                    <select
                      className="form-control px-5"
                      name="page_type"
                      onChange={handleChange}
                      value={formData.page_type}
                    >
                      <option value="">Select here</option>
                      <option value="school">
                        Learning institution (School)
                      </option>
                      <option value="agency">Recruitment agency</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div className="get_respoce"></div>
                  <button
                    type="submit"
                    className="h-12 btn rounded-xl text-center flex align-center w-full my-4 mt-10 justify-center"
                    style={{ borderRadius: 10 }}
                  >
                    <span>Continue</span>
                    {/* <Loader /> */}
                  </button>
                  <div className="res mt-1"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showResponceCard && responceCard}
    </>
  );
}

export default CreatePage;
