import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "./Slider";
import app from "../../config/app";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowRightIcon,
  ChevronRightIcon,
  FlagIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/16/solid";
import {
  formatRelativeTime,
  sendMessage,
  shareVibeAsMessage,
  submitDeleteVibe,
  submitFollowUser,
  submitlLikeVibe,
  submitRestoreVibe,
  submitRevibeVibe,
  submitVibeBookmark,
  submitVibeView,
} from "../../db";
import SelectUsers from "../modals/SelectUsers";
import SelectHubs from "../modals/SelectHubs";
import ReportVibe from "../modals/ReportVibe";
import Revibe from "./Revibe";
import LinkPreview from "./LinkPreview";
import PostContent from "./PostContent";
import OneSlider from "./OneSlider";
import MediaFeed from "./MediaFeed";
import Responce from "./Responce";
import PollItem from "./PollItem";

function Feed({
  data,
  key,
  allowVibeLinks = true,
  onCloseVibePopup,
  onCommentSubmit,
  local,
  from,
}) {
  const [likeStatus, setLikeStatus] = useState(false);
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);
  const [animateLikes, setAnimateLikes] = useState(false);
  const [timeAgo, setTimeAgo] = useState("");
  const [followingStatus, setFollowingStatus] = useState(false);
  const [seenStatus, setSeenStatus] = useState(false);
  const [totalViews, setTotalViews] = useState(0);
  const [submited, setSubmited] = useState(false);
  const [vibeLink, setVibeLink] = useState("");
  const [saveStatus, setSaveStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showHubsModal, setShowHubsModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [revibeStatus, setRevibeStatus] = useState(false);
  const [links, setLinks] = useState([]);

  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);

  useEffect(() => {
    setLikeStatus(data.row.like_status);
    setLikes(data.row.likes);
    setComments(data.row.comments);
    setFollowingStatus(data.row.followingStatus);
    setSeenStatus(data.row.seenStatus);
    setSaveStatus(data.row?.bookmark_status);
    setRevibeStatus(data.row?.revibeStatus);
    // console.log("Data->>>>", data);
    Array.isArray(data.row?.links) ? setLinks(data.row?.links) : setLinks([]);

    // console.log("Links ->s", data.row.links);
    if (data.row.seenStatus) {
      setTotalViews(data.row.views);
    } else {
      setTotalViews(data.row.views + 1);
      update();
    }
  }, [data, onCommentSubmit]);

  const update = () => {
    if (!submited) {
      setSubmited(true);
      submitVibeView(data?.row?.activity_id);
    }
  };

  const likeVibe = async (vibe_id) => {
    const response = await submitlLikeVibe(vibe_id);
    if (likeStatus) {
      setLikeStatus(false);
      setLikes(likes - 1);
    } else {
      setLikeStatus(true);
      setLikes(likes + 1);
    }
    // Trigger the animation
    setAnimateLikes(true);
    setTimeout(() => setAnimateLikes(false), 500);
  };

  useEffect(() => {
    const updateRelativeTime = () => {
      const timeElapsed = Date.now() - data.row.date_time * 1000; // assuming date_time is in seconds
      setTimeAgo(formatRelativeTime(timeElapsed));
    };

    updateRelativeTime();
    const intervalId = setInterval(updateRelativeTime, 1000); // update every second
    const link = app.share_link + "vibe/" + data?.row?.activity_id;
    setVibeLink(link);
    return () => clearInterval(intervalId); // cleanup on unmount
  }, [data]);

  const handleFollow = async (activity_id) => {
    followingStatus ? setFollowingStatus(false) : setFollowingStatus(true);
    // alert(username);
    try {
      const data = await submitFollowUser(activity_id);
      // console.log("Follow - > ", data);
    } catch (error) {
      // console.error("Details:", error);
    }
  };

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(vibeLink)
        .then(() => {
          // alert("Link copied to clipboard!");
          setShowResponceCard(true);
          setTimeout(() => {
            setShowResponceCard(false);
          }, 3000);
          setResponceCard(
            <Responce data={{ text: "Link copied", status: true }} />
          );
        })
        .catch((error) => {
          console.error("Error copying link: ", error);
        });
    } else {
      console.error("Clipboard API not supported in this browser.");
    }
  };

  const moreShareOptions = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this!",
          text: "Here is something really interesting!",
          url: vibeLink,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((error) => {
          console.error("Error sharing:", error);
        });
    } else {
      console.error("Web Share API not supported in this browser.");
    }
  };

  const revibe = async () => {
    // action
    const vibe_id = data?.row?.activity_id;
    if (!revibeStatus) {
      try {
        setRevibeStatus(true);
        const data_res = await submitRevibeVibe(vibe_id);
        console.log("Revibe - > ", data_res);
        if (data_res) {
          setShowResponceCard(true);
          setTimeout(() => {
            setShowResponceCard(false);
          }, 3000);
          setResponceCard(
            <Responce
              data={{ text: data_res.responce, status: data_res.r_status }}
            />
          );
          // console.log(responce_card);
        }
      } catch (error) {
        console.error("Details:", error);
      }
    } else {
      alert("Already revibed");
    }
  };

  const shareAsMessage = () => {
    // action
    setShowUsersModal(true);
  };

  const shareVibeOnHub = () => {
    // action
    setShowHubsModal(true);
  };

  const reportVibe = () => {
    // action
    setShowReportModal(true);
  };

  const deleteVibe = async (vibe_id) => {
    // action
    setDeleteStatus(true);
    // alert(username);
    try {
      const data_res = await submitDeleteVibe(vibe_id);
      console.log("Delete - > ", data_res);
      if (data_res) {
        setShowResponceCard(true);
        setTimeout(() => {
          setShowResponceCard(false);
        }, 3000);
        setResponceCard(
          <Responce
            data={{ text: data_res.responce, status: data_res.r_status }}
          />
        );
        // console.log(responce_card);
      }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const undoDelete = async (vibe_id) => {
    // action
    setDeleteStatus(false);
    // alert(username);
    try {
      const data_res = await submitRestoreVibe(vibe_id);
      // console.log("Delete - > ", data_res);
      if (data_res) {
        setShowResponceCard(true);
        setTimeout(() => {
          setShowResponceCard(false);
        }, 3000);
        setResponceCard(
          <Responce
            data={{ text: data_res.responce, status: data_res.r_status }}
          />
        );
        // console.log(responce_card);
      }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleSave = async (vibe_id) => {
    saveStatus ? setSaveStatus(false) : setSaveStatus(true);
    // alert(username);
    try {
      const data_res = await submitVibeBookmark(vibe_id);
      if (data_res) {
        setShowResponceCard(true);
        setTimeout(() => {
          setShowResponceCard(false);
        }, 3000);
        setResponceCard(
          <Responce
            data={{ text: data_res.responce, status: data_res.r_status }}
          />
        );
      }
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false); // Track whether the full text is shown

  // Toggle between showing full text or truncated text
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const onSelectHub = async (hubids) => {};

  const onSelectUser = async (chatids) => {
    const data_res = await shareVibeAsMessage(
      chatids,
      vibeLink,
      data?.row?.activity_id
    );

    if (data_res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: data_res.responce, status: data_res.r_status }}
        />
      );
      // console.log(responce_card);
    }

    // also save this messsage to the device and broadcast it to relevant devices
    for (let index = 0; index < chatids.length; index++) {
      const element = chatids[index];
      const messageObj = {
        user_id: app.current_user,
        activity_id: Date.now().toString() + "@" + app.current_user,
        status: false,
        parent: "",
        last_modified: Date.now(),
        date_time: Date.now(),
        sender: app.current_user,
        chat_body: vibeLink.trim(),
        reciever: chatids[index].user_id,
        contain_media: false,
        conversation_id: chatids[index].chatid,
        send_status: true,
        read_status: false,
        post_shared: null,
        time: Date.now(),
        uploads: {
          images: [],
          videos: [],
          audios: [],
          documents: [],
        },
        draft_status: false,
        options: {},
      };
      const res = await sendMessage(messageObj, false);
    }

    console.log("Share responce", data_res);
  };

  const share_menu = (
    <Menu>
      <MenuButton className="h-8 w-8  hover rounded-full align-center flex justify-center ml-3">
        <span class="material-symbols-rounded">ios_share</span>
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom end"
        className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
      >
        <MenuItem
          as="div"
          onClick={() => revibe()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-outlined">autorenew</span>
          <div className="Upload an audio">Revibe </div>
        </MenuItem>
        <MenuItem
          as="div"
          onClick={() => shareAsMessage()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">send</span>
          <div className="Upload an audio">Share as message </div>
        </MenuItem>
        <MenuItem
          as="div"
          onClick={() => copyLink()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">content_copy</span>
          <div className="Upload an audio">Copy vibe link </div>
        </MenuItem>
        {/* <MenuItem
          as="div"
          onClick={() => shareVibeOnHub()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">group</span>
          <div className="Upload an audio">Share on Hub </div>
        </MenuItem> */}
        <MenuItem
          as="div"
          onClick={() => moreShareOptions()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">more_horiz</span>
          <div className="Upload an audio">More options </div>
        </MenuItem>
      </MenuItems>
    </Menu>
  );

  const vibe_menu = (
    <Menu>
      <MenuButton className="h-10 min-w-10 ml-3 -mt-1.5 rounded-full flex align-center justify-center hover">
        <span class="material-icons-round">more_horiz</span>
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom end"
        className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
      >
        <MenuItem as="div">
          <Link
            to={`${from ? from : "/home/vibe/"}${data?.row?.activity_id}`}
            className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
          >
            <div className="Upload an audio">View vibe </div>
            <ChevronRightIcon />
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => copyLink()}
          as="div"
          className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
        >
          <div className="Upload an audio">Copy vibe link </div>
          <span class="material-symbols-rounded">content_copy</span>
        </MenuItem>

        <MenuItem
          as="div"
          onClick={() => handleSave(data?.row?.activity_id)}
          className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
        >
          <div className="Upload an audio">
            {saveStatus ? "Remove from " : "Add to "} bookmark
          </div>
          {saveStatus ? (
            <span class="material-icons">bookmark</span>
          ) : (
            <span class="material-symbols-outlined">bookmark_border</span>
          )}
        </MenuItem>
        <MenuItem
          as="div"
          className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
        >
          <div className="Upload an audio">Share vibe </div>
          <div style={{ position: "relative", right: -5 }}>{share_menu}</div>
        </MenuItem>

        <div className="line"></div>

        {app.current_user == data?.user_info[0]?.user_id && (
          <MenuItem
            as="div"
            onClick={() => deleteVibe(data?.row?.activity_id)}
            className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
          >
            <div className="Upload an audio">Delete vibe </div>
            <TrashIcon />
          </MenuItem>
        )}
        {app.current_user != data?.user_info[0]?.user_id && (
          <MenuItem
            as="div"
            onClick={() => reportVibe(data?.row?.activity_id)}
            className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
          >
            <div className="Upload an audio">Report vibe </div>
            <FlagIcon />
          </MenuItem>
        )}
      </MenuItems>
    </Menu>
  );

  if (deleteStatus) {
    return (
      <>
        <div className="py-3 px-3 flex align-center justify-between">
          <div className="">Your deleted this vibe</div>
          <button
            className="btn"
            style={{ fontSize: 14 }}
            onClick={() => undoDelete(data?.row?.activity_id)}
          >
            Undo
          </button>
        </div>
        <div className="line"></div>
      </>
    );
  }

  return (
    <>
      {data && Array.isArray(data.user_info) && (
        <div key={key} className="w-full feed_container">
          <div className="w-full flex align-center px-2 vibe_header">
            {data.row.publicity_page != "" && (
              <>
                <Link to={`/page/@${data.row?.page_info[0]?.username}`}>
                  <div
                    className={`h-10 min-w-10
                    bg-gray-200 mr-3 rounded-full`}
                  >
                    <img
                      src={
                        app.media_url +
                        "thumb/small/" +
                        data.row?.page_info[0]?.profile_image
                      }
                      class={`
                          h-8 w-8 rounded-full
                          profile_image
                       object-cover`}
                    />
                  </div>
                </Link>
                <div className="w-full flex-row mt-1">
                  <Link to={`/page/@${data.row?.page_info[0]?.username}`}>
                    <div className="w-full rounded-full flex align-center sm_block gap-2">
                      <div className="font-bold full_name_post flex align-center gap-1">
                        <span>
                          {data.row?.page_info[0]?.business_name}
                          {data.row?.page_info[0]?.verification_status ==
                            "true" && (
                            <svg
                              className="verification_icon"
                              xmlns="http://www.w3.org/2000/svg"
                              enable-background="new 0 0 24 24"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#e8eaed"
                              style={{ marginLeft: 0 }}
                            >
                              <g>
                                <rect fill="none" height="24" width="24"></rect>
                              </g>
                              <g>
                                <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"></path>
                              </g>
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="w-full mr-3 rounded-full flex align-center relative -mt-1">
                      <div className="text-sm">Business</div>
                      <div className="text-sm ml-4 show_time relative">
                        {timeAgo}
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}

            {data.row.publicity_hub != "" && (
              <>
                <Link to={`/communities/${data.row?.hub_info[0]?.username}`}>
                  <div
                    className={`h-10 min-w-10
                    bg-gray-200 mr-3 rounded-full`}
                  >
                    <img
                      src={
                        app.media_url +
                        "thumb/small/" +
                        data.row?.hub_info[0]?.profile_image
                      }
                      class={`
                          h-8 w-8 rounded-full
                          profile_image
                       object-cover`}
                    />
                  </div>
                </Link>
                <div className="w-full flex-row mt-1">
                  <Link to={`/communities/${data.row?.hub_info[0]?.username}`}>
                    <div className="w-full rounded-full flex align-center sm_block gap-2">
                      <div className="font-bold full_name_post flex align-center gap-1">
                        <span>{data.row?.hub_info[0]?.hub_name}</span>
                        <span
                          class="material-symbols-outlined ml-2 flex align-center justify-center"
                          style={{ fontWeight: "lighter", fontSize: 15 }}
                        >
                          group
                        </span>
                      </div>
                    </div>
                    <div className="w-full mr-3 rounded-full flex align-center relative -mt-1">
                      <div className="text-sm text_ellipsis">
                        Posted by {data.user_info[0]?.fullname}
                      </div>
                      <div className="text-sm ml-4 show_time relative">
                        {timeAgo}
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}

            {data.row.publicity_hub == "" && data.row.publicity_page == "" && (
              <>
                <Link to={`/Profile/${data.user_info[0]?.username}`}>
                  <div
                    className={`${
                      data.row.publicity_hub != ""
                        ? "h-8 min-w-8"
                        : "h-10 min-w-10"
                    } bg-gray-200 mr-3 rounded-full`}
                  >
                    <img
                      src={data.user_info[0]?.profile_image}
                      class={`${
                        data.row.publicity_hub != ""
                          ? "h-8 w-8 rounded-full"
                          : "profile_image"
                      } object-cover`}
                    />
                  </div>
                </Link>
                <div className="w-full flex-row mt-1">
                  <Link to={`/Profile/${data.user_info[0]?.username}`}>
                    <div className="w-full rounded-full flex align-center sm_block gap-2">
                      <div className="font-bold full_name_post flex align-center gap-1">
                        {data.user_info[0]?.fullname}
                        {data.user_info[0]?.verification_status == "true" && (
                          <svg
                            className="verification_icon"
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#e8eaed"
                            style={{ marginLeft: 0 }}
                          >
                            <g>
                              <rect fill="none" height="24" width="24"></rect>
                            </g>
                            <g>
                              <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"></path>
                            </g>
                          </svg>
                        )}
                      </div>
                      <div className="text-sm -mt-1">
                        @{data.user_info[0]?.username}
                      </div>
                    </div>
                    <div className="w-full mr-3 rounded-full flex align-center relative -mt-1">
                      <div className="text-sm">
                        {data.user_info[0]?.current_job_title}
                      </div>
                      <div className="text-sm ml-4 show_time relative">
                        {timeAgo}
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}

            {data?.user_info[0]?.user_id != app.current_user &&
              !followingStatus && (
                <div
                  onClick={() => handleFollow(data?.user_info[0]?.user_id)}
                  className="h-8 min-w-24 border-1 mr-3 ml-2 rounded-full flex align-center text-center justify-center hover follow_on_btn_feed"
                >
                  {!followingStatus ? "Follow" : "Unfollow"}
                </div>
              )}

            {!allowVibeLinks && (
              <div
                onClick={onCloseVibePopup}
                className="w-10 h-10 min-w-10 rounded-full hover flex align-center justify-center"
              >
                <span class="material-symbols-outlined">close</span>
              </div>
            )}

            {vibe_menu}
          </div>
          {data.row?.parent_post != "" && (
            <Revibe parent={data?.row?.parent_post} local={local} />
          )}
          {/* post details */}
          <PostContent text={data.row?.post_content} />
          {data.row?.hasPoll && (
            <div className="">
              <PollItem data={data?.row?.optionDetails} />
            </div>
          )}

          {/* post media*/}
          {data.uploads.length > 0 && (
            <MediaFeed
              data={data}
              allowVibeLinks={allowVibeLinks}
              from={from}
            />
          )}

          {Array.isArray(links) && links.length > 0 && (
            <LinkPreview
              links={links}
              show_featured_img={data.uploads.length > 0 ? false : true}
            />
          )}

          <div className="flex mt-3 align-center px-4 footer_vibe_stats">
            <div className="flex align-center w-full">
              <div
                className="flex align-center gap-1 rounded-full hover"
                onClick={() => likeVibe(data?.row?.activity_id)}
              >
                <div
                  className={`h-8 w-8  rounded-full align-center flex justify-center  ${
                    animateLikes ? "like-count-change" : ""
                  }`}
                >
                  {likeStatus ? (
                    <span class="material-icons-round liked">thumb_up</span>
                  ) : (
                    <span class="material-symbols-outlined unliked">
                      thumb_up
                    </span>
                  )}
                </div>
                <div
                  className={`h-8 pr-1 rounded-full align-center text-center flex justify-center sm-px-2 gap-1`}
                >
                  <span className="alt_post_like_count">{likes}</span>
                  <span className="alt_post_exp">Like{likes > 1 && "s"} </span>
                </div>
              </div>
              <Link
                to={`${from ? from : "/home/vibe/"}${data?.row?.activity_id}`}
                className="flex align-center gap-1 ml-3 mr-3 hover rounded-full"
              >
                <div className="h-8 w-8 rounded-full align-center flex justify-center">
                  {/* <span class="material-symbols-outlined">quick_phrases</span> */}
                  <span class="material-symbols-rounded">maps_ugc</span>
                </div>
                <div className="h-8 px-1 w-auto rounded-full align-center text-center flex justify-center sm-px-2 gap-1">
                  <span className="alt_post_comment_count">{comments}</span>
                  <span className="alt_post_exp">
                    Comment{comments > 1 && "s"}
                  </span>
                </div>
              </Link>
            </div>
            <div className="flex align-center">
              <div
                className="h-8 w-auto  hover mr-1 rounded-full align-center flex justify-center"
                title="Vibe views"
              >
                <span class="material-symbols-rounded">
                  signal_cellular_alt
                </span>
                <div className="px-2">{totalViews}</div>
              </div>
              <div
                className="h-8 w-8  hover rounded-full align-center flex justify-center"
                onClick={() => revibe()}
              >
                <span class="material-symbols-outlined">autorenew</span>
              </div>
              <div
                onClick={() => handleSave(data?.row?.activity_id)}
                className="h-8 w-8  hover rounded-full align-center flex justify-center ml-3"
              >
                {saveStatus ? (
                  <span class="material-icons">bookmark</span>
                ) : (
                  <span class="material-symbols-outlined">bookmark_border</span>
                )}
              </div>
              {share_menu}
            </div>
          </div>
          <hr class="line" />
        </div>
      )}

      {showResponceCard && responceCard}
      {/* modals */}

      {showUsersModal && (
        <SelectUsers
          onCloseModal={() => setShowUsersModal(false)}
          onSelectUser={(user_id) => onSelectUser(user_id)}
          submitTitle={"Share vibe"}
        />
      )}

      {showHubsModal && (
        <SelectHubs
          onCloseModal={() => setShowHubsModal(false)}
          onSelectHub={(user_id) => onSelectHub(user_id)}
          submitTitle={"Share vibe"}
        />
      )}

      {showReportModal && (
        <ReportVibe onCloseModal={() => setShowReportModal(false)} />
      )}
    </>
  );
}

export default Feed;
