import React, { useEffect, useState } from "react";
import app from "../../../config/app";
import Responce from "../../components/Responce";
import {
  getHubAccessList,
  getHubProfile,
  updateHubCategory,
} from "../../../db";
import { useParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/16/solid";

function CategoryAndType({ local }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filter, setFilter] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [hubProfile, setHubProfile] = useState();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { id, page, focus } = useParams();
  const system_categories = app.businessCategories;
  const [formData, setFormData] = useState({
    type: "",
    category: "",
  });

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const info = await getHubProfile(id);
      console.log("Hub info - > ", info.hub_info[0]);
      setHubProfile(info.hub_info[0]);
      if (info.r_status) {
        setSelectedCategory(info.hub_info[0].category);
        setFormData({
          type: info.hub_info[0].type,
          category: info.hub_info[0].category,
        });
      }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCategoryUpdate = (category) => {
    setSelectedCategory(category);
    setFormData({
      type: formData.type,
      category: category,
    });
  };

  const handleRemoveCategory = () => {
    setSelectedCategory("");
    setFormData({
      type: formData.type,
      category: "",
    });
  };

  const filteredCategory = system_categories.filter((category) =>
    category.toLowerCase().includes(filter.toLowerCase())
  );

  const handleSubmit = async () => {
    const res = await updateHubCategory(id, formData);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      // console.log(responce_card);
    }

    // update local data list
    await getHubAccessList(app.current_user, 30);
    console.log("Form submitted res:", res);
  };

  return (
    <div className="p-0 pt-3">
      <div className="mb-4 flex align-center justify-between">
        <div className="text-xl font-semibold">Category settings</div>

        <button type="button" onClick={() => handleSubmit()} className="btn">
          {isSaving ? "Saving..." : "Save"}
        </button>
      </div>

      <div className="w-full flex flex-column gap-3 mt-5">
        <label>
          Hub type<span className="inline ml-2">*</span>
        </label>

        <select
          className="form-control px-5"
          name="type"
          value={formData.type}
          onChange={handleChange}
        >
          <option value="public">Public</option>
          <option value="private">Private</option>
        </select>
      </div>

      <hr className="line" />

      <div className="px-2 py-3 pt-0">Select from categories below</div>
      <div className="w-full flex">
        {selectedCategory != "" && (
          <div className="border-1 rounded-full h-8 px-3 pr-0 flex align-center justify-between gap-3">
            <div className="">{formData.category}</div>
            <div
              className="flex align-center justify-center w-8 h-8 rounded-full bg-gray-100 hover"
              onClick={() => handleRemoveCategory()}
            >
              <XMarkIcon />
            </div>
          </div>
        )}
      </div>
      <div className="sticky tags_filter py-2">
        <input
          type="text"
          placeholder="Search category..."
          className="mb-4 p-2 border border-gray-300 rounded-xl w-full px-6"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      <div className="overflow-hidden overflow_list_cont">
        <div className="category_list border-1 rounded-2xl px-2 py-2">
          {filteredCategory.map((category, index) => (
            <div
              key={index}
              onClick={() => handleCategoryUpdate(category)}
              className={`p-3 cursor-pointer ${
                selectedCategory === category
                  ? "active bg-gray-200 select_list rounded-xl text-black"
                  : ""
              }`}
            >
              {category}
            </div>
          ))}
        </div>
      </div>
      {showResponceCard && responceCard}
    </div>
  );
}

export default CategoryAndType;
