import { Link, useParams } from "react-router-dom";
import app from "../../config/app";
import { CameraIcon, PencilIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";

function ProfileCard({ userInfo }) {
  const { username } = useParams();
  const [seeAllSkills, setSeeAllSkills] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null); // Initialize user state as null

  // When userInfo is available, set it in state
  useEffect(() => {
    if (userInfo) {
      setUser(userInfo);
      setLoading(false); // Set loading to false after setting user data
    }
  }, [userInfo]);

  // Show loading state if data is still loading
  if (loading) {
    return <div>Loading...</div>;
  }

  // If user data is available, render the profile
  return (
    <>
      {Array.isArray(user?.user_info) && user?.user_info.length > 0 ? (
        <div className="w-full mr-3 rounded-lg">
          <div className="relative w-full">
            <div className="relative w-full">
              <div className="" style={{ height: 150 }}>
                {!loading && (
                  <img
                    src={user?.user_info[0].cover_image}
                    className="userCard lg_cover m-0"
                  />
                )}
              </div>

              {user?.user_info[0].user_id === app.current_user && (
                <Link
                  to="/settings/profileimage"
                  className="absolute top-3 py-1 hover right-3 w-10 h-10 rounded-full m-auto flex align-center bg-gray-100 justify-center"
                >
                  <CameraIcon />
                </Link>
              )}
            </div>

            <div className="w-20 h-20 mr-3 -mt-10 ml-2 rounded-full overflow-hidden align-center flex justify-start relative">
              <img
                src={user?.user_info[0].profile_image}
                className="w-20 h-20 object-cover rounded-full "
              />
              {user?.user_info[0].user_id === app.current_user && (
                <Link
                  to="/settings/profileimage"
                  className="absolute bottom-0 py-1 rounded-full w-full hover left-0 right-0 m-auto flex align-center bg-gray-100 justify-center"
                >
                  <CameraIcon />
                </Link>
              )}
            </div>
          </div>

          <div className="mt-3">
            <div className="flex align-start mt-3">
              <div className="font-bold text-2xl flex aling-center gap-2">
                {user?.user_info[0].fullname}
                {user?.user_info[0].user_id === app.current_user && (
                  <small>(You)</small>
                )}
              </div>
              {user?.user_info[0].verification_status === "true" && (
                <svg
                  className="verification_icon"
                  style={{ top: 7 }}
                  xmlns="http://www.w3.org/2000/svg"
                  enableBackground="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#e8eaed"
                >
                  <g>
                    <rect fill="none" height="24" width="24"></rect>
                  </g>
                  <g>
                    <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"></path>
                  </g>
                </svg>
              )}
            </div>
            <div className="text text-mute">{username}</div>
            <div className="button-status flex justify-between align-center">
              {user?.user_info[0].availabilityStatus === "notAvailable"
                ? "Not available now"
                : "Available for work"}

              {user?.user_info[0].user_id === app.current_user && (
                <Link
                  to="/settings/set_profile"
                  className="h-8 w-auto flex gap-2 align-center border-1 justify-center hover rounded-full text-center break-keep px-3"
                >
                  <span class="material-icons-round" style={{ fontSize: 12 }}>
                    auto_fix_normal
                  </span>
                  Edit Profile
                </Link>
              )}
            </div>
          </div>

          {/* list skills below */}
          {user?.user_info[0].skills.length > 0 && (
            <>
              <hr className="line_sm mt-5" />
              <div className="flex align-center mt-2 justify-center mb-4">
                <div className="h-6 w-full rounded-full mr-10 align-center justify-center font-bold text-xl">
                  Skills
                </div>
              </div>
              <div className="flex align-center gap-1 flex-wrap">
                {user?.user_info[0].skills.length > 0 &&
                  user?.user_info[0].skills.map((item, index) => (
                    <>
                      {index < 10 && !seeAllSkills && item != "" && (
                        <>
                          {item != "" && (
                            <div className="w-auto bg-gray-100 rounded-full px-4 flex align-center justify-center h-7 text-sm">
                              {item}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}

                {user?.user_info[0].skills.length > 0 &&
                  user?.user_info[0].skills.map((item, index) => (
                    <>
                      {seeAllSkills && (
                        <>
                          {item != "" && (
                            <div className="w-auto bg-gray-100 rounded-full px-4 flex align-center justify-center h-7 text-sm">
                              {item}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}

                {user?.user_info[0].skills.length > 10 && (
                  <div
                    className="hover flex align-center justify-center px-4 text-sm h-7 rounded-full"
                    onClick={() =>
                      seeAllSkills
                        ? setSeeAllSkills(false)
                        : setSeeAllSkills(true)
                    }
                  >
                    {seeAllSkills
                      ? "See less"
                      : "See all " +
                        user?.user_info[0].skills.length +
                        " skill"}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
}

export default ProfileCard;
