import { ArrowRightIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getBusinessJobReportDetails } from "../../db";
import app from "../../config/app";
import TableDummy from "../components/Dummies/TableDummy";

function JobReports({ local, business }) {
  const navigate = useNavigate();
  const [businessProfile, setBusinessProfile] = useState();
  let { id, page, focus } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    if (business) {
      setBusinessProfile(business);
    }
  }, [page, id, local]);

  const [loading, setLoading] = useState(true);
  const [jobReportDetails, setJobReportDetails] = useState({
    jobPostedCount: 0,
    businessName: "",
    applicationCount: 0,
    activeJobsCount: 0,
    currentPrev: "",
    followersCount: 0,
    recentApplications: [[]],
    recentJobsPost: [],
  });

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const dashboard = await getBusinessJobReportDetails(id);
      console.log("Details - > ", dashboard.data);
      setJobReportDetails(dashboard.data);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };
  return (
    <div>
      <div className="flex align-center justify-between py-3">
        <div className="text-xl font-semibold py-3 sm-p-0">Jobs reports</div>
        <Link
          to={`/admin/${id}/postedjobs`}
          className="flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
        >
          <div>View jobs</div>
          <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
            <ArrowRightIcon width={15} height={15} />
          </div>
        </Link>
      </div>
      <div className="grid grid-cols-3 sm-grid-cols-1 align-start justify-between gap-5 cards_conatiner py-4 mt-0">
        {/* show page views */}
        <div className="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
          <div className="flex w-full align-center px-3">
            <div className="d-flex flex-column my-7 flex-1">
              <span className="font-semibold text-4xl">
                {jobReportDetails?.jobPostedCount}
              </span>
              <div className="mt-3">
                <span className="mt-3 text-gray-500">Posted jobs</span>
              </div>
            </div>
            <div className="card_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"></path>
              </svg>
            </div>
          </div>
          <div className="px-3 pl-1 border-top pt-2">
            <Link
              to={`/admin/${id}/postedjobs`}
              className="flex align-center justify-between rounded-full gap-2 hover px-3 pr-0 w-auto"
            >
              <div>View jobs</div>
              <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                <ArrowRightIcon width={15} height={15} />
              </div>
            </Link>
          </div>
        </div>
        {/* show engagements */}
        <div className="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
          <div className="flex w-full align-center px-3">
            <div className="d-flex flex-column my-7 flex-1">
              <span className="font-semibold text-4xl">
                {jobReportDetails?.applicationCount}
              </span>
              <div className="mt-3">
                <span className="mt-3 text-gray-500">Applications</span>
              </div>
            </div>
            <div className="card_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
              >
                <g>
                  <rect fill="none" height="24" width="24"></rect>
                </g>
                <g>
                  <g>
                    <g>
                      <path d="M17,12c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S19.76,12,17,12z M19.15,17.85l-1.79,1.79c-0.2,0.2-0.51,0.2-0.71,0 l-1.79-1.79C14.54,17.54,14.76,17,15.21,17h1.29v-2.5c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V17h1.29 C19.24,17,19.46,17.54,19.15,17.85z"></path>
                    </g>
                    <g>
                      <path d="M17,10c0.1,0,0.19,0.01,0.28,0.01L3,4v6l8,2l-8,2v6l7-2.95c0-0.02,0-0.03,0-0.05C10,13.13,13.13,10,17,10z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="px-3 pl-1 border-top pt-2">
            <Link
              to={`/admin/${id}/applications`}
              className="flex align-center justify-between rounded-full gap-2 hover px-3 pr-0 w-auto"
            >
              <div>All applications</div>
              <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                <ArrowRightIcon width={15} height={15} />
              </div>
            </Link>
          </div>
        </div>
        {/* show page followers */}
        <div className="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
          <div className="flex w-full align-center px-3">
            <div className="d-flex flex-column my-7 flex-1">
              <span className="font-semibold text-4xl">
                {jobReportDetails?.activeJobsCount}
              </span>
              <div className="mt-3">
                <span className="mt-3 text-gray-500">Active jobs</span>
              </div>
            </div>
            <div className="card_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
              >
                <g>
                  <rect fill="none" height="24" width="24"></rect>
                </g>
                <g>
                  <g>
                    <path d="M18,11c1.49,0,2.87,0.47,4,1.26V8c0-1.11-0.89-2-2-2h-4V4c0-1.11-0.89-2-2-2h-4C8.89,2,8,2.89,8,4v2H4 C2.89,6,2.01,6.89,2.01,8L2,19c0,1.11,0.89,2,2,2h7.68C11.25,20.09,11,19.08,11,18C11,14.13,14.13,11,18,11z M10,4h4v2h-4V4z"></path>
                    <path d="M18,13c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S20.76,13,18,13z M19.65,20.35l-2.15-2.15V15h1v2.79l1.85,1.85 L19.65,20.35z"></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="px-3 pl-1 border-top pt-2">
            <Link
              to={`/admin/${id}/postedjobs`}
              className="flex align-center justify-between rounded-full gap-2 hover px-3 pr-0 w-auto"
            >
              <div>Open jobs</div>
              <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                <ArrowRightIcon width={15} height={15} />
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* other card */}

      <div className="w-full">
        <div className="overflow-hidden w-full bg-gray-100 rounded-xl py-5 pt-2">
          {/* all settings goes here */}
          <div className="px-4  mt-5 flex align-center justify-between">
            <div className="text-xl font-semibold">Recent applications</div>
            {jobReportDetails?.recentApplications[0]?.length > 0 &&
              !loading && (
                <Link
                  to={`/admin/${id}/applications`}
                  className="flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
                >
                  <div>View all</div>
                  <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                    <ArrowRightIcon width={15} height={15} />
                  </div>
                </Link>
              )}
          </div>
          <div className="applicants">
            <div className="table-content">
              <div className="wrap-applicants table-responsive">
                {jobReportDetails?.recentApplications[0]?.length == 0 &&
                  !loading && (
                    <div className="mt-2 mb-2 px-4">
                      <div className="flex align-center">
                        <img
                          src="/images/beam-woman-meditating-in-lotus-position.gif"
                          className="w-36 max-w-36 min-w-36 h-auto sm_image"
                          with="50"
                        />
                        <div className="px-3">
                          <div className="font-bold text-lg">
                            No applications
                          </div>
                          <div>
                            When you apply for a job it will be shown here.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {loading && <TableDummy />}

                <table className="table-auto">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job</th>
                      <th>Applicant</th>
                      <th>Profession</th>
                      <th>Application date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobReportDetails?.recentApplications[0]?.length > 0 &&
                      jobReportDetails?.recentApplications[0]?.map(
                        (item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.job_details[0].row.job_name}</td>
                            <td>{item.user_profile[0].fullname}</td>
                            <td>{item.user_profile[0].current_job_title}</td>
                            <td>{item.row.date_time}</td>
                            <td>
                              {item.row.status == "false"
                                ? "Pending"
                                : "Accepted"}
                            </td>
                          </tr>
                        )
                      )}
                    {/* */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-4">
        <div className="overflow-hidden w-full bg-gray-100 rounded-xl py-5 pt-2">
          {/* all settings goes here */}
          <div className="px-4  mt-5 flex align-center justify-between">
            <div className="text-xl font-semibold">Recent jobs</div>
            {jobReportDetails?.recentJobsPost.length > 0 && !loading && (
              <Link
                to={`/admin/${id}/postedjobs`}
                className="flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
              >
                <div>View all</div>
                <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                  <ArrowRightIcon width={15} height={15} />
                </div>
              </Link>
            )}
          </div>
          <div className="applicants">
            <div className="table-content">
              <div className="wrap-applicants table-responsive">
                {jobReportDetails?.recentJobsPost.length == 0 && !loading && (
                  <div className="mt-2 mb-2">
                    <div className="flex align-center px-4">
                      <img
                        src="/images/beam-sitting-young-woman.png"
                        className="w-36 max-w-36 min-w-36 h-auto sm_image"
                        with="50"
                      />
                      <div className="px-3">
                        <div className="font-bold text-lg">
                          No jobs created yet!!
                        </div>
                        <div>
                          All jobs you create and post will appear here.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {loading && <TableDummy />}

                <table className="table-auto">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job/Gig</th>
                      <th>Applications</th>
                      <th className="center">Deadline</th>
                      <th className="center">Date posted</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobReportDetails?.recentJobsPost.length > 0 &&
                      jobReportDetails?.recentJobsPost.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.row.job_name}</td>
                          <td>
                            {item.row.applications == 0
                              ? "No application"
                              : item.row.applications + " application"}
                          </td>
                          <td>{item.row.date_time}</td>
                          <td>{item.row.applicant_deadline}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobReports;
