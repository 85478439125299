import React, { useState } from "react";

function Skills({ data }) {
  const [seeAllSkills, setSeeAllSkills] = useState(false);
  return (
    <div className="p-2">
      {data.skills.length == 0 && (
        <ul className=" space-y-4 mt-2 text-sm">
          <div className="mt-2 mb-2">
            <div className="flex align-center">
              <img
                src="/images/willowy-teamwork-with-papers-at-the-office-1.png"
                className="w-48 h-auto object-contain"
                with={50}
              />
              <div className="px-3">
                <div>No skills added yet.</div>
              </div>
            </div>
          </div>
        </ul>
      )}
      <div className="flex align-center gap-1 flex-wrap">
        {data?.skills.length > 0 &&
          data?.skills.map((item, index) => (
            <>
              {index < 15 && !seeAllSkills && item != "" && (
                <>
                  {item != "" && (
                    <div className="w-auto bg-gray-100 rounded-full px-4 flex align-center justify-center h-7 text-sm">
                      {item}
                    </div>
                  )}
                </>
              )}
            </>
          ))}

        {data?.skills.length > 0 &&
          data?.skills.map((item, index) => (
            <>
              {seeAllSkills && (
                <>
                  {item != "" && (
                    <div className="w-auto bg-gray-100 rounded-full px-4 flex align-center justify-center h-7 text-sm">
                      {item}
                    </div>
                  )}
                </>
              )}
            </>
          ))}

        {data?.skills.length > 15 && (
          <div
            className="hover flex align-center justify-center px-4 text-sm h-7 rounded-full"
            onClick={() =>
              seeAllSkills ? setSeeAllSkills(false) : setSeeAllSkills(true)
            }
          >
            {seeAllSkills
              ? "See less"
              : "See all " + data?.skills.length + " skill"}
          </div>
        )}
      </div>
    </div>
  );
}

export default Skills;
