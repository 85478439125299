import React, { useEffect, useState } from "react";
import { countries } from "../../../config/countries";
import { useParams } from "react-router-dom";
import {
  getBusinessProfile,
  getPageAccessList,
  updateBusinessContactInfo,
  updateLocalStorage,
} from "../../../db";
import Responce from "../../components/Responce";
import app from "../../../config/app";

function ContactInfo({ local, business, updateLocal }) {
  const [businessProfile, setBusinessProfile] = useState();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { id, page, focus } = useParams();
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    country: "",
    physical_address: "",
    website: "",
  });

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const info = await getBusinessProfile(id);
      console.log("Business info - > ", info.business_info[0]);
      setBusinessProfile(info.business_info[0]);
      if (info.r_status) {
        setFormData({
          email: info.business_info[0].email,
          phone: info.business_info[0].phone,
          country: info.business_info[0].country,
          physical_address: info.business_info[0].physical_address,
          website: info.business_info[0].website,
        });
      }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await updateBusinessContactInfo(id, formData);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      // console.log(responce_card);
    }
    const list = await getPageAccessList(app.current_user, 30);
    updateLocalStorage({ type: "updatepage", data: list });
    updateLocal(list);
    console.log("Form submitted res:", res);
  };

  return (
    <div className="p-3 sm-p-0">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Contact info</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5">
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="country">
                Select country
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">public</span>
                </div>
                <select
                  className="form-control w-full relative has_icon"
                  name="country"
                  id="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="">Select a country</option>
                  {Object.keys(countries).map((countryCode) => (
                    <option
                      key={countryCode}
                      value={countries[countryCode].code}
                    >
                      {countries[countryCode].name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="phone">
                Phone number
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">phone</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="phone"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone number without country code"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="email">
                Email address
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">mail</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your email address"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="website">
                Website
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">public</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="url"
                  name="website"
                  id="website"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="eg. www.yourwebsite.com"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="address">
                Physical address (Optional)
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">pin_drop</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="physical_address"
                  id="physical_address"
                  value={formData.physical_address}
                  onChange={handleChange}
                  placeholder="Physical address"
                />
              </div>
            </div>
          </div>
          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      {showResponceCard && responceCard}
    </div>
  );
}

export default ContactInfo;
