import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import CreatePost from "./components/CreatePost";
import { useNavigate, useParams } from "react-router-dom";
import Feed from "./components/Feed";
import TopUserMenu from "./components/TopUserMenu";
import GeneralMidHeader from "./components/GeneralMidHeader";
import app from "../config/app";
import { getDiscoverFollowList, getFeedList } from "../db";
import VibeDummy from "./components/Dummies/VibeDummy";
import CopyRight from "./components/CopyRight";
import AdvertRight from "./components/AdvertRight";

import FollowListReused from "./components/FollowListReused";
import VibePopup from "./VibePopup";

function Home({ local }) {
  const [usersList, setUsersList] = useState([]);
  const [vibeList, setVibeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const { vibeid, name } = useParams();
  const navigate = useNavigate();

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - 100 &&
      !loading &&
      hasMore
    ) {
      loadMore();
    }
  }, [loading, hasMore]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);
    window.addEventListener("scroll", debouncedHandleScroll);

    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [handleScroll]);

  const fetchFollowListData = async () => {
    try {
      const data = await getDiscoverFollowList(5, 0);
      setUsersList(data.users);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const fetchVibeListData = async (feedType) => {
    if (loading) return;
    setLoading(true);
    try {
      const data = await getFeedList(feedType, 10, offset);
      console.log("Log feed", data);
      if (data.vibes && data.vibes.length > 0) {
        const existingVibeIds = new Set(
          vibeList.map((vibe) => vibe.row.activity_id)
        );

        const newVibes = data.vibes.filter(
          (newVibe) => !existingVibeIds.has(newVibe.row.activity_id)
        );

        if (newVibes.length > 0) {
          setVibeList((prevVibes) => [...prevVibes, ...newVibes]);
        }

        setOffset((prevOffset) => prevOffset + 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching vibes:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = useCallback(() => {
    if (name) {
      fetchVibeListData(name);
    } else {
      fetchVibeListData("trending");
    }
  }, [name, offset]);

  useEffect(() => {
    setVibeList([]);
    setOffset(0);
    setHasMore(true);
    if (name) {
      fetchVibeListData(name);
    } else {
      fetchVibeListData("trending");
    }
  }, [name]);

  useEffect(() => {
    fetchFollowListData();
  }, []);

  const updateCommentsCount = (count) => {};

  return (
    <>
      <div className="min-h-screen flex-1">
        <GeneralMidHeader onClickTab={(data) => fetchVibeListData(data)} />
        <div className="p-4 px-0 sm-p-0 feed_list_cont pt-0">
          <div className="px-0 sm-p-1">
            <CreatePost />
          </div>
          <hr className="line" />
          {Array.isArray(vibeList) && vibeList.length > 0 && (
            <div className="p-0">
              {vibeList.map((item) => (
                <Feed data={item} key={item.row.activity_id} local={local} />
              ))}
            </div>
          )}
          {/* {loading && <div className="text-center">Loading more vibes...</div>} */}
          <div className="mb-5">
            <VibeDummy />
          </div>
        </div>
      </div>

      {vibeid && (
        <div className="vibe_overlay">
          <VibePopup
            vibeid={vibeid}
            local={local}
            onCommentSubmit={(count) => updateCommentsCount(count)}
          />
        </div>
      )}

      {window.innerWidth > app.mobile_width && (
        <div className="min-h-screen flex right_cont">
          <TopUserMenu local={local} />
          {/* <AdvertRight /> */}
          <div className="p-4">
            <FollowListReused more="/discover/people" />
            <hr className="line" />
            <div className="mt-7 mb-16">
              <CopyRight />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
