import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import app from "../../config/app";
import { submitFollowUser } from "../../db";
import PostContent from "./PostContent";

function FollowHorizontal({ data, simple, className = "" }) {
  const [followingStatus, setFollowingStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setFollowingStatus(data?.follow_status);
    setLoading(false);
  }, []);

  const handleFollow = async (activity_id) => {
    followingStatus ? setFollowingStatus(false) : setFollowingStatus(true);
    // alert(username);
    try {
      const data = await submitFollowUser(activity_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <>
      <div
        key={data?.id}
        className={`flex py-1 relative follow_horizontal_cont ${className} ${
          !simple ? "mt-3" : "my-0"
        }`}
      >
        <div className="h-11 min-w-11 bg-gray-200 mr-3 rounded-full">
          <Link to={`/profile/${data?.username}/vibes`}>
            <img
              src={data?.profile_image}
              className="h-11 min-w-11 rounded-full object-cover"
            />
          </Link>
        </div>
        <div className="w-full">
          <div className="flex align-center w-full">
            <div className="h-auto w-full mr-4 rounded-full">
              <Link to={`/profile/${data?.username}/vibes`}>
                <div
                  className={`font-semibold ${
                    !simple && "flex align-center gap-1"
                  }`}
                >
                  <div className="flex align-start gap-1 text-md">
                    <div>{data?.fullname}</div>

                    {data?.verification_status == "true" && (
                      <svg
                        className="verification_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#e8eaed"
                        style={{ marginLeft: 0, width: 15, height: 15 }}
                      >
                        <g>
                          <rect fill="none" height="24" width="24"></rect>
                        </g>
                        <g>
                          <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"></path>
                        </g>
                      </svg>
                    )}
                  </div>

                  {app.current_user == data?.user_id ? (
                    <small> (You) </small>
                  ) : (
                    ""
                  )}

                  {data?.current_job_title != "" && !simple && (
                    <div className="text relative  text-mute text-sm">
                      @{data?.username}
                    </div>
                  )}
                </div>
                {data?.current_job_title == "" && (
                  <div className="text relative  text-mute text-sm">
                    {data?.username}
                  </div>
                )}
                {data?.current_job_title != "" && (
                  <div className="text relative text-sm text-mute">
                    {data?.current_job_title}
                  </div>
                )}
              </Link>
            </div>

            {app.current_user != data?.activity_id && (
              <div
                className={`h-10 w-40 rounded-full font-normal align-center justify-center text-center flex follow_btn ${
                  followingStatus ? "following" : "follow"
                }`}
                onClick={() => handleFollow(data?.activity_id)}
                // style={{ width: 90 }}
              >
                {followingStatus ? "Following" : "Follow"}
              </div>
            )}
          </div>
          {!simple && (
            <>
              {/* <Link to={`/profile/${data?.username}/vibes`}> */}
              <PostContent text={data?.bio} contentStyle={{}} />
              {/* <div className="text relative -top-1 bio_desc">{data?.bio}</div> */}

              {/* </Link> */}
            </>
          )}
        </div>
      </div>
      {!simple && <hr className="line_sm" />}
    </>
  );
}

export default FollowHorizontal;
