import React from "react";
import { useParams } from "react-router-dom";
import JobDetailsComponent from "../../Jobs/JobDetailsComponent";

function JobInfo({ local }) {
  let { id, focus, tab } = useParams();
  return (
    <div className="job_details_as_admin">
      <JobDetailsComponent local={local} id={focus} viewAs="admin" />
    </div>
  );
}

export default JobInfo;
