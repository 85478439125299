import React from "react";

function LoaderBar() {
  return (
    <div className="loader_bar">
      <svg
        version="1.1"
        id="pagesloading"
        x="0px"
        y="0px"
        width="15px"
        height="1px"
        viewBox="0 0 15 1"
        enable-background="new 0 0 15 1"
        preserveAspectRatio="none"
      >
        <circle r="1px" cx="0px" cy="0.5px" fill="#ffdeae6e">
          <animate
            attributeName="r"
            begin="0.2s"
            from="11"
            to="0"
            dur="1.2s"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="translate"
            values="-35 0;2 0;17 0;20 0"
            dur="1.2s"
            begin="0"
            repeatCount="indefinite"
          ></animateTransform>{" "}
        </circle>
      </svg>
    </div>
  );
}

export default LoaderBar;
