import React, { useEffect, useState } from "react";
import HubSliderDummy from "../components/Dummies/HubSliderDummy";
import { getDiscoverSchoolsList, getHubToJoinListGrouped } from "../../db";
import BusinessSlide from "../components/BusinessSlide";
import Loader from "../components/Loader";

function DiscoverSchools() {
  const [allPages, setAllPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if there's more data to load

  useEffect(() => {
    fetchPagesListData(); // Fetch initial data
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
  }, [offset]);

  const fetchPagesListData = async () => {
    // if (!hasMore || loading) return; // Prevent fetching if already loading or no more pages
    setLoading(true);
    try {
      const data = await getDiscoverSchoolsList(6, offset); // Ensure this returns the correct data structure

      // Check if no more pages to load
      if (data && data.schoolpages && data.schoolpages.length === 0) {
        setHasMore(false); // Stop loading if no more pages are returned
      }

      // Append new pages to the list
      setAllPages((prevPages) => [...prevPages, ...(data.schoolpages || [])]);
    } catch (error) {
      console.error("Error fetching pages:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  // Handle scroll and load more pages if at bottom
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight &&
      hasMore &&
      !loading
    ) {
      setOffset((prevOffset) => prevOffset + 10); // Increase offset to load more pages
    }
  };

  const loadMore = () => {
    setOffset((prevOffset) => prevOffset + 10);
  };

  return (
    <div className="overflow-hidden">
      {loading && allPages.length === 0 && (
        <div className="flex gap-0 pt-5 sm-grid-cols-1 sm-p-1">
          <HubSliderDummy />
          <HubSliderDummy />
          <HubSliderDummy />
        </div>
      )}

      {/* Render hubs if available */}
      {allPages.length > 0 && (
        <>
          <div className="flex align-center justify-between px-4">
            <div className="text-xl font-bold">Discover schools</div>
          </div>

          <div className="slider grid grid-cols-3 align-start gap-4 py-5 flex-wrap px-4 sm-grid-cols-1 sm-p-1">
            {allPages.map((item, index) => (
              <BusinessSlide key={index} simple={true} data={item} />
            ))}
          </div>
        </>
      )}

      {/* Show load more button or message */}
      <div className="align-center justify-center w-full py-5 flex">
        {hasMore ? (
          <button
            className={`${!loading ? "btn" : ""}`}
            onClick={loadMore}
            disabled={loading}
          >
            {loading ? <Loader /> : "Load more"}
          </button>
        ) : (
          <div>No more businesses to load</div>
        )}
      </div>
    </div>
  );
}

export default DiscoverSchools;
