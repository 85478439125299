import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getJobInfo, updateJobSkills } from "../../../../db";
import app from "../../../../config/app";
import Responce from "../../../components/Responce";
import { XMarkIcon } from "@heroicons/react/16/solid";

function Skills({ local, business }) {
  const navigate = useNavigate();
  let { id, page, focus, focus_id } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;
  const [jobInfo, setJobInfo] = useState();
  const [filter, setFilter] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const skills = app.skills; // Use the skills from the app config
  const [mySkills, setMySkills] = useState([]);
  const [error, setError] = useState(null);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  useEffect(() => {
    const fetchDetails = async () => {
      if (focus_id) {
        const res = await getJobInfo(focus_id, id);
        setJobInfo(res);
        console.log("Job details", res);
        if (res.job_info[0].row.skills) {
          const delimiter = ",";
          const result = res.job_info[0].row.skills || [];
          setMySkills(result);
        } else {
          setMySkills([]); // Handle the case when skills are empty
        }
        // console.log(result);
      }
    };
    fetchDetails();
  }, [focus, currentPath, focus_id, id, page]);

  const toggleSkills = (skill) => {
    if (mySkills.includes(skill)) {
      setMySkills(mySkills.filter((item) => item !== skill));
    } else {
      setMySkills([...mySkills, skill]);
    }
  };

  const filteredSkills = () => {
    return skills.filter(
      (item) =>
        item.skill.toLowerCase().includes(filter.toLowerCase()) ||
        item.job_category.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const groupedSkills = () => {
    return filteredSkills().reduce((acc, curr) => {
      if (!acc[curr.job_category]) {
        acc[curr.job_category] = [];
      }
      acc[curr.job_category].push(curr);
      return acc;
    }, {});
  };

  const handleSaveSkills = async () => {
    setIsSaving(true);
    setError(null);
    if (focus_id) {
      const res = await updateJobSkills(focus_id, id, mySkills);
      console.log("Job update res", res);

      if (res) {
        setShowResponceCard(true);
        setTimeout(() => {
          setShowResponceCard(false);
        }, 3000);
        setResponceCard(
          <Responce data={{ text: res.responce, status: res.r_status }} />
        );
        setJobInfo(res.job_info);
        console.log("Jobinfo", res.job_info);
        if (res.r_status == true) {
          navigate(`/admin/${id}/createjob/moreinfo/${focus_id}`);
        }
      }
    }

    // console.log(mySkills);
    // Optionally, add logic to submit skills to the server
  };

  return (
    <div className="border-1 mt-3 px-8 py-5 rounded-xl">
      <div className="text-xl my-2">Job skills</div>
      <div className="py-2 pt-2 pb-0 sticky tags_filter flex align-center gap-3">
        <div className="py-3 w-full">
          <input
            type="text"
            placeholder="Filter skills..."
            className="mb-0 p-2 border border-gray-300 rounded-full w-full px-6"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <button type="button" onClick={handleSaveSkills} className="btn">
          {isSaving ? "Saving..." : "Save & continue"}
        </button>
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {mySkills.length > 0 ? (
        <div className="mb-6">
          <div className="text-lg font-bold mb-2 px-2">Select</div>
          <div className="flex flex-wrap">
            {mySkills.map((skill, idx) => (
              <div
                key={idx}
                className={`w-auto h-8 px-4 pr-0 m-1 rounded-full flex items-center hover border-1 justify-between gap-2 cursor-pointer `}
                onClick={() => toggleSkills(skill)}
              >
                <div>{skill}</div>
                <div className="w-8 min-w-8 h-8 min-h-8 rounded-full flex align-center justify-center bg-gray-100">
                  <XMarkIcon />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}

      {Object.entries(groupedSkills()).map(([category, skills], index) => (
        <div key={index} className="mb-6">
          <div className="text-lg font-bold mb-2 px-2">
            {category.toUpperCase()}
          </div>
          <div className="flex flex-wrap">
            {skills.map((skill, idx) => (
              <div
                key={idx}
                className={`w-auto h-8 px-4 m-1 rounded-full flex items-center justify-center cursor-pointer ${
                  mySkills.includes(skill.skill)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
                onClick={() => toggleSkills(skill.skill)}
              >
                {skill.skill}
              </div>
            ))}
          </div>
        </div>
      ))}

      {showResponceCard && responceCard}
    </div>
  );
}

export default Skills;
