import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getProfileFromIndexedDB } from "../../db";

function MobileFooterMenu() {
  const [username, setUsername] = useState(false);
  const menu = [
    {
      name: "Home",
      icon: "home",
      path: "/",
    },
    {
      name: "Discover",
      icon: "explore",
      path: "/Discover",
    },
    {
      name: "",
      icon: "add",
      path: "/CreatePost",
    },
    {
      name: "Updates",
      icon: "notifications",
      path: "/notifications",
    },
    {
      name: "Profile",
      icon: "person",
      path: "/profile/" + username,
    },
  ];

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const fetchProfileInfo = async () => {
    const profile = await getProfileFromIndexedDB();
    setUsername(profile?.username || "");
  };

  return (
    <div className="fixed bottom-0 z-10 bg-white w-full align-center justify-between flex mobile_footer_menu">
      {menu.map((item, index) => (
        <Link
          to={item.path}
          key={index}
          className={
            currentPath == item.path
              ? "flex active align-center justify-center flex-column w-full hover cursor-pointer py-2"
              : "flex align-center justify-center flex-column w-full hover cursor-pointer py-2"
          }
        >
          {item.path != "/CreatePost" ? (
            <span
              className={
                currentPath == item.path
                  ? "material-icons-round"
                  : "material-symbols-outlined"
              }
            >
              {item.icon}
            </span>
          ) : (
            <div className="material-icons-round create_post_icon">add</div>
          )}

          <div className="text-sm text-mute">{item.name}</div>
        </Link>
      ))}
    </div>
  );
}

export default MobileFooterMenu;
