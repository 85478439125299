import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFeedListBookmark, getSavedJobListBookmark } from "../../db";
import VibePopup from "../VibePopup";
import VibeDummy from "../components/Dummies/VibeDummy";
import Feed from "../components/Feed";
import JobList from "../components/JobList";
import JobDetailsComponent from "../Jobs/JobDetailsComponent";

function BookMarkJobs({ local = "" }) {
  const [usersList, setUsersList] = useState([]);
  const [savedJobList, setSavedJobsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const navigate = useNavigate();
  let { dataid } = useParams();

  // Debounced scroll handler
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - 100 && // Adjust trigger point
      !loading
    ) {
      loadMore();
    }
  }, [loading]);

  // Set up scroll event listener with debounce
  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200); // Debounce with 200ms delay
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [handleScroll]);

  // Fetch vibe list data
  const fetchSavedJobsListData = async () => {
    if (loading) return; // Prevent fetching if currently loading
    setLoading(true); // Start loading

    try {
      const data = await getSavedJobListBookmark(10, offset);
      console.log("Jobs---->", data.jobs);
      if (data.jobs && data.jobs.length > 0) {
        // Create a Set of existing vibe IDs for quick lookup
        const existingJobIds = new Set(
          savedJobList.map((job) => job.row?.activity_id)
        );

        // Filter out duplicates
        const newJobs = data.jobs.filter(
          (newJob) => !existingJobIds.has(newJob.row?.activity_id)
        );

        if (newJobs.length > 0) {
          setSavedJobsList((prevJobs) => [...prevJobs, ...newJobs]); // Append new unique vibes to the existing list
        }

        // Increase offset for next fetch
        setOffset((prevOffset) => prevOffset + 10);
      } else {
        // Optionally handle the case where no vibes are returned
        console.log("No more vibes available."); // Optional: remove if not needed
      }
    } catch (error) {
      console.error("Error fetching vibes:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Load more vibes
  const loadMore = () => {
    fetchSavedJobsListData();
  };

  // Update comments count (placeholder function)
  const updateCommentsCount = (count) => {};

  // Initial data fetch
  useEffect(() => {
    fetchSavedJobsListData();
  }, []);

  return (
    <>
      <div className="px-0 sm-p-0 feed_list_cont pt-5">
        {Array.isArray(savedJobList) && savedJobList.length > 0 && (
          <div className="p-1">
            {savedJobList.map((item, index) => (
              <>
                {index > 0 && (
                  <div className="line" style={{ margin: 0 }}></div>
                )}
                <JobList data={item} local={local} from="/bookmarks/jobs/" />
              </>
            ))}
          </div>
        )}
        {/* Loading indicator */}
        {/* {loading && (
          <div className="text-center">Loading more saved jobs...</div>
        )} */}
        {savedJobList.length == 0 && !loading && (
          <div className="w-full py-4 flex align-center justify-center px-3 flex-column">
            <img
              src="/images/coworking-woman-doing-online-shopping-at-home.gif"
              className="w-48 object-contain"
            />
            <div className="text-xl font-semibold">No jobs saved</div>
          </div>
        )}
        {loading && (
          <div className="mb-5">
            <VibeDummy />
          </div>
        )}

        {dataid && (
          <div className="vibe_overlay align-start justify-center flex py-5">
            <div className="min_popup_container bg-gray-200 rounded-xl overflow-hidden">
              <div className="flex align-center justify-between px-8 border-bottom py-3">
                <div className="flex text-2xl font-bold">About job</div>
                <div className="flex">
                  <Link
                    to={"/bookmarks/jobs/"}
                    className="flex align-center justify-center hover rounded-full h-10 w-10"
                  >
                    <span class="material-symbols-outlined">close</span>
                  </Link>
                </div>
              </div>
              <div>
                <JobDetailsComponent local={local} id={dataid} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BookMarkJobs;
