import React, { useState } from "react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/16/solid";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

const OneSlider = ({ children }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const totalSlides = React.Children.count(children);

  // Calculate the maximum index based on the total  number of slides
  const maxSlideIndex = totalSlides - 1;

  const handleSlideRight = () => {
    setSlideIndex((prevIndex) =>
      prevIndex < maxSlideIndex ? prevIndex + 1 : prevIndex
    );
  };

  const handleSlideLeft = () => {
    setSlideIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  return (
    <div className="relative overflow-hidden w-full">
      {/* Left Arrow */}
      <div
        className={`absolute arrow_prev left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer ${
          slideIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleSlideLeft}
        style={{ opacity: slideIndex === 0 ? 0.01 : 1 }}
      >
        <ChevronLeftIcon />
      </div>

      {/* Slider Container */}
      <div
        className="slider flex transition-transform duration-300 ease-in-out"
        style={{
          transform: `translateX(-${slideIndex * 100}%)`, // Slide by 100% of the container width
        }}
      >
        {/* Children (Slides) */}
        {React.Children.map(children, (child) => (
          <div className="flex-none w-full">
            {" "}
            {/* Each slide takes up 100% width */}
            {child}
          </div>
        ))}
      </div>

      {/* Right Arrow */}
      <div
        className={`absolute arrow_next right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer ${
          slideIndex === maxSlideIndex ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleSlideRight}
        style={{
          opacity: slideIndex === maxSlideIndex ? 0.01 : 1,
        }}
      >
        <ChevronRightIcon />
      </div>

      {/* Dots Indicator */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2 my-4">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full ${
              index === slideIndex ? "bg-orange-500" : "bg-gray-300"
            }`}
            style={{ transition: "background-color 0.3s ease" }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default OneSlider;
