import React, { useState } from "react";

function Verification() {
  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };
  return (
    <div className="p-3 sm-p-1">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold mb-4">Page verification</div>
        <div className="text-xl font-bold my-3">Your page is not verified</div>
        <div className="my-2">Please upload the following documents</div>
        <div className=" mb-10">
          <span className="text-mute ">
            Note that we may call or email you concerning the verification
            process, if you need any help dont hesitate to reach us.
          </span>
          <a
            class="flex align-center justify-between rounded-full w-36 hover pl-3 mt-3 pr-0 border-1"
            href="https://about.vibein.africa/contact/"
            target="_blank"
          >
            <div className="mr-3">Contact us</div>
            <div class="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              <span class="material-symbols-outlined">north_east</span>
            </div>
          </a>
        </div>
        <div className="">
          <div className="">
            <div className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex items-start justify-start gap-6 relative hover sm-flex-col">
              <div className="bg-white rounded-full min-w-10 min-h-10 overflow-hidden">
                <img
                  src="/images/doc2.png"
                  className="min-w-10 min-h-10 w-10 -h-10 object-contain p-1 rounded-sm"
                  alt="Attachment icon"
                />
              </div>
              <div>
                <div className="text-lg font-semibold">
                  Company/Business registration certificate
                </div>
                <div className="text-mute text-sm my-2">
                  This details will help us check if your business exits in
                  ligal terms with the state where you do your operations.
                </div>
                <input
                  type="file"
                  id="registration_certificate"
                  className="display-none"
                />
                <div className="">Read more</div>
              </div>
              <label
                for="registration_certificate"
                className="flex items-start rounded-full gap-3"
              >
                <button className="btn">Upload</button>
              </label>
            </div>
          </div>
          <div className="mt-5">
            <div className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex items-start justify-start gap-6 relative hover sm-flex-col">
              <div className="bg-white rounded-full min-w-10 min-h-10 overflow-hidden">
                <img
                  src="/images/doc2.png"
                  className="min-w-10 min-h-10 w-10 -h-10 object-contain p-1 rounded-sm"
                  alt="Attachment icon"
                />
              </div>
              <div>
                <div className="text-lg font-semibold">
                  Company/Business registration certificate
                </div>
                <div className="text-mute text-sm my-2">
                  This details will help us check if your business exits in
                  ligal terms with the state where you do your operations.
                </div>
                <input
                  type="file"
                  id="registration_certificate"
                  className="display-none"
                />
                <div className="">Read more</div>
              </div>
              <label
                for="registration_certificate"
                className="flex items-start rounded-full gap-3"
              >
                <button className="btn">Upload</button>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Verification;
