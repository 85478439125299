import axios from "axios";
import React, { useState, useEffect } from "react";
import app from "../../config/app";
import CopyRight from "../components/CopyRight";
import {
  saveProfileToIndexedDB,
  getProfileFromIndexedDB,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function Profile({ local }) {
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    username: "",
    jobTitle: "",
    dateOfBirth: "",
    bio: "",
  });

  useEffect(() => {
    const fetchProfile = async () => {
      const savedProfile = await getProfileFromIndexedDB();
      if (savedProfile) {
        setFormData(savedProfile);
      }
    };
    fetchProfile();
  }, [local]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    const responce = await saveProfileToIndexedDB(formData);
    console.log(responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
    updateLocalStorage({ type: "updateProfile", data: formData });
  };

  return (
    <div className="p-7">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Profile setting</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5">
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="fullName">
                Full name
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">person</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="fullName"
                  id="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="eg. John Doe"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="username">
                Username
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">alternate_email</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="username"
                  id="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="eg. johndoe"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="jobTitle">
                Current Job Title
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">contact_mail</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  placeholder="eg. Software Engineer"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="dateOfBirth">
                Date of birth
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">calendar_month</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="date"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="bio">
                Bio
              </label>
              <div className="flex align-center relative mt-3">
                <textarea
                  rows={5}
                  className="form-control w-full relative min-h-40 pt-4 pl-3"
                  name="bio"
                  id="bio"
                  value={formData.bio}
                  onChange={handleChange}
                  placeholder="Write a short bio"
                ></textarea>
              </div>
            </div>
          </div>

          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      <div className="mt-10">
        <hr className="line" />
        <CopyRight />
        {showResponceCard && responceCard}
      </div>
    </div>
  );
}

export default Profile;
