import React from 'react'
import JobList from '../components/JobList'

function DiscoverJobs() {
  return (
    <div>
        <div className='text-xl font-bold mb-8'>Recent jobs</div>
        <JobList/>
        <JobList/>
        <JobList/>
        <JobList/>
        <JobList/>
    </div>
  )
}

export default DiscoverJobs