export const jobCategories = [
    "Accountant",
    "Actuary",
    "Administrative Assistant",
    "Advertising Manager",
    "Aerospace Engineer",
    "Agricultural Engineer",
    "Air Traffic Controller",
    "Aircraft Mechanic",
    "Aircraft Pilot",
    "Anthropologist",
    "App Developer",
    "Architect",
    "Archivist",
    "Art Director",
    "Artist",
    "Arts Administrator",
    "Athletic Trainer",
    "Attorney",
    "Audiologist",
    "Bank Manager",
    "Bartender",
    "Biochemist",
    "Biomedical Engineer",
    "Biostatistician",
    "Bookkeeper",
    "Brand Manager",
    "Building Inspector",
    "Business Analyst",
    "Business Consultant",
    "Business Development Manager",
    "Business Teacher",
    "C++ Developer",
    "CAD Technician",
    "Call Center Manager",
    "Carpenter",
    "Cartographer",
    "Case Manager",
    "Cashier",
    "Chef",
    "Chemical Engineer",
    "Chemist",
    "Chief Executive Officer (CEO)",
    "Chief Financial Officer (CFO)",
    "Chief Operating Officer (COO)",
    "Childcare Worker",
    "Chiropractor",
    "Civil Engineer",
    "Claims Adjuster",
    "Clinical Psychologist",
    "Clinical Research Coordinator",
    "Coach",
    "Commercial Pilot",
    "Communication Specialist",
    "Community Health Worker",
    "Compensation and Benefits Manager",
    "Compliance Officer",
    "Computer Programmer",
    "Computer Scientist",
    "Computer Support Specialist",
    "Construction Manager",
    "Content Manager",
    "Content Writer",
    "Copy Editor",
    "Copywriter",
    "Corporate Trainer",
    "Correctional Officer",
    "Cost Estimator",
    "Court Reporter",
    "Credit Analyst",
    "Crime Analyst",
    "Criminal Investigator",
    "Customer Service Manager",
    "Customer Service Representative",
    "Cyber Security Analyst",
    "Data Analyst",
    "Data Engineer",
    "Data Scientist",
    "Database Administrator",
    "Dentist",
    "Dermatologist",
    "Desktop Support Specialist",
    "Dietitian",
    "Digital Marketing Manager",
    "Director of Operations",
    "Doctor",
    "Economist",
    "Editor",
    "Electrical Engineer",
    "Electrician",
    "Elementary School Teacher",
    "Emergency Management Director",
    "Emergency Medical Technician (EMT)",
    "Energy Consultant",
    "Engineering Manager",
    "Environmental Engineer",
    "Environmental Scientist",
    "Event Coordinator",
    "Event Planner",
    "Executive Assistant",
    "Family Therapist",
    "Fashion Designer",
    "Film Editor",
    "Financial Advisor",
    "Financial Analyst",
    "Financial Manager",
    "Firefighter",
    "Fitness Trainer",
    "Flight Attendant",
    "Florist",
    "Food Scientist",
    "Forensic Scientist",
    "Freelance Artist",
    "Freelance Graphic Designer",
    "Freelance Photographer",
    "Freelance Translator",
    "Freelance Writer",
    "Fundraiser",
    "Game Developer",
    "General Manager",
    "Genetic Counselor",
    "Geographer",
    "Geologist",
    "Graphic Designer",
    "Hairdresser",
    "Health Educator",
    "Healthcare Administrator",
    "Heating, Ventilation, and Air Conditioning (HVAC) Technician",
    "High School Teacher",
    "Historian",
    "Home Health Aide",
    "Hotel Manager",
    "Human Resources Manager",
    "Illustrator",
    "Industrial Designer",
    "Industrial Engineer",
    "Information Security Analyst",
    "Information Technology (IT) Manager",
    "Instructional Coordinator",
    "Insurance Agent",
    "Interior Designer",
    "Interpreter",
    "Investment Banker",
    "IT Support Specialist",
    "Java Developer",
    "Journalist",
    "Judge",
    "Laboratory Technician",
    "Land Surveyor",
    "Landscape Architect",
    "Lawyer",
    "Librarian",
    "Logistician",
    "Machine Learning Engineer",
    "Magazine Editor",
    "Maintenance Worker",
    "Management Analyst",
    "Manufacturing Engineer",
    "Marine Biologist",
    "Marketing Analyst",
    "Marketing Manager",
    "Massage Therapist",
    "Material Scientist",
    "Mathematician",
    "Mechanical Engineer",
    "Medical Assistant",
    "Medical Laboratory Technician",
    "Medical Records Technician",
    "Medical Transcriptionist",
    "Mental Health Counselor",
    "Microbiologist",
    "Middle School Teacher",
    "Mobile App Developer",
    "Mortgage Loan Officer",
    "Museum Curator",
    "Music Director",
    "Music Teacher",
    "Network Administrator",
    "Network Architect",
    "Nuclear Engineer",
    "Nurse",
    "Nurse Practitioner",
    "Nutritionist",
    "Occupational Therapist",
    "Office Clerk",
    "Office Manager",
    "Operations Manager",
    "Optometrist",
    "Orthodontist",
    "Paralegal",
    "Paramedic",
    "Park Ranger",
    "Personal Trainer",
    "Pharmacist",
    "Pharmacy Technician",
    "Photographer",
    "Physical Therapist",
    "Physician",
    "Physician Assistant",
    "Physicist",
    "Plumber",
    "Police Officer",
    "Political Scientist",
    "Principal",
    "Private Investigator",
    "Product Manager",
    "Production Manager",
    "Project Manager",
    "Property Manager",
    "Psychiatrist",
    "Psychologist",
    "Public Relations Specialist",
    "Publicist",
    "Quality Assurance Manager",
    "Radiologic Technologist",
    "Real Estate Agent",
    "Real Estate Appraiser",
    "Receptionist",
    "Recruiter",
    "Registered Nurse",
    "Reporter",
    "Research Scientist",
    "Restaurant Manager",
    "Retail Manager",
    "Risk Manager",
    "Robotics Engineer",
    "Safety Manager",
    "Sales Manager",
    "Sales Representative",
    "School Counselor",
    "Scientist",
    "Scriptwriter",
    "Security Guard",
    "Social Media Manager",
    "Social Worker",
    "Software Developer",
    "Software Engineer",
    "Software Tester",
    "Speech Therapist",
    "Statistician",
    "Stockbroker",
    "Store Manager",
    "Structural Engineer",
    "Substance Abuse Counselor",
    "Supply Chain Manager",
    "Surgeon",
    "Surveyor",
    "Systems Administrator",
    "Systems Analyst",
    "Tax Advisor",
    "Teacher",
    "Technical Writer",
    "Telecommunications Specialist",
    "Travel Agent",
    "Truck Driver",
    "Tutor",
    "Urban Planner",
    "User Experience (UX) Designer",
    "User Interface (UI) Designer",
    "Veterinarian",
    "Video Editor",
    "Web Developer",
    "Web Designer",
    "Welder",
    "Writer",
    "Youth Worker",
    "Zoologist",
    "Freelance Editor",
    "Freelance Web Developer",
    "Freelance Content Creator",
    "Freelance Social Media Manager",
    "Freelance Marketing Consultant",
    "Freelance Accountant",
    "Freelance App Developer",
    "Freelance Business Consultant",
    "Freelance Copywriter",
    "Freelance Illustrator",
    "Freelance Video Editor",
    "Freelance SEO Specialist",
    "Freelance Virtual Assistant",
    "Freelance Data Analyst",
    "Freelance Financial Consultant",
    "Freelance Interior Designer",
    "Freelance IT Consultant",
    "Freelance Legal Consultant",
    "Freelance Project Manager",
    "Freelance Researcher",
    "Freelance Software Developer",
    "Freelance Translator",
    "Freelance UX/UI Designer",
    "Freelance Writer"
];
