import { ArrowRightIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUserAsHubAdmin,
  getHubDashboardDetails,
  getHubMembers,
  removeMemberHub,
  removeUserHubAdmin,
  updateLocalStorage,
} from "../../db";
import TableDummy from "../components/Dummies/TableDummy";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import app from "../../config/app";
import LoaderBar from "../components/LoaderBar";

const MySwal = withReactContent(Swal);

function Members({ local }) {
  const navigate = useNavigate();
  let { id, page, focus } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPrev, setCurrentPrev] = useState("member");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  useEffect(() => {
    filterMembers();
  }, [searchTerm, members]);

  const fetchData = async () => {
    try {
      const list = await getHubMembers(id);
      setMembers(list.members);
      setCurrentPrev(list.currentPrev);
      setLoading(false);
    } catch (error) {
      console.error("Members:", error);
    }
  };

  const filterMembers = () => {
    const filtered = members.filter((member) =>
      member.user_profile[0].fullname
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredMembers(filtered);
  };

  const addAdmin = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Are you sure add this user as admin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continue",
    });

    if (result.isConfirmed) {
      const res = await addUserAsHubAdmin(id, activity_id);
      updateLocalStorage({ type: "updateHubMembers", data: res });
      fetchData();

      if (res.r_status) {
        MySwal.fire("Remove!", res.responce, "success");
      } else {
        MySwal.fire("Denied!", res.responce, "danger");
      }
    }
  };

  const removeAdmin = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Are you sure to remove admin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continue",
    });

    if (result.isConfirmed) {
      const res = await removeUserHubAdmin(id, activity_id);
      updateLocalStorage({ type: "updateHubMembers", data: res });
      fetchData();

      if (res.r_status) {
        MySwal.fire("Remove!", res.responce, "success");
      } else {
        MySwal.fire("Denied!", res.responce, "danger");
      }
    }
  };

  const removeMember = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Are you sure to remove this member",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continue",
    });

    if (result.isConfirmed) {
      const res = await removeMemberHub(id, activity_id);
      updateLocalStorage({ type: "updateHubMembers", data: res });
      fetchData();
      if (res.r_status) {
        MySwal.fire("Remove!", res.responce, "success");
      } else {
        MySwal.fire("Denied!", res.responce, "danger");
      }
    }
  };

  return (
    <div>
      {loading ? <LoaderBar /> : ""}
      <div className="flex align-center justify-between py-3">
        <div className="text-xl font-semibold py-3">Members</div>
      </div>
      <div className="w-full">
        <div className="overflow-hidden w-full bg-gray-100 rounded-xl py-5 pt-2">
          <div className="applicants">
            <div className="table-content">
              <div className="wrap-applicants table-responsive">
                {!loading && members.length == 0 && (
                  <div className="mt-2 mb-2 px-4">
                    <div className="flex align-center">
                      <img
                        src="/images/beam-woman-meditating-in-lotus-position.gif"
                        className="w-36 max-w-36 min-w-36 h-auto sm_image"
                        with="50"
                      />
                      <div className="px-3">
                        <div className="font-bold text-lg">No members yet</div>
                        <div>Recently joins will appear here.</div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex align-center flex-wrap gap-5 rounded-full my-4 px-4">
                  <div className="search_table flex-1 w-full relative">
                    <span className="material-icons-round absolute top-3 left-4 m-auto h-auto">
                      search
                    </span>
                    <input
                      placeholder="Search members"
                      className="has_icon form-control search_input w-full rounded-full"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                {loading && <TableDummy />}
                {!loading && filteredMembers.length > 0 && (
                  <table className="table-auto">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Join date</th>
                        <th className="left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredMembers.length > 0 &&
                        filteredMembers.map((item, index) => (
                          <tr key={item.activity_id}>
                            <td>{index + 1}</td>
                            <td>{item.user_profile[0].fullname}</td>
                            <td>{item.prev}</td>
                            <td>{item.date_time}</td>
                            <td>
                              <Menu>
                                <MenuButton className="h-10 w-10 bg-gray-100 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-3 post_attachment start_audio_recording">
                                  <span className="material-symbols-outlined">
                                    more_horiz
                                  </span>
                                </MenuButton>

                                <MenuItems
                                  transition
                                  anchor="bottom end"
                                  className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                                >
                                  {item.user_profile[0].activity_id !=
                                    app.current_user && (
                                    <MenuItem>
                                      <Link
                                        to={`/messages/${item.user_profile[0].username}`}
                                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                      >
                                        Message
                                      </Link>
                                    </MenuItem>
                                  )}

                                  <MenuItem>
                                    <Link
                                      to={`/profile/${item.user_profile[0].username}`}
                                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                    >
                                      View profile
                                    </Link>
                                  </MenuItem>

                                  {currentPrev == "owner" &&
                                    item.user_profile[0].activity_id !=
                                      app.current_user && (
                                      <MenuItem>
                                        <div
                                          target="_blank"
                                          onClick={() =>
                                            item.prev == "admin"
                                              ? removeAdmin(item.activity_id)
                                              : addAdmin(item.activity_id)
                                          }
                                          className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                        >
                                          {item.prev == "admin"
                                            ? "Remove admin"
                                            : "Add as admin"}
                                        </div>
                                      </MenuItem>
                                    )}

                                  {(currentPrev == "owner" ||
                                    currentPrev == "admin") &&
                                    item.user_profile[0].activity_id !=
                                      app.current_user && (
                                      <>
                                        <hr className="line_sm" />
                                        <MenuItem>
                                          <div
                                            onClick={() =>
                                              removeMember(item.activity_id)
                                            }
                                            className="group text-red-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                          >
                                            Remove member
                                          </div>
                                        </MenuItem>
                                      </>
                                    )}
                                </MenuItems>
                              </Menu>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Members;
