import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import app from "../../config/app";
import { submitFollowUser } from "../../db";
import Loader from "./Loader";

function UsersSlide({ data, allowLink = true, onFollow, onUnFollow }) {
  const [followingStatus, setFollowingStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setFollowingStatus(data?.follow_status);
    console.log("User profile", data);
    setLoading(false);
  }, [data]);

  const handleFollow = async (activity_id) => {
    setLoading(true);
    if (followingStatus) {
      setFollowingStatus(false);
      onUnFollow(activity_id);
    } else {
      setFollowingStatus(true);
      onFollow(activity_id);
    }

    // alert(username);
    try {
      const data = await submitFollowUser(activity_id);
      console.log("Follow - > ", data);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
      setLoading(false);
    }
  };

  return (
    <div className="w-auto border-solid p-4 py-6 user_card_follow text-center flex flex-col justify-between">
      <div className="">
        <Link
          to={`${allowLink ? `/profile/${data?.username}/vibes` : `#`}`}
          className="flex align-center pb-3 w-full justify-center"
        >
          <div className="relative">
            <img
              src={data?.profile_image}
              className="w-16 h-16 min-w-16 w-16 max-h-16 min-h-16 w-16 max-h-16 rounded-full object-cover"
            />

            {data?.verification_status == "true" && (
              <div
                className="absolute flex align-center justify-center bg-white rounded-full w-4 h-4"
                style={{
                  bottom: 0,
                  right: 5,
                  margin: "auto",
                }}
              >
                <svg
                  className="verification_icon"
                  style={{ margin: 0, top: 0 }}
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#e8eaed"
                >
                  <g>
                    <rect fill="none" height="24" width="24"></rect>
                  </g>
                  <g>
                    <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"></path>
                  </g>
                </svg>
              </div>
            )}
          </div>
        </Link>
        <Link to={`${allowLink ? `/profile/${data?.username}/vibes` : `#`}`}>
          <div className="font-semibold flex align-center">
            {data?.fullname}
          </div>
          <div className="text-sm text-mute">@{data?.username}</div>
          <div className="text-sm">{data?.followers} Followers</div>
          <div className="line"></div>
          {data?.current_job_title != "" && (
            <div className="text-sm">{data?.current_job_title}</div>
          )}
        </Link>
      </div>

      {app.current_user != data?.activity_id && (
        <button
          className={`h-10 w-40 rounded-full font-normal align-center justify-center text-center flex follow_btn mt-3 ${
            followingStatus ? "following" : "follow"
          }`}
          onClick={() => handleFollow(data?.activity_id)}
          // style={{ width: 90 }}
        >
          {loading ? (
            <Loader
              customStyle={{
                width: "16px !important",
                height: "16px !important",
              }}
            />
          ) : (
            <>{followingStatus ? "Following" : "Follow"}</>
          )}
        </button>
      )}
    </div>
  );
}

export default UsersSlide;
