import React, { useEffect, useState, useMemo } from "react";
import { submitUserVote } from "../../db";

export default function PollItem({ data }) {
  const [pollDetails, setPollDetails] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPollDetails(data);
    console.log("Poll details - >", data);
    // Uncomment below line if you want to handle loading state based on data
    // setLoading(false);
  }, [data]);

  const handleVote = async (optionId) => {
    const updatedOptions = pollDetails.options.map((option) => {
      // Increment vote for the selected option
      if (option.activity_id === optionId) {
        return { ...option, votes: option.votes + 1 };
      }
      return option;
    });

    const updatedPollDetails = {
      ...pollDetails,
      options: updatedOptions,
      votes: pollDetails.votes + 1, // Increment total votes
    };

    setPollDetails(updatedPollDetails); // Update local state

    try {
      const response = await submitUserVote(pollDetails?.activity_id, optionId);
      console.log("Vote - >", response);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const totalVotes = pollDetails?.votes || 0;

  const optionsWithPercentage = useMemo(() => {
    return pollDetails?.options.map((option) => ({
      ...option,
      // Use Math.floor to avoid decimals in the percentage calculation
      percentage:
        totalVotes > 0 ? Math.floor((option.votes / totalVotes) * 100) : 0,
    }));
  }, [pollDetails, totalVotes]);

  return (
    <div>
      <div className="px-2 -mt-2">
        <div className="mb-2">{pollDetails?.poll_title}</div>
        <div className="flex flex-col gap-2">
          {optionsWithPercentage?.length > 0 &&
            optionsWithPercentage.map((option, index) => (
              <div
                key={index}
                onClick={() => handleVote(option.activity_id)}
                className="h-12 border-1 rounded-full flex align-center px-6 relative overflow-hidden py-1 justify-between cursor-pointer"
              >
                <div
                  className="poll_option_overlay bg-gray-100 h-12 top-0 bottom-0 left-0 rounded-full m-auto"
                  style={{ width: option.percentage + "%" }}
                ></div>
                <div className="z-10">{option.title}</div>
                <div className="text-sm z-10">
                  {option.percentage > 0 ? option.percentage + "%" : "0%"}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
