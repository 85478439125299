import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserFollowers } from "../../db";
import FollowHorizontal from "./FollowHorizontal";
import FollowHorizontalDummy from "./Dummies/FollowHorizontalDummy";

function UserFollowers({ local }) {
  const { username } = useParams();
  const [userList, setUserList] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      if (username) {
        const res = await getUserFollowers(username, 30);
        if (res.users.length > 0) {
          setUserList(res.users);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
        console.log("Followers", res);
      }
    };
    fetchDetails();
  }, [local, username]);

  return (
    <div className="">
      {Array.isArray(userList) && userList.length > 0 && (
        <>
          <hr className="line_sm" style={{ marginTop: 0 }} />
          <div className="p-4 sm-p-1">
            <div className="font-bold text-xl mb-4">Following</div>
            {userList.map((item, index) => (
              <FollowHorizontal data={item.user_profile[0]} />
            ))}
          </div>
        </>
      )}

      {loading && <FollowHorizontalDummy />}

      {!loading && userList.length == 0 ? (
        <div className="w-full py-4 flex align-center justify-center px-3 flex-column">
          <img
            src="/images/coworking-woman-doing-online-shopping-at-home.gif"
            className="w-48 object-contain animate__animated animate__zoomIn animate_fast"
          />
          <div className="text-xl font-semibold">
            No followers at the moment
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default UserFollowers;
