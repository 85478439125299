import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import VibePopup from "../../../VibePopup";
import { getHubMedia } from "../../../../db";
import MediaListDummy from "../../../components/Dummies/MediaListDummy";
import app from "../../../../config/app";

function HubMedia({ local }) {
  const [mediaList, setMediaList] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let { focusid, username } = useParams();
  useEffect(() => {
    const fetchDetails = async () => {
      if (username) {
        const res = await getHubMedia(username, 30);
        console.log("Media", res);
        if (res.media_list.length > 0) {
          setMediaList(res.media_list);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      }
    };
    fetchDetails();
  }, [local, username]);

  return (
    <div className="">
      {loading && <MediaListDummy />}
      <div className="p-4 sm-p-1">
        <div className="grid grid-cols-4 sm-grid-cols-3 gap-1 animate__animated animate__zoomIn animate_fast rounded-sm overflow-hidden">
          {mediaList.map((item, index) => (
            <Link
              to={`/communities/${username}/media/${item?.post_id}`}
              className="w-full bg-gray-100 rounded-sm hover overflow-hidden"
            >
              {item.mediatype == "image" ? (
                <img
                  src={app.media_url + "posts/" + item.filename}
                  className="w-full h-full object-cover rounded-sm ratio-1"
                />
              ) : (
                <div className="relative">
                  <img
                    src={app.media_url + "posts/" + item.thumb_filename}
                    className="w-full h-full object-cover rounded-sm ratio-1"
                  />
                  <div className="absolute w-full h-full video_overlay block top-0 bottom-0 m-auto"></div>
                  <div className="absolute w-10 h-10 top-0 bottom-0 left-0 right-0 m-auto flex align-center justify-center">
                    <span
                      class="material-icons-round"
                      style={{ color: "white", fontSize: 60 }}
                    >
                      play_arrow
                    </span>
                  </div>
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>

      {!loading && mediaList.length == 0 ? (
        <div className="w-full py-4 flex align-center justify-center px-3 flex-column">
          <img
            src="/images/coworking-woman-doing-online-shopping-at-home.gif"
            className="w-48 object-contain"
          />
          <div className="text-xl font-semibold">No media posted</div>
        </div>
      ) : (
        ""
      )}

      {focusid && (
        <div className="vibe_overlay">
          <VibePopup
            vibeid={focusid}
            local={local}
            // onCommentSubmit={(count) => updateCommentsCount(count)}
          />
        </div>
      )}
    </div>
  );
}

export default HubMedia;
