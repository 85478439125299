import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import CreatePost from "./components/CreatePost";
import { Link, useNavigate, useParams } from "react-router-dom";
import Feed from "./components/Feed";
import TopUserMenu from "./components/TopUserMenu";
import GeneralMidHeader from "./components/GeneralMidHeader";
import app from "../config/app";
import { getDiscoverFollowList, getTopicsList } from "../db";
import VibeDummy from "./components/Dummies/VibeDummy";
import CopyRight from "./components/CopyRight";
import FollowListReused from "./components/FollowListReused";
import VibePopup from "./VibePopup";
import VibesList from "./Topics/VibesList";
import TopicDummy from "./components/Dummies/TopicDummy";

function Topics({ local }) {
  const [usersList, setUsersList] = useState([]);
  const [topicsList, setTopicList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const navigate = useNavigate();
  let { vibeid, tagname } = useParams();

  // Debounced scroll handler
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - 100 && // Adjust trigger point
      !loading
    ) {
      loadMore();
    }
  }, [loading]);

  // Set up scroll event listener with debounce
  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200); // Debounce with 200ms delay

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [handleScroll]);

  // Fetch follow list data
  const fetchFollowListData = async () => {
    try {
      const data = await getDiscoverFollowList(5, 0);
      setUsersList(data.users);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  // Fetch vibe list data
  const fetchTopicsListData = async () => {
    if (loading) return; // Prevent fetching if currently loading
    setLoading(true); // Start loading

    try {
      const data = await getTopicsList(3, offset);
      console.log("Topics", data);
      if (data.topics && data.topics.length > 0) {
        // Create a Set of existing topic IDs for quick lookup
        const existingTopicIds = new Set(
          topicsList.map((topic) => topic.row?.id)
        );

        // Filter out duplicates
        const newTopics = data.topics.filter(
          (newVibe) => !existingTopicIds.has(newVibe.row?.id)
        );

        if (newTopics.length > 0) {
          setTopicList((prevTopics) => [...prevTopics, ...newTopics]); // Append new unique topics to the existing list
        }

        // Increase offset for next fetch
        setOffset((prevOffset) => prevOffset + 10);
      } else {
        console.log("No more topics available.");
      }
    } catch (error) {
      console.error("Error fetching topics:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Load more topics
  const loadMore = () => {
    fetchTopicsListData();
  };

  // Update comments count (placeholder function)
  const updateCommentsCount = (count) => {};

  // Initial data fetch
  useEffect(() => {
    fetchFollowListData();
    if (!tagname) {
      fetchTopicsListData();
    }
  }, []);

  return (
    <>
      <div className="min-h-screen flex-1">
        <div className="px-4 headers p-0 w-full h-full">
          <div
            className="flex align-center feed_list_cont_ w-full h-full p-0 pl-2"
            style={{ width: "100%" }}
          >
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">
                {tagname ? "#" + tagname : "Topics"}
              </div>
            </div>
          </div>
        </div>
        <div className={`p-4 px-0 sm-p-0 ${tagname && "feed_list_cont"}`}>
          {tagname ? (
            <VibesList />
          ) : (
            <>
              {Array.isArray(topicsList) && topicsList.length > 0 && (
                <div className="p-0">
                  {topicsList.map((item, index) => (
                    <>
                      <div className="p-2 rounded-xl">
                        <div className="px-4 mb-2 flex align-center justify-between">
                          <Link
                            to={"/topics/" + item.tag}
                            className="text-xl font-semibold hover_decoration_link"
                          >
                            #{item.tag}
                          </Link>
                          <Link to={"/topics/" + item.tag} className="text-sm">
                            {item.count} Vibe{item.count > 1 && "s"}
                          </Link>
                        </div>
                        <div className="flex align-center gap-2 rounded-xl overflow-hidden">
                          {item.vibes.map((vibe, vibe_index) => (
                            <Link
                              to={`/topics/${item.tag}/${vibe.activity_id}`}
                              className={`bg-gray-100 h-auto ${
                                item.vibes.length == 3 && "w-1/3"
                              } ${item.vibes.length == 2 && "w-1/2"} ${
                                item.vibes.length == 1 && "w-1/2"
                              } relative hover`}
                              style={{ aspectRatio: 0.7 }}
                            >
                              {vibe.uploads.length > 0 && (
                                <div className="w-full h-full">
                                  <img
                                    className="h-full w-full object-cover"
                                    src={
                                      vibe.uploads[0]?.row?.mediatype == "image"
                                        ? vibe.uploads[0].upload_path +
                                          "posts/mid/" +
                                          vibe.uploads[0].row.filename
                                        : vibe.uploads[0].upload_path +
                                          "posts/" +
                                          vibe.uploads[0].row.thumb_filename
                                    }
                                  />
                                </div>
                              )}

                              {vibe.uploads[0]?.row?.mediatype == "video" && (
                                <div className="absolute top-0 bottom-0 left-0 right-0 m-auto w-14 h-14 flex align-center justify-center vibein_play_container rounded-full">
                                  <span
                                    className="material-icons-round vibein_play_icon"
                                    style={{ fontSize: 50 }}
                                  >
                                    play_arrow
                                  </span>
                                </div>
                              )}

                              <div className="absolute bottom-0 opacity-100 px-1 py-2 bg-overlay w-full">
                                <div className="flex align-center gap-1">
                                  <div className="w-6 h-6 rounded-full border-1 flex align-center justify-center">
                                    <img
                                      src={vibe.user_profile[0].profile_image}
                                      className="w-6 h-6 object-cover rounded-full"
                                    />
                                  </div>
                                  <div className="h-6 px-2 bg-gray-100 rounded-full flex align-center justify-center">
                                    @{vibe.user_profile[0].username}
                                  </div>
                                </div>
                              </div>

                              {item.count > 3 && vibe_index == 2 && (
                                <Link
                                  to={"/topics/" + item.tag}
                                  className="absolute top-0 bottom-0 left-0 right-0 m-auto w-14 h-14 flex align-center justify-center bg-gray-200 rounded-full"
                                >
                                  <div className="font-bold text-xl">
                                    +{item.count - 3}
                                  </div>
                                </Link>
                              )}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </>
          )}

          {!tagname && (
            <>
              {loading && (
                <>
                  {/* <div className="text-center">Loading more vibes...</div> */}
                  <div className="mb-5 grid grid-cols-3 align-center gap-2">
                    <TopicDummy />
                    <TopicDummy />
                    <TopicDummy />
                    <TopicDummy />
                    <TopicDummy />
                    <TopicDummy />
                  </div>
                </>
              )}
            </>
          )}

          {/* Loading indicator */}
        </div>
      </div>

      {/* {vibeid && (
        <div className="vibe_overlay">
          <VibePopup
            vibeid={vibeid}
            local={local}
            onCommentSubmit={(count) => updateCommentsCount(count)}
          />
        </div>
      )} */}

      {window.innerWidth > app.mobile_width ? (
        <div className="min-h-screen flex right_cont">
          <TopUserMenu local={local} />
          <div className="p-4">
            <FollowListReused />
            <hr className="line" />
            <div className="mt-7 mb-16">
              <CopyRight />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Topics;
