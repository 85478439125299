import React, { useRef, useEffect, useState } from "react";
import LinkPreview from "./LinkPreview"; // Assuming you have this component
import EmojiSelector from "./EmojiSelector";

function TextContentInput({
  onChange,
  placeholder = "Say something...",
  clearText,
  enReachmentPosittion = "bottom",
  showEmojiSelector = true,
}) {
  const [content, setContent] = useState("");
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [links, setLinks] = useState([]); // To store detected URLs
  const contentEditableRef = useRef(null);

  // Regex for detecting URLs, mentions (@), and topics (#)
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const mentionRegex = /@\w+/g;
  const topicRegex = /#\w+/g;

  // Manage post content changes and enrichment detection
  const handlePostContentChange = (event) => {
    let text = event.target.innerHTML;

    // Replace custom formatting and detect special patterns
    text = text
      .replace(/<div>/g, "&newline;")
      .replace(/<\/div>/g, "&newline;")
      .replace(/<br>/g, "&newline;")
      .replace(/<\/br>/g, "&newline;")
      .replace(/<strong>/g, "&boldopen;")
      .replace(/<b>/g, "&boldopen;")
      .replace(/<\/strong>/g, "&boldclose;")
      .replace(/<\/b>/g, "&boldclose;")
      .replace(/<em>/g, "&italicopen;")
      .replace(/<i>/g, "&italicopen;")
      .replace(/<\/em>/g, "&italicclose;")
      .replace(/<\/i>/g, "&italicclose;")
      .replace(/&newline;&newline;/g, "&newline;")
      .replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags

    // Detect URLs and profile/topic mentions
    const detectedUrls = [...text.matchAll(urlRegex)].map((match) => match[0]);
    const mentions = [...text.matchAll(mentionRegex)];
    const topics = [...text.matchAll(topicRegex)];

    // Handle detected URLs
    if (detectedUrls.length > 0) {
      setLinks(detectedUrls);
    } else {
      setLinks([]); // Clear if no URLs
    }

    onChange({
      text,
      mentions: mentions.map((m) => m[0]),
      topics: topics.map((t) => t[0]),
    });

    setIsPlaceholderVisible(text === "");
  };

  // Handle focus and blur to toggle placeholder
  const handleFocus = () => {
    setIsPlaceholderVisible(contentEditableRef.current.innerHTML === "");
  };

  const handleBlur = () => {
    if (contentEditableRef.current.innerHTML === "") {
      setIsPlaceholderVisible(true);
    }
  };

  // Handle paste event
  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text");
    const textWithNewLines = text.split("\n");
    textWithNewLines.forEach((line, index) => {
      if (index > 0) {
        document.execCommand("insertHTML", false, "<br>");
      }
      document.execCommand("insertText", false, line);
    });
  };

  // Clear content if clearText is invoked
  useEffect(() => {
    if (clearText) {
      contentEditableRef.current.innerHTML = ""; // Clear the content
      setIsPlaceholderVisible(true); // Show the placeholder after clearing
    }
  }, [clearText]);

  const handleEmojiSelected = (emoji) => {
    // const newText =
    //   message.slice(0, selection.start) +
    //   emoji +
    //   message.slice(selection.end, message.length);
    // setMessage(newText);
    // setSelection({
    //   start: selection.start + emoji.length,
    //   end: selection.start + emoji.length,
    // });
  };

  return (
    <div className="relative w-full min-h-48">
      {enReachmentPosittion == "top" && links.length > 0 && (
        <div className="mt-2 create_post_link_preview">
          {links.map((link, index) => (
            <LinkPreview key={index} links={[link]} show_featured_img={true} />
          ))}
        </div>
      )}
      <div
        className="w-full min-h-48 bg-gray-200 rounded-xl p-2 new_post_content_input mb-1"
        contentEditable={true}
        ref={contentEditableRef}
        onInput={handlePostContentChange}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {isPlaceholderVisible && (
          <div
            className="w-full h-full text-gray-500 opacity-70 pointer-events-none"
            style={{ lineHeight: "1.5" }}
          >
            {placeholder}
          </div>
        )}
      </div>
      {showEmojiSelector && (
        <div
          style={{
            position: "relative",
          }}
        >
          <EmojiSelector onEmojiSelected={handleEmojiSelected} />
        </div>
      )}
      {enReachmentPosittion == "bottom" && links.length > 0 && (
        <div className="mt-2 create_post_link_preview">
          {links.map((link, index) => (
            <LinkPreview
              key={index}
              links={[link]}
              show_featured_img={true}
              size="small"
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default TextContentInput;
