import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import JoinHubList from "./components/JoinHubList";
import FollowList from "./components/FollowList";
import Feed from "./components/Feed";
import JobList from "./components/JobList";
import BookMarkVibes from "./Bookmarks/BookMarkVibes";
import BookMarkJobs from "./Bookmarks/BookMarkJobs";
import BooksMarkHub from "./Bookmarks/BooksMarkHub";
import BookMarkPeople from "./Bookmarks/BookMarkPeople";
import FollowListReused from "./components/FollowListReused";
import JoinHubsListReused from "./components/JoinHubsListReused";
import CopyRight from "./components/CopyRight";
import app from "../config/app";

function Bookmarks({ local }) {
  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname;
  let { name } = useParams();
  let component = <Feed data="bookmark" />;
  const paths = [
    {
      id: 1,
      title: "Vibes",
      path: "/Bookmarks/vibes",
    },
    {
      id: 2,
      title: "Jobs & Gigs",
      path: "/Bookmarks/jobs",
    },
    // {
    //   id: 3,
    //   title: "People",
    //   path: "/Bookmarks/people",
    // },
    // {
    //   id: 4,
    //   title: "Hubs",
    //   path: "/Bookmarks/hubs",
    // },
  ];

  if (!name) {
    name = "vibes";
    currentPath = "/Bookmarks/vibes";
  }

  if (name == "vibes") {
    component = <BookMarkVibes />;
  } else if (name == "jobs") {
    component = <BookMarkJobs />;
  } else if (name == "hubs") {
    component = <BooksMarkHub />;
  } else if (name == "people") {
    component = <BookMarkPeople />;
  }

  const menu = (
    <>
      <div className="p-0 sticky_profile_header flex align-center w-full">
        <div
          className="px-4 flex align-center headers w-full "
          style={{ background: "transparent", border: "none" }}
        >
          {paths.map((item, index) => (
            <Link
              key={item.id}
              to={item.path}
              className={currentPath == item.path ? "active_tab" : ""}
            >
              <div className="h-10 px-4 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
                {item.title}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="min-h-screen flex-1">
        <div className="px-4 headers p-0">
          <div
            className="feed_list_cont h-full w-full flex align-center"
            style={{ width: "100%" }}
          >
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Bookmarks</div>
            </div>
            <div className="w-full">{menu}</div>
          </div>
        </div>

        <div className="p-0">
          {/* all settings goes here */}
          {component}
        </div>
      </div>

      {window.innerWidth > app.mobile_width ? (
        <div className="min-h-screen flex right_cont">
          <TopUserMenu local={local} />
          <div className="p-4">
            <FollowListReused />
            <hr className="line" />
            <div className="mt-7 mb-16">
              <CopyRight />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Bookmarks;
