import React from "react";
import { Link } from "react-router-dom";

function CreatePost() {
  return (
    <div className="w-full flex align-center mb-4 mt-4 pr-2 bg-gray-100 rounded-full create_post_cont_widget">
      <Link
        to="/CreatePost"
        className="w-full h-14 mr-0 rounded-full align-center pl-2 pr-0 flex"
      >
        {/* <img src="/images/user.png" className="w-10 h-10 rounded-full mr-3" /> */}
        <div className="w-10 h-10 rounded-full mr-3 bg-white flex align-center justify-center hover">
          <span class="material-symbols-outlined">add</span>
        </div>
        <div>Hi, Lets vibe</div>
      </Link>
      <Link to="/CreatePost/photo">
        <div className="h-10 min-w-10 mr-3 rounded-full hover flex align-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
          >
            <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z" />
          </svg>
        </div>
      </Link>
      <Link to="/CreatePost/video">
        <div className="h-10 min-w-10 mr-3 rounded-full hover flex align-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#5f6368"
          >
            <path d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1c-.33-.25-.8-.01-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
          </svg>
        </div>
      </Link>
      <Link to="/CreatePost/polls">
        <div className="h-10 min-w-10 mr-3 rounded-full hover flex align-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5f6368"
          >
            <path d="m654-313 142-142q12-12 28-11.5t28 12.5q11 12 11 28t-11 28L683-228q-12 12-28 12t-28-12l-86-86q-11-11-11-28t11-28q11-11 28-11t28 11l57 57Zm0-320 142-142q12-12 28-11.5t28 12.5q11 12 11 28t-11 28L683-548q-12 12-28 12t-28-12l-86-86q-11-11-11-28t11-28q11-11 28-11t28 11l57 57ZM120-280q-17 0-28.5-11.5T80-320q0-17 11.5-28.5T120-360h280q17 0 28.5 11.5T440-320q0 17-11.5 28.5T400-280H120Zm0-320q-17 0-28.5-11.5T80-640q0-17 11.5-28.5T120-680h280q17 0 28.5 11.5T440-640q0 17-11.5 28.5T400-600H120Z" />
          </svg>
        </div>
      </Link>
      <Link to="/CreatePost/feeling">
        <div className="h-10 min-w-10 rounded-full hover flex align-center justify-center">
          <span class="material-icons-round" style={{ color: "#fd7307" }}>
            insert_emoticon
          </span>
        </div>
      </Link>
    </div>
  );
}

export default CreatePost;
