import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Profile from "./Settings/Profile";
import Username from "./Settings/Username";
import ContactInfo from "./Settings/ContactInfo";
import JobSettings from "./Settings/JobSettings";
import PageAccess from "./Settings/PageAccess";
import ProfileImage from "./Settings/ProfileImage";
import ServiceArea from "./Settings/ServiceArea";
import Verification from "./Verification";
import { get_menus } from "./menus_config";
import PageCategoryAndType from "./Settings/PageCategoryAndType";

function Settings({ local, business, updateLocal }) {
  const navigate = useNavigate();
  let { id, page, focus } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;
  const [showMenu, setShowMenu] = useState(false);

  const paths = get_menus(id, "page_settings");

  if (focus) {
    if (focus == "profile") {
      component = (
        <Profile
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "username") {
      component = (
        <Username
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "contactinfo") {
      component = (
        <ContactInfo
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "jobsettings") {
      component = (
        <JobSettings
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "pageaccess") {
      component = (
        <PageAccess
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "profileimage") {
      component = (
        <ProfileImage
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "servicearea") {
      component = (
        <ServiceArea
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "verification") {
      component = (
        <Verification
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else if (focus == "categoryandtype") {
      component = (
        <PageCategoryAndType
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    } else {
      component = (
        <Profile
          local={local}
          business={business}
          updateLocal={() => triggerLocal()}
        />
      );
    }
  } else {
    component = (
      <Profile
        local={local}
        business={business}
        updateLocal={() => triggerLocal()}
      />
    );
  }

  const triggerLocal = (data) => {
    updateLocal(data);
  };

  const toggleAdminMenu = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
  };

  return (
    <div>
      <div className="flex align-start">
        <div
          className={`left_menu_settings left_side_settings min-w-80 has_border_right bg-match px-3 pl-0 ${
            showMenu ? "show" : "hide"
          }`}
        >
          <div className="px-4 py-3 text-lg font-semibold">Page settings</div>
          {paths.map((item, index) => (
            <Link
              key={item.id}
              to={item.path}
              onClick={() => setShowMenu(false)}
              className="py-0.5 block"
            >
              <div
                className={
                  currentPath == item.path
                    ? "flex align-center hover p-2 px-3 rounded-md sidebar_item active"
                    : "flex align-center hover p-2 px-3 rounded-md sidebar_item"
                }
              >
                <div className="flex align-center w-10 h-10">
                  <span
                    className={
                      currentPath == item.path
                        ? "material-icons-round"
                        : "material-symbols-outlined"
                    }
                  >
                    {item.icon}
                  </span>
                </div>
                <div className="flex align-center flex-1">{item.title}</div>
                {item.has_sub ? (
                  <div className="flex align-center">
                    <span class="material-icons-round">chevron_right</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Link>
          ))}
        </div>
        <div
          className="open_menu_setting min-w-10 min-h-10 w-10 h-10 flex align-center justify-center bg-gray-100 rounded-full hover"
          onClick={() => toggleAdminMenu()}
        >
          <span class="material-symbols-outlined">settings</span>
        </div>
        <div className="right_settings w-full px-4 sm-p-0">{component}</div>
      </div>
    </div>
  );
}

export default Settings;
