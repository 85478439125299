import { CheckIcon, ExclamationTriangleIcon } from "@heroicons/react/16/solid";
import React, { useState } from "react";

function Responce({ data }) {
  return (
    <div
      className="fixed responce_popup bottom-0 left-0 gap-3 z-10 bg-gray-100 rounded-xl px-3 py-3 pr-5 flex align-center justify-between"
      style={{ zIndex: 99999 }}
    >
      <div className="w-8 h-8 rounded-full flex align-center justify-center bg-gray-100">
        {data.status ? <CheckIcon /> : <ExclamationTriangleIcon />}
      </div>
      <div className="">{data.text}</div>
    </div>
  );
}

export default Responce;
