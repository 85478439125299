import React, { useEffect, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/16/solid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getJobInfo, submitJobBasicInfo } from "../../../../db";
import app from "../../../../config/app";
import Responce from "../../../components/Responce";

function BasicJobInfo({ local }) {
  const navigate = useNavigate();
  let { id, page, focus, focus_id } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;

  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [jobInfo, setJobInfo] = useState();
  const [jobId, setJobId] = useState(
    Date.now().toString() + "@" + app.current_user
  );

  useEffect(() => {
    const fetchDetails = async () => {
      if (focus_id) {
        const res = await getJobInfo(focus_id, id);
        setJobInfo(res);
        setJobId(res.job_info[0].row.activity_id);
        // alert(res.job_info[0].row.job_name);
        setFormData({
          ...formData,
          job_name: res.job_info[0].row.job_name,
          about: res.job_info[0].row.about,
          applicant_deadline: res.job_info[0].row.applicant_deadline,
        });
        console.log("Job details", res);
      }
    };
    fetchDetails();
  }, [focus, currentPath, focus_id, id, page]);

  const [formData, setFormData] = useState({
    job_name: "",
    applicant_deadline: "",
    about: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let job_id = "";
    if (focus_id) {
      job_id = focus_id;
    } else {
      job_id = jobId;
    }

    const res = await submitJobBasicInfo({
      business: id,
      job_id: job_id,
      formData: formData,
    });
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      setJobInfo(res.job_info);
      console.log("Jobinfo", res.job_info);
      if (res.r_status == true && !focus_id) {
        navigate(`/admin/${id}/createjob/skills/${job_id}`);
      }
    }

    // console.log("Res 2", res);
  };

  return (
    <div className="py-3">
      <form
        onSubmit={handleSubmit}
        className="border-1 mt-3 px-8 py-5 rounded-xl min-h-60 flex flex-column align-start justify-center sm-p-2"
      >
        <div className="py-3 text-xl font-medium">
          {!focus_id ? "Get started" : "Update job details"}
        </div>
        <div className="w-full flex align-center gap-2 sm-block">
          <div className="w-full flex-1">
            <div className="text-xl my-2">Job title</div>
            <input
              placeholder="Add a job title"
              name="job_name"
              value={formData.job_name}
              onChange={handleChange}
              className="form-control px-3 rounded-xl w-full"
            />
          </div>
          <div className="pl-2 sm-p-0">
            <div className="text-xl my-2">Application Deadline</div>
            <input
              type="date"
              placeholder="Deadline"
              name="applicant_deadline"
              value={formData.applicant_deadline}
              onChange={handleChange}
              className="form-control px-3 rounded-xl w-full"
            />
          </div>
        </div>
        <div className="pt-4">
          <div className="text-xl my-2">Job description</div>
          <textarea
            className="form-control min-h-48 rounded-xl w-full mt-2"
            placeholder="Add some details here"
            name="about"
            value={formData.about}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="text-mute mt-1 px-2">
          You can access and change these details any time when you want to make
          an update.
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="btn align-center flex justify-center text-center gap-3"
          >
            <div className="w-full">{focus_id ? "Update" : "Continue"}</div>
            <span class="material-icons-round">arrow_right</span>
          </button>
        </div>
      </form>

      {showResponceCard && responceCard}
    </div>
  );
}

export default BasicJobInfo;
