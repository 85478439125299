import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PlusIcon, ShareIcon } from "@heroicons/react/16/solid";
import { sendMessage, shareJobAsMessage, submitJobBookmark } from "../../db";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import app from "../../config/app";
import Responce from "./Responce";
import SelectUsers from "../modals/SelectUsers";

function JobList({ data, local, from }) {
  const location = useLocation();
  const currentPath = location.pathname;
  const [bookmarkStatus, setBookmarkStatus] = useState(false);

  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [applyStatus, setApplyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [seeAllSkills, setSeeAllSkills] = useState(false);

  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showHubsModal, setShowHubsModal] = useState(false);

  const [jobLink, setJobLink] = useState("");

  useEffect(() => {
    console.log("Data", data);
    console.log("Info", data?.business_info?.[0]?.activity_id);
    setBookmarkStatus(data?.row?.bookmark_status || false);
  }, [data]);

  const addJobToBookmark = async (job_id) => {
    const response = await submitJobBookmark(job_id);
    console.log("Bookmark response", response);
    setBookmarkStatus(!bookmarkStatus);
  };

  const shareAsMessage = () => {
    // action
    setShowUsersModal(true);
  };

  const share_menu = (
    <Menu>
      <MenuButton className="w-8 h-8 rounded-full bg-gray hover flex align-center justify-center">
        <span class="material-icons-round">share</span>
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom end"
        className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
      >
        <MenuItem
          as="div"
          onClick={() => shareAsMessage()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">send</span>
          <div className="Upload an audio">Share as message </div>
        </MenuItem>
        <MenuItem
          as="div"
          onClick={() => copyLink()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">content_copy</span>
          <div className="Upload an audio">Copy job link </div>
        </MenuItem>
        {/* <MenuItem
          as="div"
          onClick={() => shareVibeOnHub()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">group</span>
          <div className="Upload an audio">Share on Hub </div>
        </MenuItem> */}
        <MenuItem
          as="div"
          onClick={() => moreShareOptions()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">more_horiz</span>
          <div className="Upload an audio">More options </div>
        </MenuItem>
      </MenuItems>
    </Menu>
  );

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(jobLink)
        .then(() => {
          // alert("Link copied to clipboard!");
          setShowResponceCard(true);
          setTimeout(() => {
            setShowResponceCard(false);
          }, 3000);
          setResponceCard(
            <Responce data={{ text: "Link copied", status: true }} />
          );
        })
        .catch((error) => {
          console.error("Error copying link: ", error);
        });
    } else {
      console.error("Clipboard API not supported in this browser.");
    }
  };

  const onSelectUser = async (chatids) => {
    const data_res = await shareJobAsMessage(
      chatids,
      jobLink,
      data?.row?.activity_id
    );

    if (data_res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: data_res.responce, status: data_res.r_status }}
        />
      );
      // console.log(responce_card);
    }

    // also save this messsage to the device and broadcast it to relevant devices
    for (let index = 0; index < chatids.length; index++) {
      const element = chatids[index];
      const messageObj = {
        user_id: app.current_user,
        activity_id: Date.now().toString() + "@" + app.current_user,
        status: false,
        parent: "",
        last_modified: Date.now(),
        date_time: Date.now(),
        sender: app.current_user,
        chat_body: jobLink.trim(),
        reciever: chatids[index].user_id,
        contain_media: false,
        conversation_id: chatids[index].chatid,
        send_status: true,
        read_status: false,
        post_shared: null,
        time: Date.now(),
        uploads: {
          images: [],
          videos: [],
          audios: [],
          documents: [],
        },
        draft_status: false,
        options: {},
      };
      const res = await sendMessage(messageObj, false);
    }

    console.log("Share responce", data_res);
  };

  const moreShareOptions = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this!",
          text: "Here is something really interesting!",
          url: jobLink,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((error) => {
          console.error("Error sharing:", error);
        });
    } else {
      console.error("Web Share API not supported in this browser.");
    }
  };

  return (
    <>
      {data && (
        <div
          className={`job_list_item rounded-md relative hover p-2 ${
            currentPath === `/jobs/${data?.row?.activity_id}` && "active"
          }`}
        >
          <div className="flex align-center w-full">
            <div className="h-14 w-14 min-h-14 min-w-14 rounded-full bg-gray-100 relative">
              <img
                src={
                  data?.business_info[0]?.profile_image == ""
                    ? "/images/business.png"
                    : app.media_url +
                      "/thumb/small/" +
                      data?.business_info[0]?.profile_image
                }
                alt="Business profile"
                className="h-14 w-14 min-h-14 min-w-14 rounded-full object-contain"
              />
              {app.current_user != "" && (
                <div
                  title="Your qualification to this job"
                  className={`absolute shadow-md qualification_rate min-w-8 h-8 flex align-center justify-center  m-auto rounded-full ${
                    data?.row?.user_qualification > 40 ? "success" : "danger"
                  }`}
                >
                  {data?.row?.user_qualification}%
                </div>
              )}
            </div>
            <div className="w-full px-3 text-left">
              <div className="w-full flex align-center">
                <Link
                  to={`/page/${data?.business_info?.[0]?.username}/jobs`}
                  className="w-full flex justify-center flex-column"
                >
                  <div>{data?.business_info?.[0]?.business_name}</div>
                  <div className="text-mute text-sm">
                    Posted by {data?.user_info?.[0]?.fullname || "Unknown User"}
                  </div>
                </Link>
                <div className="flex align-center gap-3">
                  <div
                    className="w-8 h-8 hover rounded-full flex align-center justify-center"
                    onClick={() => addJobToBookmark(data?.row?.activity_id)}
                  >
                    {bookmarkStatus ? (
                      <span className="material-icons-round">bookmark</span>
                    ) : (
                      <span className="material-symbols-outlined">
                        bookmark
                      </span>
                    )}
                  </div>
                  {share_menu}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <Link
              to={`${from ? from : "/jobs/"}${
                data?.row?.activity_id
              }/about-job`}
            >
              <div className="w-full text-xl">{data?.row?.job_name}</div>
              <div className="text-mute">{data?.row?.about || ""}</div>
              <div className="flex flex-wrap gap-1 py-2">
                {data?.row?.skills?.slice(0, 4).map(
                  (item, index) =>
                    item && (
                      <div
                        key={index}
                        className="flex align-center px-4 text-sm py-1 bg-gray-100 rounded-full"
                      >
                        {item}
                      </div>
                    )
                )}
                {data?.row?.skills?.length > 4 && (
                  <div className="h-8 bg-gray-100 rounded-full flex align-center justify-center gap-1 px-2 text-sm">
                    <PlusIcon />
                    {data?.row?.skills.length - 4}
                  </div>
                )}
              </div>
            </Link>
          </div>
        </div>
      )}

      {showUsersModal && (
        <SelectUsers
          onCloseModal={() => setShowUsersModal(false)}
          onSelectUser={(user_id) => onSelectUser(user_id)}
          submitTitle={"Share job"}
        />
      )}
    </>
  );
}

export default JobList;
