import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import Dashboard from "./PageAdmin/Dashboard";
import JobReports from "./PageAdmin/JobReports";
import PostedJobs from "./PageAdmin/ManageJobs/PostedJobs";
import CreateJob from "./PageAdmin/ManageJobs/CreateJob";
import Applications from "./PageAdmin/Candidates/Applications";
import Settings from "./PageAdmin/Settings";
import CopyRight from "./components/CopyRight";
import Verification from "./PageAdmin/Verification";
import Preview from "./PageAdmin/Preview";
import { get_menus } from "./PageAdmin/menus_config";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { getBusinessFromIndexedDB, socket } from "../db";
import JobApplications from "./PageAdmin/ManageJobs/JobApplications";
import JobDetails from "./PageAdmin/ManageJobs/JobDetails";
import EditJobs from "./PageAdmin/ManageJobs/EditJobs";
import Followers from "./PageAdmin/Followers";
import CandidateProfile from "./PageAdmin/Candidates/CandidateProfile";
import app from "../config/app";

function Admin({ local }) {
  const navigate = useNavigate();
  let { id, page, focus } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;
  const [showSearch, setShowSearch] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [activeBusinessProfile, setActiveBusinessProfile] = useState();
  const [currentLocalStatus, setCurrentLocalStatus] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [showMainMenuIcon, setShowMainMenuIcon] = useState(true);

  const [buinessProfile, setBuinessProfile] = useState({
    business_name: "",
    profile_image: "",
    username: "",
  });

  const paths = get_menus(id, "general_page");
  useEffect(() => {
    document.body.classList.add("wide_body");
    document.body.classList.add("message_body");
    document.body.classList.add("admin_body");
    // Cleanup function to remove the class when the component unmounts

    return () => {
      document.body.classList.remove("wide_body");
      document.body.classList.remove("message_body");
      document.body.classList.remove("admin_body");
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  const handleSearchUpdate = (value) => {
    let searches = get_menus(id, "", value);
    setShowSearch(true);
    setSearchResults(searches);
  };

  // get this page info
  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        const list = await getBusinessFromIndexedDB();
        // console.log(list);
        setBusinessList(list);
        // alert(id);
        for (let index = 0; index < list.length; index++) {
          if (list[index].business_id == id) {
            // alert(list[index].business_id);
            console.log("Active", list[index].business_info[0]);
            setBuinessProfile(list[index].business_info[0]);
          }
        }
        // updateActiveBusiness(list);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (page == "settings") {
      setShowMainMenuIcon(false);
    } else {
      setShowMainMenuIcon(true);
    }

    fetchBusiness();
  }, [page, id, local, currentPath, currentLocalStatus]);

  const triggerLocal = async (data) => {
    setCurrentLocalStatus(data);
    const list = await getBusinessFromIndexedDB();
    setBusinessList(list);
    for (let index = 0; index < list.length; index++) {
      if (list[index].business_id == id) {
        console.log("Active", list[index].business_info[0]);
        setBuinessProfile(list[index].business_info[0]);
      }
    }
  };

  if (page) {
    if (page == "dashboard") {
      component = (
        <Dashboard
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "jobreports") {
      component = (
        <JobReports
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "postedjobs") {
      component = (
        <PostedJobs
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "jobapplication") {
      component = (
        <JobApplications
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "jobdetails") {
      component = (
        <JobDetails
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "editjob") {
      component = (
        <EditJobs
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "createjob") {
      component = (
        <CreateJob
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "applications") {
      component = (
        <Applications
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "settings") {
      component = (
        <Settings
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "verification") {
      component = (
        <Verification
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "preview") {
      component = (
        <Preview
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "followers") {
      component = (
        <Followers
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    } else if (page == "candidateprofile") {
      component = (
        <CandidateProfile
          local={local}
          updateLocal={() => triggerLocal()}
          business={buinessProfile}
        />
      );
    }
  } else {
    page = "dashboard";
    component = (
      <Dashboard
        local={local}
        updateLocal={() => triggerLocal()}
        business={buinessProfile}
      />
    );
  }

  const toggleAdminMenu = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
  };

  return (
    <>
      <div className={`min-h-screen mainadmin_pane flex-1`}>
        <div className="px-2 py-1 rounded-xl flex align-center justify-between headers pr-0 top_header_cont">
          <div className="flex align-center justifty-start">
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center ml-2"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Manage page</div>
            </div>
          </div>

          {window.innerWidth < app.mobile_width && showMainMenuIcon ? (
            <div
              className="w-10 h-10 rounded-full hover flex align-center justify-center mr-2 hover"
              onClick={() => toggleAdminMenu()}
            >
              <span class="material-symbols-outlined">menu</span>
            </div>
          ) : (
            <TopUserMenu local={local} />
          )}
        </div>

        <div className="flex align-start">
          <div
            className={`p-4 w-full max-w-80 min-w-80 has_border_right left_side_settings bg-match sm-p-2 ${
              showMenu ? "show" : "hide"
            }`}
          >
            {/* search features and settings */}
            <div className="relative w-full flex align-center gap-3">
              <div className="w-full relative mb-3">
                <span class="material-icons-round absolute top-3 left-4 m-auto h-auto">
                  search
                </span>
                <input
                  className="form-control search_input has_icon w-full rounded-xl pl-14"
                  placeholder="Search features"
                  onFocus={() => setShowSearch(true)}
                  //   onFocusCapture={() => alert()}
                  onBlur={() => {
                    setTimeout(() => setShowSearch(false), 200);
                  }}
                  onChange={(e) => handleSearchUpdate(e.target.value)}
                />
              </div>

              <div
                className={
                  showSearch && searchResults.length > 0
                    ? "absolute top-12 -mt-1 search_resutls_drop_down w-full min-h-20 rounded-xl bg-gray-200 z-10 shadow-xl"
                    : "absolute top-12 -mt-1 display-none search_resutls_drop_down w-full min-h-20 rounded-xl bg-gray-200 z-10 shadow-xl"
                }
              >
                <div className="p-5">Search results</div>
                <div className="result_list p-1">
                  {searchResults.map((item, index) => (
                    <Link
                      to={item.path}
                      onClick={() => setShowMenu(false)}
                      className="w-full hover h-10 flex align-center justify-between rounded-xl gap-3 pl-2"
                    >
                      <div className="w-8 h-8 rounded-full flex align-center justify-center">
                        <span class="material-icons-round">{item.icon}</span>
                      </div>
                      <div className="w-full h-8 flex align-center pl-1">
                        {item.title}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex align-center justify-between">
              {/* switch business */}
              <Menu>
                <MenuButton className="align-center justify-start flex w-full hover py-2 rounded-xl px-2">
                  <div className="flex align-center gap-2 w-full flex-1">
                    <div className="w-10 h-10 min-w-10 min-h-10 rounded-full bg-gray-100">
                      <img
                        src={
                          buinessProfile.profile_image == ""
                            ? "/images/business.png"
                            : app.media_url +
                              "/thumb/small/" +
                              buinessProfile.profile_image
                        }
                        className="w-10 h-10 min-w-10 min-h-10 rounded-full "
                      />
                    </div>
                    <div className="w-full flex-1 text-left">
                      <div className="text-xl font-semibold">
                        {buinessProfile.business_name}
                      </div>
                      <div className="text-mute -mt-1">
                        @{buinessProfile.username}
                      </div>
                    </div>
                  </div>
                  <div className="w-10 h-10 min-w-10 min-h-10 bg-gray-100 rounded-full hover cursor-pointer flex align-center justify-center">
                    <span class="material-icons-round">store</span>
                  </div>
                </MenuButton>

                <MenuItems
                  transition
                  anchor="bottom start"
                  className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container account_dropdown_container"
                >
                  <div className="my-3 px-3">Switch business page</div>
                  <hr className="line" />
                  {businessList.length > 0 &&
                    businessList.map((item, index) => (
                      <MenuItem key={item.business_info[0].page_id}>
                        <Link
                          key={item.business_info[0].page_id}
                          to={`/admin/${item.business_info[0].page_id}/${page}`}
                          className={
                            item.business_info[0].page_id == id
                              ? "group active list_items flex h-auto my-1 w-full items-center gap-3 rounded-lg py-2.5 px-3 pl-1 data-[focus]:bg-white/10"
                              : "group list_items flex h-auto my-1 w-full items-center gap-3 rounded-lg py-2.5 px-3 pl-1 data-[focus]:bg-white/10"
                          }
                        >
                          <div className="flex align-center arrow_more">
                            <img
                              src={
                                item.business_info[0].profile_image == ""
                                  ? "/images/business.png"
                                  : app.media_url +
                                    "/thumb/small/" +
                                    item.business_info[0].profile_image
                              }
                              className="w-10 h-10 min-w-10 min-h-10 rounded-full object-cover"
                            />
                          </div>
                          <div className="w-full">
                            <div className="w-full text-left text-lg font-semibold">
                              {item.business_info[0].business_name}
                            </div>
                            <div className="-mt-1">
                              @{item.business_info[0].username}
                            </div>
                          </div>

                          <div className="flex align-center arrow_more">
                            <span className="material-icons-round">
                              chevron_right
                            </span>
                          </div>
                        </Link>
                      </MenuItem>
                    ))}
                </MenuItems>
              </Menu>
            </div>

            <div className="mt-1">
              {/* {name} */}
              {/* <div className='my-3 px-4 mt-2'>Manage your professional profile</div> */}

              {paths.map((item, index) => (
                <Link key={item.id} to={item.path} className="py-0.5 block">
                  <div
                    onClick={() => setShowMenu(false)}
                    className={
                      currentPath == item.path
                        ? "flex align-center hover p-2 px-3 rounded-md sidebar_item active"
                        : "flex align-center hover p-2 px-3 rounded-md sidebar_item"
                    }
                  >
                    <div className="flex align-center w-10 h-10">
                      <span
                        className={
                          currentPath == item.path
                            ? "material-icons-round"
                            : "material-symbols-outlined"
                        }
                      >
                        {item.icon}
                      </span>
                    </div>
                    <div className="flex align-center flex-1">{item.title}</div>
                    {item.has_sub ? (
                      <div className="flex align-center">
                        <span class="material-icons-round">chevron_right</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>
              ))}
            </div>

            <hr className="line" />
            <a href="">
              <div class="px-2 py-1 hover rounded-xl flex align-center">
                <div class="icon_cont h-8 w-8 mr-3 rounded-full flex align-center text-normal justify-center text-center ">
                  <span class="material-icons-round">help</span>
                </div>
                <div class="h-10 rounded-full flex align-center px-4 py-2 text-normal flex-1 w-full">
                  VibeIn for Business
                </div>
                <div class="flex align-center">
                  <span class="material-icons-round">arrow_outward</span>
                </div>
              </div>
            </a>
            <a href="">
              <div class="px-2 py-1 hover rounded-xl flex align-center">
                <div class="icon_cont h-8 w-8 mr-3 rounded-full flex align-center text-normal justify-center text-center ">
                  <span class="material-icons-round">info</span>
                </div>
                <div class="h-10 rounded-full flex align-center px-4 py-2 text-normal flex-1 w-full">
                  Support for business
                </div>
                <div class="flex align-center">
                  <span class="material-icons-round">arrow_outward</span>
                </div>
              </div>
            </a>
          </div>
          <div className="w-full right_admin_content">
            <div className="pannel_component p-4 pt-0 sm-p-1">
              {activeBusinessProfile != "" && component}
            </div>
            <div className="mt-4 p-4 sm-p-1 border-top pt-10">
              <CopyRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
