import React from "react";
import PostContent from "../../../components/PostContent";

function Education({ data }) {
  return (
    <div>
      {data.length == 0 && (
        <ul className=" space-y-4 mt-2 text-sm">
          <div className="mt-2 mb-2">
            <div className="flex align-center">
              <img
                src="/images/willowy-woman-on-screen-doing-online-educational-video-1.png"
                className="w-48 h-auto object-contain"
                with={50}
              />
              <div className="px-3">
                <div>No education details added yet.</div>
              </div>
            </div>
          </div>
        </ul>
      )}

      {data.length > 0 && (
        <div className="flex flex-wrap gap-3 py-3">
          {data.map((item) => (
            <div
              key={item.id}
              className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex mt-2 items-start justify-start gap-6 relative hover"
            >
              <div className="relative top-1">
                <span class="material-symbols-outlined">school</span>
              </div>
              <div className="text-lg font-semibold flex-1">
                <div>{item.institutionName}</div>
                <div className="text-sm description_pnone">
                  <PostContent text={item.description} contentStyle={{}} />
                </div>
              </div>
              <div className="text-sm border-left pl-2">
                {item.startDate} - {item.endDate}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Education;
