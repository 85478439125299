import React from "react";

function JobsListDummy() {
  return (
    <>
      <div
        className="px-4 job_list_item rounded-xl p-2"
        style={{ background: "transparent" }}
      >
        <div className="flex align- mb-4 animate__animated animate__slideInUp animate_fast mt-4">
          <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
          <div className="w-full px-3">
            <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
          </div>
          <div className="flex align-start gap-2">
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
          </div>
        </div>
        <div className="flex gap-3 animate__animated animate__slideInUp animate_fast">
          <div className="h-28 w-full bg-gray-200 loader rounded-xl"></div>
        </div>
      </div>

      <div className="px-4 job_list_item hover rounded-xl p-2">
        <div className="flex align- mb-4 animate__animated animate__slideInUp animate_fast mt-4">
          <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
          <div className="w-full px-3">
            <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
          </div>
          <div className="flex align-start gap-2">
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
          </div>
        </div>
        <div className="flex gap-3 animate__animated animate__slideInUp animate_fast">
          <div className="h-28 w-full bg-gray-200 loader rounded-xl"></div>
        </div>
      </div>

      <div className="px-4 job_list_item hover rounded-xl p-2">
        <div className="flex align- mb-4 animate__animated animate__slideInUp animate_fast mt-4">
          <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
          <div className="w-full px-3">
            <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
          </div>
          <div className="flex align-start gap-2">
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
          </div>
        </div>
        <div className="flex gap-3 animate__animated animate__slideInUp animate_fast">
          <div className="h-28 w-full bg-gray-200 loader rounded-xl"></div>
        </div>
      </div>
      <div className="px-4 job_list_item hover rounded-xl p-2">
        <div className="flex align- mb-4 animate__animated animate__slideInUp animate_fast mt-4">
          <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
          <div className="w-full px-3">
            <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
          </div>
          <div className="flex align-start gap-2">
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
            <div className="h-8 w-8 bg-gray-200 loader rounded-full"></div>
          </div>
        </div>
        <div className="flex gap-3 animate__animated animate__slideInUp animate_fast">
          <div className="h-28 w-full bg-gray-200 loader rounded-xl"></div>
        </div>
      </div>
    </>
  );
}

export default JobsListDummy;
