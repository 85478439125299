import React, { useState } from "react";
import ReactPlayer from "react-player";
import MediaListDummy from "../../components/Dummies/MediaListDummy";

function Media({ conversationData }) {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [loadingMedia, setLoadingMedia] = useState(true);
  const [allMedia, setAllMedia] = useState([]);
  const [mediaLimit, setMediaLimit] = useState(3); // Limit the initial number of media items shown

  // Aggregate all media from the conversation data in descending order
  React.useEffect(() => {
    const media = [];
    conversationData.forEach((item) => {
      if (item.uploads.images.length > 0) {
        item.uploads.images.forEach((image) => {
          media.push({
            type: "image",
            src:
              image.base64 ||
              image.blobUrl ||
              image?.upload_path + "/posts/" + image?.row?.filename,
          });
        });
      }
      if (item.uploads.videos.length > 0) {
        item.uploads.videos.forEach((video) => {
          media.push({
            type: "video",
            thumbnail: video.thumbnail,
            src:
              video.base64 ||
              video.blobUrl ||
              video?.upload_path + "/posts/" + video?.row?.thumb_filename,
          });
        });
      }
    });
    // Sort the media array in descending order based on index
    setAllMedia(media.reverse());
    setLoadingMedia(false);
  }, [conversationData]);

  const handleMediaClick = (index) => {
    setSelectedMediaIndex(index);
  };

  const closeModal = () => {
    setSelectedMediaIndex(null);
  };

  const showNextMedia = () => {
    setSelectedMediaIndex((prevIndex) =>
      prevIndex < allMedia.length - 1 ? prevIndex + 1 : 0
    );
  };

  const showPreviousMedia = () => {
    setSelectedMediaIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : allMedia.length - 1
    );
  };

  const loadMoreMedia = () => {
    setMediaLimit((prevLimit) => prevLimit + 9); // Load 9 more items each time
  };

  const selectMediaFromThumbnail = (index) => {
    setSelectedMediaIndex(index);
  };

  return (
    <div>
      <div className="p-4">
        <div className="grid grid-cols-3 w-full align-start justify-between gap-2 flex-wrap rounded-xl overflow-hidden">
          {allMedia.slice(0, mediaLimit).map((media, index) => (
            <div
              key={index}
              className="hover w-full animate__animated animate__zoomIn animate_fast cursor-pointer"
              onClick={() => handleMediaClick(index)}
            >
              {media.type === "image" ? (
                <img
                  src={media.src}
                  className="w-full h-full object-cover"
                  alt={`Media ${index}`}
                />
              ) : (
                <div
                  className="relative w-full h-full"
                  style={{ aspectRatio: 1 }}
                >
                  <ReactPlayer
                    url={media.src}
                    light={media.thumbnail}
                    width="100%"
                    height="100%"
                    className="absolute top-0 left-0 w-full h-full max-w-16 max-h-16 overflow-hidden"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {loadingMedia && <MediaListDummy />}
      {allMedia.length == 0 && (
        <div className="rounded p-1 cursor-pointer mt-3 px-4 py-2 select-none text-sm mx-10 text-center">
          There is no media shared at the moment.
        </div>
      )}

      {mediaLimit < allMedia.length && (
        <div
          className="hover rounded p-1 cursor-pointer mt-3 px-4 py-2 select-none text-sm mx-10 text-center"
          onClick={loadMoreMedia}
        >
          See more
        </div>
      )}

      {selectedMediaIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-between">
          <div
            className="relative max-w-full max-h-full w-full rounded-lg"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 hover rounded-full p-2 shadow-lg z-50 w-10 h-10 bg-white"
            >
              <span className="material-symbols-outlined">close</span>
            </button>

            <div className="flex justify-center items-center h-full">
              {allMedia[selectedMediaIndex].type === "image" ? (
                <img
                  className="max-h-full max-w-full animate__animated animate__zoomIn animate_fast"
                  src={allMedia[selectedMediaIndex].src}
                  alt={`Image ${selectedMediaIndex}`}
                />
              ) : (
                <ReactPlayer
                  url={allMedia[selectedMediaIndex].src}
                  controls
                  width="100%"
                  height="100%"
                  className="animate__animated animate__zoomIn animate_fast"
                />
              )}
            </div>

            <button
              onClick={showPreviousMedia}
              className="absolute left-2 w-10 h-10 hover flex align-center justify-center top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-lg z-50"
            >
              <span
                className="material-symbols-outlined"
                style={{ left: 3, position: "relative" }}
              >
                arrow_back_ios
              </span>
            </button>
            <button
              onClick={showNextMedia}
              className="absolute right-2 w-10 h-10 hover flex align-center justify-center top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-lg z-50"
            >
              <span className="material-symbols-outlined">
                arrow_forward_ios
              </span>
            </button>

            <div
              className="flex justify-center gap-2 align-center w-full rev_bg"
              style={{ height: 100 }}
            >
              {allMedia.map((media, index) => (
                <div
                  key={index}
                  className={`cursor-pointer rounded-xl overflow-hidden ${
                    index === selectedMediaIndex ? "thumbs active" : "thumbs"
                  }`}
                  onClick={() => selectMediaFromThumbnail(index)}
                >
                  {media.type === "image" ? (
                    <img
                      className="w-16 h-16 object-cover"
                      src={media.src}
                      alt={`Thumbnail ${index}`}
                    />
                  ) : (
                    <div className="w-16 h-16 bg-gray-200 flex items-center justify-center relative">
                      <img
                        className="w-16 h-16 max-h-16 max-w-16 object-cover"
                        src={media.thumbnail}
                      />
                      <span className="material-symbols-outlined text-gray-500 absolute top-0 bottom-0 left-0 right-0 m-auto w-8 h-8 flex align-center justify-center">
                        play_circle
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Media;
