import React, { useEffect } from "react";
import TopUserMenu from "./components/TopUserMenu";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Professional from "./Settings/Professional";
import Privacy from "./Settings/Privacy";
import Profile from "./Settings/Profile";
import ContactInfo from "./Settings/ContactInfo";
import Security from "./Settings/Security";
import Notifications from "./Settings/Notifications";
import Language from "./Settings/Language";
import Interests from "./Settings/Interests";
import Timezone from "./Settings/Timezone";
import ProfileImage from "./Settings/ProfileImage";
import CopyRight from "./components/CopyRight";

const settingsLinks = [
  { to: "profileimage", icon: "photo_camera", label: "Profile & Cover image" },
  { to: "professional", icon: "person", label: "Professional settings" },
  { to: "privacy", icon: "verified_user", label: "Account privacy" },
  { to: "profile", icon: "settings", label: "Profile setting" },
  {
    to: "contact_info",
    icon: "person_pin_circle",
    label: "Contact information",
  },
  { to: "security", icon: "lock", label: "Security settings" },
  {
    to: "notifications",
    icon: "notifications",
    label: "Notifications setting",
  },
  { to: "interests", icon: "favorite", label: "Manage interests" },
  { to: "language", icon: "language", label: "Language" },
  { to: "timezone", icon: "schedule", label: "Timezone" },
];

function Settings({ local }) {
  const navigate = useNavigate();
  let { name } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;

  const settingsComponents = {
    professional: <Professional local={local} />,
    privacy: <Privacy local={local} />,
    profile: <Profile local={local} />,
    contact_info: <ContactInfo local={local} />,
    security: <Security local={local} />,
    notifications: <Notifications local={local} />,
    language: <Language local={local} />,
    interests: <Interests local={local} />,
    timezone: <Timezone local={local} />,
    profileimage: <ProfileImage local={local} />,
  };

  const component = settingsComponents[name] || <Professional local={local} />;

  useEffect(() => {
    document.body.classList.add("wide_body");
    return () => {
      document.body.classList.remove("wide_body");
    };
  }, []);

  return (
    <>
      <div className="flex-1">
        <div className="px-4 flex align-center headers justify-between">
          <div className="flex align-center w-full">
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Account settings</div>
            </div>
          </div>

          <TopUserMenu local={local} />
        </div>
        <div className="flex align-start mid_container_inn">
          <div className="p-4 w-full max-w-80 has_border_right left_side_settings">
            {settingsLinks.map((link) => (
              <Link
                key={link.to}
                to={`/Settings/${link.to}`}
                className="py-0.5 block"
              >
                <div
                  className={
                    currentPath == `/Settings/${link.to}`
                      ? "flex align-center hover p-2 px-3 rounded-md sidebar_item active"
                      : "flex align-center hover p-2 px-3 rounded-md sidebar_item"
                  }
                >
                  <div className="flex align-center w-10 h-10">
                    <span className="material-icons-round">{link.icon}</span>
                  </div>
                  <div className="flex align-center flex-1">{link.label}</div>
                  <div className="flex align-center">
                    <span className="material-icons-round">chevron_right</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="w-full">
            <div className="w-full">{component}</div>
            {/* <div className="p-8">
              <CopyRight />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
