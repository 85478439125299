import React, { useState, useEffect } from "react";
import app from "../../config/app";
import {
  saveTimezoneToIndexedDB,
  getTimezoneFromIndexedDB,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function Timezone({ local }) {
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [filter, setFilter] = useState("");
  const system_Timezone = app.timezone;

  useEffect(() => {
    const fetchTimezone = async () => {
      const savedTimezone = await getTimezoneFromIndexedDB();
      if (savedTimezone) {
        setSelectedTimezone(savedTimezone);
      }
    };
    fetchTimezone();
  }, [local]);

  const handleTimezoneUpdate = async (item) => {
    setSelectedTimezone(item);
    updateLocalStorage({ type: "updateTimezone", data: item });
    const responce = await saveTimezoneToIndexedDB(item);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  const filteredTimezones = system_Timezone.filter((Timezone) =>
    Timezone.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="p-7">
      <div className="text-xl font-semibold mb-4">Timezone settings</div>
      <div className="sticky tags_filter py-2">
        <input
          type="text"
          placeholder="Search Timezone..."
          className="mb-4 p-2 border border-gray-300 rounded-xl w-full px-6"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <div className="px-2 py-3 pt-0">Select a timezone below</div>
      <div className="Timezone_list border-1 rounded-2xl px-2 py-2">
        {filteredTimezones.map((item, index) => (
          <div
            key={index}
            onClick={() => handleTimezoneUpdate(item)}
            className={`p-3 cursor-pointer ${
              selectedTimezone === item
                ? "active bg-gray-200 select_list rounded-xl text-black"
                : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Timezone;
