import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { CameraIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";

import Cropper from "react-easy-crop";
import CopyRight from "../../components/CopyRight";
import {
  getCroppedImg,
  getPageAccessList,
  getBusinessProfile,
  updateBusinessProfileImageAndCover,
  updateLocalStorage,
} from "../../../db";
import { useParams } from "react-router-dom";
import app from "../../../config/app";
import Loader from "../../components/Loader";

function ProfileImage({ local, updateLocal }) {
  const [isSaving, setIsSaving] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  let { id, page, focus } = useParams();
  const [profileImages, setProfileImages] = useState({
    cover_image: "",
    profile_image: "",
  });

  const [selectedProfileImages, setSelectedProfileImages] = useState({
    cover_image: "",
    profile_image: "",
  });

  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCroppingCover, setIsCroppingCover] = useState(false);
  const [showCroper, setShowCroper] = useState(false);

  const defaultProfileImage = "/images/hubuser.png"; // Update with your default profile image path
  const defaultCoverImage = "/images/hubcover.png"; // Update with your default cover image path

  const handleSaveProfile = async () => {
    setIsSaving(true);
    try {
      // Prepare data for submission
      const dataToSubmit = {
        cover_image:
          profileImages.cover_image === defaultCoverImage
            ? "remove"
            : profileImages.cover_image,
        profile_image:
          profileImages.profile_image === defaultProfileImage
            ? "remove"
            : profileImages.profile_image,
      };

      const dt_res = await updateBusinessProfileImageAndCover(
        id,
        selectedProfileImages
      );

      console.log("Submit imgs", dt_res);
      // Handle success response here
    } catch (error) {
      console.error("Error saving profile images", error);
      // Handle error response here
    }

    // update local data list
    const list = await getPageAccessList(app.current_user, 30);
    updateLocalStorage({ type: "updatepage", data: list });
    updateLocal(list);
    setIsSaving(false);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useEffect(() => {
    const fetchImages = async () => {
      const profileImages = await getBusinessProfile(id);
      console.log(profileImages);
      let imgs = {
        cover_image:
          profileImages.business_info[0].cover_image != ""
            ? app.media_url +
              "/thumb/" +
              profileImages.business_info[0].cover_image
            : "/images/hubcover.png",
        profile_image:
          profileImages.business_info[0].profile_image != ""
            ? app.media_url +
              "/thumb/small/" +
              profileImages.business_info[0].profile_image
            : "/images/hubuser.png",
      };
      // console.log("Images", profileImages);
      console.log("profileImages-<", profileImages.business_info[0]);
      setProfileImages(imgs);
    };
    fetchImages();
  }, [page, id, local]);

  const handleImageUpload = (e, isCover) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
      setShowCroper(true);
      setIsCroppingCover(isCover); // Flag whether it's a cover or profile image
    };
    reader.readAsDataURL(file);
  };

  const saveImage = async () => {
    if (croppedAreaPixels && selectedImage) {
      try {
        const croppedImage = await getCroppedImg(
          selectedImage,
          croppedAreaPixels
        );
        // console.log("croppedImage", croppedImage);
        // Update the appropriate image (cover or profile)
        if (isCroppingCover) {
          setProfileImages((prev) => ({ ...prev, cover_image: croppedImage }));
          setSelectedProfileImages((prev) => ({
            ...prev,
            cover_image: croppedImage,
          }));
        } else {
          setProfileImages((prev) => ({
            ...prev,
            profile_image: croppedImage,
          }));
          setSelectedProfileImages((prev) => ({
            ...prev,
            profile_image: croppedImage,
          }));
        }

        // Hide cropper
        setShowCroper(false);
      } catch (error) {
        console.error("Failed to crop the image", error);
      }
    }
  };

  const closeCroper = () => {
    setShowCroper(false);
  };

  const removeProfile = () => {
    setProfileImages((prev) => ({
      ...prev,
      profile_image: defaultProfileImage,
    }));
    setSelectedProfileImages((prev) => ({
      ...prev,
      profile_image: "remove",
    }));
  };

  const removeCover = () => {
    setProfileImages((prev) => ({
      ...prev,
      cover_image: defaultCoverImage,
    }));
    setSelectedProfileImages((prev) => ({
      ...prev,
      cover_image: "remove",
    }));
  };

  const submitProfileImages = () => {
    setShowCroper(false);
  };

  // profileImages

  return (
    <div>
      <div className="form-group mt-0 rounded-md p-0">
        <div className="p-0 pt-2">
          <div className="py-3 pt-0 flex align-center justify-between mb-6">
            <div className="text-xl font-semibold">Profile image settings</div>
            <button
              type="button"
              onClick={handleSaveProfile}
              className="btn flex align-center gap-2 h-10"
            >
              {isSaving && <Loader />}
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
          <div
            className="relative border-1 rounded-2xl bg-gray-100"
            style={{ height: 240 }}
          >
            {profileImages.cover_image != "" && (
              <img
                className="w-full rounded-2xl object-cover"
                style={{ height: 240 }}
                src={profileImages.cover_image}
              ></img>
            )}

            <Menu>
              <MenuButton className="absolute min-w-10 h-10 px-3 hover cursor-pointer rounded-full bg-gray-100 right-2 bottom-2 flex align-center justify-center gap-2">
                <div className="">Change cover</div>
                <CameraIcon />
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom end"
                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
              >
                <MenuItem
                  as="label"
                  htmlFor="coverImageUpload"
                  className="flex align-center cursor-pointer"
                >
                  <div className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                    Upload image
                  </div>
                </MenuItem>

                <MenuItem onClick={() => removeCover()}>
                  <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                    Remove cover image
                  </div>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>

          <div className="w-32 h-32 -mt-16 ml-4 relative rounded-full bg-gray-100 overflow-hidden">
            {profileImages.profile_image != "" && (
              <img
                className="rounded-full object-cover w-32 h-32"
                src={profileImages.profile_image}
              ></img>
            )}

            <Menu>
              <MenuButton className="rounded-full bg-white flex align-center justify-center absolute h-12 bottom-0 w-full cursor-pointer">
                <CameraIcon />
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom start"
                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
              >
                <MenuItem
                  as="label"
                  htmlFor="profileImageUpload"
                  className="flex align-center cursor-pointer"
                >
                  <div className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                    Upload image
                  </div>
                </MenuItem>

                <MenuItem onClick={() => removeProfile()}>
                  <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                    Remove profile image
                  </div>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>

          {/* Cropper */}
          {selectedImage && showCroper && (
            <div className="relative crop_image_cont">
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={isCroppingCover ? 9 / 3 : 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape={isCroppingCover ? "rect" : "round"}
              />
              <div
                className="flex align-center justify-center gap-5 right-4 absolute py-5 px-6"
                style={{ zIndex: 10 }}
              >
                <div
                  className="h-10 w-10 rounded-full hover flex align-center justify-center bg-gray-100"
                  onClick={() => saveImage()}
                >
                  <span class="material-symbols-outlined">check</span>
                </div>
                <div
                  className="h-10 w-10 rounded-full hover flex align-center justify-center bg-gray-100"
                  onClick={() => closeCroper()}
                >
                  <span class="material-symbols-outlined">close</span>
                </div>
              </div>
              <div
                className="crop_controls absolute bottom-2 left-0 right-0"
                style={{ zIndex: 10 }}
              >
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range w-1/2"
                />
              </div>
            </div>
          )}

          <input
            id="coverImageUpload"
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, true)}
            style={{ display: "none" }}
          />

          <input
            id="profileImageUpload"
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, false)}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileImage;
