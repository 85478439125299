import React, { useEffect, useState } from "react";
import Responce from "../../components/Responce";
import {
  getHubAccessList,
  getHubProfile,
  updateHubContactInfo,
} from "../../../db";
import { useParams } from "react-router-dom";
import app from "../../../config/app";
import Loader from "../../components/Loader";

function ContactInfo({ local }) {
  const [hubProfile, setHubProfile] = useState();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [loading, setLoading] = useState(true);
  let { id, page, focus } = useParams();
  const countries = app.countries;
  const [formData, setFormData] = useState({
    country: "",
    phone: "",
    email: "",
    website: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const info = await getHubProfile(id);
      console.log("Hub info - > ", info.hub_info[0]);
      setHubProfile(info.hub_info[0]);
      if (info.r_status) {
        setFormData({
          country: info.hub_info[0].country,
          phone: info.hub_info[0].phone,
          email: info.hub_info[0].email,
          website: info.hub_info[0].website,
        });
      }
    } catch (error) {
      console.error("Details:", error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await updateHubContactInfo(id, formData);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      // console.log(responce_card);
    }
    setLoading(false);
    // update local data list
    await getHubAccessList(app.current_user, 30);
    console.log("Form submitted res:", res);
  };

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Contact info</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5 relative">
          {loading && (
            <div className="absolute w-full flex align-center justify-center h-full rounded-2xl top-0 left-0 auth_loader_cont">
              <Loader />
            </div>
          )}
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="country">
                Select country
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">public</span>
                </div>
                <select
                  className="form-control w-full relative has_icon"
                  name="country"
                  id="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="">Select a country</option>
                  {Object.keys(countries).map((countryCode) => (
                    <option
                      key={countryCode}
                      value={countries[countryCode].code}
                    >
                      {countries[countryCode].name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="phone">
                Phone number
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">phone</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="phone"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone number without country code"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="email">
                Email address
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">mail</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your email address"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="website">
                Website
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">public</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="url"
                  name="website"
                  id="website"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="eg. www.yourwebsite.com"
                />
              </div>
            </div>
          </div>
          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
        {showResponceCard && responceCard}
      </form>
    </div>
  );
}

export default ContactInfo;
