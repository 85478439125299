import React, { useState, useEffect } from "react";
import { openDB } from "idb";
import app from "../../config/app";
import { Link } from "react-router-dom";
import { emitWebSocketEvent } from "../../db";
import Responce from "../components/Responce";

function ManageSkills() {
  const [mySkills, setMySkills] = useState([]);
  const [filter, setFilter] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const skills = app.skills; // Use the skills from the app config
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);

  // Initialize IndexedDB
  const initDB = async () => {
    return openDB("skills-db", 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("skills")) {
          db.createObjectStore("skills", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      },
    });
  };

  const saveSkillsToDB = async (skills) => {
    const db = await initDB();
    const tx = db.transaction("skills", "readwrite");
    const store = tx.objectStore("skills");
    await store.clear(); // Clear previous skills
    for (const skill of skills) {
      await store.put({ skill });
    }
    await tx.done;
    // console.log(skills);

    const data = {
      type: "saveSkillsToDb",
      user: app.current_user,
      skills,
    };

    const responce = await emitWebSocketEvent(data);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
    }
    console.log(responce);
  };

  const fetchSkillsFromDB = async () => {
    try {
      // Start fetching the WebSocket data and initializing IndexedDB in parallel
      const [db, skills] = await Promise.all([
        initDB(), // Initialize IndexedDB
        emitWebSocketEvent({ type: "getSkills", user: app.current_user }), // Fetch skills from WebSocket
      ]);

      console.log("Skills", skills.skills);

      // Fetch all existing skills from IndexedDB to avoid duplicates
      const readTx = db.transaction("skills", "readonly");
      const readStore = readTx.store;
      const existingSkills = await readStore.getAll();
      const existingSkillNames = new Set(
        existingSkills.map((item) => item.skill.skill)
      ); // Assuming each skill has a `name` field

      // Filter out duplicate skills
      const uniqueSkills = skills.skills.filter(
        (skill) => !existingSkillNames.has(skill.skill)
      );

      // Save only unique skills to IndexedDB
      if (uniqueSkills.length > 0) {
        const saveTx = db.transaction("skills", "readwrite");
        const saveStore = saveTx.store;
        const savePromises = uniqueSkills.map((skill) =>
          saveStore.put({ skill })
        );
        await Promise.all(savePromises);
        await saveTx.done; // Commit the transaction
      }

      // Return all saved skills from IndexedDB
      const savedSkills = await readStore.getAll();
      return savedSkills.map((item) => item.skill);
    } catch (error) {
      console.error("Error fetching skills from DB:", error);
      throw error;
    }
  };

  useEffect(() => {
    const loadSkills = async () => {
      try {
        const savedSkills = await fetchSkillsFromDB();
        setMySkills(savedSkills);
      } catch (err) {
        console.error("Failed to fetch skills from IndexedDB", err);
      }
    };

    loadSkills();
  }, []);

  const toggleSkills = (skill) => {
    if (mySkills.includes(skill)) {
      setMySkills(mySkills.filter((item) => item !== skill));
    } else {
      setMySkills([...mySkills, skill]);
    }
  };

  const filteredSkills = () => {
    return skills.filter(
      (item) =>
        item.skill.toLowerCase().includes(filter.toLowerCase()) ||
        item.job_category.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const groupedSkills = () => {
    return filteredSkills().reduce((acc, curr) => {
      if (!acc[curr.job_category]) {
        acc[curr.job_category] = [];
      }
      acc[curr.job_category].push(curr);
      return acc;
    }, {});
  };

  const handleSaveSkills = async () => {
    setIsSaving(true);
    setError(null);
    try {
      await saveSkillsToDB(mySkills);
      // alert("Skills saved to IndexedDB");
      console.log("Skills saved");
    } catch (err) {
      console.error("Failed to save skills to IndexedDB", err);
      setError("Failed to save skills. Please try again.");
    } finally {
      setIsSaving(false);
    }
    // Optionally, add logic to submit skills to the server
  };

  return (
    <div className="p-7 pt-2">
      <div className="py-2 pt-2 pb-0 sticky tags_filter">
        <div className="lg:flex lg:items-center lg:justify-between align-center">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-bold leading-4 sm:truncate sm:text-2xl sm:tracking-tight">
              Manage your skills
            </h2>
          </div>

          <div className="mt-5 flex lg:ml-4 lg:mt-0">
            <Link to="/Professional/myskills" className="ml-3 hidden sm:block">
              <button
                type="button"
                className="inline-flex items-center rounded-full bg-white px-3 h-10 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-8"
              >
                View
              </button>
            </Link>
            <span className="sm:ml-3">
              <button
                type="button"
                onClick={handleSaveSkills}
                className="inline-flex items-center rounded-full bg-blue-500 h-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 px-8 focus-visible:outline-offset-2 focus-visible:outline-blue-600 w-28 align-center flex justify-center text-center"
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </span>
          </div>
        </div>
        <div className="py-3">
          <input
            type="text"
            placeholder="Filter skills..."
            className="mb-0 p-2 border border-gray-300 rounded-full w-full px-6"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="w-full mt-3 h-6"></div>
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {Object.entries(groupedSkills()).map(([category, skills], index) => (
        <div key={index} className="mb-6">
          <div className="text-lg font-bold mb-2 px-2">
            {category.toUpperCase()}
          </div>
          <div className="flex flex-wrap">
            {skills.map((skill, idx) => (
              <div
                key={idx}
                className={`w-auto h-8 px-4 m-1 rounded-full flex items-center justify-center cursor-pointer ${
                  mySkills.includes(skill.skill)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
                onClick={() => toggleSkills(skill.skill)}
              >
                {skill.skill}
              </div>
            ))}
          </div>
        </div>
      ))}

      {showResponceCard && responceCard}
    </div>
  );
}

export default ManageSkills;
