export const emojis = [
  {
    title: "Smile",
    emojiCode: "U1f600",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f600/emoji.svg",
  },
  {
    title: "Smile-with-big-eyes",
    emojiCode: "U1f603",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f603/emoji.svg",
  },
  {
    title: "Grin",
    emojiCode: "U1f604",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f604/emoji.svg",
  },
  {
    title: "Grinning",
    emojiCode: "U1f601",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f601/emoji.svg",
  },
  {
    title: "Laughing",
    emojiCode: "U1f606",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/emoji.svg",
  },
  {
    title: "Grin-sweat",
    emojiCode: "U1f605",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f605/emoji.svg",
  },
  {
    title: "Joy",
    emojiCode: "U1f602",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f602/emoji.svg",
  },
  {
    title: "Rofl",
    emojiCode: "U1f923",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f923/emoji.svg",
  },
  {
    title: "Loudly-crying",
    emojiCode: "U1f62d",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f62d/emoji.svg",
  },
  {
    title: "Wink",
    emojiCode: "U1f609",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f609/emoji.svg",
  },
  {
    title: "Kissing",
    emojiCode: "U1f617",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f617/emoji.svg",
  },
  {
    title: "Kissing-smiling-eyes",
    emojiCode: "U1f619",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f619/emoji.svg",
  },
  {
    title: "Kissing-closed-eyes",
    emojiCode: "U1f61a",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f61a/emoji.svg",
  },
  {
    title: "Kissing-heart",
    emojiCode: "U1f618",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f618/emoji.svg",
  },
  {
    title: "Heart-face",
    emojiCode: "U1f970",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f970/emoji.svg",
  },
  {
    title: "Heart-eyes",
    emojiCode: "U1f60d",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60d/emoji.svg",
  },
  {
    title: "Star-struck",
    emojiCode: "U1f929",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f929/emoji.svg",
  },
  {
    title: "Partying-face",
    emojiCode: "U1f973",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f973/emoji.svg",
  },
  {
    title: "Melting",
    emojiCode: "U1fae0",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1fae0/emoji.svg",
  },
  {
    title: "Upside-down-face",
    emojiCode: "U1f643",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f643/emoji.svg",
  },
  {
    title: "Slightly-happy",
    emojiCode: "U1f642",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f642/emoji.svg",
  },
  {
    title: "Happy-cry",
    emojiCode: "U1f972",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f972/emoji.svg",
  },
  {
    title: "Holding-back-tears",
    emojiCode: "U1f979",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f979/emoji.svg",
  },
  {
    title: "Blush",
    emojiCode: "U1f60a",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60a/emoji.svg",
  },
  {
    title: "Warm-smile",
    emojiCode: "U263a Fe0f",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/263a_fe0f/emoji.svg",
  },
  {
    title: "Relieved",
    emojiCode: "U1f60c",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60c/emoji.svg",
  },
  {
    title: "Smirk",
    emojiCode: "U1f60f",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60f/emoji.svg",
  },
  {
    title: "Drool",
    emojiCode: "U1f924",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f924/emoji.svg",
  },
  {
    title: "Yum",
    emojiCode: "U1f60b",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60b/emoji.svg",
  },
  {
    title: "Stuck-out-tongue",
    emojiCode: "U1f61b",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f61b/emoji.svg",
  },
  {
    title: "Squinting-tongue",
    emojiCode: "U1f61d",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f61d/emoji.svg",
  },
  {
    title: "Winky-tongue",
    emojiCode: "U1f61c",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f61c/emoji.svg",
  },
  {
    title: "Zany-face",
    emojiCode: "U1f92a",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f92a/emoji.svg",
  },
  {
    title: "Woozy",
    emojiCode: "U1f974",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f974/emoji.svg",
  },
  {
    title: "Pensive",
    emojiCode: "U1f614",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f614/emoji.svg",
  },
  {
    title: "Sleepy",
    emojiCode: "U1f62a",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f62a/emoji.svg",
  },
  {
    title: "Sleeping",
    emojiCode: "U1f634",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f634/emoji.svg",
  },
  {
    title: "Snore",
    emojiCode: "U1f62a",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f62a/emoji.svg",
  },
  {
    title: "Dream",
    emojiCode: "U1f634",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f634/emoji.svg",
  },
  {
    title: "Mask",
    emojiCode: "U1f637",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f637/emoji.svg",
  },
  {
    title: "Thermometer",
    emojiCode: "U1f912",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f912/emoji.svg",
  },
  {
    title: "Bandage",
    emojiCode: "U1f915",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f915/emoji.svg",
  },
  {
    title: "Nausea",
    emojiCode: "U1f922",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f922/emoji.svg",
  },
  {
    title: "Head-exploding",
    emojiCode: "U1f92f",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f92f/emoji.svg",
  },
  {
    title: "Hot",
    emojiCode: "U1f975",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f975/emoji.svg",
  },
  {
    title: "Cold",
    emojiCode: "U1f976",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f976/emoji.svg",
  },
  {
    title: "Sick",
    emojiCode: "U1f927",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f927/emoji.svg",
  },
  {
    title: "Woozy-face",
    emojiCode: "U1f974",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f974/emoji.svg",
  },
  {
    title: "Fever",
    emojiCode: "U1f912",
    category: "Smileys and emotions",
    src: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f912/emoji.svg",
  },
];
