import React, { useState, useEffect } from "react";
import app from "../../config/app";
import {
  saveLanguageToIndexedDB,
  getLanguageFromIndexedDB,
  pollIndexedDB,
  socketListeners,
  socket,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function Language({ local }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [filter, setFilter] = useState("");
  const system_languages = app.languages;

  useEffect(() => {
    const fetchLanguage = async () => {
      const savedLanguage = await getLanguageFromIndexedDB();
      setSelectedLanguage(savedLanguage || ""); // Initialize with stored language or empty string
    };

    fetchLanguage();
    // Listen for updates from the server
    console.log(local);
    // alert(local);
  }, [local]);

  const handleLanguageUpdate = async (language) => {
    setSelectedLanguage(language);
    updateLocalStorage({ type: "updateLanguage", data: language });
    const responce = await saveLanguageToIndexedDB(language);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  const filteredLanguages = system_languages.filter((language) =>
    language.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="p-7">
      <div className="text-xl font-semibold mb-4">Language settings</div>
      <div className="sticky tags_filter py-2">
        <input
          type="text"
          placeholder="Search language..."
          className="mb-4 p-2 border border-gray-300 rounded-xl w-full px-6"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <div className="px-2 py-3 pt-0">Select a language below</div>
      <div className="language_list border-1 rounded-2xl px-2 py-2">
        {filteredLanguages.map((language, index) => (
          <div
            key={index}
            onClick={() => handleLanguageUpdate(language)}
            className={`p-3 cursor-pointer ${
              selectedLanguage === language
                ? "active bg-gray-200 select_list rounded-xl text-black"
                : ""
            }`}
          >
            {language}
          </div>
        ))}
      </div>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Language;
