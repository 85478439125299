import React from "react";

function MediaListDummy() {
  return (
    <div className="px-1 py-1">
      <div className="grid grid-cols-3 gap-1 animate__animated animate__slideInUp animate_fast">
        <div
          className="w-full bg-gray-200 rounded-sm loader"
          style={{ aspectRatio: 1 }}
        ></div>
        <div
          className="w-full bg-gray-200 rounded-sm loader"
          style={{ aspectRatio: 1 }}
        ></div>
        <div
          className="w-full bg-gray-200 rounded-sm loader"
          style={{ aspectRatio: 1 }}
        ></div>
        <div
          className="w-full bg-gray-200 rounded-sm loader"
          style={{ aspectRatio: 1 }}
        ></div>
        <div
          className="w-full bg-gray-200 rounded-sm loader"
          style={{ aspectRatio: 1 }}
        ></div>
        <div
          className="w-full bg-gray-200 rounded-sm loader"
          style={{ aspectRatio: 1 }}
        ></div>
      </div>
    </div>
  );
}

export default MediaListDummy;
