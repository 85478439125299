import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import FollowList from "./components/FollowList";
import JoinHubList from "./components/JoinHubList";
import NotificationsVibes from "./Notifications/NotificationsVibes";
import NotificationsJobs from "./Notifications/NotificationsJobs";
import NotificationsHubs from "./Notifications/NotificationsHubs";
import NotificationsFollow from "./Notifications/NotificationsFollow";
import NotificationsAll from "./Notifications/NotificationsAll";
import app from "../config/app";
import FollowListReused from "./components/FollowListReused";
import CopyRight from "./components/CopyRight";
import { getUserNotificationList } from "../db";

function Notifications({ local }) {
  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname;
  let { name } = useParams();

  let component = <NotificationsAll />;
  const paths = [
    {
      id: 0,
      title: "All",
      path: "/Notifications",
    },
    {
      id: 1,
      title: "Vibes",
      path: "/Notifications/vibes",
    },
    {
      id: 2,
      title: "Jobs & Gigs",
      path: "/Notifications/jobs",
    },
    {
      id: 3,
      title: "People",
      path: "/Notifications/people",
    },
    {
      id: 4,
      title: "Hubs",
      path: "/Notifications/hubs",
    },
  ];

  if (!name) {
    name = "all";
    currentPath = "/Notifications";
  }

  if (name == "vibes") {
    component = <NotificationsVibes />;
  } else if (name == "jobs") {
    component = <NotificationsJobs />;
  } else if (name == "hubs") {
    component = <NotificationsHubs />;
  } else if (name == "people") {
    component = <NotificationsFollow />;
  } else {
    component = <NotificationsAll />;
  }

  const menu = (
    <>
      <div className="p-0 sticky_profile_header flex align-center w-full">
        <div className="px-4 flex align-center headers w-full">
          {paths.map((item, index) => (
            <Link
              key={item.id}
              to={item.path}
              className={currentPath == item.path ? "active_tab" : ""}
            >
              <div className="h-10 px-4 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
                {item.title}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="min-h-screen flex-1">
        <div className="px-4 headers p-0">
          <div
            className="feed_list_cont h-full w-full flex align-center"
            style={{ width: "100%" }}
          >
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Updates</div>
            </div>
          </div>
        </div>
        <div className="w-full feed_list_cont">{menu}</div>

        <div className="p-0 feed_list_cont">
          {/* all settings goes here */}
          {component}
        </div>
      </div>

      {window.innerWidth > app.mobile_width && (
        <div className="min-h-screen flex right_cont">
          <TopUserMenu local={local} />
          <div className="p-4">
            <FollowListReused more="/discover/people" />
            <hr className="line" />
            <div className="mt-7 mb-16">
              <CopyRight />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Notifications;
