import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className='flex align-center justify-center min-h-screen w-full'>
      <main className="grid min-h-full place-items-center px-6 py-2 pb-10 rounded-lg">
        <div className="text-center align-center">
          <img src='/images/search.gif' width={200} height={200} className='m-auto'/>
          <p className="text-base font-semibold text-xl2">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 ">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/Feeds">
              <button className="rounded-full bg-black px-6 py-2.5 border-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Go back home
              </button>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}

export default NotFound;
