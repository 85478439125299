import React from "react";

function VibeDummy() {
  return (
    <>
      <div className="px-4 sm-p-1">
        <div className="flex align-start mb-4 animate__animated animate__slideInUp animate_fast mt-4 gap-2">
          <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
          <div className="w-full px-3">
            <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
          </div>
          <div className="h-8 w-36 bg-gray-200 loader rounded-full"></div>
          <div className="h-8 w-8 min-w-8 bg-gray-200 loader rounded-full"></div>
        </div>
        <div className="flex animate__animated animate__slideInUp animate_fast">
          <div className="h-48 w-full bg-gray-200 loader rounded-xl"></div>
        </div>
      </div>
    </>
  );
}

export default VibeDummy;
