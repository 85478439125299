import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getApplicationsByBusiness,
  getUserProfessionalInfo,
} from "../../../db";
import Loader from "../../components/Loader";
import About from "./Profile/About";
import Skills from "./Profile/Skills";
import Education from "./Profile/Education";
import Experience from "./Profile/Experience";
import Portfolio from "./Profile/Portfolio";
import Applications from "./Profile/Applications";
import Attachment from "./Profile/Attachment";

function CandidateProfile() {
  let { id, page, focus, focus_id } = useParams();
  const location = useLocation();
  let currentPath = location.pathname;

  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState();
  const [educationList, setEducationList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);

  let component = "";

  const profile_paths = [
    {
      id: 1,
      title: "About",
      path: "/admin/" + id + "/" + page + "/" + focus + "/about",
    },
    {
      id: 2,
      title: "Skills",
      path: "/admin/" + id + "/" + page + "/" + focus + "/skills",
    },
    {
      id: 3,
      title: "Education",
      path: "/admin/" + id + "/" + page + "/" + focus + "/education",
    },
    {
      id: 4,
      title: "Experience",
      path: "/admin/" + id + "/" + page + "/" + focus + "/experience",
    },
    {
      id: 5,
      title: "Applications",
      path: "/admin/" + id + "/" + page + "/" + focus + "/applications",
    },
    {
      id: 6,
      title: "Portfolio",
      path: "/admin/" + id + "/" + page + "/" + focus + "/portfolio",
    },
    {
      id: 7,
      title: "Attachment",
      path: "/admin/" + id + "/" + page + "/" + focus + "/attachment",
    },
  ];

  const getUserInfo = async () => {
    const res = await getUserProfessionalInfo(focus);
    const res_applications = await getApplicationsByBusiness(focus, id);
    // only get data if there are job application to this business page
    if (res_applications.applications.length > 0) {
      setApplicationList(res_applications.applications);
      setUserProfile(res.profile[0]);
      setEducationList(res.education);
      setExperienceList(res.experience);
      setPortfolioList(res.portfolio);
      setAttachmentList(res.attachment);
      console.log(res.attachment);
      setLoading(false);
    }

    console.log("User profile =>", res.profile[0]);
  };

  useEffect(() => {
    if ((currentPath = "/admin/" + id + "/" + page + "/" + focus)) {
      currentPath = "/admin/" + id + "/" + page + "/" + focus + "/about";
    }
    getUserInfo();
  }, []);

  //   we are using `focus_id` as tab name
  if (focus_id == "about") {
    component = <About data={userProfile} />;
  }

  if (focus_id == "skills") {
    component = <Skills data={userProfile} />;
  }

  if (focus_id == "education") {
    component = <Education data={educationList} />;
  }

  if (focus_id == "experience") {
    component = <Experience data={experienceList} />;
  }

  if (focus_id == "portfolio") {
    component = <Portfolio data={portfolioList} />;
  }

  if (focus_id == "applications") {
    component = (
      <Applications
        data={applicationList}
        onRefreshData={() => getUserInfo()}
      />
    );
  }

  if (focus_id == "attachment") {
    component = <Attachment data={attachmentList} userProfile={userProfile} />;
  }

  return (
    <div className="profile_info_cont px-3 py-2">
      {loading && (
        <div className="min-h-72 flex align-center justify-center w-full">
          <Loader />
        </div>
      )}

      {!loading && (
        <>
          <div class="flex align-center gap-3 py-3">
            <div class="w-28 h-28 min-w-28 min-h-28 rounded-full bg-gray-100">
              <img
                src={userProfile.profile_image}
                class="w-28 h-28 min-w-28 min-h-28 rounded-full"
              />
            </div>
            <div class=" py-3">
              <div class="text-xl font-semibold flex relative align-center gap-2">
                <span>{userProfile.fullname}</span>

                {userProfile.verification_status == "true" && (
                  <svg
                    className="verification_icon"
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#e8eaed"
                    style={{ marginLeft: 0 }}
                  >
                    <g>
                      <rect fill="none" height="24" width="24"></rect>
                    </g>
                    <g>
                      <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"></path>
                    </g>
                  </svg>
                )}
              </div>
              <div class="text-mute">@{userProfile.username}</div>
              <div class="text font-semibold">
                {userProfile.current_job_title}
              </div>
              <div class="flex align-center jsutify-center gap-2">
                <div>Joined - {userProfile.join_date}</div>
              </div>
              <Link
                to={`/profile/${userProfile.username}/vibes`}
                className="hover_decoration_link text-sm flex gap-2 text-mute"
              >
                <span> View public profile</span>
                <span class="material-symbols-outlined text-sm">
                  arrow_right_alt
                </span>
              </Link>
            </div>
          </div>
          <div className="px-3 flex align-center headers w-full profile_header justify-start">
            {profile_paths.map((item, index) => (
              <Link
                key={item.id}
                to={item.path}
                className={currentPath === item.path ? "active_tab" : ""}
              >
                <div className="h-10 px-3 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
                  {item.title}
                </div>
              </Link>
            ))}
          </div>
          <div className="component_cont">{component}</div>
        </>
      )}
    </div>
  );
}

export default CandidateProfile;
