import { ArrowRightIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getBusinessDashboardDetails } from "../../db";
import app from "../../config/app";

function Dashboard({ local, business }) {
  const navigate = useNavigate();
  const [businessProfile, setBusinessProfile] = useState();
  let { id, page, focus } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    if (business) {
      setBusinessProfile(business);
    }
  }, [page, id, local]);

  const [loading, setLoading] = useState(true);
  const [pageDetails, setPageDetails] = useState({
    pageViewsCount: 0,
    businessName: "",
    engagementCount: 0,
    currentPrev: "",
    followersCount: 0,
    recentVibes: [],
  });

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const dashboard = await getBusinessDashboardDetails(id);
      console.log("Details - > ", dashboard);
      setPageDetails(dashboard.data);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <>
      <div>
        <div>
          <div className="text-xl font-semibold py-3">Business dashboard</div>
        </div>
        <div className="flex align-center justify-between mt-4">
          <div className="text-2xl">
            Hi, <div className="font-bold">{business.business_name}</div>
          </div>
          <Link
            to={`/page/${business?.username}`}
            className="flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
          >
            <div>View page</div>
            <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              <ArrowRightIcon width={15} height={15} />
            </div>
          </Link>
        </div>
        <div className="grid grid-cols-3 sm-grid-cols-1 align-start justify-between gap-5 cards_conatiner py-4 mt-4">
          {/* show page views */}
          <div class="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
            <div class="flex w-full align-center px-3">
              <div class="d-flex flex-column my-7 flex-1">
                <span class="font-semibold text-4xl">
                  {pageDetails?.pageViewsCount}
                </span>
                <div class="mt-3">
                  <span class="mt-3 text-gray-500">Page views</span>
                </div>
              </div>
              <div class="card_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                >
                  <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"></path>
                </svg>
              </div>
            </div>
          </div>
          {/* show engagements */}
          <div class="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
            <div class="flex w-full align-center px-3">
              <div class="d-flex flex-column my-7 flex-1">
                <span class="font-semibold text-4xl">
                  {pageDetails?.engagementCount}
                </span>
                <div class="mt-3">
                  <span class="mt-3 text-gray-500">Engagement</span>
                </div>
              </div>
              <div class="card_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                >
                  <path d="M350-63q-46 0-82.5-24T211-153q-16 21-40.5 32.5T120-109q-51 0-85.5-35T0-229q0-43 28-77.5T99-346q-14-20-21.5-42.5T70-436q0-40 20.5-75t57.5-57q5 18 13.5 38.5T181-494q-14 11-22 26.5t-8 32.5q0 56 46 69t87 21l19 32q-11 32-19 54.5t-8 40.5q0 30 21.5 52.5T350-143q38 0 63-34t41-80q16-46 24.5-93t13.5-72l78 21q-9 45-22 103t-36.5 110.5Q488-135 449.5-99T350-63ZM120-189q17 0 28.5-11.5T160-229q0-17-11.5-28.5T120-269q-17 0-28.5 11.5T80-229q0 17 11.5 28.5T120-189Zm284-158q-46-41-83.5-76.5t-64.5-69q-27-33.5-41.5-67T200-629q0-65 44.5-109.5T354-783q4 0 7 .5t7 .5q-4-10-6-20t-2-21q0-50 35-85t85-35q50 0 85 35t35 85q0 11-2 20.5t-6 19.5h14q60 0 102 38.5t50 95.5q-18-3-40.5-3t-41.5 2q-7-23-25.5-38T606-703q-35 0-54.5 20.5T498-623h-37q-35-41-54.5-60.5T354-703q-32 0-53 21t-21 53q0 23 13 47.5t36.5 52q23.5 27.5 57 58.5t74.5 67l-57 57Zm76-436q17 0 28.5-11.5T520-823q0-17-11.5-28.5T480-863q-17 0-28.5 11.5T440-823q0 17 11.5 28.5T480-783ZM609-63q-22 0-43.5-6T524-88q11-14 22-33t20-35q11 7 22 10t22 3q32 0 53.5-22.5T685-219q0-19-8-41t-19-54l19-32q42-8 87.5-21t45.5-69q0-40-29.5-58T716-512q-42 0-98 16t-131 41l-21-78q78-25 139-42t112-17q69 0 121 41t52 115q0 25-7.5 47.5T861-346q43 5 71 39.5t28 77.5q0 50-34.5 85T840-109q-26 0-50.5-11.5T749-153q-20 42-56.5 66T609-63Zm232-126q17 0 28-11.5t11-28.5q0-17-11.5-29T840-270q-17 0-28.5 11.5T800-230q0 17 12 29t29 12Zm-721-40Zm360-594Zm360 593Z"></path>
                </svg>
              </div>
            </div>
          </div>
          {/* show page followers */}
          <div class="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
            <div class="flex w-full align-center px-3">
              <div class="d-flex flex-column my-7 flex-1">
                <span class="font-semibold text-4xl">
                  {pageDetails?.followersCount}
                </span>
                <div class="mt-3">
                  <span class="mt-3 text-gray-500">Followers</span>
                </div>
              </div>
              <div class="card_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                >
                  <path d="M412-168q45-91 120-121.5T660-320q23 0 45 4t43 10q24-38 38-82t14-92q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 45 11.5 86t34.5 76q41-20 85-31t89-11q32 0 61.5 5.5T500-340q-23 12-43.5 28T418-278q-12-2-20.5-2H380q-32 0-63.5 7T256-252q32 32 71.5 53.5T412-168Zm68 88q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM380-420q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T440-560q0-25-17.5-42.5T380-620q-25 0-42.5 17.5T320-560q0 25 17.5 42.5T380-500Zm280 120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM480-480Z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* other card */}
        <div class="row m-0 mt-3 w-full gap-4 sm-flex-col">
          <div class="w-full fit_height">
            <div class="card bg-white shadow-sm border-none rounded-xl p-4">
              <div class="card-body d-flex flex-column flex-start">
                <div class="mb-2">
                  <h4 class="">
                    <div className="font-semibold text-left text-lg">
                      Verify your business today.
                    </div>
                    <div class="font-bolder">Let people trust your brand.</div>
                  </h4>
                  <div class="py-10 text-center">
                    <img
                      src="/images/2.svg"
                      class="theme-light-show w-200px"
                      alt=""
                    />
                    <img
                      src="/images/2-dark.svg"
                      class="theme-dark-show w-200px"
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-center mb-1 flex">
                  <Link
                    to={`/admin/${id}/verification`}
                    class="btn btn-sm btn-primary me-2"
                  >
                    Verify now
                  </Link>
                  <a class="btn btn-sm btn-light">Learn More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full rounded-xl">
            <div class="card bg-white shadow-sm border-none rounded-xl py-4">
              <div class="card-body card-flush h-xl-50 px-2">
                <div class="card-header px-4">
                  <h3 class="text-lg font-semibold">Recent posts</h3>
                </div>
              </div>
              {pageDetails?.recentVibes.length > 0 && (
                <div class="chart_containter p-0 mt-3">
                  <table className="table-auto">
                    <tbody>
                      {pageDetails?.recentVibes.length > 0 &&
                        pageDetails?.recentVibes.map((item, index) => (
                          <tr class="open_post hover rounded-xl overflow-hidden align-top">
                            <td>
                              <Link to={`/vibe/${item.activity_id}`}>
                                {Array.isArray(item.uploads) &&
                                  item.uploads.length > 0 &&
                                  item.uploads.map((media, index) => (
                                    <>
                                      {media.row.mediatype == "image" ? (
                                        <img
                                          src={
                                            app.media_url +
                                            "/posts/small/" +
                                            media.row.filename
                                          }
                                          class="h-full object-cover rounded-xl min-h-20 min-w-20"
                                          width="50"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            app.media_url +
                                            "/posts/" +
                                            media.row.thumb_filename
                                          }
                                          class="h-full object-cover rounded-xl min-h-20 min-w-20"
                                          width="50"
                                        />
                                      )}
                                    </>
                                  ))}
                              </Link>
                            </td>
                            <td>
                              <small class="block">{item.parent_post}</small>
                            </td>
                            <td class="text-right">
                              <div>
                                Post engagement
                                <div class="font-bold">{item.engagement} </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {/* if no vibes */}
              {pageDetails?.recentVibes.length == 0 && !loading && (
                <div className="mt-2 mb-2 px-4">
                  <div className="flex align-center">
                    <img
                      src="/images/beam-woman-meditating-in-lotus-position.gif"
                      className="w-36 max-w-36 min-w-36 h-auto sm_image"
                      with="50"
                    />
                    <div className="px-3">
                      <div className="font-bold text-lg">No vibes yet</div>
                      <div>Recent posted vibes will appear here.</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
