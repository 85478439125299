import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import HubVibes from "./Settings/Profile/HubVibes";
import HubMedia from "./Settings/Profile/HubMedia";
import {
  getHubProfileByUsername,
  submitHubBookmark,
  submitJoinHub,
} from "../../db";
import Loader from "../components/Loader";
import app from "../../config/app";
import HubMembers from "./Settings/Profile/HubMembers";
import HubPinned from "./Settings/Profile/HubPinned";
import HubAbout from "./Settings/Profile/HubAbout";
import CopyRight from "../components/CopyRight";

function HubProfile({ username, local }) {
  const location = useLocation();
  const [hubProfile, setHubProfile] = useState();
  const [members, setMembers] = useState([]);
  const [joinStatus, setJoinStatus] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);
  const [totalMembers, setTotalMembers] = useState(0);
  const [canEdit, setCanEdit] = useState(false);
  const [userInfo, setUserInfo] = useState();

  let currentPath = location.pathname;
  const { focus, focusid } = useParams();
  const [loading, setLoading] = useState(true);
  let component = <HubVibes local={local} username={username} />;

  useEffect(() => {
    fetchData();
  }, [username]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const info = await getHubProfileByUsername(username);
      console.log("Hub info - > ", info.profile[0]);
      setHubProfile(info.profile[0]);
      setMembers(info.members);
      setJoinStatus(info.join_status);
      setTotalMembers(info.total_members);
      setSaveStatus(info.save_status);
      setCanEdit(info.can_edit);
      setUserInfo(info.user_info[0]);
    } catch (error) {
      console.error("Details:", error);
    }
    setLoading(false);
  };

  const profile_paths = [
    {
      id: 1,
      title: "Vibes",
      path: "/communities/" + username + "/vibes",
    },
    // {
    //   id: 3,
    //   title: "Pinned",
    //   path: "/communities/" + username + "/pinned",
    // },
    {
      id: 4,
      title: "Media",
      path: "/communities/" + username + "/media",
    },
    {
      id: 5,
      title: "Members (" + totalMembers + ")",
      path: "/communities/" + username + "/members",
    },
    {
      id: 6,
      title: "About hub",
      path: "/communities/" + username + "/about",
    },
  ];

  if (!focus) {
    currentPath = "/communities/" + username + "/vibes";
  }

  if (focus === "vibes") {
    component = <HubVibes local={local} username={username} />;
  }

  if (focus === "media") {
    component = <HubMedia local={local} username={username} />;
  }

  if (focus === "members") {
    component = <HubMembers local={local} username={username} />;
  }

  if (focus === "pinned") {
    component = <HubPinned local={local} username={username} />;
  }

  if (focus === "about") {
    component = (
      <HubAbout
        local={local}
        username={username}
        data={hubProfile}
        user={userInfo}
      />
    );
  }

  const handleSave = async (hub_id) => {
    saveStatus ? setSaveStatus(false) : setSaveStatus(true);
    // alert(username);
    try {
      const data = await submitHubBookmark(hub_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleJoin = async (hub_id) => {
    if (joinStatus) {
      setJoinStatus(false);
      setTotalMembers(totalMembers - 1);
    } else {
      setJoinStatus(true);
      setTotalMembers(totalMembers + 1);
    }
    try {
      const data = await submitJoinHub(hub_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <div className="relative pr-0 sm-p-0">
      {loading && (
        <div className="absolute w-full flex align-center justify-center h-full rounded-2xl top-0 left-0 auth_loader_cont">
          <Loader />
        </div>
      )}
      <div className="w-full relative">
        <div className="w-full h-72 bg-gray-100 rounded-xl -mt-16 relative">
          <img
            src={
              hubProfile?.cover_image == ""
                ? "/images/hubcover.png"
                : app.media_url + "/thumb/" + hubProfile?.cover_image
            }
            className="w-full h-full min-w-full object-cover rounded-2xl"
          />
          <div className="absolute top-20 right-20">
            <button className="w-10 h-10 flex align-center justify-center hover rounded-full absolute -right-14">
              <span class="material-symbols-outlined">more_horiz</span>
            </button>
          </div>
        </div>
        <div className="-mt-24 flex align-center gap-0 w-full justify-center flex-col relative z-8">
          <div className="w-36 h-36 rounded-full bg-gray-200 rounded-xl border-4">
            <img
              src={
                hubProfile?.profile_image == ""
                  ? "/images/hubuser.png"
                  : app.media_url + "/thumb/small/" + hubProfile?.profile_image
              }
              className="w-full h-full min-w-full rounded-full object-cover"
            />
          </div>
          <div className="mb-3">
            <div className="flex align-center gap-3 relative justify-center">
              <div className="font-bold text-3xl text-center">
                {hubProfile?.hub_name}
              </div>
            </div>
            <div className="font-semibold text-center">
              @{hubProfile?.username}
            </div>
            <div className="align-center justify-center w-full flex mt-2 gap-2">
              {!joinStatus && (
                <div
                  className={`h-14 w-40 rounded-full font-normal align-center justify-center text-center flex follow_btn ${
                    joinStatus ? "following" : "follow"
                  }`}
                  onClick={() => handleJoin(hubProfile?.hub_id)}
                  style={{ height: 36, fontWeight: "500", fontSize: 16 }}
                >
                  {joinStatus ? "Joined" : "Join"}
                </div>
              )}

              <div
                onClick={() => handleSave(hubProfile?.hub_id)}
                className="border-1 flex align-center rounded-full px-3 py-2 w-full text-center justify-center text-sm hover"
              >
                {saveStatus ? "Saved" : "Save"}
              </div>
              {canEdit && (
                <Link
                  to={`/hubadmin/${hubProfile.hub_id}/settings`}
                  className="btn"
                >
                  Manage hub
                </Link>
              )}
            </div>
          </div>
          <div className="flex align-center gap-0">
            {members.length > 1 &&
              members.map((item, index) => (
                <Link
                  key={index}
                  to={`/profile/${item.user_profile[0]?.username}/vibes`}
                >
                  <img
                    src={item.user_profile[0]?.profile_image}
                    className="w-10 h-10 min-w-10 min-h-10 object-cover rounded-full bg-gray-100 flex align-center justify-center -ml-2 hover"
                  />
                </Link>
              ))}
            {totalMembers > 10 && (
              <div className="w-10 h-10 rounded-full bg-gray-200 flex align-center justify-center -ml-2 hover z-10">
                {totalMembers > 10 ? "+" + totalMembers - 10 : totalMembers}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="px-4 flex align-center headers w-full profile_header justify-center sm-justify-start">
        {profile_paths.map((item, index) => (
          <Link
            key={item.id}
            to={item.path}
            className={currentPath === item.path ? "active_tab" : ""}
          >
            <div className="h-10 px-4 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
              {item.title}
            </div>
          </Link>
        ))}
      </div>
      <div className="">{component}</div>
      <div className="border-top pt-5 mt-5 text-center px-10 sm-p-1">
        <CopyRight />
      </div>
    </div>
  );
}

export default HubProfile;
