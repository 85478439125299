import { ArrowRightIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addUserAsHubAdmin,
  getHubDashboardDetails,
  removeMemberHub,
  removeUserHubAdmin,
  updateLocalStorage,
} from "../../db";
import TableDummy from "../components/Dummies/TableDummy";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
// alerts
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import app from "../../config/app";
const MySwal = withReactContent(Swal);

function Overview({ local }) {
  const navigate = useNavigate();
  let { id, page, focus } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const [loading, setLoading] = useState(true);
  const [hubDetails, setHubDetails] = useState({
    membersCount: 0,
    vibesCount: 0,
    currentPrev: "",
    followersCount: 0,
    recentMembers: [],
  });

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const dashboard = await getHubDashboardDetails(id);
      console.log("Details - > ", dashboard);
      setHubDetails(dashboard.data);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const addAdmin = async (activity_id) => {
    // add user as admin
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Are you sure add this user as admin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continue",
    });

    if (result.isConfirmed) {
      const res = await addUserAsHubAdmin(id, activity_id);
      console.log("Adding res - ", res);
      updateLocalStorage({ type: "updateHubMembers", data: res });
      fetchData();

      if (res.r_status) {
        MySwal.fire("Remove!", res.responce, "success");
      } else {
        MySwal.fire("Denied!", res.responce, "danger");
      }
    }

    // console.log(result);
  };

  const removeAdmin = async (activity_id) => {
    // remove user as amin
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Are you sure to remove admin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continue",
    });

    if (result.isConfirmed) {
      const res = await removeUserHubAdmin(id, activity_id);
      console.log("Remove res - ", res);
      updateLocalStorage({ type: "updateHubMembers", data: res });
      fetchData();

      if (res.r_status) {
        MySwal.fire("Remove!", res.responce, "success");
      } else {
        MySwal.fire("Denied!", res.responce, "danger");
      }
    }
  };

  const removeMember = async (activity_id) => {
    // add user as admin
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Are you sure to remove this member",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continue",
    });

    if (result.isConfirmed) {
      const res = await removeMemberHub(id, activity_id);
      console.log("Remove res - ", res);
      updateLocalStorage({ type: "updateHubMembers", data: res });
      fetchData();
      if (res.r_status) {
        MySwal.fire("Remove!", res.responce, "success");
      } else {
        MySwal.fire("Denied!", res.responce, "danger");
      }
    }
  };

  return (
    <div>
      <div className="flex align-center justify-between py-3">
        <div className="text-xl font-semibold py-3">Community overview</div>
        <Link
          to={`/hubadmin/${id}/postedjobs`}
          className="flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
        >
          <div>View members</div>
          <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
            <ArrowRightIcon width={15} height={15} />
          </div>
        </Link>
      </div>
      <div className="grid grid-cols-3 align-start justify-between gap-5 cards_conatiner py-4 mt-0">
        {/* show page views */}
        <div className="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
          <div className="flex w-full align-center px-3">
            <div className="d-flex flex-column my-7 flex-1">
              <span className="font-semibold text-4xl animate__animated animate__bounceInUp">
                {hubDetails.membersCount}
              </span>
              <div className="mt-3">
                <span className="mt-3 text-gray-500">Members</span>
              </div>
            </div>
            <div className="card_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"></path>
              </svg>
            </div>
          </div>
          <div className="px-3 pl-1 border-top pt-2">
            <Link
              to={`/hubadmin/${id}/members`}
              className="flex align-center justify-between rounded-full gap-2 hover px-3 pr-0 w-auto"
            >
              <div>View members</div>
              <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                <ArrowRightIcon width={15} height={15} />
              </div>
            </Link>
          </div>
        </div>
        {/* show engagements */}
        <div className="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
          <div className="flex w-full align-center px-3">
            <div className="d-flex flex-column my-7 flex-1">
              <span className="font-semibold text-4xl animate__animated animate__bounceInUp">
                {hubDetails.vibesCount}
              </span>
              <div className="mt-3">
                <span className="mt-3 text-gray-500">Vibes</span>
              </div>
            </div>
            <div className="card_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
              >
                <g>
                  <rect fill="none" height="24" width="24"></rect>
                </g>
                <g>
                  <g>
                    <g>
                      <path d="M17,12c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S19.76,12,17,12z M19.15,17.85l-1.79,1.79c-0.2,0.2-0.51,0.2-0.71,0 l-1.79-1.79C14.54,17.54,14.76,17,15.21,17h1.29v-2.5c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V17h1.29 C19.24,17,19.46,17.54,19.15,17.85z"></path>
                    </g>
                    <g>
                      <path d="M17,10c0.1,0,0.19,0.01,0.28,0.01L3,4v6l8,2l-8,2v6l7-2.95c0-0.02,0-0.03,0-0.05C10,13.13,13.13,10,17,10z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="px-3 pl-1 border-top pt-2">
            <Link
              to={`/hubadmin/${id}/overview/vibes`}
              className="flex align-center justify-between rounded-full gap-2 hover px-3 pr-0 w-auto"
            >
              <div>View vibes</div>
              <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                <ArrowRightIcon width={15} height={15} />
              </div>
            </Link>
          </div>
        </div>
        {/* show page followers */}
        <div className="card-body d-flex justify-content-between align-items-start flex-column w-full rounded-xl bg-gray-100 p-2">
          <div className="flex w-full align-center px-3">
            <div className="d-flex flex-column my-7 flex-1">
              <span className="font-semibold text-4xl animate__animated animate__bounceInUp">
                {hubDetails.followersCount}
              </span>
              <div className="mt-3">
                <span className="mt-3 text-gray-500">Follower</span>
              </div>
            </div>
            <div className="card_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
              >
                <g>
                  <rect fill="none" height="24" width="24"></rect>
                </g>
                <g>
                  <g>
                    <path d="M18,11c1.49,0,2.87,0.47,4,1.26V8c0-1.11-0.89-2-2-2h-4V4c0-1.11-0.89-2-2-2h-4C8.89,2,8,2.89,8,4v2H4 C2.89,6,2.01,6.89,2.01,8L2,19c0,1.11,0.89,2,2,2h7.68C11.25,20.09,11,19.08,11,18C11,14.13,14.13,11,18,11z M10,4h4v2h-4V4z"></path>
                    <path d="M18,13c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S20.76,13,18,13z M19.65,20.35l-2.15-2.15V15h1v2.79l1.85,1.85 L19.65,20.35z"></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="px-3 pl-1 border-top pt-2">
            <Link
              to={`/hubadmin/${id}/followers`}
              className="flex align-center justify-between rounded-full gap-2 hover px-3 pr-0 w-auto"
            >
              <div>View follower</div>
              <div className="w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
                <ArrowRightIcon width={15} height={15} />
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* other card */}

      <div className="w-full">
        <div className="overflow-hidden w-full bg-gray-100 rounded-xl py-5 pt-2">
          {/* all settings goes here */}
          <div className="px-4 text-xl font-semibold mt-5">Recent members</div>
          <div className="applicants">
            <div className="table-content">
              <div className="wrap-applicants table-responsive">
                {!loading && hubDetails.recentMembers.length == 0 && (
                  <div className="mt-2 mb-2 px-4">
                    <div className="flex align-center">
                      <img
                        src="/images/beam-woman-meditating-in-lotus-position.gif"
                        className="w-36 max-w-36 min-w-36 h-auto sm_image"
                        with="50"
                      />
                      <div className="px-3">
                        <div className="font-bold text-lg">No members yet</div>
                        <div>Recently joins will appear here.</div>
                      </div>
                    </div>
                  </div>
                )}
                {loading && <TableDummy />}
                {!loading && hubDetails.recentMembers.length > 0 && (
                  <table className="table-auto">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Join date</th>
                        <th className="left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hubDetails.recentMembers.length > 0 &&
                        hubDetails.recentMembers.map((item, index) => (
                          <tr>
                            <td>{item.user_profile[0].fullname}</td>
                            <td>{item.prev}</td>
                            <td>{item.date_time}</td>
                            <td>
                              <Menu>
                                <MenuButton className="h-10 w-10 bg-gray-100 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-3 post_attachment start_audio_recording">
                                  <span className="material-symbols-outlined">
                                    more_horiz
                                  </span>
                                </MenuButton>

                                <MenuItems
                                  transition
                                  anchor="bottom end"
                                  className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                                >
                                  {item.user_profile[0].activity_id !=
                                    app.current_user && (
                                    <MenuItem>
                                      <Link
                                        to={`/messages/${item.user_profile[0].username}`}
                                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                      >
                                        Message
                                      </Link>
                                    </MenuItem>
                                  )}

                                  <MenuItem>
                                    <Link
                                      to={`/profile/${item.user_profile[0].username}`}
                                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                    >
                                      View profile
                                    </Link>
                                  </MenuItem>

                                  {hubDetails.currentPrev == "owner" &&
                                    item.user_profile[0].activity_id !=
                                      app.current_user && (
                                      <MenuItem>
                                        <div
                                          target="_blank"
                                          onClick={() =>
                                            item.prev == "admin"
                                              ? removeAdmin(item.activity_id)
                                              : addAdmin(item.activity_id)
                                          }
                                          className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                        >
                                          {item.prev == "admin"
                                            ? "Remove admin"
                                            : "Add as admin"}
                                        </div>
                                      </MenuItem>
                                    )}

                                  {(hubDetails.currentPrev == "owner" ||
                                    hubDetails.currentPrev == "admin") &&
                                    item.user_profile[0].activity_id !=
                                      app.current_user && (
                                      <>
                                        <hr className="line_sm" />
                                        <MenuItem>
                                          <div
                                            onClick={() =>
                                              removeMember(item.activity_id)
                                            }
                                            className="group text-red-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                          >
                                            Remove member
                                          </div>
                                        </MenuItem>
                                      </>
                                    )}
                                </MenuItems>
                              </Menu>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
