import { openDB } from "idb";
import io from "socket.io-client";
import app from "./config/app";
import axios from "axios";
import { useState } from "react";

const DB_VERSION = 1;
// user
const DB_NAME = "app";
const META_USER_STORE = "meta_user";
const META_BUSINISS_STORE = "meta_business";
const FOLLOW_LIST_STORE = "follow_list";
const FOLLOWERS_LIST_STORE = "followers_list";
const HUBS_JOIN_STORE = "hubs_join";
const BUSINESS_FOLLOW_STORE = "requests";
const POSTS_STORE = "posts";
const CHATS_STORE = "chats";
const STORE_NAME = "settings";
const BUSINESS_ACCESS_STORE = "access";
const APPLICATION_STORE = "my_applications";
const CONVERSATION_USER_STORE = "conversation_user";
const META_URL_STORE = "metadata";
const MSG_PINNED_STORE = "msg_pinned";
const MSG_USER_PINNED_STORE = "msg_pinned_users";
const MSG_MUTE_USER_STORE = "msg_mute_users";
const MSG_BLOCKLIST_STORE = "msg_blocklist";
const MSG_ARCHIEVED_STORE = "msg_archieved";

// business
const BUSINESS_DB_NAME = "business";
const BUSINESS_STORE = "business";
const BUSINESS_JOBS_STORE = "jobs";
const BUSINESS_JOBS_APPLICATION_STORE = "job_applications";
const BUSINESS_CANDIDATES_STORE = "candidates";
const BUSINESS_SETTINGS_STORE = "settings";
const REQUESTS_STORE = "requests";

// hubs

//check connection status, if online we can sync data else we just use the local saved data
let internetConnectionStatus = true;
// export const socket = io(app.base_url); // Replace with your server URL
export const socket = io("http://localhost:5000"); // Replace with your server URL

export const initDB = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      // personal (user) db
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }

      // posts table
      if (!db.objectStoreNames.contains(POSTS_STORE)) {
        db.createObjectStore(POSTS_STORE);
      }

      // metadata table
      if (!db.objectStoreNames.contains(META_URL_STORE)) {
        db.createObjectStore(META_URL_STORE);
      }

      // chats table
      if (!db.objectStoreNames.contains(CHATS_STORE)) {
        db.createObjectStore(CHATS_STORE);
      }
      // jobs application store table
      if (!db.objectStoreNames.contains(APPLICATION_STORE)) {
        db.createObjectStore(APPLICATION_STORE);
      }

      // jobs application store table
      if (!db.objectStoreNames.contains(APPLICATION_STORE)) {
        db.createObjectStore(APPLICATION_STORE);
      }

      if (!db.objectStoreNames.contains(CONVERSATION_USER_STORE)) {
        db.createObjectStore(CONVERSATION_USER_STORE);
      }

      if (!db.objectStoreNames.contains(META_USER_STORE)) {
        db.createObjectStore(META_USER_STORE);
      }

      if (!db.objectStoreNames.contains(MSG_ARCHIEVED_STORE)) {
        db.createObjectStore(MSG_ARCHIEVED_STORE);
      }

      if (!db.objectStoreNames.contains(MSG_PINNED_STORE)) {
        db.createObjectStore(MSG_PINNED_STORE);
      }

      if (!db.objectStoreNames.contains(MSG_USER_PINNED_STORE)) {
        db.createObjectStore(MSG_USER_PINNED_STORE);
      }

      if (!db.objectStoreNames.contains(MSG_MUTE_USER_STORE)) {
        db.createObjectStore(MSG_MUTE_USER_STORE);
      }

      if (!db.objectStoreNames.contains(MSG_BLOCKLIST_STORE)) {
        db.createObjectStore(MSG_BLOCKLIST_STORE);
      }

      if (!db.objectStoreNames.contains(BUSINESS_FOLLOW_STORE)) {
        db.createObjectStore(BUSINESS_FOLLOW_STORE);
      }

      if (!db.objectStoreNames.contains(HUBS_JOIN_STORE)) {
        db.createObjectStore(HUBS_JOIN_STORE);
      }

      if (!db.objectStoreNames.contains(META_BUSINISS_STORE)) {
        db.createObjectStore(META_BUSINISS_STORE);
      }

      if (!db.objectStoreNames.contains(FOLLOW_LIST_STORE)) {
        db.createObjectStore(FOLLOW_LIST_STORE);
      }

      if (!db.objectStoreNames.contains(FOLLOWERS_LIST_STORE)) {
        db.createObjectStore(FOLLOWERS_LIST_STORE);
      }
    },
  });
};

export const businessInitDB = async () => {
  return openDB(BUSINESS_DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(BUSINESS_STORE)) {
        db.createObjectStore(BUSINESS_STORE);
      }

      if (!db.objectStoreNames.contains(BUSINESS_ACCESS_STORE)) {
        db.createObjectStore(BUSINESS_ACCESS_STORE);
      }

      // business list table
      if (!db.objectStoreNames.contains(BUSINESS_STORE)) {
        db.createObjectStore(BUSINESS_STORE);
      }

      if (!db.objectStoreNames.contains(BUSINESS_JOBS_APPLICATION_STORE)) {
        db.createObjectStore(BUSINESS_JOBS_APPLICATION_STORE);
      }

      if (!db.objectStoreNames.contains(BUSINESS_CANDIDATES_STORE)) {
        db.createObjectStore(BUSINESS_CANDIDATES_STORE);
      }

      if (!db.objectStoreNames.contains(BUSINESS_SETTINGS_STORE)) {
        db.createObjectStore(BUSINESS_SETTINGS_STORE);
      }

      if (!db.objectStoreNames.contains(REQUESTS_STORE)) {
        db.createObjectStore(REQUESTS_STORE);
      }

      // jobs table
      if (!db.objectStoreNames.contains(BUSINESS_JOBS_STORE)) {
        db.createObjectStore(BUSINESS_JOBS_STORE);
      }
    },
  });
};

// Socket.emit("message", "Testing");
// function to broadcast updates
// WebSocket event emitter function (to be defined)
export const emitWebSocketEvent = async (data) => {
  let action = data.type;
  let min_action = data.min_action;
  let request_response = "";
  let request_url = "";
  if (action == "business") {
    request_url = app.auth_request_url + "business/" + min_action;
  } else if (action == "hub") {
    request_url = app.auth_request_url + "hub/" + min_action;
  } else {
    request_url = app.auth_request_url + action;
  }

  try {
    const response = await axios.post(request_url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    request_response = response.data;
    // console.log("Main responce from server:", response.data);
    // Handle the response data as needed
  } catch (error) {
    console.error("There was a problem with the axios operation:", error);
    // Handle the error as needed
  }

  // Emit socket.io event
  socket.emit("updateData", data);
  console.log("Emitted:", data);

  return request_response;
};

export const updateLocalStorage = (data) => {
  localStorage.setItem("localUpdate", JSON.stringify(data));
};

export const socketListeners = () => {
  socket.on("newUpdateData", (data) => {
    console.log(data);
    // alert("New data detected");
    // callback(data); // Pass the received data to the provided callback function
  });
};

// Functions for Language settings
export const saveLanguageToIndexedDB = async (language, save_online = true) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(language, "selectedLanguage");
  // Emit WebSocket event
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveLanguageToIndexedDB",
      user: app.current_user,
      language,
    });
  }

  await tx.done;
  return submit;
};

export const getLanguageFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Open a transaction to retrieve stored language from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedLanguage = await tx.store.get("selectedLanguage");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the stored language from IndexedDB
      console.log(
        "Offline: Returning stored language from IndexedDB",
        storedLanguage
      );
      return storedLanguage;
    }

    // If online, fetch and sync language with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const language = profile.profile[0].language;

    // Sync by saving the remote language to IndexedDB
    await saveLanguageToIndexedDB(language, false);
    console.log(
      "Online: Synced language from remote and saved to IndexedDB",
      language
    );

    return language;
  } catch (error) {
    console.error("Error fetching language:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for Interest settings
export const saveInterestsToIndexedDB = async (
  interests,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(interests, "selectedInterests");
  // Emit WebSocket event
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveInterestsToIndexedDB",
      user: app.current_user,
      interests,
    });
  }

  await tx.done;
  return submit;
};

export const getInterestsFromIndexedDB = async () => {
  try {
    const db = await initDB();
    // Open a transaction to retrieve stored interests from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedInterests = await tx.store.get("selectedInterests");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the stored interests from IndexedDB
      console.log(
        "Offline: Returning stored interests from IndexedDB",
        storedInterests
      );
      return storedInterests;
    }

    // If online, fetch and sync interests with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const interests = profile.profile[0].interest;

    // Sync by saving the remote interests to IndexedDB
    await saveInterestsToIndexedDB(interests, false);
    console.log(
      "Online: Synced interests from remote and saved to IndexedDB",
      interests
    );

    return interests;
  } catch (error) {
    console.error("Error fetching interests:", error);
    throw error; // Handle errors appropriately
  }
};

// functions for skills

// export const fetchSkillsFromDB = async () => {
//   const db = await initDB();
//   const tx = db.transaction("skills", "readonly");
//   return tx.store.get("skills");
// };

// Functions for Timezone settings
export const saveTimezoneToIndexedDB = async (timezone, save_online = true) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(timezone, "selectedTimezone");
  // Emit WebSocket event
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveTimezoneToIndexedDB",
      user: app.current_user,
      timezone,
    });
  }

  await tx.done;
  return submit;
};

export const getTimezoneFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Open a transaction to retrieve stored timezone from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedTimezone = await tx.store.get("selectedTimezone");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the stored timezone from IndexedDB
      console.log(
        "Offline: Returning stored timezone from IndexedDB",
        storedTimezone
      );
      return storedTimezone;
    }

    // If online, fetch and sync timezone with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const timezone = profile.profile[0].timezone;

    // Sync by saving the remote timezone to IndexedDB
    await saveTimezoneToIndexedDB(timezone, false);
    console.log(
      "Online: Synced timezone from remote and saved to IndexedDB",
      timezone
    );

    return timezone;
  } catch (error) {
    console.error("Error fetching timezone:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for Contact Info settings
export const saveContactInfoToIndexedDB = async (
  contactInfo,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(contactInfo, "contactInfo");
  // Emit WebSocket event
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveContactInfoToIndexedDB",
      user: app.current_user,
      contactInfo,
    });
  }

  await tx.done;
  return submit;
};

export const getContactInfoFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Check if contact info exists in IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedContactInfo = await tx.store.get("contactInfo");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // Return offline data if we are not connected to the internet
      console.log(
        "Offline: Returning stored contact info from IndexedDB",
        storedContactInfo
      );
      return storedContactInfo;
    }

    // If online, sync with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const remoteContactInfo = {
      address: profile.profile[0].address,
      country: profile.profile[0].country_phone,
      email: profile.profile[0].email,
      phoneNumber: profile.profile[0].phone,
    };

    // Sync by saving remote data to IndexedDB
    await saveContactInfoToIndexedDB(remoteContactInfo, false);

    return remoteContactInfo;
  } catch (error) {
    console.error("Error fetching contact info:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for Profile settings
export const saveProfileToIndexedDB = async (profile, save_online = true) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(profile, "profile");
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveProfileToIndexedDB",
      user: app.current_user,
      profile,
    });
  }

  await tx.done;
  return submit;
};

export const getProfileFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Open a transaction to retrieve the stored profile from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedProfile = await tx.store.get("profile");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the profile from IndexedDB
      console.log(
        "Offline: Returning stored profile from IndexedDB",
        storedProfile
      );
      return storedProfile;
    }

    // If online, fetch and sync the profile with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const remoteProfile = {
      bio: profile.profile[0].bio,
      dateOfBirth: profile.profile[0].date_of_birth,
      fullName: profile.profile[0].fullname,
      jobTitle: profile.profile[0].current_job_title,
      username: profile.profile[0].username,
    };

    // Sync by saving the remote profile to IndexedDB
    await saveProfileToIndexedDB(remoteProfile, false);
    console.log(
      "Online: Synced profile from remote and saved to IndexedDB",
      remoteProfile
    );

    return remoteProfile;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for Professional settings
export const saveProfessionalSettingsToIndexedDB = async (
  settings,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(settings, "professionalSettings");
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveProfessionalSettingsToIndexedDB",
      user: app.current_user,
      settings,
    });
  }

  await tx.done;
  return submit;
};

export const getProfessionalSettingsFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Open a transaction to retrieve the stored settings from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedSettings = await tx.store.get("professionalSettings");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the settings from IndexedDB
      console.log(
        "Offline: Returning stored professional settings from IndexedDB",
        storedSettings
      );
      return storedSettings;
    }

    // If online, fetch and sync the settings with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const remoteSettings = {
      availabilityStatus: profile.profile[0].availabilityStatus,
      downloadMyCv: profile.profile[0].downloadMyCv,
    };

    // Sync by saving the remote settings to IndexedDB
    await saveProfessionalSettingsToIndexedDB(remoteSettings, false);
    console.log(
      "Online: Synced professional settings from remote and saved to IndexedDB",
      remoteSettings
    );

    return remoteSettings;
  } catch (error) {
    console.error("Error fetching professional settings:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for profile and cover image settings
export const saveProfileImageAndCoverSettingsToIndexedDB = async (
  data,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(data, "profileImageSettings");
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveProfileImageAndCoverSettingsToIndexedDB",
      user: app.current_user,
      data,
    });
  }
  await tx.done;
  return submit;
};

export const getProfileImageAndCoverSettingsFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Open a transaction to retrieve stored profile image and cover settings from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedSettings = await tx.store.get("profileImageSettings");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the stored settings from IndexedDB
      console.log(
        "Offline: Returning stored profile image and cover settings from IndexedDB",
        storedSettings
      );
      return storedSettings;
    }

    // If online, fetch and sync profile image and cover settings with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const remoteSettings = {
      cover_image: profile.profile[0].cover_image,
      profile_image: profile.profile[0].profile_image,
    };

    // Sync by saving the remote settings to IndexedDB
    await saveProfileImageAndCoverSettingsToIndexedDB(remoteSettings, false);
    console.log(
      "Online: Synced profile image and cover settings from remote and saved to IndexedDB",
      remoteSettings
    );

    return remoteSettings;
  } catch (error) {
    console.error("Error fetching profile image and cover settings:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for Privacy settings
export const savePrivacySettingsToIndexedDB = async (
  settings,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(settings, "privacySettings");
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "savePrivacySettingsToIndexedDB",
      user: app.current_user,
      settings,
    });
  }

  await tx.done;
  return submit;
};

export const getPrivacySettingsFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Open a transaction to retrieve stored privacy settings from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedSettings = await tx.store.get("privacySettings");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the stored settings from IndexedDB
      console.log(
        "Offline: Returning stored privacy settings from IndexedDB",
        storedSettings
      );
      return storedSettings;
    }

    // If online, fetch and sync privacy settings with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const remoteSettings = {
      coverImageUpdate: profile.profile[0].privacyHideCoverImageUpdate,
      onlineStatusChats: profile.profile[0].privacyHideOnlineStatusOnChats,
      onlineStatusPosts: profile.profile[0].privacyHideOnlineStatusOnPosts,
      profileImageUpdate: profile.profile[0].privacyHideProfilePictureUpdate,
      profileVisibility: profile.profile[0].privacyHideProfileSearchResults,
    };

    // Sync by saving remote settings to IndexedDB
    await savePrivacySettingsToIndexedDB(remoteSettings, false);
    console.log(
      "Online: Synced privacy settings from remote and saved to IndexedDB",
      remoteSettings
    );

    return remoteSettings;
  } catch (error) {
    console.error("Error fetching privacy settings:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for Notifications settings
export const saveNotificationSettingsToIndexedDB = async (
  settings,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(settings, "notificationSettings");
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveNotificationSettingsToIndexedDB",
      user: app.current_user,
      settings,
    });
  }

  await tx.done;
  return submit;
};

export const getNotificationSettingsFromIndexedDB = async () => {
  try {
    const db = await initDB();

    // Open a transaction to retrieve stored notification settings from IndexedDB
    const tx = db.transaction(STORE_NAME, "readonly");
    const storedSettings = await tx.store.get("notificationSettings");

    // Check internet connectivity
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      // If offline, return the stored notification settings from IndexedDB
      console.log(
        "Offline: Returning stored notification settings from IndexedDB",
        storedSettings
      );
      return storedSettings;
    }

    // If online, fetch and sync notification settings with remote data
    const profile = await emitWebSocketEvent({
      type: "getUserProfile",
      user: app.current_user,
    });

    const remoteSettings = {
      emailNotifications: profile.profile[0].emailNotifications,
      followNotifications: profile.profile[0].followNotifications,
      hubJoinRequestAcceptanceNotifications:
        profile.profile[0].hubJoinRequestAcceptanceNotifications,
      jobApplicationAcceptanceNotifications:
        profile.profile[0].jobApplicationAcceptanceNotifications,
      jobApplicationRejectionsNotifications:
        profile.profile[0].jobApplicationRejectionsNotifications,
      myHubJoinRequestNotifications:
        profile.profile[0].myHubJoinRequestNotifications,
    };

    // Sync by saving the remote settings to IndexedDB
    await saveNotificationSettingsToIndexedDB(remoteSettings, false);
    console.log(
      "Online: Synced notification settings from remote and saved to IndexedDB",
      remoteSettings
    );

    return remoteSettings;
  } catch (error) {
    console.error("Error fetching notification settings:", error);
    throw error; // Handle errors appropriately
  }
};

// Functions for Portfolio images
export const savePortfolioImageToIndexedDB = async (
  portfolio,
  submit_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  console.log("Saving portfolio to IndexedDB:", portfolio); // Debug log
  tx.store.put(portfolio, `portfolio-${portfolio.id}`);
  let submit = "";
  if (submit_online) {
    submit = emitWebSocketEvent({
      type: "savePortfolioImageToIndexedDB",
      user: app.current_user,
      portfolio,
    });
  }

  await tx.done;
  return submit;
};

export const getImagesFromIndexedDB = async () => {
  try {
    // Start fetching the keys from IndexedDB and WebSocket data in parallel
    const [db, list] = await Promise.all([
      initDB(), // Initialize IndexedDB
      emitWebSocketEvent({ type: "getPortfolioImage", user: app.current_user }), // Fetch remote portfolio data
    ]);

    console.log("Portfolio image", list.portfolio);

    // Start a write transaction to save portfolio images in bulk
    const writeTx = db.transaction(STORE_NAME, "readwrite");
    const writeStore = writeTx.store;

    const savePromises = list.portfolio.map((image) =>
      savePortfolioImageToIndexedDB(image, false)
    );
    await Promise.all(savePromises); // Batch saving portfolio images to IndexedDB

    // Commit the write transaction
    await writeTx.done;

    // Start a read transaction to get all portfolio-related keys
    const readTx = db.transaction(STORE_NAME, "readonly");
    const readStore = readTx.store;
    const keys = await readStore.getAllKeys();

    // Filter keys and fetch the corresponding portfolio images
    const portfolios = await Promise.all(
      keys
        .filter((key) => key.startsWith("portfolio-"))
        .map((key) => readStore.get(key))
    );

    // Ensure the transaction completes
    await readTx.done;

    console.log("Portfolios retrieved from IndexedDB:", portfolios); // Debug log
    return portfolios;
  } catch (error) {
    console.error("Error in getImagesFromIndexedDB:", error);
    throw error;
  }
};

export const removePortfolioImageFromIndexedDB = async (id) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.delete(`portfolio-${id}`);
  const submit = emitWebSocketEvent({
    type: "removePortfolio",
    user: app.current_user,
    id,
  });
  await tx.done;
  return submit;
};

// Functions for managing attachments
export const saveAttachmentToIndexedDB = async (
  attachment,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(attachment, `attachment-${attachment.id}`);
  console.log("Add Attachment", attachment);
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveAttachmentToIndexedDB",
      user: app.current_user,
      attachment,
    });
  }
  await tx.done;
  return submit;
};

export const getAttachmentsFromIndexedDB = async () => {
  try {
    // Start fetching the keys from IndexedDB and WebSocket data in parallel
    const [db, list] = await Promise.all([
      initDB(), // Initialize IndexedDB
      emitWebSocketEvent({ type: "getAttachments", user: app.current_user }), // Fetch remote data
    ]);

    // Start a write transaction to save attachments in bulk
    const writeTx = db.transaction(STORE_NAME, "readwrite");
    const writeStore = writeTx.store;

    const savePromises = list.attachment.map((attachment) =>
      saveAttachmentToIndexedDB(attachment, false)
    );
    await Promise.all(savePromises); // Batch saving to IndexedDB

    // Commit the write transaction
    await writeTx.done;

    // Start a read transaction to get all attachment keys
    const readTx = db.transaction(STORE_NAME, "readonly");
    const readStore = readTx.store;
    const keys = await readStore.getAllKeys();

    // Filter keys and fetch the corresponding attachments
    const attachments = await Promise.all(
      keys
        .filter((key) => key.startsWith("attachment-"))
        .map((key) => readStore.get(key))
    );

    // Ensure transaction completes
    await readTx.done;

    return attachments;
  } catch (error) {
    console.error("Error in getAttachmentsFromIndexedDB:", error);
    throw error;
  }
};

export const removeAttachmentFromIndexedDB = async (id) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.delete(`attachment-${id}`);
  const submit = emitWebSocketEvent({
    type: "removeAttachment",
    user: app.current_user,
    id,
  });
  await tx.done;
  return submit;
};

// Functions for managing education
export const saveEducationToIndexedDB = async (
  education,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(education, `education-${education.id}`);
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveEducationToIndexedDB",
      user: app.current_user,
      education,
    });
  }

  await tx.done;
  return submit;
};

export const getEducationFromIndexedDB = async () => {
  try {
    // Start fetching the WebSocket data and initializing IndexedDB in parallel
    const [db, list] = await Promise.all([
      initDB(), // Initialize IndexedDB
      emitWebSocketEvent({ type: "getEducation", user: app.current_user }), // Fetch remote education data
    ]);

    // Start a write transaction to save education data in bulk
    const writeTx = db.transaction(STORE_NAME, "readwrite");
    const writeStore = writeTx.store;

    const savePromises = list.education.map((educationItem) =>
      saveEducationToIndexedDB(educationItem, false)
    );
    await Promise.all(savePromises); // Batch saving education data to IndexedDB

    // Commit the write transaction
    await writeTx.done;

    // Start a read transaction to get all education-related keys
    const readTx = db.transaction(STORE_NAME, "readonly");
    const readStore = readTx.store;
    const keys = await readStore.getAllKeys();

    // Filter keys and fetch the corresponding education data
    const education = await Promise.all(
      keys
        .filter((key) => key.startsWith("education-"))
        .map((key) => readStore.get(key))
    );

    // Ensure the read transaction completes
    await readTx.done;

    console.log("Education retrieved from IndexedDB:", education); // Debug log
    return education;
  } catch (error) {
    console.error("Error in getEducationFromIndexedDB:", error);
    throw error;
  }
};

export const removeEducationFromIndexedDB = async (id) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.delete(`education-${id}`);
  const submit = emitWebSocketEvent({
    type: "removeEducation",
    user: app.current_user,
    id,
  });
  await tx.done;
  return submit;
};

// Functions for managing experience
export const saveExperienceToIndexedDB = async (
  experience,
  save_online = true
) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.put(experience, `experience-${experience.id}`);
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "saveExperienceToIndexedDB",
      user: app.current_user,
      experience,
    });
  }

  await tx.done;
  return submit;
};

export const getExperienceFromIndexedDB = async () => {
  try {
    // Start fetching the WebSocket data and initializing IndexedDB in parallel
    const [db, list] = await Promise.all([
      initDB(), // Initialize IndexedDB
      emitWebSocketEvent({ type: "getExperience", user: app.current_user }), // Fetch remote experience data
    ]);

    // Start a write transaction to save experience in bulk
    const writeTx = db.transaction(STORE_NAME, "readwrite");
    const writeStore = writeTx.store;

    const savePromises = list.experience.map((exp) =>
      saveExperienceToIndexedDB(exp, false)
    );
    await Promise.all(savePromises); // Batch saving experience to IndexedDB

    // Commit the write transaction
    await writeTx.done;

    // Start a read transaction to get all experience-related keys
    const readTx = db.transaction(STORE_NAME, "readonly");
    const readStore = readTx.store;
    const keys = await readStore.getAllKeys();

    // Filter keys and fetch the corresponding experience data
    const experience = await Promise.all(
      keys
        .filter((key) => key.startsWith("experience-"))
        .map((key) => readStore.get(key))
    );

    // Ensure the read transaction completes
    await readTx.done;

    console.log("Experience retrieved from IndexedDB:", experience); // Debug log
    return experience;
  } catch (error) {
    console.error("Error in getExperienceFromIndexedDB:", error);
    throw error;
  }
};

export const removeExperienceFromIndexedDB = async (id) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  tx.store.delete(`experience-${id}`);
  await tx.done;
  const submit = emitWebSocketEvent({
    type: "removeExperience",
    user: app.current_user,
    id,
  });
  return submit;
};

// Functions for post images
export const savePostToIndexedDB = async (post) => {
  const db = await initDB();
  const tx = db.transaction(POSTS_STORE, "readwrite");
  console.log("Saving post to IndexedDB:", post); // Debug log
  tx.store.put(post, `post-${post.id}`);
  const submit = emitWebSocketEvent({
    type: "savePostToIndexedDB",
    user: app.current_user,
    post,
  });
  await tx.done;
  return submit;
};

export const getPostFromIndexedDB = async () => {
  const db = await initDB();
  const tx = db.transaction(POSTS_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const posts = await Promise.all(
    keys
      .filter((key) => key.startsWith("post-"))
      .map((key) => tx.store.get(key))
  );
  // console.log("posts retrieved from IndexedDB:", posts); // Debug log
  return posts;
};

export const removePostFromIndexedDB = async (id) => {
  const db = await initDB();
  const tx = db.transaction(POSTS_STORE, "readwrite");
  tx.store.delete(`post-${id}`);
  await tx.done;
};

// Functions for hub
export const createNewHub = async (hub) => {
  const submit = emitWebSocketEvent({
    type: "hub",
    min_action: "createNewHub",
    user: app.current_user,
    hub,
  });
  return submit;
};

export const saveHubAccessToIndexedDB = async (data) => {
  const db = await initDB();
  const tx = db.transaction(HUBS_JOIN_STORE, "readwrite");
  for (let index = 0; index < data.length; index++) {
    tx.store.put(data[index], `hub-${data[index].hub_id}`);
    console.log("Saving hub to IndexedDB:", data[index].hub_id);
  }
  await tx.done;
};

// this is a single hub entry so no need to loop  the data
export const updateHubAccessToIndexedDB = async (data) => {
  const db = await initDB();
  const tx = db.transaction(HUBS_JOIN_STORE, "readwrite");
  tx.store.put(data, `hub-${data.hub_id}`);
  await tx.done;
};

export const getHubFromIndexedDB = async () => {
  const db = await initDB();
  const tx = db.transaction(HUBS_JOIN_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const hubs = await Promise.all(
    keys.filter((key) => key.startsWith("hub-")).map((key) => tx.store.get(key))
  );
  return hubs;
};

export const getHubProfileFromIndexedDB = async (hub_id) => {
  const db = await initDB();
  const tx = db.transaction(HUBS_JOIN_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const hub = await Promise.all(
    keys
      .filter((key) => key.startsWith("hub-" + hub_id))
      .map((key) => tx.store.get(key))
  );
  return hub;
};

// Functions for business page
export const saveBusinessToIndexedDB = async (business) => {
  const db = await businessInitDB();
  const tx = db.transaction(BUSINESS_STORE, "readwrite");
  console.log("Saving business to IndexedDB:", business); // Debug log
  tx.store.put(business, `business-${business.id}`);
  const submit = emitWebSocketEvent({
    type: "business",
    min_action: "saveBusinessToIndexedDB",
    user: app.current_user,
    business,
  });
  await tx.done;
  return submit;
};

export const saveBusinessAccessToIndexedDB = async (data) => {
  const db = await businessInitDB();
  const tx = db.transaction(BUSINESS_ACCESS_STORE, "readwrite");
  // console.log("Saving business to IndexedDB:", business);
  for (let index = 0; index < data.length; index++) {
    tx.store.put(data[index], `business-${data[index].business_id}`);
  }
  await tx.done;
};

export const getBusinessFromIndexedDB = async () => {
  const db = await businessInitDB();
  const tx = db.transaction(BUSINESS_ACCESS_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const business = await Promise.all(
    keys
      .filter((key) => key.startsWith("business-"))
      .map((key) => tx.store.get(key))
  );
  return business;
};

export const getJobsFromBusiness = async (id) => {
  // const db = await businessInitDB();
  // const tx = db.transaction(BUSINESS_JOBS_STORE, "readonly");
  // const keys = await tx.store.getAllKeys();
  // const business = await Promise.all(
  //   keys
  //     .filter((key) => key.startsWith("jobs-"))
  //     .map((key) => tx.store.get(key))
  // );

  if (internetConnectionStatus) {
  }

  const job_list = emitWebSocketEvent({
    type: "business",
    min_action: "getJobsFromBusiness",
    user: app.current_user,
    business: id,
  });

  return job_list;
};

export const getJobInfo = async (job_id, business) => {
  const details = emitWebSocketEvent({
    type: "business",
    min_action: "getJobInfo",
    user: app.current_user,
    job_id: job_id,
    business: business,
  });

  return details;
};

export const updateJobSkills = async (job_id, business, skills) => {
  const details = emitWebSocketEvent({
    type: "business",
    min_action: "updateJobSkills",
    user: app.current_user,
    job_id: job_id,
    business: business,
    skills: skills,
  });

  return details;
};

export const submitJobBasicInfo = async (data) => {
  const res = await emitWebSocketEvent({
    type: "business",
    min_action: "submitJobBasicInfo",
    user: app.current_user,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const submitOtherJobInfo = async (data) => {
  const res = await emitWebSocketEvent({
    type: "business",
    min_action: "submitOtherJobInfo",
    user: app.current_user,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateBusinessContactInfo = (business_id, formData) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "updateBusinessContactInfo",
    user: app.current_user,
    business_id: business_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateBusinessUsername = (business_id, formData) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "updateBusinessUsername",
    user: app.current_user,
    business_id: business_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateBusinessCategory = (business_id, formData) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "updateBusinessCategory",
    user: app.current_user,
    business_id: business_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateBusinessProfileInfo = (business_id, formData) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "updateBusinessProfileInfo",
    user: app.current_user,
    business_id: business_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const getBusinessProfile = (business_id) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "getBusinessProfile",
    user: app.current_user,
    business_id: business_id,
  });
  return res;
};

export const updateBusinessProfileImageAndCover = (business_id, data) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "updateBusinessProfileImageAndCover",
    user: app.current_user,
    business_id: business_id,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const acceptJobApplication = (data) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "acceptJobApplication",
    user: app.current_user,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const rejectJobApplication = (data) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "rejectJobApplication",
    user: app.current_user,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const archiveBusinessJob = (data) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "archiveBusinessJob",
    user: app.current_user,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const deleteBusinessJob = (data) => {
  const res = emitWebSocketEvent({
    type: "business",
    min_action: "deleteBusinessJob",
    user: app.current_user,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const removeBusinessFromIndexedDB = async (id) => {
  const db = await businessInitDB();
  const tx = db.transaction(BUSINESS_STORE, "readwrite");
  tx.store.delete(`business-${id}`);
  await tx.done;
};

// user manager
export const getUserInfoByUsername = async (username) => {
  const details = emitWebSocketEvent({
    type: "getUserInfoByUsername",
    user: app.current_user,
    username: username,
  });

  return details;
};

export const getUserFollowing = async (username, limit) => {
  const details = emitWebSocketEvent({
    type: "getUserFollowing",
    user: app.current_user,
    username: username,
    limit: limit,
  });

  return details;
};

export const getHubAccessList = async (user, limit) => {
  try {
    const details = await emitWebSocketEvent({
      type: "getHubAccessList",
      user: user || app.current_user, // Use the provided user, or fallback to current user
      limit: limit,
    });

    if (!details || !details.hubs) {
      throw new Error("No hub details returned");
    }

    const hubUpdatePromises = details.hubs.map((element) => {
      const obj = {
        hub_id: element.hub_info[0].hub_id,
        hub_info: element.hub_info,
        prev: element.row.prev,
        status: element.row.status,
      };
      console.log("List obj", obj);
      return updateHubAccessToIndexedDB(obj);
    });

    // Await all updates in parallel
    await Promise.all(hubUpdatePromises);

    return details;
  } catch (error) {
    console.error("Error fetching hub access list: ", error);
    throw error;
  }
};

export const getPageAccessList = async (user = "", limit) => {
  const details = await emitWebSocketEvent({
    type: "getPageAccessList",
    user: app.current_user,
    limit: limit,
  });

  // update indexed db with this new data
  await saveBusinessAccessToIndexedDB(details.pages);
  // console.log("bslist - >", details.pages);
  return details;
};

export const getUserProfessionalInfo = async (username) => {
  const details = emitWebSocketEvent({
    type: "getUserProfessionalInfo",
    user: app.current_user,
    profile_username: username,
  });
  return details;
};

export const getUserFollowers = async (username, limit) => {
  const details = emitWebSocketEvent({
    type: "getUserFollowers",
    user: app.current_user,
    username: username,
    limit: limit,
  });
  return details;
};

export const getUserVibes = async (username, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getUserVibes",
    user: app.current_user,
    username: username,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getHubVibes = async (username, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getHubVibes",
    user: app.current_user,
    username: username,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getPageVibes = async (username, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getPageVibes",
    user: app.current_user,
    username: username,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getUserMedia = async (username, limit) => {
  const details = emitWebSocketEvent({
    type: "getUserMedia",
    user: app.current_user,
    username: username,
    limit: limit,
  });
  return details;
};

export const getHubMedia = async (username, limit) => {
  const details = emitWebSocketEvent({
    type: "getHubMedia",
    user: app.current_user,
    username: username,
    limit: limit,
  });

  return details;
};

export const getPageMedia = async (username, limit) => {
  const details = emitWebSocketEvent({
    type: "getPageMedia",
    user: app.current_user,
    username: username,
    limit: limit,
  });
  return details;
};

export const getChatConversationsRemote = async () => {
  const list = emitWebSocketEvent({
    type: "getChatConversationsRemote",
    user: app.current_user,
  });
  return list;
};

export const getUserJobList = async (list_type, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getUserJobList",
    user: app.current_user,
    limit,
    offset,
    list_type,
  });
  return details;
};

export const searchJobList = async (
  list_type,
  limit,
  offset,
  searchText,
  activeCategory,
  activeJobType,
  activeQualification
) => {
  const details = emitWebSocketEvent({
    type: "searchJobList",
    user: app.current_user,
    list_type,
    limit,
    offset,
    searchText,
    activeCategory,
    activeJobType,
    activeQualification,
  });
  return details;
};

export const getHubDashboardDetails = async (hub_id) => {
  const details = emitWebSocketEvent({
    type: "hub",
    min_action: "getHubDashboardDetails",
    user: app.current_user,
    hub_id: hub_id,
  });
  return details;
};

export const getBusinessDashboardDetails = async (business_id) => {
  const details = emitWebSocketEvent({
    type: "business",
    min_action: "getBusinessDashboardDetails",
    user: app.current_user,
    business_id: business_id,
  });
  return details;
};

export const getBusinessJobReportDetails = async (business_id) => {
  const details = emitWebSocketEvent({
    type: "business",
    min_action: "getBusinessJobReportDetails",
    user: app.current_user,
    business_id: business_id,
  });
  return details;
};

export const getBusinessJobApplications = async (business_id) => {
  const details = emitWebSocketEvent({
    type: "business",
    min_action: "getBusinessJobApplications",
    user: app.current_user,
    business_id: business_id,
  });
  return details;
};

export const getJobApplicationDetails = async (business_id, application_id) => {
  const details = emitWebSocketEvent({
    type: "business",
    min_action: "getJobApplicationDetails",
    user: app.current_user,
    business_id,
    application_id,
  });
  return details;
};

export const addUserAsHubAdmin = async (hub_id, activity_id) => {
  const details = emitWebSocketEvent({
    type: "hub",
    min_action: "addUserAsHubAdmin",
    user: app.current_user,
    hub_id: hub_id,
    activity_id: activity_id,
  });
  return details;
};

export const removeUserHubAdmin = async (hub_id, activity_id) => {
  const details = emitWebSocketEvent({
    type: "hub",
    min_action: "removeUserHubAdmin",
    user: app.current_user,
    hub_id: hub_id,
    activity_id: activity_id,
  });
  return details;
};

export const removeMemberHub = async (hub_id, activity_id) => {
  const details = emitWebSocketEvent({
    type: "hub",
    min_action: "removeMemberHub",
    user: app.current_user,
    hub_id: hub_id,
    activity_id: activity_id,
  });
  return details;
};

export const getHubMembers = async (hub_id) => {
  const details = emitWebSocketEvent({
    type: "hub",
    min_action: "getHubMembers",
    user: app.current_user,
    hub_id: hub_id,
  });
  return details;
};

export const getHubAdmins = async (hub_id) => {
  const details = emitWebSocketEvent({
    type: "hub",
    min_action: "getHubAdmins",
    user: app.current_user,
    hub_id: hub_id,
  });
  return details;
};

export const getHubFollowers = async (hub_id) => {
  const details = emitWebSocketEvent({
    type: "hub",
    min_action: "getHubFollowers",
    user: app.current_user,
    hub_id: hub_id,
  });
  return details;
};

export const getBusinessPageFollowers = async (business_id) => {
  const details = emitWebSocketEvent({
    type: "business",
    min_action: "getBusinessPageFollowers",
    user: app.current_user,
    business_id: business_id,
  });
  return details;
};

// hubs settings
export const updateHubContactInfo = (hub_id, formData) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateHubContactInfo",
    user: app.current_user,
    hub_id: hub_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateHubProfileImageAndCover = (hub_id, data) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateHubProfileImageAndCover",
    user: app.current_user,
    hub_id: hub_id,
    data,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateHubUsername = (hub_id, formData) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateHubUsername",
    user: app.current_user,
    hub_id: hub_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateHubCategory = (hub_id, formData) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateHubCategory",
    user: app.current_user,
    hub_id: hub_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateHubProfileInfo = (hub_id, formData) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateHubProfileInfo",
    user: app.current_user,
    hub_id: hub_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const updateHubRules = (hub_id, formData) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateHubRules",
    user: app.current_user,
    hub_id: hub_id,
    data: formData,
  });
  console.log("RESPONSE", res);
  return res;
};

export const getHubProfile = (hub_id) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "getHubProfile",
    user: app.current_user,
    hub_id: hub_id,
  });
  // console.log("RESPONSE", res);
  return res;
};

export const updateHubAccessList = (hub_id, activity_id) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateHubAccessList",
    user: app.current_user,
    hub_id: hub_id,
    activity_id: activity_id,
  });
  // console.log("RESPONSE", res);
  return res;
};

export const updateRemoveHubAdmin = (hub_id, activity_id) => {
  const res = emitWebSocketEvent({
    type: "hub",
    min_action: "updateRemoveHubAdmin",
    user: app.current_user,
    hub_id: hub_id,
    activity_id: activity_id,
  });
  // console.log("RESPONSE", res);
  return res;
};

export const getAllJobDetails = async (job_id) => {
  const details = emitWebSocketEvent({
    type: "getAllJobDetails",
    user: app.current_user,
    job_id: job_id,
  });

  return details;
};

export const getHubProfileByUsername = async (username) => {
  const details = emitWebSocketEvent({
    type: "getHubProfileByUsername",
    user: app.current_user,
    username,
  });

  return details;
};

export const getPageProfileByUsername = async (username) => {
  const details = emitWebSocketEvent({
    type: "getPageProfileByUsername",
    user: app.current_user,
    username,
  });

  return details;
};

export const getHubMembersByUsername = async (username, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getHubMembersByUsername",
    user: app.current_user,
    username,
    limit,
    offset,
  });
  return details;
};

export const getPageFollowersByUsername = async (username, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getPageFollowersByUsername",
    user: app.current_user,
    username,
    limit,
    offset,
  });
  return details;
};

export const submitJobApplication = async (job_id, formData) => {
  const details = emitWebSocketEvent({
    type: "submitJobApplication",
    user: app.current_user,
    job_id: job_id,
    data: formData,
  });
  return details;
};

export const submitJobBookmark = async (job_id) => {
  const details = emitWebSocketEvent({
    type: "submitJobBookmark",
    user: app.current_user,
    job_id: job_id,
  });
  return details;
};

export const submitHubBookmark = async (hub_id) => {
  const details = emitWebSocketEvent({
    type: "submitHubBookmark",
    user: app.current_user,
    hub_id: hub_id,
  });
  return details;
};

export const shareVibeAsMessage = async (
  chatids,
  vibeLink,
  vibe_id,
  reciever
) => {
  const details = emitWebSocketEvent({
    type: "shareVibeAsMessage",
    user: app.current_user,
    chatids: chatids,
    vibeLink: vibeLink,
    vibe_id: vibe_id,
  });
  return details;
};

export const shareJobAsMessage = async (chatids, jobLink, job_id, reciever) => {
  const details = emitWebSocketEvent({
    type: "shareJobAsMessage",
    user: app.current_user,
    chatids: chatids,
    jobLink: jobLink,
    job_id: job_id,
  });
  return details;
};

export const submitVibeBookmark = async (vibe_id) => {
  const details = emitWebSocketEvent({
    type: "submitVibeBookmark",
    user: app.current_user,
    vibe_id: vibe_id,
  });
  return details;
};

export const submitDeleteVibe = async (vibe_id) => {
  const details = emitWebSocketEvent({
    type: "submitDeleteVibe",
    user: app.current_user,
    vibe_id: vibe_id,
  });
  return details;
};

export const submitRestoreVibe = async (vibe_id) => {
  const details = emitWebSocketEvent({
    type: "submitRestoreVibe",
    user: app.current_user,
    vibe_id: vibe_id,
  });
  return details;
};

export const submitRevibeVibe = async (vibe_id) => {
  const details = emitWebSocketEvent({
    type: "submitRevibeVibe",
    user: app.current_user,
    vibe_id: vibe_id,
  });
  return details;
};

export const submitPageBookmark = async (page_id) => {
  const details = emitWebSocketEvent({
    type: "submitPageBookmark",
    user: app.current_user,
    page_id: page_id,
  });
  return details;
};

export const submitlLikeVibe = async (vibe_id) => {
  const details = emitWebSocketEvent({
    type: "submitlLikeVibe",
    user: app.current_user,
    vibe_id: vibe_id,
  });
  return details;
};

export const submitFollowUser = async (followed) => {
  const details = emitWebSocketEvent({
    type: "submitFollowUser",
    user: app.current_user,
    followed: followed,
  });
  return details;
};

export const submitUserVote = async (poll_id, option_id) => {
  const details = emitWebSocketEvent({
    type: "submitUserVote",
    user: app.current_user,
    poll_id: poll_id,
    option_id: option_id,
  });
  return details;
};

export const submitFollowPage = async (followed) => {
  const details = emitWebSocketEvent({
    type: "submitFollowPage",
    user: app.current_user,
    followed: followed,
  });
  return details;
};

export const submitVibeComment = async (vibe_id, content) => {
  const details = emitWebSocketEvent({
    type: "submitVibeComment",
    user: app.current_user,
    vibe_id: vibe_id,
    content: content,
  });
  return details;
};

export const submitJoinHub = async (hub_id) => {
  const details = emitWebSocketEvent({
    type: "submitJoinHub",
    user: app.current_user,
    hub_id: hub_id,
  });
  return details;
};

export const submitVibeView = async (vibe_id) => {
  const details = emitWebSocketEvent({
    type: "submitVibeView",
    user: app.current_user,
    vibe_id: vibe_id,
  });
  return details;
};

export const submitRequestHubJoin = async (hub_id) => {
  const details = emitWebSocketEvent({
    type: "submitRequestHubJoin",
    user: app.current_user,
    hub_id: hub_id,
  });
  return details;
};

export const getVibeComments = async (vibe_id, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getVibeComments",
    user: app.current_user,
    vibe_id: vibe_id,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getFeedList = async (feed_type, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getFeedList",
    user: app.current_user,
    feed_type: feed_type,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getFeedListOnTopic = async (tagname, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getFeedListOnTopic",
    user: app.current_user,
    limit: limit,
    offset: offset,
    tagname: tagname,
  });
  return details;
};

export const getTopicsList = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getTopicsList",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getFeedListBookmark = async (type, limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getFeedListBookmark",
    user: app.current_user,
    feed_type: type,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getSavedJobListBookmark = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getSavedJobListBookmark",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getHubToJoinList = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getHubToJoinList",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getHubToJoinListGrouped = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getHubToJoinListGrouped",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getDiscoverSchoolsList = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getDiscoverSchoolsList",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getDiscoverPagesGroupSingleList = async (limit, offset, group) => {
  const details = emitWebSocketEvent({
    type: "getDiscoverPagesGroupSingleList",
    user: app.current_user,
    limit: limit,
    offset: offset,
    group: group,
  });
  return details;
};

export const getDiscoverAgencyList = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getDiscoverAgencyList",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getPageToJoinListGrouped = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getPageToJoinListGrouped",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getVibeDetails = async (vibe_id) => {
  const details = emitWebSocketEvent({
    type: "getVibeDetails",
    user: app.current_user,
    vibe_id: vibe_id,
  });
  return details;
};

export const getApplications = async () => {
  const details = emitWebSocketEvent({
    type: "getApplications",
    user: app.current_user,
  });
  return details;
};

export const getApplicationsByBusiness = async (username, page_id) => {
  const details = emitWebSocketEvent({
    type: "getApplicationsByBusiness",
    user: app.current_user,
    username,
    page_id,
  });
  return details;
};

export const getDiscoverFollowList = async (limit, offset) => {
  const details = emitWebSocketEvent({
    type: "getDiscoverFollowList",
    user: app.current_user,
    limit: limit,
    offset: offset,
  });
  return details;
};

export const getInboxList = async () => {
  const chats = emitWebSocketEvent({
    type: "getInboxList",
    user: app.current_user,
  });
  return chats;
};

export const saveUrlMetaIndexedDB = async (url, meta) => {
  const db = await initDB();
  const tx = db.transaction(META_URL_STORE, "readwrite");
  tx.store.put(meta, url);
  await tx.done;
};

export const getUrlMetaFromIndexedDB = async (url) => {
  const db = await initDB();
  const tx = db.transaction(META_URL_STORE, "readonly");
  const meta = await tx.store.get(url);
  await tx.done;
  return meta;
};

export const saveUserMetaIndexedDB = async (profile) => {
  const db = await initDB();
  const tx = db.transaction(META_USER_STORE, "readwrite");
  tx.store.put(profile, `user@${profile.user_id}`);
  await tx.done;
};

export const getUserMetaFromIndexedDB = async (userId) => {
  const db = await initDB();
  const tx = db.transaction(META_USER_STORE, "readonly");
  const store = tx.objectStore(META_USER_STORE);
  const profile = await store.get(`user@${userId}`);
  await tx.done;
  return profile;
};

export const searchFriends = async (text) => {
  const chats = emitWebSocketEvent({
    type: "searchFriends",
    user: app.current_user,
    text,
  });
  return chats;
};

export const getSearchResults = async (text, search_type) => {
  const res = emitWebSocketEvent({
    type: "getSearchResults",
    user: app.current_user,
    text,
    search_type,
  });
  return res;
};

export const getUserNotificationList = async (notification_type) => {
  const res = emitWebSocketEvent({
    type: "getUserNotificationList",
    user: app.current_user,
    notification_type,
  });
  return res;
};

export const searchPages = async (text, limit, offset) => {
  const chats = emitWebSocketEvent({
    type: "searchPages",
    user: app.current_user,
    text,
    limit,
    offset,
  });
  return chats;
};

export const searchHubs = async (text, limit, offset) => {
  const chats = emitWebSocketEvent({
    type: "searchHubs",
    user: app.current_user,
    text,
    limit,
    offset,
  });
  return chats;
};

export const getInboxFromIndexedDB = async () => {
  const db = await initDB();
  const tx = db.transaction(CHATS_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const inbox = await Promise.all(
    keys
      .filter((key) => key.startsWith("chat-")) // Filter only conversation keys
      .map(async (key) => {
        const conversationId = key.replace("chat-", ""); // Extract conversation ID
        const messages = await tx.store.get(key); // Get messages for this conversation
        const user_info = await getUserMetaFromIndexedDB();
        return {
          conversation_id: conversationId,
          messages: messages || [], // Ensure messages is an array
          user_info,
        };
      })
  );
  await tx.done;
  console.log(inbox);
  return inbox; // This will be an array of {conversation_id, messages}
};

export const getChatsFromIndexedDB = async (conversationId) => {
  const db = await initDB();
  const tx = db.transaction(CHATS_STORE, "readonly");
  const store = tx.objectStore(CHATS_STORE);
  // Retrieve the messages array stored under the key `chat-<conversation_id>`
  const messages = await store.get(`chat-${conversationId}`);
  await tx.done;
  // Ensure that messages are returned as an array or an empty array
  return Array.isArray(messages) ? messages : [];
};

// create an empty conversation
export const createConversation = async (conversationId) => {
  // check if the conversationId is already created so that to prevent overwrite of the messages
  const messages = await getChatsFromIndexedDB(conversationId);
  if (messages.length == 0) {
    // create if it has no messages
    const db = await initDB();
    const tx = db.transaction(CHATS_STORE, "readwrite");
    await tx.store.put([], `chat-${conversationId}`);
    await tx.done;
  }
};

export const saveConversationIdByUser = async (conversationId, user_id) => {
  // match conversations with user
  const db = await initDB();
  const tx = db.transaction(CONVERSATION_USER_STORE, "readwrite");
  await tx.store.put(user_id, `chat-${conversationId}`);
  await tx.done;
};

export const archiveUserMsg = async (conversationId, user_id) => {
  const db = await initDB();
  const tx = db.transaction(MSG_ARCHIEVED_STORE, "readwrite");

  const key = `chat-${conversationId}`;
  const existingUser = await tx.store.get(key);

  if (existingUser === user_id) {
    // If the user is already archived, remove the archive
    await tx.store.delete(key);
  } else {
    // Otherwise, archive the user
    await tx.store.put(user_id, key);
  }

  await tx.done;
};

export const getArchivedUserMsg = async () => {
  const db = await initDB();
  const tx = db.transaction(MSG_ARCHIEVED_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const list = await Promise.all(
    keys
      .filter((key) => key.startsWith("chat-"))
      .map((key) => tx.store.get(key))
  );
  return Array.isArray(list) ? list : [];
};

export const pinUserMsg = async (conversationId, user_id) => {
  const db = await initDB();
  const tx = db.transaction(MSG_USER_PINNED_STORE, "readwrite");

  const key = `chat-${conversationId}`;
  const existingUser = await tx.store.get(key);

  if (existingUser === user_id) {
    // If the user is already pinned, unpin
    await tx.store.delete(key);
  } else {
    // Otherwise, pin the user
    await tx.store.put(user_id, key);
  }

  await tx.done;
};

export const getPinnedUserMsg = async () => {
  const db = await initDB();
  const tx = db.transaction(MSG_USER_PINNED_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const list = await Promise.all(
    keys
      .filter((key) => key.startsWith("chat-"))
      .map((key) => tx.store.get(key))
  );
  return Array.isArray(list) ? list : [];
};

export const muteUserMsg = async (conversationId, user_id) => {
  const db = await initDB();
  const tx = db.transaction(MSG_MUTE_USER_STORE, "readwrite");

  const key = `chat-${conversationId}`;
  const existingUser = await tx.store.get(key);

  if (existingUser === user_id) {
    // If the user is already muted, unmute
    await tx.store.delete(key);
  } else {
    // Otherwise, mute the user
    await tx.store.put(user_id, key);
  }

  await tx.done;
};

export const getMutedUserMsg = async () => {
  const db = await initDB();
  const tx = db.transaction(MSG_MUTE_USER_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const list = await Promise.all(
    keys
      .filter((key) => key.startsWith("chat-"))
      .map((key) => tx.store.get(key))
  );
  return Array.isArray(list) ? list : [];
};

export const blockUserMsg = async (conversationId, user_id) => {
  const db = await initDB();
  const tx = db.transaction(MSG_BLOCKLIST_STORE, "readwrite");

  const key = `chat-${conversationId}`;
  const existingUser = await tx.store.get(key);

  if (existingUser === user_id) {
    // If the user is already blocked, unblock
    await tx.store.delete(key);
  } else {
    // Otherwise, block the user
    await tx.store.put(user_id, key);
  }

  await tx.done;
};

export const getBlockedUserMsg = async () => {
  const db = await initDB();
  const tx = db.transaction(MSG_BLOCKLIST_STORE, "readonly");
  const keys = await tx.store.getAllKeys();
  const list = await Promise.all(
    keys
      .filter((key) => key.startsWith("chat-"))
      .map((key) => tx.store.get(key))
  );
  return Array.isArray(list) ? list : [];
};

export const getUserFromConversationIndexedDB = async (conversationId) => {
  const db = await initDB();
  const tx = db.transaction(CONVERSATION_USER_STORE, "readonly");
  const store = tx.objectStore(CONVERSATION_USER_STORE);
  const user_id = await store.get(`chat-${conversationId}`);
  await tx.done;
  return user_id;
};

export const clearConversationFromIndexedDB = async (conversationId) => {
  try {
    const db = await initDB();
    const tx = db.transaction(CHATS_STORE, "readwrite");
    const store = tx.objectStore(CHATS_STORE);

    // Check if the key exists
    const key = `chat-${conversationId}`;
    const exists = await store.getKey(key);

    if (exists) {
      // Delete the conversation with the specific key
      await store.delete(key);
      console.log(`Conversation with ID ${key} deleted successfully.`);
    } else {
      console.log(`No conversation found with ID ${key}.`);
    }

    await tx.done;
  } catch (error) {
    console.error(
      `Failed to delete conversation with ID ${conversationId}:`,
      error
    );
  }
};

export const getUserProfileFromConversationIndexedDB = async (user_id) => {
  const db = await initDB();
  const tx = db.transaction(META_USER_STORE, "readonly");
  const store = tx.objectStore(META_USER_STORE);
  const profile = await store.get(`user@${user_id}`);
  await tx.done;
  return profile;
};

export const sendMessage = async (message, save_online = true) => {
  const conversationId = message.conversation_id;

  // Get current messages for the conversation from IndexedDB
  let currentMessages = await getChatsFromIndexedDB(conversationId);

  // Ensure currentMessages is a flat array (it should be already, but this is a safeguard)
  currentMessages = Array.isArray(currentMessages) ? currentMessages : [];

  // Add the new message to the currentMessages array
  const updatedChatList = [...currentMessages, message];

  // Log the currentMessages to verify the structure
  console.log("Updated Message List:", updatedChatList);

  // Store the updated array of messages back in IndexedDB
  const db = await initDB();
  const tx = db.transaction(CHATS_STORE, "readwrite");
  await tx.store.put(updatedChatList, `chat-${conversationId}`);

  await tx.done;

  // Emit the message to the WebSocket after storing it
  let submit = "";
  if (save_online) {
    submit = emitWebSocketEvent({
      type: "sendMessage",
      user: app.current_user,
      message,
    });
  }

  return submit;
};

export const insertMessage = async (message, conversationId) => {
  // Fetch current messages for the conversation from IndexedDB
  console.log("Message", message);
  let currentMessages = await getChatsFromIndexedDB(conversationId);

  // Ensure currentMessages is an array
  currentMessages = Array.isArray(currentMessages) ? currentMessages : [];

  // Debug: Log current messages and new message for comparison
  // console.log("Current Messages:", currentMessages);
  // console.log("New Message activity_id:", message.activity_id);

  // Check if the message is already in the currentMessages by activity_id
  const messageExists = currentMessages.some((msg) => {
    console.log("Comparing:", msg.activity_id, "with", message.activity_id); // Log for debugging
    return msg.activity_id === message.activity_id;
  });

  // Proceed only if the message doesn't already exist
  if (!messageExists) {
    if (Array.isArray(message)) {
      currentMessages.push(...message);
    } else {
      currentMessages.push(message);
    }

    // Initialize IndexedDB and store the updated chat list
    const db = await initDB();
    const tx = db.transaction(CHATS_STORE, "readwrite");
    await tx.store.put(currentMessages, `chat-${conversationId}`);
    await tx.done;
  }

  return ""; // Return value can be modified if needed
};

// -
// -
// -
// -
// -
// -
// -
// -
// -------------------------------------------------------------------------------
// other functions

export const formatRelativeTime = (timeElapsed) => {
  const seconds = Math.floor(timeElapsed / 1000);
  if (seconds < 60) return `${seconds} sec${seconds !== 1 ? "s" : ""}`;

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} min${minutes !== 1 ? "s" : ""}`;

  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} hr${hours !== 1 ? "s" : ""}`;

  const days = Math.floor(hours / 24);
  if (days < 7) return `${days} day${days !== 1 ? "s" : ""}`;

  const weeks = Math.floor(days / 7);
  if (weeks < 4) return `${weeks} wk${weeks !== 1 ? "s" : ""}`;

  const months = Math.floor(days / 30);
  if (months < 12) return `${months} mt${months !== 1 ? "s" : ""}`;

  const years = Math.floor(days / 365);
  return `${years} yr${years !== 1 ? "s" : ""}`;
};

export const extractLinks = (text) => {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Use the regex to find all matches in the text
  const links = text.match(urlRegex);

  // Return the array of links or an empty array if no links were found
  return links || [];
};

// Function to parse hashtags, mentions, links, and emails
export const parseContent = (text) => {
  // Regex patterns for URLs, emails, hashtags, and mentions
  const urlPattern =
    /(\b(https?|ftp|www):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;
  const hashtagPattern = /(^|\s)#(\w+)/g; // Avoid matching # inside URLs
  const mentionPattern = /(^|\s)@(\w+)/g; // Avoid matching @ inside emails and URLs

  // Replace URLs with clickable links
  let parsedText = text
    .replace(
      urlPattern,
      '<a href="$1" target="_blank" class="font-semibold hover_decoration_link rounded-full">$1</a>'
    )
    // Replace emails with mailto links
    .replace(
      emailPattern,
      '<a href="mailto:$&" class="font-semibold hover_decoration_link rounded-full">$&</a>'
    )
    // Replace hashtags with links
    .replace(
      hashtagPattern,
      '$1<a href="/topics/$2" class="font-semibold hover_decoration_link rounded-full">#$2</a>'
    )
    // Replace mentions with links
    .replace(
      mentionPattern,
      '$1<a href="/profile/$2" class="font-semibold hover_decoration_link rounded-full">@$2</a>'
    );

  return parsedText;
};

export const getCroppedImg = async (imageSrc, crop, rotation = 0) => {
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - crop.x,
    0 - safeArea / 2 + image.height * 0.5 - crop.y
  );

  return new Promise((resolve) => {
    const base64Image = canvas.toDataURL("image/jpeg");
    resolve(base64Image);
  });
};
