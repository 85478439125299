import React, { useEffect, useState } from "react";
import HubSlide from "../components/HubSlide";
import HubSliderDummy from "../components/Dummies/HubSliderDummy";
import { getHubToJoinListGrouped } from "../../db"; // Make sure this function returns the correct data
import Loader from "../components/Loader";

function DiscoverHubs() {
  const [allHubs, setAllHubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if there's more data to load

  useEffect(() => {
    fetchHubsListData(); // Fetch initial data
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
  }, [offset]);

  const fetchHubsListData = async () => {
    // if (!hasMore || loading) return; // Prevent fetching if already loading or no more hubs

    setLoading(true);
    try {
      const data = await getHubToJoinListGrouped(6, offset); // Ensure this returns the correct data structure
      console.log("Hubs data -> ", data);

      // Check if no more hubs to load
      if (data && data.all && data.all.length === 0) {
        setHasMore(false); // Stop loading if no more hubs are returned
      }

      // Append new hubs to the list
      setAllHubs((prevHubs) => [...prevHubs, ...(data.all || [])]);
    } catch (error) {
      console.error("Error fetching hubs:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  // Handle scroll and load more hubs if at bottom
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight &&
      hasMore &&
      !loading
    ) {
      setOffset((prevOffset) => prevOffset + 10); // Increase offset to load more hubs
    }
  };

  const loadMore = () => {
    setOffset((prevOffset) => prevOffset + 10);
  };

  return (
    <div className="overflow-hidden">
      {loading && allHubs.length === 0 && (
        <div className="flex gap-0 pt-5 sm-grid-cols-1 sm-p-1">
          <HubSliderDummy />
          <HubSliderDummy />
        </div>
      )}

      {/* Render hubs if available */}
      {allHubs.length > 0 && (
        <>
          <div className="flex align-center justify-between px-4">
            <div className="text-xl font-bold">Hubs on VibeIn</div>
          </div>

          <div className="slider grid grid-cols-3 sm-grid-cols-1 sm-p-1 align-center gap-4 py-5 flex-wrap px-4">
            {allHubs.map((item, index) => (
              <HubSlide key={index} simple={true} data={item} />
            ))}
          </div>
        </>
      )}

      {/* Show load more button or message */}
      <div className="align-center justify-center w-full py-5 flex">
        {hasMore ? (
          <button
            className={`${!loading ? "btn" : ""}`}
            onClick={loadMore}
            disabled={loading}
          >
            {loading ? <Loader /> : "Load more"}
          </button>
        ) : (
          <div>No more hubs to load</div>
        )}
      </div>
    </div>
  );
}

export default DiscoverHubs;
