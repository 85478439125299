import React, { useEffect, useState } from "react";
import { extractLinks } from "../../db";
import LinkPreview from "../components/LinkPreview";
import ChatTextContent from "../components/ChatTextContent";

function MessagesItem({ item }) {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    const getlink = () => {
      const lkns = extractLinks(item.chat_body);
      setLinks(lkns);
    };
    getlink();
  }, []);

  return (
    <>
      {item.chat_body !== "" && (
        <>
          {Array.isArray(links) && links.length > 0 && (
            <LinkPreview links={links} show_featured_img={true} />
          )}

          <div
            className={`w-auto min-w-20 rounded-xl min-h-10 chat p-2 px-4 content animate__animated animate__slideInUp animate_fast ${
              links.length > 0 && "chat_has_link"
            }`}
          >
            <ChatTextContent text={item.chat_body} />
          </div>
        </>
      )}
    </>
  );
}

export default MessagesItem;
