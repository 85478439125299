import React from "react";

function Links() {
  return (
    <div>
      <div className="">
        <div className="hover px-4 py-1">
          <div className="font-semibold">You</div>
          <div className="text-mute">https://www.reddit.com/</div>
          <div className="text-sm mt-2">
            <div className="flex align-center gap-2">
              <span class="material-icons-round">schedule</span>
              <div className="text-sm">
                2 June 2024 <small>at</small> 11:20am
              </div>
            </div>
          </div>
        </div>

        <div className="hover px-4 py-1">
          <div className="font-semibold">Allan</div>
          <div className="text-mute">https://www.vibein.com/</div>
          <div className="text-sm mt-2">
            <div className="flex align-center gap-2">
              <span class="material-icons-round">schedule</span>
              <div className="text-sm">
                2 June 2024 <small>at</small> 11:20am
              </div>
            </div>
          </div>
        </div>

        <div className="hover rounded p-1 cursor-pointer mt-3 px-4 select-none text-sm">
          See more
        </div>
      </div>
    </div>
  );
}

export default Links;
