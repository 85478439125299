export const interests = {
    automotive: [
        "Cars", "Trucks", "SUVs", "Electric Vehicles", "Hybrid Cars", "Motorcycles", "Car Reviews", 
        "Car Maintenance", "Auto Repair", "Car Accessories", "Car Parts", "Vehicle Safety", 
        "Car Insurance", "Car Loans", "Auto Shows", "Vintage Cars", "Luxury Cars", "Sports Cars", 
        "Car Racing", "Off-Road Vehicles", "Classic Cars", "Car Detailing", "Auto News", 
        "Driving Tips", "Road Trips", "Car Culture", "Automotive Technology", "Car Customization", 
        "Car Brands", "Car Leasing"
    ],
    beauty: [
        "Makeup", "Skincare", "Haircare", "Beauty Tips", "Cosmetics", "Nail Art", "Facial Treatments", 
        "Beauty Trends", "Anti-Aging", "Hair Styling", "Fragrances", "Natural Beauty", "Beauty Tutorials", 
        "Beauty Products", "Spa Treatments", "Beauty Hacks", "Grooming", "Beauty Blogs", "Beauty Vlogs", 
        "Makeup Reviews", "Beauty Influencers", "Hair Colors", "Makeup Looks", "Beauty Routines", 
        "Skin Types", "Sun Protection", "Makeup Brushes", "Beauty Contests", "Dermatology", "Haircuts"
    ],
    business: [
        "Entrepreneurship", "Startups", "Small Business", "Marketing", "E-commerce", "Investing", 
        "Finance", "Leadership", "Management", "Business Growth", "Sales", "Business Strategy", 
        "Networking", "Business News", "Economic Trends", "Corporate Culture", "Business Ethics", 
        "Workplace", "Human Resources", "Public Relations", "Advertising", "Branding", 
        "Customer Service", "Business Development", "Innovation", "Market Research", "Business Planning", 
        "Remote Work", "Business Analytics", "Supply Chain"
    ],
    careers: [
        "Job Search", "Career Advice", "Resume Tips", "Interview Tips", "Career Development", 
        "Workplace Skills", "Career Growth", "Freelancing", "Work-Life Balance", "Networking", 
        "Job Fairs", "Professional Development", "Career Changes", "Remote Jobs", "Internships", 
        "Cover Letters", "Job Applications", "Workplace Diversity", "Career Counseling", 
        "Job Market Trends", "Workplace Culture", "Employee Benefits", "Career Goals", "Soft Skills", 
        "Leadership Skills", "Career Advancement", "Job Satisfaction", "Mentoring", "Career Workshops", 
        "Corporate Jobs"
    ],
    education: [
        "Online Learning", "Higher Education", "Study Tips", "E-learning", "Homeschooling", 
        "College Life", "Scholarships", "Education Technology", "STEM Education", "Adult Education", 
        "Language Learning", "Educational Resources", "Tutoring", "Student Loans", "Classroom Management", 
        "Teaching Strategies", "Learning Disabilities", "Distance Learning", "Educational Apps", 
        "Exam Preparation", "Education News", "Special Education", "Teacher Training", 
        "Educational Games", "Early Childhood Education", "Parenting Tips", "School Supplies", 
        "Curriculum Development", "Student Engagement", "Extracurricular Activities"
    ],
    events: [
        "Concerts", "Festivals", "Sports Events", "Trade Shows", "Conferences", "Workshops", 
        "Webinars", "Charity Events", "Community Events", "Networking Events", "Parties", 
        "Live Music", "Art Exhibitions", "Film Festivals", "Comedy Shows", "Food Festivals", 
        "Fashion Shows", "Award Ceremonies", "Cultural Events", "Business Events", "Educational Events", 
        "Family Events", "Holiday Events", "Virtual Events", "Book Signings", "Product Launches", 
        "Corporate Events", "Seminars", "Theater Performances", "Dance Performances"
    ],
    family_relationships: [
        "Parenting", "Marriage", "Family Activities", "Dating", "Relationships", "Family Health", 
        "Parenting Tips", "Child Development", "Family Travel", "Work-Life Balance", "New Parents", 
        "Adoption", "Foster Care", "Sibling Relationships", "Elder Care", "Family Finances", 
        "Single Parenting", "Family Traditions", "Home Life", "Family Communication", "Relationship Advice", 
        "Family Counseling", "Blended Families", "Co-Parenting", "Family Wellness", "Family Holidays", 
        "Family Fun", "Relationship Goals", "Marriage Tips", "Relationship Challenges"
    ],
    fitness_wellness: [
        "Workout", "Yoga", "Meditation", "Fitness Tips", "Healthy Eating", "Weight Loss", 
        "Strength Training", "Cardio", "Pilates", "Running", "Fitness Motivation", "Exercise Routines", 
        "Fitness Equipment", "Personal Training", "Wellness Retreats", "Mental Health", "Wellness Tips", 
        "Nutrition", "Healthy Recipes", "Fitness Challenges", "Health Goals", "Hydration", 
        "Fitness Classes", "Fitness Apps", "Wellness Products", "Stress Management", "Mindfulness", 
        "Home Workouts", "Fitness Tracking", "Fitness Blogs"
    ],
    food_drink: [
        "Recipes", "Cooking", "Baking", "Healthy Eating", "Vegetarian", "Vegan", "Gluten-Free", 
        "Food Photography", "Food Blogs", "Food Trends", "Restaurants", "Food Reviews", "Home Cooking", 
        "International Cuisine", "Desserts", "Beverages", "Cocktails", "Wine", "Coffee", "Tea", 
        "Meal Prep", "Comfort Food", "Street Food", "Food Festivals", "Food Safety", "Diet Tips", 
        "Nutrition", "Organic Food", "Gourmet Food", "Food Science"
    ],
    gaming: [
        "Video Games", "Gaming News", "Esports", "PC Gaming", "Console Gaming", "Mobile Gaming", 
        "Game Reviews", "Gaming Tips", "Indie Games", "Multiplayer Games", "Gaming Events", 
        "Retro Gaming", "Gaming Gear", "Game Development", "Streaming", "Twitch", "YouTube Gaming", 
        "Gaming Communities", "Virtual Reality", "Gaming Consoles", "Role-Playing Games", "Strategy Games", 
        "Action Games", "Adventure Games", "Simulation Games", "Sports Games", "Puzzle Games", 
        "Board Games", "Card Games", "Online Gaming"
    ],
    hobbies_interests: [
        "Photography", "Gardening", "Crafting", "Drawing", "Painting", "DIY Projects", "Knitting", 
        "Sewing", "Cooking", "Baking", "Reading", "Writing", "Collecting", "Hiking", "Fishing", 
        "Camping", "Bird Watching", "Traveling", "Blogging", "Podcasting", "Model Building", 
        "Board Games", "Puzzles", "Magic Tricks", "Calligraphy", "Woodworking", "Robotics", 
        "Astronomy", "Genealogy", "Origami"
    ],
    home_garden: [
        "Home Decor", "Interior Design", "Gardening", "Landscaping", "Home Improvement", 
        "DIY Projects", "Home Organization", "Cleaning Tips", "Furniture", "Appliances", 
        "Kitchen Design", "Bathroom Design", "Outdoor Living", "Home Renovation", "Real Estate", 
        "Home Safety", "Smart Home", "Eco-Friendly Living", "Pet Care", "Home Security", 
        "Gardening Tips", "Houseplants", "Garden Design", "Backyard Ideas", "Sustainable Living", 
        "Storage Solutions", "Home Maintenance", "Home Lighting", "Home Automation"
    ],
    movies_tv: [
        "Movies", "TV Shows", "Streaming", "Film Reviews", "Movie Trailers", "Film Festivals", 
        "Celebrities", "Hollywood", "Independent Films", "Documentaries", "Action Movies", 
        "Comedy Movies", "Drama Movies", "Horror Movies", "Romantic Movies", "Sci-Fi Movies", 
        "Fantasy Movies", "Animated Movies", "TV Series", "Reality TV", "Sitcoms", "TV Drama", 
        "Mini-Series", "Cartoons", "Classic Movies", "Movie Awards", "Film Industry", 
        "TV Production", "Film Directors", "Screenwriting"
    ],
    music: [
        "Pop Music", "Rock Music", "Hip Hop", "Classical Music", "Jazz", "Country Music", 
        "Electronic Music", "Music Festivals", "Concerts", "Music Reviews", "Music Videos", 
        "New Music", "Indie Music", "Live Music", "Music Awards", "Musicians", "Bands", 
        "Songwriting", "Music Production", "Music Streaming", "Albums", "EPs", "Singles", 
        "Music Charts", "Guitar", "Piano", "Drums", "Music Lessons", "Music Industry", "Record Labels"
    ],
    news_politics: [
        "Breaking News", "Politics", "World News", "Local News", "Government", "Elections", 
        "Political Analysis", "International Relations", "Policy", "Law", "Economy", "Environment", 
        "Human Rights", "Social Issues", "Health News", "Education Policy", "Public Safety", 
        "Immigration", "Military", "Diplomacy", "Elections", "Campaigns", "Activism", "Media", 
        "Fact-Checking", "News Commentary", "Journalism", "Press Freedom", "Voting", "Public Opinion"
    ],
    personal_finance: [
        "Budgeting", "Saving Money", "Investing", "Retirement Planning", "Debt Management", 
        "Credit Cards", "Loans", "Taxes", "Real Estate", "Insurance", "Financial Planning", 
        "Personal Finance Tips", "Wealth Management", "Frugality", "Side Hustles", "Financial Independence", 
        "Passive Income", "Stock Market", "Cryptocurrency", "Banking", "Mortgages", "Estate Planning", 
        "Emergency Fund", "Pension Plans", "Financial Goals", "Money Management", "Savings Accounts", 
        "Credit Score", "Student Loans", "Financial Literacy"
    ],
    pets: [
        "Dog Care", "Cat Care", "Pet Health", "Pet Training", "Pet Nutrition", "Pet Grooming", 
        "Pet Adoption", "Pet Accessories", "Pet Behavior", "Pet Breeds", "Pet Safety", "Pet Travel", 
        "Pet Toys", "Veterinary Care", "Exotic Pets", "Pet Insurance", "Pet Rescue", "Pet Sitters", 
        "Pet-Friendly Places", "Pet Communication", "Pet Psychology", "Pet Shows", "Pet Products", 
        "Pet Diets", "Pet Fitness", "Pet Care Tips", "Pet Ownership", "Pet Vaccinations", "Pet Laws", 
        "Pet Boarding"
    ],
    real_estate: [
        "Home Buying", "Home Selling", "Real Estate Market", "Real Estate Investing", "Property Management", 
        "Real Estate Agents", "Mortgages", "Home Inspections", "Commercial Real Estate", 
        "Real Estate Trends", "Home Appraisal", "Real Estate Development", "Real Estate Law", 
        "Luxury Real Estate", "Rental Properties", "Property Tax", "Home Equity", "Real Estate Financing", 
        "Real Estate Listings", "Home Staging", "Real Estate Photography", "Open Houses", "Homeowners Association", 
        "Foreclosures", "Real Estate Marketing", "Real Estate Tips", "Real Estate News", 
        "First-Time Home Buyers", "Real Estate Technology", "Property Valuation"
    ],
    science_tech: [
        "Space Exploration", "Artificial Intelligence", "Robotics", "Physics", "Biology", "Chemistry", 
        "Technology News", "Gadgets", "Innovations", "Computer Science", "Software Development", 
        "Renewable Energy", "Climate Change", "Medical Research", "Astronomy", "Environmental Science", 
        "Genetics", "Nanotechnology", "Quantum Computing", "Cybersecurity", "Biotechnology", "Space Missions", 
        "Science Discoveries", "Tech Reviews", "Smartphones", "Wearable Tech", "Science Fiction", 
        "Scientific Research", "Tech Startups", "Virtual Reality"
    ],
    sports: [
        "Football", "Basketball", "Baseball", "Soccer", "Tennis", "Golf", "Swimming", "Running", 
        "Cycling", "Boxing", "MMA", "Wrestling", "Cricket", "Hockey", "Rugby", "Volleyball", 
        "Gymnastics", "Olympics", "Sports News", "Athlete Profiles", "Sports Teams", "Sports Events", 
        "Sports Analysis", "Fantasy Sports", "Extreme Sports", "Motorsports", "Winter Sports", 
        "Sports History", "Paralympics", "College Sports"
    ],
    style_fashion: [
        "Fashion Trends", "Street Style", "Fashion Shows", "Designer Brands", "Fashion Tips", 
        "Fashion Accessories", "Footwear", "Jewelry", "Bags", "Fashion Photography", "Modeling", 
        "Fashion Magazines", "Fashion Influencers", "Men's Fashion", "Women's Fashion", "Kids' Fashion", 
        "Fashion History", "Sustainable Fashion", "Luxury Fashion", "Casual Wear", "Formal Wear", 
        "Seasonal Fashion", "Swimwear", "Activewear", "Vintage Fashion", "Fashion Blogs", 
        "Fashion Designers", "Wardrobe Essentials", "Fashion Styling", "Fashion Events"
    ],
    travel: [
        "Destinations", "Travel Tips", "Adventure Travel", "Travel Guides", "Travel Photography", 
        "Travel Deals", "Solo Travel", "Family Travel", "Budget Travel", "Luxury Travel", 
        "Cultural Travel", "Travel Gear", "Road Trips", "Eco-Friendly Travel", "Travel Safety", 
        "Travel Itineraries", "Travel Experiences", "Travel Reviews", "Beach Vacations", "Mountain Getaways", 
        "City Breaks", "Travel Blogs", "Travel Vlogs", "Backpacking", "Travel Agencies", "Travel Apps", 
        "Travel Planning", "Travel Contests", "Travel Insurance", "Travel Souvenirs"
    ],
    wedding: [
        "Wedding Planning", "Wedding Dresses", "Bridal Fashion", "Wedding Venues", "Wedding Photography", 
        "Wedding Invitations", "Wedding Decor", "Wedding Cakes", "Wedding Rings", "Wedding Flowers", 
        "Wedding Music", "Wedding Budget", "Destination Weddings", "Wedding Hairstyles", "Wedding Makeup", 
        "Bridesmaids", "Groomsmen", "Wedding Vows", "Wedding Traditions", "Wedding Gifts", 
        "Wedding Registry", "Wedding Favors", "Wedding Themes", "Wedding Trends", "Wedding Receptions", 
        "Wedding Catering", "Honeymoons", "Engagements", "Elopements", "Wedding Planning Tips"
    ]
};