import React, { useEffect } from "react";

import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import app from "../../config/app";
import {
  saveBusinessAccessToIndexedDB,
  saveContactInfoToIndexedDB,
  saveHubAccessToIndexedDB,
  saveInterestsToIndexedDB,
  saveLanguageToIndexedDB,
  savePrivacySettingsToIndexedDB,
  saveProfessionalSettingsToIndexedDB,
  saveProfileImageAndCoverSettingsToIndexedDB,
  saveProfileToIndexedDB,
  saveTimezoneToIndexedDB,
} from "../../db";
import CopyRight from "../components/CopyRight";
import Loader from "../components/Loader";

export default function AuthChangePassword() {
  const [open, setOpen] = useState(true);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle confirm password visibility

  const [errorMsg, setErrorMsg] = useState("");
  const [close, setClose] = useState(true);

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const sysUser = Cookies.get("sys_user");
    setUser(sysUser);
    setLoading(false);
  }, []);

  const handleCreatePassword = async () => {
    setErrorMsg("");
    setLoading(true);
    if (password != "" && password == confirmPassword) {
      try {
        const { data } = await axios.post(
          `${app.auth_request_url}AUTH_UPADATE_PASSWORD`,
          {
            email: user,
            password,
          }
        );

        if (data.r_status) {
          Cookies.set("sys_user", data.user_activity_id, {
            expires: 365,
          }); // Save the token as a cookie for 365 days
          console.log("Signup res", data);

          const profile = data.user_settings.profile[0];
          const {
            business_access_list,
            hub_access_list,
            user_settings: {
              profile: [profile_responce],
            },
          } = data;

          // Prepare IndexedDB operations in parallel
          const saveProfileOps = [
            saveProfileToIndexedDB({
              fullName: profile.fullname,
              username: profile.username,
              bio: profile.bio,
              jobTitle: profile.current_job_title,
              dateOfBirth: profile.date_of_birth,
            }),
            savePrivacySettingsToIndexedDB({
              coverImageUpdate: profile.privacyHideCoverImageUpdate,
              onlineStatusChats: profile.privacyHideOnlineStatusOnChats,
              onlineStatusPosts: profile.privacyHideOnlineStatusOnPosts,
              profileImageUpdate: profile.privacyHideProfilePictureUpdate,
              profileVisibility: profile.privacyHideProfileSearchResults,
            }),
            saveContactInfoToIndexedDB({
              address: profile.address,
              country: profile.country_phone,
              email: profile.email,
              phoneNumber: profile.phone,
            }),
            saveProfessionalSettingsToIndexedDB({
              availabilityStatus: profile.availabilityStatus,
              downloadMyCv: profile.downloadMyCv,
            }),
            saveProfileImageAndCoverSettingsToIndexedDB({
              profile_image: profile.profile_image,
              cover_image: profile.cover_image,
            }),
            saveLanguageToIndexedDB(profile.language || "English"),
            saveTimezoneToIndexedDB(profile.timezone || "Africa/Nairobi"),
            saveInterestsToIndexedDB(profile.interest),
            // saveSkillsToIndexedDB(profile.skills), // assuming skills was meant to be saved
            saveBusinessAccessToIndexedDB(business_access_list),
            saveHubAccessToIndexedDB(hub_access_list),
          ];

          // Execute all IndexedDB operations in parallel
          await Promise.all(saveProfileOps);

          // Handle navigation after sign-in
          // if (currentPath === "/auth/signin" || currentPath === "/Auth/signin") {
          setTimeout(() => {
            window.location = "/";
          }, 10);
          // } else {
          //   setOpen(false);
          // }
          setErrorMsg(data.responce);
          setLoading(false);
        } else {
          setLoading(false);
          setErrorMsg(data.responce);
        }
      } catch (error) {
        console.error("Sign-in error:", error);
        alert("An error occurred. Please try again.");
        setLoading(false);
      }
    } else {
      setErrorMsg("Confrim password");
      setLoading(false);
    }
  };

  return (
    <Dialog className="relative z-10 auth_modal" open={open} onClose={setClose}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in modal_backdrop"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-white modal_container">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            style={{ marginTop: -34, borderRadius: 20 }}
          >
            <div className="bg-white pb-3 pt-4">
              <div className="sm:flex sm:items-start w-full">
                <div className="text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="px-6 text-base font-semibold justify-center leading-6 text-gray-900 mb-6 border-bottom pb-5 flex align-center justify-between"
                  >
                    <div className="flex align-center flex-col w-full">
                      <div className="h-16 w-16 mr-3 rounded-full mb-3">
                        <img src="/images/icon.png" alt="Icon" />
                      </div>
                      <div>Update your login password</div>
                    </div>
                  </DialogTitle>
                  {loading && (
                    <div className="absolute w-full flex align-center justify-center h-full top-0 auth_loader_cont">
                      <Loader />
                    </div>
                  )}

                  <div className=" px-6">
                    <div className="mt-2 relative">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        className="w-full form-control"
                        placeholder="Create a password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        type="div"
                        className="absolute right-3 top-4 text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <span class="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span class="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className=" px-6">
                    <div className="mt-2 relative">
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        className="w-full form-control"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className="absolute right-3 top-4 text-gray-500"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <span class="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span class="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errorMsg != "" && (
              <div className="px-4 sm:flex sm:px-6 w-full mb-3">{errorMsg}</div>
            )}
            <div className="px-4 py-4 sm:flex sm:px-6 w-full mb-10">
              <button
                type="button"
                onClick={handleCreatePassword}
                className="inline-flex justify-center rounded-xl w-full bg-black px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:w-full"
              >
                Submit
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
      <div className="footer_details_auth">
        <CopyRight showLess={true} />
      </div>
    </Dialog>
  );
}
