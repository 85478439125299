import React, { useState } from "react";
import app from "../../config/app";
import axios from "axios";
import CopyRight from "../components/CopyRight";
import Responce from "../components/Responce";

function Security() {
  const [showPassword, setShowPassword] = useState(false);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    user: app.current_user,
    current_password: "",
    new_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // console.log("Form submitted:", formData);
    if (formData.new_password == formData.confirm_password) {
      setShowConfirmPassword(false);
      try {
        const response = await axios.post(
          app.auth_request_url + "UPDATE_PASSWORD",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data) {
          setShowResponceCard(true);

          setTimeout(() => {
            setShowResponceCard(false);
          }, 3000);
          setResponceCard(
            <Responce
              data={{
                text: response.data.responce,
                status: response.data.r_status,
              }}
            />
          );
          // console.log(responce_card);
        } else {
          alert("Nop");
        }
        console.log("Form submitted successfully:", response.data);
        // Handle the response data as needed
      } catch (error) {
        console.error("There was a problem with the axios operation:", error);
        // Handle the error as needed
      }
    } else {
      setShowConfirmPassword(true);
    }
  };

  return (
    <div className="p-7">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Security setting</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5">
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" for="name">
                Current password
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span class="material-icons-round">shield</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type={!showPassword ? "password" : "text"}
                  name="current_password"
                  id="current_password"
                  value={formData.current_password}
                  onChange={handleChange}
                  placeholder="Password that you use to login"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" for="name">
                New password
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span class="material-icons-round">key</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type={!showPassword ? "password" : "text"}
                  name="new_password"
                  id="new_password"
                  value={formData.new_password}
                  onChange={handleChange}
                  placeholder="Change your password"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" for="name">
                Confirm password
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span class="material-icons-round">key</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type={!showPassword ? "password" : "text"}
                  name="confirm_password"
                  id="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                  placeholder="Password"
                />
              </div>
            </div>
          </div>
          {showConfirmPassword ? (
            <div className="mt-2 text-sm px-2 text-mute text-orange-300">
              Confirm your new password to continue
            </div>
          ) : (
            ""
          )}

          <div
            className="hover py-2 rounded-xl px-2 mt-2 w-32 flex slign-center justify-center"
            onClick={() => {
              showPassword ? setShowPassword(false) : setShowPassword(true);
            }}
          >
            {!showPassword ? "Show password" : "Hide password"}
          </div>

          <button class="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      <div className="mt-10">
        <hr className="line" />
        <CopyRight />
        {showResponceCard && responceCard}
      </div>
    </div>
  );
}

export default Security;
