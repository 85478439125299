import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import {
  saveHubAccessToIndexedDB,
  createNewHub,
  updateLocalStorage,
} from "../db";
import Responce from "./components/Responce";
import app from "../config/app";
import Loader from "./components/Loader";

function CreateHub() {
  const navigate = useNavigate();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
  });
  useEffect(() => {
    setLoading(false);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    document.body.classList.add("wide_body");
    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove("wide_body");
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  const handleSubmit = async (event) => {
    // handle form submition for creating a busindess page
    event.preventDefault();
    setLoading(true);
    let current_hub = Date.now().toString() + "@" + app.current_user;
    const submit_data = {
      id: current_hub,
      data: formData,
    };

    const responce = await createNewHub(submit_data);
    updateLocalStorage({ type: "updateHub", data: formData });
    // console.log("Responce", responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // let save pages access to the indexed db
      const save_responce = await saveHubAccessToIndexedDB(
        responce.access_list
      );

      navigate("/hubadmin/" + current_hub);
      // console.log(save_responce);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="flex-1">
        <div className="px-4 flex align-center headers w-full">
          <div className="flex align-center flex-1 w-full">
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Create a Community (Hub)</div>
            </div>
          </div>
          <TopUserMenu />
        </div>

        <div className="w-full flex align-center justify-center py-7 pb-0">
          {/* create content here */}
          <div className="m-0 align-center justify-center flex w-full px-10">
            <div className="box w-full rounded-xl px-10 py-5 w-full flex align-start gap-5">
              <div className="illustration w-2/4 flex align-center justify-center">
                <img
                  src="/images/beam-people-working-in-a-team.png"
                  className="w-2/3"
                />
              </div>
              <div className="w-2/4 border-left pl-10 pr-4 relative">
                {loading && (
                  <div className="absolute w-full flex align-center justify-center h-full top-0 auth_loader_cont">
                    <Loader />
                  </div>
                )}
                <h4 className="text-2xl font-bold my-4">VibeIn Communities</h4>
                <div className="explainer text-mute">
                  Unlock the power of VibeIn's business page to effortlessly
                  enhance your online presence. Seamlessly manage your
                  business's vibes, job opportunities, and remote workforce with
                  advanced efficiency
                </div>
                <form method="POST" id="createHub" onSubmit={handleSubmit}>
                  <div className="w-full flex flex-column gap-3 mt-5">
                    <label className="full">
                      Hub name<span className="inline ml-2">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Add a name"
                      name="name"
                      required=""
                      className="form-control px-5"
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </div>
                  <div className="w-full flex flex-column gap-3 mt-5">
                    <label>
                      Select Type
                      <span className="inline ml-2">*</span>
                    </label>
                    <select
                      name="type"
                      className="form-control px-5"
                      onChange={handleChange}
                      value={formData.type}
                    >
                      <option value="" disabled="">
                        Select here
                      </option>
                      <option value="public">Public</option>
                      <option value="private">Private</option>
                    </select>
                  </div>

                  <div className="get_respoce"></div>
                  <button
                    type="submit"
                    className="h-12 btn rounded-xl text-center flex align-center w-full my-4 mt-10 justify-center"
                    style={{ borderRadius: 10 }}
                  >
                    <span>Continue</span>
                  </button>
                  <div className="res mt-1"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateHub;
