import axios from "axios";
import React, { useState, useEffect } from "react";
import app from "../../config/app";
import CopyRight from "../components/CopyRight";
import { countries } from "../../config/countries";
import {
  saveContactInfoToIndexedDB,
  getContactInfoFromIndexedDB,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function ContactInfo({ local }) {
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    country: "",
    address: "",
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      const savedContactInfo = await getContactInfoFromIndexedDB();
      if (savedContactInfo) {
        setFormData(savedContactInfo);
      }
    };
    fetchContactInfo();
  }, [local]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const responce = await saveContactInfoToIndexedDB(formData);
    updateLocalStorage({ type: "updateContactInfo", data: formData });
    console.log("Responce", responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  return (
    <div className="p-7">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Contact info</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5">
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="country">
                Select country
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">public</span>
                </div>
                <select
                  className="form-control w-full relative has_icon"
                  name="country"
                  id="country"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="">Select a country</option>
                  {Object.keys(countries).map((countryCode) => (
                    <option
                      key={countryCode}
                      value={countries[countryCode].code}
                    >
                      {countries[countryCode].name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="phoneNumber">
                Phone number
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">phone</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Phone number without country code"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="email">
                Email address
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">mail</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your email address"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="address">
                Physical address (Optional)
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">pin_drop</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="address"
                  id="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Physical address"
                />
              </div>
            </div>
          </div>
          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      <div className="mt-10">
        <hr className="line" />
        <CopyRight />
        {showResponceCard && responceCard}
      </div>
    </div>
  );
}

export default ContactInfo;
