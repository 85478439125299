// src/index.js
import React from "react";
import ReactDOM from "react-dom"; // Correct import for ReactDOM
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import process from "process";
import { socket } from "./db";

// alert("Index");
// socket.on("newUpdateData", (data) => {
//   console.log(data);
//   alert("New data detected");
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

reportWebVitals();
