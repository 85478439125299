import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import SearchMessages from "./SearchMessages";
import Media from "./Tabs/Media";
import Videos from "./Tabs/Videos";
import Links from "./Tabs/Links";
import Documents from "./Tabs/Documents";

function ProfileDetails({ user, activeChatid, conversationData }) {
  const [activeTab, setActiveTab] = useState();
  const [showSearchMessages, setShowSearchMessages] = useState(false);
  const [userProfileInfo, setUserProfileInfo] = useState([]);
  const [conversation, setConversation] = useState([]);
  const location = useLocation();
  let currentPath = location.pathname;
  let { focus, tab } = useParams();
  // console.log("conversationData", conversationData);
  useEffect(() => {
    // console.log("User info -- ", user);
    if (focus && focus == "search") {
      setShowSearchMessages(true);
    } else {
      setShowSearchMessages(false);
    }
  }, [currentPath, user, activeChatid]);

  const tabslist = [
    {
      id: 1,
      path: "/Messages/" + activeChatid + "/details/tabs/images",
      name: "Media",
    },
    {
      id: 3,
      path: "/Messages/" + activeChatid + "/details/tabs/docs",
      name: "Docs",
    },
    {
      id: 4,
      path: "/Messages/" + activeChatid + "/details/tabs/links",
      name: "Links",
    },
  ];

  let component = <Media conversationData={conversationData} />;

  if (tab) {
    if (tab == "images") {
      component = <Media conversationData={conversationData} />;
    } else if (tab == "videos") {
      component = <Videos conversationData={conversationData} />;
    } else if (tab == "links") {
      component = <Links conversationData={conversationData} />;
    } else if (tab == "docs") {
      component = <Documents conversationData={conversationData} />;
    } else {
      component = <Media conversationData={conversationData} />;
    }
  } else {
    component = <Media conversationData={conversationData} />;
  }

  return (
    <div>
      {!showSearchMessages ? (
        <>
          <div className="flex align-center justify-center w-full py-3 my-2 flex-column">
            <div className="w-32 h-32 p-1.5 rounded-full flex align-center justify-center bg-gradient mb-3 animate__animated animate__zoomIn animate_fast">
              <img
                src={user.profile_image}
                className="w-full h-full rounded-full object-cover"
              />
            </div>
            <div className="text-2xl font-bold">{user.fullname}</div>
            <div className="text-mute textsm">@{user.username}</div>
            {/* <div className="text-sm">Last seen 20min ago</div> */}
            <a
              target="blank"
              href={`/Profile/@${user.username}`}
              className="w-full flex align-center justify-center gap-3 mt-3"
            >
              <div className="border-1 rounded-full px-2 pl-4 gap-3 h-9 flex align-center justify-between text-sm font-normal hover">
                View profile
                <span className="material-symbols-outlined">arrow_right</span>
              </div>
            </a>
          </div>
          <div className="cutom_tabs">
            <div className="attachment flex gap-0 align-center justify-between px-4 headers p-0 h-auto">
              {tabslist.map((item, index) => (
                <Link
                  key={item.id}
                  to={item.path}
                  className={
                    currentPath == item.path
                      ? "active_tab w-full text-center h-10 custom_tab_item"
                      : " w-full text-center h-10 custom_tab_item"
                  }
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="tab_content">{component}</div>
          </div>
        </>
      ) : (
        <SearchMessages conversationData={conversationData} user={user} />
      )}
    </div>
  );
}

export default ProfileDetails;
