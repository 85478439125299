import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserInfoByUsername, submitFollowUser } from "../db";

// Memoize imported components
import TopUserMenu from "./components/TopUserMenu";
import ProfileCard from "./components/ProfileCard";
import FollowList from "./components/FollowList";
import UserVibes from "./components/UserVibes";
import UserMedia from "./components/UserMedia";
import UserProfessional from "./components/UserProfessional";
import UserActivities from "./components/UserActivities";
import UserFollowing from "./components/UserFollowing";
import UserFollowers from "./components/UserFollowers";
import FollowListReused from "./components/FollowListReused";
import CopyRight from "./components/CopyRight";
import app from "../config/app";
import PostContent from "./components/PostContent";

// Wrap imported components with React.memo
const MemoizedTopUserMenu = React.memo(TopUserMenu);
const MemoizedProfileCard = React.memo(ProfileCard);
const MemoizedFollowList = React.memo(FollowList);
const MemoizedUserVibes = React.memo(UserVibes);
const MemoizedUserMedia = React.memo(UserMedia);
const MemoizedUserProfessional = React.memo(UserProfessional);
const MemoizedUserActivities = React.memo(UserActivities);
const MemoizedUserFollowing = React.memo(UserFollowing);
const MemoizedUserFollowers = React.memo(UserFollowers);

function Profile({ local }) {
  const navigate = useNavigate();
  let { username, path } = useParams();
  const [userInfo, setUserInfo] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [conversationId, setConversationId] = useState("");
  const [followingStatus, setFollowingStatus] = useState(false);

  const profile_paths = [
    {
      id: 1,
      title: "Vibes",
      path: "/profile/" + username + "/vibes",
    },
    {
      id: 2,
      title: "Media",
      path: "/profile/" + username + "/media",
    },
    {
      id: 3,
      title: "Professional",
      path: "/profile/" + username + "/professional",
    },
    // {
    //   id: 4,
    //   title: "Skills",
    //   path: "/Profile/" + username + "/activities",
    // },
  ];

  const location = useLocation();
  let currentPath = location.pathname;
  let component = (
    <MemoizedUserVibes local={local} username={username} userInfo={userInfo} />
  );

  if (!path) {
    currentPath = "/Profile/" + username + "/vibes";
  }

  if (path === "vibes") {
    component = (
      <MemoizedUserVibes
        local={local}
        username={username}
        userInfo={userInfo}
      />
    );
  }

  if (path === "media") {
    component = (
      <MemoizedUserMedia
        local={local}
        username={username}
        userInfo={userInfo}
      />
    );
  }

  if (path === "professional") {
    component = (
      <MemoizedUserProfessional
        local={local}
        username={username}
        userInfo={userInfo}
      />
    );
  }

  if (path === "following") {
    component = (
      <MemoizedUserFollowing
        local={local}
        username={username}
        userInfo={userInfo}
      />
    );
  }

  if (path === "followers") {
    component = (
      <MemoizedUserFollowers
        data={username}
        local={local}
        username={username}
        userInfo={userInfo}
      />
    );
  }

  useEffect(() => {
    const fetchDetails = async () => {
      if (username) {
        const res = await getUserInfoByUsername(username);
        if (res.user_info.length > 0) {
          setUserInfo(res);
          setFollowingStatus(res.user_info[0].follow_status);
          setConversationId(res.conversation_id);
          setLoading(false);
          // alert(res.user_info[0].country);
        } else {
          setError(true);
        }
        console.log("User details", res.user_info.length);
      }
    };
    fetchDetails();
  }, [local, currentPath, username]);

  const handleFollow = async (activity_id) => {
    followingStatus ? setFollowingStatus(false) : setFollowingStatus(true);
    // alert(username);
    try {
      const data = await submitFollowUser(activity_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const menu = (
    <>
      <div className="p-0 sticky_profile_header flex align-center w-full">
        {/* profile_tabs */}
        {path === "following" || path === "followers" ? (
          <div
            className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
            </svg>
          </div>
        ) : (
          ""
        )}
        <div className="px-4 flex align-center headers w-full profile_header justify-center">
          {profile_paths.map((item, index) => (
            <Link
              key={item.id}
              to={item.path}
              className={currentPath === item.path ? "active_tab" : ""}
            >
              <div className="h-10 px-4 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
                {item.title}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );

  const top_menu = (
    <>
      <div className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center bg-gray-100">
        {!loading && (
          <img
            src={
              Array.isArray(userInfo.user_info) &&
              userInfo.user_info[0].profile_image
            }
            className="profile_image"
          />
        )}
      </div>
      <div className="h-10 w-full mr-3 rounded-full align-center flex flex-row">
        <div className="">
          <div className="font-bold text-normal">
            {Array.isArray(userInfo.user_info) &&
              userInfo.user_info[0].fullname}
          </div>
          <div className="relative -top-1 text-sm">
            {Array.isArray(userInfo.user_info) &&
              userInfo.user_info[0].username}
          </div>
        </div>
      </div>

      {Array.isArray(userInfo.user_info) &&
        app.current_user != userInfo?.user_info[0]?.activity_id && (
          <div
            className={`h-10 w-40 rounded-full font-normal align-center justify-center text-center flex follow_btn ${
              followingStatus ? "following" : "follow"
            }`}
            onClick={() => handleFollow(userInfo?.user_info[0]?.activity_id)}
            // style={{ width: 90 }}
          >
            {followingStatus ? "Following" : "Follow"}
          </div>
        )}
    </>
  );

  return (
    <>
      <div className="min-h-screen flex-1">
        {/* <GeneralMidHeader/> */}
        <div className="px-4 flex align-center w-full headers p-0">
          <div
            className="feed_list_cont_ flex px-4 align-center w-full m-0 h-full"
            style={{ width: "100%" }}
          >
            {path === "following" || path === "followers" ? menu : top_menu}
          </div>
        </div>
        {Array.isArray(userInfo.user_info) && userInfo.user_info ? (
          <div
            className="feed_list_cont_ px-4 w-full pb-4"
            style={{ width: "100%" }}
          >
            <div className="font-bold text-xl pt-5">About</div>
            <div className="bio_info">
              <PostContent
                text={userInfo.user_info[0].bio}
                contentStyle={{}}
                maxLength={200}
                allowSpacing={false}
                customStyle={{}}
              />
            </div>
            <div className="flex align-center w-full mt-4 flex-wrap gap-1">
              {userInfo.user_info[0].country !== "" ? (
                <div className="flex align-center rounded-full">
                  <div className="h-8 w-8 bg-gray-100 hover rounded-full  align-center flex justify-center">
                    <span className="material-symbols-outlined">
                      location_on
                    </span>
                  </div>
                  <div className="h-8 px-2 hover ml-0 rounded-full  align-center text-center flex justify-center">
                    {userInfo.user_info[0].country}
                  </div>
                </div>
              ) : (
                ""
              )}

              {userInfo.user_info[0].current_job_title !== "" ? (
                <div className="flex align-center rounded-full">
                  <div className="h-8 w-8 bg-gray-100 hover rounded-full  align-center flex justify-center">
                    <span className="material-symbols-outlined">work</span>
                  </div>
                  <div className="h-8 px-4 pl-1 min-w-20 hover ml-0 rounded-full  align-center text-center flex justify-center">
                    {userInfo.user_info[0].current_job_title}
                  </div>
                </div>
              ) : (
                ""
              )}
              {userInfo.user_info[0].website !== "" ? (
                <div className="flex align-center rounded-full">
                  <div className="h-8 w-8 bg-gray-100 hover rounded-full  align-center flex justify-center">
                    <span className="material-symbols-outlined">link</span>
                  </div>
                  <div className="h-8 px-4 pl-1 min-w-20 hover ml-0 rounded-full  align-center text-center flex justify-center">
                    {userInfo.user_info[0].website}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="flex align-center rounded-full">
                <div className="h-8 w-8 bg-gray-100 hover rounded-full  align-center flex justify-center">
                  <span className="material-symbols-outlined">event</span>
                </div>
                <div className="h-8 px-4 pl-1 min-w-20 hover ml-0 rounded-full  align-center text-center flex justify-center">
                  Joined {userInfo.user_info[0].date_time}
                </div>
              </div>
            </div>

            <div className="flex align-center w-full mt-2 gap-2">
              <Link
                to={`/Profile/${userInfo.user_info[0].username}/following`}
                className="flex align-center rounded-full"
              >
                <div className="h-8 w-8 bg-gray-100 hover rounded-full  align-center flex justify-center">
                  <span className="material-symbols-outlined">group</span>
                </div>
                <div className="h-8 px-4 pl-1 min-w-20 hover ml-0 rounded-full  align-center text-center flex justify-center">
                  Following {userInfo.user_info[0].following}
                </div>
              </Link>
              <Link
                to={`/Profile/${userInfo.user_info[0].username}/followers`}
                className="flex align-center rounded-full"
              >
                <div className="h-8 w-8 bg-gray-100 hover rounded-full  align-center flex justify-center">
                  <span className="material-symbols-outlined">group</span>
                </div>
                <div className="h-8 px-4 pl-1 min-w-20 hover ml-0 rounded-full  align-center text-center flex justify-center">
                  Follower {userInfo.user_info[0].followers}
                </div>
              </Link>
            </div>

            {/* {userInfo.user_info[0].activity_id != app.current_user && (
              <div className="flex">
                <Link
                  to={`/messages/${conversationId}/messages`}
                  className="flex align-center rounded-full mt-3 bg-gray-100 p-1 hover"
                >
                  <div className="h-8 w-8 rounded-full align-center flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div className="h-8 px-4 pl-1 min-w-20 ml-1 rounded-full  align-center text-center flex justify-center">
                    Message
                  </div>
                </Link>
              </div>
            )} */}
          </div>
        ) : (
          ""
        )}

        <div className="">
          {path === "following" || path === "followers" ? "" : menu}
          {!error ? (
            component
          ) : (
            <div className="text-center py-10 px-4 w-full flex align-center justify-center flex-column gap-10">
              <span
                className="material-symbols-outlined text-orange-400 text-3xl"
                style={{ fontSize: 52 }}
              >
                report
              </span>
              <h2>This user does not exist</h2>
            </div>
          )}
        </div>
      </div>

      <div className="min-h-screen flex right_cont">
        <MemoizedTopUserMenu local={local} username={username} />
        <div className="p-4">
          {!error && <MemoizedProfileCard local={local} userInfo={userInfo} />}
          <hr className="line" />
          <FollowListReused />
          <hr className="line" />
          <div className="mt-7 mb-16">
            <CopyRight />
          </div>
        </div>
      </div>
    </>
  );
}

// Optionally memoize the Profile component itself
export default React.memo(Profile);
