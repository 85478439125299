import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Profile from "./Settings/Profile";
import Username from "./Settings/Username";
import ContactInfo from "./Settings/ContactInfo";
import HubAccess from "./Settings/HubAccess";
import ProfileImage from "./Settings/ProfileImage";
import { get_menus } from "./menus_config";
import CategoryAndType from "./Settings/CategoryAndType";
import Rules from "./Settings/Rules";

function Settings() {
  const navigate = useNavigate();
  let { id, page, focus } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;

  const paths = get_menus(id, "settings_menu");

  if (focus) {
    if (focus == "profile") {
      component = <Profile />;
    } else if (focus == "username") {
      component = <Username />;
    } else if (focus == "contactinfo") {
      component = <ContactInfo />;
    } else if (focus == "hubaccess") {
      component = <HubAccess />;
    } else if (focus == "profileimage") {
      component = <ProfileImage />;
    } else if (focus == "categoryandtype") {
      component = <CategoryAndType />;
    } else if (focus == "rules") {
      component = <Rules />;
    } else {
      component = <Profile />;
    }
  } else {
    component = <Profile />;
  }

  return (
    <div>
      <div className="flex align-start">
        <div className="left_menu_settings min-w-80 has_border_right px-3 pl-0">
          <div className="px-4 py-3 text-lg font-semibold">
            Community (Hub) settings
          </div>
          {paths.map((item, index) => (
            <Link key={item.id} to={item.path} className="py-0.5 block">
              <div
                className={
                  currentPath == item.path
                    ? "flex align-center hover p-2 px-3 rounded-md sidebar_item active"
                    : "flex align-center hover p-2 px-3 rounded-md sidebar_item"
                }
              >
                <div className="flex align-center w-10 h-10">
                  <span
                    className={
                      currentPath == item.path
                        ? "material-icons-round"
                        : "material-symbols-outlined"
                    }
                  >
                    {item.icon}
                  </span>
                </div>
                <div className="flex align-center flex-1">{item.title}</div>
                {item.has_sub ? (
                  <div className="flex align-center">
                    <span class="material-icons-round">chevron_right</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Link>
          ))}
        </div>
        <div className="right_settings w-full px-4">{component}</div>
      </div>
    </div>
  );
}

export default Settings;
