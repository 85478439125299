import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  ClockIcon,
  ListBulletIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  acceptJobApplication,
  getBusinessJobApplications,
  rejectJobApplication,
} from "../../../db";
import TableDummy from "../../components/Dummies/TableDummy";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const MySwal = withReactContent(Swal);

function Applications({ local, business }) {
  const navigate = useNavigate();
  const [businessProfile, setBusinessProfile] = useState();
  let { id, page, focus, focus_id } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (business) {
      setBusinessProfile(business);
    }
  }, [page, id, local]);

  const [loading, setLoading] = useState(true);
  const [listTitle, setListTitle] = useState("All applications");
  const [jobApplications, setJobApplications] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  let list_type = "all";
  if (focus) {
    list_type = focus;
  }
  useEffect(() => {
    fetchData();
    switch (list_type) {
      case "accepted":
        setListTitle("Accepted applications");
        break;
      case "pending":
        setListTitle("Pending applications");
        break;
      case "rejected":
        setListTitle("Rejected applications");
        break;
      default:
        setListTitle("All job applications");
    }

    // Set job title if focus is present and we have filtered applications
    if (focus_id && filteredApplications.length > 0) {
      const firstApplication = filteredApplications[0];
      setJobTitle(firstApplication.job_details[0].row.job_name);
    } else {
      setJobTitle("");
    }

    // Extract title from query parameters (e.g., ?title=job_name)
    const searchParams = new URLSearchParams(location.search);
    const urlTitle = searchParams.get("title");
    if (urlTitle) {
      setJobTitle(urlTitle);
    }
    // alert(focus_id);
  }, [page, id, local, focus, focus_id]); // Include focus in dependencies

  const fetchData = async () => {
    try {
      const list = await getBusinessJobApplications(id);
      console.log("Application list - > ", list.applications);
      // console.log("Applications - > ", list.applications[0]);
      setJobApplications(list.applications);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const acceptApplication = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Please confirm to accept this application",
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm",
    });

    if (result.isConfirmed) {
      try {
        const response = await acceptJobApplication({
          business: id,
          activity_id: activity_id,
        });

        console.log(response);
        fetchData();
        MySwal.fire(
          "Accepted!",
          "Details saved and applicant notified",
          "success"
        );
      } catch (error) {
        console.error("Failed to archive the job:", error);
        MySwal.fire("Error!", "There was an error archiving the job.", "error");
      }
    }
  };

  const rejectApplication = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Please confirm to reject this application",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm",
    });

    if (result.isConfirmed) {
      try {
        const response = await rejectJobApplication({
          business: id,
          activity_id: activity_id,
        });

        console.log(response);
        fetchData();
        MySwal.fire(
          "Archived!",
          "Details saved and applicant notified",
          "success"
        );
      } catch (error) {
        console.error("Failed:", error);
        MySwal.fire("Error!", "There was an error archiving the job.", "error");
      }
    }
  };

  const filteredApplications = jobApplications?.filter((application) => {
    const applicantName = application.user_profile[0].fullname.toLowerCase();
    const jobName = application.job_details[0].row.job_name.toLowerCase();
    const matchesSearchTerm =
      applicantName.includes(searchTerm.toLowerCase()) ||
      jobName.includes(searchTerm.toLowerCase());

    // If focus_id exists, filter by job activity_id
    if (focus_id) {
      return (
        application.job_details[0].row.activity_id === focus_id &&
        matchesSearchTerm
      );
    }

    // Otherwise, filter by list type (all, accepted, pending, etc.)
    if (list_type === "all") {
      return matchesSearchTerm;
    }

    return matchesSearchTerm && application.row.status === list_type;
  });

  // Update counts, taking focus_id into account
  const allCount =
    jobApplications?.filter(
      (application) =>
        !focus_id || application.job_details[0].row.activity_id === focus_id
    )?.length || 0;

  const acceptedCount =
    jobApplications?.filter(
      (application) =>
        application.row.status === "accepted" &&
        (!focus_id || application.job_details[0].row.activity_id === focus_id)
    )?.length || 0;

  const pendingCount =
    jobApplications?.filter(
      (application) =>
        (application.row.status === "pending" ||
          application.row.status === "false") &&
        (!focus_id || application.job_details[0].row.activity_id === focus_id)
    )?.length || 0;

  const rejectedCount =
    jobApplications?.filter(
      (application) =>
        application.row.status === "rejected" &&
        (!focus_id || application.job_details[0].row.activity_id === focus_id)
    )?.length || 0;

  return (
    <div>
      <div className="flex align-center justify-between gap-3 py-3 pt-0 sm-block">
        <div className="text-xl font-semibold py-3">
          {focus_id ? (
            <span>
              <span className="">Applications </span>
            </span>
          ) : (
            "Job applications"
          )}
        </div>

        <div className="flex align-center gap-2 overflow-auto no-wrap">
          <Link
            to={`/admin/${id}/applications/all${
              focus_id ? "/" + focus_id : ""
            }`}
            className={
              list_type === "all"
                ? "flex active align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-none bg-gray-100"
                : "flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
            }
          >
            <div>All applications </div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              {allCount}
            </div>
          </Link>
          <Link
            to={`/admin/${id}/applications/accepted${
              focus_id ? "/" + focus_id : ""
            }`}
            className={
              list_type === "accepted"
                ? "flex active align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-none bg-gray-100"
                : "flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
            }
          >
            <div>Accepted</div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              {acceptedCount}
            </div>
          </Link>
          <Link
            to={`/admin/${id}/applications/pending${
              focus_id ? "/" + focus_id : ""
            }`}
            className={
              list_type === "pending"
                ? "flex active align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-none bg-gray-100"
                : "flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
            }
          >
            <div>Pending</div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              {pendingCount}
            </div>
          </Link>
          <Link
            to={`/admin/${id}/applications/rejected${
              focus_id ? "/" + focus_id : ""
            }`}
            className={
              list_type === "rejected"
                ? "flex active align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-none bg-gray-100"
                : "flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1"
            }
          >
            <div>Rejected</div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              {rejectedCount}
            </div>
          </Link>
        </div>
      </div>
      {jobTitle != "" && (
        <div className="-mt-3">
          <div className="text-mute text-xl font-thin">{jobTitle}</div>
        </div>
      )}

      <div className="flex align-center flex-wrap gap-5 border-1 rounded-full px-4 pr-0 my-3">
        <div className="">{listTitle}</div>
        <div className="search_table flex-1 w-full relative bg-gray-100 rounded-full">
          <span className="material-icons-round absolute top-3 left-4 m-auto h-auto">
            search
          </span>
          <input
            placeholder="Search application"
            className="has_icon form-control search_input w-full rounded-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="w-full">
        <div className="overflow-hidden w-full bg-gray-100 rounded-xl py-5 pt-2">
          <div className="applicants">
            <div className="table-content">
              <div className="wrap-applicants table-responsive">
                {filteredApplications?.length === 0 && !loading && (
                  <div className="mt-2 mb-2 px-4">
                    <div className="flex align-center">
                      <img
                        src="/images/beam-woman-meditating-in-lotus-position.gif"
                        className="w-36 max-w-36 min-w-36 h-auto sm_image"
                        with="50"
                      />
                      <div className="px-3">
                        <div className="font-bold text-lg">No applications</div>
                        <div>
                          When someone apply for a job it will be shown here.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {loading && <TableDummy />}

                <table className="table-auto datatable table_bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Applicant</th>
                      {!focus && <th>Job</th>}
                      <th>Application date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredApplications?.length > 0 &&
                      filteredApplications.map((item, index) => (
                        <tr className="v_top" key={index}>
                          <td>
                            <div className="mt-2">{index + 1}</div>
                          </td>
                          <td style={{ verticalAlign: "top" }}>
                            <div className="flex align-start gap-3">
                              <div className="flex mt-2 align-center justify-center w-10 h-10 rounded-full ">
                                <img
                                  src={item.user_profile[0].profile_image}
                                  className="w-10 h-10 rounded-full object-full"
                                />
                              </div>
                              <Link
                                to={`/admin/${id}/jobapplication/${item.row.activity_id}`}
                                className="w-full flex-1"
                              >
                                <div className="text-xl font-semibold">
                                  {item.user_profile[0].fullname}
                                </div>
                                <div className="text-sm">
                                  @{item.user_profile[0].username}
                                </div>

                                <div className="text-sm mt-1 mb-1">
                                  Qualifies -{" "}
                                  {
                                    item?.job_details[0]?.row
                                      ?.user_qualification
                                  }
                                  %
                                </div>

                                <div
                                  className="progress relative overflow-hidden"
                                  style={{ height: 5, position: "relative" }}
                                >
                                  <div
                                    className="progress_inn animate__animated animate__slideInLeft"
                                    style={{
                                      width:
                                        item?.job_details[0]?.row
                                          ?.user_qualification + "%",
                                      height: 5,
                                    }}
                                  ></div>
                                </div>

                                {item.user_profile[0].skills.length > 0 && (
                                  <div className="mt-1">
                                    <div className="flex flex-wrap gap-2 -ml-1 mt-2">
                                      {item.user_profile[0].skills.map(
                                        (skill, index) => (
                                          <>
                                            {skill != "" && (
                                              <div
                                                key={index}
                                                className="bg-gray-100 rounded-full text-sm px-2"
                                              >
                                                {skill}
                                              </div>
                                            )}
                                          </>
                                        )
                                      )}
                                      {item.user_profile[0].skills.length >
                                        5 && (
                                        <div className="bg-gray-100 rounded-full text-sm px-2 flex align-center justify-center gap-2">
                                          <PlusIcon />
                                          {item.user_profile[0].skills.length -
                                            5}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Link>
                              <a
                                target="_blank"
                                href={`/profile/${item.user_profile[0].username}`}
                                className="w-8 h-8 min-w-8 min-h-8 rounded-full flex align-center justify-center hover -mt-1"
                              >
                                <ArrowTopRightOnSquareIcon />
                              </a>
                            </div>
                          </td>

                          {!focus && (
                            <td>
                              <Link
                                className="hover_decoration_link"
                                to={`/admin/${id}/applications/all/${item.job_details[0].row.activity_id}`}
                              >
                                {item.job_details[0].row.job_name}
                              </Link>
                            </td>
                          )}

                          <td>{item.row.date_time}</td>
                          <td className="text-capitalized">
                            {item.row.status == "false"
                              ? "Pending"
                              : item.row.status}
                          </td>
                          <td>
                            <Menu>
                              <MenuButton className="h-10 w-10 bg-gray-100 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-3 post_attachment start_audio_recording">
                                <span className="material-symbols-outlined">
                                  more_horiz
                                </span>
                              </MenuButton>

                              <MenuItems
                                transition
                                anchor="bottom end"
                                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                              >
                                <MenuItem>
                                  <Link
                                    to={`/admin/${id}/jobapplication/${item.row.activity_id}`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    View application
                                  </Link>
                                </MenuItem>

                                <MenuItem>
                                  <Link
                                    to={`/admin/${id}/candidateprofile/${item.user_profile[0].username}/about`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    View applicant profile
                                  </Link>
                                </MenuItem>

                                <MenuItem>
                                  <Link
                                    to={`/messages/${item.user_profile[0].conversation_id}`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    Message
                                  </Link>
                                </MenuItem>

                                <MenuItem>
                                  <Link
                                    to={`/admin/${id}/jobdetails/${item.job_details[0].row.activity_id}`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    About job
                                  </Link>
                                </MenuItem>
                                <MenuItem>
                                  <a
                                    target="_blank"
                                    href={`/jobs/${item.job_details[0].row.activity_id}`}
                                    className="group cursor-pointer flex w-full items-center justify-between gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    <div className="">
                                      Job details (Open in search)
                                    </div>
                                    <ArrowTopRightOnSquareIcon />
                                  </a>
                                </MenuItem>

                                <hr className="line_sm" />
                                {(item.row.status == "false" ||
                                  item.row.status == "rejected") && (
                                  <MenuItem>
                                    <div
                                      onClick={() =>
                                        acceptApplication(item.row.activity_id)
                                      }
                                      className="group text-green-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                    >
                                      Accept application
                                    </div>
                                  </MenuItem>
                                )}

                                {item.row.status == "accepted" && (
                                  <MenuItem>
                                    <div
                                      onClick={() =>
                                        rejectApplication(item.row.activity_id)
                                      }
                                      className="group text-red-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                    >
                                      Reject application
                                    </div>
                                  </MenuItem>
                                )}
                              </MenuItems>
                            </Menu>
                          </td>
                        </tr>
                      ))}
                    {/* */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Applications;
