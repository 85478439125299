export const get_menus = (id, menu_type = "", search_term = "") => {
  let item_list = [];

  const general_page = [
    {
      id: 0,
      title: "Dashboard",
      path: "/admin/" + id + "/dashboard",
      search_visibale: true,
      search_icon: "grid_view",
      icon: "grid_view",
      has_sub: false,
    },
    {
      id: 1,
      title: "Jobs report",
      path: "/admin/" + id + "/jobreports",
      search_visibale: true,
      search_icon: "bar_chart",
      icon: "bar_chart",
      has_sub: false,
    },
    {
      id: 2,
      title: "Job posted",
      path: "/admin/" + id + "/postedjobs",
      search_visibale: true,
      search_icon: "work_history",
      icon: "work_history",
      has_sub: true,
    },
    {
      id: 3,
      title: "Post a job",
      path: "/admin/" + id + "/createjob",
      search_visibale: true,
      search_icon: "add",
      icon: "add",
      has_sub: false,
    },
    {
      id: 4,
      title: "Applications",
      path: "/admin/" + id + "/applications",
      search_visibale: true,
      search_icon: "send_and_archive",
      icon: "send_and_archive",
      has_sub: false,
    },
    {
      id: 5,
      title: "Followers",
      path: "/admin/" + id + "/followers",
      search_visibale: true,
      search_icon: "interpreter_mode",
      icon: "interpreter_mode",
      has_sub: false,
    },
    {
      id: 6,
      title: "Settings",
      path: "/admin/" + id + "/settings",
      search_visibale: true,
      search_icon: "settings",
      icon: "settings",
      has_sub: true,
    },
  ];

  const page_settings = [
    {
      id: -1,
      title: "Profile & Cover",
      path: "/admin/" + id + "/settings/profileimage",
      search_visibale: true,
      search_icon: "photo_camera",
      icon: "photo_camera",
      has_sub: false,
    },
    {
      id: 0,
      title: "General profile",
      path: "/admin/" + id + "/settings/profile",
      search_visibale: true,
      search_icon: "store",
      icon: "store",
      has_sub: false,
    },
    {
      id: 1,
      title: "Manage username",
      path: "/admin/" + id + "/settings/username",
      search_visibale: true,
      search_icon: "alternate_email",
      icon: "alternate_email",
      has_sub: false,
    },
    {
      id: 2,
      title: "Contact info",
      path: "/admin/" + id + "/settings/contactinfo",
      search_visibale: true,
      search_icon: "location_on",
      icon: "location_on",
      has_sub: false,
    },
    // {
    //   id: 3,
    //   title: "Job settings",
    //   path: "/admin/" + id + "/settings/jobsettings",
    //   search_visibale: true,
    //   search_icon: "settings_input_component",
    //   icon: "settings_input_component",
    //   has_sub: false,
    // },
    // {
    //   id: 4,
    //   title: "Page access",
    //   path: "/admin/" + id + "/settings/pageaccess",
    //   search_visibale: true,
    //   search_icon: "admin_panel_settings",
    //   icon: "admin_panel_settings",
    //   has_sub: false,
    // },
    // {
    //   id: 5,
    //   title: "Service area",
    //   path: "/admin/" + id + "/settings/servicearea",
    //   search_visibale: true,
    //   search_icon: "map",
    //   icon: "map",
    //   has_sub: false,
    // },
    {
      id: 6,
      title: "Categories & Type",
      path: "/admin/" + id + "/settings/categoryandtype",
      search_visibale: true,
      search_icon: "restaurant",
      icon: "restaurant",
      has_sub: false,
    },
    {
      id: 7,
      title: "Page verification",
      path: "/admin/" + id + "/settings/verification",
      search_visibale: true,
      search_icon: "verified",
      icon: "verified",
      has_sub: false,
    },
  ];

  if (menu_type === "general_page") {
    item_list = item_list.concat(general_page);
  }

  if (menu_type === "page_settings") {
    item_list = item_list.concat(page_settings);
  }

  if (search_term) {
    item_list = item_list.concat(general_page);
    item_list = item_list.concat(page_settings);
    item_list = item_list.filter((item) =>
      item.title.toLowerCase().includes(search_term.toLowerCase())
    );
  }

  return item_list;
};
