import React, { useState, useEffect } from "react";
import app from "../../config/app";
import {
  saveInterestsToIndexedDB,
  getInterestsFromIndexedDB,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function Interests({ local }) {
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [myInterests, setMyInterests] = useState([]);
  const [filter, setFilter] = useState("");
  const interests = app.interests; // Object containing categories and their interests

  useEffect(() => {
    const fetchInterests = async () => {
      const savedInterests = await getInterestsFromIndexedDB();
      setMyInterests(savedInterests || []); // Initialize with stored interests or empty array
    };
    fetchInterests();
  }, [local]);

  const toggleInterest = async (interest) => {
    let updatedInterests;
    if (myInterests.includes(interest)) {
      updatedInterests = myInterests.filter((item) => item !== interest);
    } else {
      updatedInterests = [...myInterests, interest];
    }
    setMyInterests(updatedInterests);
    updateLocalStorage({ type: "updateIntesrests", data: updatedInterests });
    const responce = await saveInterestsToIndexedDB(updatedInterests);
    console.log(responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  const filteredInterests = (category, tags) => {
    return tags.filter((tag) =>
      tag.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const filteredCategories = () => {
    return Object.entries(interests).filter(([category, tags]) => {
      const categoryMatch = category
        .replace(/_/g, " ")
        .replace(/tags$/i, "")
        .toLowerCase()
        .includes(filter.toLowerCase());
      const tagsMatch = tags.some((tag) =>
        tag.toLowerCase().includes(filter.toLowerCase())
      );
      return categoryMatch || tagsMatch;
    });
  };

  return (
    <div className="p-7">
      <div className="text-xl font-semibold mb-4">Interest settings</div>
      <div className="sticky tags_filter py-2">
        <input
          type="text"
          placeholder="Filter interests..."
          className="mb-4 p-2 border border-gray-300 rounded-xl w-full px-6"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      {filteredCategories().map(([category, tags], index) => (
        <div key={index} className="mb-6">
          <div className="text-lg font-bold mb-2 px-2">
            {category.replace(/_/g, " ").replace(/tags$/i, "").toUpperCase()}
          </div>
          <div className="flex flex-wrap">
            {filteredInterests(category, tags).map((interest, idx) => (
              <div
                key={idx}
                className={`w-auto h-8 px-4 m-1 rounded-full flex items-center justify-center cursor-pointer ${
                  myInterests.includes(interest)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100"
                }`}
                onClick={() => toggleInterest(interest)}
              >
                {interest}
              </div>
            ))}
          </div>
        </div>
      ))}
      {showResponceCard && responceCard}
    </div>
  );
}

export default Interests;
