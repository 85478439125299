import React from "react";
import PostContent from "../../../components/PostContent";
import { Link } from "react-router-dom";

function HubAbout({ data, user }) {
  console.log("Creator", user);
  return (
    <div className="px-2 mb-10 feed_list_cont">
      <div className="bg-gray-100 rounded-xl mt-3 py-4 px-4 flex flex-col gap-3">
        {data?.about != "" && (
          <>
            <div className="flex align-start gap-3">
              <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
              <div className="">
                <div className="text-sm text-mute">Info</div>
                <div className="">
                  <PostContent
                    text={data?.about}
                    maxLength={100}
                    customStyle={{ padding: 0, marginBottom: 0 }}
                  />
                </div>
              </div>
            </div>
            <hr className="line" style={{ marginTop: 0, marginBottom: 0 }} />
          </>
        )}
        <Link
          to={`/profile/${user?.username}/vibes`}
          className="flex align-center gap-3 hover rounded-xl px-2 py-2 -ml-2"
        >
          <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center">
            <img
              src={user?.profile_image}
              className="min-w-10 w-10 h-10 rounded-full"
            />
          </div>
          <div className="">
            <div className="text-sm text-mute">Created by</div>
            <div className="">{user?.fullname}</div>
          </div>
        </Link>

        <div className="flex align-center gap-3">
          <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
          <div className="">
            <div className="text-sm text-mute">Date created</div>
            <div className="">{data?.date_time}</div>
          </div>
        </div>

        <hr className="line" style={{ marginTop: 0, marginBottom: 0 }} />

        {data?.phone != "" && (
          <div className="flex align-center gap-3">
            <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
            <div className="">
              <div className="text-sm text-mute">Phone</div>
              <div className="">{data?.phone}</div>
            </div>
          </div>
        )}

        {data?.email != "" && (
          <div className="flex align-center gap-3">
            <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
            <div className="">
              <div className="text-sm text-mute">Email address</div>
              <div className="">{data?.email}</div>
            </div>
          </div>
        )}

        {data?.website != "" && (
          <div className="flex align-center gap-3">
            <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
            <div className="">
              <div className="text-sm text-mute">Website</div>
              <div className="">{data?.website}</div>
            </div>
          </div>
        )}

        {data?.rules.length > 0 && (
          <>
            <hr className="line" style={{ marginTop: 0, marginBottom: 0 }} />
            <div className="flex align-start gap-3">
              <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
              <div className="">
                <div className="text-sm text-mute">Rules</div>
                {data?.rules.map((item, index) => (
                  <div className="">{item}</div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default HubAbout;
