import {
  ArchiveBoxIcon,
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  ClockIcon,
  PlusIcon,
} from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  archiveBusinessJob,
  deleteBusinessJob,
  getJobsFromBusiness,
} from "../../../db";
import LoaderBar from "../../components/LoaderBar";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
// alerts
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import app from "../../../config/app";
import TableDummy from "../../components/Dummies/TableDummy";

const MySwal = withReactContent(Swal);

function PostedJobs({ local, business }) {
  const navigate = useNavigate();
  const [jobsList, setJobList] = useState([]);
  const [loadStatus, setLoadStatus] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [listTitle, setListTitle] = useState("All jobs");

  const [allJobsCount, setAllJobsCount] = useState(0);
  const [activeJobsCount, setActiveJobsCount] = useState(0);
  const [archiveJobsCount, setArchiveJobsCount] = useState(0);

  let { id, page, focus } = useParams();
  const location = useLocation();
  let list_type = "all";

  if (focus) {
    list_type = focus;
  }

  useEffect(() => {
    const jobs = async () => {
      const response = await getJobsFromBusiness(id);
      console.log("Jobs", response.job_list);
      setJobList(response.job_list);
      setAllJobsCount(response.job_list.length);

      const activeJobs = response.job_list.filter(
        (job) => job.row.applications_info?.application_status === "active"
      ).length;

      setActiveJobsCount(activeJobs);

      const archivedJobs = response.job_list.filter(
        (job) => job.row.applications_info?.application_status === "archived"
      ).length;
      setArchiveJobsCount(archivedJobs);

      setLoadStatus(false);
    };

    switch (list_type) {
      case "archived":
        setListTitle("Archived jobs");
        break;
      case "active":
        setListTitle("Active jobs (People can apply for these jobs)");
        break;
      default:
        setListTitle("All jobs posted");
    }

    jobs();
  }, [page, id, local, focus]);

  // Filter jobs based on search term and status
  const filteredJobs = jobsList.filter((job) => {
    const matchesSearchTerm = job.row.job_name
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesStatus =
      list_type === "all" ||
      job.row.applications_info?.application_status === list_type;
    return matchesSearchTerm && matchesStatus;
  });

  const archiveJob = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to archive this job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await archiveBusinessJob({
          business: id,
          activity_id: activity_id,
        });

        console.log(response);

        MySwal.fire("Archived!", "Your job has been archived.", "success");

        // Optionally update the job list after archiving
        const updatedJobsList = jobsList.map((job) =>
          job.row.activity_id === activity_id
            ? {
                ...job,
                applications_info: {
                  ...job.row.applications_info,
                  application_status: "archived",
                },
              }
            : job
        );

        setJobList(updatedJobsList);
        // Update active and archived counts
        setActiveJobsCount((prevCount) => prevCount - 1);
        setArchiveJobsCount((prevCount) => prevCount + 1);
      } catch (error) {
        console.error("Failed to archive the job:", error);
        MySwal.fire("Error!", "There was an error archiving the job.", "error");
      }
    }
  };

  const deleteJob = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deleteBusinessJob({
          business: id,
          activity_id: activity_id,
        });

        console.log(response);

        MySwal.fire("Deleted!", "Your job has been deleted.", "success");

        // Optionally update the job list after deletion
        setJobList(
          jobsList.filter((job) => job.row.activity_id !== activity_id)
        );
        setAllJobsCount((prevCount) => prevCount - 1);

        // Update active or archived counts if necessary
        const jobToDelete = jobsList.find(
          (job) => job.row.activity_id === activity_id
        );
        if (
          jobToDelete.row.applications_info?.application_status === "active"
        ) {
          setActiveJobsCount((prevCount) => prevCount - 1);
        } else if (
          jobToDelete.row.applications_info?.application_status === "archived"
        ) {
          setArchiveJobsCount((prevCount) => prevCount - 1);
        }
      } catch (error) {
        console.error("Failed to delete the job:", error);
        MySwal.fire("Error!", "There was an error deleting the job.", "error");
      }
    }
  };

  return (
    <div>
      {loadStatus ? <LoaderBar /> : ""}
      <div className="flex align-center justify-between gap-3 py-3 pt-0 sm-block">
        <div className="text-xl font-semibold py-3">Created jobs</div>
        <div className="flex align-center gap-2 overflow-auto">
          <Link
            to={`/admin/${id}/postedjobs`}
            className={
              list_type == "all"
                ? "flex active align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-none bg-gray-100"
                : "flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1 no-wrap"
            }
          >
            <div>All</div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              {allJobsCount}
            </div>
          </Link>
          <Link
            to={`/admin/${id}/postedjobs/active`}
            className={
              list_type == "active"
                ? "flex active align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-none bg-gray-100"
                : "flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1 no-wrap"
            }
          >
            <div>Active</div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              {activeJobsCount}
            </div>
          </Link>
          <Link
            to={`/admin/${id}/postedjobs/archived`}
            className={
              list_type == "archived"
                ? "flex active align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-none bg-gray-100"
                : "flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1 no-wrap"
            }
          >
            <div>Archived</div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              {archiveJobsCount}
            </div>
          </Link>

          <Link
            to={`/admin/${id}/createjob`}
            className="flex align-center justify-center rounded-full gap-2 hover px-3 pr-0 border-1 no-wrap"
          >
            <div>Post new job</div>
            <div className="min-w-8 h-8 bg-gray-100 rounded-full flex align-center justify-center">
              <PlusIcon width={15} height={15} />
            </div>
          </Link>
        </div>
      </div>

      {/* search table data */}
      <div className="flex align-center flex-wrap gap-5 border-1 rounded-full px-4 pr-0">
        <div className="">{listTitle}</div>
        <div className="search_table flex-1 w-full relative bg-gray-100 rounded-full">
          <span className="material-icons-round absolute top-3 left-4 m-auto h-auto">
            search
          </span>
          <input
            placeholder="Search jobs"
            className="has_icon form-control search_input w-full rounded-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="w-full overflow-hidden mt-4 rounded-xl">
        <div className="overflow-auto w-full rounded-xl bg-gray-100 py-5 pt-2">
          <div className="applicants">
            <div className="table-content">
              <div className="wrap-applicants table-responsive">
                {jobsList.length == 0 && !loadStatus && (
                  <div className="mt-2 mb-2">
                    <div className="flex align-center px-4">
                      <img
                        src="/images/beam-sitting-young-woman.png"
                        className="w-36 max-w-36 min-w-36 h-auto sm_image"
                        width="50"
                        alt="No jobs"
                      />
                      <div className="px-3">
                        <div className="font-bold text-lg">
                          No jobs created yet!!
                        </div>
                        <div>
                          All jobs you create and post will appear here.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {loadStatus && <TableDummy />}

                <table className="table-auto datatable table_bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job/Gig</th>
                      <th>Applications</th>
                      <th className="center">Deadline</th>
                      <th className="center">Date posted</th>
                      <th className="center">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredJobs.length > 0 ? (
                      filteredJobs.map((item, index) => (
                        <tr key={item.row.id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="text-md font-semibold flex align-start gap-1">
                              <Link
                                to={`/admin/${id}/jobdetails/${item.row.activity_id}`}
                                className="w-full flex-1"
                              >
                                {item.row.job_name}
                              </Link>

                              <a
                                target="_blank"
                                href={`/jobs/${item.row.activity_id}`}
                                className="w-8 h-8 min-w-8 min-h-8 rounded-full flex align-center justify-center hover -mt-1"
                              >
                                <span
                                  class="material-symbols-outlined"
                                  style={{ fontSize: 15 }}
                                >
                                  open_in_new
                                </span>
                              </a>
                            </div>
                            <div className="my-2">
                              <span className="flex align-center justify-center text-capitalized bg-orange-100 w-20 text-black rounded-full text-sm">
                                {item.row.applications_info?.application_status}
                              </span>
                            </div>
                            <Link
                              to={`/admin/${id}/jobdetails/${item.row.activity_id}`}
                            >
                              <div className="text-sm">{item.row.category}</div>
                            </Link>
                          </td>
                          <td className="text-center">
                            {item.row.applications_info?.applications === 0 ? (
                              <span class="material-symbols-outlined">
                                remove
                              </span>
                            ) : (
                              <Link
                                className="flex gap-2 align-center"
                                to={`/admin/${id}/applications/all/${item.row.activity_id}?title=${item.row.job_name}`}
                              >
                                <div className="flex align-center gap-2">
                                  {item.row.applications_info?.applicants &&
                                    Array.isArray(
                                      item.row.applications_info?.applicants
                                    ) &&
                                    item.row.applications_info?.applicants
                                      .slice(0, 4)
                                      .map((user, userIndex) => (
                                        <div key={userIndex}>
                                          <img
                                            className="w-6 h-6 min-w-6 min-h-6 max-w-6 max-h-6 rounded-full object-cover"
                                            src={
                                              user.user_profile[0].profile_image
                                            }
                                            alt="User Profile"
                                          />
                                        </div>
                                      ))}
                                </div>
                                {item.row.applications_info?.applications >
                                  4 && (
                                  <div className="min-h-6 h-6 min-w-6 w-6 bg-gray-100 rounded-full flex align-center justify-center">
                                    +
                                    {item.row.applications_info?.applications -
                                      4}
                                  </div>
                                )}
                              </Link>
                            )}
                          </td>
                          <td>{item.row.applicant_deadline}</td>
                          <td>{item.row.date_time}</td>
                          <td>
                            <Menu>
                              <MenuButton className="h-10 w-10 bg-gray-100 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-3 post_attachment start_audio_recording">
                                <span className="material-symbols-outlined">
                                  more_horiz
                                </span>
                              </MenuButton>

                              <MenuItems
                                transition
                                anchor="bottom end"
                                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                              >
                                <MenuItem>
                                  <Link
                                    to={`/admin/${id}/applications/all/${item.row.activity_id}?title=${item.row.job_name}`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    View applications
                                  </Link>
                                </MenuItem>

                                <MenuItem>
                                  <Link
                                    to={`/admin/${id}/jobdetails/${item.row.activity_id}`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    View job details
                                  </Link>
                                </MenuItem>
                                <MenuItem>
                                  <a
                                    target="_blank"
                                    href={`/jobs/${item.row.activity_id}`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    Open job details (In job search)
                                  </a>
                                </MenuItem>
                                <MenuItem>
                                  <Link
                                    to={`/admin/${id}/createjob/basicjobinfo/${item.row.activity_id}`}
                                    className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    Edit job info
                                  </Link>
                                </MenuItem>
                                <hr className="line_sm" />
                                {item.row.applications_info
                                  ?.application_status == "active" ? (
                                  <MenuItem>
                                    <div
                                      onClick={() =>
                                        archiveJob(item.row.activity_id)
                                      }
                                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                    >
                                      Archive job
                                    </div>
                                  </MenuItem>
                                ) : (
                                  ""
                                )}

                                <MenuItem>
                                  <div
                                    onClick={() =>
                                      deleteJob(item.row.activity_id)
                                    }
                                    className="group text-red-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                                  >
                                    Delete
                                  </div>
                                </MenuItem>
                              </MenuItems>
                            </Menu>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No jobs available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostedJobs;
