import React, { useState } from "react";
import PostContent from "../../components/PostContent";
import { Link, useParams } from "react-router-dom";
import CopyRight from "../../components/CopyRight";
import {
  acceptJobApplication,
  getJobApplicationDetails,
  rejectJobApplication,
} from "../../../db";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import app from "../../../config/app";

const MySwal = withReactContent(Swal);

function JobApplications() {
  const [loading, setLoading] = useState(true);
  const [applicationDetails, setApplicationDetails] = useState();
  const [userInfo, setUserInfo] = useState();
  const [jobInfo, setJobInfo] = useState();
  let { id, page, focus } = useParams();

  const fetchData = async () => {
    try {
      const data = await getJobApplicationDetails(id, focus);
      console.log("Applications - > ", data);
      // console.log("Applications - > ", data.applications[0]);
      setApplicationDetails(data.info);
      setUserInfo(data.info.user_info[0]);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  useState(() => {
    fetchData();
  }, [id, page, focus]);

  const acceptApplication = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Please confirm to accept this application",
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm",
    });

    if (result.isConfirmed) {
      try {
        const response = await acceptJobApplication({
          business: id,
          activity_id: activity_id,
        });

        console.log(response);
        fetchData();
        MySwal.fire(
          "Accepted!",
          "Details saved and applicant notified",
          "success"
        );
      } catch (error) {
        console.error("Failed to archive the job:", error);
        MySwal.fire("Error!", "There was an error archiving the job.", "error");
      }
    }
  };

  const rejectApplication = async (activity_id) => {
    const result = await MySwal.fire({
      title: "Confirm",
      text: "Please confirm to reject this application",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm",
    });

    if (result.isConfirmed) {
      try {
        const response = await rejectJobApplication({
          business: id,
          activity_id: activity_id,
        });

        console.log(response);
        fetchData();
        MySwal.fire(
          "Archived!",
          "Details saved and applicant notified",
          "success"
        );
      } catch (error) {
        console.error("Failed:", error);
        MySwal.fire("Error!", "There was an error archiving the job.", "error");
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="w-full min-h-96 pt-20 flex align-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && (
        <div>
          <div className="px-2 mb-10">
            <div
              className="text-xl font-semibold py-3"
              onClick={() => fetchData()}
            >
              Application details
            </div>
            <div className="bg-gray-100 rounded-xl mt-3 py-4 px-4 flex flex-col gap-3">
              <div className="">
                <div class="flex align-start gap-3 py-3 pt-0">
                  <div class="w-28 h-28 min-w-28 min-h-28 rounded-full bg-gray-100">
                    <img
                      src={userInfo.profile_image}
                      class="w-28 h-28 min-w-28 min-h-28 rounded-full"
                    />
                  </div>
                  <div class=" py-3">
                    <div class="text-xl font-semibold flex relative align-center gap-2">
                      <span>{userInfo.fullname}</span>

                      {userInfo.verification_status == "true" && (
                        <svg
                          className="verification_icon"
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#e8eaed"
                          style={{ marginLeft: 0 }}
                        >
                          <g>
                            <rect fill="none" height="24" width="24"></rect>
                          </g>
                          <g>
                            <path d="M23,12l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,12l2.44,2.79l-0.34,3.7 l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,12z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"></path>
                          </g>
                        </svg>
                      )}
                    </div>
                    <div class="text-mute">@{userInfo.username}</div>
                    <div class="text font-semibold">
                      {userInfo.current_job_title}
                    </div>
                    <div class="flex align-center jsutify-center gap-2">
                      <div>Joined - {userInfo.join_date}</div>
                    </div>
                    <Link
                      to={`/admin/${id}/candidateprofile/${userInfo.username}/about`}
                      className="hover_decoration_link text-sm flex gap-2 text-mute"
                    >
                      <span> View candidate profile</span>
                      <span class="material-symbols-outlined text-sm">
                        arrow_right_alt
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <hr className="line" style={{ marginTop: 0, marginBottom: 0 }} />

              <div className="flex align-center gap-3">
                <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
                <div className="">
                  <div className="text-sm text-mute">Application date</div>
                  <div className="">{applicationDetails?.date_time}</div>
                </div>
              </div>

              <hr className="line" style={{ marginTop: 0, marginBottom: 0 }} />
              {applicationDetails?.about != "" &&
                applicationDetails?.about != null && (
                  <>
                    <div className="flex align-start gap-3">
                      <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
                      <div className="">
                        <div className="text-sm text-mute">Info</div>
                        <div className="">
                          <PostContent
                            text={applicationDetails?.about}
                            maxLength={100}
                            customStyle={{ padding: 0, marginBottom: 0 }}
                          />
                        </div>
                      </div>
                    </div>
                    <hr
                      className="line"
                      style={{ marginTop: 0, marginBottom: 0 }}
                    />
                  </>
                )}
              <div className="flex align-start gap-3">
                <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
                <div className="">
                  <div className="text-sm text-mute">Actions</div>
                  <div className="">
                    {(applicationDetails.status == "false" ||
                      applicationDetails.status == "rejected") && (
                      <div
                        onClick={() =>
                          acceptApplication(applicationDetails.activity_id)
                        }
                        className="group text-green-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 bg-gray-100 mt-2"
                      >
                        Accept application
                      </div>
                    )}

                    {applicationDetails.status == "accepted" && (
                      <div
                        onClick={() =>
                          rejectApplication(applicationDetails.activity_id)
                        }
                        className="group text-red-400 cursor-pointer flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 bg-gray-100 mt-2"
                      >
                        Reject application
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="line" style={{ marginTop: 0, marginBottom: 0 }} />

              {applicationDetails?.filename != "" &&
                applicationDetails?.filename != null && (
                  <>
                    <div className="flex align-center gap-3">
                      <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
                      <div className="">
                        <div className="text-sm text-mute">Cover letter</div>
                        <a
                          target="blank"
                          href={`${app.media_url}files/${applicationDetails?.filename}`}
                          download={`Cover letter - ${userInfo?.fullname}`}
                          className="flex align-center"
                        >
                          <div>Download document</div>
                        </a>
                      </div>
                    </div>
                    <hr
                      className="line"
                      style={{ marginTop: 0, marginBottom: 0 }}
                    />
                  </>
                )}

              <div className="flex align-center gap-3">
                <div className="min-w-10 w-10 h-10 bg-gray-100 rounded-full flex align-center justify-center"></div>
                <div className="min-w-72">
                  <div className="text-sm text-mute">Qualification</div>

                  <div className="text-sm mt-1 mb-1">
                    {applicationDetails?.job_qualification}%
                  </div>

                  <div
                    className="progress relative overflow-hidden"
                    style={{ height: 5, position: "relative" }}
                  >
                    <div
                      className="progress_inn animate__animated animate__slideInLeft"
                      style={{
                        width: applicationDetails?.job_qualification + "%",
                        height: 5,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="border-top mt-12"></div>
    </>
  );
}

export default JobApplications;
