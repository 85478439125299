import React from "react";
import app from "../../../../config/app";

function Attachment({ data, userProfile }) {
  console.log(data);
  return (
    <div>
      {data.length == 0 && (
        <div className="mt-0 text-gray-500">
          <div className="flex align-center">
            <img
              src="/images/colors-online-meeting-via-group-call.gif"
              className="w-48 h-auto object-contain"
              width={100}
            />
            <div className="px-3">
              <div className="">No attachments available.</div>
            </div>
          </div>
        </div>
      )}

      {data.length > 0 && (
        <div className="mt-0">
          <div className="flex flex-wrap gap-3 py-3">
            {data.map((item) => (
              <div
                key={item.id}
                className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex items-center justify-start gap-6 relative hover"
              >
                <div className="bg-white rounded-full w-10 h-10 overflow-hidden">
                  <img
                    src="/images/doc2.png"
                    className="w-10 h-10 object-contain p-1 rounded-sm"
                    alt="Attachment icon"
                  />
                </div>
                <div>
                  <div className="text-lg font-semibold">{item.name}</div>
                  {userProfile.downloadMyCv != "private" ? (
                    <a
                      href={app.media_url + "/files/" + item.file_name}
                      target="blank"
                      download={item.title}
                      className="flex items-center rounded-full gap-3"
                    >
                      <div className="text-sm px-2 bg-orange-200 rounded-xl">
                        {item.file_type &&
                          item.file_type.substring(
                            item.file_type.lastIndexOf("/") + 1
                          )}
                      </div>
                      <div>Download</div>
                      <span className="material-icons-round text-sm">
                        file_download
                      </span>
                    </a>
                  ) : (
                    <div className="flex align-center gap-2">
                      <span
                        class="material-symbols-outlined"
                        style={{ fontSize: 15 }}
                        title="You can not download this document because this user has restricted access to this file. Please contact them to allow downloads for this files so you can access it."
                      >
                        info
                      </span>
                      <span>Restricted access</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Attachment;
