import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import { useNavigate, useParams } from "react-router-dom";
import { getFeedListOnTopic } from "../../db";
import Feed from "../components/Feed";
import VibeDummy from "../components/Dummies/VibeDummy";
import VibePopup from "../VibePopup";

function VibesList({ local }) {
  const [usersList, setUsersList] = useState([]);
  const [vibeList, setVibeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const navigate = useNavigate();
  let { vibeid, tagname } = useParams();

  // Debounced scroll handler
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - 100 && // Adjust trigger point
      !loading
    ) {
      loadMore();
    }
  }, [loading]);

  // Set up scroll event listener with debounce
  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200); // Debounce with 200ms delay

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [handleScroll]);

  // Fetch vibe list data
  const fetchVibeListData = async () => {
    if (loading) return; // Prevent fetching if currently loading
    setLoading(true); // Start loading

    try {
      const data = await getFeedListOnTopic(tagname, 10, offset);
      if (data.vibes && data.vibes.length > 0) {
        // Create a Set of existing vibe IDs for quick lookup
        const existingVibeIds = new Set(
          vibeList.map((vibe) => vibe.row.activity_id)
        );

        // Filter out duplicates
        const newVibes = data.vibes.filter(
          (newVibe) => !existingVibeIds.has(newVibe.row.activity_id)
        );

        if (newVibes.length > 0) {
          setVibeList((prevVibes) => [...prevVibes, ...newVibes]); // Append new unique vibes to the existing list
        }

        // Increase offset for next fetch
        setOffset((prevOffset) => prevOffset + 10);
      } else {
        // Optionally handle the case where no vibes are returned
        console.log("No more vibes available."); // Optional: remove if not needed
      }
    } catch (error) {
      console.error("Error fetching vibes:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Load more vibes
  const loadMore = () => {
    fetchVibeListData();
  };

  // Update comments count (placeholder function)
  const updateCommentsCount = (count) => {};

  // Initial data fetch
  useEffect(() => {
    fetchVibeListData();
  }, []);

  return (
    <>
      <div className=" px-0 sm-p-0">
        {Array.isArray(vibeList) && vibeList.length > 0 && (
          <div className="p-0">
            {vibeList.map((item, index) => (
              <Feed
                data={item}
                key={item.row.activity_id}
                local={local}
                from={`/topics/${tagname}/`}
              />
            ))}
          </div>
        )}

        {/* Loading indicator */}
        {loading && <div className="text-center">Loading more vibes...</div>}

        <div className="mb-5">
          <VibeDummy />
        </div>
      </div>

      {vibeid && (
        <div className="vibe_overlay">
          <VibePopup
            vibeid={vibeid}
            local={local}
            onCommentSubmit={(count) => updateCommentsCount(count)}
          />
        </div>
      )}
    </>
  );
}

export default VibesList;
