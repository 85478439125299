import React from "react";
import { Link, useParams } from "react-router-dom";

function JoinHubList() {
  const { username, type } = useParams();
  const users = [
    {
      name: "James BMk",
      username: "@james",
      profile: "/images/user.png",
      bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor",
    },
    {
      name: "Kimani JJ",
      username: "@kimani",
      profile: "/images/max.jpg",
      bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor",
    },
    {
      name: "Allan Young",
      username: "@james",
      profile: "/images/featured.png",
      bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor",
    },
    {
      name: "Peter",
      username: "@james",
      profile: "/images/user.png",
      bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor",
    },
  ];

  const handleJoin = (username) => {
    alert(username);
  };

  return (
    <>
      {/* title */}
      <div className="flex align-center mt-8 justify-center mb-4">
        <div className="h-6 w-full rounded-full mr-10 align-center justify-center px-2 font-bold text-xl">
          Communities
        </div>
        <div className="h-6 w-40 hover rounded-full text-center break-keep font-medium">
          See more
        </div>
      </div>

      {/* list follow people */}
      {/* <div className="flex align-center" class="list-no-padding">
              <div className="h-10 min-w-10 bg-gray-200 mr-3 rounded-full"></div>
              <div className="h-10 w-full bg-gray-200 mr-4 rounded-full"></div>
              <div className="h-10 w-40 bg-gray-200 rounded-full"></div>
          </div> */}

      {users.map((item, index) => (
        <>
          <div
            key={index}
            className="flex align-start my-2 master_hover relative "
          >
            <div className="h-10 min-w-10 bg-gray-200 mr-3 rounded-full">
              <Link to={`/Profile/${item.username}`}>
                <img src={item.profile} class="profile_image_min" />
              </Link>
            </div>
            <div className="h-auto w-full mr-4 rounded-full">
              <Link to={`/Profile/${item.username}`}>
                <div className="font-semibold">{item.name}</div>
                <div className="text relative -top-1 text-mute">
                  {item.username}
                </div>
                <div className="text relative -top-1 bio_desc">{item.bio}</div>
              </Link>
            </div>
            <div
              className="h-10 w-40 rounded-full font-normal align-center justify-center text-center flex follow_btn"
              onClick={() => handleJoin(item.username)}
            >
              Join
            </div>
          </div>
        </>
      ))}
    </>
  );
}

export default JoinHubList;
