import React from "react";
import UsersSlide from "../components/UsersSlide";
import Feed from "../components/Feed";
import { Link } from "react-router-dom";
import HubSlide from "../components/HubSlide";
import Slider from "../components/Slider";

function DiscoverAll() {
  return (
    <div className="overflow-hidden">
      <div className="flex align-center justify-between">
        <div className="text-xl font-bold">People you may know</div>
        <Link
          to="/discover/people"
          className="h-8 w-32 flex align-center justify-center hover rounded-full text-center break-keep font-medium"
        >
          See more
        </Link>
      </div>
      <Slider slideWidth={200} slidesVisible={3}>
        {/* <UsersSlide />
        <UsersSlide />
        <UsersSlide />
        <UsersSlide />
        <UsersSlide />
        <UsersSlide />
        <UsersSlide /> */}
      </Slider>

      <hr className="line" />

      <div className="flex align-center justify-between">
        <div className="text-xl font-bold">Popular Hubs</div>
        <Link
          to="/discover/hubs"
          className="h-8 w-32 flex align-center justify-center hover rounded-full text-center break-keep font-medium"
        >
          See more
        </Link>
      </div>
      <Slider slideWidth={200} slidesVisible={2}>
        {/* <HubSlide />
        <HubSlide />
        <HubSlide />
        <HubSlide />
        <HubSlide />
        <HubSlide />
        <HubSlide /> */}
      </Slider>
    </div>
  );
}

export default DiscoverAll;
