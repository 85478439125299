import React, { useEffect, useState } from "react";
import Responce from "../../components/Responce";
import {
  getHubAccessList,
  getHubProfile,
  updateHubAccessToIndexedDB,
  updateHubProfileInfo,
} from "../../../db";
import { useParams } from "react-router-dom";
import app from "../../../config/app";
import Loader from "../../components/Loader";

function Profile({ local }) {
  const [hubProfile, setHubProfile] = useState();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [loading, setLoading] = useState(true);
  let { id, page, focus } = useParams();
  const [formData, setFormData] = useState({
    hub_name: "",
    about: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const info = await getHubProfile(id);
      // console.log("Hub info - > ", info.hub_info[0]);
      setHubProfile(info.hub_info[0]);

      if (info.r_status) {
        setFormData({
          hub_name: info.hub_info[0].hub_name,
          about: info.hub_info[0].about,
        });
      }
    } catch (error) {
      console.error("Details:", error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await updateHubProfileInfo(id, formData);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      setLoading(false);
      // console.log(responce_card);
    }

    // update local data list
    await getHubAccessList(app.current_user, 30);
    console.log("Form submitted res:", res);
  };

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Hub public profile</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5 relative">
          {/* inputs */}
          {loading && (
            <div className="absolute w-full flex align-center justify-center h-full rounded-2xl top-0 left-0 auth_loader_cont">
              <Loader />
            </div>
          )}
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="name">
                Community (Hub) name
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">group</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="hub_name"
                  id="hub_name"
                  value={formData.hub_name}
                  onChange={handleChange}
                  placeholder="eg. Learn English"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="about">
                Short description about this hub
              </label>
              <div className="flex align-center relative mt-3">
                <textarea
                  rows={5}
                  className="form-control w-full relative min-h-40 pt-4 px-3"
                  name="about"
                  id="about"
                  value={formData.about}
                  onChange={handleChange}
                  placeholder="Write something"
                ></textarea>
              </div>
            </div>
          </div>
          {/* submit the form */}
          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      {showResponceCard && responceCard}
    </div>
  );
}
export default Profile;
