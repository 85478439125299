import React, { useEffect, useState } from "react";
import axios from "axios";

const LinkPreview = ({ links, show_featured_img = true, size = "large" }) => {
  const [iconUrl, setIconUrl] = useState("");
  const [featuredImageUrl, setFeaturedImageUrl] = useState("");
  const [domainName, setDomainName] = useState("");
  const [pageTitle, setPageTitle] = useState(""); // New state for the page title
  const [imageLoading, setImageLoading] = useState(true); // Track image loading state

  useEffect(() => {
    const fetchPreviewData = async () => {
      if (Array.isArray(links) && links.length > 0) {
        const targetUrl = links[0];
        const url = new URL(targetUrl);
        setDomainName(url.hostname);

        try {
          const response = await axios.get(
            "http://localhost/api/fetch-preview.php",
            {
              params: { url: targetUrl },
            }
          );

          const { iconUrl, featuredImageUrl, title } = response.data; // Extract title from response
          setIconUrl(iconUrl);
          setFeaturedImageUrl(featuredImageUrl);
          setPageTitle(title); // Set the page title state
        } catch (error) {
          console.error("Error fetching preview data:", error);
          setIconUrl("/images/link.png");
          setFeaturedImageUrl("");
          setPageTitle(""); // Reset title on error
        }
      }
    };

    fetchPreviewData();
  }, [links]);

  const handleImageLoad = () => {
    setImageLoading(false); // Image loaded successfully
  };

  if (!links || links.length === 0) return null;

  return (
    <a className="external_link" target="_blank" href={links[0]}>
      {/* Loader or image */}
      {featuredImageUrl && show_featured_img && size != "small" && (
        <div className="w-full bg-gray-100 flex align-center justify-center media_container_inn">
          {imageLoading && (
            <div className="loader w-full h-64 flex items-center justify-center">
              {/* Loader content */}
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full"></div>
            </div>
          )}
          <img
            src={featuredImageUrl}
            className={`w-full h-auto rounded-none ${
              imageLoading ? "hidden" : ""
            }`}
            onLoad={handleImageLoad}
            alt="Preview"
            style={{ borderRadius: 0 }}
          />
        </div>
      )}

      <div
        className={`flex bg-gray-100 ${
          size == "large" ? "px-5  py-2" : "p-2 px-2"
        } w-full link_mn_container`}
      >
        <div className={`${size == "large" ? "py-2" : "py-0"}`}>
          {imageLoading && size == "small" && (
            <div className="loader w-24 h-24 flex items-center justify-center">
              {/* Loader content */}
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full"></div>
            </div>
          )}
          {size == "small" && (
            <img
              src={featuredImageUrl}
              className={`w-24 h-24 rounded-lg object-cover ${
                imageLoading ? "hidden" : ""
              }`}
              onLoad={handleImageLoad}
            />
          )}
          {iconUrl && size != "small" && (
            <img
              src={iconUrl}
              className="w-6 h-6 max-w-6 max-h-6"
              style={{ objectFit: "contain" }}
              alt="Icon"
            />
          )}
        </div>
        <div className="flex-1 px-4 py-1">
          <div className="font-semibold">{pageTitle || domainName}</div>
          {/* Display page title or domain name */}
          <div className="text-sm" style={{ opacity: 0.7 }}>
            {pageTitle != "" ? domainName : "Open link"}
          </div>
        </div>
        <div className="py-2">
          <span className="material-symbols-outlined">north_east</span>
        </div>
      </div>
    </a>
  );
};

export default LinkPreview;
