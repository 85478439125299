import { Link, useLocation } from "react-router-dom";
import {
  getProfileFromIndexedDB,
  getProfileImageAndCoverSettingsFromIndexedDB,
} from "../../db";
import { useEffect, useState } from "react";
import app from "../../config/app";

function GeneralMenuLeft({ local }) {
  const [fullName, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [userProfile, setUserProfile] = useState({
    profile_image: "",
    cover_image: "",
  });

  useEffect(() => {
    if (app.current_user && app.current_user != "") {
      const fetchProfileImage = async () => {
        const profile = await getProfileImageAndCoverSettingsFromIndexedDB();
        setUserProfile(profile || "");
      };

      const fetchProfileInfo = async () => {
        const profile = await getProfileFromIndexedDB();
        setFullname(profile?.fullName || "");
        setUsername(profile?.username || "");
      };

      fetchProfileImage();
      fetchProfileInfo();
    }
  }, [local]);

  let general_left_menu = [];

  if (app.current_user != "") {
    general_left_menu = [
      {
        icon: "profile",
        title: fullName,
        path: "/Profile/" + username,
        has_subs: false,
      },
      {
        icon: "home",
        title: "Home",
        path: "/home",
        has_subs: false,
      },
      {
        icon: "tag",
        title: "Topics",
        path: "/topics",
        has_subs: false,
      },
      {
        icon: "business_center",
        title: "Find Jobs & Gigs",
        path: "/jobs",
        has_subs: false,
      },
      {
        icon: "explore",
        title: "Discover",
        path: "/discover/people",
        has_subs: false,
      },
      {
        icon: "people",
        title: "Communities",
        path: "/communities",
        has_subs: false,
      },

      {
        icon: "bookmark",
        title: "Bookmarks",
        path: "/bookmarks",
        has_subs: false,
      },
      {
        icon: "offline_bolt",
        title: "Professional",
        path: "/professional/applications",
        has_subs: true,
        class: "professional",
      },
      {
        icon: "manage_accounts",
        title: "Settings",
        path: "/settings",
        has_subs: true,
      },
      {
        icon: "hr",
        title: "",
        path: "",
      },
    ];
  } else {
    general_left_menu = [
      {
        icon: "business_center",
        title: "Find Jobs & Gigs",
        path: "/jobs",
        has_subs: false,
      },
      {
        icon: "explore",
        title: "Discover",
        path: "/discover",
        has_subs: false,
      },
      {
        icon: "hr",
        title: "",
        path: "",
      },
    ];
  }

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="py-4 left_list_cont px-2" path={currentPath}>
      {general_left_menu.map((item, index) =>
        item.icon != "hr" ? (
          <Link key={item.path} to={item.path} className="w-full">
            <div
              className={
                currentPath == item.path
                  ? "active px-4 py-1 my-1 flex align-center list w-full "
                  : "px-4 py-1 my-1 flex align-center list w-full "
              }
            >
              <div className="icon_cont h-10 min-w-10 min-h-10 w-10 mr-3 rounded-full flex align-center text-normal justify-center text-center ">
                {item.title == "Home" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="48px"
                    height="48px"
                  >
                    <path d="M39.5,43h-9c-1.381,0-2.5-1.119-2.5-2.5v-9c0-1.105-0.895-2-2-2h-4c-1.105,0-2,0.895-2,2v9c0,1.381-1.119,2.5-2.5,2.5h-9 C7.119,43,6,41.881,6,40.5V21.413c0-2.299,1.054-4.471,2.859-5.893L23.071,4.321c0.545-0.428,1.313-0.428,1.857,0L39.142,15.52 C40.947,16.942,42,19.113,42,21.411V40.5C42,41.881,40.881,43,39.5,43z"></path>
                  </svg>
                ) : item.icon == "profile" ? (
                  <div className="w-8 h-8 min-w-8 min-h-8 rounded-full bg-gray-100">
                    {userProfile.profile_image != "" && (
                      <img
                        src={userProfile.profile_image}
                        className="w-8 h-8 min-w-8 min-h-8 rounded-full object-cover"
                      />
                    )}
                  </div>
                ) : currentPath == item.path ? (
                  <span className="material-icons-round">{item.icon}</span>
                ) : (
                  <span className="material-symbols-outlined">{item.icon}</span>
                )}
              </div>
              <div className="h-10 rounded-full flex align-center px-4 pl-2 py-2 text-normal list_title flex-1 w-full">
                {item.title} <span className={`ml-5 ${item?.class}`}></span>
              </div>
              {item.has_subs ? (
                <div className="flex align-center arrow_more">
                  <span class="material-icons-round">chevron_right</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </Link>
        ) : (
          // <hr class="line" />
          <></>
        )
      )}
    </div>
  );
}

export default GeneralMenuLeft;
