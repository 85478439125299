import axios from "axios";
import React, { useState, useEffect } from "react";
import app from "../../config/app";
import CopyRight from "../components/CopyRight";
import {
  saveNotificationSettingsToIndexedDB,
  getNotificationSettingsFromIndexedDB,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function Notifications({ local }) {
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [formData, setFormData] = useState({
    emailNotifications: "",
    followNotifications: "",
    myHubJoinRequestNotifications: "",
    hubJoinRequestAcceptanceNotifications: "",
    jobApplicantNotifications: "",
    jobApplicationAcceptanceNotifications: "",
    jobApplicationRejectionsNotifications: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const savedSettings = await getNotificationSettingsFromIndexedDB();
      if (savedSettings) {
        setFormData(savedSettings);
      }
    };
    fetchSettings();
  }, [local]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted:", formData);
    const responce = await saveNotificationSettingsToIndexedDB(formData);
    updateLocalStorage({ type: "notificationSettings", data: formData });
    // console.log("Responce", responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  return (
    <div className="p-7">
      <form onSubmit={handleSubmit}>
        <div className="text-xl font-semibold">Notifications settings</div>
        <div className="form-group mt-6 rounded-2xl p-6 border-1">
          <div>
            <h3>Email notifications</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="emailNotifications"
              id="emailNotificationsAllow"
              value="on"
              checked={formData.emailNotifications === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="emailNotificationsAllow"
            >
              Allow
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="emailNotifications"
              id="emailNotificationsDisable"
              value="off"
              checked={formData.emailNotifications === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="emailNotificationsDisable"
            >
              Disable
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Follow notifications</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="followNotifications"
              id="followNotificationsEnable"
              value="on"
              checked={formData.followNotifications === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="followNotificationsEnable"
            >
              Enable
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="followNotifications"
              id="followNotificationsDisable"
              value="off"
              checked={formData.followNotifications === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="followNotificationsDisable"
            >
              Disable
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Hub join requests</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="myHubJoinRequestNotifications"
              id="myHubJoinRequestNotificationsShow"
              value="on"
              checked={formData.myHubJoinRequestNotifications === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="myHubJoinRequestNotificationsShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="myHubJoinRequestNotifications"
              id="myHubJoinRequestNotificationsMute"
              value="off"
              checked={formData.myHubJoinRequestNotifications === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="myHubJoinRequestNotificationsMute"
            >
              Mute
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Hub join request acceptance</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="hubJoinRequestAcceptanceNotifications"
              id="hubJoinRequestAcceptanceNotificationsShow"
              value="on"
              checked={formData.hubJoinRequestAcceptanceNotifications === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="hubJoinRequestAcceptanceNotificationsShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="hubJoinRequestAcceptanceNotifications"
              id="hubJoinRequestAcceptanceNotificationsHide"
              value="off"
              checked={formData.hubJoinRequestAcceptanceNotifications === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="hubJoinRequestAcceptanceNotificationsHide"
            >
              Hide
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Job applicants</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="jobApplicantNotifications"
              id="jobApplicantNotificationsShow"
              value="on"
              checked={formData.jobApplicantNotifications === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="jobApplicantNotificationsShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="jobApplicantNotifications"
              id="jobApplicantNotificationsHide"
              value="off"
              checked={formData.jobApplicantNotifications === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="jobApplicantNotificationsHide"
            >
              Hide
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Job application acceptance</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="jobApplicationAcceptanceNotifications"
              id="jobApplicationAcceptanceNotificationsShow"
              value="on"
              checked={formData.jobApplicationAcceptanceNotifications === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="jobApplicationAcceptanceNotificationsShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="jobApplicationAcceptanceNotifications"
              id="jobApplicationAcceptanceNotificationsHide"
              value="off"
              checked={formData.jobApplicationAcceptanceNotifications === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="jobApplicationAcceptanceNotificationsHide"
            >
              Hide
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Job application rejections</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="jobApplicationRejectionsNotifications"
              id="jobApplicationRejectionsNotificationsShow"
              value="on"
              checked={formData.jobApplicationRejectionsNotifications === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="jobApplicationRejectionsNotificationsShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="jobApplicationRejectionsNotifications"
              id="jobApplicationRejectionsNotificationsHide"
              value="off"
              checked={formData.jobApplicationRejectionsNotifications === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="jobApplicationRejectionsNotificationsHide"
            >
              Hide
            </label>
          </div>
        </div>

        <button className="mt-16 btn" type="submit">
          Submit
        </button>
      </form>
      <div className="mt-10">
        <hr className="line" />
        <CopyRight />
      </div>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Notifications;
