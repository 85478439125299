import React, { useEffect, useState } from "react";
import TopUserMenu from "./components/TopUserMenu";
import FollowListReused from "./components/FollowListReused";
import JoinHubsListReused from "./components/JoinHubsListReused";
import CopyRight from "./components/CopyRight";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import app from "../config/app";
import HubSliderDummy from "./components/Dummies/HubSliderDummy";
import { getDiscoverPagesGroupSingleList } from "../db";
import BusinessSlide from "./components/BusinessSlide";
import Loader from "./components/Loader";

function PagesTaxonimy() {
  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname;
  let { name } = useParams();

  const [allPages, setAllPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [taxoTitle, setTaxoTitle] = useState("");

  useEffect(() => {
    fetchPagesListData();
    switch (name) {
      case "recentpages":
        setTaxoTitle("Recent pages");
        break;
      case "popularpages":
        setTaxoTitle("Popular pages");
        break;

      default:
        break;
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [offset]);

  const fetchPagesListData = async () => {
    // if (!hasMore || loading) return; // Prevent fetching if already loading or no more pages
    setLoading(true);
    try {
      const data = await getDiscoverPagesGroupSingleList(9, offset, name);
      console.log("Pages -----> ", data);
      if (data && data.data && data.data.length === 0) {
        setHasMore(false);
      }

      setAllPages((prevPages) => [...prevPages, ...(data.data || [])]);
    } catch (error) {
      console.error("Error fetching pages:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight &&
      hasMore &&
      !loading
    ) {
      setOffset((prevOffset) => prevOffset + 9);
    }
  };

  const loadMore = () => {
    setOffset((prevOffset) => prevOffset + 9);
  };

  return (
    <>
      <div className="min-h-screen flex-1 border-left">
        <div className="px-4 headers p-0 pl-1">
          <div
            className=" h-full w-full flex align-center pl-2"
            style={{ width: "100%" }}
          >
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Discover pages</div>
            </div>
          </div>
          {window.innerWidth > app.mobile_width && <TopUserMenu />}
        </div>
        <div className="headers px-0 flex align-center gap-3">
          <div className="w-full">
            <input
              className="search_input form-control px-2 rounded-full text-md w-full"
              placeholder="Search pages"
            />
          </div>
          <div className="flex-1">
            {taxoTitle != "" && (
              <button
                className="flex align-center h-10 min-w-8 rounded-full bg-gray-100 px-3 gap-2 no-wrap"
                style={{ paddingBottom: 5 }}
              >
                <span>{taxoTitle}</span>
              </button>
            )}
          </div>
        </div>

        <div className="p-0 mt-4">
          {loading && allPages.length === 0 && (
            <>
              <div className="flex gap-0 pt-5">
                <HubSliderDummy />
                <HubSliderDummy />
                <HubSliderDummy />
              </div>
              <div className="flex gap-0 pt-5">
                <HubSliderDummy />
                <HubSliderDummy />
                <HubSliderDummy />
              </div>
              <div className="flex gap-0 pt-5">
                <HubSliderDummy />
                <HubSliderDummy />
                <HubSliderDummy />
              </div>
            </>
          )}

          {allPages.length > 0 && (
            <>
              <div className="slider grid grid-cols-3 align-start gap-3 pb-5 flex-wrap px-4">
                {allPages.map((item, index) => (
                  <BusinessSlide key={index} simple={true} data={item} />
                ))}
              </div>
            </>
          )}

          {/* Show load more button or message */}
          <div className="align-center justify-center w-full py-5 flex">
            {hasMore ? (
              <button
                className={`${!loading ? "btn" : ""}`}
                onClick={loadMore}
                disabled={loading}
              >
                {loading ? <Loader /> : "Load more"}
              </button>
            ) : (
              <div>No more businesses to load</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PagesTaxonimy;
