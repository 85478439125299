import React from "react";

function TableDummy() {
  return (
    <div className="">
      <div className="flex align-center justify-between gap-2 px-3 py-2">
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
      </div>
      <div className="flex align-center justify-between gap-2 px-3 py-2">
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
      </div>
      <div className="flex align-center justify-between gap-2 px-3 py-2">
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
        <div className="w-full h-8 rounded-xl bg-gray-100 loader animate__animated animate__zoomIn animate_fast"></div>
      </div>
    </div>
  );
}

export default TableDummy;
