import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Login from "./modals/Login";
import Signup from "./modals/Signup";
import AuthChangePassword from "./modals/AuthChangePassword";
import AuthUpdateUsername from "./modals/AuthUpdateUsername";
import AuthFollowPeople from "./modals/AuthFollowPeople";
import AuthResetPassword from "./modals/AuthResetPassword";

function Auth() {
  const { name } = useParams();
  const [modal, setModal] = useState("login"); // default to "login"

  useEffect(() => {
    if (!name) {
      setModal("login");
    } else {
      setModal(name);
    }
  }, [name]);

  let component;
  if (modal === "signup") {
    component = <Signup />;
  } else if (modal === "createpassword") {
    component = <AuthChangePassword />;
  } else if (modal === "followpeople") {
    component = <AuthFollowPeople />;
  } else if (modal === "resetpassword") {
    component = <AuthResetPassword />;
  } else if (modal === "updateusername") {
    component = <AuthUpdateUsername />;
  } else {
    component = <Login />;
  }

  return <div className="auth_container">{component}</div>;
}

export default Auth;
