import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import app from "../../config/app";
import Login from "../modals/Login";
import Signup from "../modals/Signup";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
} from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import {
  getBusinessFromIndexedDB,
  getHubFromIndexedDB,
  getProfileFromIndexedDB,
  getProfileImageAndCoverSettingsFromIndexedDB,
} from "../../db";

import Cookies from "js-cookie";
import Loader from "./Loader";

function TopUserMenu({ local }) {
  const user = app.current_user;
  const navigate = useNavigate();
  const [activeModal, setModal] = useState(null);
  const [isAppsDropdownOpen, setAppsDropdownOpen] = useState(false);
  const [isAccountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("pages");
  const [enabled, setEnabled] = useState(app.enabledDarkmode);
  const [fullName, setFullname] = useState(false);
  const [username, setUsername] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localUpdate, setLocalUpdate] = useState(null); // State to hold update data
  const [businessList, setBusinessList] = useState([]);
  const [hubList, setHubList] = useState([]);

  const [userProfile, setUserProfile] = useState({
    profile_image: "",
    cover_image: "",
  });

  useEffect(() => {
    if (app.current_user && app.current_user != "") {
      fetchHub();
      fetchBusienss();
      fetchProfileImage();
      fetchProfileInfo();
    } else {
      navigate("/auth/signin");
    }

    // check and update theme settings
    const darkModeSetting = Cookies.get("enabledDarkmode") === "true";
    setEnabled(darkModeSetting);
    if (darkModeSetting) {
      document.documentElement.classList.add("dark_mode");
      document.documentElement.classList.remove("light_mode");
    } else {
      document.documentElement.classList.add("light_mode");
      document.documentElement.classList.remove("dark_mode");
    }

    // console.log(userProfile.profile_image);
  }, [local, activeTab]);

  const fetchProfileImage = async () => {
    const profile = await getProfileImageAndCoverSettingsFromIndexedDB();
    setUserProfile(profile || "");
  };

  const fetchProfileInfo = async () => {
    const profile = await getProfileFromIndexedDB();
    setFullname(profile.fullName || "");
    setUsername(profile.username || "");
  };

  const fetchBusienss = async () => {
    const bs_list = await getBusinessFromIndexedDB();
    setBusinessList(bs_list);
    // console.log("bs list", bs_list);
  };

  const fetchHub = async () => {
    const hb_list = await getHubFromIndexedDB();
    setHubList(hb_list);
    console.log("hub list", hb_list);
  };

  let tab_component = "";
  let component = "";
  if (activeModal === "login") {
    component = <Login />;
  }

  if (activeModal === "signup") {
    component = <Signup />;
  }

  const toggleAppsDropdown = () => {
    setAppsDropdownOpen(!isAppsDropdownOpen);
  };

  const toggleAccountDropdown = () => {
    setAccountDropdownOpen(!isAccountDropdownOpen);
  };

  const handleSwitchChange = (checked) => {
    if (checked) {
      document.documentElement.classList.add("dark_mode");
      document.documentElement.classList.remove("light_mode");
    } else {
      document.documentElement.classList.add("light_mode");
      document.documentElement.classList.remove("dark_mode");
    }

    Cookies.set("enabledDarkmode", checked, { expires: 365 });
    setEnabled(checked);
  };

  const updateWidgets = () => {
    fetchHub();
    fetchBusienss();
    fetchProfileInfo();
  };

  const signout = async () => {
    setLoading(true);
    localStorage.clear();
    Cookies.remove("sys_user");

    // Clear IndexedDB by deleting all databases
    const deleteIndexedDB = () => {
      return new Promise((resolve, reject) => {
        const databases = indexedDB.databases
          ? indexedDB.databases()
          : Promise.resolve([]);

        databases.then((dbs) => {
          const deletePromises = dbs.map((db) => {
            return new Promise((resolve, reject) => {
              const request = indexedDB.deleteDatabase(db.name);
              request.onsuccess = resolve;
              request.onerror = reject;
            });
          });

          Promise.all(deletePromises)
            .then(() => resolve())
            .catch((error) => reject(error));
        });
      });
    };

    try {
      await deleteIndexedDB();
      // Redirect to the sign-in page
      window.location.href = "/auth/signin";
    } catch (error) {
      console.error("Error clearing IndexedDB:", error);
    }
  };

  if (activeTab == "pages") {
    tab_component = (
      <>
        <div className="px-3 py-4 pt-0 h-14 pt-3 pr-0 flex align-center">
          <div className="w-full text-mute">My pages</div>
          <Link
            to="/CreatePage"
            className="w-10 h-10 min-w-10 flex align-center cursor-pointer hover justify-center rounded-full"
          >
            <PlusCircleIcon />
          </Link>
        </div>
        {businessList.map((item, index) => (
          <MenuItem>
            <Link
              to={`/admin/${item?.business_info[0]?.page_id}`}
              className="flex align-center gap-3 hover rounded-xl p-1"
            >
              <div className="w-10 h-10 min-w-10 rounded-full bg-orange-100">
                <img
                  src={
                    item?.business_info[0]?.profile_image == ""
                      ? "/images/business.png"
                      : app.media_url +
                        "/thumb/small/" +
                        item?.business_info[0]?.profile_image
                  }
                  className="w-10 h-10 min-w-10 rounded-full object-cover"
                />
              </div>
              <div className="w-full">
                {item?.business_info[0]?.business_name}
              </div>
            </Link>
          </MenuItem>
        ))}
      </>
    );
  } else if (activeTab == "hubs") {
    tab_component = (
      <>
        <div className="px-3 py-4 pt-0 h-14 pt-3 pr-0 flex align-center">
          <div className="w-full text-mute">Commnunites (Hub)</div>
          <Link
            to="/CreateHub"
            className="w-10 h-10 min-w-10 flex align-center cursor-pointer hover justify-center rounded-full"
          >
            <PlusCircleIcon />
          </Link>
        </div>
        {hubList.map((item, index) => (
          <Link
            to={`/communities/${item.hub_info[0]?.username}`}
            className="flex align-center gap-3 hover rounded-xl p-1"
          >
            <div className="w-10 h-10 min-w-10 rounded-full bg-orange-100">
              <img
                src={
                  item.hub_info[0]?.profile_image == ""
                    ? "/images/hubuser.png"
                    : app.media_url +
                      "/thumb/" +
                      item.hub_info[0]?.profile_image
                }
                className="w-10 h-10 min-w-10 rounded-full object-cover"
              />
            </div>

            <div className="w-full">{item.hub_info[0]?.hub_name}</div>
          </Link>
        ))}
      </>
    );
  } else if (activeTab == "apps") {
    tab_component = (
      <>
        <div className="px-3 py-4 pt-0 h-14 pt-3 pr-0 flex align-center">
          <div className="w-full text-mute">Explore our apps</div>
        </div>
        <div className="grid grid-cols-2 align-center w-full gap-3 ">
          <Link
            to="/apps/quickhr"
            className="flex flex-column w-full apps_item h-32 justify-center hover:shadow-xl align-center p-2 gap-3 hover rounded-xl p-1"
          >
            <div className="w-10 h-10 min-w-10 rounded-full bg-orange-100"></div>
            <div className="w-full text-center w-full">QuickHR</div>
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      {user != "" ? (
        <div className="px-4 flex align-center headers border-none justify-end right_header">
          {loading && (
            <div className="fixed w-full flex align-center justify-center h-full top-0 auth_loader_cont">
              <Loader />
            </div>
          )}

          <Link to="/CreatePost">
            <div className="h-10 hover w-auto px-4 mr-3 rounded-full flex align-center justify-center create_btn">
              <div>
                <span className="material-icons-round relative top-1">add</span>
              </div>
              <div className="font-medium ml-2">Create</div>
            </div>
          </Link>
          <div>
            <Menu>
              <MenuButton
                className="inline-flex mr-3 items-center gap-2 rounded-full font-semibold focus:outline-none hover data-[focus]:outline-1 data-[focus]:outline-white"
                onClick={() => updateWidgets()}
              >
                <div className="h-10 hover w-10 rounded-full flex align-center justify-center">
                  <div>
                    <span className="material-icons-round relative top-1">
                      widgets
                    </span>
                  </div>
                </div>
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom end"
                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container apps_dropdown_container"
              >
                <div className="px-0">
                  <div className="text-mute text-xl py-3 px-3">
                    Manage business pages and apps
                  </div>

                  <div className="align-start">
                    <div className="min-w-40 py-0 px-1 pr-3 flex align-center gap-2 border-bottom">
                      {/* <div
                        onClick={() => setActiveTab("apps")}
                        className={
                          activeTab == "apps"
                            ? "active tab_title flex w-auto pr-2 align-center gap-2 hover rounded-xl cursor-pointer py-1 px-2 my-1 pr-5"
                            : "tab_title flex w-auto pr-2 align-center gap-2 hover rounded-xl cursor-pointer py-1 px-2 my-1 pr-5"
                        }
                      >
                        <div className="w-10 h-10 min-w-10 rounded-full align-center flex">
                          <img
                            className="w-8 h-8 object-cover mr-4"
                            src="/images/icons8-faq-48.png"
                          />
                        </div>
                        <div className="w-full">Apps</div>
                      </div> */}
                      <div
                        onClick={() => setActiveTab("pages")}
                        className={
                          activeTab == "pages"
                            ? "active tab_title flex w-auto pr-2 align-center gap-2 hover rounded-xl cursor-pointer py-1 px-2 my-1 pr-5"
                            : "tab_title flex w-auto pr-2 align-center gap-2 hover rounded-xl cursor-pointer py-1 px-2 my-1 pr-5"
                        }
                      >
                        <div className="w-10 h-10 min-w-10 rounded-full align-center flex">
                          <img
                            className="w-8 h-8 object-cover mr-4"
                            src="/images/icons8-company-48.png"
                          />
                        </div>
                        <div className="w-full">Pages</div>
                      </div>
                      <div
                        onClick={() => setActiveTab("hubs")}
                        className={
                          activeTab == "hubs"
                            ? "active tab_title flex w-auto pr-2 align-center gap-2 hover rounded-xl cursor-pointer py-1 px-2 my-1 pr-5"
                            : "tab_title flex w-auto pr-2 align-center gap-2 hover rounded-xl cursor-pointer py-1 px-2 my-1 pr-5"
                        }
                      >
                        <div className="w-10 h-10 min-w-10 rounded-full align-center flex">
                          <img
                            className="w-8 h-8 object-cover mr-4"
                            src="/images/customer-insight-48.png"
                          />
                        </div>
                        <div className="w-full">Hubs</div>
                      </div>
                    </div>
                    <div className="w-full border-top px-2">
                      {tab_component}
                    </div>
                  </div>

                  <div className=""></div>
                </div>
                <div className="px-3">
                  <hr className="line pt-1 rounded-full" />
                </div>

                <div className="py-4 pt-2">
                  <MenuItem>
                    <Link
                      to="/CreatePage"
                      className="group flex w-full items-center gap-2 rounded-lg py-2.5 px-3 data-[focus]:bg-white/10"
                    >
                      <img
                        className="w-8 h-8 object-cover mr-4"
                        src="/images/icons8-company-48.png"
                      />
                      <div className="text-left w-full">
                        <div className="text-title">Create a business page</div>
                        <div className="text-mute text-sm">
                          Easly manage your business public presence
                        </div>
                      </div>
                      <div className="">
                        <ChevronRightIcon />
                      </div>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="/CreateHub"
                      className="group flex w-full items-center gap-2 rounded-lg py-2.5 px-3 data-[focus]:bg-white/10"
                    >
                      <img
                        className="w-8 h-8 object-cover mr-4"
                        src="/images/icons8-people-48.png"
                      />
                      <div className="text-left w-full">
                        <div className="text-title">
                          Create a community (Hub)
                        </div>
                        <div className="text-mute text-sm">
                          Enable people to connect and share ideas
                        </div>
                      </div>
                      <div className="">
                        <ChevronRightIcon />
                      </div>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <button className="group flex w-full items-center gap-2 rounded-lg py-2.5 px-3 data-[focus]:bg-white/10">
                      <img
                        className="w-8 h-8 object-cover mr-4"
                        src="/images/new-job-48.png"
                      />
                      <div className="text-left w-full">
                        <div className="text-title">Post a job or gig</div>
                        <div className="text-mute text-sm">
                          Are you hiring? Get best talent.
                        </div>
                      </div>
                      <div className="">
                        <ChevronRightIcon />
                      </div>
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button className="group flex w-full items-center gap-2 rounded-lg py-2.5 px-3 data-[focus]:bg-white/10">
                      <img
                        className="w-8 h-8 object-cover mr-4"
                        src="/images/hashtag-large-48.png"
                      />
                      <div className="text-left w-full">
                        <div className="text-title">Create a custom post</div>
                        <div className="text-mute text-sm">
                          Share whats on your mind with friends
                        </div>
                      </div>
                      <div className="">
                        <ChevronRightIcon />
                      </div>
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>

          <Link to="/Notifications">
            <div className="h-10 hover w-10 mr-3 rounded-full flex align-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z"></path>
                <path
                  fillRule="evenodd"
                  d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </Link>
          {/* <Link to="/Messages">
            <div className="h-10 hover w-10 mr-3 rounded-full flex align-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </Link> */}
          <div className="relative">
            <Menu>
              <MenuButton
                className="h-8 w-8 min-w-8 min-h-8 max-h-8 max-w-8 inline-flex items-center rounded-full text-sm/6 font-semibold  focus:outline-none hover data-[focus]:outline-1 data-[focus]:outline-white bg-gray-100"
                style={{ position: "relative", top: 3 }}
              >
                {userProfile.profile_image != "" && (
                  <img
                    src={userProfile.profile_image}
                    className="h-8 w-8 min-w-8 min-h-8 max-h-8 max-w-8 object-cover rounded-full"
                  ></img>
                )}
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom end"
                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container account_dropdown_container"
              >
                {/* dropdown menu for profile */}
                <MenuItem>
                  <Link to={`/Profile/@${username}`}>
                    <div className="w-full flex align-center py-3 px-3 pl-1 pb-0">
                      <div className="w-20 h-20 min-w-20 min-h-20 rounded-full bg-white">
                        <img
                          src={userProfile.profile_image}
                          className="h-20 w-20 min-w-20 min-h-20 max-h-20 max-w-20 object-cover rounded-full mr-1"
                        ></img>
                      </div>
                      <div className="w-full rounded-full px-3 flex align-start flex-column">
                        <div className="text-xl font-bold">{fullName}</div>
                        <div className="text-mute">@{username}</div>
                      </div>
                    </div>
                  </Link>
                </MenuItem>

                <hr className="line_sm" />
                <MenuItem>
                  <Link
                    to={`/Profile/@${username}`}
                    className="group flex w-full items-center gap-3 rounded-lg py-1.5 px-3 pl-1 data-[focus]:bg-white/10"
                  >
                    <div className="flex align-center arrow_more">
                      <img
                        src={userProfile.profile_image}
                        className="w-10 h-10 min-w-10 min-h-10 rounded-full object-cover"
                      />
                    </div>
                    <div className="w-full text-left">Profile overview</div>
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="/Settings"
                    className="group flex h-12 w-full items-center gap-3 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                  >
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">
                        settings_suggest
                      </span>
                    </div>
                    <div className="w-full text-left">Account settings</div>
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">
                        chevron_right
                      </span>
                    </div>
                  </Link>
                </MenuItem>

                <MenuItem>
                  <div
                    onClick={() => signout()}
                    className="group flex h-12 w-full items-center gap-3 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 hover"
                  >
                    <div className="flex align-center arrow_more">
                      <span class="material-icons-round">person_add_alt</span>
                    </div>
                    <div className="w-full text-left">Sign out</div>
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">add</span>
                    </div>
                  </div>
                </MenuItem>
                <div className="group flex h-12 w-full items-center gap-3 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 -ml-1">
                  <div className="flex align-center arrow_more">
                    <Switch
                      checked={enabled}
                      onChange={handleSwitchChange}
                      className="group relative flex h-8 w-14 flex align-center cursor-pointer rounded-full bg-white/10 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10"
                    >
                      <span
                        aria-hidden="true"
                        className="pointer-events-none inline-block size-5 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
                      />
                    </Switch>
                  </div>
                  <div className="w-full text-left select-none">
                    Enable dark mode
                  </div>
                </div>
                <hr className="line_sm" />
                <MenuItem>
                  <a
                    href="/"
                    target="_blank"
                    className="group flex h-12 w-full items-center gap-3 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 hover"
                  >
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">help</span>
                    </div>
                    <div className="w-full text-left">Get help</div>
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">
                        arrow_outward
                      </span>
                    </div>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="/"
                    target="_blank"
                    className="group flex h-12 w-full items-center gap-3 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 hover"
                  >
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">help</span>
                    </div>
                    <div className="w-full text-left">Send feedback</div>
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">
                        arrow_outward
                      </span>
                    </div>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="/"
                    target="_blank"
                    className="group flex h-12 w-full items-center gap-3 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 hover"
                  >
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">help</span>
                    </div>
                    <div className="w-full text-left">About VibeIn</div>
                    <div className="flex align-center arrow_more">
                      <span className="material-icons-round">
                        arrow_outward
                      </span>
                    </div>
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
      ) : (
        <div className="px-4 flex align-center headers border-none justify-end">
          <div
            onClick={() => setModal("login")}
            className="h-10 hover w-auto px-4 mr-3 rounded-full flex align-center justify-center"
          >
            <div className="font-medium">Login</div>
          </div>
          <div
            onClick={() => setModal("signup")}
            className="h-10 hover w-auto px-4 mr-3 rounded-full flex align-center justify-center"
          >
            <div className="font-medium">Sign Up</div>
          </div>
          {component}
        </div>
      )}
    </>
  );
}

export default TopUserMenu;
