import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import FollowList from "./components/FollowList";
import JoinHubList from "./components/JoinHubList";
import {
  savePostToIndexedDB,
  getPostFromIndexedDB,
  removePostFromIndexedDB,
  getHubAccessList,
  getPageAccessList,
} from "../db"; // Import IndexedDB functions
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import EmotionPicker from "./components/EmotionPicker";
import app from "../config/app";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  GlobeAltIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/16/solid";
import Loader from "./components/Loader";
import Responce from "./components/Responce";
import FollowListReused from "./components/FollowListReused";
import CopyRight from "./components/CopyRight";
import TextContentInput from "./components/TextContentInput";
import SelectHubs from "./modals/SelectHubs";
import SelectPages from "./modals/SelectPages";

const gifImages = [
  {
    id: 1,
    url: "/images/dummy/200 (1).webp",
    alt: "",
    category: "",
  },
];

const publicity = [
  { id: 1, name: "Everyone", value: "everyone", icon: <GlobeAltIcon /> },
  { id: 2, name: "Friends", value: "friends", icon: <UsersIcon /> },
  { id: 3, name: "Only me", value: "only_me", icon: <UserIcon /> },
  { id: 4, name: "Hub", value: "hub", icon: <ChevronRightIcon /> },
  {
    id: 5,
    name: "Business page",
    value: "page",
    icon: <ChevronRightIcon />,
  },
];

function CreatePost() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [audioFiles, setAudioFiles] = useState([]);
  const [post, setPost] = useState([]);
  const [selectedPublicity, setPublicitySelected] = useState(publicity[0]);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [content, setContent] = useState("");
  const [mentions, setMentions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [minimzePoll, setMinimizePoll] = useState(true);
  const [showGifs, setShowGifs] = useState(false);
  const [displayEmoji, setDisplayEmoji] = useState(false);

  // handle images when selectedPublicity
  const [postedImage, setPostedImage] = useState([]);
  const [postedVideo, setPostedVideo] = useState([]);
  const [postedAudio, setPostedAudio] = useState([]);
  const [selectedGif, setSelectedGif] = useState(null);
  const [loadingInbox, setLoadingInbox] = useState(false);
  const [showHubSelector, setShowHubSelector] = useState(false);
  const [showPageSelector, setShowPageSelector] = useState(false);
  const [publicityHub, setPublicityHub] = useState("");
  const [publicityHubName, setPublicityHubName] = useState("");
  const [publicityPage, setPublicityPage] = useState("");
  const [publicityPageName, setPublicityPageName] = useState("");
  const [hubs, setHubs] = useState([]);
  const [publicityName, setPublicityName] = useState("Everyone");
  const [clear, setClear] = useState(false); // To trigger content clearing
  let { name } = useParams();

  const contentEditableRef = useRef(null);
  const postJobTo = [
    { id: 1, name: "VibeIn Developers", value: "7857847574" },
    { id: 2, name: "Microsoft For Busines", value: "49343094000" },
  ];

  // manage polls
  const [showPoll, setShowPoll] = useState(false);
  const [pollTitle, setPollTitle] = useState("");
  const [pollOptions, setPollOptions] = useState(["", ""]);

  useEffect(() => {
    fetchHubs();
    fetchPages();
  }, []);

  const fetchHubs = async () => {
    const hubList = await getHubAccessList(app.current_user, 30);
    console.log("Access list -- >", hubList);
    setHubs(hubList.hubs || []);
  };

  const fetchPages = async () => {
    const hubList = await getPageAccessList(app.current_user, 30);
    console.log("Pages list -- >", hubList);
    setHubs(hubList.hubs || []);
  };

  const handleTogglePoll = () => {
    setShowPoll(!showPoll);
  };

  const handlePollTitleChange = (e) => {
    setPollTitle(e.target.value);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...pollOptions];
    newOptions[index] = value;
    setPollOptions(newOptions);
  };

  const handleAddOption = () => {
    setPollOptions([...pollOptions, ""]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...pollOptions];
    newOptions.splice(index, 1);
    setPollOptions(newOptions);
  };

  const [formData, setFormData] = useState({
    content: "",
    publicity: selectedPublicity,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    const base64Images = await Promise.all(
      files.map((file) => convertToBase64(file))
    );
    setPostedImage((prev) => [...prev, ...base64Images]);
  };

  const handleRemoveImage = (index) => {
    setPostedImage((prev) => prev.filter((_, i) => i !== index));
  };

  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);
    const base64Videos = await Promise.all(
      files.map((file) => convertToBase64(file))
    );
    setPostedVideo((prev) => [...prev, ...base64Videos]);
  };

  const handleRemoveVideo = (index) => {
    setPostedVideo((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAudioChange = async (e) => {
    const files = Array.from(e.target.files);
    const base64Audios = await Promise.all(
      files.map((file) => convertToBase64(file))
    );
    setPostedAudio((prev) => [...prev, ...base64Audios]);
  };

  const handleRemoveAudio = (index) => {
    setPostedAudio((prev) => prev.filter((_, i) => i !== index));
  };

  const start_audio_recording = () => {
    // start_audio_recording
  };

  const handleSelectGif = (gifUrl) => {
    setSelectedGif(gifUrl);
  };

  const handleClearSelection = () => {
    setSelectedGif(null);
  };

  // manage post details submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingInbox(true);
    const newPost = {
      id: app.current_user + "@" + Date.now(),
      activity_id: app.current_user + "@" + Date.now(),
      content: content,
      mentions: mentions,
      topics: topics,
      user_id: app.current_user,
      publicity: selectedPublicity.value,
      pollTitle: pollTitle,
      pollOptions: pollOptions,
      parent_post: "",
      publicity_page: publicityPage,
      contain_media: "",
      publicity_timeline: "",
      publicity_hub: publicityHub,
      parent_post: "",
      uploads: {
        images: postedImage,
        videos: postedVideo,
        audios: postedAudio,
        gifs: selectedGif,
      },
    };

    const save_res = await savePostToIndexedDB(newPost);
    if (save_res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: save_res.responce, status: save_res.r_status }}
        />
      );
      // console.log(responce_card);
    }
    console.log("Post res", save_res);
    setPost([...post, newPost]);
    // clear inputs
    setContent("");
    clearText();
    setFormData({
      content: "",
      publicity: publicity[0],
    });
    setPublicitySelected(publicity[0]);
    setPollTitle("");
    setPostedImage([]);
    setPostedVideo([]);
    setPostedAudio([]);
    setPollOptions([]);
    setSelectedGif(null);
    setLoadingInbox(false);
  };

  // manage publicity change
  const updatePublicity = (item) => {
    setPublicityName(item.name);
    if (item.value == "hub") {
      setShowHubSelector(true);
      navigate("/createpost/selecthub");
    } else if (item.value == "page") {
      setShowPageSelector(true);
      navigate("/createpost/selectpage");
    } else {
      setPublicityPage("");
      setPublicityHub("");
    }
    // setFormData().publicity = value;
  };

  const handleTextChange = (content) => {
    setContent(content.text);
    setMentions(content.mentions);
    setTopics(content.topics);
  };

  const clearText = () => {
    // contentEditableRef.current.innerHTML = "";
    // setContent("");
    setClear(true); // Trigger clear
  };

  const hubSelected = (hub) => {
    console.log("Hub seleted", hub);
    setPublicityHub(hub.hub_id || "");
    setPublicityName(hub.hub_name || "");
    setPublicityPage("");
  };

  const pageSelected = (page) => {
    console.log("Hub seleted", page);
    setPublicityPage(page.page_id || "");
    setPublicityName(page.business_name || "");
    setPublicityHub("");
  };

  const toggleEmoji = () => {
    // toggleEmoji container
    setDisplayEmoji(!displayEmoji);
  };

  return (
    <>
      <div className="create_post_pg w-full">
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-full"
        >
          <div className="flex-1 w-full">
            <div className="px-4 flex align-center headers">
              <div
                className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
                onClick={() => navigate(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5f6368"
                >
                  <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
                </svg>
              </div>
              <div className="h-10 w-full mr-3 rounded-full align-center flex justify-between">
                <div className="font-bold text-xl">Create a post</div>
                <button
                  className={`btn ${loadingInbox && "opacity-40"}`}
                  type={loadingInbox ? "button" : "submit"}
                >
                  Post
                </button>
              </div>
            </div>
            <div className="p-4 relative sm-p-1">
              {loadingInbox && (
                <div className="absolute w-full h-full  form_overlay_create_post animate__animated animate__zoomIn animate_fast">
                  <div className="flex align-center justify-center py-4 flex-col gap-2">
                    <Loader />
                    <div className="text-lg">Posting...</div>
                    <div className="text-sm -mt-1">Do not close this page</div>
                  </div>
                </div>
              )}

              <div>
                <TextContentInput
                  onChange={handleTextChange} // Handle the content change
                  placeholder="Start a vibe 😉" // Custom placeholder text
                  clearText={clear} // Passing clearText trigger
                  onClearComplete={() => setClear(false)} // Reset the clear trigger
                  urlEnreachment={true}
                  topicEnreachment={true}
                  profileEnreachment={true}
                  showEmojiSelector={displayEmoji}
                  enReachmentPosittion="bottom"
                />
                <div>
                  <div className="my-3 mt-1 flex align-center justify-between gap-3">
                    {/* piblicity selector */}
                    <div className="flex align-center w-full gap-3">
                      <div className="flex align-center py-1 px-0 gap-3 border-1 text-sm rounded-xl h-10 w-fit hover">
                        <Listbox
                          value={selectedPublicity}
                          onChange={setPublicitySelected}
                        >
                          <ListboxButton
                            className={clsx(
                              "relative flex align-center w-full rounded-lg bg-white/5 py-1.5 px-3 text-left text-sm/6 ",
                              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 gap-4 justify-between"
                            )}
                          >
                            <div className="no-wrap max-w-24 ellipsis_text">
                              {publicityName}
                            </div>

                            <ChevronDownIcon
                              className="group pointer-events-none relative size-4 fill-white/60"
                              aria-hidden="true"
                            />
                          </ListboxButton>
                          <ListboxOptions
                            anchor="bottom"
                            transition
                            className={clsx(
                              "w-[var(--button-width)] rounded-xl border border-white/5 bg-white/5 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 dropdwon_container"
                            )}
                          >
                            {publicity.map((item, index) => (
                              <ListboxOption
                                key={item.name}
                                value={item}
                                onClick={() => updatePublicity(item)}
                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10 cursor-pointer justify-between"
                              >
                                <div className="text-sm/6 ">{item.name}</div>
                                {item.icon}
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </Listbox>
                      </div>

                      {/* add attachment */}
                      <div className=" bottom-0 py-2 flex align-center bg-match gap-2">
                        <div
                          onClick={() => toggleEmoji()}
                          className="flex align-center justify-center rounded-full h-10 w-10 min-w-10 min-h-10 w-fit hover float-right"
                        >
                          <div className="text-lg">😃</div>
                        </div>
                        <div
                          className="flex align-center"
                          style={{ maxWidth: "100vw", overflow: "auto" }}
                        >
                          <div className="flex align-center gap-2">
                            <label
                              htmlFor="imageFiles"
                              className="h-10 w-10 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                              >
                                <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z" />
                              </svg>
                            </label>
                            <input
                              type="file"
                              id="imageFiles"
                              name="imageFiles"
                              className="display-none"
                              multiple
                              accept="image/*"
                              onChange={handleImageChange}
                            />
                            <input
                              type="file"
                              id="videoFiles"
                              name="videoFiles"
                              className="display-none"
                              multiple
                              accept="video/*"
                              onChange={handleVideoChange}
                            />
                            <input
                              type="file"
                              id="audioFiles"
                              name="audioFiles"
                              className="display-none"
                              multiple
                              accept="audio/*"
                              onChange={handleAudioChange}
                            />
                            <label
                              htmlFor="videoFiles"
                              className="h-10 w-10 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-0 post_attachment"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#5f6368"
                              >
                                <path d="M10.8 15.9l4.67-3.5c.27-.2.27-.6 0-.8L10.8 8.1c-.33-.25-.8-.01-.8.4v7c0 .41.47.65.8.4zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                              </svg>
                            </label>

                            <div
                              onClick={() => (
                                setShowPoll(true), handleTogglePoll()
                              )}
                              className="h-10 w-10 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-0 post_attachment create_poll"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#5f6368"
                              >
                                <path d="m654-313 142-142q12-12 28-11.5t28 12.5q11 12 11 28t-11 28L683-228q-12 12-28 12t-28-12l-86-86q-11-11-11-28t11-28q11-11 28-11t28 11l57 57Zm0-320 142-142q12-12 28-11.5t28 12.5q11 12 11 28t-11 28L683-548q-12 12-28 12t-28-12l-86-86q-11-11-11-28t11-28q11-11 28-11t28 11l57 57ZM120-280q-17 0-28.5-11.5T80-320q0-17 11.5-28.5T120-360h280q17 0 28.5 11.5T440-320q0 17-11.5 28.5T400-280H120Zm0-320q-17 0-28.5-11.5T80-640q0-17 11.5-28.5T120-680h280q17 0 28.5 11.5T440-640q0 17-11.5 28.5T400-600H120Z" />
                              </svg>
                            </div>

                            {/* <div
                            onClick={() => {
                              showGifs ? setShowGifs(false) : setShowGifs(true);
                            }}
                            className="h-10 w-10 hover rounded-full animate__animated animate__zoomIn animate_fast align-center flex justify-center ml-0 post_attachment create_poll"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="#5f6368"
                            >
                              <path d="M340-400h40q17 0 28.5-11.5T420-440v-40h-40v40h-40v-80h80q0-17-11.5-28.5T380-560h-40q-17 0-28.5 11.5T300-520v80q0 17 11.5 28.5T340-400Zm120 0h40v-160h-40v160Zm80 0h40v-60h60v-40h-60v-20h80v-40H540v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
                            </svg>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {showEmojiPicker && <EmotionPicker />}

                {/* poll creation */}

                {showPoll && (
                  <div className="mb-4">
                    <label
                      htmlFor="pollTitle"
                      className="block font-semibold mb-2 flex align-center justify-between"
                    >
                      <div>Create a poll</div>
                      <button
                        type="button"
                        className="hover w-10 h-10 rounded-full flex align-center justify-center"
                        onClick={handleTogglePoll}
                      >
                        <span class="material-symbols-outlined">close</span>
                      </button>
                    </label>
                    <input
                      type="text"
                      id="pollTitle"
                      className="w-full p-2 border border-gray-300 rounded-xl form-control"
                      value={pollTitle}
                      onChange={handlePollTitleChange}
                      placeholder="Just a simple title"
                      style={{ display: "none" }}
                    />
                    <label
                      className="block font-semibold mt-4 mb-2"
                      style={{ display: "none" }}
                    >
                      Poll Options
                    </label>
                    {pollOptions.map((option, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <input
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded-xl form-control"
                          placeholder={`Option ${index + 1}`}
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                        />
                        {index > 0 && (
                          <button
                            type="button"
                            className="ml-2 text-red-500 hover:text-red-600 w-12 min-w-12 h-12 min-h-12 flex align-center justify-center bg-gray-100 rounded-xl"
                            onClick={() => handleRemoveOption(index)}
                          >
                            <XMarkIcon />
                          </button>
                        )}
                      </div>
                    ))}
                    <div
                      className="hover:bg-gray-100 mt-6 border-1 py-1 px-4 rounded-lg text-sm w-28 no-wrap hover flex align-center justify-center"
                      onClick={handleAddOption}
                    >
                      Add Option
                    </div>
                    <hr className="line" />
                  </div>
                )}
                {/* gif selector */}
                <div
                  className={
                    showGifs ? "my-3 mt-0 py-3 pt-0 w-full" : "display-none"
                  }
                >
                  <div className="flex align-center justify-between gap-2 py-3">
                    <input
                      className="w-full rounded-full border-1 px-3 h-12 py-0"
                      placeholder="Search a gif"
                    />
                  </div>
                  <div className="grid grid-cols-4 gap-2 justify-between">
                    {gifImages.map((gif, index) => (
                      <div
                        key={index}
                        className="relative cursor-pointer"
                        onClick={() => handleSelectGif(gif.url)}
                      >
                        <img
                          src={gif.url}
                          alt={gif.alt}
                          className="w-full h-full min-h-20 rounded-xl object-cover"
                        />
                        {selectedGif === gif.url && (
                          <div className="absolute inset-0 bg-black opacity-50 rounded-xl"></div>
                        )}
                        {selectedGif === gif.url && (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <p className=" text-xl font-bold">
                              <CheckCircleIcon />
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="medi_list flex align-center gap-2">
                  {selectedGif && (
                    <div className="w-40 max-w-40 h-40 max-h-40  mt-4 relative w-auto">
                      <img
                        src={selectedGif}
                        className="w-40 h-40 rounded-xl object-cover"
                      />
                      <button
                        onClick={handleClearSelection}
                        className="absolute top-2 right-2 bg-red-500  rounded-full p-1 remove_data"
                      >
                        <XMarkIcon />
                      </button>
                    </div>
                  )}

                  {/* display media files selectedPublicity */}
                  {/* images */}
                  {postedImage.length > 0 && (
                    <div className="flex overflow-x-hidden gap-2 w-full py-4 flex-wrap media_selector_list">
                      {postedImage.map((item, index) => (
                        <div
                          key={index}
                          className="w-40 min-w-40 h-40 rounded-xl hover relative single_item"
                        >
                          <img
                            src={item}
                            className="w-40 min-w-40 h-40 rounded-xl object-cover"
                          />
                          <button
                            className="absolute top-2 right-2 bg-red-500  rounded-full p-1 remove_data"
                            onClick={() => handleRemoveImage(index)}
                          >
                            <XMarkIcon />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  {postedVideo.length > 0 && (
                    <div className="flex overflow-x-hidden gap-2 w-full py-4 flex-wrap">
                      {postedVideo.map((item, index) => (
                        <div
                          key={index}
                          className="w-40 min-w-40 h-40 rounded-xl hover relative"
                        >
                          <video
                            src={item}
                            className="w-40 min-w-40 h-40 rounded-xl object-cover"
                            controls
                          />
                          <button
                            className="absolute top-2 right-2 bg-red-500  rounded-full p-1 remove_data"
                            onClick={() => handleRemoveVideo(index)}
                          >
                            <XMarkIcon />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {postedAudio.length > 0 && (
                  <div className="flex flex-col gap-2 w-full py-4 flex-wrap">
                    {postedAudio.map((item, index) => (
                      <div
                        key={index}
                        className="w-full hover relative flex items-center justify-between p-2 rounded-full"
                      >
                        <audio src={item} controls className="w-full" />
                        <button
                          className="bg-red-500  rounded-full p-1 ml-2"
                          onClick={() => handleRemoveAudio(index)}
                        >
                          <XMarkIcon />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                {showResponceCard && responceCard}
              </div>
            </div>
          </div>
        </form>

        {name && name == "selecthub" && (
          <SelectHubs
            onCloseModal={() => navigate("/createpost")}
            onSelectHub={(item) => hubSelected(item)}
          />
        )}

        {name && name == "selectpage" && (
          <SelectPages
            onCloseModal={() => navigate("/createpost")}
            onSelectPage={(item) => pageSelected(item)}
          />
        )}
      </div>

      {window.innerWidth > app.mobile_width ? (
        <div className="min-h-screen flex bg-white" class="right_cont">
          <TopUserMenu />
          <div className="p-4">
            <FollowListReused />
            <hr className="line" />
            <div className="mt-7 mb-16">
              <CopyRight />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default CreatePost;
