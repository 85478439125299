import React from "react";

function CopyRight({ showLess = false }) {
  return (
    <div className="text-sm footer_copy_right">
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        About VibeIn
      </a>
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        Terms & conditions
      </a>
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        Privacy policy
      </a>
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        Advertise
      </a>
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        Cookies
      </a>
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        Get help
      </a>
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        Send feedback
      </a>
      <a href="" target="_blank" className="px-1 text-mute text-sm">
        Content policy
      </a>
      {!showLess && (
        <>
          <span className="px-1 text-mute text-sm">VibeIn © 2024 By</span>
          <a href="" target="_blank" className="px-1 no_after text-mute">
            Silva Valley Technologies
          </a>
          <span className="px-1 text-mute text-sm">in partnership with </span>
          <a href="" target="_blank" className="px-1 no_after text-mute pl-">
            Embu College
          </a>
        </>
      )}
    </div>
  );
}

export default CopyRight;
