import React, { useEffect, useState } from "react";
import NotificationItem from "../components/NotificationItem";
import { getUserNotificationList } from "../../db";
import Loader from "../components/Loader";

function NotificationsHubs({ local }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchnotifications();
  }, []);

  const fetchnotifications = async () => {
    const res = await getUserNotificationList("hub");
    console.log("Notify results", res);
    setNotifications(res.data);
    setLoading(false);
  };

  return (
    <div>
      {notifications.length > 0 &&
        notifications.map((item, index) => <NotificationItem data={item} />)}

      {loading && (
        <div className="py-10 mt-20 w-full align-center justify-center flex">
          <Loader />
        </div>
      )}
      {notifications.length == 0 && !loading && (
        <div className="w-full py-4 mt-20 flex align-center justify-center px-3 flex-column">
          <img
            src="/images/coworking-woman-doing-online-shopping-at-home.gif"
            className="w-48 object-contain"
          />
          <div className="text-xl font-semibold">No hub notifications</div>
        </div>
      )}
    </div>
  );
}

export default NotificationsHubs;
