import React, { useEffect, useState } from "react";
import { countries } from "../../../config/countries";
import {
  getBusinessProfile,
  getPageAccessList,
  updateBusinessProfileInfo,
  updateLocalStorage,
} from "../../../db";
import Responce from "../../components/Responce";
import { useParams } from "react-router-dom";
import app from "../../../config/app";

function Profile({ local, business, updateLocal }) {
  const [businessProfile, setBusinessProfile] = useState();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { id, page, focus } = useParams();
  const [formData, setFormData] = useState({
    business_name: "",
    founded: "",
    company_size: "",
    bio_only_html: "",
  });

  const companySize = [
    "Just me",
    "1 - 10",
    "10 - 50",
    "50 - 120",
    "120 - 500",
    "500 - 1000",
    "More than 1000",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const info = await getBusinessProfile(id);
      console.log("Business info - > ", info?.business_info[0]);
      setBusinessProfile(info?.business_info[0]);
      if (info.r_status) {
        setFormData({
          business_name: info?.business_info[0].business_name,
          founded: info?.business_info[0].founded,
          company_size: info?.business_info[0].company_size,
          bio_only_html: info?.business_info[0].bio_only_html,
        });
      }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await updateBusinessProfileInfo(id, formData);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      // console.log(responce_card);
    }
    const list = await getPageAccessList(app.current_user, 30);
    updateLocalStorage({ type: "updatepage", data: list });
    updateLocal(res);
    console.log("Form submitted res:", res);
  };

  return (
    <div className="p-3 sm-p-0">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Business profile</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5">
          {/* inputs */}
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="name">
                Business name
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">alternate_email</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="business_name"
                  id="business_name"
                  value={formData.business_name}
                  onChange={handleChange}
                  placeholder="eg. John Doe"
                />
              </div>
            </div>
          </div>
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="founded">
                Founded year
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">today</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="founded"
                  id="founded"
                  value={formData.founded}
                  onChange={handleChange}
                  placeholder="eg. 2024"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="founded">
                Company size
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">today</span>
                </div>

                <select
                  name="company_size"
                  id="company_size"
                  className="form-control w-full relative has_icon"
                  value={formData.company_size}
                  onChange={handleChange}
                >
                  <optgroup label="Change">
                    {companySize.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </optgroup>
                </select>
              </div>
            </div>
          </div>

          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="bio_only_html">
                Short description about your business
              </label>
              <div className="flex align-center relative mt-3">
                <textarea
                  rows={5}
                  className="form-control w-full relative min-h-40 pt-4 px-3"
                  name="bio_only_html"
                  id="bio_only_html"
                  value={formData.bio_only_html}
                  onChange={handleChange}
                  placeholder="Write something"
                ></textarea>
              </div>
            </div>
          </div>
          {/* submit the form */}
          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      {showResponceCard && responceCard}
    </div>
  );
}
export default Profile;
