import React, { useRef, useState, useEffect } from "react";

const VideoPlayer = ({ thumbnailSrc, videoSrc }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Default to muted for autoplay
  const [volume, setVolume] = useState(1);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [ended, setEnded] = useState(false);

  const [videoBlob, setVideoBlob] = useState("");

  // Event listener for fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange); // Safari
    document.addEventListener("mozfullscreenchange", handleFullscreenChange); // Firefox
    document.addEventListener("MSFullscreenChange", handleFullscreenChange); // IE/Edge

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  // Convert video URL to blob when component mounts
  useEffect(() => {
    const fetchVideoBlob = async () => {
      try {
        const response = await fetch(videoSrc);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        console.log("Blob link", blobUrl);
        setVideoBlob(blobUrl); // Set blob URL as video source
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };

    fetchVideoBlob();
    return () => {
      // Cleanup: Revoke the blob URL when the component is unmounted
      if (videoBlob) {
        URL.revokeObjectURL(videoBlob);
      }
    };
  }, [videoSrc]);

  // Autoplay and auto-pause on scroll into view (with Intersection Observer)
  useEffect(() => {
    const video = videoRef.current;

    const handleIntersection = ([entry]) => {
      if (entry.isIntersecting) {
        // Pause all other videos
        pauseAllVideosExcept(video);

        // Play this video and mute on autoplay
        video.play();
        setIsPlaying(true);
        video.muted = true; // Ensure autoplay is muted
        setIsMuted(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);

  // Ensure that only one video is playing at a time
  const pauseAllVideosExcept = (currentVideo) => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      if (video !== currentVideo) {
        video.pause();
      }
    });
  };

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const handleTimeUpdate = () => {
        if (video.duration) {
          setProgress((video.currentTime / video.duration) * 100);
        }
      };

      const handleLoadedMetadata = () => {
        setDuration(video.duration);
        setIsLoaded(true);
      };

      video.addEventListener("timeupdate", handleTimeUpdate);
      video.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }
  }, []);

  // Handle play/pause interaction
  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        // Pause all other videos before playing this one
        pauseAllVideosExcept(video);
        setEnded(false);
        video.play();

        // Unmute the video when the user interacts with it by clicking to play
        video.muted = false;
        setIsMuted(false);
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Handle volume change interaction
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    const video = videoRef.current;
    if (video) {
      video.volume = newVolume;
      setVolume(newVolume);

      // Unmute the video if the user adjusts the volume
      if (newVolume > 0) {
        video.muted = false;
        setIsMuted(false);
      } else {
        video.muted = true;
        setIsMuted(true);
      }
    }
  };

  // Handle manual mute/unmute toggle
  const handleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleProgressChange = (e) => {
    const newProgress = e.target.value;
    const video = videoRef.current;
    if (video) {
      video.currentTime = (newProgress / 100) * duration;
      setProgress(newProgress);
    }
  };

  const handleFullscreen = () => {
    const container = containerRef.current;

    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      setIsMuted(false);
      setIsPlaying(true);
      setEnded(false);
      const video = videoRef.current;
      video.play();
      video.volume = 1;
      if (container) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.mozRequestFullScreen) {
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          container.msRequestFullscreen();
        }
      }
    }
  };

  // Automatically set playing to false when the video ends
  const handleVideoEnd = () => {
    setIsPlaying(false);
    setEnded(true);
  };

  // Helper function to format time to hh:mm:ss
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    const paddedMins = mins.toString().padStart(2, "0");
    const paddedSecs = secs.toString().padStart(2, "0");

    if (hrs > 0) {
      const paddedHrs = hrs.toString().padStart(2, "0");
      return `${paddedHrs}:${paddedMins}:${paddedSecs}`;
    } else {
      return `${paddedMins}:${paddedSecs}`;
    }
  };

  // Add keyboard controls for fullscreen playback
  useEffect(() => {
    const handleKeyDown = (e) => {
      const video = videoRef.current;
      if (!video) return;
      switch (e.key) {
        case " ": // Spacebar: Play/pause the video
          e.preventDefault();
          handlePlayPause();
          break;
        case "ArrowLeft": // Fast rewind
        case "<": // Fast rewind
        case ",": // Handle alternate '<' input (comma)
          video.currentTime = Math.max(0, video.currentTime - 5); // Rewind by 10 seconds
          break;
        case "ArrowRight": // Fast forward
        case ">": // Fast forward
        case ".": // Handle alternate '>' input (period)
          video.currentTime = Math.min(video.duration, video.currentTime + 5); // Fast-forward by 10 seconds
          break;
        case "ArrowUp": // Volume up
          video.volume = Math.min(1, video.volume + 0.1);
          setVolume(video.volume);
          break;
        case "ArrowDown": // Volume down
          video.volume = Math.max(0, video.volume - 0.1);
          setVolume(video.volume);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPlaying]);

  return (
    <div
      className={`custom-video-player relative video-container ${
        isFullscreen ? "fullscreen" : ""
      }`}
      ref={containerRef}
    >
      {!isLoaded && (
        <img src={thumbnailSrc} className="thumbnail" alt="Thumbnail" />
      )}

      <video
        ref={videoRef}
        className="video"
        src={videoSrc}
        controls={false}
        onEnded={handleVideoEnd}
        onClick={handlePlayPause}
        onLoadedData={() => setDuration(videoRef.current.duration)}
        onContextMenu={(e) => e.preventDefault()} // Disable right-click
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="controls w-full flex align-center">
        <div className="flex align-center justify-between flex align-center w-full">
          <div className="flex align-center gap-0 mr-1">
            <button
              className="w-8 h-8 flex align-center justify-center hover rounded-full"
              onClick={handlePlayPause}
            >
              {!isPlaying ? (
                <>
                  {!ended ? (
                    <span className="material-icons-round ">play_arrow</span>
                  ) : (
                    <span className="material-symbols-outlined">replay</span>
                  )}
                </>
              ) : (
                <span className="material-icons-round ">pause</span>
              )}
            </button>
            <div className="relative flex align-center justify-center sound_controler">
              <button
                className="w-8 h-8 flex align-center justify-center hover rounded-full"
                onClick={handleMute}
              >
                {isMuted ? (
                  <span
                    className="material-symbols-outlined "
                    style={{ fontSize: 18 }}
                  >
                    volume_off
                  </span>
                ) : (
                  <span
                    className="material-symbols-outlined "
                    style={{ fontSize: 18 }}
                  >
                    volume_up
                  </span>
                )}
              </button>
              <div className="input_vol">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="volume-slider "
                  aria-label="Volume Control"
                />
              </div>
            </div>
          </div>

          <input
            type="range"
            min="0"
            max="100"
            step="0.1"
            value={progress}
            onChange={handleProgressChange}
            className="progress-slider flex-1"
            aria-label="Progress Control"
          />

          <span className="time-display" style={{ fontSize: 12 }}>
            {formatTime(videoRef.current?.currentTime || 0)} /{" "}
            {formatTime(duration)}
          </span>

          <button
            className="w-8 h-8 flex align-center justify-center hover rounded-full"
            onClick={handleFullscreen}
          >
            <span className="material-icons-round m-0">fullscreen</span>
          </button>
        </div>
      </div>

      {!isPlaying && (
        <div
          onClick={handlePlayPause}
          className="absolute top-0 bottom-0 left-0 right-0 m-auto flex align-center justify-center rounded-full z-10 w-14 h-14 vibein_play_container"
        >
          <span
            className="material-icons-round vibein_play_icon"
            style={{ fontSize: 45 }}
          >
            play_arrow
          </span>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
