import React, { useEffect, useState } from "react";
import HubHorizontal from "./HubHorizontal";
import { getHubToJoinList } from "../../db";
import { Link } from "react-router-dom";

function JoinHubsListReused() {
  const [hubList, setHubList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchHubsListData();
  }, []);

  const fetchHubsListData = async () => {
    try {
      const data = await getHubToJoinList(5, 0);
      console.log("Hubs - > ", data);
      setHubList(data.hubs);
      setLoading(false);
    } catch (error) {
      console.error("Vibe:", error);
    }
  };

  return (
    <div>
      <div className="flex align-center justify-center mb-4">
        <div className="w-full rounded-full mr-10 align-center justify-center font-semibold">
          Communities to join
        </div>
        <Link
          to={"/communitiestaxonimy/popular-hubs"}
          className="h-8 flex align-center justify-center w-40 hover rounded-full text-center break-keep "
        >
          See more
        </Link>
      </div>
      {hubList.length > 0 &&
        hubList.map((item, index) => (
          <HubHorizontal simple={true} data={item} />
        ))}
    </div>
  );
}

export default JoinHubsListReused;
