import React, { useEffect, useState } from "react";
import app from "../../../config/app";
import { XMarkIcon } from "@heroicons/react/16/solid";

function ServiceArea() {
  const [selectedPlaces, setSelectedPlaces] = useState("");
  const [filter, setFilter] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const system_places = app.businessCategories;

  useEffect(() => {}, []);

  const handlePlacesUpdate = (places) => {
    setSelectedPlaces(places);
  };

  const addServiceArea = () => {
    // add service area and save
  };

  const filteredPlaces = system_places.filter((places) =>
    places.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="p-0 pt-3">
      <div className="mb-4 flex align-center justify-between">
        <div className="text-xl font-semibold">Service area settings</div>
        <button type="button" className="btn">
          {isSaving ? "Saving..." : "Save"}
        </button>
      </div>

      <div className="px-0 py-3 pt-0">Enter town, region or country</div>
      <div className="places_list_selected flex align-start justify-start flex-wrap">
        <div className="border-1 rounded-full flex align-center justify-between w-auto">
          <div className="px-3">Selected 1</div>
          <div className="remove_place w-8 h-8 bg-gray-100 hover:shadow-lg hover rounded-full flex align-center justify-center hover">
            <XMarkIcon />
          </div>
        </div>
      </div>
      <div className="sticky tags_filter py-2 flex align-center justify-center gap-3 mb-4">
        <input
          type="text"
          placeholder="Search or add places ..."
          className="p-2 form-control rounded-xl w-full px-6"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <div
          className="min-w-14 min-h-14 rounded-xl bg-gray-50 flex align-center justify-center hover cursor-pointer"
          onClick={() => addServiceArea()}
        >
          <span className="material-icons-round">add</span>
        </div>
      </div>

      <div className="overflow-hidden overflow_list_cont">
        <div className="category_list border-1 rounded-2xl px-2 py-2">
          {filteredPlaces.map((places, index) => (
            <div
              key={index}
              onClick={() => handlePlacesUpdate(places)}
              className={`p-3 cursor-pointer ${
                selectedPlaces === places
                  ? "active bg-gray-200 select_list rounded-xl text-black"
                  : ""
              }`}
            >
              {places}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServiceArea;
