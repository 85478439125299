import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import ProfileDetails from "./Chat/ProfileDetails";
import MessagesList from "./Chat/MessagesList";
import SearchMessages from "./Chat/SearchMessages";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import ChatInboxDummy from "./components/Dummies/ChatInboxDummy";
import EmojiSelector from "./components/EmojiSelector";
import axios from "axios";
import app from "../config/app";
import {
  createConversation,
  clearConversationFromIndexedDB,
  getChatsFromIndexedDB,
  getInboxFromIndexedDB,
  getInboxList,
  getUserFromConversationIndexedDB,
  getUserProfileFromConversationIndexedDB,
  saveConversationIdByUser,
  saveUserMetaIndexedDB,
  searchFriends,
  sendMessage,
  archiveUserMsg,
  pinUserMsg,
  muteUserMsg,
  blockUserMsg,
  getArchivedUserMsg,
  getMutedUserMsg,
  getBlockedUserMsg,
  getPinnedUserMsg,
  getChatConversationsRemote,
  insertMessage,
} from "../db";
import Loader from "./components/Loader";

function Messages() {
  const navigate = useNavigate();
  const location = useLocation();
  let currentPath = location.pathname;
  let { chatid, activity, focus, tab } = useParams();
  const [selection, setSelection] = useState({ start: 0, end: 0 }); // State to hold selection
  const [showProfileDetails, setShowProfileDetails] = useState(false);
  const [showMessages, setShowMessages] = useState(true);
  const [showAudioCallModal, setShowAudioCallModal] = useState(false);
  const [showVideoCallModal, setShowVideoCallModal] = useState(false);
  const [micMute, setMicMute] = useState(false);
  const [muteNotification, setMuteNotification] = useState(false);
  const [gridView, setGridView] = useState(false);
  const [searchPeople, setSearchPeople] = useState(false);
  const [showEmojiSelector, setShowEmojiSelector] = useState(false);
  const [message, setMessage] = useState("");
  const [searchPeopleText, setSearchPeopleText] = useState("");
  const [searchInboxText, setSearchInboxText] = useState("");
  const [minimizeMediaSelector, setMinimizeMediaSelector] = useState(false);
  const [reciever, setReciever] = useState("");
  const [recieverProfile, setRecieverProfile] = useState({});
  const [loadingMessages, setLoadingMessages] = useState(true);

  // file selects
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedAudios, setSelectedAudios] = useState([]);
  const [selectedGifs, setSelectedGifs] = useState([]);

  const [activeChatid, setActiveChatid] = useState("");
  const [showReplyMessage, setShowReplyMessage] = useState(false);
  const [showGifs, setShowGifs] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [gifs, setGifs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectMessages, setSelectMessages] = useState(false);
  const [showSelectedMedia, setShowSelectedMedia] = useState(false);
  const [usersSearchProfile, setUsersSearchProfile] = useState([]);
  const [inboxList, setInboxList] = useState([]);
  const [conversationData, setConversationData] = useState([]);
  const [loadingInbox, setLoadingInbox] = useState(true);

  const [archivedChats, setArchivedChats] = useState([]);
  const [pinnedChats, setPinnedChats] = useState([]);
  const [mutedChats, setMutedChats] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);

  // Giphy API key and endpoint
  const apiKey = "laxVZurf2K2jfwS5Sa9M3JKtCdGSdJjG";
  const apiEndpoint = "https://api.giphy.com/v1/gifs/search";
  const updateConversationData = async () => {
    const data = await getChatsFromIndexedDB(chatid);
    setConversationData(data);
    const user_id = await getUserFromConversationIndexedDB(chatid);
    const user_prof = await getUserProfileFromConversationIndexedDB(user_id);
    let messages = await getChatsFromIndexedDB(chatid);
    // console.log("messages - ", messages);
    setConversationData(messages);
    if (chatid != "") {
      openConversation(chatid, user_id, user_prof);
    }
  };

  const loadInbox = async () => {
    const list = await getInboxFromIndexedDB();
    // add user profile to the inbox object
    let conv_list = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      const conversation_id = element.conversation_id;
      // get the user related to this conversation id
      console.log("=>", conversation_id);
      const this_user_id = await getUserFromConversationIndexedDB(
        conversation_id
      );
      // console.log("User data = >", this_user_id);
      if (this_user_id) {
        const user_info = await getUserProfileFromConversationIndexedDB(
          this_user_id
        );
        conv_list.push({
          conversation: element,
          user_info,
        });
      }
    }

    setLoadingInbox(false);
    setInboxList(conv_list);
    refreshChatAndUserStatus();
    setLoadingMessages(false);
    // console.log("Inboxing", conv_list);
  };

  useEffect(() => {
    document.body.classList.add("wide_body");
    document.body.classList.add("message_body");
    if (activity) {
      if (activity == "details") {
        setShowProfileDetails(true);
      }

      if (activity == "messages") {
        // setShowMessages(true);
      }

      setActiveChatid(chatid);
    } else {
      setActiveChatid("");
      // alert(chatid);
    }

    updateConversationData();
    loadInbox();

    return () => {
      document.body.classList.remove("wide_body");
      document.body.classList.remove("message_body");
    };
  }, [chatid, reciever]);

  const fetchGifs = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get(apiEndpoint, {
        params: {
          api_key: apiKey,
          q: query,
          limit: 10,
        },
      });
      setGifs(response.data.data);
    } catch (error) {
      // console.error("Error fetching GIFs:", error);
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      fetchGifs(e.target.value);
    } else {
      setGifs([]);
    }
  };

  const endAudioCall = () => {
    setShowAudioCallModal(false);
  };

  const minimizeAudioCall = () => {
    setShowAudioCallModal(false);
  };

  const muteAudioCall = () => {
    if (!micMute) {
      setMicMute(true);
    } else {
      setMicMute(false);
    }
  };

  const muteNotifications = () => {
    if (!muteNotification) {
      setMuteNotification(true);
    } else {
      setMuteNotification(false);
    }
  };

  const startAudioCall = () => {
    setShowAudioCallModal(true);
  };

  const startVideoCall = () => {
    setShowVideoCallModal(true);
  };

  const endVideoCall = () => {
    setShowVideoCallModal(false);
  };

  const minimizeVideoCall = () => {
    setShowVideoCallModal(false);
  };

  const handleEmojiSelected = (emoji) => {
    const newText =
      message.slice(0, selection.start) +
      emoji +
      message.slice(selection.end, message.length);
    setMessage(newText);
    setSelection({
      start: selection.start + emoji.length,
      end: selection.start + emoji.length,
    });
  };

  const replayMessage = () => {
    setShowReplyMessage(true);
  };

  // Convert file to Base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  const selectImageFile = async (event) => {
    const files = event.target.files;
    setMinimizeMediaSelector(false);
    // console.log("Event - >", event);
    if (files.length > 0) {
      const newSelectedImages = await Promise.all(
        Array.from(files).map(async (file) => {
          // Convert to Base64 for server submission
          const base64 = await convertToBase64(file);
          return {
            blobUrl: URL.createObjectURL(file),
            base64: base64,
            type: file.type,
            name: file.name,
            size: file.size,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
          };
        })
      );

      setSelectedImages((prevSelectedImages) => [
        ...prevSelectedImages,
        ...newSelectedImages,
      ]);
      setShowSelectedMedia(true);
    }
  };

  const selectVideoFile = async (event) => {
    const files = event.target.files;
    setMinimizeMediaSelector(false);

    if (files.length > 0) {
      const newSelectedVideos = await Promise.all(
        Array.from(files).map(async (file) => {
          // Convert to Base64 for server submission
          const base64 = await convertToBase64(file);

          // Generate video thumbnail
          const thumbnail = await generateVideoThumbnail(file);

          return {
            blobUrl: URL.createObjectURL(file), // Blob URL for display
            base64: base64, // Base64 for server submission
            thumbnail: thumbnail, // Base64 image of the video thumbnail
            type: file.type,
            name: file.name,
            size: file.size,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
          };
        })
      );

      setSelectedVideos((prevSelectedVideos) => [
        ...prevSelectedVideos,
        ...newSelectedVideos,
      ]);
      setShowSelectedMedia(true);
    }
  };

  // Function to generate the video thumbnail
  const generateVideoThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.muted = true;
      video.playsInline = true;

      video.addEventListener("loadeddata", () => {
        // Wait until enough data is loaded to capture a frame
        video.currentTime = 1; // Seek to 1 second in the video

        video.addEventListener("seeked", () => {
          // Create a canvas element to capture the frame
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a data URL (Base64 image)
          const thumbnail = canvas.toDataURL("image/jpeg");

          resolve(thumbnail);
        });
      });

      video.addEventListener("error", (error) => {
        reject(error);
      });
    });
  };

  const selectAudioFile = async (event) => {
    const files = event.target.files;
    setMinimizeMediaSelector(false);
    // console.log("Event - >", event);
    if (files.length > 0) {
      const newSelectedAudios = await Promise.all(
        Array.from(files).map(async (file) => {
          // Convert to Base64 for server submission
          const base64 = await convertToBase64(file);
          return {
            blobUrl: URL.createObjectURL(file), // Blob URL for display
            base64: base64, // Base64 for server submission
            type: file.type,
            name: file.name,
            size: file.size,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
          };
        })
      );

      setSelectedAudios((prevSelectedAudios) => [
        ...prevSelectedAudios,
        ...newSelectedAudios,
      ]);
      setShowSelectedMedia(true);
    }
  };

  const selectDocumentFile = async (event) => {
    const files = event.target.files;
    setMinimizeMediaSelector(false);
    // console.log("Event - >", event);
    if (files.length > 0) {
      const newSelectedDocuments = await Promise.all(
        Array.from(files).map(async (file) => {
          // Convert to Base64 for server submission
          const base64 = await convertToBase64(file);
          return {
            blobUrl: URL.createObjectURL(file), // Blob URL for display
            base64: base64, // Base64 for server submission
            type: file.type,
            name: file.name,
            size: file.size,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
          };
        })
      );

      setSelectedDocuments((prevSelectedDocuments) => [
        ...prevSelectedDocuments,
        ...newSelectedDocuments,
      ]);
      setShowSelectedMedia(true);
    }
  };

  const handleSend = async () => {
    if (
      message.trim() ||
      selectedImages.length > 0 ||
      selectedVideos.length > 0 ||
      selectedAudios.length > 0 ||
      selectedDocuments.length > 0
    ) {
      // Construct the message object
      const messageObj = {
        user_id: app.current_user,
        activity_id: Date.now().toString() + "@" + activeChatid,
        status: false,
        parent: "",
        last_modified: Date.now(),
        date_time: Date.now(),
        sender: app.current_user,
        chat_body: message.trim(),
        reciever: reciever,
        contain_media:
          selectedImages.length > 0 ||
          selectedVideos.length > 0 ||
          selectedAudios.length > 0 ||
          selectedDocuments.length > 0
            ? true
            : false,
        conversation_id: chatid,
        send_status: true,
        read_status: false,
        post_shared: null,
        time: Date.now(),
        uploads: {
          images: selectedImages,
          videos: selectedVideos,
          audios: selectedAudios,
          documents: selectedDocuments,
          gifs: selectedGifs,
        },
        draft_status: false,
        options: {},
      };

      // console.log(messageObj);
      loadInbox();
      updateConversationData();
      setMessage("");
      setShowSelectedMedia(false);
      setMinimizeMediaSelector(false);
      clearSelectedFiles();

      // Send the message using the sendMessage function
      const res = await sendMessage(messageObj, true);
      console.log("Send message responce ", res);
    }
  };

  const removeImageFromSelected = (index) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.filter((_, i) => i !== index)
    );
  };

  const removeVideoFromSelected = (index) => {
    setSelectedVideos((prevSelectedVideo) =>
      prevSelectedVideo.filter((_, i) => i !== index)
    );
  };

  const removeAudioFromSelected = (index) => {
    setSelectedAudios((prevSelectedAudio) =>
      prevSelectedAudio.filter((_, i) => i !== index)
    );
  };

  const removeDocumentFromSelected = (index) => {
    setSelectedDocuments((prevSelectedDocuments) =>
      prevSelectedDocuments.filter((_, i) => i !== index)
    );
  };

  const clearSelectedFiles = () => {
    setSelectedImages([]);
    setSelectedVideos([]);
    setSelectedDocuments([]);
    setSelectedAudios([]);
  };

  useEffect(() => {
    loadRemoteChats();
  }, []);

  const loadRemoteChats = async () => {
    const data = await getInboxList();
    await getChatsConversationsRemote();
    // console.log("Remote chats", data.inbox);
    for (let index = 0; index < data?.inbox?.length; index++) {
      const element = data.inbox[index];
      // update conversations in the indexed db
      for (
        let index_msg = 0;
        index_msg < element.messages.length;
        index_msg++
      ) {
        const message = element.messages[index_msg];
        await insertMessage(message, element.activity_id);
      }

      console.log("Conversation", element.activity_id);
    }
  };

  const searchRemoteUsers = async (text) => {
    const data = await searchFriends(text);
    setUsersSearchProfile(data.users);
    // save user in meta database for quick access
    for (let index = 0; index < data.users.length; index++) {
      const element = data.users[index];
      saveMeta(element.user_info[0]);
    }
    console.log("Remote users", data.users);
  };

  const saveMeta = async (profile) => {
    const res = await saveUserMetaIndexedDB(profile);
    // console.log("Save res", res);
  };

  const openConversation = async (chatid, other_user, user_info) => {
    console.log("User", chatid);
    setReciever(other_user);
    setRecieverProfile(user_info);
    if (other_user && other_user != "") {
      const res = await createConversation(chatid);
      const res_two = await saveConversationIdByUser(chatid, other_user);
    }
  };

  const messagesEndRef = useRef(null);
  // This useEffect will scroll the div to the bottom whenever the conversationData changes.
  useEffect(() => {
    scrollToBottom();
  }, [conversationData, inboxList]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const clearChat = async (conversation_id) => {
    const res = await clearConversationFromIndexedDB(conversation_id);
    updateConversationData();
    loadInbox();
    if (res) {
      console.log("Conversation deleted successfully.");
    } else {
      // console.log("Failed to delete conversation.");
    }
    // alert("Delete res", res);
  };

  const archiveChat = async (conversation_id, user_id) => {
    const res = await archiveUserMsg(conversation_id, user_id);
    refreshChatAndUserStatus();
    console.log("Archive res", res);
  };

  const pinChat = async (conversation_id, user_id) => {
    const res = await pinUserMsg(conversation_id, user_id);
    refreshChatAndUserStatus();
    console.log("Pin res", res);
  };

  const muteUser = async (conversation_id, user_id) => {
    const res = await muteUserMsg(conversation_id, user_id);
    refreshChatAndUserStatus();
    console.log("Mute res", res);
  };

  const blockUser = async (conversation_id, user_id) => {
    const res = await blockUserMsg(conversation_id, user_id);
    refreshChatAndUserStatus();
    console.log("Block res", res);
  };

  const refreshChatAndUserStatus = async () => {
    const arch = await getArchivedUserMsg();
    setArchivedChats(arch);
    const pinned = await getPinnedUserMsg();
    setPinnedChats(pinned);
    const muted = await getMutedUserMsg();
    setMutedChats(muted);
    const blocked = await getBlockedUserMsg();
    setBlockedUsers(blocked);
  };

  const getChatsConversationsRemote = async () => {
    const res = await getChatConversationsRemote();
    console.log("conversations list->>>>>", res);
    for (let index = 0; index < res?.conversations?.length; index++) {
      const element = res?.conversations[index];
      if (element.user_info[0]) {
        console.log("user_info", element.user_info[0]);
        await openConversation(
          element?.row?.activity_id,
          element?.other_user,
          element?.user_info[0]
        );
      }
    }
  };

  return (
    <>
      <div className="w-fill flex-1 flex align-start overflow-hidden border-left messages_cont">
        <div className="max-80 left_chat_content border-left">
          {/* left chat container */}
          <div className="px-4 flex align-center headers border-none gap-1">
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() =>
                !searchPeople ? navigate(-1) : setSearchPeople(false)
              }
            >
              {searchPeople ? (
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: 25 }}
                >
                  close
                </span>
              ) : (
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: 25 }}
                >
                  arrow_left_alt
                </span>
              )}
            </div>

            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">
                {!searchPeople ? "Messages" : "Start chat"}
              </div>
            </div>
            <div
              className="min-w-10 w-10 h-10 rounded-full justify-center flex align-center hover"
              onClick={() => loadRemoteChats()}
            >
              <span class="material-symbols-outlined" style={{ fontSize: 25 }}>
                refresh
              </span>
            </div>

            <div
              className="min-w-10 w-10 h-10 rounded-full justify-center flex align-center hover"
              onClick={() =>
                searchPeople ? setSearchPeople(false) : setSearchPeople(true)
              }
            >
              <span class="material-symbols-outlined" style={{ fontSize: 25 }}>
                chat_add_on
              </span>
            </div>

            <Menu>
              <MenuButton className="min-w-10 w-10 h-10 rounded-full justify-center flex align-center hover">
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: 25 }}
                >
                  more_vert
                </span>
              </MenuButton>
              <MenuItems
                transition
                anchor="bottom start"
                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
              >
                <MenuItem>
                  <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item ">
                    <span class="material-icons-round h-auto">check</span>
                    Mark all as read
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item ">
                    <span class="material-icons-round h-auto">
                      notifications
                    </span>
                    Mute notifications
                  </div>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>

          <div
            className={
              searchPeople
                ? "start_search animate__animated animate__slideInLeft animate_fast"
                : "start_search d-none"
            }
          >
            <div className="w-full relative p-3 my-3 mb-0">
              <span
                class="material-icons-round absolute top-4 right-5 m-auto h-auto z-10 close_search_btn hover w-8 h-8 text-center justify-center align-center flex rounded-full p-0 min-h-8"
                onClick={() => setSearchPeopleText("")}
              >
                {searchPeopleText == "" ? "search" : "close"}
              </span>
              <input
                className="form-control search_input w-full rounded-full pr-14 pl-4"
                placeholder="Search people"
                value={searchPeopleText}
                onChange={(e) => (
                  setSearchPeopleText(e.target.value),
                  searchRemoteUsers(e.target.value)
                )}
              />
            </div>
            {usersSearchProfile.length == 0 && (
              <div className="flex align-center justify-center flex-col">
                <img
                  src="/images/coworking-woman-doing-online-shopping-at-home.gif"
                  className="w-56 object-cover"
                />
                <div className=" my-4 w-56 text-center">
                  Search for friends that you follow or follow you
                </div>
              </div>
            )}

            {usersSearchProfile.length > 0 &&
              usersSearchProfile.map((item, index) => (
                <Link
                  to={`/Messages/${item.chatid}/messages`}
                  onClick={() => (
                    setShowProfileDetails(false),
                    setActiveChatid(item.chatid),
                    saveMeta(item.user_info[0]),
                    openConversation(
                      item.chatid,
                      item.user_info[0].user_id,
                      item.user_info[0]
                    ),
                    setSearchPeople(false)
                  )}
                  key={index}
                  className="flex align-center px-2 gap-1 hover py-2 mx-2 rounded-xl overflow-hidden inbox_item  animate__animated animate__slideInUp animate_fast"
                >
                  <div className="flex ">
                    <div className="h-12 min-w-12 rounded-full">
                      <img
                        src={item.user_info[0]?.profile_image}
                        className="h-12 min-w-12 rounded-full object-cover"
                      />
                    </div>
                    <div className="w-full px-3">
                      <div className="w-full rounded-full">
                        {item.user_info[0]?.fullname}
                      </div>
                      <div className=" w-1/3 rounded-full text-sm">
                        @{item.user_info[0]?.username}
                      </div>
                    </div>
                    <div className="flex align-start gap-2">
                      <div className="h-8 w-8 rounded-full"></div>
                    </div>
                  </div>
                </Link>
              ))}
            {/* <ChatInboxDummy /> */}
          </div>

          <div
            className={
              searchPeople
                ? "d-none"
                : "animate__animated animate__slideInLeft animate_fast"
            }
          >
            <div className="w-full relative p-3 my-3 mb-0">
              <span
                class="material-icons-round absolute top-4 right-5 m-auto h-auto z-10 close_search_btn hover w-8 h-8 text-center justify-center align-center flex rounded-full p-0 min-h-8"
                onClick={() => setSearchInboxText("")}
              >
                {searchInboxText == "" ? "search" : "close"}
              </span>
              <input
                className="form-control search_input w-full rounded-full pr-14 pl-4"
                placeholder="Search messages"
                value={searchInboxText}
                onChange={(e) => setSearchInboxText(e.target.value)}
              />
            </div>

            {/* inbox by users */}
            {loadingInbox && (
              <div className="flex align-center justify-center py-4">
                <Loader />
              </div>
            )}
            <div className="">
              {!loadingInbox && (
                <div className="flex align-center justify-between py-3 px-5">
                  <div className="text-mute">All messages</div>
                  <div
                    className="w-6 h-6 rounded-full bg-gray-100 hover"
                    onClick={() => refreshChatAndUserStatus()}
                  ></div>
                </div>
              )}

              {!loadingInbox && inboxList.length == 0 && (
                <div className="flex align-center justify-center flex-col">
                  <img
                    src="/images/coworking-woman-doing-online-shopping-at-home.gif"
                    className="w-56 object-cover"
                  />
                  <div className=" my-4 w-56 text-center">Start a chat</div>
                </div>
              )}

              {inboxList.map((item, index) => (
                <>
                  <Link
                    to={`/Messages/${item.conversation.conversation_id}/messages`}
                    onClick={() => (
                      setActiveChatid(item.conversation.conversation_id),
                      openConversation(
                        item.conversation.conversation_id,
                        item.user_info?.user_id,
                        item.user_info
                      )
                    )}
                    key={index}
                    className={`flex align-center px-2 gap-1 hover py-2 mx-2 rounded-xl overflow-hidden inbox_item ${
                      item.conversation.conversation_id == activeChatid &&
                      "active"
                    }`}
                  >
                    <div className="w-12 h-12 min-w-12 bg-gray-100 rounded-full relative">
                      <img
                        src={item.user_info?.profile_image}
                        className="w-12 h-12 min-w-12 rounded-full object-cover "
                      />
                      {pinnedChats.includes(item.user_info?.user_id) && (
                        <div
                          className=" absolute shadow-lg left-0 top-0 bg-white w-6 h-6 shadow-sm rounded-full flex align-center justify-center border-2 border-color-white"
                          style={{
                            left: -4,
                            top: -4,
                            borderColor: "white",
                            background: "white",
                          }}
                        >
                          <span
                            class="material-symbols-outlined"
                            style={{ fontSize: 15, color: "black" }}
                          >
                            keep
                          </span>
                        </div>
                      )}
                      {archivedChats.includes(item.user_info?.user_id) && (
                        <div
                          className=" absolute shadow-lg left-0 top-0 bg-white w-6 h-6 shadow-sm rounded-full flex align-center justify-center border-2 border-color-white"
                          style={{
                            left: -4,
                            top: -4,
                            borderColor: "white",
                            background: "white",
                          }}
                        >
                          <span
                            class="material-symbols-outlined"
                            style={{ fontSize: 15, color: "black" }}
                          >
                            archive
                          </span>
                        </div>
                      )}
                    </div>
                    <div
                      className="w-full px-2"
                      style={{ width: "calc(100% - 90px)" }}
                    >
                      <div className="font-semibold">
                        {item.user_info?.fullname}
                        {item.user_info?.user_id == app.current_user &&
                          " (You)"}
                      </div>
                      <div
                        className="text-mute text-sm text_simple"
                        style={{ maxHeight: 21 }}
                      >
                        {app.current_user ==
                          item.conversation.messages[
                            item.conversation.messages.length - 1
                          ]?.sender && "You:"}{" "}
                        {item.conversation.messages.length == 0
                          ? "Start a chat"
                          : item.conversation.messages[
                              item.conversation.messages.length - 1
                            ]?.chat_body == ""
                          ? "Shared a file"
                          : item.conversation.messages[
                              item.conversation.messages.length - 1
                            ]?.chat_body}
                      </div>
                    </div>
                    <div className="flex align-end justify-end flex-column relative">
                      <div className="text-sm">
                        {/* update this with real time update */}
                        {item.timestamp}min
                      </div>
                      <div className="flex align-center gap-1 relative">
                        {item.unread > 0 ? (
                          <div className="bg-blue-500 text-sm mt-1 text-center rounded-xl w-fit px-2 min-h-6 min-w-6 align-center flex">
                            {item.unread}
                          </div>
                        ) : (
                          <div className="text-sm mt-1 text-center rounded-xl w-fit min-h-4 min-w-4 align-center flex">
                            <span class="material-symbols-outlined">check</span>
                            <span class="material-symbols-outlined -ml-2">
                              check
                            </span>
                          </div>
                        )}
                        <Menu>
                          <MenuButton className="chat_menu bg-gray-100 rounded-full options align-center justify-center flex relative inbox_menu_icon">
                            <span class="material-symbols-outlined">
                              keyboard_arrow_down
                            </span>
                          </MenuButton>

                          <MenuItems
                            transition
                            anchor="bottom start"
                            className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                          >
                            <MenuItem>
                              <div
                                onClick={() =>
                                  archiveChat(
                                    item.conversation.conversation_id,
                                    item.user_info?.user_id
                                  )
                                }
                                className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item "
                              >
                                {archivedChats.includes(item.user_info?.user_id)
                                  ? "Archive"
                                  : "Unarchive"}{" "}
                                chat
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div
                                onClick={() =>
                                  muteUser(
                                    item.conversation.conversation_id,
                                    item.user_info?.user_id
                                  )
                                }
                                className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item "
                              >
                                {mutedChats.includes(item.user_info?.user_id)
                                  ? "Mute"
                                  : "Unmute"}{" "}
                                notification
                              </div>
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                clearChat(item.conversation.conversation_id)
                              }
                            >
                              <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item ">
                                Clear chat
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div
                                onClick={() =>
                                  pinChat(
                                    item.conversation.conversation_id,
                                    item.user_info?.user_id
                                  )
                                }
                                className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item "
                              >
                                {!pinnedChats.includes(item.user_info?.user_id)
                                  ? "Pin"
                                  : "Unpin"}{" "}
                                chat
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div
                                onClick={() =>
                                  blockUser(
                                    item.conversation.conversation_id,
                                    item.user_info?.user_id
                                  )
                                }
                                className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item "
                              >
                                {blockedUsers.includes(item.user_info?.user_id)
                                  ? "Unblocked"
                                  : "Block"}
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-white/10 text-md menu_item ">
                                Report
                              </div>
                            </MenuItem>
                          </MenuItems>
                        </Menu>
                      </div>
                    </div>
                  </Link>
                  {/* <hr className="w-full sm_line my-0" /> */}
                </>
              ))}
            </div>
          </div>
        </div>

        {/* mid chat content  */}
        {loadingMessages && (
          <div className="mid_chat_content flex-1 flex flex-column relative align center justify-center">
            <div className="flex align-center justify-center py-4">
              <Loader />
            </div>
          </div>
        )}

        {!loadingMessages && activeChatid == "" && (
          <div className="mid_chat_content flex-1 flex flex-column relative align-center justify-center">
            <div className="align-center text-center">
              <img
                src="/images/3d.png"
                className="w-64 animate__animated animate__zoomIn animate_fast"
              />
            </div>
            <hr className="line my-3 w-48" style={{ width: 72 }} />
            <h1 className="text-center text-2xl font-bold">VibeIn Chat</h1>
            <div className="text-center px-3 max-w-72">
              Start sending messages to friends, just select a chat an get
              started.
            </div>
          </div>
        )}

        {!loadingMessages && activeChatid != "" && activeChatid && (
          <div
            className={
              !showMessages
                ? "display-none"
                : "mid_chat_content flex-1 flex flex-column relative"
            }
          >
            <div className="px-4 flex align-center bg-gray-100 py-3 border-none ">
              <div
                className="h-10 w-full mr-3 rounded-full align-center cursor-pointer flex gap-2"
                onClick={() => {
                  showProfileDetails
                    ? setShowProfileDetails(false)
                    : setShowProfileDetails(true);
                }}
              >
                <div className="min-w-12 w-12 h-12 rounded-full bg-gray-100 flex align-center">
                  <img
                    className="w-12 min-w-12 h-12 min-h-12 rounded-full"
                    src={recieverProfile?.profile_image}
                  />
                </div>
                <div className="w-full">
                  <div className="font-semibold text-lg px-3">
                    {recieverProfile?.fullname}
                  </div>
                  {/* <div className="-mt-1 text-sm text-mute">@allan_young</div> */}
                </div>
              </div>
              <div className="flex align-center gap-2">
                <div
                  onClick={() => startAudioCall(true)}
                  className="min-w-10 w-10 h-10 rounded-full flex align-center justify-center flex hover"
                >
                  <span class="material-symbols-outlined">call</span>
                </div>
                <div
                  onClick={() => startVideoCall(true)}
                  className="min-w-10 w-10 h-10 rounded-full flex  justify-center align-center flex hover"
                >
                  <span class="material-symbols-outlined">videocam</span>
                </div>
                <div
                  onClick={() => pinChat(activeChatid, reciever)}
                  className="min-w-10 w-10 h-10 rounded-full flex align-center justify-center flex hover"
                >
                  {pinnedChats.includes(reciever) ? (
                    <span class="material-icons-round">push_pin</span>
                  ) : (
                    <span class="material-symbols-outlined">keep</span>
                  )}
                </div>

                <div
                  onClick={() => archiveChat(activeChatid, reciever)}
                  className="min-w-10 w-10 h-10 rounded-full flex align-center justify-center flex hover"
                >
                  {archivedChats.includes(reciever) ? (
                    <span class="material-symbols-outlined">unarchive</span>
                  ) : (
                    <span class="material-symbols-outlined">archive</span>
                  )}
                </div>

                <div
                  onClick={() => {
                    showProfileDetails
                      ? setShowProfileDetails(false)
                      : setShowProfileDetails(true);
                  }}
                  className="min-w-10 w-10 h-10 rounded-full flex align-center hover flex justify-center"
                >
                  <span class="material-symbols-outlined">info</span>
                </div>

                <Menu>
                  <MenuButton className="min-w-10 w-10 h-10 rounded-full flex align-center justify-center flex hover">
                    <span class="material-icons-round">more_vert</span>
                  </MenuButton>

                  <MenuItems
                    transition
                    anchor="bottom end"
                    className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                  >
                    <MenuItem>
                      <div
                        onClick={() => {
                          showProfileDetails
                            ? setShowProfileDetails(false)
                            : setShowProfileDetails(true);
                        }}
                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                      >
                        About chat
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        to={`/Profile/@${recieverProfile?.username}`}
                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                      >
                        Open profile
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item">
                        Forward
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        onClick={() => setShowProfileDetails(true)}
                        to={`/Messages/${activeChatid}/details/search`}
                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                      >
                        Search messages
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <div
                        onClick={() => muteUser(activeChatid, reciever)}
                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                      >
                        {mutedChats.includes(reciever) ? "Mute" : "Unmute"}{" "}
                        notification
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div
                        onClick={() =>
                          selectMessages
                            ? setSelectMessages(false)
                            : setSelectMessages(true)
                        }
                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                      >
                        Select messages
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item">
                        Pinned messages
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        to={`/Messages`}
                        onClick={() => setActiveChatid("")}
                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                      >
                        Close chat
                      </Link>
                    </MenuItem>
                    <hr
                      className="my-2 ml-3"
                      style={{ opacity: 0.3, width: "90%" }}
                    />
                    <MenuItem>
                      <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item">
                        Delete chat
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div
                        onClick={() => blockUser(activeChatid, reciever)}
                        className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                      >
                        {blockedUsers.includes(reciever)
                          ? "Unblocked"
                          : "Block"}
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item">
                        Report user
                      </div>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </div>
            <div className="messages_container flex-1 h-full overflow-y-auto">
              <MessagesList
                onReplay={() => replayMessage()}
                selectMessages={
                  Array.isArray(selectMessages) ? selectMessages : []
                }
                activeChatid={activeChatid}
                conversationData={
                  Array.isArray(conversationData) ? conversationData : []
                }
              />

              {/* Dummy element to scroll to */}
              <div ref={messagesEndRef} />
            </div>
            {showEmojiSelector && (
              <div
                style={{
                  position: "relative",
                  top: minimizeMediaSelector ? -64 : 0,
                }}
              >
                <EmojiSelector onEmojiSelected={handleEmojiSelected} />
              </div>
            )}

            {showReplyMessage && (
              <div className="py-3 px-5 bg-gray-200 border-bottom animate__animated animate__slideInUp animate_fast reply_container z-10">
                <div className="left_message_cont flex align-start justify-start gap-3">
                  <div className="w-10 min-w-10 h-10 min-h-10 bg-gray-100 rounded-full"></div>
                  <div className="flex-1">
                    <div className="w-auto min-w-20 rounded-xl chat min-h-10 p-2 px-4 content">
                      Lorem ipsum dolor sit amet
                    </div>
                  </div>
                  <div
                    className="w-10 min-w-10 h-10 min-h-10 bg-gray-100 rounded-full hover flex align-center justify-center"
                    onClick={() =>
                      showReplyMessage && setShowReplyMessage(false)
                    }
                  >
                    <span class="material-symbols-outlined">close</span>
                  </div>
                </div>
              </div>
            )}

            {showSelectedMedia &&
              selectedImages.length +
                selectedVideos.length +
                selectedAudios.length +
                selectedDocuments.length >
                0 && (
                <div
                  className={`pb-3 bg-gray-200 border-bottom animate__animated animate__slideInUp animate_fast media_select ${
                    minimizeMediaSelector && "mini"
                  }`}
                  style={{ zIndex: 13 }}
                >
                  <div className="left_message_cont flex align-center justify-between gap-3  sticky top-0 headers px-4">
                    <div className="flex align-center justify-center">
                      <div className="text-xl font-bold">
                        Share media
                        <span className="px-3 font-semibold">
                          {selectedImages.length +
                            selectedVideos.length +
                            selectedAudios.length +
                            selectedDocuments.length}
                        </span>
                      </div>
                    </div>

                    <div className="flex align-center justify-center gap-3">
                      <div
                        className="w-10 min-w-10 h-10 min-h-10 bg-gray-100 rounded-full hover flex align-center justify-center"
                        onClick={() =>
                          minimizeMediaSelector
                            ? setMinimizeMediaSelector(false)
                            : setMinimizeMediaSelector(true)
                        }
                      >
                        <span class="material-icons-round">arrow_right</span>
                      </div>
                      <div
                        className="w-10 min-w-10 h-10 min-h-10 bg-gray-100 rounded-full hover flex align-center justify-center"
                        onClick={() => (
                          showSelectedMedia && setShowSelectedMedia(false),
                          setMinimizeMediaSelector(false),
                          clearSelectedFiles()
                        )}
                      >
                        <span class="material-symbols-outlined">close</span>
                      </div>
                    </div>
                  </div>
                  {selectedImages.length == 0 &&
                    selectedVideos.length == 0 &&
                    selectedAudios.length == 0 &&
                    selectedDocuments.length == 0 && (
                      <div className="w-full align-center justify-center px-4 py-5 flex flex-col">
                        <img
                          src="/images/beam-sitting-young-woman.png"
                          className="w-56"
                        />
                        <h3 className="text-center font-bold mt-3 ">
                          No media selected
                        </h3>
                      </div>
                    )}

                  {!minimizeMediaSelector && (
                    <div className="selected_inn_media px-4">
                      {/* display sected images */}
                      <div className="w-full grid grid-cols-4 gap-3 py-3">
                        {selectedImages.map((item, index) => (
                          <div
                            key={index}
                            className="bg-orange-100 rounded-xl relative shadow-lg overflow-hidden animate__animated animate__zoomIn animate_fast"
                            style={{ aspectRatio: 1 }}
                          >
                            <img
                              src={item.blobUrl}
                              className="w-full h-full object-cover"
                            />
                            <div
                              onClick={() => removeImageFromSelected(index)}
                              className="w-8 h-8 shadow-sm rounded-full bg-gray-100 flex align-center justify-center absolute right-2 top-2 hover"
                            >
                              <span class="material-symbols-outlined">
                                delete
                              </span>
                            </div>
                          </div>
                        ))}
                        {/* list selected images */}
                        {selectedVideos.map((item, index) => (
                          <div
                            key={index}
                            className="bg-orange-100 rounded-xl relative shadow-lg overflow-hidden animate__animated animate__zoomIn animate_fast"
                            style={{ aspectRatio: 1 }}
                          >
                            <video
                              controls
                              className="w-full h-full object-cover"
                            >
                              <source src={item.blobUrl} type={item.type} />
                            </video>
                            <div
                              onClick={() => removeVideoFromSelected(index)}
                              className="w-8 h-8 shadow-sm rounded-full bg-gray-100 flex align-center justify-center absolute right-2 top-2 hover"
                            >
                              <span class="material-symbols-outlined">
                                delete
                              </span>
                            </div>
                          </div>
                        ))}

                        {/* list selected audio */}
                        {selectedAudios.map((item, index) => (
                          <div
                            key={index}
                            className="bg-gray-100 rounded-xl relative shadow-lg overflow-hidden animate__animated animate__zoomIn animate_fast"
                            style={{ aspectRatio: 1 }}
                          >
                            <div className="w-full h-full flex align-center justify-center flex-col px-3 gap-3">
                              <span
                                class="material-symbols-outlined"
                                style={{ fontSize: 50 }}
                              >
                                music_note
                              </span>
                              <div
                                className="text-center px-5"
                                style={{ fontSize: 12 }}
                              >
                                {item.name}
                              </div>
                              <div
                                className="text-center px-5"
                                style={{ fontSize: 12 }}
                              >
                                {item.size / (1000 * 1000)} MB
                              </div>
                            </div>
                            <div
                              onClick={() => removeAudioFromSelected(index)}
                              className="w-8 h-8 shadow-sm rounded-full bg-gray-100 flex align-center justify-center absolute right-2 top-2 hover"
                            >
                              <span class="material-symbols-outlined">
                                delete
                              </span>
                            </div>
                          </div>
                        ))}
                        {/* list selected documents */}
                        {selectedDocuments.map((item, index) => (
                          <div
                            key={index}
                            className="bg-gray-100 rounded-xl relative shadow-lg overflow-hidden animate__animated animate__zoomIn animate_fast"
                            style={{ aspectRatio: 1 }}
                          >
                            <div className="w-full h-full flex align-center justify-center flex-col px-3 gap-3">
                              <span
                                class="material-symbols-outlined"
                                style={{ fontSize: 50 }}
                              >
                                description
                              </span>
                              <div
                                className="text-center px-5"
                                style={{ fontSize: 12 }}
                              >
                                {item.name}
                              </div>
                              <div
                                className="text-center px-5"
                                style={{ fontSize: 12 }}
                              >
                                {item.size / 1000} MB
                              </div>
                            </div>
                            <div
                              onClick={() => removeDocumentFromSelected(index)}
                              className="w-8 h-8 shadow-sm rounded-full bg-gray-100 flex align-center justify-center absolute right-2 top-2 hover"
                            >
                              <span class="material-symbols-outlined">
                                delete
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

            {showGifs && (
              <div className="py-3 bg-gray-200 border-bottom animate__animated animate__slideInUp animate_fast gif_container_chat z-10">
                <div className="left_message_cont flex align-start justify-start gap-3 px-5 pb-2">
                  <div className="w-10 min-w-10 h-10 min-h-10 bg-gray-100 rounded-full flex align-center justify-center">
                    <span className="material-symbols-outlined">search</span>
                  </div>
                  <div className="flex-1">
                    <div className="w-auto min-w-20 rounded-xl chat min-h-10 content">
                      <input
                        placeholder="Search gif"
                        className="w-full h-full px-4 py-2 non_outline"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div
                    className="w-10 min-w-10 h-10 min-h-10 rounded-full hover flex align-center justify-center"
                    onClick={() =>
                      showGifs &&
                      (setShowGifs(false), setShowEmojiSelector(false))
                    }
                  >
                    <span className="material-symbols-outlined">close</span>
                  </div>
                </div>
                <div className="grid grid-cols-4 overflow-y-auto px-2 py-4 gap-2 gifs_cont_inn">
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    gifs.map((gif) => (
                      <div
                        key={gif.id}
                        className="bg-gray-100 rounded-lg"
                        style={{ aspectRatio: 1 }}
                      >
                        <img
                          src={gif.images.fixed_height_small.url}
                          alt={gif.title}
                          className="w-full h-full object-cover rounded-lg"
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            <div className="footer_create_chat min-h-16 bg-gray-200 w-full flex align-center justify-between gap-1 px-4 z-10">
              {/* attachment icon */}
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={selectImageFile}
                id="imageSelector"
                style={{ display: "none" }} // Hides the input but keeps it functional
              />

              <input
                type="file"
                multiple
                accept="video/*"
                onChange={selectVideoFile}
                id="videoSelector"
                style={{ display: "none" }} // Hides the input but keeps it functional
              />

              <input
                type="file"
                multiple
                accept="audio/*"
                onChange={selectAudioFile}
                id="audioSelector"
                style={{ display: "none" }} // Hides the input but keeps it functional
              />

              <input
                type="file"
                multiple
                // accept="audio/*"
                onChange={selectDocumentFile}
                id="documentSelector"
                style={{ display: "none" }} // Hides the input but keeps it functional
              />

              <Menu>
                <MenuButton className="w-10 min-w-10 h-10 flex align-center justify-center rounded-full hover">
                  <span class="material-icons-round">add</span>
                </MenuButton>

                <MenuItems
                  transition
                  anchor="top start"
                  className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                >
                  <MenuItem>
                    <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item align-center justify-between">
                      Camera
                      <span class="material-icons-round h-auto">
                        photo_camera
                      </span>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <label
                      htmlFor="imageSelector"
                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item align-center justify-between"
                    >
                      Image
                      <span className="material-icons-round h-auto">image</span>
                    </label>
                  </MenuItem>

                  <MenuItem>
                    <label
                      htmlFor="videoSelector"
                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item align-center justify-between"
                    >
                      Video
                      <span class="material-icons-round h-auto">videocam</span>
                    </label>
                  </MenuItem>
                  <MenuItem>
                    <label
                      htmlFor="audioSelector"
                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item align-center justify-between"
                    >
                      Audio
                      <span class="material-icons-round h-auto">
                        play_arrow
                      </span>
                    </label>
                  </MenuItem>
                  <MenuItem>
                    <label
                      htmlFor="documentSelector"
                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item align-center justify-between"
                    >
                      Document
                      <span class="material-icons-round h-auto">
                        receipt_long
                      </span>
                    </label>
                  </MenuItem>
                  <MenuItem>
                    <div
                      onClick={() =>
                        showGifs
                          ? setShowGifs(false)
                          : (setShowGifs(true), setShowEmojiSelector())
                      }
                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item align-center justify-between"
                    >
                      Gif
                      <span class="material-icons-round h-auto">gif</span>
                    </div>
                  </MenuItem>
                </MenuItems>
              </Menu>

              {/* create chat input */}

              <div className="min-h-10 flex align-center justify-center rounded-full w-full flex align-center py-2">
                <textarea
                  className="min-h-10 rounded-2xl chat_input bg-gray-100"
                  rows={1}
                  cols={1}
                  placeholder="Enter your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div
                className="w-10 min-w-10 h-10 flex align-center justify-center rounded-full hover"
                onClick={() =>
                  showEmojiSelector
                    ? setShowEmojiSelector(false)
                    : (setShowEmojiSelector(true),
                      setShowGifs(false),
                      showSelectedMedia && setMinimizeMediaSelector(true))
                }
              >
                <span class="material-icons-round">
                  sentiment_very_satisfied
                </span>
              </div>
              <div className="w-10 min-w-10 h-10 flex align-center justify-center rounded-full hover">
                <span class="material-icons-round">mic</span>
              </div>
              {/* send message */}
              <div
                onClick={() => handleSend()}
                className="w-10 min-w-10 h-10 flex align-center bg-gray-100 justify-center rounded-full hover"
              >
                <span class="material-icons-round">send</span>
              </div>
            </div>
          </div>
        )}

        {loadingMessages && (
          <div
            className="right_profile_content"
            style={{
              minHeight: "100vh",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Loader />
          </div>
        )}

        {!loadingMessages && showProfileDetails ? (
          <div
            className={`right_profile_content animate__animated ${
              showProfileDetails
                ? "animate__backInRight"
                : "animate__backOutLeft"
            } animate_fast `}
          >
            <div
              className="px-4 flex align-center headers gap-2 border-none"
              style={{ zIndex: 12 }}
            >
              <div className="h-10 w-full mr-3 rounded-full align-center flex">
                <div className="font-bold text-xl">About</div>
              </div>
              <Link
                to={`/Messages/${activeChatid}/details/search`}
                className="min-w-10 w-10 h-10 rounded-full flex align-center justify-center hover"
              >
                <span class="material-icons-round">search</span>
              </Link>
              <div
                className="min-w-10 w-10 h-10 rounded-full flex align-center justify-center hover"
                // onClick={() => muteNotifications()}
                onClick={() => muteUser(activeChatid, reciever)}
              >
                {!mutedChats.includes(reciever) ? (
                  <span class="material-icons-round">notifications_off</span>
                ) : (
                  <span class="material-icons-round">notifications</span>
                )}
              </div>
              <div
                onClick={() => setShowProfileDetails(false)}
                className="min-w-10 w-10 h-10 rounded-full flex align-center justify-center hover"
              >
                <span class="material-icons-round">close</span>
              </div>
            </div>
            <ProfileDetails
              user={recieverProfile}
              activeChatid={activeChatid}
              conversationData={
                Array.isArray(conversationData) ? conversationData : []
              }
            />
          </div>
        ) : (
          ""
        )}

        {showAudioCallModal ? (
          <>
            <div className="fixed flex flex-column justify-between py-16 align-center call_modal z-80 top-0 bottom-0 left-0 right-0 m-auto  animate__animated animate__zoomIn animate_fast">
              <div className="flex align-center justify-center flex-column p-4 pt-0">
                <div className="w-40 h-40 rounded-full bg-gradient p-2">
                  <img
                    src={recieverProfile?.profile_image}
                    className="w-38 h-38 rounded-full object-cover"
                  />
                </div>
                <div className="text-center mt-3">
                  <div className="text-xl font-bold">
                    {recieverProfile?.fullname}
                  </div>
                  <div className="text-mute textsm">
                    @{recieverProfile?.username}
                  </div>
                  <div className="text-sm">Calling...</div>
                </div>
              </div>

              <div className="flex w-full align-center justify-center mt-8 gap-5">
                <button
                  title="Minimize call"
                  onClick={() => minimizeAudioCall()}
                  className="w-14 h-14 min-w-14 min-h-14 flex align-center justify-center hover rounded-full text-sm border-1 w-auto"
                >
                  <span class="material-icons-round">remove</span>
                </button>
                <button
                  title="Mute mic"
                  onClick={() => muteAudioCall()}
                  className="w-14 h-14 min-w-14 min-h-14 flex align-center justify-center hover rounded-full text-sm border-1 w-auto"
                >
                  {!micMute ? (
                    <span class="material-icons-round">mic</span>
                  ) : (
                    <span class="material-icons-round">mic_off</span>
                  )}
                </button>
                <button
                  title="End call"
                  onClick={() => endAudioCall()}
                  className="w-14 h-14 min-w-14 min-h-14 flex align-center justify-center hover rounded-full text-sm border-1 w-auto"
                >
                  <span class="material-icons-round">call_end</span>
                </button>
              </div>
            </div>
            <div className="back_drop"></div>
          </>
        ) : (
          ""
        )}

        {showVideoCallModal ? (
          <>
            <div className="fixed flex flex-column justify-between overflow-hidden align-center call_modal z-80 top-0 bottom-0 left-0 right-0 m-auto  animate__animated animate__zoomIn animate_fast">
              <div className="w-full block h-full bg-black video_contain">
                {gridView ? (
                  <div className="flex align-center justify-center px-3 gap-3 py-3">
                    <div className="w-full h-80 rounded-2xl border-1 hover cursor-pointer animate__animated animate__zoomIn animate_fast bg-gray-100">
                      <div className="text-sm relative top-6 left-6">
                        {recieverProfile?.fullname}
                      </div>
                      {/* video goes here */}
                    </div>
                    <div className="w-full h-80 rounded-2xl border-1 hover cursor-pointer animate__animated animate__zoomIn animate_fast bg-gray-100">
                      <div className="text-sm relative top-6 left-6">
                        @{recieverProfile?.username}
                      </div>
                      {/* video goes here */}
                    </div>
                  </div>
                ) : (
                  <div className="video_contain_inn">
                    {/* video goes here */}
                    <div className="">
                      <div className="text-sm relative top-6 left-6">
                        {recieverProfile?.fullname}
                      </div>
                      {/* add a video here */}
                    </div>
                    <div className="w-32 h-40 bg-gray-100 absolute right-6 top-6 rounded-2xl border-1 hover cursor-pointer animate__animated animate__zoomIn animate_fast">
                      <div className="text-sm absolute bottom-4 left-0 right-0 m-auto w-full text-center">
                        @{recieverProfile?.username}
                      </div>
                      {/* add a video here */}
                    </div>
                  </div>
                )}

                <div className="flex w-full align-end justify-center mt-8 gap-5 absolute bottom-5 px-5">
                  <div className="flex align-center justify-end gap-3">
                    <button
                      title="Minimize call"
                      onClick={() => {
                        gridView ? setGridView(false) : setGridView(true);
                      }}
                      className="w-12 h-12 min-w-12 min-h-12 flex align-center justify-center hover rounded-full text-sm border-1 w-auto"
                    >
                      {gridView ? (
                        <span class="material-icons-round">
                          check_box_outline_blank
                        </span>
                      ) : (
                        <span class="material-icons-round">
                          space_dashboard
                        </span>
                      )}
                    </button>
                    <button
                      title="Minimize call"
                      onClick={() => minimizeVideoCall()}
                      className="w-12 h-12 min-w-12 min-h-12 flex align-center justify-center hover rounded-full text-sm border-1 w-auto"
                    >
                      <span class="material-icons-round">remove</span>
                    </button>
                    <button
                      title="Mute mic"
                      onClick={() => muteAudioCall()}
                      className="w-12 h-12 min-w-12 min-h-12 flex align-center justify-center hover rounded-full text-sm border-1 w-auto"
                    >
                      {!micMute ? (
                        <span class="material-icons-round">mic</span>
                      ) : (
                        <span class="material-icons-round">mic_off</span>
                      )}
                    </button>
                    <button
                      title="End call"
                      onClick={() => endVideoCall()}
                      className="w-12 h-12 min-w-12 min-h-12 flex align-center justify-center hover rounded-full text-sm border-1 w-auto"
                    >
                      <span class="material-icons-round">call_end</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="back_drop"></div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Messages;
