import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import JobList from "../components/JobList";
import JobsListDummy from "../components/Dummies/JobsListDummy";
import JobDetailsComponent from "./JobDetailsComponent";

function JobDetails({ local, jobs, showSidebar = true }) {
  let { id, focus, tab } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div className="flex align-start job_details_cont">
      {showSidebar && (
        <div className="min-w-60 jobs_list_left border-right border-left px-1 py-4">
          {loading && <JobsListDummy />}
          {!loading &&
            jobs.map((item, index) => (
              <>
                <JobList data={item} local={local} />
                <hr className="line_sm" />
              </>
            ))}
        </div>
      )}
      <JobDetailsComponent local={local} id={id} />
    </div>
  );
}

export default JobDetails;
