import React, { useEffect, useState } from "react";
import app from "../../../config/app";
import { XMarkIcon } from "@heroicons/react/16/solid";
import {
  getHubAccessList,
  getHubAdmins,
  getHubMembers,
  updateHubAccessList,
  updateRemoveHubAdmin,
} from "../../../db";
import { useParams } from "react-router-dom";
import Responce from "../../components/Responce";

function HubAccess({ local }) {
  const [selectedMember, setSelectedMember] = useState("");
  const [filter, setFilter] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { id, page, focus } = useParams();

  useEffect(() => {
    fetchAllMembers();
    fetchAdmins();
  }, [page, id, local]);

  const fetchAllMembers = async () => {
    try {
      const info = await getHubMembers(id);
      console.log("members list- > ", info.members);
      // if (info.r_status) {
      setMembersList(info.members);
      // }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const fetchAdmins = async () => {
    try {
      const info = await getHubAdmins(id);
      console.log("Admin list- > ", info.admins);
      // if (info.r_status) {
      setAdminList(info.admins);
      // }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleMembersUpdate = (places) => {
    setSelectedMember(places);
  };

  const addAdmin = async (activity_id) => {
    const res = await updateHubAccessList(id, activity_id);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );

      fetchAllMembers();
      fetchAdmins();
      // update local data list
      await getHubAccessList(app.current_user, 30);
      // console.log(responce_card);
    }
  };

  const removeAdmin = async (activity_id) => {
    const res = await updateRemoveHubAdmin(id, activity_id);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );

      fetchAllMembers();
      fetchAdmins();
      // update local data list
      await getHubAccessList(app.current_user, 30);
      // console.log(responce_card);
    }
  };

  const filteredMembers = membersList.filter(
    (member) =>
      member.user_profile[0].fullname
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      member.user_profile[0].username
        .toLowerCase()
        .includes(filter.toLowerCase())
  );

  return (
    <div className="p-0 pt-3">
      <div className="mb-4 flex align-center justify-between">
        <div className="text-xl font-semibold">Hub access</div>
      </div>

      <div className="px-0 py-3 pt-0">
        Who can access & manage this community (Hub)?
      </div>
      <div className="places_list_selected flex align-start justify-start flex-wrap gap-3">
        {/* list hub admins and owner */}

        {adminList.length > 0 &&
          adminList.map((item, index) => (
            <div className="border-1 rounded-full flex align-center justify-between w-auto">
              <div className="px-1 py-1 flex align-center justify-center">
                <div className="w-10 h-10 rounded-full ">
                  <img
                    src={
                      item.user_profile[0].profile_image == ""
                        ? "/images/user.png"
                        : item.user_profile[0].profile_image
                    }
                    className="w-10 h-10 min-w-10 min-h-10 rounded-full object-cover"
                  />
                </div>
                <div className="px-3">{item.user_profile[0].fullname}</div>
                <div className="rounded-full bg-gray-100 px-3 h-8 justify-center flex align-center text-capitalized">
                  {item.prev}
                </div>
              </div>
              {item.prev != "owner" && (
                <div
                  onClick={() => removeAdmin(item.activity_id)}
                  className="remove_place w-10 h-10 mr-1 bg-gray-100 hover:shadow-lg hover rounded-full flex align-center justify-center hover"
                >
                  <XMarkIcon />
                </div>
              )}
            </div>
          ))}
      </div>
      <hr className="line" />
      <div className="sticky tags_filter py-2 flex align-center justify-center gap-3 mb-4">
        <input
          type="text"
          placeholder="Search members ..."
          className="p-2 form-control rounded-xl w-full px-6 flex-1"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      <div className="overflow-hidden overflow_list_cont">
        <div className="category_list border-1 rounded-2xl px-2 py-2">
          {filteredMembers.map((item, index) => (
            <div
              key={index}
              onClick={() => handleMembersUpdate(item)}
              className={`p-3 cursor-pointer hover flex align-center gap-2 rounded-xl`}
            >
              <img
                src={
                  item.user_profile[0].profile_image == ""
                    ? "/images/user.png"
                    : item.user_profile[0].profile_image
                }
                className="w-10 h-10 min-w-10 min-h-10 rounded-full object-cover"
              />
              <div className="w-full">
                <div className="flex align-center gap-2">
                  <div className="">{item.user_profile[0].fullname}</div>
                  {(item.prev == "admin" || item.prev == "owner") && (
                    <div className="badge_success rounded-full h-5 px-2 text-sm">
                      {item.prev}
                    </div>
                  )}
                </div>
                <div className="text-mute -mt-1">
                  {item.user_profile[0].username}
                </div>
              </div>
              {item.user_profile[0].activity_id != app.current_user &&
                (item.prev == "admin" || item.prev == "owner") && (
                  <div
                    className="min-w-14 min-h-14 rounded-xl bg-gray-50 px-5 gap-3 flex align-center justify-center hover cursor-pointer"
                    onClick={() => addAdmin(item.activity_id)}
                  >
                    <div>Add</div>
                    <div className="material-icons-round">add</div>
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
      {showResponceCard && responceCard}
    </div>
  );
}

export default HubAccess;
