import React from "react";
import { Link } from "react-router-dom";

function NotificationItem({ data }) {
  return (
    <div>
      <div
        className="sub-notification-item flex align-center gap-4 hover rounded-xl py-1 px-2"
        data="478434"
        type="follow"
      >
        <Link className="notification_container_img">
          <img
            src={
              (data.row.type = "follow" && data?.user_info[0]?.profile_image)
            }
            alt=""
            className="w-10 h-10 rounded-full object-cover"
          />
        </Link>
        <div className="notification_content flex-1 justify-start text-left">
          <div className="">
            <div>
              <b>
                {(data.row.type = "follow" && data?.user_info[0]?.fullname)}
              </b>
            </div>
            <div>
              {data?.row?.about} {(data.row.type = "follow" && " you")}
            </div>
          </div>
          <div className="display_time_notification text-sm">
            {data?.row?.date}
          </div>
        </div>
        <button class="follow_btn text-center flex align-center justify-center w-auto">
          Follow
        </button>
      </div>
      <hr className="line_sm" />
    </div>
  );
}

export default NotificationItem;
