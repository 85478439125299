import React, { useEffect, useState } from "react";
import Feed from "./Feed";
import { getVibeDetails } from "../../db";
import Loader from "./Loader";
import { Link } from "react-router-dom";

function Revibe({ parent, local }) {
  const [vibeDetails, setVibeDetails] = useState(null); // Initialize as null to track loading state
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    fetchDetails();
  }, [parent, local]);

  const fetchDetails = async () => {
    try {
      const data = await getVibeDetails(parent);
      setVibeDetails(data.details[0]);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Details:", error);
      setLoading(false); // Handle failure, but still stop loading
    }
  };

  const clicked = async () => {
    console.log(vibeDetails);
  };

  return (
    <div className="revibe_content">
      {!loading && vibeDetails ? (
        <>
          <div className="px-3 flex align-center gap-2 revibe_title">
            <div className="text-sm ">Revibed</div>
            <Link to={`/Profile/${vibeDetails.user_info[0]?.username}`}>
              @{vibeDetails.user_info[0]?.username}
            </Link>
          </div>
          <Feed data={vibeDetails} local={local} />
        </>
      ) : (
        <div className="w-full flex align-center justify-center">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default Revibe;
