import React, { useEffect, useState } from "react";
import Responce from "../../components/Responce";
import {
  getBusinessProfile,
  getPageAccessList,
  updateBusinessUsername,
  updateLocalStorage,
} from "../../../db";
import { useParams } from "react-router-dom";
import app from "../../../config/app";

function Username({ local, business, updateLocal }) {
  const [businessProfile, setBusinessProfile] = useState();
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { id, page, focus } = useParams();
  const [formData, setFormData] = useState({
    username: "",
  });

  const replaceSpecialCharacters = (str) => {
    const specialCharsRegex = /[^a-zA-Z0-9_]/g;
    return str.replace(specialCharsRegex, "_");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: replaceSpecialCharacters(value),
    });
  };

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const info = await getBusinessProfile(id);
      console.log("Business info - > ", info.business_info[0]);
      setBusinessProfile(info.business_info[0]);
      if (info.r_status) {
        setFormData({
          username: info.business_info[0].username,
        });
      }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await updateBusinessUsername(id, formData);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      // console.log(responce_card);
    }
    console.log("Form submitted res:", res);
    const list = await getPageAccessList(app.current_user, 30);
    updateLocalStorage({ type: "updatepage", data: list });
    updateLocal(list);
  };

  return (
    <div className="p-3 sm-p-0">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Contact info</div>
        <div className="border-1 rounded-2xl px-4 mt-4 pb-5">
          <div className="mt-6 rounded-md">
            <div className="form-check p-0 mt-5">
              <label className="form-check-label mb-4" htmlFor="username">
                Page username
              </label>
              <div className="flex align-center relative mt-3">
                <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                  <span className="material-icons-round">alternate_email</span>
                </div>
                <input
                  className="form-control w-full relative has_icon"
                  type="text"
                  name="username"
                  id="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Just a simple name"
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-mute">
              This help when someone whats to quicky access your page in the
              internet.
            </div>
            <div>
              <span className="text-mute">
                For example. www.vibein.africa/@
              </span>
              <span className="text-lg">
                {formData.username == ""
                  ? "your_page_username"
                  : replaceSpecialCharacters(formData.username)}
              </span>
            </div>
          </div>
          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
      {showResponceCard && responceCard}
    </div>
  );
}
export default Username;
