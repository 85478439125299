import React, { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import axios from "axios";
import Cookies from "js-cookie";
import app from "../../config/app";
import {
  saveBusinessAccessToIndexedDB,
  saveContactInfoToIndexedDB,
  saveHubAccessToIndexedDB,
  saveInterestsToIndexedDB,
  saveLanguageToIndexedDB,
  savePrivacySettingsToIndexedDB,
  saveProfessionalSettingsToIndexedDB,
  saveProfileImageAndCoverSettingsToIndexedDB,
  saveProfileToIndexedDB,
  saveTimezoneToIndexedDB,
} from "../../db";
import CopyRight from "../components/CopyRight";
import Loader from "../components/Loader";

export default function AuthUpdateUsername() {
  const [open, setOpen] = useState(true);
  const [user, setUser] = useState("");
  const [username, setUsername] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [close, setClose] = useState(true);

  useEffect(() => {
    const sysUser = Cookies.get("sys_user");
    setUser(sysUser);
    setLoading(false);
  }, []);

  const handleUpdateUsername = async () => {
    setErrorMsg("");
    setLoading(true);
    if (username !== "" && dateOfBirth !== "" && gender !== "") {
      try {
        const { data } = await axios.post(
          `${app.auth_request_url}AUTH_UPDATE_USERNAME`,
          {
            user_id: user,
            username,
            date_of_birth: dateOfBirth,
            gender,
            profile_image: profileImage,
            cover_image: coverImage,
          }
        );

        if (data.r_status) {
          Cookies.set("sys_user", data.user_activity_id, { expires: 365 });
          console.log("Signup res", data);

          const profile = data.user_settings.profile[0];
          const { business_access_list, hub_access_list } = data;

          // Parallel IndexedDB operations
          await Promise.all([
            saveProfileToIndexedDB({
              fullName: profile.fullname,
              username: profile.username,
              bio: profile.bio,
              jobTitle: profile.current_job_title,
              dateOfBirth: profile.date_of_birth,
            }),
            savePrivacySettingsToIndexedDB({
              coverImageUpdate: profile.privacyHideCoverImageUpdate,
              onlineStatusChats: profile.privacyHideOnlineStatusOnChats,
              onlineStatusPosts: profile.privacyHideOnlineStatusOnPosts,
              profileImageUpdate: profile.privacyHideProfilePictureUpdate,
              profileVisibility: profile.privacyHideProfileSearchResults,
            }),
            saveContactInfoToIndexedDB({
              address: profile.address,
              country: profile.country_phone,
              email: profile.email,
              phoneNumber: profile.phone,
            }),
            saveProfessionalSettingsToIndexedDB({
              availabilityStatus: profile.availabilityStatus,
              downloadMyCv: profile.downloadMyCv,
            }),
            saveProfileImageAndCoverSettingsToIndexedDB({
              profile_image: profile.profile_image,
              cover_image: profile.cover_image,
            }),
            saveLanguageToIndexedDB(profile.language || "English"),
            saveTimezoneToIndexedDB(profile.timezone || "Africa/Nairobi"),
            saveInterestsToIndexedDB(profile.interest),
            saveBusinessAccessToIndexedDB(business_access_list),
            saveHubAccessToIndexedDB(hub_access_list),
          ]);

          setTimeout(() => {
            window.location = "/auth/followpeople";
          }, 10);

          setLoading(false);
        } else {
          setErrorMsg(data.responce);
          setLoading(false);
        }
      } catch (error) {
        console.error("Sign-in error:", error);
        alert("An error occurred. Please try again.");
        setLoading(false);
      }
    } else {
      setErrorMsg("Fill all fields");
      setLoading(false);
    }
  };

  // Calculate the minimum date for 13 years ago
  const getMinimumDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 13);
    return today.toISOString().split("T")[0];
  };

  return (
    <Dialog className="relative z-10 auth_modal" open={open} onClose={setClose}>
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-white modal_container">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white pb-3 pt-4">
              <div className="sm:flex sm:items-start w-full">
                <div className="text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="px-6 text-base font-semibold justify-center leading-6 text-gray-900 mb-6 border-bottom pb-5 flex align-center justify-between"
                  >
                    <div className="flex align-center flex-col w-full">
                      <div className="h-16 w-16 mr-3 rounded-full mb-3">
                        <img src="/images/icon.png" alt="Icon" />
                      </div>
                      <div>Your profile details</div>
                    </div>
                  </DialogTitle>

                  {loading && (
                    <div className="absolute w-full flex align-center justify-center h-full top-0 auth_loader_cont">
                      <Loader />
                    </div>
                  )}

                  <div className=" px-6">
                    <div className="mt-2 relative">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        required
                        className="w-full form-control"
                        placeholder="Your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className=" px-6">
                    <div className="mt-2">
                      <input
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        required
                        className="w-full form-control"
                        placeholder="Select date"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        max={getMinimumDate()} // Restrict date to 13+ years old
                      />
                    </div>
                  </div>

                  <div className=" px-6">
                    <div className="mt-2">
                      <select
                        id="gender"
                        name="gender"
                        required
                        className="w-full form-control"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Select gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errorMsg && (
              <div className="px-4 sm:flex sm:px-6 w-full mb-3">{errorMsg}</div>
            )}

            <div className="px-4 py-3 sm:flex sm:px-6 w-full mb-10">
              <button
                type="button"
                onClick={handleUpdateUsername}
                className="inline-flex justify-center rounded-xl w-full bg-black px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:w-full"
              >
                Continue
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
      <div className="footer_details_auth">
        <CopyRight showLess={true} />
      </div>
    </Dialog>
  );
}
