import React, { useState } from "react";
import { parseContent } from "../../db";

const PostContent = ({
  text,
  maxLength = 200,
  customStyle = {},
  allowSpacing = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const postContent = text;
  if (!postContent) return null;

  const isLongContent = postContent.length > maxLength;

  // Only append "..." if the content is longer than maxLength characters and not expanded
  const displayText = isExpanded
    ? postContent
    : isLongContent
    ? `${postContent.slice(0, maxLength)}...`
    : postContent;

  // Replace custom entities with HTML tags
  const textWithFormatting = displayText
    .replace(/&newline;/g, "<br>")
    .replace(/&boldopen;/g, "<strong>")
    .replace(/&boldclose;/g, "</strong>")
    .replace(/&italicopen;/g, "<em>")
    .replace(/&italicclose;/g, "</em>");

  const parsedContent = parseContent(textWithFormatting);

  return (
    <div
      className={`post_text ${allowSpacing ? "px-2 mb-4 mt-2" : ""}`}
      style={customStyle}
    >
      <div dangerouslySetInnerHTML={{ __html: parsedContent }} />
      {isLongContent && (
        <button
          onClick={toggleReadMore}
          className="text-gray-500 text-mute text-sm hover rounded-sm"
        >
          {isExpanded ? "Show Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export default PostContent;
