import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import FollowList from "./components/FollowList";
import ProfileCard from "./components/ProfileCard";
import JoinHubList from "./components/JoinHubList";
import HubSlide from "./components/HubSlide";
import FollowListReused from "./components/FollowListReused";
import JoinHubsListReused from "./components/JoinHubsListReused";
import CopyRight from "./components/CopyRight";
import Slider from "./components/Slider";
import { useState } from "react";
import { getHubToJoinListGrouped } from "../db";
import HubSliderDummy from "./components/Dummies/HubSliderDummy";
import app from "../config/app";
import HubProfile from "./Hub/HubProfile";

function Communities({ local }) {
  const navigate = useNavigate();
  const [myHubs, setMyHubs] = useState([]);
  const [popularHubs, setPopularHubs] = useState([]);
  const [fromPeopleListHubs, setFromPeopleListHubs] = useState([]);
  const [hubList, setHubList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { username } = useParams();
  useEffect(() => {
    fetchHubsListData();
    // alert(username);
  }, []);

  const fetchHubsListData = async () => {
    try {
      const data = await getHubToJoinListGrouped(10, 0);
      console.log("Hubs grouped - > ", data);
      setMyHubs(data.myhubs);
      setPopularHubs(data.popularhubs);
      setFromPeopleListHubs(data.followlisthubs);
      setLoading(false);
    } catch (error) {
      console.error("Vibe:", error);
    }
  };

  // get multi request for hubs

  return (
    <>
      <div className="min-h-screen flex-1 ">
        <div className="px-4 headers p-0 pl-1">
          <div
            className=" h-full w-full flex align-center"
            style={{ width: "100%" }}
          >
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center ml-2"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Communities</div>
            </div>
          </div>
          {window.innerWidth > app.mobile_width && <TopUserMenu />}
        </div>
        <div className="p-4 px-0 ">
          {!username && (
            <div className="overflow-hidden">
              {myHubs?.length > 0 && (
                <div className="flex align-center justify-between px-4">
                  <div className="text-xl font-bold">My hubs</div>
                  <Link
                    to={`/communitiestaxonimy/myhubs`}
                    className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
                  >
                    View more
                  </Link>
                </div>
              )}

              {loading && (
                <div className="flex gap-0 pt-5">
                  <HubSliderDummy />
                  <HubSliderDummy />
                </div>
              )}

              {!loading && myHubs?.length > 0 && (
                <Slider slideWidth={340} slidesVisible={3}>
                  {myHubs?.length > 0 &&
                    myHubs.map((item, index) => (
                      <HubSlide simple={true} data={item} />
                    ))}
                  <div
                    style={{
                      minWidth: 300,
                      height: 100,
                    }}
                  ></div>
                </Slider>
              )}

              {popularHubs.length > 0 && (
                <div className="flex align-center justify-between mt-6 px-4">
                  <div className="text-xl font-bold">Popular Hubs</div>
                  <Link
                    to={`/communitiestaxonimy/popularhubs`}
                    className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
                  >
                    View more
                  </Link>
                </div>
              )}

              {popularHubs.length > 0 && (
                <Slider slideWidth={340} slidesVisible={3}>
                  {popularHubs.length > 0 &&
                    popularHubs.map((item, index) => (
                      <HubSlide simple={true} data={item} />
                    ))}
                  <div
                    style={{
                      minWidth: 300,
                      height: 100,
                    }}
                  ></div>
                </Slider>
              )}

              {fromPeopleListHubs.length > 0 && (
                <div className="flex align-center justify-between mt-6 px-4">
                  <div className="text-xl font-bold">From your network</div>
                  <Link
                    to={`/communitiestaxonimy/followlisthubs`}
                    className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
                  >
                    View more
                  </Link>
                </div>
              )}

              {fromPeopleListHubs.length > 0 && (
                <Slider slideWidth={340} slidesVisible={3}>
                  {fromPeopleListHubs.length > 0 &&
                    fromPeopleListHubs.map((item, index) => (
                      <HubSlide simple={true} data={item} />
                    ))}
                  <div
                    style={{
                      minWidth: 300,
                      height: 100,
                    }}
                  ></div>
                </Slider>
              )}
            </div>
          )}

          {/* loading hub details here if there is a username */}
          {username && username != "" && (
            <HubProfile username={username} local={local} />
          )}
        </div>
      </div>
    </>
  );
}

export default Communities;
