export const skills = [
  {
    id: "1",
    job_category: "Accountant",
    skill: "Financial Reporting",
    count: "10",
  },
  { id: "2", job_category: "Accountant", skill: "Budgeting", count: "20" },
  { id: "3", job_category: "Accountant", skill: "Tax Preparation", count: "3" },
  { id: "4", job_category: "Accountant", skill: "Auditing", count: "5" },
  { id: "5", job_category: "Accountant", skill: "Cost Accounting", count: "1" },
  {
    id: "6",
    job_category: "Accountant",
    skill: "Payroll Management",
    count: "1",
  },
  {
    id: "7",
    job_category: "Accountant",
    skill: "Accounts Payable/Receivable",
    count: "2",
  },
  {
    id: "8",
    job_category: "Accountant",
    skill: "Financial Analysis",
    count: "20",
  },
  { id: "9", job_category: "Accountant", skill: "General Ledger", count: "2" },
  { id: "10", job_category: "Accountant", skill: "Compliance", count: "11" },
  {
    id: "11",
    job_category: "Accountant",
    skill: "Risk Management",
    count: "28",
  },
  {
    id: "12",
    job_category: "Accountant",
    skill: "Financial Planning",
    count: "4",
  },
  {
    id: "13",
    job_category: "Accountant",
    skill: "Accounting Software (e.g. QuickBooks SAP)",
    count: "1",
  },
  {
    id: "14",
    job_category: "Accountant",
    skill: "Microsoft Excel",
    count: "3",
  },
  { id: "15", job_category: "Accountant", skill: "Data Entry", count: "12" },
  {
    id: "16",
    job_category: "Accountant",
    skill: "Attention to Detail",
    count: "89",
  },
  {
    id: "17",
    job_category: "Accountant",
    skill: "Analytical Skills",
    count: "93",
  },
  {
    id: "18",
    job_category: "Accountant",
    skill: "Communication Skills",
    count: "182",
  },
  {
    id: "19",
    job_category: "Accountant",
    skill: "Problem-Solving",
    count: "1",
  },
  {
    id: "20",
    job_category: "Accountant",
    skill: "Time Management",
    count: "167",
  },
  { id: "21", job_category: "Accountant", skill: "Ethics", count: "15" },
  {
    id: "22",
    job_category: "Accountant",
    skill: "Regulatory Knowledge",
    count: "10",
  },
  {
    id: "23",
    job_category: "Accountant",
    skill: "Cash Flow Management",
    count: "3",
  },
  {
    id: "24",
    job_category: "Accountant",
    skill: "Financial Forecasting",
    count: "2",
  },
  {
    id: "25",
    job_category: "Accountant",
    skill: "Variance Analysis",
    count: "2",
  },
  {
    id: "26",
    job_category: "Accountant",
    skill: "Bank Reconciliation",
    count: "2",
  },
  {
    id: "27",
    job_category: "Accountant",
    skill: "Internal Controls",
    count: "2",
  },
  {
    id: "28",
    job_category: "Accountant",
    skill: "Strategic Planning",
    count: "27",
  },
  {
    id: "29",
    job_category: "Accountant",
    skill: "Investment Analysis",
    count: "3",
  },
  {
    id: "30",
    job_category: "Accountant",
    skill: "Financial Modeling",
    count: "12",
  },
  { id: "31", job_category: "Actuary", skill: "Risk Assessment", count: "16" },
  {
    id: "32",
    job_category: "Actuary",
    skill: "Statistical Analysis",
    count: "13",
  },
  {
    id: "33",
    job_category: "Actuary",
    skill: "Probability Theory",
    count: "2",
  },
  { id: "35", job_category: "Actuary", skill: "Data Analysis", count: "37" },
  {
    id: "36",
    job_category: "Actuary",
    skill: "Predictive Analytics",
    count: "2",
  },
  { id: "37", job_category: "Actuary", skill: "Economic Modeling", count: "1" },
  {
    id: "38",
    job_category: "Actuary",
    skill: "Insurance Knowledge",
    count: "1",
  },
  { id: "39", job_category: "Actuary", skill: "Pension Planning", count: "1" },
  {
    id: "40",
    job_category: "Actuary",
    skill: "Investment Strategies",
    count: "3",
  },
  {
    id: "41",
    job_category: "Actuary",
    skill: "Regulatory Compliance",
    count: "23",
  },
  {
    id: "42",
    job_category: "Actuary",
    skill: "Mathematical Skills",
    count: "2",
  },
  {
    id: "43",
    job_category: "Actuary",
    skill: "Critical Thinking",
    count: "50",
  },
  { id: "44", job_category: "Actuary", skill: "Problem Solving", count: "175" },
  {
    id: "48",
    job_category: "Actuary",
    skill: "Programming (e.g. R Python)",
    count: "1",
  },
  {
    id: "49",
    job_category: "Actuary",
    skill: "Spreadsheet Software (e.g. Excel)",
    count: "1",
  },
  {
    id: "51",
    job_category: "Actuary",
    skill: "Actuarial Software (e.g. Prophet)",
    count: "1",
  },
  { id: "52", job_category: "Actuary", skill: "Market Research", count: "23" },
  { id: "55", job_category: "Actuary", skill: "Forecasting", count: "7" },
  {
    id: "56",
    job_category: "Actuary",
    skill: "Project Management",
    count: "79",
  },
  { id: "59", job_category: "Actuary", skill: "Decision Making", count: "43" },
  {
    id: "60",
    job_category: "Actuary",
    skill: "Presentation Skills",
    count: "16",
  },
  {
    id: "61",
    job_category: "Administrative Assistant",
    skill: "Microsoft Office Suite",
    count: "3",
  },
  {
    id: "62",
    job_category: "Administrative Assistant",
    skill: "Calendar Management",
    count: "4",
  },
  {
    id: "64",
    job_category: "Administrative Assistant",
    skill: "Organizational Skills",
    count: "15",
  },
  {
    id: "67",
    job_category: "Administrative Assistant",
    skill: "Document Preparation",
    count: "2",
  },
  {
    id: "68",
    job_category: "Administrative Assistant",
    skill: "Customer Service",
    count: "46",
  },
  {
    id: "70",
    job_category: "Administrative Assistant",
    skill: "Multitasking",
    count: "13",
  },
  {
    id: "72",
    job_category: "Administrative Assistant",
    skill: "Office Management",
    count: "3",
  },
  {
    id: "73",
    job_category: "Administrative Assistant",
    skill: "Scheduling",
    count: "6",
  },
  {
    id: "74",
    job_category: "Administrative Assistant",
    skill: "Travel Arrangements",
    count: "3",
  },
  {
    id: "75",
    job_category: "Administrative Assistant",
    skill: "Filing and Record Keeping",
    count: "1",
  },
  {
    id: "76",
    job_category: "Administrative Assistant",
    skill: "Meeting Coordination",
    count: "4",
  },
  {
    id: "77",
    job_category: "Administrative Assistant",
    skill: "Typing Skills",
    count: "1",
  },
  {
    id: "78",
    job_category: "Administrative Assistant",
    skill: "Email Management",
    count: "2",
  },
  {
    id: "79",
    job_category: "Administrative Assistant",
    skill: "Event Planning",
    count: "15",
  },
  {
    id: "80",
    job_category: "Administrative Assistant",
    skill: "Basic Accounting",
    count: "1",
  },
  {
    id: "81",
    job_category: "Administrative Assistant",
    skill: "Confidentiality",
    count: "6",
  },
  {
    id: "83",
    job_category: "Administrative Assistant",
    skill: "Research Skills",
    count: "33",
  },
  {
    id: "84",
    job_category: "Administrative Assistant",
    skill: "Interpersonal Skills",
    count: "23",
  },
  {
    id: "85",
    job_category: "Administrative Assistant",
    skill: "Supply Management",
    count: "1",
  },
  {
    id: "86",
    job_category: "Administrative Assistant",
    skill: "Database Management",
    count: "25",
  },
  {
    id: "87",
    job_category: "Administrative Assistant",
    skill: "Phone Etiquette",
    count: "1",
  },
  {
    id: "88",
    job_category: "Administrative Assistant",
    skill: "Report Generation",
    count: "1",
  },
  {
    id: "89",
    job_category: "Administrative Assistant",
    skill: "Professionalism",
    count: "9",
  },
  {
    id: "90",
    job_category: "Administrative Assistant",
    skill: "Office Equipment Operation",
    count: "2",
  },
  {
    id: "91",
    job_category: "Advertising Manager",
    skill: "Marketing Strategy",
    count: "6",
  },
  {
    id: "92",
    job_category: "Advertising Manager",
    skill: "Creative Thinking",
    count: "17",
  },
  {
    id: "93",
    job_category: "Advertising Manager",
    skill: "Campaign Management",
    count: "8",
  },
  {
    id: "95",
    job_category: "Advertising Manager",
    skill: "Brand Management",
    count: "10",
  },
  {
    id: "96",
    job_category: "Advertising Manager",
    skill: "Budget Management",
    count: "24",
  },
  {
    id: "98",
    job_category: "Advertising Manager",
    skill: "Negotiation Skills",
    count: "34",
  },
  {
    id: "99",
    job_category: "Advertising Manager",
    skill: "Digital Marketing",
    count: "15",
  },
  {
    id: "100",
    job_category: "Advertising Manager",
    skill: "Social Media Marketing",
    count: "7",
  },
  {
    id: "101",
    job_category: "Advertising Manager",
    skill: "SEO/SEM",
    count: "3",
  },
  {
    id: "102",
    job_category: "Advertising Manager",
    skill: "Content Creation",
    count: "16",
  },
  {
    id: "103",
    job_category: "Advertising Manager",
    skill: "Copywriting",
    count: "12",
  },
  {
    id: "105",
    job_category: "Advertising Manager",
    skill: "Client Relations",
    count: "41",
  },
  {
    id: "109",
    job_category: "Advertising Manager",
    skill: "Sales Skills",
    count: "8",
  },
  {
    id: "111",
    job_category: "Advertising Manager",
    skill: "Creative Direction",
    count: "2",
  },
  {
    id: "112",
    job_category: "Advertising Manager",
    skill: "Advertising Platforms (e.g. Google Ads)",
    count: "1",
  },
  {
    id: "114",
    job_category: "Advertising Manager",
    skill: "Team Leadership",
    count: "20",
  },
  {
    id: "116",
    job_category: "Advertising Manager",
    skill: "Public Relations",
    count: "9",
  },
  {
    id: "118",
    job_category: "Advertising Manager",
    skill: "Vendor Management",
    count: "14",
  },
  {
    id: "119",
    job_category: "Advertising Manager",
    skill: "Budget Forecasting",
    count: "1",
  },
  {
    id: "120",
    job_category: "Advertising Manager",
    skill: "Performance Metrics",
    count: "8",
  },
  {
    id: "121",
    job_category: "Aerospace Engineer",
    skill: "Aerodynamics",
    count: "1",
  },
  {
    id: "122",
    job_category: "Aerospace Engineer",
    skill: "Avionics",
    count: "2",
  },
  {
    id: "123",
    job_category: "Aerospace Engineer",
    skill: "CAD Software (e.g. AutoCAD)",
    count: "2",
  },
  {
    id: "124",
    job_category: "Aerospace Engineer",
    skill: "Propulsion Systems",
    count: "1",
  },
  {
    id: "125",
    job_category: "Aerospace Engineer",
    skill: "Structural Analysis",
    count: "3",
  },
  {
    id: "126",
    job_category: "Aerospace Engineer",
    skill: "Material Science",
    count: "3",
  },
  {
    id: "133",
    job_category: "Aerospace Engineer",
    skill: "Systems Engineering",
    count: "2",
  },
  {
    id: "134",
    job_category: "Aerospace Engineer",
    skill: "Mathematical Modeling",
    count: "4",
  },
  {
    id: "135",
    job_category: "Aerospace Engineer",
    skill: "Thermodynamics",
    count: "2",
  },
  {
    id: "136",
    job_category: "Aerospace Engineer",
    skill: "Flight Mechanics",
    count: "1",
  },
  {
    id: "137",
    job_category: "Aerospace Engineer",
    skill: "Testing and Evaluation",
    count: "1",
  },
  {
    id: "138",
    job_category: "Aerospace Engineer",
    skill: "Computer Programming",
    count: "1",
  },
  {
    id: "139",
    job_category: "Aerospace Engineer",
    skill: "Simulation Software",
    count: "2",
  },
  {
    id: "140",
    job_category: "Aerospace Engineer",
    skill: "Electronics",
    count: "2",
  },
  {
    id: "141",
    job_category: "Aerospace Engineer",
    skill: "Quality Assurance",
    count: "19",
  },
  {
    id: "142",
    job_category: "Aerospace Engineer",
    skill: "Technical Writing",
    count: "20",
  },
  {
    id: "144",
    job_category: "Aerospace Engineer",
    skill: "Team Collaboration",
    count: "53",
  },
  {
    id: "145",
    job_category: "Aerospace Engineer",
    skill: "Innovation",
    count: "16",
  },
  {
    id: "146",
    job_category: "Aerospace Engineer",
    skill: "Manufacturing Processes",
    count: "4",
  },
  {
    id: "147",
    job_category: "Aerospace Engineer",
    skill: "Safety Protocols",
    count: "12",
  },
  {
    id: "148",
    job_category: "Aerospace Engineer",
    skill: "Design Optimization",
    count: "1",
  },
  {
    id: "151",
    job_category: "Agricultural Engineer",
    skill: "Crop Production",
    count: "1",
  },
  {
    id: "152",
    job_category: "Agricultural Engineer",
    skill: "Irrigation Systems",
    count: "1",
  },
  {
    id: "153",
    job_category: "Agricultural Engineer",
    skill: "Soil Science",
    count: "1",
  },
  {
    id: "154",
    job_category: "Agricultural Engineer",
    skill: "CAD Software",
    count: "10",
  },
  {
    id: "155",
    job_category: "Agricultural Engineer",
    skill: "Machinery Design",
    count: "1",
  },
  {
    id: "159",
    job_category: "Agricultural Engineer",
    skill: "Environmental Impact Assessment",
    count: "6",
  },
  {
    id: "160",
    job_category: "Agricultural Engineer",
    skill: "Hydrology",
    count: "1",
  },
  {
    id: "161",
    job_category: "Agricultural Engineer",
    skill: "Agronomy",
    count: "1",
  },
  {
    id: "162",
    job_category: "Agricultural Engineer",
    skill: "Biological Engineering",
    count: "1",
  },
  {
    id: "163",
    job_category: "Agricultural Engineer",
    skill: "Food Processing",
    count: "2",
  },
  {
    id: "164",
    job_category: "Agricultural Engineer",
    skill: "Sustainable Agriculture",
    count: "1",
  },
  {
    id: "165",
    job_category: "Agricultural Engineer",
    skill: "Water Resource Management",
    count: "1",
  },
  {
    id: "166",
    job_category: "Agricultural Engineer",
    skill: "Pest Management",
    count: "1",
  },
  {
    id: "167",
    job_category: "Agricultural Engineer",
    skill: "Geographic Information Systems (GIS)",
    count: "6",
  },
  {
    id: "173",
    job_category: "Agricultural Engineer",
    skill: "Laboratory Skills",
    count: "2",
  },
  {
    id: "174",
    job_category: "Agricultural Engineer",
    skill: "Safety Management",
    count: "1",
  },
  {
    id: "176",
    job_category: "Agricultural Engineer",
    skill: "Biotechnology",
    count: "1",
  },
  {
    id: "177",
    job_category: "Agricultural Engineer",
    skill: "Renewable Energy",
    count: "2",
  },
  {
    id: "178",
    job_category: "Agricultural Engineer",
    skill: "Chemical Application",
    count: "1",
  },
  {
    id: "179",
    job_category: "Agricultural Engineer",
    skill: "Economics and Cost Analysis",
    count: "1",
  },
  {
    id: "183",
    job_category: "Air Traffic Controller",
    skill: "Situational Awareness",
    count: "2",
  },
  {
    id: "186",
    job_category: "Air Traffic Controller",
    skill: "Stress Management",
    count: "9",
  },
  {
    id: "188",
    job_category: "Air Traffic Controller",
    skill: "Technical Knowledge",
    count: "3",
  },
  {
    id: "189",
    job_category: "Air Traffic Controller",
    skill: "Radar Operation",
    count: "1",
  },
  {
    id: "190",
    job_category: "Air Traffic Controller",
    skill: "Navigation Systems",
    count: "1",
  },
  {
    id: "192",
    job_category: "Air Traffic Controller",
    skill: "Aircraft Separation Standards",
    count: "1",
  },
  {
    id: "193",
    job_category: "Air Traffic Controller",
    skill: "Emergency Procedures",
    count: "9",
  },
  {
    id: "194",
    job_category: "Air Traffic Controller",
    skill: "Coordination Skills",
    count: "1",
  },
  {
    id: "195",
    job_category: "Air Traffic Controller",
    skill: "Conflict Resolution",
    count: "28",
  },
  {
    id: "198",
    job_category: "Air Traffic Controller",
    skill: "Adaptability",
    count: "144",
  },
  {
    id: "200",
    job_category: "Air Traffic Controller",
    skill: "Flight Planning",
    count: "3",
  },
  {
    id: "203",
    job_category: "Air Traffic Controller",
    skill: "Aeronautical Knowledge",
    count: "1",
  },
  {
    id: "204",
    job_category: "Air Traffic Controller",
    skill: "Weather Interpretation",
    count: "2",
  },
  {
    id: "205",
    job_category: "Air Traffic Controller",
    skill: "Record Keeping",
    count: "5",
  },
  {
    id: "207",
    job_category: "Air Traffic Controller",
    skill: "Computer Literacy",
    count: "1",
  },
  {
    id: "208",
    job_category: "Air Traffic Controller",
    skill: "Attention to Safety",
    count: "4",
  },
  {
    id: "209",
    job_category: "Air Traffic Controller",
    skill: "Proactive Monitoring",
    count: "1",
  },
  {
    id: "210",
    job_category: "Air Traffic Controller",
    skill: "Operational Procedures",
    count: "2",
  },
  {
    id: "211",
    job_category: "Aircraft Mechanic",
    skill: "Aircraft Maintenance",
    count: "1",
  },
  {
    id: "212",
    job_category: "Aircraft Mechanic",
    skill: "Troubleshooting",
    count: "17",
  },
  {
    id: "215",
    job_category: "Aircraft Mechanic",
    skill: "Mechanical Skills",
    count: "3",
  },
  {
    id: "219",
    job_category: "Aircraft Mechanic",
    skill: "Diagnostic Skills",
    count: "6",
  },
  {
    id: "220",
    job_category: "Aircraft Mechanic",
    skill: "Hydraulic Systems",
    count: "1",
  },
  {
    id: "221",
    job_category: "Aircraft Mechanic",
    skill: "Electrical Systems",
    count: "5",
  },
  {
    id: "223",
    job_category: "Aircraft Mechanic",
    skill: "Inspection Skills",
    count: "2",
  },
  {
    id: "224",
    job_category: "Aircraft Mechanic",
    skill: "Tool Proficiency",
    count: "1",
  },
  {
    id: "225",
    job_category: "Aircraft Mechanic",
    skill: "Preventive Maintenance",
    count: "1",
  },
  {
    id: "226",
    job_category: "Aircraft Mechanic",
    skill: "Quality Control",
    count: "16",
  },
  {
    id: "228",
    job_category: "Aircraft Mechanic",
    skill: "Technical Documentation",
    count: "9",
  },
  {
    id: "231",
    job_category: "Aircraft Mechanic",
    skill: "Aircraft Systems Knowledge",
    count: "2",
  },
  {
    id: "233",
    job_category: "Aircraft Mechanic",
    skill: "Engine Repair",
    count: "1",
  },
  {
    id: "234",
    job_category: "Aircraft Mechanic",
    skill: "Operational Testing",
    count: "1",
  },
  {
    id: "235",
    job_category: "Aircraft Mechanic",
    skill: "Physical Stamina",
    count: "4",
  },
  {
    id: "236",
    job_category: "Aircraft Mechanic",
    skill: "Manual Dexterity",
    count: "2",
  },
  {
    id: "237",
    job_category: "Aircraft Mechanic",
    skill: "Technical Training",
    count: "1",
  },
  {
    id: "238",
    job_category: "Aircraft Mechanic",
    skill: "Inspection Standards",
    count: "1",
  },
  {
    id: "239",
    job_category: "Aircraft Mechanic",
    skill: "Detail-Oriented",
    count: "57",
  },
  {
    id: "240",
    job_category: "Aircraft Mechanic",
    skill: "Operational Efficiency",
    count: "1",
  },
  {
    id: "242",
    job_category: "Aircraft Pilot",
    skill: "Navigation",
    count: "1",
  },
  {
    id: "261",
    job_category: "Aircraft Pilot",
    skill: "Instrument Flight Rules (IFR)",
    count: "2",
  },
  {
    id: "262",
    job_category: "Aircraft Pilot",
    skill: "Visual Flight Rules (VFR)",
    count: "2",
  },
  {
    id: "263",
    job_category: "Aircraft Pilot",
    skill: "Flight Control Systems",
    count: "1",
  },
  {
    id: "265",
    job_category: "Aircraft Pilot",
    skill: "Pre-Flight Checks",
    count: "1",
  },
  {
    id: "266",
    job_category: "Aircraft Pilot",
    skill: "Crew Resource Management",
    count: "2",
  },
  {
    id: "269",
    job_category: "Aircraft Pilot",
    skill: "Performance Monitoring",
    count: "1",
  },
  {
    id: "270",
    job_category: "Aircraft Pilot",
    skill: "Logbook Maintenance",
    count: "1",
  },
  {
    id: "271",
    job_category: "Anthropologist",
    skill: "Field Research",
    count: "4",
  },
  {
    id: "273",
    job_category: "Anthropologist",
    skill: "Cultural Studies",
    count: "1",
  },
  {
    id: "274",
    job_category: "Anthropologist",
    skill: "Ethnography",
    count: "1",
  },
  {
    id: "275",
    job_category: "Anthropologist",
    skill: "Archaeology",
    count: "1",
  },
  {
    id: "278",
    job_category: "Anthropologist",
    skill: "Writing Skills",
    count: "3",
  },
  {
    id: "281",
    job_category: "Anthropologist",
    skill: "Historical Research",
    count: "4",
  },
  {
    id: "282",
    job_category: "Anthropologist",
    skill: "Qualitative Research",
    count: "1",
  },
  {
    id: "283",
    job_category: "Anthropologist",
    skill: "Quantitative Research",
    count: "2",
  },
  {
    id: "284",
    job_category: "Anthropologist",
    skill: "Interviewing Skills",
    count: "3",
  },
  {
    id: "285",
    job_category: "Anthropologist",
    skill: "Survey Design",
    count: "1",
  },
  {
    id: "286",
    job_category: "Anthropologist",
    skill: "Social Theory",
    count: "1",
  },
  {
    id: "291",
    job_category: "Anthropologist",
    skill: "Teaching Skills",
    count: "2",
  },
  {
    id: "292",
    job_category: "Anthropologist",
    skill: "Public Speaking",
    count: "13",
  },
  {
    id: "293",
    job_category: "Anthropologist",
    skill: "Cultural Sensitivity",
    count: "8",
  },
  {
    id: "296",
    job_category: "Anthropologist",
    skill: "Digital Humanities",
    count: "1",
  },
  {
    id: "297",
    job_category: "Anthropologist",
    skill: "Museum Curation",
    count: "2",
  },
  {
    id: "298",
    job_category: "Anthropologist",
    skill: "Historical Preservation",
    count: "1",
  },
  {
    id: "299",
    job_category: "Anthropologist",
    skill: "Research Funding",
    count: "1",
  },
  {
    id: "300",
    job_category: "Anthropologist",
    skill: "Ethical Considerations",
    count: "1",
  },
  {
    id: "301",
    job_category: "App Developer",
    skill: "Programming Languages (e.g. Java Swift)",
    count: "1",
  },
  {
    id: "302",
    job_category: "App Developer",
    skill: "Mobile App Development",
    count: "2",
  },
  {
    id: "303",
    job_category: "App Developer",
    skill: "User Interface (UI) Design",
    count: "4",
  },
  {
    id: "304",
    job_category: "App Developer",
    skill: "User Experience (UX) Design",
    count: "5",
  },
  {
    id: "305",
    job_category: "App Developer",
    skill: "API Integration",
    count: "7",
  },
  {
    id: "307",
    job_category: "App Developer",
    skill: "Version Control (e.g. Git)",
    count: "3",
  },
  { id: "315", job_category: "App Developer", skill: "Debugging", count: "5" },
  {
    id: "316",
    job_category: "App Developer",
    skill: "Performance Optimization",
    count: "4",
  },
  {
    id: "317",
    job_category: "App Developer",
    skill: "Security Best Practices",
    count: "1",
  },
  {
    id: "318",
    job_category: "App Developer",
    skill: "Testing and QA",
    count: "3",
  },
  {
    id: "319",
    job_category: "App Developer",
    skill: "Cross-Platform Development",
    count: "3",
  },
  {
    id: "320",
    job_category: "App Developer",
    skill: "Cloud Services",
    count: "1",
  },
  {
    id: "321",
    job_category: "App Developer",
    skill: "Agile Methodologies",
    count: "10",
  },
  {
    id: "322",
    job_category: "App Developer",
    skill: "Software Development Lifecycle (SDLC)",
    count: "2",
  },
  {
    id: "323",
    job_category: "App Developer",
    skill: "Front-End Development",
    count: "2",
  },
  {
    id: "324",
    job_category: "App Developer",
    skill: "Back-End Development",
    count: "2",
  },
  {
    id: "325",
    job_category: "App Developer",
    skill: "Responsive Design",
    count: "7",
  },
  {
    id: "326",
    job_category: "App Developer",
    skill: "Code Documentation",
    count: "1",
  },
  {
    id: "327",
    job_category: "App Developer",
    skill: "Frameworks and Libraries",
    count: "1",
  },
  {
    id: "328",
    job_category: "App Developer",
    skill: "Continuous Integration/Continuous Deployment (CI/CD)",
    count: "3",
  },
  {
    id: "330",
    job_category: "App Developer",
    skill: "Innovation and Creativity",
    count: "2",
  },
  {
    id: "331",
    job_category: "Architect",
    skill: "Architectural Design",
    count: "2",
  },
  {
    id: "333",
    job_category: "Architect",
    skill: "Building Information Modeling (BIM)",
    count: "1",
  },
  {
    id: "335",
    job_category: "Architect",
    skill: "Construction Documentation",
    count: "1",
  },
  {
    id: "342",
    job_category: "Architect",
    skill: "Sustainable Design",
    count: "1",
  },
  {
    id: "343",
    job_category: "Architect",
    skill: "Building Codes and Regulations",
    count: "1",
  },
  {
    id: "344",
    job_category: "Architect",
    skill: "Structural Engineering",
    count: "1",
  },
  { id: "345", job_category: "Architect", skill: "Site Analysis", count: "2" },
  {
    id: "347",
    job_category: "Architect",
    skill: "Conceptual Design",
    count: "1",
  },
  { id: "348", job_category: "Architect", skill: "3D Modeling", count: "7" },
  { id: "349", job_category: "Architect", skill: "Urban Planning", count: "3" },
  {
    id: "350",
    job_category: "Architect",
    skill: "Interior Design",
    count: "3",
  },
  {
    id: "351",
    job_category: "Architect",
    skill: "Landscape Architecture",
    count: "1",
  },
  {
    id: "352",
    job_category: "Architect",
    skill: "Materials Knowledge",
    count: "2",
  },
  {
    id: "353",
    job_category: "Architect",
    skill: "Cost Estimation",
    count: "4",
  },
  {
    id: "354",
    job_category: "Architect",
    skill: "Technical Drawing",
    count: "4",
  },
  {
    id: "355",
    job_category: "Architect",
    skill: "Construction Management",
    count: "5",
  },
  { id: "356", job_category: "Architect", skill: "Space Planning", count: "3" },
  {
    id: "357",
    job_category: "Architect",
    skill: "Building Systems Integration",
    count: "1",
  },
  {
    id: "358",
    job_category: "Architect",
    skill: "Architectural History",
    count: "1",
  },
  {
    id: "359",
    job_category: "Architect",
    skill: "Visualization Techniques",
    count: "1",
  },
  {
    id: "361",
    job_category: "Archivist",
    skill: "Records Management",
    count: "2",
  },
  { id: "362", job_category: "Archivist", skill: "Cataloging", count: "3" },
  {
    id: "363",
    job_category: "Archivist",
    skill: "Archival Research",
    count: "4",
  },
  {
    id: "364",
    job_category: "Archivist",
    skill: "Preservation Techniques",
    count: "1",
  },
  {
    id: "365",
    job_category: "Archivist",
    skill: "Digital Archiving",
    count: "2",
  },
  {
    id: "371",
    job_category: "Archivist",
    skill: "Metadata Standards",
    count: "1",
  },
  { id: "372", job_category: "Archivist", skill: "Digitization", count: "1" },
  { id: "373", job_category: "Archivist", skill: "Indexing", count: "1" },
  {
    id: "380",
    job_category: "Archivist",
    skill: "Information Management",
    count: "1",
  },
  {
    id: "381",
    job_category: "Archivist",
    skill: "Document Handling",
    count: "1",
  },
  {
    id: "387",
    job_category: "Archivist",
    skill: "Inventory Management",
    count: "13",
  },
  {
    id: "388",
    job_category: "Archivist",
    skill: "Legal Compliance",
    count: "8",
  },
  {
    id: "389",
    job_category: "Archivist",
    skill: "Technology Proficiency",
    count: "4",
  },
  {
    id: "390",
    job_category: "Archivist",
    skill: "Preservation Ethics",
    count: "1",
  },
  {
    id: "392",
    job_category: "Art Director",
    skill: "Graphic Design",
    count: "12",
  },
  {
    id: "395",
    job_category: "Art Director",
    skill: "Adobe Creative Suite",
    count: "9",
  },
  { id: "396", job_category: "Art Director", skill: "Typography", count: "9" },
  { id: "397", job_category: "Art Director", skill: "Branding", count: "2" },
  {
    id: "398",
    job_category: "Art Director",
    skill: "Concept Development",
    count: "7",
  },
  {
    id: "399",
    job_category: "Art Director",
    skill: "Visual Storytelling",
    count: "7",
  },
  {
    id: "404",
    job_category: "Art Director",
    skill: "Marketing Knowledge",
    count: "2",
  },
  { id: "406", job_category: "Art Director", skill: "Photography", count: "9" },
  {
    id: "407",
    job_category: "Art Director",
    skill: "Illustration",
    count: "9",
  },
  {
    id: "408",
    job_category: "Art Director",
    skill: "UX/UI Design",
    count: "1",
  },
  {
    id: "409",
    job_category: "Art Director",
    skill: "Video Production",
    count: "2",
  },
  { id: "411", job_category: "Art Director", skill: "Creativity", count: "12" },
  { id: "412", job_category: "Art Director", skill: "Advertising", count: "4" },
  {
    id: "413",
    job_category: "Art Director",
    skill: "Digital Media",
    count: "8",
  },
  {
    id: "414",
    job_category: "Art Director",
    skill: "Print Production",
    count: "2",
  },
  {
    id: "415",
    job_category: "Art Director",
    skill: "Motion Graphics",
    count: "3",
  },
  {
    id: "417",
    job_category: "Art Director",
    skill: "Color Theory",
    count: "12",
  },
  {
    id: "418",
    job_category: "Art Director",
    skill: "Storyboarding",
    count: "4",
  },
  {
    id: "419",
    job_category: "Art Director",
    skill: "Visual Merchandising",
    count: "3",
  },
  { id: "420", job_category: "Art Director", skill: "Web Design", count: "7" },
  { id: "421", job_category: "Artist", skill: "Drawing", count: "4" },
  { id: "422", job_category: "Artist", skill: "Painting", count: "3" },
  { id: "423", job_category: "Artist", skill: "Sculpture", count: "2" },
  { id: "426", job_category: "Artist", skill: "Art History", count: "1" },
  { id: "431", job_category: "Artist", skill: "Digital Art", count: "4" },
  { id: "434", job_category: "Artist", skill: "Printmaking", count: "2" },
  { id: "435", job_category: "Artist", skill: "Animation", count: "5" },
  { id: "437", job_category: "Artist", skill: "Video Editing", count: "3" },
  {
    id: "442",
    job_category: "Artist",
    skill: "Exhibition Planning",
    count: "2",
  },
  {
    id: "443",
    job_category: "Artist",
    skill: "Portfolio Development",
    count: "1",
  },
  { id: "445", job_category: "Artist", skill: "Marketing Skills", count: "2" },
  {
    id: "447",
    job_category: "Artist",
    skill: "Multimedia Production",
    count: "1",
  },
  {
    id: "451",
    job_category: "Arts Administrator",
    skill: "Fundraising",
    count: "2",
  },
  {
    id: "455",
    job_category: "Arts Administrator",
    skill: "Grant Writing",
    count: "4",
  },
  {
    id: "459",
    job_category: "Arts Administrator",
    skill: "Community Engagement",
    count: "4",
  },
  {
    id: "460",
    job_category: "Arts Administrator",
    skill: "Volunteer Coordination",
    count: "2",
  },
  {
    id: "472",
    job_category: "Arts Administrator",
    skill: "Social Media Management",
    count: "6",
  },
  {
    id: "474",
    job_category: "Arts Administrator",
    skill: "Report Writing",
    count: "10",
  },
  {
    id: "475",
    job_category: "Arts Administrator",
    skill: "Networking",
    count: "3",
  },
  {
    id: "477",
    job_category: "Arts Administrator",
    skill: "Event Coordination",
    count: "2",
  },
  {
    id: "478",
    job_category: "Arts Administrator",
    skill: "Resource Management",
    count: "4",
  },
  {
    id: "479",
    job_category: "Arts Administrator",
    skill: "Board Relations",
    count: "1",
  },
  {
    id: "480",
    job_category: "Athletic Trainer",
    skill: "Injury Assessment",
    count: "1",
  },
  {
    id: "481",
    job_category: "Athletic Trainer",
    skill: "Rehabilitation",
    count: "2",
  },
  {
    id: "482",
    job_category: "Athletic Trainer",
    skill: "Sports Medicine",
    count: "1",
  },
  {
    id: "483",
    job_category: "Athletic Trainer",
    skill: "First Aid",
    count: "4",
  },
  { id: "484", job_category: "Athletic Trainer", skill: "CPR", count: "3" },
  {
    id: "489",
    job_category: "Athletic Trainer",
    skill: "Anatomy Knowledge",
    count: "1",
  },
  {
    id: "490",
    job_category: "Athletic Trainer",
    skill: "Exercise Physiology",
    count: "3",
  },
  {
    id: "491",
    job_category: "Athletic Trainer",
    skill: "Nutrition",
    count: "3",
  },
  {
    id: "492",
    job_category: "Athletic Trainer",
    skill: "Therapeutic Modalities",
    count: "3",
  },
  {
    id: "493",
    job_category: "Athletic Trainer",
    skill: "Kinesiology",
    count: "1",
  },
  {
    id: "495",
    job_category: "Athletic Trainer",
    skill: "Patient Education",
    count: "12",
  },
  {
    id: "498",
    job_category: "Athletic Trainer",
    skill: "Fitness Training",
    count: "3",
  },
  {
    id: "499",
    job_category: "Athletic Trainer",
    skill: "Manual Therapy",
    count: "3",
  },
  {
    id: "500",
    job_category: "Athletic Trainer",
    skill: "Taping and Bracing",
    count: "1",
  },
  {
    id: "501",
    job_category: "Athletic Trainer",
    skill: "Hydration Management",
    count: "1",
  },
  {
    id: "502",
    job_category: "Athletic Trainer",
    skill: "Strength and Conditioning",
    count: "1",
  },
  {
    id: "503",
    job_category: "Athletic Trainer",
    skill: "Biomechanics",
    count: "3",
  },
  {
    id: "505",
    job_category: "Athletic Trainer",
    skill: "Health Promotion",
    count: "9",
  },
  {
    id: "506",
    job_category: "Athletic Trainer",
    skill: "Patient Advocacy",
    count: "6",
  },
  {
    id: "507",
    job_category: "Athletic Trainer",
    skill: "Clinical Skills",
    count: "4",
  },
  {
    id: "508",
    job_category: "Athletic Trainer",
    skill: "Medical Documentation",
    count: "3",
  },
  {
    id: "509",
    job_category: "Athletic Trainer",
    skill: "Performance Enhancement",
    count: "1",
  },
  { id: "510", job_category: "Attorney", skill: "Legal Research", count: "6" },
  { id: "511", job_category: "Attorney", skill: "Legal Writing", count: "5" },
  { id: "513", job_category: "Attorney", skill: "Litigation", count: "3" },
  { id: "520", job_category: "Attorney", skill: "Case Management", count: "9" },
  { id: "523", job_category: "Attorney", skill: "Document Review", count: "1" },
  {
    id: "524",
    job_category: "Attorney",
    skill: "Trial Preparation",
    count: "1",
  },
  { id: "525", job_category: "Attorney", skill: "Contract Law", count: "3" },
  { id: "527", job_category: "Attorney", skill: "Mediation", count: "2" },
  { id: "528", job_category: "Attorney", skill: "Corporate Law", count: "2" },
  {
    id: "529",
    job_category: "Attorney",
    skill: "Intellectual Property Law",
    count: "3",
  },
  { id: "530", job_category: "Attorney", skill: "Family Law", count: "2" },
  { id: "531", job_category: "Attorney", skill: "Criminal Law", count: "5" },
  { id: "532", job_category: "Attorney", skill: "Employment Law", count: "4" },
  { id: "533", job_category: "Attorney", skill: "Real Estate Law", count: "4" },
  {
    id: "535",
    job_category: "Attorney",
    skill: "Drafting Legal Documents",
    count: "1",
  },
  {
    id: "536",
    job_category: "Attorney",
    skill: "Court Procedures",
    count: "1",
  },
  {
    id: "537",
    job_category: "Attorney",
    skill: "Legal Counseling",
    count: "1",
  },
  {
    id: "538",
    job_category: "Attorney",
    skill: "Discovery Processes",
    count: "1",
  },
  {
    id: "539",
    job_category: "Attorney",
    skill: "Judicial Processes",
    count: "1",
  },
  {
    id: "540",
    job_category: "Audiologist",
    skill: "Hearing Assessment",
    count: "1",
  },
  {
    id: "541",
    job_category: "Audiologist",
    skill: "Patient Care",
    count: "17",
  },
  {
    id: "546",
    job_category: "Audiologist",
    skill: "Audiometric Testing",
    count: "1",
  },
  {
    id: "547",
    job_category: "Audiologist",
    skill: "Hearing Aid Fitting",
    count: "1",
  },
  {
    id: "548",
    job_category: "Audiologist",
    skill: "Aural Rehabilitation",
    count: "1",
  },
  {
    id: "549",
    job_category: "Audiologist",
    skill: "Pediatric Audiology",
    count: "1",
  },
  {
    id: "550",
    job_category: "Audiologist",
    skill: "Balance Assessment",
    count: "1",
  },
  {
    id: "551",
    job_category: "Audiologist",
    skill: "Hearing Conservation",
    count: "1",
  },
  {
    id: "552",
    job_category: "Audiologist",
    skill: "Cochlear Implants",
    count: "1",
  },
  {
    id: "558",
    job_category: "Audiologist",
    skill: "Technical Skills",
    count: "19",
  },
  {
    id: "561",
    job_category: "Audiologist",
    skill: "Counseling Skills",
    count: "1",
  },
  {
    id: "563",
    job_category: "Audiologist",
    skill: "Hearing Science",
    count: "1",
  },
  {
    id: "564",
    job_category: "Audiologist",
    skill: "Assistive Listening Devices",
    count: "1",
  },
  {
    id: "565",
    job_category: "Audiologist",
    skill: "Acoustic Measurement",
    count: "1",
  },
  {
    id: "566",
    job_category: "Audiologist",
    skill: "Vestibular Assessment",
    count: "1",
  },
  {
    id: "567",
    job_category: "Audiologist",
    skill: "Noise Control",
    count: "1",
  },
  {
    id: "568",
    job_category: "Audiologist",
    skill: "Speech-Language Pathology Knowledge",
    count: "1",
  },
  {
    id: "570",
    job_category: "Bank Manager",
    skill: "Financial Management",
    count: "9",
  },
  { id: "572", job_category: "Bank Manager", skill: "Leadership", count: "23" },
  {
    id: "584",
    job_category: "Bank Manager",
    skill: "Business Development",
    count: "6",
  },
  {
    id: "585",
    job_category: "Bank Manager",
    skill: "Loan Processing",
    count: "1",
  },
  {
    id: "586",
    job_category: "Bank Manager",
    skill: "Credit Analysis",
    count: "3",
  },
  {
    id: "590",
    job_category: "Bank Manager",
    skill: "Performance Management",
    count: "10",
  },
  {
    id: "593",
    job_category: "Bank Manager",
    skill: "Sales Management",
    count: "1",
  },
  {
    id: "594",
    job_category: "Bank Manager",
    skill: "Product Knowledge",
    count: "6",
  },
  {
    id: "596",
    job_category: "Bank Manager",
    skill: "Fraud Prevention",
    count: "1",
  },
  {
    id: "597",
    job_category: "Bank Manager",
    skill: "Audit Management",
    count: "2",
  },
  { id: "600", job_category: "Bartender", skill: "Mixology", count: "1" },
  { id: "607", job_category: "Bartender", skill: "Cash Handling", count: "4" },
  {
    id: "608",
    job_category: "Bartender",
    skill: "Knowledge of Beverages",
    count: "1",
  },
  { id: "612", job_category: "Bartender", skill: "Cleanliness", count: "1" },
  { id: "615", job_category: "Bartender", skill: "Hospitality", count: "1" },
  {
    id: "616",
    job_category: "Bartender",
    skill: "Order Processing",
    count: "2",
  },
  { id: "617", job_category: "Bartender", skill: "Upselling", count: "3" },
  {
    id: "618",
    job_category: "Bartender",
    skill: "Bar Equipment Operation",
    count: "1",
  },
  { id: "619", job_category: "Bartender", skill: "Menu Knowledge", count: "2" },
  { id: "620", job_category: "Bartender", skill: "Food Pairing", count: "1" },
  {
    id: "624",
    job_category: "Bartender",
    skill: "Point of Sale (POS) Systems",
    count: "2",
  },
  {
    id: "626",
    job_category: "Bartender",
    skill: "Inventory Control",
    count: "2",
  },
  {
    id: "628",
    job_category: "Bartender",
    skill: "Recipe Memorization",
    count: "1",
  },
  {
    id: "629",
    job_category: "Bartender",
    skill: "Guest Relations",
    count: "2",
  },
  {
    id: "630",
    job_category: "Biochemist",
    skill: "Molecular Biology",
    count: "1",
  },
  { id: "631", job_category: "Biochemist", skill: "Cell Culture", count: "1" },
  {
    id: "632",
    job_category: "Biochemist",
    skill: "Protein Purification",
    count: "1",
  },
  { id: "633", job_category: "Biochemist", skill: "Enzyme Assays", count: "1" },
  {
    id: "634",
    job_category: "Biochemist",
    skill: "Chromatography",
    count: "2",
  },
  {
    id: "635",
    job_category: "Biochemist",
    skill: "Mass Spectrometry",
    count: "2",
  },
  {
    id: "637",
    job_category: "Biochemist",
    skill: "Laboratory Techniques",
    count: "5",
  },
  { id: "646", job_category: "Biochemist", skill: "Genetics", count: "2" },
  {
    id: "647",
    job_category: "Biochemist",
    skill: "Bioinformatics",
    count: "3",
  },
  {
    id: "648",
    job_category: "Biochemist",
    skill: "Clinical Research",
    count: "3",
  },
  { id: "651", job_category: "Biochemist", skill: "PCR", count: "1" },
  {
    id: "652",
    job_category: "Biochemist",
    skill: "Western Blotting",
    count: "1",
  },
  { id: "653", job_category: "Biochemist", skill: "ELISA", count: "1" },
  { id: "654", job_category: "Biochemist", skill: "Spectroscopy", count: "2" },
  { id: "655", job_category: "Biochemist", skill: "Microscopy", count: "1" },
  {
    id: "656",
    job_category: "Biochemist",
    skill: "Experiment Design",
    count: "1",
  },
  {
    id: "660",
    job_category: "Biomedical Engineer",
    skill: "Biomedical Instrumentation",
    count: "1",
  },
  {
    id: "661",
    job_category: "Biomedical Engineer",
    skill: "Medical Imaging",
    count: "2",
  },
  {
    id: "662",
    job_category: "Biomedical Engineer",
    skill: "Biomaterials",
    count: "1",
  },
  {
    id: "671",
    job_category: "Biomedical Engineer",
    skill: "Device Fabrication",
    count: "1",
  },
  {
    id: "672",
    job_category: "Biomedical Engineer",
    skill: "Circuit Design",
    count: "2",
  },
  {
    id: "673",
    job_category: "Biomedical Engineer",
    skill: "Prototyping",
    count: "6",
  },
  {
    id: "676",
    job_category: "Biomedical Engineer",
    skill: "Programming",
    count: "3",
  },
  {
    id: "677",
    job_category: "Biomedical Engineer",
    skill: "Signal Processing",
    count: "1",
  },
  {
    id: "680",
    job_category: "Biomedical Engineer",
    skill: "Testing and Validation",
    count: "1",
  },
  {
    id: "681",
    job_category: "Biomedical Engineer",
    skill: "Microfluidics",
    count: "1",
  },
  {
    id: "682",
    job_category: "Biomedical Engineer",
    skill: "Cellular Engineering",
    count: "1",
  },
  {
    id: "683",
    job_category: "Biomedical Engineer",
    skill: "Nanotechnology",
    count: "2",
  },
  {
    id: "684",
    job_category: "Biomedical Engineer",
    skill: "Clinical Trials",
    count: "3",
  },
  {
    id: "688",
    job_category: "Biomedical Engineer",
    skill: "Healthcare Technology",
    count: "1",
  },
  {
    id: "693",
    job_category: "Biostatistician",
    skill: "R Programming",
    count: "4",
  },
  {
    id: "694",
    job_category: "Biostatistician",
    skill: "SAS Programming",
    count: "1",
  },
  {
    id: "702",
    job_category: "Biostatistician",
    skill: "Experimental Design",
    count: "4",
  },
  {
    id: "703",
    job_category: "Biostatistician",
    skill: "Data Visualization",
    count: "6",
  },
  { id: "704", job_category: "Biostatistician", skill: "SPSS", count: "1" },
  {
    id: "705",
    job_category: "Biostatistician",
    skill: "Python Programming",
    count: "2",
  },
  {
    id: "706",
    job_category: "Biostatistician",
    skill: "Statistical Software",
    count: "2",
  },
  {
    id: "707",
    job_category: "Biostatistician",
    skill: "Survival Analysis",
    count: "1",
  },
  {
    id: "708",
    job_category: "Biostatistician",
    skill: "Regression Analysis",
    count: "1",
  },
  {
    id: "710",
    job_category: "Biostatistician",
    skill: "Epidemiology",
    count: "2",
  },
  {
    id: "717",
    job_category: "Biostatistician",
    skill: "Machine Learning",
    count: "8",
  },
  {
    id: "719",
    job_category: "Biostatistician",
    skill: "Healthcare Data Analysis",
    count: "1",
  },
  {
    id: "724",
    job_category: "Bookkeeper",
    skill: "Payroll Processing",
    count: "3",
  },
  {
    id: "732",
    job_category: "Bookkeeper",
    skill: "Accounting Software",
    count: "1",
  },
  {
    id: "741",
    job_category: "Bookkeeper",
    skill: "Expense Tracking",
    count: "1",
  },
  { id: "742", job_category: "Bookkeeper", skill: "Invoicing", count: "1" },
  {
    id: "751",
    job_category: "Brand Manager",
    skill: "Brand Development",
    count: "1",
  },
  {
    id: "768",
    job_category: "Brand Manager",
    skill: "Customer Insights",
    count: "1",
  },
  {
    id: "769",
    job_category: "Brand Manager",
    skill: "Brand Positioning",
    count: "1",
  },
  {
    id: "770",
    job_category: "Brand Manager",
    skill: "Competitive Analysis",
    count: "3",
  },
  {
    id: "772",
    job_category: "Brand Manager",
    skill: "Product Development",
    count: "7",
  },
  {
    id: "773",
    job_category: "Brand Manager",
    skill: "Brand Guidelines",
    count: "1",
  },
  {
    id: "785",
    job_category: "Building Inspector",
    skill: "Building Codes",
    count: "6",
  },
  {
    id: "786",
    job_category: "Building Inspector",
    skill: "Safety Standards",
    count: "1",
  },
  {
    id: "792",
    job_category: "Building Inspector",
    skill: "Construction Knowledge",
    count: "2",
  },
  {
    id: "794",
    job_category: "Building Inspector",
    skill: "Plumbing Systems",
    count: "2",
  },
  {
    id: "795",
    job_category: "Building Inspector",
    skill: "HVAC Systems",
    count: "3",
  },
  {
    id: "796",
    job_category: "Building Inspector",
    skill: "Fire Safety",
    count: "2",
  },
  {
    id: "798",
    job_category: "Building Inspector",
    skill: "Zoning Regulations",
    count: "1",
  },
  {
    id: "801",
    job_category: "Building Inspector",
    skill: "Legal Knowledge",
    count: "3",
  },
  {
    id: "805",
    job_category: "Building Inspector",
    skill: "Building Materials Knowledge",
    count: "1",
  },
  {
    id: "806",
    job_category: "Building Inspector",
    skill: "Documentation Skills",
    count: "2",
  },
  {
    id: "808",
    job_category: "Building Inspector",
    skill: "Site Assessments",
    count: "1",
  },
  {
    id: "811",
    job_category: "Business Analyst",
    skill: "Business Process Improvement",
    count: "2",
  },
  {
    id: "812",
    job_category: "Business Analyst",
    skill: "Requirements Gathering",
    count: "1",
  },
  {
    id: "818",
    job_category: "Business Analyst",
    skill: "Stakeholder Management",
    count: "4",
  },
  { id: "822", job_category: "Business Analyst", skill: "SQL", count: "9" },
  {
    id: "823",
    job_category: "Business Analyst",
    skill: "Reporting Skills",
    count: "2",
  },
  {
    id: "830",
    job_category: "Business Analyst",
    skill: "Workflow Analysis",
    count: "2",
  },
  {
    id: "831",
    job_category: "Business Analyst",
    skill: "Software Development Life Cycle (SDLC)",
    count: "1",
  },
  {
    id: "834",
    job_category: "Business Analyst",
    skill: "Process Mapping",
    count: "1",
  },
  {
    id: "836",
    job_category: "Business Analyst",
    skill: "Customer Relationship Management (CRM)",
    count: "6",
  },
  {
    id: "838",
    job_category: "Business Analyst",
    skill: "Business Intelligence Tools",
    count: "2",
  },
  {
    id: "852",
    job_category: "Business Consultant",
    skill: "Change Management",
    count: "10",
  },
  {
    id: "865",
    job_category: "Business Consultant",
    skill: "Business Intelligence",
    count: "1",
  },
  {
    id: "869",
    job_category: "Business Consultant",
    skill: "Supply Chain Management",
    count: "8",
  },
  {
    id: "893",
    job_category: "Business Development Manager",
    skill: "Lead Generation",
    count: "4",
  },
  {
    id: "895",
    job_category: "Business Development Manager",
    skill: "Contract Management",
    count: "3",
  },
  {
    id: "900",
    job_category: "Business Teacher",
    skill: "Curriculum Development",
    count: "10",
  },
  {
    id: "901",
    job_category: "Business Teacher",
    skill: "Classroom Management",
    count: "7",
  },
  {
    id: "902",
    job_category: "Business Teacher",
    skill: "Teaching",
    count: "3",
  },
  {
    id: "903",
    job_category: "Business Teacher",
    skill: "Educational Technology",
    count: "6",
  },
  {
    id: "904",
    job_category: "Business Teacher",
    skill: "Lesson Planning",
    count: "7",
  },
  {
    id: "907",
    job_category: "Business Teacher",
    skill: "Instructional Design",
    count: "4",
  },
  {
    id: "911",
    job_category: "Business Teacher",
    skill: "Student Assessment",
    count: "6",
  },
  {
    id: "913",
    job_category: "Business Teacher",
    skill: "Student Engagement",
    count: "3",
  },
  {
    id: "915",
    job_category: "Business Teacher",
    skill: "Accounting",
    count: "7",
  },
  {
    id: "916",
    job_category: "Business Teacher",
    skill: "Economics",
    count: "1",
  },
  { id: "917", job_category: "Business Teacher", skill: "Finance", count: "1" },
  {
    id: "918",
    job_category: "Business Teacher",
    skill: "Marketing",
    count: "3",
  },
  {
    id: "919",
    job_category: "Business Teacher",
    skill: "Entrepreneurship",
    count: "4",
  },
  {
    id: "920",
    job_category: "Business Teacher",
    skill: "Business Ethics",
    count: "1",
  },
  {
    id: "921",
    job_category: "Business Teacher",
    skill: "Digital Literacy",
    count: "2",
  },
  {
    id: "923",
    job_category: "Business Teacher",
    skill: "Mentoring",
    count: "5",
  },
  {
    id: "925",
    job_category: "Business Teacher",
    skill: "Motivational Skills",
    count: "2",
  },
  {
    id: "928",
    job_category: "Business Teacher",
    skill: "Innovative Teaching Methods",
    count: "1",
  },
  {
    id: "929",
    job_category: "Business Teacher",
    skill: "Cultural Awareness",
    count: "4",
  },
  {
    id: "930",
    job_category: "C++ Developer",
    skill: "C++ Programming",
    count: "1",
  },
  {
    id: "931",
    job_category: "C++ Developer",
    skill: "Object-Oriented Programming (OOP)",
    count: "6",
  },
  {
    id: "932",
    job_category: "C++ Developer",
    skill: "Algorithm Design",
    count: "4",
  },
  {
    id: "933",
    job_category: "C++ Developer",
    skill: "Data Structures",
    count: "4",
  },
  {
    id: "935",
    job_category: "C++ Developer",
    skill: "Software Development",
    count: "8",
  },
  {
    id: "936",
    job_category: "C++ Developer",
    skill: "Version Control (Git)",
    count: "1",
  },
  {
    id: "941",
    job_category: "C++ Developer",
    skill: "Coding Standards",
    count: "1",
  },
  {
    id: "942",
    job_category: "C++ Developer",
    skill: "Unit Testing",
    count: "3",
  },
  {
    id: "943",
    job_category: "C++ Developer",
    skill: "Multithreading",
    count: "1",
  },
  {
    id: "945",
    job_category: "C++ Developer",
    skill: "Memory Management",
    count: "1",
  },
  { id: "946", job_category: "C++ Developer", skill: "Linux/Unix", count: "3" },
  {
    id: "947",
    job_category: "C++ Developer",
    skill: "Windows Programming",
    count: "1",
  },
  {
    id: "948",
    job_category: "C++ Developer",
    skill: "GUI Development",
    count: "1",
  },
  {
    id: "949",
    job_category: "C++ Developer",
    skill: "Embedded Systems",
    count: "3",
  },
  {
    id: "950",
    job_category: "C++ Developer",
    skill: "Network Programming",
    count: "1",
  },
  {
    id: "952",
    job_category: "C++ Developer",
    skill: "Web Development",
    count: "10",
  },
  {
    id: "954",
    job_category: "C++ Developer",
    skill: "AI/Machine Learning",
    count: "1",
  },
  {
    id: "955",
    job_category: "C++ Developer",
    skill: "Game Development",
    count: "3",
  },
  {
    id: "956",
    job_category: "C++ Developer",
    skill: "Parallel Programming",
    count: "1",
  },
  {
    id: "957",
    job_category: "C++ Developer",
    skill: "Software Architecture",
    count: "2",
  },
  {
    id: "959",
    job_category: "C++ Developer",
    skill: "Problem Analysis",
    count: "1",
  },
  {
    id: "961",
    job_category: "CAD Technician",
    skill: "Technical Drawings",
    count: "1",
  },
  {
    id: "962",
    job_category: "CAD Technician",
    skill: "Blueprint Reading",
    count: "8",
  },
  {
    id: "963",
    job_category: "CAD Technician",
    skill: "Engineering Design",
    count: "1",
  },
  { id: "964", job_category: "CAD Technician", skill: "AutoCAD", count: "4" },
  {
    id: "965",
    job_category: "CAD Technician",
    skill: "SolidWorks",
    count: "2",
  },
  {
    id: "966",
    job_category: "CAD Technician",
    skill: "Drafting Standards",
    count: "1",
  },
  {
    id: "968",
    job_category: "CAD Technician",
    skill: "Mechanical Design",
    count: "2",
  },
  {
    id: "969",
    job_category: "CAD Technician",
    skill: "Electrical Design",
    count: "1",
  },
  {
    id: "971",
    job_category: "CAD Technician",
    skill: "Civil Design",
    count: "1",
  },
  {
    id: "972",
    job_category: "CAD Technician",
    skill: "HVAC Design",
    count: "1",
  },
  {
    id: "973",
    job_category: "CAD Technician",
    skill: "Piping Design",
    count: "1",
  },
  {
    id: "974",
    job_category: "CAD Technician",
    skill: "Construction Drawings",
    count: "1",
  },
  {
    id: "975",
    job_category: "CAD Technician",
    skill: "BIM (Building Information Modeling)",
    count: "1",
  },
  {
    id: "976",
    job_category: "CAD Technician",
    skill: "Design Analysis",
    count: "1",
  },
  {
    id: "977",
    job_category: "CAD Technician",
    skill: "Geometric Dimensioning and Tolerancing (GD&T)",
    count: "1",
  },
  {
    id: "979",
    job_category: "CAD Technician",
    skill: "Collaboration Tools",
    count: "1",
  },
  {
    id: "980",
    job_category: "CAD Technician",
    skill: "Data Management",
    count: "3",
  },
  { id: "985", job_category: "CAD Technician", skill: "Teamwork", count: "42" },
  {
    id: "986",
    job_category: "CAD Technician",
    skill: "Mathematics",
    count: "13",
  },
  {
    id: "989",
    job_category: "CAD Technician",
    skill: "Material Selection",
    count: "3",
  },
  {
    id: "990",
    job_category: "Call Center Manager",
    skill: "Call Center Operations",
    count: "3",
  },
  {
    id: "991",
    job_category: "Call Center Manager",
    skill: "Customer Service Management",
    count: "2",
  },
  {
    id: "994",
    job_category: "Call Center Manager",
    skill: "Coaching and Training",
    count: "1",
  },
  {
    id: "996",
    job_category: "Call Center Manager",
    skill: "Call Monitoring",
    count: "1",
  },
  {
    id: "997",
    job_category: "Call Center Manager",
    skill: "KPI Management",
    count: "2",
  },
  {
    id: "999",
    job_category: "Call Center Manager",
    skill: "Workforce Management",
    count: "1",
  },
  {
    id: "1004",
    job_category: "Call Center Manager",
    skill: "CRM Software",
    count: "1",
  },
  {
    id: "1006",
    job_category: "Call Center Manager",
    skill: "Customer Satisfaction",
    count: "4",
  },
  {
    id: "1007",
    job_category: "Call Center Manager",
    skill: "Process Improvement",
    count: "17",
  },
  {
    id: "1008",
    job_category: "Call Center Manager",
    skill: "Employee Engagement",
    count: "3",
  },
  {
    id: "1009",
    job_category: "Call Center Manager",
    skill: "Technical Support",
    count: "10",
  },
  {
    id: "1010",
    job_category: "Call Center Manager",
    skill: "Telecommunications",
    count: "1",
  },
  {
    id: "1013",
    job_category: "Call Center Manager",
    skill: "Training Development",
    count: "1",
  },
  { id: "1020", job_category: "Carpenter", skill: "Carpentry", count: "2" },
  { id: "1021", job_category: "Carpenter", skill: "Construction", count: "1" },
  { id: "1024", job_category: "Carpenter", skill: "Power Tools", count: "4" },
  { id: "1025", job_category: "Carpenter", skill: "Hand Tools", count: "4" },
  {
    id: "1026",
    job_category: "Carpenter",
    skill: "Measurement Skills",
    count: "1",
  },
  { id: "1027", job_category: "Carpenter", skill: "Woodworking", count: "1" },
  { id: "1028", job_category: "Carpenter", skill: "Framing", count: "1" },
  {
    id: "1029",
    job_category: "Carpenter",
    skill: "Finish Carpentry",
    count: "1",
  },
  { id: "1030", job_category: "Carpenter", skill: "Trim Work", count: "1" },
  {
    id: "1031",
    job_category: "Carpenter",
    skill: "Cabinet Making",
    count: "1",
  },
  {
    id: "1032",
    job_category: "Carpenter",
    skill: "Flooring Installation",
    count: "1",
  },
  { id: "1033", job_category: "Carpenter", skill: "Roofing", count: "1" },
  {
    id: "1034",
    job_category: "Carpenter",
    skill: "Siding Installation",
    count: "1",
  },
  {
    id: "1035",
    job_category: "Carpenter",
    skill: "Window Installation",
    count: "1",
  },
  {
    id: "1036",
    job_category: "Carpenter",
    skill: "Door Installation",
    count: "1",
  },
  { id: "1037", job_category: "Carpenter", skill: "Concrete Work", count: "1" },
  { id: "1038", job_category: "Carpenter", skill: "Renovation", count: "1" },
  { id: "1039", job_category: "Carpenter", skill: "Remodeling", count: "1" },
  {
    id: "1041",
    job_category: "Carpenter",
    skill: "Exterior Design",
    count: "1",
  },
  { id: "1046", job_category: "Carpenter", skill: "Estimating", count: "1" },
  {
    id: "1050",
    job_category: "Cartographer",
    skill: "Cartography",
    count: "2",
  },
  {
    id: "1052",
    job_category: "Cartographer",
    skill: "Geospatial Data Analysis",
    count: "1",
  },
  {
    id: "1053",
    job_category: "Cartographer",
    skill: "Remote Sensing",
    count: "5",
  },
  { id: "1054", job_category: "Cartographer", skill: "Map Design", count: "1" },
  {
    id: "1056",
    job_category: "Cartographer",
    skill: "Spatial Analysis",
    count: "2",
  },
  {
    id: "1057",
    job_category: "Cartographer",
    skill: "Topographic Mapping",
    count: "2",
  },
  {
    id: "1058",
    job_category: "Cartographer",
    skill: "Map Projection",
    count: "1",
  },
  { id: "1059", job_category: "Cartographer", skill: "Surveying", count: "2" },
  {
    id: "1060",
    job_category: "Cartographer",
    skill: "GPS Technology",
    count: "1",
  },
  { id: "1061", job_category: "Cartographer", skill: "Geocoding", count: "1" },
  {
    id: "1062",
    job_category: "Cartographer",
    skill: "Geological Mapping",
    count: "2",
  },
  {
    id: "1063",
    job_category: "Cartographer",
    skill: "Climate Mapping",
    count: "1",
  },
  {
    id: "1065",
    job_category: "Cartographer",
    skill: "Environmental Mapping",
    count: "1",
  },
  {
    id: "1066",
    job_category: "Cartographer",
    skill: "Software Proficiency",
    count: "2",
  },
  {
    id: "1076",
    job_category: "Cartographer",
    skill: "Earth Sciences",
    count: "1",
  },
  { id: "1077", job_category: "Cartographer", skill: "Field Work", count: "5" },
  {
    id: "1078",
    job_category: "Cartographer",
    skill: "Government Regulations",
    count: "1",
  },
  {
    id: "1081",
    job_category: "Case Manager",
    skill: "Client Assessment",
    count: "4",
  },
  {
    id: "1082",
    job_category: "Case Manager",
    skill: "Needs Analysis",
    count: "1",
  },
  {
    id: "1083",
    job_category: "Case Manager",
    skill: "Resource Coordination",
    count: "1",
  },
  {
    id: "1084",
    job_category: "Case Manager",
    skill: "Social Services",
    count: "2",
  },
  { id: "1085", job_category: "Case Manager", skill: "Counseling", count: "5" },
  { id: "1086", job_category: "Case Manager", skill: "Advocacy", count: "2" },
  {
    id: "1087",
    job_category: "Case Manager",
    skill: "Crisis Intervention",
    count: "7",
  },
  {
    id: "1089",
    job_category: "Case Manager",
    skill: "Referral Services",
    count: "1",
  },
  {
    id: "1090",
    job_category: "Case Manager",
    skill: "Documentation",
    count: "9",
  },
  {
    id: "1091",
    job_category: "Case Manager",
    skill: "Medical Records",
    count: "2",
  },
  {
    id: "1093",
    job_category: "Case Manager",
    skill: "Community Outreach",
    count: "7",
  },
  {
    id: "1099",
    job_category: "Case Manager",
    skill: "Emotional Intelligence",
    count: "3",
  },
  { id: "1101", job_category: "Case Manager", skill: "Empathy", count: "20" },
  {
    id: "1104",
    job_category: "Case Manager",
    skill: "Cultural Competence",
    count: "4",
  },
  {
    id: "1106",
    job_category: "Case Manager",
    skill: "Mental Health",
    count: "1",
  },
  {
    id: "1107",
    job_category: "Case Manager",
    skill: "Substance Abuse",
    count: "1",
  },
  {
    id: "1108",
    job_category: "Case Manager",
    skill: "Healthcare Systems",
    count: "1",
  },
  {
    id: "1109",
    job_category: "Case Manager",
    skill: "Client Empowerment",
    count: "1",
  },
  {
    id: "1114",
    job_category: "Cashier",
    skill: "Sales Transactions",
    count: "1",
  },
  {
    id: "1115",
    job_category: "Cashier",
    skill: "Payment Processing",
    count: "2",
  },
  {
    id: "1116",
    job_category: "Cashier",
    skill: "Order Fulfillment",
    count: "2",
  },
  {
    id: "1118",
    job_category: "Cashier",
    skill: "Refund Processing",
    count: "1",
  },
  {
    id: "1126",
    job_category: "Cashier",
    skill: "Upselling Techniques",
    count: "1",
  },
  { id: "1127", job_category: "Cashier", skill: "Merchandising", count: "2" },
  {
    id: "1128",
    job_category: "Cashier",
    skill: "Store Operations",
    count: "1",
  },
  {
    id: "1131",
    job_category: "Cashier",
    skill: "Security Procedures",
    count: "2",
  },
  {
    id: "1132",
    job_category: "Cashier",
    skill: "Financial Transactions",
    count: "1",
  },
  { id: "1133", job_category: "Cashier", skill: "Product Returns", count: "1" },
  {
    id: "1134",
    job_category: "Cashier",
    skill: "Price Adjustments",
    count: "1",
  },
  {
    id: "1135",
    job_category: "Cashier",
    skill: "Problem Identification",
    count: "2",
  },
  { id: "1137", job_category: "Cashier", skill: "Efficiency", count: "2" },
  { id: "1138", job_category: "Cashier", skill: "Patience", count: "11" },
  { id: "1139", job_category: "Cashier", skill: "Flexibility", count: "3" },
  { id: "1140", job_category: "Chef", skill: "Culinary Arts", count: "1" },
  { id: "1141", job_category: "Chef", skill: "Food Preparation", count: "2" },
  { id: "1142", job_category: "Chef", skill: "Menu Planning", count: "1" },
  { id: "1143", job_category: "Chef", skill: "Cooking Techniques", count: "1" },
  { id: "1144", job_category: "Chef", skill: "Kitchen Management", count: "1" },
  { id: "1145", job_category: "Chef", skill: "Food Safety", count: "3" },
  {
    id: "1146",
    job_category: "Chef",
    skill: "Sanitation Standards",
    count: "1",
  },
  { id: "1149", job_category: "Chef", skill: "Recipe Development", count: "1" },
  { id: "1161", job_category: "Chef", skill: "Multi-tasking", count: "5" },
  { id: "1162", job_category: "Chef", skill: "Catering", count: "1" },
  { id: "1163", job_category: "Chef", skill: "Fine Dining", count: "1" },
  { id: "1164", job_category: "Chef", skill: "Menu Costing", count: "1" },
  {
    id: "1165",
    job_category: "Chef",
    skill: "Ingredient Sourcing",
    count: "1",
  },
  {
    id: "1166",
    job_category: "Chef",
    skill: "International Cuisine",
    count: "1",
  },
  {
    id: "1167",
    job_category: "Chef",
    skill: "Restaurant Operations",
    count: "2",
  },
  { id: "1168", job_category: "Chef", skill: "Staff Training", count: "2" },
  {
    id: "1170",
    job_category: "Chemical Engineer",
    skill: "Chemical Engineering",
    count: "3",
  },
  {
    id: "1171",
    job_category: "Chemical Engineer",
    skill: "Process Optimization",
    count: "3",
  },
  {
    id: "1172",
    job_category: "Chemical Engineer",
    skill: "Unit Operations",
    count: "1",
  },
  {
    id: "1173",
    job_category: "Chemical Engineer",
    skill: "Mass Transfer",
    count: "1",
  },
  {
    id: "1174",
    job_category: "Chemical Engineer",
    skill: "Heat Transfer",
    count: "2",
  },
  {
    id: "1175",
    job_category: "Chemical Engineer",
    skill: "Fluid Dynamics",
    count: "1",
  },
  {
    id: "1176",
    job_category: "Chemical Engineer",
    skill: "Reaction Engineering",
    count: "1",
  },
  {
    id: "1178",
    job_category: "Chemical Engineer",
    skill: "Chemical Kinetics",
    count: "1",
  },
  {
    id: "1179",
    job_category: "Chemical Engineer",
    skill: "Plant Design",
    count: "1",
  },
  {
    id: "1180",
    job_category: "Chemical Engineer",
    skill: "Process Safety",
    count: "1",
  },
  {
    id: "1181",
    job_category: "Chemical Engineer",
    skill: "Environmental Compliance",
    count: "2",
  },
  {
    id: "1186",
    job_category: "Chemical Engineer",
    skill: "Research and Development (R&D)",
    count: "1",
  },
  {
    id: "1191",
    job_category: "Chemical Engineer",
    skill: "Regulatory Standards",
    count: "1",
  },
  {
    id: "1198",
    job_category: "Chemical Engineer",
    skill: "Physics",
    count: "4",
  },
  { id: "1200", job_category: "Chemist", skill: "Chemistry", count: "1" },
  {
    id: "1202",
    job_category: "Chemist",
    skill: "Analytical Chemistry",
    count: "1",
  },
  {
    id: "1203",
    job_category: "Chemist",
    skill: "Organic Chemistry",
    count: "1",
  },
  {
    id: "1204",
    job_category: "Chemist",
    skill: "Inorganic Chemistry",
    count: "1",
  },
  {
    id: "1205",
    job_category: "Chemist",
    skill: "Physical Chemistry",
    count: "1",
  },
  { id: "1206", job_category: "Chemist", skill: "Biochemistry", count: "1" },
  { id: "1210", job_category: "Chemist", skill: "Titration", count: "1" },
  { id: "1214", job_category: "Chemist", skill: "Instrumentation", count: "2" },
  {
    id: "1216",
    job_category: "Chemist",
    skill: "Safety Procedures",
    count: "9",
  },
  { id: "1226", job_category: "Chemist", skill: "Biology", count: "2" },
  { id: "1227", job_category: "Chemist", skill: "Computer Skills", count: "4" },
  {
    id: "1228",
    job_category: "Chemist",
    skill: "Laboratory Management",
    count: "1",
  },
  {
    id: "1233",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Business Strategy",
    count: "5",
  },
  {
    id: "1235",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Organizational Development",
    count: "3",
  },
  {
    id: "1237",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Corporate Governance",
    count: "1",
  },
  {
    id: "1238",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Stakeholder Engagement",
    count: "2",
  },
  {
    id: "1241",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Investor Relations",
    count: "2",
  },
  {
    id: "1242",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Crisis Management",
    count: "3",
  },
  {
    id: "1244",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Market Analysis",
    count: "4",
  },
  {
    id: "1246",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Strategic Partnerships",
    count: "2",
  },
  {
    id: "1247",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Ethics and Integrity",
    count: "4",
  },
  {
    id: "1248",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Visionary Leadership",
    count: "1",
  },
  {
    id: "1249",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Team Building",
    count: "4",
  },
  {
    id: "1253",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Global Business",
    count: "1",
  },
  {
    id: "1258",
    job_category: "Chief Executive Officer (CEO)",
    skill: "Customer Focus",
    count: "6",
  },
  {
    id: "1264",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Strategic Financial Planning",
    count: "1",
  },
  {
    id: "1267",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Cost Control",
    count: "2",
  },
  {
    id: "1268",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Investment Management",
    count: "2",
  },
  {
    id: "1270",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Accounting Standards",
    count: "1",
  },
  {
    id: "1271",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Tax Compliance",
    count: "1",
  },
  {
    id: "1272",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Audit Preparation",
    count: "2",
  },
  {
    id: "1274",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Corporate Finance",
    count: "3",
  },
  {
    id: "1275",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Mergers and Acquisitions (M&A)",
    count: "1",
  },
  {
    id: "1277",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Due Diligence",
    count: "3",
  },
  {
    id: "1279",
    job_category: "Chief Financial Officer (CFO)",
    skill: "GAAP",
    count: "1",
  },
  {
    id: "1280",
    job_category: "Chief Financial Officer (CFO)",
    skill: "IFRS",
    count: "1",
  },
  {
    id: "1281",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Management Consulting",
    count: "2",
  },
  {
    id: "1282",
    job_category: "Chief Financial Officer (CFO)",
    skill: "Executive Leadership",
    count: "1",
  },
  {
    id: "1290",
    job_category: "Chief Operating Officer (COO)",
    skill: "Operations Management",
    count: "6",
  },
  {
    id: "1301",
    job_category: "Chief Operating Officer (COO)",
    skill: "Logistics Management",
    count: "2",
  },
  {
    id: "1302",
    job_category: "Chief Operating Officer (COO)",
    skill: "Quality Management",
    count: "3",
  },
  {
    id: "1307",
    job_category: "Chief Operating Officer (COO)",
    skill: "Lean Manufacturing",
    count: "4",
  },
  {
    id: "1312",
    job_category: "Chief Operating Officer (COO)",
    skill: "Leadership Development",
    count: "3",
  },
  {
    id: "1319",
    job_category: "Childcare Worker",
    skill: "Child Development",
    count: "4",
  },
  {
    id: "1320",
    job_category: "Childcare Worker",
    skill: "Childcare Services",
    count: "1",
  },
  {
    id: "1322",
    job_category: "Childcare Worker",
    skill: "CPR and First Aid",
    count: "2",
  },
  {
    id: "1323",
    job_category: "Childcare Worker",
    skill: "Behavior Management",
    count: "2",
  },
  {
    id: "1329",
    job_category: "Childcare Worker",
    skill: "Storytelling",
    count: "9",
  },
  {
    id: "1331",
    job_category: "Childcare Worker",
    skill: "Hygiene Practices",
    count: "1",
  },
  {
    id: "1332",
    job_category: "Childcare Worker",
    skill: "Physical Fitness Activities",
    count: "1",
  },
  {
    id: "1333",
    job_category: "Childcare Worker",
    skill: "Special Needs Care",
    count: "1",
  },
  {
    id: "1334",
    job_category: "Childcare Worker",
    skill: "Parent Communication",
    count: "5",
  },
  {
    id: "1335",
    job_category: "Childcare Worker",
    skill: "Play-Based Learning",
    count: "2",
  },
  {
    id: "1336",
    job_category: "Childcare Worker",
    skill: "Emotional Support",
    count: "2",
  },
  {
    id: "1341",
    job_category: "Childcare Worker",
    skill: "Observational Skills",
    count: "1",
  },
  {
    id: "1342",
    job_category: "Childcare Worker",
    skill: "Resilience",
    count: "3",
  },
  {
    id: "1346",
    job_category: "Childcare Worker",
    skill: "Positive Reinforcement",
    count: "2",
  },
  {
    id: "1347",
    job_category: "Childcare Worker",
    skill: "Sensory Activities",
    count: "1",
  },
  {
    id: "1348",
    job_category: "Chiropractor",
    skill: "Chiropractic Care",
    count: "1",
  },
  {
    id: "1349",
    job_category: "Chiropractor",
    skill: "Spinal Manipulation",
    count: "1",
  },
  {
    id: "1350",
    job_category: "Chiropractor",
    skill: "Patient Assessment",
    count: "5",
  },
  {
    id: "1351",
    job_category: "Chiropractor",
    skill: "Musculoskeletal Therapy",
    count: "1",
  },
  {
    id: "1352",
    job_category: "Chiropractor",
    skill: "Pain Management",
    count: "2",
  },
  {
    id: "1353",
    job_category: "Chiropractor",
    skill: "Rehabilitation Exercises",
    count: "1",
  },
  {
    id: "1354",
    job_category: "Chiropractor",
    skill: "Health and Wellness",
    count: "2",
  },
  {
    id: "1356",
    job_category: "Chiropractor",
    skill: "Diagnostic Imaging",
    count: "3",
  },
  { id: "1357", job_category: "Chiropractor", skill: "Anatomy", count: "2" },
  { id: "1358", job_category: "Chiropractor", skill: "Physiology", count: "2" },
  {
    id: "1360",
    job_category: "Chiropractor",
    skill: "Medical Records Management",
    count: "2",
  },
  {
    id: "1361",
    job_category: "Chiropractor",
    skill: "Insurance Billing",
    count: "1",
  },
  {
    id: "1362",
    job_category: "Chiropractor",
    skill: "Ethics and Confidentiality",
    count: "2",
  },
  {
    id: "1370",
    job_category: "Chiropractor",
    skill: "Holistic Health",
    count: "2",
  },
  {
    id: "1376",
    job_category: "Chiropractor",
    skill: "Continuing Education",
    count: "3",
  },
  {
    id: "1377",
    job_category: "Chiropractor",
    skill: "Health Assessment",
    count: "3",
  },
  {
    id: "1378",
    job_category: "Civil Engineer",
    skill: "Civil Engineering",
    count: "3",
  },
  {
    id: "1384",
    job_category: "Civil Engineer",
    skill: "Geotechnical Engineering",
    count: "1",
  },
  {
    id: "1385",
    job_category: "Civil Engineer",
    skill: "Environmental Engineering",
    count: "3",
  },
  {
    id: "1386",
    job_category: "Civil Engineer",
    skill: "Transportation Engineering",
    count: "1",
  },
  {
    id: "1387",
    job_category: "Civil Engineer",
    skill: "Hydraulic Engineering",
    count: "1",
  },
  {
    id: "1388",
    job_category: "Civil Engineer",
    skill: "Water Resources Engineering",
    count: "1",
  },
  {
    id: "1389",
    job_category: "Civil Engineer",
    skill: "Materials Testing",
    count: "1",
  },
  {
    id: "1394",
    job_category: "Civil Engineer",
    skill: "Safety Regulations",
    count: "4",
  },
  {
    id: "1408",
    job_category: "Claims Adjuster",
    skill: "Insurance Claims",
    count: "1",
  },
  {
    id: "1409",
    job_category: "Claims Adjuster",
    skill: "Claims Processing",
    count: "1",
  },
  {
    id: "1411",
    job_category: "Claims Adjuster",
    skill: "Policy Analysis",
    count: "5",
  },
  {
    id: "1414",
    job_category: "Claims Adjuster",
    skill: "Investigation Techniques",
    count: "1",
  },
  {
    id: "1417",
    job_category: "Claims Adjuster",
    skill: "Fraud Detection",
    count: "1",
  },
  {
    id: "1418",
    job_category: "Claims Adjuster",
    skill: "Liability Assessment",
    count: "1",
  },
  {
    id: "1419",
    job_category: "Claims Adjuster",
    skill: "Settlement Negotiations",
    count: "1",
  },
  {
    id: "1420",
    job_category: "Claims Adjuster",
    skill: "Claim Reviews",
    count: "1",
  },
  {
    id: "1421",
    job_category: "Claims Adjuster",
    skill: "Medical Terminology",
    count: "10",
  },
  {
    id: "1436",
    job_category: "Claims Adjuster",
    skill: "Customer Advocacy",
    count: "1",
  },
  {
    id: "1438",
    job_category: "Clinical Psychologist",
    skill: "Psychological Assessment",
    count: "1",
  },
  {
    id: "1439",
    job_category: "Clinical Psychologist",
    skill: "Therapy",
    count: "1",
  },
  {
    id: "1441",
    job_category: "Clinical Psychologist",
    skill: "Mental Health Diagnosis",
    count: "1",
  },
  {
    id: "1442",
    job_category: "Clinical Psychologist",
    skill: "Treatment Planning",
    count: "7",
  },
  {
    id: "1443",
    job_category: "Clinical Psychologist",
    skill: "Psychotherapy",
    count: "4",
  },
  {
    id: "1444",
    job_category: "Clinical Psychologist",
    skill: "Behavioral Therapy",
    count: "1",
  },
  {
    id: "1445",
    job_category: "Clinical Psychologist",
    skill: "Cognitive Behavioral Therapy (CBT)",
    count: "2",
  },
  {
    id: "1446",
    job_category: "Clinical Psychologist",
    skill: "Family Therapy",
    count: "2",
  },
  {
    id: "1447",
    job_category: "Clinical Psychologist",
    skill: "Group Therapy",
    count: "3",
  },
  {
    id: "1448",
    job_category: "Clinical Psychologist",
    skill: "Substance Abuse Counseling",
    count: "3",
  },
  {
    id: "1450",
    job_category: "Clinical Psychologist",
    skill: "Diagnostic Interviewing",
    count: "1",
  },
  {
    id: "1453",
    job_category: "Clinical Psychologist",
    skill: "Patient Record Keeping",
    count: "1",
  },
  {
    id: "1466",
    job_category: "Clinical Psychologist",
    skill: "Self-Care",
    count: "1",
  },
  {
    id: "1467",
    job_category: "Clinical Psychologist",
    skill: "Professional Development",
    count: "4",
  },
  {
    id: "1470",
    job_category: "Clinical Research Coordinator",
    skill: "Protocol Development",
    count: "1",
  },
  {
    id: "1472",
    job_category: "Clinical Research Coordinator",
    skill: "Informed Consent Process",
    count: "1",
  },
  {
    id: "1473",
    job_category: "Clinical Research Coordinator",
    skill: "IRB Submissions",
    count: "1",
  },
  {
    id: "1474",
    job_category: "Clinical Research Coordinator",
    skill: "Data Collection",
    count: "1",
  },
  {
    id: "1477",
    job_category: "Clinical Research Coordinator",
    skill: "Patient Recruitment",
    count: "1",
  },
  {
    id: "1478",
    job_category: "Clinical Research Coordinator",
    skill: "Study Coordination",
    count: "1",
  },
  {
    id: "1479",
    job_category: "Clinical Research Coordinator",
    skill: "Research Ethics",
    count: "2",
  },
  {
    id: "1497",
    job_category: "CNC Machinist",
    skill: "CNC Programming",
    count: "1",
  },
  {
    id: "1498",
    job_category: "CNC Machinist",
    skill: "Machine Operation",
    count: "1",
  },
  {
    id: "1500",
    job_category: "CNC Machinist",
    skill: "Precision Measurement Tools",
    count: "1",
  },
  {
    id: "1501",
    job_category: "CNC Machinist",
    skill: "Tooling Setup",
    count: "1",
  },
  {
    id: "1502",
    job_category: "CNC Machinist",
    skill: "Metalworking",
    count: "1",
  },
  {
    id: "1503",
    job_category: "CNC Machinist",
    skill: "Machining Processes",
    count: "1",
  },
  {
    id: "1508",
    job_category: "CNC Machinist",
    skill: "Mechanical Aptitude",
    count: "1",
  },
  {
    id: "1516",
    job_category: "CNC Machinist",
    skill: "Computer Numerical Control",
    count: "1",
  },
  {
    id: "1517",
    job_category: "CNC Machinist",
    skill: "Material Handling",
    count: "1",
  },
  {
    id: "1518",
    job_category: "CNC Machinist",
    skill: "Production Scheduling",
    count: "1",
  },
  {
    id: "1519",
    job_category: "CNC Machinist",
    skill: "Fixture Design",
    count: "1",
  },
  {
    id: "1520",
    job_category: "CNC Machinist",
    skill: "CAD/CAM Software",
    count: "1",
  },
  {
    id: "1521",
    job_category: "CNC Machinist",
    skill: "Manual Machining",
    count: "1",
  },
  {
    id: "1524",
    job_category: "CNC Machinist",
    skill: "Continuous Learning",
    count: "4",
  },
  {
    id: "1525",
    job_category: "CNC Machinist",
    skill: "Engineering Drawings",
    count: "1",
  },
  {
    id: "1526",
    job_category: "Commercial Pilot",
    skill: "Aviation Regulations",
    count: "2",
  },
  {
    id: "1527",
    job_category: "Commercial Pilot",
    skill: "Flight Operations",
    count: "1",
  },
  {
    id: "1528",
    job_category: "Commercial Pilot",
    skill: "Aircraft Navigation",
    count: "1",
  },
  {
    id: "1531",
    job_category: "Commercial Pilot",
    skill: "Aircraft Systems",
    count: "1",
  },
  {
    id: "1534",
    job_category: "Commercial Pilot",
    skill: "Navigation Charts",
    count: "1",
  },
  {
    id: "1535",
    job_category: "Commercial Pilot",
    skill: "Air Traffic Control Communication",
    count: "1",
  },
  {
    id: "1536",
    job_category: "Commercial Pilot",
    skill: "Weather Conditions",
    count: "1",
  },
  {
    id: "1540",
    job_category: "Commercial Pilot",
    skill: "Multi-engine Aircraft",
    count: "1",
  },
  {
    id: "1541",
    job_category: "Commercial Pilot",
    skill: "Human Factors",
    count: "1",
  },
  {
    id: "1553",
    job_category: "Commercial Pilot",
    skill: "Spatial Orientation",
    count: "1",
  },
  {
    id: "1555",
    job_category: "Commercial Pilot",
    skill: "Flight Simulator Training",
    count: "1",
  },
  {
    id: "1557",
    job_category: "Compliance Officer",
    skill: "Policy Development",
    count: "4",
  },
  {
    id: "1559",
    job_category: "Compliance Officer",
    skill: "Internal Audit",
    count: "1",
  },
  {
    id: "1562",
    job_category: "Compliance Officer",
    skill: "Investigation Skills",
    count: "1",
  },
  {
    id: "1564",
    job_category: "Compliance Officer",
    skill: "Training and Development",
    count: "6",
  },
  {
    id: "1565",
    job_category: "Compliance Officer",
    skill: "Documentation Management",
    count: "1",
  },
  {
    id: "1574",
    job_category: "Compliance Officer",
    skill: "Compliance Monitoring",
    count: "1",
  },
  {
    id: "1585",
    job_category: "Computer Programmer",
    skill: "Programming Languages",
    count: "4",
  },
  {
    id: "1590",
    job_category: "Computer Programmer",
    skill: "Code Optimization",
    count: "1",
  },
  {
    id: "1592",
    job_category: "Computer Programmer",
    skill: "Object-Oriented Design",
    count: "2",
  },
  {
    id: "1594",
    job_category: "Computer Programmer",
    skill: "Version Control",
    count: "6",
  },
  {
    id: "1595",
    job_category: "Computer Programmer",
    skill: "System Architecture",
    count: "1",
  },
  {
    id: "1598",
    job_category: "Computer Programmer",
    skill: "Software Documentation",
    count: "2",
  },
  {
    id: "1612",
    job_category: "Computer Programmer",
    skill: "Cybersecurity",
    count: "7",
  },
  {
    id: "1614",
    job_category: "Computer Programmer",
    skill: "Mobile Development",
    count: "5",
  },
  {
    id: "1617",
    job_category: "Construction Manager",
    skill: "Contract Negotiation",
    count: "4",
  },
  {
    id: "1619",
    job_category: "Construction Manager",
    skill: "Schedule Management",
    count: "1",
  },
  {
    id: "1625",
    job_category: "Construction Manager",
    skill: "Subcontractor Management",
    count: "1",
  },
  {
    id: "1627",
    job_category: "Construction Manager",
    skill: "Site Inspections",
    count: "1",
  },
  {
    id: "1629",
    job_category: "Construction Manager",
    skill: "Procurement",
    count: "2",
  },
  {
    id: "1632",
    job_category: "Construction Manager",
    skill: "Project Planning",
    count: "3",
  },
  {
    id: "1640",
    job_category: "Construction Manager",
    skill: "Customer Relationship Management",
    count: "5",
  },
  {
    id: "1643",
    job_category: "Construction Manager",
    skill: "IT Skills",
    count: "1",
  },
  {
    id: "1646",
    job_category: "Content Writer",
    skill: "SEO Writing",
    count: "6",
  },
  { id: "1647", job_category: "Content Writer", skill: "Editing", count: "16" },
  {
    id: "1648",
    job_category: "Content Writer",
    skill: "Proofreading",
    count: "13",
  },
  {
    id: "1650",
    job_category: "Content Writer",
    skill: "Creative Writing",
    count: "9",
  },
  { id: "1651", job_category: "Content Writer", skill: "Blogging", count: "2" },
  {
    id: "1653",
    job_category: "Content Writer",
    skill: "Marketing Communication",
    count: "1",
  },
  {
    id: "1654",
    job_category: "Content Writer",
    skill: "Audience Engagement",
    count: "6",
  },
  {
    id: "1656",
    job_category: "Content Writer",
    skill: "Grammar Skills",
    count: "5",
  },
  {
    id: "1664",
    job_category: "Content Writer",
    skill: "Brand Voice Development",
    count: "1",
  },
  {
    id: "1665",
    job_category: "Content Writer",
    skill: "Editing Tools",
    count: "1",
  },
  {
    id: "1666",
    job_category: "Content Writer",
    skill: "Web Content Management",
    count: "1",
  },
  {
    id: "1667",
    job_category: "Content Writer",
    skill: "Content Strategy",
    count: "8",
  },
  {
    id: "1668",
    job_category: "Content Writer",
    skill: "Keyword Research",
    count: "2",
  },
  {
    id: "1669",
    job_category: "Content Writer",
    skill: "CMS Platforms",
    count: "1",
  },
  {
    id: "1670",
    job_category: "Content Writer",
    skill: "Multimedia Content",
    count: "1",
  },
  {
    id: "1672",
    job_category: "Content Writer",
    skill: "Journalism",
    count: "9",
  },
  {
    id: "1682",
    job_category: "Customer Service Representative",
    skill: "Active Listening",
    count: "3",
  },
  {
    id: "1685",
    job_category: "Customer Service Representative",
    skill: "Complaint Handling",
    count: "1",
  },
  {
    id: "1691",
    job_category: "Customer Service Representative",
    skill: "Telephone Etiquette",
    count: "1",
  },
  {
    id: "1692",
    job_category: "Customer Service Representative",
    skill: "Positive Attitude",
    count: "2",
  },
  {
    id: "1696",
    job_category: "Customer Service Representative",
    skill: "Problem Escalation",
    count: "1",
  },
  {
    id: "1697",
    job_category: "Customer Service Representative",
    skill: "Salesforce Management",
    count: "1",
  },
  {
    id: "1699",
    job_category: "Customer Service Representative",
    skill: "Service Recovery",
    count: "1",
  },
  {
    id: "1701",
    job_category: "Customer Service Representative",
    skill: "Billing Inquiries",
    count: "1",
  },
  {
    id: "1703",
    job_category: "Customer Service Representative",
    skill: "Feedback Management",
    count: "1",
  },
  { id: "1708", job_category: "Data Analyst", skill: "Python", count: "7" },
  { id: "1709", job_category: "Data Analyst", skill: "Excel", count: "3" },
  {
    id: "1712",
    job_category: "Data Analyst",
    skill: "Data Mining",
    count: "3",
  },
  {
    id: "1713",
    job_category: "Data Analyst",
    skill: "Data Cleaning",
    count: "1",
  },
  {
    id: "1714",
    job_category: "Data Analyst",
    skill: "Quantitative Analysis",
    count: "3",
  },
  {
    id: "1715",
    job_category: "Data Analyst",
    skill: "Predictive Modeling",
    count: "2",
  },
  {
    id: "1729",
    job_category: "Data Analyst",
    skill: "Data Interpretation",
    count: "1",
  },
  {
    id: "1730",
    job_category: "Data Analyst",
    skill: "Dashboard Creation",
    count: "1",
  },
  {
    id: "1732",
    job_category: "Data Analyst",
    skill: "Reporting Tools",
    count: "1",
  },
  {
    id: "1733",
    job_category: "Dental Hygienist",
    skill: "Dental Hygiene",
    count: "1",
  },
  {
    id: "1735",
    job_category: "Dental Hygienist",
    skill: "Oral Health Education",
    count: "3",
  },
  {
    id: "1736",
    job_category: "Dental Hygienist",
    skill: "Periodontal Assessment",
    count: "1",
  },
  {
    id: "1737",
    job_category: "Dental Hygienist",
    skill: "Dental Instruments",
    count: "1",
  },
  {
    id: "1738",
    job_category: "Dental Hygienist",
    skill: "Dental Radiography",
    count: "1",
  },
  {
    id: "1739",
    job_category: "Dental Hygienist",
    skill: "Scaling and Root Planing",
    count: "1",
  },
  {
    id: "1740",
    job_category: "Dental Hygienist",
    skill: "Fluoride Treatments",
    count: "1",
  },
  {
    id: "1741",
    job_category: "Dental Hygienist",
    skill: "Sealant Application",
    count: "1",
  },
  {
    id: "1742",
    job_category: "Dental Hygienist",
    skill: "Patient Screening",
    count: "1",
  },
  {
    id: "1743",
    job_category: "Dental Hygienist",
    skill: "Medical History Review",
    count: "2",
  },
  {
    id: "1744",
    job_category: "Dental Hygienist",
    skill: "Oral Cancer Screening",
    count: "1",
  },
  {
    id: "1745",
    job_category: "Dental Hygienist",
    skill: "Dental Charting",
    count: "2",
  },
  {
    id: "1746",
    job_category: "Dental Hygienist",
    skill: "Infection Control",
    count: "4",
  },
  {
    id: "1747",
    job_category: "Dental Hygienist",
    skill: "Patient Comfort",
    count: "2",
  },
  {
    id: "1749",
    job_category: "Dental Hygienist",
    skill: "Ergonomics",
    count: "2",
  },
  {
    id: "1760",
    job_category: "Dental Hygienist",
    skill: "Patient Interaction",
    count: "4",
  },
  {
    id: "1762",
    job_category: "Dental Hygienist",
    skill: "Dental Software",
    count: "2",
  },
  {
    id: "1763",
    job_category: "Dental Assistant",
    skill: "Dental Assisting",
    count: "1",
  },
  {
    id: "1764",
    job_category: "Dental Assistant",
    skill: "Chairside Assistance",
    count: "1",
  },
  {
    id: "1765",
    job_category: "Dental Assistant",
    skill: "Instrument Sterilization",
    count: "1",
  },
  {
    id: "1767",
    job_category: "Dental Assistant",
    skill: "X-ray Techniques",
    count: "1",
  },
  {
    id: "1772",
    job_category: "Dental Assistant",
    skill: "Dental Materials",
    count: "1",
  },
  {
    id: "1773",
    job_category: "Dental Assistant",
    skill: "Front Office Procedures",
    count: "1",
  },
  {
    id: "1774",
    job_category: "Dental Assistant",
    skill: "Appointment Scheduling",
    count: "2",
  },
  {
    id: "1785",
    job_category: "Dental Assistant",
    skill: "HIPAA Compliance",
    count: "2",
  },
  {
    id: "1786",
    job_category: "Dental Assistant",
    skill: "CPR Certification",
    count: "1",
  },
  {
    id: "1787",
    job_category: "Dental Assistant",
    skill: "Digital Radiography",
    count: "1",
  },
  { id: "1793", job_category: "Dentist", skill: "Dentistry", count: "2" },
  { id: "1794", job_category: "Dentist", skill: "Oral Diagnosis", count: "1" },
  {
    id: "1795",
    job_category: "Dentist",
    skill: "Restorative Dentistry",
    count: "1",
  },
  {
    id: "1796",
    job_category: "Dentist",
    skill: "Cosmetic Dentistry",
    count: "1",
  },
  { id: "1797", job_category: "Dentist", skill: "Oral Surgery", count: "1" },
  { id: "1798", job_category: "Dentist", skill: "Periodontics", count: "1" },
  { id: "1799", job_category: "Dentist", skill: "Endodontics", count: "1" },
  { id: "1800", job_category: "Dentist", skill: "Prosthodontics", count: "1" },
  {
    id: "1801",
    job_category: "Dentist",
    skill: "Pediatric Dentistry",
    count: "1",
  },
  { id: "1802", job_category: "Dentist", skill: "Orthodontics", count: "2" },
  { id: "1803", job_category: "Dentist", skill: "Dental Implants", count: "1" },
  {
    id: "1804",
    job_category: "Dentist",
    skill: "Digital Dentistry",
    count: "1",
  },
  {
    id: "1805",
    job_category: "Dentist",
    skill: "Medical History Evaluation",
    count: "2",
  },
  {
    id: "1808",
    job_category: "Dentist",
    skill: "Anesthesia Administration",
    count: "1",
  },
  {
    id: "1811",
    job_category: "Dentist",
    skill: "Clinical Documentation",
    count: "4",
  },
  {
    id: "1822",
    job_category: "Dentist",
    skill: "Ethical Standards",
    count: "6",
  },
  {
    id: "1823",
    job_category: "Dermatologist",
    skill: "Dermatology",
    count: "1",
  },
  {
    id: "1824",
    job_category: "Dermatologist",
    skill: "Skin Disorders",
    count: "1",
  },
  {
    id: "1825",
    job_category: "Dermatologist",
    skill: "Cosmetic Dermatology",
    count: "1",
  },
  {
    id: "1826",
    job_category: "Dermatologist",
    skill: "Medical Dermatology",
    count: "1",
  },
  {
    id: "1827",
    job_category: "Dermatologist",
    skill: "Skin Cancer Screening",
    count: "1",
  },
  {
    id: "1828",
    job_category: "Dermatologist",
    skill: "Dermatopathology",
    count: "1",
  },
  {
    id: "1829",
    job_category: "Dermatologist",
    skill: "Mohs Surgery",
    count: "1",
  },
  {
    id: "1830",
    job_category: "Dermatologist",
    skill: "Botox Injections",
    count: "1",
  },
  {
    id: "1831",
    job_category: "Dermatologist",
    skill: "Dermal Fillers",
    count: "1",
  },
  {
    id: "1832",
    job_category: "Dermatologist",
    skill: "Laser Treatments",
    count: "1",
  },
  {
    id: "1833",
    job_category: "Dermatologist",
    skill: "Cryotherapy",
    count: "1",
  },
  {
    id: "1834",
    job_category: "Dermatologist",
    skill: "Biopsy Procedures",
    count: "1",
  },
  {
    id: "1852",
    job_category: "Dermatologist",
    skill: "Patient Satisfaction",
    count: "1",
  },
  {
    id: "1854",
    job_category: "Digital Marketing Manager",
    skill: "SEO",
    count: "6",
  },
  {
    id: "1855",
    job_category: "Digital Marketing Manager",
    skill: "SEM",
    count: "1",
  },
  {
    id: "1857",
    job_category: "Digital Marketing Manager",
    skill: "Email Marketing",
    count: "2",
  },
  {
    id: "1858",
    job_category: "Digital Marketing Manager",
    skill: "Content Marketing",
    count: "2",
  },
  {
    id: "1859",
    job_category: "Digital Marketing Manager",
    skill: "PPC Advertising",
    count: "2",
  },
  {
    id: "1860",
    job_category: "Digital Marketing Manager",
    skill: "Web Analytics",
    count: "2",
  },
  {
    id: "1861",
    job_category: "Digital Marketing Manager",
    skill: "Marketing Automation",
    count: "1",
  },
  {
    id: "1864",
    job_category: "Digital Marketing Manager",
    skill: "Online Reputation Management",
    count: "1",
  },
  {
    id: "1866",
    job_category: "Digital Marketing Manager",
    skill: "Conversion Rate Optimization",
    count: "1",
  },
  {
    id: "1875",
    job_category: "Digital Marketing Manager",
    skill: "Customer Engagement",
    count: "2",
  },
  {
    id: "1877",
    job_category: "Digital Marketing Manager",
    skill: "Trend Analysis",
    count: "3",
  },
  { id: "1884", job_category: "Electrician", skill: "Wiring", count: "1" },
  {
    id: "1886",
    job_category: "Electrician",
    skill: "Electrical Code",
    count: "1",
  },
  {
    id: "1887",
    job_category: "Electrician",
    skill: "Circuit Testing",
    count: "1",
  },
  {
    id: "1889",
    job_category: "Electrician",
    skill: "Power Distribution",
    count: "1",
  },
  {
    id: "1893",
    job_category: "Electrician",
    skill: "Electrical Maintenance",
    count: "1",
  },
  {
    id: "1894",
    job_category: "Electrician",
    skill: "Lighting Systems",
    count: "1",
  },
  {
    id: "1907",
    job_category: "Electrician",
    skill: "Blueprint Interpretation",
    count: "1",
  },
  {
    id: "1911",
    job_category: "Electrician",
    skill: "Workplace Safety",
    count: "2",
  },
  {
    id: "1912",
    job_category: "Electrician",
    skill: "Code Compliance",
    count: "1",
  },
  {
    id: "1913",
    job_category: "Elementary School Teacher",
    skill: "Elementary Education",
    count: "1",
  },
  {
    id: "1917",
    job_category: "Elementary School Teacher",
    skill: "Differentiated Instruction",
    count: "4",
  },
  {
    id: "1922",
    job_category: "Elementary School Teacher",
    skill: "Literacy Instruction",
    count: "1",
  },
  {
    id: "1923",
    job_category: "Elementary School Teacher",
    skill: "Numeracy Instruction",
    count: "1",
  },
  {
    id: "1924",
    job_category: "Elementary School Teacher",
    skill: "Special Education",
    count: "2",
  },
  {
    id: "1925",
    job_category: "Elementary School Teacher",
    skill: "English Language Learners (ELL)",
    count: "1",
  },
  {
    id: "1927",
    job_category: "Elementary School Teacher",
    skill: "Classroom Resources",
    count: "1",
  },
  {
    id: "1929",
    job_category: "Elementary School Teacher",
    skill: "Creative Teaching",
    count: "1",
  },
  {
    id: "1939",
    job_category: "Elementary School Teacher",
    skill: "Assessment Tools",
    count: "1",
  },
  {
    id: "1940",
    job_category: "Elementary School Teacher",
    skill: "Instructional Strategies",
    count: "1",
  },
  {
    id: "1942",
    job_category: "Elementary School Teacher",
    skill: "Classroom Environment",
    count: "1",
  },
  { id: "1943", job_category: "Engineer", skill: "Engineering", count: "2" },
  {
    id: "1951",
    job_category: "Engineer",
    skill: "Research and Development",
    count: "2",
  },
  {
    id: "1952",
    job_category: "Engineer",
    skill: "Mechanical Engineering",
    count: "3",
  },
  {
    id: "1953",
    job_category: "Engineer",
    skill: "Electrical Engineering",
    count: "3",
  },
  {
    id: "1956",
    job_category: "Engineer",
    skill: "Software Engineering",
    count: "2",
  },
  {
    id: "1958",
    job_category: "Engineer",
    skill: "Aerospace Engineering",
    count: "1",
  },
  {
    id: "1959",
    job_category: "Engineer",
    skill: "Biomedical Engineering",
    count: "1",
  },
  {
    id: "1961",
    job_category: "Engineer",
    skill: "Materials Science",
    count: "2",
  },
  { id: "1962", job_category: "Engineer", skill: "Automation", count: "3" },
  {
    id: "1976",
    job_category: "Event Coordinator",
    skill: "Timeline Management",
    count: "1",
  },
  {
    id: "1985",
    job_category: "Event Coordinator",
    skill: "Venue Selection",
    count: "1",
  },
  {
    id: "1986",
    job_category: "Event Coordinator",
    skill: "Catering Management",
    count: "1",
  },
  {
    id: "1987",
    job_category: "Event Coordinator",
    skill: "Entertainment Coordination",
    count: "1",
  },
  {
    id: "1988",
    job_category: "Event Coordinator",
    skill: "Sponsorship Relations",
    count: "1",
  },
  {
    id: "1989",
    job_category: "Event Coordinator",
    skill: "Marketing and Promotion",
    count: "1",
  },
  {
    id: "1995",
    job_category: "Event Coordinator",
    skill: "Client Relationship Management",
    count: "4",
  },
  {
    id: "1996",
    job_category: "Event Coordinator",
    skill: "On-site Coordination",
    count: "2",
  },
  {
    id: "1998",
    job_category: "Event Coordinator",
    skill: "Guest Experience",
    count: "1",
  },
  {
    id: "2000",
    job_category: "Event Coordinator",
    skill: "Event Production",
    count: "1",
  },
  {
    id: "2001",
    job_category: "Executive Assistant",
    skill: "Administrative Support",
    count: "4",
  },
  {
    id: "2011",
    job_category: "Executive Assistant",
    skill: "Prioritization",
    count: "1",
  },
  {
    id: "2016",
    job_category: "Executive Assistant",
    skill: "Expense Reports",
    count: "1",
  },
  {
    id: "2024",
    job_category: "Executive Assistant",
    skill: "Problem Resolution",
    count: "1",
  },
  {
    id: "2029",
    job_category: "Executive Assistant",
    skill: "Detail Orientation",
    count: "2",
  },
  {
    id: "2038",
    job_category: "Financial Analyst",
    skill: "Valuation",
    count: "2",
  },
  {
    id: "2039",
    job_category: "Financial Analyst",
    skill: "Accounting Principles",
    count: "1",
  },
  {
    id: "2045",
    job_category: "Financial Analyst",
    skill: "Economic Analysis",
    count: "2",
  },
  {
    id: "2047",
    job_category: "Financial Analyst",
    skill: "Quantitative Skills",
    count: "1",
  },
  {
    id: "2048",
    job_category: "Financial Analyst",
    skill: "Financial Statements",
    count: "2",
  },
  {
    id: "2049",
    job_category: "Financial Analyst",
    skill: "Financial Markets",
    count: "1",
  },
  {
    id: "2064",
    job_category: "Graphic Designer",
    skill: "Visual Communication",
    count: "3",
  },
  {
    id: "2065",
    job_category: "Graphic Designer",
    skill: "Layout Design",
    count: "3",
  },
  {
    id: "2067",
    job_category: "Graphic Designer",
    skill: "Brand Identity",
    count: "2",
  },
  {
    id: "2068",
    job_category: "Graphic Designer",
    skill: "Print Design",
    count: "5",
  },
  {
    id: "2069",
    job_category: "Graphic Designer",
    skill: "Digital Illustration",
    count: "1",
  },
  {
    id: "2071",
    job_category: "Graphic Designer",
    skill: "User Experience (UX)",
    count: "6",
  },
  {
    id: "2078",
    job_category: "Graphic Designer",
    skill: "Collaboration",
    count: "31",
  },
  {
    id: "2079",
    job_category: "Graphic Designer",
    skill: "Client Management",
    count: "9",
  },
  {
    id: "2081",
    job_category: "Graphic Designer",
    skill: "Multimedia Design",
    count: "2",
  },
  {
    id: "2082",
    job_category: "Graphic Designer",
    skill: "Design Software",
    count: "1",
  },
  {
    id: "2087",
    job_category: "Graphic Designer",
    skill: "Photo Editing",
    count: "2",
  },
  {
    id: "2088",
    job_category: "Graphic Designer",
    skill: "Layout Software",
    count: "1",
  },
  {
    id: "2090",
    job_category: "Graphic Designer",
    skill: "Design Principles",
    count: "2",
  },
  {
    id: "2091",
    job_category: "HR Coordinator",
    skill: "Human Resources",
    count: "3",
  },
  {
    id: "2092",
    job_category: "HR Coordinator",
    skill: "Recruitment",
    count: "1",
  },
  {
    id: "2093",
    job_category: "HR Coordinator",
    skill: "Onboarding",
    count: "2",
  },
  {
    id: "2094",
    job_category: "HR Coordinator",
    skill: "Employee Relations",
    count: "4",
  },
  {
    id: "2095",
    job_category: "HR Coordinator",
    skill: "Compensation and Benefits",
    count: "3",
  },
  {
    id: "2098",
    job_category: "HR Coordinator",
    skill: "Job Descriptions",
    count: "1",
  },
  { id: "2102", job_category: "HR Coordinator", skill: "HRIS", count: "2" },
  {
    id: "2105",
    job_category: "HR Coordinator",
    skill: "HR Administration",
    count: "1",
  },
  {
    id: "2116",
    job_category: "HR Coordinator",
    skill: "Talent Management",
    count: "1",
  },
  {
    id: "2119",
    job_category: "HR Coordinator",
    skill: "Microsoft Office",
    count: "3",
  },
  {
    id: "2120",
    job_category: "HR Coordinator",
    skill: "Employee Orientation",
    count: "1",
  },
  {
    id: "2122",
    job_category: "IT Support Specialist",
    skill: "Help Desk Management",
    count: "1",
  },
  {
    id: "2123",
    job_category: "IT Support Specialist",
    skill: "Computer Hardware",
    count: "1",
  },
  {
    id: "2124",
    job_category: "IT Support Specialist",
    skill: "Software Troubleshooting",
    count: "1",
  },
  {
    id: "2125",
    job_category: "IT Support Specialist",
    skill: "Network Configuration",
    count: "3",
  },
  {
    id: "2126",
    job_category: "IT Support Specialist",
    skill: "Operating Systems",
    count: "4",
  },
  {
    id: "2127",
    job_category: "IT Support Specialist",
    skill: "IT Security",
    count: "4",
  },
  {
    id: "2128",
    job_category: "IT Support Specialist",
    skill: "Server Maintenance",
    count: "1",
  },
  {
    id: "2129",
    job_category: "IT Support Specialist",
    skill: "Remote Desktop",
    count: "2",
  },
  {
    id: "2130",
    job_category: "IT Support Specialist",
    skill: "VPN",
    count: "1",
  },
  {
    id: "2131",
    job_category: "IT Support Specialist",
    skill: "TCP/IP",
    count: "1",
  },
  {
    id: "2142",
    job_category: "IT Support Specialist",
    skill: "User Training",
    count: "1",
  },
  {
    id: "2143",
    job_category: "IT Support Specialist",
    skill: "Cloud Computing",
    count: "8",
  },
  {
    id: "2145",
    job_category: "IT Support Specialist",
    skill: "Data Recovery",
    count: "2",
  },
  {
    id: "2146",
    job_category: "IT Support Specialist",
    skill: "ITIL",
    count: "1",
  },
  {
    id: "2147",
    job_category: "IT Support Specialist",
    skill: "Information Security",
    count: "3",
  },
  {
    id: "2148",
    job_category: "IT Support Specialist",
    skill: "Service Desk",
    count: "1",
  },
  {
    id: "2149",
    job_category: "IT Support Specialist",
    skill: "Software Installation",
    count: "5",
  },
  { id: "2151", job_category: "Janitor", skill: "Cleaning", count: "1" },
  { id: "2152", job_category: "Janitor", skill: "Sanitization", count: "1" },
  { id: "2153", job_category: "Janitor", skill: "Floor Care", count: "1" },
  { id: "2154", job_category: "Janitor", skill: "Trash Removal", count: "1" },
  {
    id: "2155",
    job_category: "Janitor",
    skill: "Restroom Maintenance",
    count: "1",
  },
  {
    id: "2156",
    job_category: "Janitor",
    skill: "Cleaning Products",
    count: "1",
  },
  {
    id: "2158",
    job_category: "Janitor",
    skill: "Supply Replenishment",
    count: "1",
  },
  {
    id: "2162",
    job_category: "Janitor",
    skill: "Organization Skills",
    count: "5",
  },
  {
    id: "2168",
    job_category: "Janitor",
    skill: "Cleaning Equipment",
    count: "1",
  },
  {
    id: "2170",
    job_category: "Janitor",
    skill: "Health and Safety",
    count: "3",
  },
  {
    id: "2171",
    job_category: "Janitor",
    skill: "Cleaning Techniques",
    count: "1",
  },
  {
    id: "2172",
    job_category: "Janitor",
    skill: "Sweeping and Mopping",
    count: "1",
  },
  {
    id: "2173",
    job_category: "Janitor",
    skill: "Building Maintenance",
    count: "2",
  },
  { id: "2174", job_category: "Janitor", skill: "Groundskeeping", count: "2" },
  { id: "2176", job_category: "Janitor", skill: "Disinfection", count: "1" },
  {
    id: "2177",
    job_category: "Janitor",
    skill: "Cleaning Standards",
    count: "1",
  },
  {
    id: "2178",
    job_category: "Janitor",
    skill: "Janitorial Supplies",
    count: "1",
  },
  {
    id: "2179",
    job_category: "Janitor",
    skill: "Custodial Duties",
    count: "1",
  },
  { id: "2181", job_category: "Journalist", skill: "Research", count: "9" },
  { id: "2182", job_category: "Journalist", skill: "Interviewing", count: "6" },
  { id: "2183", job_category: "Journalist", skill: "News Writing", count: "2" },
  { id: "2185", job_category: "Journalist", skill: "Social Media", count: "5" },
  {
    id: "2186",
    job_category: "Journalist",
    skill: "Investigative Reporting",
    count: "2",
  },
  {
    id: "2187",
    job_category: "Journalist",
    skill: "Broadcast Journalism",
    count: "2",
  },
  {
    id: "2188",
    job_category: "Journalist",
    skill: "Feature Writing",
    count: "1",
  },
  { id: "2192", job_category: "Journalist", skill: "AP Style", count: "2" },
  {
    id: "2193",
    job_category: "Journalist",
    skill: "Content Management Systems (CMS)",
    count: "6",
  },
  {
    id: "2194",
    job_category: "Journalist",
    skill: "Fact Checking",
    count: "2",
  },
  {
    id: "2195",
    job_category: "Journalist",
    skill: "Deadline Management",
    count: "2",
  },
  {
    id: "2204",
    job_category: "Journalist",
    skill: "News Judgment",
    count: "1",
  },
  {
    id: "2206",
    job_category: "Journalist",
    skill: "Multimedia Skills",
    count: "2",
  },
  {
    id: "2207",
    job_category: "Journalist",
    skill: "Ethics in Journalism",
    count: "1",
  },
  {
    id: "2208",
    job_category: "Journalist",
    skill: "Feature Editing",
    count: "1",
  },
  { id: "2210", job_category: "Lawyer", skill: "Law", count: "1" },
  { id: "2217", job_category: "Lawyer", skill: "Civil Law", count: "2" },
  { id: "2220", job_category: "Lawyer", skill: "Business Law", count: "2" },
  { id: "2223", job_category: "Lawyer", skill: "Client Advocacy", count: "1" },
  { id: "2224", job_category: "Lawyer", skill: "Legal Advice", count: "3" },
  { id: "2225", job_category: "Lawyer", skill: "Negotiation", count: "5" },
  {
    id: "2226",
    job_category: "Lawyer",
    skill: "Dispute Resolution",
    count: "2",
  },
  {
    id: "2227",
    job_category: "Lawyer",
    skill: "Courtroom Procedures",
    count: "2",
  },
  { id: "2228", job_category: "Lawyer", skill: "Legal Documents", count: "2" },
  { id: "2233", job_category: "Lawyer", skill: "Legal Ethics", count: "3" },
  { id: "2234", job_category: "Lawyer", skill: "Legal Assistance", count: "2" },
  { id: "2235", job_category: "Lawyer", skill: "Legal Issues", count: "1" },
  {
    id: "2237",
    job_category: "Lawyer",
    skill: "Professional Responsibility",
    count: "1",
  },
  {
    id: "2240",
    job_category: "Librarian",
    skill: "Library Science",
    count: "2",
  },
  {
    id: "2242",
    job_category: "Librarian",
    skill: "Information Literacy",
    count: "2",
  },
  {
    id: "2244",
    job_category: "Librarian",
    skill: "Collection Management",
    count: "1",
  },
  {
    id: "2245",
    job_category: "Librarian",
    skill: "Library Management",
    count: "1",
  },
  {
    id: "2246",
    job_category: "Librarian",
    skill: "Reference Services",
    count: "2",
  },
  {
    id: "2248",
    job_category: "Librarian",
    skill: "Digital Archives",
    count: "1",
  },
  {
    id: "2250",
    job_category: "Librarian",
    skill: "Library Software",
    count: "1",
  },
  {
    id: "2251",
    job_category: "Librarian",
    skill: "Technical Services",
    count: "1",
  },
  {
    id: "2252",
    job_category: "Librarian",
    skill: "Metadata Management",
    count: "1",
  },
  {
    id: "2259",
    job_category: "Librarian",
    skill: "Information Organization",
    count: "1",
  },
  { id: "2260", job_category: "Librarian", skill: "Literature", count: "1" },
  {
    id: "2261",
    job_category: "Librarian",
    skill: "Archival Preservation",
    count: "1",
  },
  {
    id: "2262",
    job_category: "Librarian",
    skill: "Cultural Heritage",
    count: "1",
  },
  {
    id: "2264",
    job_category: "Librarian",
    skill: "Archival Conservation",
    count: "1",
  },
  {
    id: "2265",
    job_category: "Librarian",
    skill: "Multimedia Archives",
    count: "1",
  },
  {
    id: "2266",
    job_category: "Librarian",
    skill: "Library Instruction",
    count: "1",
  },
  {
    id: "2267",
    job_category: "Librarian",
    skill: "Library Technology",
    count: "1",
  },
  {
    id: "2268",
    job_category: "Librarian",
    skill: "User Engagement",
    count: "1",
  },
  {
    id: "2269",
    job_category: "Librarian",
    skill: "Book Selection",
    count: "1",
  },
  {
    id: "2273",
    job_category: "Mechanical Engineer",
    skill: "Product Design",
    count: "3",
  },
  {
    id: "2275",
    job_category: "Mechanical Engineer",
    skill: "Fluid Mechanics",
    count: "1",
  },
  {
    id: "2278",
    job_category: "Mechanical Engineer",
    skill: "Engineering Analysis",
    count: "1",
  },
  {
    id: "2289",
    job_category: "Mechanical Engineer",
    skill: "Prototype Development",
    count: "1",
  },
  {
    id: "2292",
    job_category: "Mechanical Engineer",
    skill: "System Design",
    count: "1",
  },
  {
    id: "2293",
    job_category: "Mechanical Engineer",
    skill: "Maintenance Planning",
    count: "1",
  },
  {
    id: "2297",
    job_category: "Mechanical Engineer",
    skill: "Project Engineering",
    count: "1",
  },
  {
    id: "2299",
    job_category: "Medical Assistant",
    skill: "Medical Assisting",
    count: "1",
  },
  {
    id: "2301",
    job_category: "Medical Assistant",
    skill: "Vital Signs",
    count: "1",
  },
  {
    id: "2303",
    job_category: "Medical Assistant",
    skill: "Electronic Health Records (EHR)",
    count: "6",
  },
  {
    id: "2304",
    job_category: "Medical Assistant",
    skill: "Anatomy and Physiology",
    count: "5",
  },
  {
    id: "2305",
    job_category: "Medical Assistant",
    skill: "Phlebotomy",
    count: "1",
  },
  {
    id: "2306",
    job_category: "Medical Assistant",
    skill: "Medical History",
    count: "1",
  },
  {
    id: "2307",
    job_category: "Medical Assistant",
    skill: "Medical Office Procedures",
    count: "1",
  },
  {
    id: "2308",
    job_category: "Medical Assistant",
    skill: "Healthcare Regulations",
    count: "1",
  },
  {
    id: "2311",
    job_category: "Medical Assistant",
    skill: "Emergency Response",
    count: "8",
  },
  {
    id: "2312",
    job_category: "Medical Assistant",
    skill: "Medication Administration",
    count: "2",
  },
  {
    id: "2313",
    job_category: "Medical Assistant",
    skill: "Medical Billing",
    count: "1",
  },
  {
    id: "2314",
    job_category: "Medical Assistant",
    skill: "Medical Coding",
    count: "2",
  },
  {
    id: "2315",
    job_category: "Medical Assistant",
    skill: "Laboratory Procedures",
    count: "3",
  },
  {
    id: "2316",
    job_category: "Medical Assistant",
    skill: "Administrative Skills",
    count: "1",
  },
  {
    id: "2323",
    job_category: "Medical Assistant",
    skill: "Compassion",
    count: "4",
  },
  { id: "2329", job_category: "Nurse", skill: "Nursing", count: "1" },
  { id: "2331", job_category: "Nurse", skill: "Medical Knowledge", count: "1" },
  { id: "2333", job_category: "Nurse", skill: "Healthcare", count: "4" },
  {
    id: "2339",
    job_category: "Nurse",
    skill: "Diagnostic Testing",
    count: "1",
  },
  {
    id: "2340",
    job_category: "Nurse",
    skill: "Intravenous (IV) Therapy",
    count: "1",
  },
  { id: "2341", job_category: "Nurse", skill: "Wound Care", count: "1" },
  { id: "2343", job_category: "Nurse", skill: "Care Planning", count: "1" },
  { id: "2353", job_category: "Nurse", skill: "Quality Care", count: "2" },
  { id: "2356", job_category: "Nurse", skill: "Medical Ethics", count: "6" },
  {
    id: "2365",
    job_category: "Operations Manager",
    skill: "Logistics",
    count: "4",
  },
  {
    id: "2381",
    job_category: "Operations Manager",
    skill: "Staff Development",
    count: "1",
  },
  {
    id: "2385",
    job_category: "Operations Manager",
    skill: "Facilities Management",
    count: "2",
  },
  {
    id: "2392",
    job_category: "Paralegal",
    skill: "Litigation Support",
    count: "2",
  },
  {
    id: "2393",
    job_category: "Paralegal",
    skill: "Legal Documentation",
    count: "2",
  },
  { id: "2394", job_category: "Paralegal", skill: "Court Filings", count: "1" },
  {
    id: "2396",
    job_category: "Paralegal",
    skill: "Legal Terminology",
    count: "3",
  },
  { id: "2397", job_category: "Paralegal", skill: "Discovery", count: "1" },
  { id: "2398", job_category: "Paralegal", skill: "Investigation", count: "1" },
  {
    id: "2400",
    job_category: "Paralegal",
    skill: "Contract Review",
    count: "1",
  },
  { id: "2401", job_category: "Paralegal", skill: "Case Analysis", count: "1" },
  { id: "2413", job_category: "Paralegal", skill: "Depositions", count: "2" },
  {
    id: "2414",
    job_category: "Paralegal",
    skill: "Legal Procedures",
    count: "3",
  },
  {
    id: "2416",
    job_category: "Paralegal",
    skill: "Client Billing",
    count: "1",
  },
  { id: "2417", job_category: "Paralegal", skill: "Case Records", count: "1" },
  { id: "2418", job_category: "Pharmacist", skill: "Pharmacy", count: "1" },
  {
    id: "2419",
    job_category: "Pharmacist",
    skill: "Pharmaceuticals",
    count: "2",
  },
  { id: "2421", job_category: "Pharmacist", skill: "Pharmacology", count: "3" },
  {
    id: "2422",
    job_category: "Pharmacist",
    skill: "Medication Therapy Management",
    count: "1",
  },
  {
    id: "2423",
    job_category: "Pharmacist",
    skill: "Prescription Processing",
    count: "2",
  },
  { id: "2424", job_category: "Pharmacist", skill: "Compounding", count: "1" },
  {
    id: "2425",
    job_category: "Pharmacist",
    skill: "Drug Interactions",
    count: "1",
  },
  { id: "2426", job_category: "Pharmacist", skill: "Pharmacy Law", count: "1" },
  {
    id: "2428",
    job_category: "Pharmacist",
    skill: "Clinical Pharmacology",
    count: "1",
  },
  {
    id: "2429",
    job_category: "Pharmacist",
    skill: "Pharmacokinetics",
    count: "1",
  },
  {
    id: "2430",
    job_category: "Pharmacist",
    skill: "Medication Adherence",
    count: "1",
  },
  {
    id: "2431",
    job_category: "Pharmacist",
    skill: "Patient Counseling",
    count: "3",
  },
  {
    id: "2432",
    job_category: "Pharmacist",
    skill: "Pharmacy Management",
    count: "1",
  },
  {
    id: "2446",
    job_category: "Pharmacist",
    skill: "Patient Safety",
    count: "1",
  },
  {
    id: "2448",
    job_category: "Pharmacist",
    skill: "Prescription Verification",
    count: "1",
  },
  {
    id: "2450",
    job_category: "Photographer",
    skill: "Digital Photography",
    count: "1",
  },
  {
    id: "2451",
    job_category: "Photographer",
    skill: "Image Editing",
    count: "1",
  },
  {
    id: "2452",
    job_category: "Photographer",
    skill: "Photojournalism",
    count: "2",
  },
  {
    id: "2453",
    job_category: "Photographer",
    skill: "Studio Lighting",
    count: "2",
  },
  {
    id: "2454",
    job_category: "Photographer",
    skill: "Portrait Photography",
    count: "2",
  },
  {
    id: "2455",
    job_category: "Photographer",
    skill: "Event Photography",
    count: "2",
  },
  {
    id: "2456",
    job_category: "Photographer",
    skill: "Wedding Photography",
    count: "2",
  },
  {
    id: "2457",
    job_category: "Photographer",
    skill: "Fashion Photography",
    count: "1",
  },
  {
    id: "2458",
    job_category: "Photographer",
    skill: "Landscape Photography",
    count: "1",
  },
  {
    id: "2459",
    job_category: "Photographer",
    skill: "Commercial Photography",
    count: "2",
  },
  {
    id: "2461",
    job_category: "Photographer",
    skill: "Camera Equipment",
    count: "1",
  },
  {
    id: "2463",
    job_category: "Photographer",
    skill: "Photography Software",
    count: "1",
  },
  {
    id: "2464",
    job_category: "Photographer",
    skill: "Color Correction",
    count: "3",
  },
  {
    id: "2465",
    job_category: "Photographer",
    skill: "Image Manipulation",
    count: "1",
  },
  {
    id: "2466",
    job_category: "Photographer",
    skill: "Photography Techniques",
    count: "1",
  },
  {
    id: "2477",
    job_category: "Photographer",
    skill: "Visual Aesthetics",
    count: "1",
  },
  {
    id: "2479",
    job_category: "Physical Therapist",
    skill: "Physical Therapy",
    count: "1",
  },
  {
    id: "2482",
    job_category: "Physical Therapist",
    skill: "Exercise Therapy",
    count: "1",
  },
  {
    id: "2485",
    job_category: "Physical Therapist",
    skill: "Musculoskeletal Disorders",
    count: "1",
  },
  {
    id: "2486",
    job_category: "Physical Therapist",
    skill: "Orthopedics",
    count: "1",
  },
  {
    id: "2487",
    job_category: "Physical Therapist",
    skill: "Neurological Disorders",
    count: "1",
  },
  {
    id: "2488",
    job_category: "Physical Therapist",
    skill: "Cardiopulmonary Therapy",
    count: "1",
  },
  {
    id: "2489",
    job_category: "Physical Therapist",
    skill: "Balance Training",
    count: "1",
  },
  {
    id: "2490",
    job_category: "Physical Therapist",
    skill: "Gait Analysis",
    count: "1",
  },
  {
    id: "2491",
    job_category: "Physical Therapist",
    skill: "Physical Conditioning",
    count: "1",
  },
  {
    id: "2492",
    job_category: "Physical Therapist",
    skill: "Functional Mobility",
    count: "1",
  },
  {
    id: "2503",
    job_category: "Physical Therapist",
    skill: "Recovery Plans",
    count: "1",
  },
  {
    id: "2505",
    job_category: "Physical Therapist",
    skill: "Exercise Techniques",
    count: "1",
  },
  {
    id: "2507",
    job_category: "Physical Therapist",
    skill: "Clinical Experience",
    count: "1",
  },
  {
    id: "2509",
    job_category: "Police Officer",
    skill: "Law Enforcement",
    count: "4",
  },
  {
    id: "2510",
    job_category: "Police Officer",
    skill: "Criminal Justice",
    count: "2",
  },
  {
    id: "2511",
    job_category: "Police Officer",
    skill: "Public Safety",
    count: "6",
  },
  {
    id: "2513",
    job_category: "Police Officer",
    skill: "Crime Prevention",
    count: "2",
  },
  {
    id: "2514",
    job_category: "Police Officer",
    skill: "Traffic Control",
    count: "1",
  },
  { id: "2515", job_category: "Police Officer", skill: "Patrol", count: "1" },
  {
    id: "2518",
    job_category: "Police Officer",
    skill: "Investigative Techniques",
    count: "4",
  },
  {
    id: "2521",
    job_category: "Police Officer",
    skill: "Physical Fitness",
    count: "4",
  },
  {
    id: "2522",
    job_category: "Police Officer",
    skill: "Use of Force",
    count: "1",
  },
  {
    id: "2523",
    job_category: "Police Officer",
    skill: "Firearms Training",
    count: "1",
  },
  {
    id: "2524",
    job_category: "Police Officer",
    skill: "Self-Defense Tactics",
    count: "1",
  },
  {
    id: "2525",
    job_category: "Police Officer",
    skill: "Crowd Control",
    count: "1",
  },
  {
    id: "2526",
    job_category: "Police Officer",
    skill: "Surveillance",
    count: "3",
  },
  {
    id: "2528",
    job_category: "Police Officer",
    skill: "Community Policing",
    count: "1",
  },
  {
    id: "2529",
    job_category: "Police Officer",
    skill: "Traffic Laws",
    count: "1",
  },
  {
    id: "2531",
    job_category: "Police Officer",
    skill: "Crime Scene Investigation",
    count: "3",
  },
  {
    id: "2537",
    job_category: "Police Officer",
    skill: "Ethics in Law Enforcement",
    count: "1",
  },
  {
    id: "2538",
    job_category: "Preschool Teacher",
    skill: "Early Childhood Education",
    count: "2",
  },
  {
    id: "2543",
    job_category: "Preschool Teacher",
    skill: "Social Skills",
    count: "1",
  },
  {
    id: "2544",
    job_category: "Preschool Teacher",
    skill: "Educational Games",
    count: "1",
  },
  {
    id: "2545",
    job_category: "Preschool Teacher",
    skill: "Teaching Strategies",
    count: "1",
  },
  {
    id: "2547",
    job_category: "Preschool Teacher",
    skill: "Behavioral Management",
    count: "3",
  },
  {
    id: "2548",
    job_category: "Preschool Teacher",
    skill: "Creative Arts",
    count: "1",
  },
  {
    id: "2550",
    job_category: "Preschool Teacher",
    skill: "Child Safety",
    count: "1",
  },
  {
    id: "2551",
    job_category: "Preschool Teacher",
    skill: "Learning Assessment",
    count: "2",
  },
  {
    id: "2552",
    job_category: "Preschool Teacher",
    skill: "Early Literacy",
    count: "1",
  },
  {
    id: "2554",
    job_category: "Preschool Teacher",
    skill: "Special Needs Education",
    count: "1",
  },
  {
    id: "2565",
    job_category: "Preschool Teacher",
    skill: "Classroom Technology",
    count: "2",
  },
  {
    id: "2567",
    job_category: "Preschool Teacher",
    skill: "Early Childhood Development",
    count: "1",
  },
  {
    id: "2568",
    job_category: "Product Manager",
    skill: "Product Management",
    count: "1",
  },
  {
    id: "2576",
    job_category: "Product Manager",
    skill: "User Interface (UI)",
    count: "5",
  },
  {
    id: "2578",
    job_category: "Product Manager",
    skill: "Product Strategy",
    count: "1",
  },
  {
    id: "2579",
    job_category: "Product Manager",
    skill: "Feature Prioritization",
    count: "1",
  },
  {
    id: "2580",
    job_category: "Product Manager",
    skill: "Product Roadmap",
    count: "1",
  },
  {
    id: "2581",
    job_category: "Product Manager",
    skill: "Cross-functional Leadership",
    count: "1",
  },
  {
    id: "2592",
    job_category: "Product Manager",
    skill: "Lean Startup",
    count: "1",
  },
  {
    id: "2601",
    job_category: "Professor",
    skill: "Higher Education",
    count: "1",
  },
  { id: "2602", job_category: "Professor", skill: "Lecturing", count: "1" },
  { id: "2607", job_category: "Professor", skill: "Course Design", count: "1" },
  {
    id: "2609",
    job_category: "Professor",
    skill: "Academic Advising",
    count: "1",
  },
  {
    id: "2610",
    job_category: "Professor",
    skill: "Faculty Development",
    count: "1",
  },
  {
    id: "2611",
    job_category: "Professor",
    skill: "Research Papers",
    count: "1",
  },
  { id: "2612", job_category: "Professor", skill: "Peer Review", count: "1" },
  {
    id: "2624",
    job_category: "Professor",
    skill: "Online Education",
    count: "1",
  },
  {
    id: "2626",
    job_category: "Professor",
    skill: "Knowledge Sharing",
    count: "1",
  },
  {
    id: "2627",
    job_category: "Professor",
    skill: "Intellectual Discourse",
    count: "1",
  },
  { id: "2637", job_category: "Project Manager", skill: "Scrum", count: "4" },
  {
    id: "2638",
    job_category: "Project Manager",
    skill: "Waterfall Methodology",
    count: "1",
  },
  {
    id: "2652",
    job_category: "Project Manager",
    skill: "Project Lifecycle Management",
    count: "1",
  },
  {
    id: "2653",
    job_category: "Project Manager",
    skill: "Resource Allocation",
    count: "2",
  },
  {
    id: "2658",
    job_category: "Real Estate Agent",
    skill: "Real Estate",
    count: "1",
  },
  { id: "2659", job_category: "Real Estate Agent", skill: "Sales", count: "2" },
  {
    id: "2660",
    job_category: "Real Estate Agent",
    skill: "Property Management",
    count: "2",
  },
  {
    id: "2662",
    job_category: "Real Estate Agent",
    skill: "Home Staging",
    count: "1",
  },
  {
    id: "2663",
    job_category: "Real Estate Agent",
    skill: "Listing Homes",
    count: "1",
  },
  {
    id: "2664",
    job_category: "Real Estate Agent",
    skill: "Real Estate Transactions",
    count: "1",
  },
  {
    id: "2666",
    job_category: "Real Estate Agent",
    skill: "Real Estate Marketing",
    count: "1",
  },
  {
    id: "2667",
    job_category: "Real Estate Agent",
    skill: "Contracts",
    count: "1",
  },
  {
    id: "2669",
    job_category: "Real Estate Agent",
    skill: "Closing Sales",
    count: "1",
  },
  {
    id: "2670",
    job_category: "Real Estate Agent",
    skill: "Property Tours",
    count: "1",
  },
  {
    id: "2671",
    job_category: "Real Estate Agent",
    skill: "Residential Real Estate",
    count: "1",
  },
  {
    id: "2672",
    job_category: "Real Estate Agent",
    skill: "Commercial Real Estate",
    count: "1",
  },
  {
    id: "2680",
    job_category: "Real Estate Agent",
    skill: "Sales Techniques",
    count: "3",
  },
  {
    id: "2681",
    job_category: "Real Estate Agent",
    skill: "Marketing Strategies",
    count: "2",
  },
  {
    id: "2682",
    job_category: "Real Estate Agent",
    skill: "Local Market Knowledge",
    count: "1",
  },
  {
    id: "2685",
    job_category: "Real Estate Agent",
    skill: "Property Evaluation",
    count: "1",
  },
  {
    id: "2686",
    job_category: "Real Estate Agent",
    skill: "Investment Properties",
    count: "1",
  },
  {
    id: "2687",
    job_category: "Real Estate Agent",
    skill: "Real Estate Financing",
    count: "1",
  },
  { id: "2688", job_category: "Receptionist", skill: "Reception", count: "1" },
  {
    id: "2690",
    job_category: "Receptionist",
    skill: "Telephone Skills",
    count: "1",
  },
  {
    id: "2701",
    job_category: "Receptionist",
    skill: "Front Desk Operations",
    count: "1",
  },
  {
    id: "2702",
    job_category: "Receptionist",
    skill: "Office Equipment",
    count: "1",
  },
  {
    id: "2705",
    job_category: "Receptionist",
    skill: "Document Management",
    count: "1",
  },
  {
    id: "2706",
    job_category: "Receptionist",
    skill: "Filing Systems",
    count: "1",
  },
  {
    id: "2707",
    job_category: "Receptionist",
    skill: "Guest Reception",
    count: "1",
  },
  {
    id: "2710",
    job_category: "Receptionist",
    skill: "Answering Phones",
    count: "1",
  },
  {
    id: "2712",
    job_category: "Receptionist",
    skill: "Reception Area Maintenance",
    count: "1",
  },
  {
    id: "2716",
    job_category: "Receptionist",
    skill: "Vendor Relations",
    count: "3",
  },
  {
    id: "2717",
    job_category: "Receptionist",
    skill: "Customer Support",
    count: "2",
  },
  { id: "2720", job_category: "Sales Associate", skill: "Retail", count: "1" },
  {
    id: "2724",
    job_category: "Sales Associate",
    skill: "Point of Sale (POS)",
    count: "1",
  },
  {
    id: "2729",
    job_category: "Sales Associate",
    skill: "Cross-selling",
    count: "1",
  },
  {
    id: "2737",
    job_category: "Sales Associate",
    skill: "Customer Relations",
    count: "1",
  },
  {
    id: "2738",
    job_category: "Sales Associate",
    skill: "Product Demos",
    count: "1",
  },
  {
    id: "2740",
    job_category: "Sales Associate",
    skill: "Customer Follow-up",
    count: "1",
  },
  {
    id: "2741",
    job_category: "Sales Associate",
    skill: "Sales Reporting",
    count: "1",
  },
  {
    id: "2743",
    job_category: "Sales Associate",
    skill: "Complaint Resolution",
    count: "2",
  },
  {
    id: "2744",
    job_category: "Sales Associate",
    skill: "Relationship Building",
    count: "3",
  },
  {
    id: "2745",
    job_category: "Sales Associate",
    skill: "Service Orientation",
    count: "2",
  },
  {
    id: "2746",
    job_category: "Sales Associate",
    skill: "Merchandise Display",
    count: "1",
  },
  {
    id: "2747",
    job_category: "Sales Associate",
    skill: "Retail Operations",
    count: "1",
  },
  {
    id: "2751",
    job_category: "Social Media Manager",
    skill: "Community Management",
    count: "2",
  },
  {
    id: "2753",
    job_category: "Social Media Manager",
    skill: "Social Media Platforms",
    count: "1",
  },
  {
    id: "2755",
    job_category: "Social Media Manager",
    skill: "Influencer Marketing",
    count: "2",
  },
  {
    id: "2756",
    job_category: "Social Media Manager",
    skill: "Analytics",
    count: "4",
  },
  {
    id: "2758",
    job_category: "Social Media Manager",
    skill: "SEO Optimization",
    count: "1",
  },
  {
    id: "2760",
    job_category: "Social Media Manager",
    skill: "Paid Advertising",
    count: "1",
  },
  {
    id: "2763",
    job_category: "Social Media Manager",
    skill: "Visual Content",
    count: "1",
  },
  {
    id: "2765",
    job_category: "Social Media Manager",
    skill: "Content Calendars",
    count: "1",
  },
  {
    id: "2766",
    job_category: "Social Media Manager",
    skill: "Social Listening",
    count: "1",
  },
  {
    id: "2767",
    job_category: "Social Media Manager",
    skill: "Platform Management",
    count: "2",
  },
  {
    id: "2777",
    job_category: "Social Media Manager",
    skill: "Brand Awareness",
    count: "1",
  },
  {
    id: "2782",
    job_category: "Software Developer",
    skill: "Full Stack Development",
    count: "3",
  },
  {
    id: "2783",
    job_category: "Software Developer",
    skill: "Frontend Development",
    count: "4",
  },
  {
    id: "2784",
    job_category: "Software Developer",
    skill: "Backend Development",
    count: "4",
  },
  { id: "2787", job_category: "Software Developer", skill: "Git", count: "2" },
  {
    id: "2790",
    job_category: "Software Developer",
    skill: "JavaScript",
    count: "9",
  },
  { id: "2791", job_category: "Software Developer", skill: "HTML", count: "8" },
  { id: "2792", job_category: "Software Developer", skill: "CSS", count: "8" },
  {
    id: "2796",
    job_category: "Software Developer",
    skill: "Testing and Debugging",
    count: "5",
  },
  {
    id: "2816",
    job_category: "Teacher",
    skill: "Inquiry-based Learning",
    count: "1",
  },
  { id: "2819", job_category: "Teacher", skill: "Literacy", count: "1" },
  { id: "2820", job_category: "Teacher", skill: "Numeracy", count: "1" },
  {
    id: "2821",
    job_category: "Teacher",
    skill: "Educational Psychology",
    count: "1",
  },
  { id: "2822", job_category: "Teacher", skill: "Pedagogy", count: "1" },
  {
    id: "2836",
    job_category: "Teacher",
    skill: "Learning Strategies",
    count: "1",
  },
  {
    id: "2840",
    job_category: "UX/UI Designer",
    skill: "Wireframing",
    count: "4",
  },
  {
    id: "2842",
    job_category: "UX/UI Designer",
    skill: "Design Thinking",
    count: "3",
  },
  {
    id: "2843",
    job_category: "UX/UI Designer",
    skill: "Interaction Design",
    count: "2",
  },
  {
    id: "2845",
    job_category: "UX/UI Designer",
    skill: "Usability Testing",
    count: "3",
  },
  {
    id: "2846",
    job_category: "UX/UI Designer",
    skill: "Information Architecture",
    count: "2",
  },
  {
    id: "2847",
    job_category: "UX/UI Designer",
    skill: "Visual Design",
    count: "2",
  },
  {
    id: "2848",
    job_category: "UX/UI Designer",
    skill: "Mobile Design",
    count: "2",
  },
  {
    id: "2850",
    job_category: "UX/UI Designer",
    skill: "User Research",
    count: "2",
  },
  {
    id: "2851",
    job_category: "UX/UI Designer",
    skill: "Human-centered Design",
    count: "1",
  },
  {
    id: "2852",
    job_category: "UX/UI Designer",
    skill: "Creative Problem Solving",
    count: "6",
  },
  { id: "2857", job_category: "UX/UI Designer", skill: "Figma", count: "1" },
  { id: "2858", job_category: "UX/UI Designer", skill: "Sketch", count: "1" },
  { id: "2859", job_category: "UX/UI Designer", skill: "InVision", count: "1" },
  {
    id: "2869",
    job_category: "Veterinarian",
    skill: "Veterinary Medicine",
    count: "1",
  },
  {
    id: "2870",
    job_category: "Veterinarian",
    skill: "Animal Care",
    count: "1",
  },
  { id: "2871", job_category: "Veterinarian", skill: "Pet Health", count: "1" },
  { id: "2872", job_category: "Veterinarian", skill: "Diagnosis", count: "1" },
  { id: "2873", job_category: "Veterinarian", skill: "Surgery", count: "2" },
  {
    id: "2874",
    job_category: "Veterinarian",
    skill: "Prescription Medication",
    count: "1",
  },
  {
    id: "2876",
    job_category: "Veterinarian",
    skill: "Laboratory Tests",
    count: "1",
  },
  {
    id: "2878",
    job_category: "Veterinarian",
    skill: "Animal Anatomy",
    count: "1",
  },
  {
    id: "2879",
    job_category: "Veterinarian",
    skill: "Surgical Procedures",
    count: "2",
  },
  {
    id: "2880",
    job_category: "Veterinarian",
    skill: "Emergency Care",
    count: "1",
  },
  {
    id: "2882",
    job_category: "Veterinarian",
    skill: "Animal Behavior",
    count: "2",
  },
  {
    id: "2883",
    job_category: "Veterinarian",
    skill: "Client Communication",
    count: "3",
  },
  {
    id: "2894",
    job_category: "Veterinarian",
    skill: "Animal Nutrition",
    count: "1",
  },
  {
    id: "2895",
    job_category: "Veterinarian",
    skill: "Veterinary Ethics",
    count: "1",
  },
  {
    id: "2897",
    job_category: "Veterinarian",
    skill: "Pet Owner Education",
    count: "1",
  },
  {
    id: "2898",
    job_category: "Waiter/Waitress",
    skill: "Food and Beverage Service",
    count: "1",
  },
  {
    id: "2901",
    job_category: "Waiter/Waitress",
    skill: "Order Accuracy",
    count: "1",
  },
  {
    id: "2902",
    job_category: "Waiter/Waitress",
    skill: "POS Systems",
    count: "1",
  },
  {
    id: "2906",
    job_category: "Waiter/Waitress",
    skill: "Dining Area Cleanliness",
    count: "1",
  },
  {
    id: "2916",
    job_category: "Waiter/Waitress",
    skill: "Menu Recommendations",
    count: "1",
  },
  {
    id: "2917",
    job_category: "Waiter/Waitress",
    skill: "Bussing and Cleaning Tables",
    count: "1",
  },
  {
    id: "2918",
    job_category: "Waiter/Waitress",
    skill: "Order Taking",
    count: "1",
  },
  {
    id: "2919",
    job_category: "Waiter/Waitress",
    skill: "Customer Interaction",
    count: "1",
  },
  {
    id: "2921",
    job_category: "Waiter/Waitress",
    skill: "Shift Management",
    count: "1",
  },
  {
    id: "2923",
    job_category: "Waiter/Waitress",
    skill: "Restaurant Experience",
    count: "1",
  },
  {
    id: "2925",
    job_category: "Waiter/Waitress",
    skill: "Waitstaff Etiquette",
    count: "1",
  },
  {
    id: "2927",
    job_category: "Waiter/Waitress",
    skill: "Table Setting",
    count: "1",
  },
  {
    id: "2938",
    job_category: "Web Developer",
    skill: "Cross-browser Compatibility",
    count: "3",
  },
  { id: "2957", job_category: "Web Developer", skill: "PHP", count: "3" },
  { id: "2959", job_category: "Web Developer", skill: "Java", count: "3" },
  { id: "2960", job_category: "Web Developer", skill: "Ruby", count: "3" },
  { id: "2961", job_category: "Web Developer", skill: "Node.js", count: "4" },
  { id: "2963", job_category: "Web Developer", skill: "MongoDB", count: "2" },
  { id: "2964", job_category: "Web Developer", skill: "React", count: "3" },
  { id: "2965", job_category: "Web Developer", skill: "Angular", count: "4" },
  { id: "2966", job_category: "Web Developer", skill: "Vue.js", count: "4" },
  { id: "2967", job_category: "Web Developer", skill: "Webpack", count: "3" },
  { id: "2968", job_category: "Web Developer", skill: "Bootstrap", count: "2" },
  { id: "2969", job_category: "Web Developer", skill: "Sass/Less", count: "2" },
  { id: "2970", job_category: "Web Developer", skill: "GraphQL", count: "3" },
  {
    id: "2971",
    job_category: "Web Developer",
    skill: "RESTful APIs",
    count: "6",
  },
  { id: "2972", job_category: "Web Developer", skill: "OAuth", count: "2" },
  {
    id: "2974",
    job_category: "Web Developer",
    skill: "Cybersecurity Awareness",
    count: "2",
  },
  { id: "2977", job_category: "Web Developer", skill: "Kanban", count: "3" },
  { id: "2978", job_category: "Web Developer", skill: "DevOps", count: "4" },
  {
    id: "2980",
    job_category: "Web Developer",
    skill: "Cloud Services (AWS Azure GCP)",
    count: "2",
  },
  {
    id: "2982",
    job_category: "Web Developer",
    skill: "Shell Scripting",
    count: "3",
  },
  {
    id: "2983",
    job_category: "Web Developer",
    skill: "Containerization (Docker Kubernetes)",
    count: "2",
  },
  {
    id: "2984",
    job_category: "Web Developer",
    skill: "Microservices Architecture",
    count: "4",
  },
  {
    id: "2985",
    job_category: "Web Developer",
    skill: "Machine Learning Basics",
    count: "2",
  },
  {
    id: "2986",
    job_category: "Web Developer",
    skill: "Artificial Intelligence Basics",
    count: "2",
  },
  {
    id: "2988",
    job_category: "Web Designer",
    skill: "UI/UX Design",
    count: "3",
  },
  {
    id: "2993",
    job_category: "Web Designer",
    skill: "Web Development Basics",
    count: "1",
  },
  {
    id: "2999",
    job_category: "Web Designer",
    skill: "User Interface Design",
    count: "3",
  },
  {
    id: "3000",
    job_category: "Web Designer",
    skill: "User Experience Design",
    count: "1",
  },
  { id: "3009", job_category: "Web Designer", skill: "SEO Basics", count: "1" },
  {
    id: "3010",
    job_category: "Web Designer",
    skill: "Social Media Integration",
    count: "1",
  },
  {
    id: "3018",
    job_category: "Welder",
    skill: "Welding Techniques",
    count: "1",
  },
  {
    id: "3019",
    job_category: "Welder",
    skill: "Metal Fabrication",
    count: "1",
  },
  { id: "3026", job_category: "Welder", skill: "Heat Treatment", count: "1" },
  {
    id: "3030",
    job_category: "Welder",
    skill: "Physical Strength",
    count: "1",
  },
  { id: "3036", job_category: "Writer", skill: "Writing", count: "4" },
  {
    id: "3046",
    job_category: "Writer",
    skill: "Social Media Writing",
    count: "1",
  },
  {
    id: "3058",
    job_category: "Writer",
    skill: "Publishing Knowledge",
    count: "1",
  },
  { id: "3059", job_category: "Writer", skill: "Self-Motivation", count: "2" },
  {
    id: "3060",
    job_category: "Youth Worker",
    skill: "Youth Development",
    count: "1",
  },
  {
    id: "3062",
    job_category: "Youth Worker",
    skill: "Social Work",
    count: "1",
  },
  {
    id: "3067",
    job_category: "Youth Worker",
    skill: "Group Facilitation",
    count: "2",
  },
  {
    id: "3072",
    job_category: "Youth Worker",
    skill: "Listening Skills",
    count: "4",
  },
  {
    id: "3074",
    job_category: "Youth Worker",
    skill: "Assessment Skills",
    count: "2",
  },
  { id: "3081", job_category: "Zoologist", skill: "Zoology", count: "1" },
  { id: "3083", job_category: "Zoologist", skill: "Ecology", count: "1" },
  {
    id: "3084",
    job_category: "Zoologist",
    skill: "Wildlife Conservation",
    count: "1",
  },
  {
    id: "3087",
    job_category: "Zoologist",
    skill: "Scientific Writing",
    count: "1",
  },
  { id: "3088", job_category: "Zoologist", skill: "Taxonomy", count: "1" },
  {
    id: "3089",
    job_category: "Zoologist",
    skill: "Population Dynamics",
    count: "1",
  },
  {
    id: "3090",
    job_category: "Zoologist",
    skill: "Habitat Management",
    count: "1",
  },
  {
    id: "3092",
    job_category: "Zoologist",
    skill: "Animal Welfare",
    count: "1",
  },
  {
    id: "3093",
    job_category: "Zoologist",
    skill: "Species Preservation",
    count: "1",
  },
  { id: "3095", job_category: "Zoologist", skill: "Botany", count: "1" },
  {
    id: "3096",
    job_category: "Zoologist",
    skill: "Natural Resource Management",
    count: "3",
  },
  {
    id: "3109",
    job_category: "Freelance Editor",
    skill: "Content Management",
    count: "5",
  },
  {
    id: "3110",
    job_category: "Freelance Editor",
    skill: "Publishing",
    count: "3",
  },
  {
    id: "3112",
    job_category: "Freelance Editor",
    skill: "Copyediting",
    count: "1",
  },
  {
    id: "3124",
    job_category: "Freelance Editor",
    skill: "Technical Editing",
    count: "1",
  },
  {
    id: "3125",
    job_category: "Freelance Editor",
    skill: "Style Guides",
    count: "2",
  },
  {
    id: "3126",
    job_category: "Freelance Editor",
    skill: "Publication Standards",
    count: "1",
  },
  {
    id: "3208",
    job_category: "Freelance Social Media Manager",
    skill: "Social Media Strategy",
    count: "2",
  },
  {
    id: "3209",
    job_category: "Freelance Social Media Manager",
    skill: "Content Calendar Management",
    count: "1",
  },
  {
    id: "3210",
    job_category: "Freelance Social Media Manager",
    skill: "Analytics Tools",
    count: "1",
  },
  {
    id: "3214",
    job_category: "Freelance Social Media Manager",
    skill: "Advertising Campaigns",
    count: "1",
  },
  {
    id: "3237",
    job_category: "Freelance Marketing Consultant",
    skill: "Customer Acquisition",
    count: "1",
  },
  {
    id: "3238",
    job_category: "Freelance Marketing Consultant",
    skill: "Market Segmentation",
    count: "1",
  },
  {
    id: "3239",
    job_category: "Freelance Marketing Consultant",
    skill: "Competitor Analysis",
    count: "2",
  },
  {
    id: "3240",
    job_category: "Freelance Marketing Consultant",
    skill: "Consulting",
    count: "1",
  },
  {
    id: "3250",
    job_category: "Freelance Accountant",
    skill: "Bookkeeping",
    count: "1",
  },
  {
    id: "3258",
    job_category: "Freelance Accountant",
    skill: "Account Reconciliation",
    count: "1",
  },
  {
    id: "3259",
    job_category: "Freelance Accountant",
    skill: "Cost Management",
    count: "1",
  },
  {
    id: "3260",
    job_category: "Freelance Accountant",
    skill: "GAAP Standards",
    count: "1",
  },
  {
    id: "3261",
    job_category: "Freelance Accountant",
    skill: "QuickBooks",
    count: "1",
  },
  {
    id: "3262",
    job_category: "Freelance Accountant",
    skill: "Xero",
    count: "1",
  },
  {
    id: "3263",
    job_category: "Freelance Accountant",
    skill: "Wave Accounting",
    count: "1",
  },
  {
    id: "3271",
    job_category: "Freelance Accountant",
    skill: "Business Acumen",
    count: "10",
  },
  {
    id: "3272",
    job_category: "Freelance App Developer",
    skill: "App Development",
    count: "1",
  },
  {
    id: "3274",
    job_category: "Freelance App Developer",
    skill: "iOS Development",
    count: "4",
  },
  {
    id: "3275",
    job_category: "Freelance App Developer",
    skill: "Android Development",
    count: "4",
  },
  {
    id: "3341",
    job_category: "Freelance Copywriter",
    skill: "Brand Voice",
    count: "2",
  },
  {
    id: "3348",
    job_category: "Freelance Illustrator",
    skill: "Creative Visualization",
    count: "1",
  },
  {
    id: "3353",
    job_category: "Freelance Illustrator",
    skill: "Character Design",
    count: "2",
  },
  {
    id: "3357",
    job_category: "Freelance Illustrator",
    skill: "Digital Painting",
    count: "1",
  },
  {
    id: "3362",
    job_category: "Freelance Illustrator",
    skill: "Client Collaboration",
    count: "6",
  },
  {
    id: "3365",
    job_category: "Freelance Illustrator",
    skill: "Client Feedback",
    count: "1",
  },
  {
    id: "3366",
    job_category: "Freelance Illustrator",
    skill: "Artistic Techniques",
    count: "1",
  },
  {
    id: "3368",
    job_category: "Freelance Video Editor",
    skill: "Post-Production",
    count: "2",
  },
  {
    id: "3369",
    job_category: "Freelance Video Editor",
    skill: "Adobe Premiere Pro",
    count: "1",
  },
  {
    id: "3370",
    job_category: "Freelance Video Editor",
    skill: "Final Cut Pro",
    count: "1",
  },
  {
    id: "3373",
    job_category: "Freelance Video Editor",
    skill: "Sound Editing",
    count: "2",
  },
  {
    id: "3374",
    job_category: "Freelance Video Editor",
    skill: "Visual Effects",
    count: "2",
  },
  {
    id: "3376",
    job_category: "Freelance Video Editor",
    skill: "Cinematography",
    count: "1",
  },
  {
    id: "3378",
    job_category: "Freelance Video Editor",
    skill: "Broadcasting",
    count: "1",
  },
  {
    id: "3390",
    job_category: "Freelance SEO Specialist",
    skill: "Search Engine Optimization",
    count: "1",
  },
  {
    id: "3392",
    job_category: "Freelance SEO Specialist",
    skill: "On-Page SEO",
    count: "1",
  },
  {
    id: "3393",
    job_category: "Freelance SEO Specialist",
    skill: "Off-Page SEO",
    count: "1",
  },
  {
    id: "3394",
    job_category: "Freelance SEO Specialist",
    skill: "SEO Audits",
    count: "1",
  },
  {
    id: "3395",
    job_category: "Freelance SEO Specialist",
    skill: "Google Analytics",
    count: "1",
  },
  {
    id: "3396",
    job_category: "Freelance SEO Specialist",
    skill: "SEO Tools",
    count: "1",
  },
  {
    id: "3398",
    job_category: "Freelance SEO Specialist",
    skill: "Link Building",
    count: "1",
  },
  {
    id: "3400",
    job_category: "Freelance SEO Specialist",
    skill: "Technical SEO",
    count: "1",
  },
  {
    id: "3401",
    job_category: "Freelance SEO Specialist",
    skill: "Local SEO",
    count: "1",
  },
  {
    id: "3402",
    job_category: "Freelance SEO Specialist",
    skill: "SEO Reporting",
    count: "1",
  },
  {
    id: "3412",
    job_category: "Freelance SEO Specialist",
    skill: "Content Optimization",
    count: "1",
  },
  {
    id: "3413",
    job_category: "Freelance Virtual Assistant",
    skill: "Virtual Assistance",
    count: "1",
  },
  {
    id: "3424",
    job_category: "Freelance Virtual Assistant",
    skill: "Task Prioritization",
    count: "1",
  },
  {
    id: "3427",
    job_category: "Freelance Virtual Assistant",
    skill: "Tech-Savvy",
    count: "1",
  },
  {
    id: "3430",
    job_category: "Freelance Virtual Assistant",
    skill: "Remote Collaboration",
    count: "1",
  },
  {
    id: "3432",
    job_category: "Freelance Virtual Assistant",
    skill: "Time Zone Management",
    count: "1",
  },
  {
    id: "3443",
    job_category: "Freelance Data Analyst",
    skill: "Business Intelligence (BI)",
    count: "1",
  },
  {
    id: "3447",
    job_category: "Freelance Data Analyst",
    skill: "Big Data",
    count: "6",
  },
  {
    id: "3450",
    job_category: "Freelance Data Analyst",
    skill: "Data Warehousing",
    count: "3",
  },
  {
    id: "3451",
    job_category: "Freelance Data Analyst",
    skill: "ETL Processes",
    count: "1",
  },
  {
    id: "3452",
    job_category: "Freelance Data Analyst",
    skill: "Dashboarding",
    count: "1",
  },
  {
    id: "3453",
    job_category: "Freelance Data Analyst",
    skill: "Reporting",
    count: "1",
  },
  {
    id: "3461",
    job_category: "Freelance Financial Consultant",
    skill: "Financial Consulting",
    count: "1",
  },
  {
    id: "3464",
    job_category: "Freelance Financial Consultant",
    skill: "Wealth Management",
    count: "2",
  },
  {
    id: "3465",
    job_category: "Freelance Financial Consultant",
    skill: "Tax Planning",
    count: "2",
  },
  {
    id: "3466",
    job_category: "Freelance Financial Consultant",
    skill: "Retirement Planning",
    count: "2",
  },
  {
    id: "3467",
    job_category: "Freelance Financial Consultant",
    skill: "Estate Planning",
    count: "2",
  },
  {
    id: "3489",
    job_category: "Freelance Interior Designer",
    skill: "Furniture Design",
    count: "2",
  },
  {
    id: "3490",
    job_category: "Freelance Interior Designer",
    skill: "Lighting Design",
    count: "1",
  },
  {
    id: "3506",
    job_category: "Freelance Interior Designer",
    skill: "Spatial Awareness",
    count: "1",
  },
  {
    id: "3507",
    job_category: "Freelance Interior Designer",
    skill: "Artistic Skills",
    count: "1",
  },
  {
    id: "3508",
    job_category: "Freelance IT Consultant",
    skill: "IT Consulting",
    count: "1",
  },
  {
    id: "3509",
    job_category: "Freelance IT Consultant",
    skill: "Network Administration",
    count: "3",
  },
  {
    id: "3510",
    job_category: "Freelance IT Consultant",
    skill: "Systems Integration",
    count: "1",
  },
  {
    id: "3512",
    job_category: "Freelance IT Consultant",
    skill: "Security Consulting",
    count: "1",
  },
  {
    id: "3513",
    job_category: "Freelance IT Consultant",
    skill: "Database Administration",
    count: "3",
  },
  {
    id: "3515",
    job_category: "Freelance IT Consultant",
    skill: "IT Project Management",
    count: "1",
  },
  {
    id: "3516",
    job_category: "Freelance IT Consultant",
    skill: "IT Strategy",
    count: "2",
  },
  {
    id: "3517",
    job_category: "Freelance IT Consultant",
    skill: "Server Management",
    count: "1",
  },
  {
    id: "3518",
    job_category: "Freelance IT Consultant",
    skill: "Disaster Recovery Planning",
    count: "1",
  },
  {
    id: "3519",
    job_category: "Freelance IT Consultant",
    skill: "Virtualization",
    count: "3",
  },
  {
    id: "3520",
    job_category: "Freelance IT Consultant",
    skill: "Network Security",
    count: "8",
  },
  {
    id: "3524",
    job_category: "Freelance IT Consultant",
    skill: "Infrastructure Management",
    count: "1",
  },
  {
    id: "3533",
    job_category: "Freelance Legal Consultant",
    skill: "Legal Consulting",
    count: "1",
  },
  {
    id: "3541",
    job_category: "Freelance Legal Consultant",
    skill: "Mergers and Acquisitions",
    count: "2",
  },
  {
    id: "3545",
    job_category: "Freelance Legal Consultant",
    skill: "Client Representation",
    count: "1",
  },
  {
    id: "3554",
    job_category: "Freelance Legal Consultant",
    skill: "Documentation Review",
    count: "1",
  },
  {
    id: "3555",
    job_category: "Freelance Legal Consultant",
    skill: "Courtroom Experience",
    count: "2",
  },
  {
    id: "3557",
    job_category: "Freelance Translator",
    skill: "Translation",
    count: "1",
  },
  {
    id: "3558",
    job_category: "Freelance Translator",
    skill: "Language Skills",
    count: "1",
  },
  {
    id: "3561",
    job_category: "Freelance Translator",
    skill: "Interpreting",
    count: "1",
  },
  {
    id: "3562",
    job_category: "Freelance Translator",
    skill: "Localization",
    count: "1",
  },
  {
    id: "3563",
    job_category: "Freelance Translator",
    skill: "Document Translation",
    count: "1",
  },
  {
    id: "3564",
    job_category: "Freelance Translator",
    skill: "Technical Translation",
    count: "1",
  },
  {
    id: "3565",
    job_category: "Freelance Translator",
    skill: "Literary Translation",
    count: "1",
  },
  {
    id: "3566",
    job_category: "Freelance Translator",
    skill: "Subtitling",
    count: "1",
  },
  {
    id: "3567",
    job_category: "Freelance Translator",
    skill: "Legal Translation",
    count: "1",
  },
  {
    id: "3568",
    job_category: "Freelance Translator",
    skill: "Medical Translation",
    count: "1",
  },
  {
    id: "3569",
    job_category: "Freelance Translator",
    skill: "Financial Translation",
    count: "1",
  },
  {
    id: "3570",
    job_category: "Freelance Translator",
    skill: "Marketing Translation",
    count: "1",
  },
  {
    id: "3571",
    job_category: "Freelance Translator",
    skill: "Website Localization",
    count: "1",
  },
  {
    id: "3580",
    job_category: "Freelance Translator",
    skill: "Computer-Assisted Translation (CAT)",
    count: "1",
  },
  {
    id: "3581",
    job_category: "Freelance Translator",
    skill: "Multilingual Proficiency",
    count: "1",
  },
  {
    id: "3588",
    job_category: "Freelance Web Developer",
    skill: "Responsive Web Design",
    count: "3",
  },
  {
    id: "3590",
    job_category: "Freelance Web Developer",
    skill: "Web Accessibility",
    count: "1",
  },
  {
    id: "3595",
    job_category: "Freelance Web Developer",
    skill: "E-commerce Development",
    count: "1",
  },
  {
    id: "3596",
    job_category: "Freelance Web Developer",
    skill: "SEO Best Practices",
    count: "1",
  },
  {
    id: "3612",
    job_category: "Freelance Writer",
    skill: "Article Writing",
    count: "1",
  },
  {
    id: "3613",
    job_category: "Freelance Writer",
    skill: "Blog Writing",
    count: "1",
  },
  {
    id: "3614",
    job_category: "Freelance Writer",
    skill: "Ghostwriting",
    count: "1",
  },
  {
    id: "3619",
    job_category: "Freelance Writer",
    skill: "Scriptwriting",
    count: "1",
  },
  {
    id: "3629",
    job_category: "Freelance Writer",
    skill: "Freelance Writing",
    count: "1",
  },
  { id: "3630", job_category: "Coach", skill: "Coaching", count: "1" },
  {
    id: "3633",
    job_category: "Coach",
    skill: "Motivational Speaking",
    count: "3",
  },
  {
    id: "3634",
    job_category: "Coach",
    skill: "Personal Development",
    count: "1",
  },
  { id: "3638", job_category: "Coach", skill: "Goal Setting", count: "3" },
  { id: "3639", job_category: "Coach", skill: "Feedback Delivery", count: "2" },
  {
    id: "3646",
    job_category: "Coach",
    skill: "Performance Improvement",
    count: "1",
  },
  { id: "3648", job_category: "Coach", skill: "Psychology", count: "2" },
  {
    id: "3650",
    job_category: "Communication Specialist",
    skill: "Communication Strategy",
    count: "1",
  },
  {
    id: "3653",
    job_category: "Communication Specialist",
    skill: "Media Relations",
    count: "3",
  },
  {
    id: "3655",
    job_category: "Communication Specialist",
    skill: "Corporate Communications",
    count: "1",
  },
  {
    id: "3656",
    job_category: "Communication Specialist",
    skill: "Crisis Communications",
    count: "1",
  },
  {
    id: "3657",
    job_category: "Communication Specialist",
    skill: "Press Releases",
    count: "3",
  },
  {
    id: "3660",
    job_category: "Communication Specialist",
    skill: "Speechwriting",
    count: "1",
  },
  {
    id: "3663",
    job_category: "Communication Specialist",
    skill: "Internal Communications",
    count: "1",
  },
  {
    id: "3664",
    job_category: "Communication Specialist",
    skill: "External Communications",
    count: "1",
  },
  {
    id: "3673",
    job_category: "Community Health Worker",
    skill: "Health Education",
    count: "2",
  },
  {
    id: "3675",
    job_category: "Community Health Worker",
    skill: "Public Health",
    count: "2",
  },
  {
    id: "3681",
    job_category: "Community Health Worker",
    skill: "Behavioral Health",
    count: "3",
  },
  {
    id: "3687",
    job_category: "Community Health Worker",
    skill: "Resource Referral",
    count: "1",
  },
  {
    id: "3693",
    job_category: "Community Health Worker",
    skill: "Advocacy Skills",
    count: "1",
  },
  {
    id: "3694",
    job_category: "Community Health Worker",
    skill: "Community Development",
    count: "1",
  },
  {
    id: "3695",
    job_category: "Compensation And Benefits Manager",
    skill: "Compensation Management",
    count: "1",
  },
  {
    id: "3696",
    job_category: "Compensation And Benefits Manager",
    skill: "Benefits Administration",
    count: "1",
  },
  {
    id: "3697",
    job_category: "Compensation And Benefits Manager",
    skill: "Job Analysis",
    count: "2",
  },
  {
    id: "3698",
    job_category: "Compensation And Benefits Manager",
    skill: "Salary Surveys",
    count: "1",
  },
  {
    id: "3699",
    job_category: "Compensation And Benefits Manager",
    skill: "Payroll Administration",
    count: "1",
  },
  {
    id: "3702",
    job_category: "Compensation And Benefits Manager",
    skill: "Labor Law Compliance",
    count: "2",
  },
  {
    id: "3704",
    job_category: "Compensation And Benefits Manager",
    skill: "HRIS Systems",
    count: "1",
  },
  {
    id: "3714",
    job_category: "Compensation And Benefits Manager",
    skill: "Strategic Thinking",
    count: "1",
  },
  {
    id: "3721",
    job_category: "Computer Scientist",
    skill: "Artificial Intelligence",
    count: "1",
  },
  {
    id: "3723",
    job_category: "Computer Scientist",
    skill: "Computer Vision",
    count: "2",
  },
  {
    id: "3729",
    job_category: "Computer Scientist",
    skill: "Quantum Computing",
    count: "2",
  },
  {
    id: "3738",
    job_category: "Computer Scientist",
    skill: "Innovative Thinking",
    count: "1",
  },
  {
    id: "3741",
    job_category: "Computer Support Specialist",
    skill: "Help Desk Support",
    count: "1",
  },
  {
    id: "3743",
    job_category: "Computer Support Specialist",
    skill: "Hardware Repair",
    count: "1",
  },
  {
    id: "3748",
    job_category: "Computer Support Specialist",
    skill: "Remote Desktop Support",
    count: "3",
  },
  {
    id: "3749",
    job_category: "Computer Support Specialist",
    skill: "System Upgrades",
    count: "2",
  },
  {
    id: "3758",
    job_category: "Computer Support Specialist",
    skill: "ITIL Framework",
    count: "1",
  },
  {
    id: "3759",
    job_category: "Computer Support Specialist",
    skill: "Service Desk Management",
    count: "1",
  },
  {
    id: "3760",
    job_category: "Computer Support Specialist",
    skill: "Endpoint Management",
    count: "1",
  },
  {
    id: "3761",
    job_category: "Computer Support Specialist",
    skill: "Patch Management",
    count: "1",
  },
  {
    id: "3788",
    job_category: "Copy Editor",
    skill: "Content Editing",
    count: "2",
  },
  {
    id: "3789",
    job_category: "Copy Editor",
    skill: "Fact-Checking",
    count: "2",
  },
  { id: "3790", job_category: "Copy Editor", skill: "Rewriting", count: "1" },
  {
    id: "3802",
    job_category: "Copy Editor",
    skill: "Deadline-Oriented",
    count: "1",
  },
  {
    id: "3805",
    job_category: "Copy Editor",
    skill: "Editing Software",
    count: "1",
  },
  {
    id: "3816",
    job_category: "Copywriter",
    skill: "Campaign Development",
    count: "1",
  },
  {
    id: "3817",
    job_category: "Copywriter",
    skill: "Social Media Content",
    count: "1",
  },
  {
    id: "3818",
    job_category: "Copywriter",
    skill: "Headline Writing",
    count: "1",
  },
  {
    id: "3819",
    job_category: "Copywriter",
    skill: "Tagline Creation",
    count: "1",
  },
  {
    id: "3831",
    job_category: "Corporate Trainer",
    skill: "Training Delivery",
    count: "1",
  },
  {
    id: "3833",
    job_category: "Corporate Trainer",
    skill: "Employee Development",
    count: "1",
  },
  {
    id: "3834",
    job_category: "Corporate Trainer",
    skill: "Training Needs Analysis",
    count: "1",
  },
  {
    id: "3835",
    job_category: "Corporate Trainer",
    skill: "Learning Management Systems (LMS)",
    count: "2",
  },
  {
    id: "3836",
    job_category: "Corporate Trainer",
    skill: "Adult Learning Principles",
    count: "1",
  },
  {
    id: "3838",
    job_category: "Corporate Trainer",
    skill: "Facilitation Skills",
    count: "1",
  },
  {
    id: "3848",
    job_category: "Corporate Trainer",
    skill: "Training Evaluation",
    count: "1",
  },
  {
    id: "3849",
    job_category: "Corporate Trainer",
    skill: "Subject Matter Expertise",
    count: "3",
  },
  {
    id: "3854",
    job_category: "Correctional Officer",
    skill: "Correctional Procedures",
    count: "1",
  },
  {
    id: "3855",
    job_category: "Correctional Officer",
    skill: "Security Operations",
    count: "1",
  },
  {
    id: "3857",
    job_category: "Correctional Officer",
    skill: "Inmate Supervision",
    count: "1",
  },
  {
    id: "3865",
    job_category: "Correctional Officer",
    skill: "Verbal De-escalation",
    count: "1",
  },
  {
    id: "3866",
    job_category: "Correctional Officer",
    skill: "Judgment and Decision Making",
    count: "1",
  },
  {
    id: "3872",
    job_category: "Correctional Officer",
    skill: "Self-Defense Techniques",
    count: "1",
  },
  {
    id: "3874",
    job_category: "Correctional Officer",
    skill: "Physical Agility",
    count: "1",
  },
  {
    id: "3875",
    job_category: "Correctional Officer",
    skill: "Conflict Management",
    count: "2",
  },
  {
    id: "3876",
    job_category: "Correctional Officer",
    skill: "Patrol Procedures",
    count: "1",
  },
  {
    id: "3878",
    job_category: "Cost Estimator",
    skill: "Construction Estimating",
    count: "1",
  },
  {
    id: "3881",
    job_category: "Cost Estimator",
    skill: "Quantity Surveying",
    count: "1",
  },
  {
    id: "3883",
    job_category: "Cost Estimator",
    skill: "Risk Analysis",
    count: "1",
  },
  {
    id: "3884",
    job_category: "Cost Estimator",
    skill: "Bidding Process",
    count: "1",
  },
  {
    id: "3885",
    job_category: "Cost Estimator",
    skill: "Material Takeoff",
    count: "1",
  },
  {
    id: "3888",
    job_category: "Cost Estimator",
    skill: "Excel Skills",
    count: "3",
  },
  {
    id: "3899",
    job_category: "Cost Estimator",
    skill: "Supplier Relations",
    count: "1",
  },
  {
    id: "3900",
    job_category: "Court Reporter",
    skill: "Stenography",
    count: "1",
  },
  {
    id: "3902",
    job_category: "Court Reporter",
    skill: "Transcription",
    count: "1",
  },
  {
    id: "3903",
    job_category: "Court Reporter",
    skill: "Verbatim Reporting",
    count: "1",
  },
  {
    id: "3904",
    job_category: "Court Reporter",
    skill: "Realtime Reporting",
    count: "1",
  },
  {
    id: "3910",
    job_category: "Court Reporter",
    skill: "Audio Equipment",
    count: "1",
  },
  {
    id: "3922",
    job_category: "Court Reporter",
    skill: "Digital Recording",
    count: "1",
  },
  {
    id: "3926",
    job_category: "Credit Analyst",
    skill: "Loan Underwriting",
    count: "1",
  },
  {
    id: "3927",
    job_category: "Credit Analyst",
    skill: "Credit Scoring",
    count: "1",
  },
  {
    id: "3930",
    job_category: "Credit Analyst",
    skill: "Banking Regulations",
    count: "1",
  },
  {
    id: "3931",
    job_category: "Credit Analyst",
    skill: "Credit Reports",
    count: "1",
  },
  {
    id: "3934",
    job_category: "Credit Analyst",
    skill: "Portfolio Management",
    count: "2",
  },
  {
    id: "3945",
    job_category: "Credit Analyst",
    skill: "Financial Statement Analysis",
    count: "1",
  },
  {
    id: "3946",
    job_category: "Crime Analyst",
    skill: "Crime Analysis",
    count: "1",
  },
  {
    id: "3950",
    job_category: "Crime Analyst",
    skill: "Crime Mapping",
    count: "1",
  },
  {
    id: "3956",
    job_category: "Crime Analyst",
    skill: "Pattern Recognition",
    count: "1",
  },
  {
    id: "3966",
    job_category: "Crime Analyst",
    skill: "Evidence Collection",
    count: "4",
  },
  {
    id: "3974",
    job_category: "Criminal Investigator",
    skill: "Forensic Science",
    count: "1",
  },
  {
    id: "3979",
    job_category: "Criminal Investigator",
    skill: "Interrogation",
    count: "1",
  },
  {
    id: "3995",
    job_category: "Customer Service Manager",
    skill: "Service Delivery",
    count: "1",
  },
  {
    id: "4017",
    job_category: "Cyber Security Analyst",
    skill: "Incident Response",
    count: "3",
  },
  {
    id: "4018",
    job_category: "Cyber Security Analyst",
    skill: "Vulnerability Assessment",
    count: "3",
  },
  {
    id: "4019",
    job_category: "Cyber Security Analyst",
    skill: "Penetration Testing",
    count: "2",
  },
  {
    id: "4020",
    job_category: "Cyber Security Analyst",
    skill: "Security Monitoring",
    count: "2",
  },
  {
    id: "4022",
    job_category: "Cyber Security Analyst",
    skill: "Security Policies",
    count: "1",
  },
  {
    id: "4023",
    job_category: "Cyber Security Analyst",
    skill: "Firewall Management",
    count: "1",
  },
  {
    id: "4024",
    job_category: "Cyber Security Analyst",
    skill: "Intrusion Detection",
    count: "1",
  },
  {
    id: "4025",
    job_category: "Cyber Security Analyst",
    skill: "Malware Analysis",
    count: "1",
  },
  {
    id: "4026",
    job_category: "Cyber Security Analyst",
    skill: "Data Protection",
    count: "1",
  },
  {
    id: "4027",
    job_category: "Cyber Security Analyst",
    skill: "Encryption",
    count: "2",
  },
  {
    id: "4028",
    job_category: "Cyber Security Analyst",
    skill: "Cyber Threat Intelligence",
    count: "2",
  },
  {
    id: "4029",
    job_category: "Cyber Security Analyst",
    skill: "Forensics",
    count: "1",
  },
  {
    id: "4037",
    job_category: "Cyber Security Analyst",
    skill: "Ethical Hacking",
    count: "2",
  },
  {
    id: "4038",
    job_category: "Data Engineer",
    skill: "Data Engineering",
    count: "1",
  },
  {
    id: "4039",
    job_category: "Data Engineer",
    skill: "Data Modeling",
    count: "2",
  },
  {
    id: "4040",
    job_category: "Data Engineer",
    skill: "ETL (Extract Transform Load)",
    count: "3",
  },
  {
    id: "4042",
    job_category: "Data Engineer",
    skill: "Database Design",
    count: "3",
  },
  {
    id: "4046",
    job_category: "Data Engineer",
    skill: "Apache Hadoop",
    count: "1",
  },
  {
    id: "4047",
    job_category: "Data Engineer",
    skill: "Apache Spark",
    count: "1",
  },
  {
    id: "4048",
    job_category: "Data Engineer",
    skill: "Data Integration",
    count: "1",
  },
  {
    id: "4050",
    job_category: "Data Engineer",
    skill: "Data Pipelines",
    count: "1",
  },
  { id: "4051", job_category: "Data Engineer", skill: "NoSQL", count: "2" },
  {
    id: "4069",
    job_category: "Data Scientist",
    skill: "Natural Language Processing",
    count: "1",
  },
  {
    id: "4070",
    job_category: "Data Scientist",
    skill: "Deep Learning",
    count: "3",
  },
  { id: "4073", job_category: "Data Scientist", skill: "Hadoop", count: "2" },
  { id: "4074", job_category: "Data Scientist", skill: "Spark", count: "2" },
  {
    id: "4086",
    job_category: "Database Administrator",
    skill: "Data Backup",
    count: "2",
  },
  {
    id: "4088",
    job_category: "Database Administrator",
    skill: "Performance Tuning",
    count: "2",
  },
  {
    id: "4089",
    job_category: "Database Administrator",
    skill: "Security Management",
    count: "1",
  },
  {
    id: "4091",
    job_category: "Database Administrator",
    skill: "Database Administration Tools",
    count: "1",
  },
  {
    id: "4092",
    job_category: "Database Administrator",
    skill: "Disaster Recovery",
    count: "2",
  },
  {
    id: "4093",
    job_category: "Database Administrator",
    skill: "Backup Solutions",
    count: "1",
  },
  {
    id: "4094",
    job_category: "Database Administrator",
    skill: "Query Optimization",
    count: "1",
  },
  {
    id: "4096",
    job_category: "Database Administrator",
    skill: "Database Monitoring",
    count: "1",
  },
  {
    id: "4097",
    job_category: "Database Administrator",
    skill: "SQL Server",
    count: "1",
  },
  {
    id: "4098",
    job_category: "Database Administrator",
    skill: "MySQL",
    count: "1",
  },
  {
    id: "4099",
    job_category: "Database Administrator",
    skill: "Oracle",
    count: "1",
  },
  {
    id: "4100",
    job_category: "Database Administrator",
    skill: "PostgreSQL",
    count: "1",
  },
  {
    id: "4102",
    job_category: "Database Administrator",
    skill: "Cloud Databases",
    count: "1",
  },
  {
    id: "4103",
    job_category: "Database Administrator",
    skill: "Linux",
    count: "2",
  },
  {
    id: "4104",
    job_category: "Database Administrator",
    skill: "Windows Server",
    count: "1",
  },
  {
    id: "4105",
    job_category: "Database Administrator",
    skill: "Unix",
    count: "1",
  },
  {
    id: "4107",
    job_category: "Desktop Support Specialist",
    skill: "Hardware Troubleshooting",
    count: "1",
  },
  {
    id: "4110",
    job_category: "Desktop Support Specialist",
    skill: "Network Connectivity",
    count: "1",
  },
  {
    id: "4114",
    job_category: "Desktop Support Specialist",
    skill: "Troubleshooting Skills",
    count: "1",
  },
  {
    id: "4116",
    job_category: "Desktop Support Specialist",
    skill: "Windows",
    count: "1",
  },
  {
    id: "4117",
    job_category: "Desktop Support Specialist",
    skill: "Mac OS",
    count: "1",
  },
  {
    id: "4119",
    job_category: "Desktop Support Specialist",
    skill: "Active Directory",
    count: "1",
  },
  {
    id: "4121",
    job_category: "Desktop Support Specialist",
    skill: "IT Support",
    count: "1",
  },
  {
    id: "4128",
    job_category: "Dietitian",
    skill: "Nutrition Counseling",
    count: "1",
  },
  {
    id: "4129",
    job_category: "Dietitian",
    skill: "Dietary Assessment",
    count: "1",
  },
  { id: "4130", job_category: "Dietitian", skill: "Meal Planning", count: "2" },
  {
    id: "4131",
    job_category: "Dietitian",
    skill: "Medical Nutrition Therapy",
    count: "1",
  },
  {
    id: "4133",
    job_category: "Dietitian",
    skill: "Nutritional Education",
    count: "1",
  },
  {
    id: "4134",
    job_category: "Dietitian",
    skill: "Weight Management",
    count: "2",
  },
  {
    id: "4135",
    job_category: "Dietitian",
    skill: "Clinical Nutrition",
    count: "1",
  },
  {
    id: "4136",
    job_category: "Dietitian",
    skill: "Disease Management",
    count: "1",
  },
  {
    id: "4137",
    job_category: "Dietitian",
    skill: "Public Health Nutrition",
    count: "2",
  },
  { id: "4138", job_category: "Dietitian", skill: "Food Science", count: "2" },
  {
    id: "4139",
    job_category: "Dietitian",
    skill: "Nutrient Analysis",
    count: "1",
  },
  {
    id: "4141",
    job_category: "Dietitian",
    skill: "Behavioral Change",
    count: "2",
  },
  {
    id: "4142",
    job_category: "Dietitian",
    skill: "Healthcare Management",
    count: "2",
  },
  {
    id: "4174",
    job_category: "Doctor",
    skill: "Medical Diagnosis",
    count: "1",
  },
  {
    id: "4177",
    job_category: "Doctor",
    skill: "Emergency Medicine",
    count: "3",
  },
  {
    id: "4179",
    job_category: "Doctor",
    skill: "Internal Medicine",
    count: "2",
  },
  { id: "4180", job_category: "Doctor", skill: "Pediatrics", count: "1" },
  {
    id: "4181",
    job_category: "Doctor",
    skill: "Obstetrics and Gynecology",
    count: "1",
  },
  {
    id: "4186",
    job_category: "Doctor",
    skill: "Healthcare Administration",
    count: "1",
  },
  {
    id: "4198",
    job_category: "Economist",
    skill: "Macroeconomics",
    count: "1",
  },
  {
    id: "4199",
    job_category: "Economist",
    skill: "Microeconomics",
    count: "1",
  },
  { id: "4201", job_category: "Economist", skill: "Econometrics", count: "1" },
  { id: "4208", job_category: "Economist", skill: "Public Policy", count: "1" },
  {
    id: "4209",
    job_category: "Economist",
    skill: "International Economics",
    count: "1",
  },
  {
    id: "4210",
    job_category: "Economist",
    skill: "Labor Economics",
    count: "1",
  },
  {
    id: "4211",
    job_category: "Economist",
    skill: "Environmental Economics",
    count: "1",
  },
  {
    id: "4212",
    job_category: "Economist",
    skill: "Economic Development",
    count: "1",
  },
  {
    id: "4219",
    job_category: "Economist",
    skill: "Policy Writing",
    count: "1",
  },
  { id: "4224", job_category: "Editor", skill: "Copy Editing", count: "1" },
  {
    id: "4228",
    job_category: "Editor",
    skill: "Chicago Manual of Style",
    count: "1",
  },
  {
    id: "4230",
    job_category: "Editor",
    skill: "Manuscript Evaluation",
    count: "1",
  },
  {
    id: "4231",
    job_category: "Editor",
    skill: "Publication Design",
    count: "1",
  },
  {
    id: "4245",
    job_category: "Electrical Engineer",
    skill: "Power Systems",
    count: "1",
  },
  {
    id: "4247",
    job_category: "Electrical Engineer",
    skill: "Microcontrollers",
    count: "1",
  },
  {
    id: "4249",
    job_category: "Electrical Engineer",
    skill: "PLC Programming",
    count: "1",
  },
  {
    id: "4251",
    job_category: "Electrical Engineer",
    skill: "Electrical Testing",
    count: "1",
  },
  {
    id: "4252",
    job_category: "Electrical Engineer",
    skill: "Wiring Diagrams",
    count: "1",
  },
  {
    id: "4253",
    job_category: "Electrical Engineer",
    skill: "Schematic Design",
    count: "1",
  },
  {
    id: "4265",
    job_category: "Emergency Medical Technician (EMT)",
    skill: "Emergency Medical Services (EMS)",
    count: "2",
  },
  {
    id: "4266",
    job_category: "Emergency Medical Technician (EMT)",
    skill: "Basic Life Support (BLS)",
    count: "1",
  },
  {
    id: "4270",
    job_category: "Emergency Medical Technician (EMT)",
    skill: "Trauma Care",
    count: "2",
  },
  {
    id: "4271",
    job_category: "Emergency Medical Technician (EMT)",
    skill: "Ambulance Operations",
    count: "2",
  },
  {
    id: "4273",
    job_category: "Emergency Medical Technician (EMT)",
    skill: "Medical Equipment",
    count: "1",
  },
  {
    id: "4309",
    job_category: "Event Planner",
    skill: "Event Management",
    count: "1",
  },
  {
    id: "4327",
    job_category: "Event Planner",
    skill: "Guest Services",
    count: "1",
  },
  {
    id: "4328",
    job_category: "Event Planner",
    skill: "Event Marketing",
    count: "1",
  },
  {
    id: "4330",
    job_category: "Financial Advisor",
    skill: "Investment Advisory",
    count: "1",
  },
  {
    id: "4338",
    job_category: "Financial Advisor",
    skill: "Client Relationship Management (CRM)",
    count: "1",
  },
  {
    id: "4339",
    job_category: "Financial Advisor",
    skill: "Insurance Planning",
    count: "1",
  },
  {
    id: "4363",
    job_category: "Financial Analyst",
    skill: "Business Analysis",
    count: "3",
  },
  {
    id: "4393",
    job_category: "Financial Manager",
    skill: "Leadership Skills",
    count: "4",
  },
  {
    id: "4395",
    job_category: "Firefighter",
    skill: "Firefighting",
    count: "1",
  },
  {
    id: "4398",
    job_category: "Firefighter",
    skill: "Search and Rescue",
    count: "1",
  },
  {
    id: "4400",
    job_category: "Firefighter",
    skill: "Hazardous Materials",
    count: "1",
  },
  {
    id: "4401",
    job_category: "Firefighter",
    skill: "Fire Equipment Operation",
    count: "1",
  },
  {
    id: "4402",
    job_category: "Firefighter",
    skill: "Fire Investigation",
    count: "1",
  },
  {
    id: "4403",
    job_category: "Firefighter",
    skill: "Incident Command System",
    count: "1",
  },
  {
    id: "4412",
    job_category: "Firefighter",
    skill: "Rescue Operations",
    count: "1",
  },
  {
    id: "4415",
    job_category: "Firefighter",
    skill: "Equipment Maintenance",
    count: "2",
  },
  {
    id: "4416",
    job_category: "Firefighter",
    skill: "Fire Behavior",
    count: "1",
  },
  {
    id: "4418",
    job_category: "Fitness Instructor",
    skill: "Personal Training",
    count: "2",
  },
  {
    id: "4421",
    job_category: "Fitness Instructor",
    skill: "Weight Training",
    count: "1",
  },
  {
    id: "4422",
    job_category: "Fitness Instructor",
    skill: "Cardiovascular Training",
    count: "2",
  },
  {
    id: "4423",
    job_category: "Fitness Instructor",
    skill: "Group Exercise",
    count: "1",
  },
  {
    id: "4424",
    job_category: "Fitness Instructor",
    skill: "Flexibility Training",
    count: "2",
  },
  {
    id: "4425",
    job_category: "Fitness Instructor",
    skill: "Motivation Techniques",
    count: "1",
  },
  {
    id: "4426",
    job_category: "Fitness Instructor",
    skill: "Injury Prevention",
    count: "3",
  },
  {
    id: "4436",
    job_category: "Fitness Instructor",
    skill: "Fitness Assessment",
    count: "1",
  },
  {
    id: "4439",
    job_category: "Flight Attendant",
    skill: "Cabin Safety",
    count: "1",
  },
  {
    id: "4446",
    job_category: "Flight Attendant",
    skill: "Passenger Assistance",
    count: "1",
  },
  {
    id: "4460",
    job_category: "Flight Attendant",
    skill: "Self-Confidence",
    count: "2",
  },
  {
    id: "4467",
    job_category: "Front End Developer",
    skill: "Front End Frameworks (e.g. React Vue)",
    count: "1",
  },
  {
    id: "4468",
    job_category: "Front End Developer",
    skill: "Testing/Debugging",
    count: "2",
  },
  {
    id: "4469",
    job_category: "Front End Developer",
    skill: "Version Control/Git",
    count: "2",
  },
  {
    id: "4481",
    job_category: "Front End Developer",
    skill: "AJAX",
    count: "1",
  },
  {
    id: "4484",
    job_category: "Full Stack Developer",
    skill: "Front End Development",
    count: "1",
  },
  {
    id: "4485",
    job_category: "Full Stack Developer",
    skill: "Back End Development",
    count: "1",
  },
  {
    id: "4497",
    job_category: "Full Stack Developer",
    skill: "Web Servers (e.g. Apache Nginx)",
    count: "1",
  },
  {
    id: "4507",
    job_category: "Game Designer",
    skill: "Game Design",
    count: "3",
  },
  { id: "4509", job_category: "Game Designer", skill: "Unity", count: "2" },
  {
    id: "4511",
    job_category: "Game Designer",
    skill: "Level Design",
    count: "1",
  },
  {
    id: "4512",
    job_category: "Game Designer",
    skill: "Scripting Languages (e.g. C# JavaScript)",
    count: "1",
  },
  {
    id: "4513",
    job_category: "Game Designer",
    skill: "Game Engines",
    count: "1",
  },
  {
    id: "4516",
    job_category: "Game Designer",
    skill: "Game Mechanics",
    count: "1",
  },
  {
    id: "4535",
    job_category: "Graphic Designer",
    skill: "Digital Design",
    count: "1",
  },
  {
    id: "4536",
    job_category: "Graphic Designer",
    skill: "Brand Identity Design",
    count: "1",
  },
  {
    id: "4540",
    job_category: "Graphic Designer",
    skill: "Presentation Design",
    count: "1",
  },
  {
    id: "4551",
    job_category: "Graphic Designer",
    skill: "Digital Imaging",
    count: "3",
  },
  {
    id: "4553",
    job_category: "HR Generalist",
    skill: "Recruitment and Selection",
    count: "2",
  },
  {
    id: "4558",
    job_category: "HR Generalist",
    skill: "HR Policies",
    count: "1",
  },
  { id: "4559", job_category: "HR Generalist", skill: "Labor Law", count: "1" },
  {
    id: "4577",
    job_category: "Illustrator",
    skill: "Adobe Illustrator",
    count: "1",
  },
  {
    id: "4578",
    job_category: "Illustrator",
    skill: "Creative Skills",
    count: "3",
  },
  { id: "4582", job_category: "Illustrator", skill: "Concept Art", count: "1" },
  { id: "4586", job_category: "Illustrator", skill: "Fine Arts", count: "1" },
  {
    id: "4596",
    job_category: "Industrial Designer",
    skill: "Industrial Design",
    count: "1",
  },
  { id: "4598", job_category: "Industrial Designer", skill: "CAD", count: "1" },
  {
    id: "4604",
    job_category: "Industrial Designer",
    skill: "Sketching",
    count: "2",
  },
  {
    id: "4605",
    job_category: "Industrial Designer",
    skill: "Design Research",
    count: "1",
  },
  {
    id: "4606",
    job_category: "Industrial Designer",
    skill: "Visualization",
    count: "1",
  },
  {
    id: "4607",
    job_category: "Industrial Designer",
    skill: "User-Centered Design",
    count: "2",
  },
  {
    id: "4621",
    job_category: "Information Security Analyst",
    skill: "Security Protocols",
    count: "1",
  },
  {
    id: "4624",
    job_category: "Information Security Analyst",
    skill: "Intrusion Detection Systems (IDS)",
    count: "1",
  },
  {
    id: "4626",
    job_category: "Information Security Analyst",
    skill: "Firewalls",
    count: "1",
  },
  {
    id: "4630",
    job_category: "Information Security Analyst",
    skill: "Compliance Management",
    count: "1",
  },
  {
    id: "4640",
    job_category: "IT Manager",
    skill: "Information Technology",
    count: "3",
  },
  {
    id: "4641",
    job_category: "IT Manager",
    skill: "IT Management",
    count: "1",
  },
  {
    id: "4644",
    job_category: "IT Manager",
    skill: "Systems Administration",
    count: "1",
  },
  {
    id: "4645",
    job_category: "IT Manager",
    skill: "Hardware and Software Support",
    count: "1",
  },
  {
    id: "4649",
    job_category: "IT Manager",
    skill: "IT Infrastructure",
    count: "2",
  },
  {
    id: "4664",
    job_category: "IT Specialist",
    skill: "Computer Systems",
    count: "2",
  },
  {
    id: "4666",
    job_category: "IT Specialist",
    skill: "Hardware and Software Troubleshooting",
    count: "2",
  },
  {
    id: "4669",
    job_category: "IT Specialist",
    skill: "System Maintenance",
    count: "2",
  },
  {
    id: "4680",
    job_category: "IT Specialist",
    skill: "Mobile Device Management",
    count: "2",
  },
  {
    id: "4707",
    job_category: "Journalist",
    skill: "News Gathering",
    count: "1",
  },
  { id: "4721", job_category: "Journalist", skill: "Multimedia", count: "1" },
  { id: "4732", job_category: "Lawyer", skill: "Case Preparation", count: "1" },
  { id: "4734", job_category: "Lawyer", skill: "Trial Practice", count: "1" },
  { id: "4742", job_category: "Lawyer", skill: "Persuasion", count: "1" },
  {
    id: "4754",
    job_category: "Librarian",
    skill: "Collection Development",
    count: "1",
  },
  {
    id: "4758",
    job_category: "Librarian",
    skill: "Library Programs",
    count: "1",
  },
  {
    id: "4769",
    job_category: "Librarian",
    skill: "Education and Training",
    count: "1",
  },
  {
    id: "4771",
    job_category: "Life Coach",
    skill: "Life Coaching",
    count: "1",
  },
  { id: "4774", job_category: "Life Coach", skill: "Empowerment", count: "1" },
  {
    id: "4775",
    job_category: "Life Coach",
    skill: "Positive Psychology",
    count: "1",
  },
  { id: "4797", job_category: "Logistician", skill: "Warehousing", count: "1" },
  {
    id: "4798",
    job_category: "Logistician",
    skill: "Transportation",
    count: "1",
  },
  {
    id: "4799",
    job_category: "Logistician",
    skill: "Demand Forecasting",
    count: "1",
  },
  {
    id: "4800",
    job_category: "Logistician",
    skill: "Distribution",
    count: "1",
  },
  {
    id: "4801",
    job_category: "Logistician",
    skill: "Shipping/Receiving",
    count: "1",
  },
  {
    id: "4812",
    job_category: "Logistician",
    skill: "Supply Chain Optimization",
    count: "1",
  },
  {
    id: "4816",
    job_category: "Machine Learning Engineer",
    skill: "Data Science",
    count: "1",
  },
  {
    id: "4817",
    job_category: "Machine Learning Engineer",
    skill: "Artificial Intelligence (AI)",
    count: "1",
  },
  {
    id: "4819",
    job_category: "Machine Learning Engineer",
    skill: "Neural Networks",
    count: "2",
  },
  {
    id: "4820",
    job_category: "Machine Learning Engineer",
    skill: "Algorithms",
    count: "1",
  },
  {
    id: "4821",
    job_category: "Machine Learning Engineer",
    skill: "Natural Language Processing (NLP)",
    count: "2",
  },
  {
    id: "4822",
    job_category: "Machine Learning Engineer",
    skill: "Model Evaluation",
    count: "1",
  },
  {
    id: "4823",
    job_category: "Machine Learning Engineer",
    skill: "TensorFlow",
    count: "1",
  },
  {
    id: "4824",
    job_category: "Machine Learning Engineer",
    skill: "Scikit-Learn",
    count: "2",
  },
  {
    id: "4825",
    job_category: "Machine Learning Engineer",
    skill: "PyTorch",
    count: "1",
  },
  {
    id: "4827",
    job_category: "Machine Learning Engineer",
    skill: "Statistics",
    count: "1",
  },
  {
    id: "4836",
    job_category: "Maintenance Technician",
    skill: "Maintenance",
    count: "1",
  },
  {
    id: "4837",
    job_category: "Maintenance Technician",
    skill: "Repair",
    count: "1",
  },
  {
    id: "4841",
    job_category: "Maintenance Technician",
    skill: "Blueprints",
    count: "1",
  },
  {
    id: "4844",
    job_category: "Maintenance Technician",
    skill: "Hydraulics",
    count: "1",
  },
  {
    id: "4845",
    job_category: "Maintenance Technician",
    skill: "Pneumatics",
    count: "1",
  },
  {
    id: "4847",
    job_category: "Maintenance Technician",
    skill: "Mechanical Systems",
    count: "1",
  },
  {
    id: "4848",
    job_category: "Maintenance Technician",
    skill: "Welding",
    count: "1",
  },
  {
    id: "4849",
    job_category: "Maintenance Technician",
    skill: "Plumbing",
    count: "2",
  },
  {
    id: "4850",
    job_category: "Maintenance Technician",
    skill: "HVAC",
    count: "1",
  },
  { id: "4858", job_category: "Manager", skill: "Management", count: "1" },
  { id: "4861", job_category: "Manager", skill: "Team Management", count: "2" },
  {
    id: "4879",
    job_category: "Emergency Management Director",
    skill: "Emergency Management",
    count: "1",
  },
  {
    id: "4880",
    job_category: "Emergency Management Director",
    skill: "Disaster Response",
    count: "1",
  },
  {
    id: "4884",
    job_category: "Emergency Management Director",
    skill: "Emergency Planning",
    count: "1",
  },
  {
    id: "4893",
    job_category: "Emergency Management Director",
    skill: "Coordination",
    count: "1",
  },
  {
    id: "4896",
    job_category: "Energy Consultant",
    skill: "Energy Efficiency",
    count: "2",
  },
  {
    id: "4898",
    job_category: "Energy Consultant",
    skill: "Energy Audits",
    count: "1",
  },
  {
    id: "4899",
    job_category: "Energy Consultant",
    skill: "Energy Management",
    count: "1",
  },
  {
    id: "4900",
    job_category: "Energy Consultant",
    skill: "Sustainability",
    count: "3",
  },
  {
    id: "4901",
    job_category: "Energy Consultant",
    skill: "Building Systems",
    count: "1",
  },
  {
    id: "4902",
    job_category: "Energy Consultant",
    skill: "Technical Analysis",
    count: "1",
  },
  {
    id: "4916",
    job_category: "Engineering Manager",
    skill: "Engineering Management",
    count: "1",
  },
  {
    id: "4933",
    job_category: "Engineering Manager",
    skill: "Cross-Functional Team Leadership",
    count: "1",
  },
  {
    id: "4937",
    job_category: "Environmental Engineer",
    skill: "Environmental Regulations",
    count: "2",
  },
  {
    id: "4938",
    job_category: "Environmental Engineer",
    skill: "Water Treatment",
    count: "1",
  },
  {
    id: "4939",
    job_category: "Environmental Engineer",
    skill: "Air Quality Management",
    count: "1",
  },
  {
    id: "4940",
    job_category: "Environmental Engineer",
    skill: "Waste Management",
    count: "1",
  },
  {
    id: "4942",
    job_category: "Environmental Engineer",
    skill: "Climate Change Mitigation",
    count: "1",
  },
  {
    id: "4956",
    job_category: "Environmental Scientist",
    skill: "Environmental Science",
    count: "1",
  },
  {
    id: "4962",
    job_category: "Environmental Scientist",
    skill: "Climate Change",
    count: "1",
  },
  {
    id: "4963",
    job_category: "Environmental Scientist",
    skill: "Ecosystem Management",
    count: "1",
  },
  {
    id: "4965",
    job_category: "Environmental Scientist",
    skill: "Sampling Techniques",
    count: "1",
  },
  {
    id: "4980",
    job_category: "Family Therapist",
    skill: "Clinical Psychology",
    count: "2",
  },
  {
    id: "4981",
    job_category: "Family Therapist",
    skill: "Mental Health Counseling",
    count: "1",
  },
  {
    id: "4992",
    job_category: "Family Therapist",
    skill: "Family Dynamics",
    count: "1",
  },
  {
    id: "4994",
    job_category: "Family Therapist",
    skill: "Adolescent Counseling",
    count: "1",
  },
  {
    id: "4997",
    job_category: "Fashion Designer",
    skill: "Fashion Design",
    count: "1",
  },
  {
    id: "4999",
    job_category: "Fashion Designer",
    skill: "Pattern Making",
    count: "1",
  },
  {
    id: "5000",
    job_category: "Fashion Designer",
    skill: "Garment Construction",
    count: "1",
  },
  {
    id: "5001",
    job_category: "Fashion Designer",
    skill: "Textiles",
    count: "1",
  },
  {
    id: "5002",
    job_category: "Fashion Designer",
    skill: "Fashion Trends",
    count: "2",
  },
  {
    id: "5004",
    job_category: "Fashion Designer",
    skill: "CAD Software (e.g. Adobe Illustrator)",
    count: "1",
  },
  { id: "5007", job_category: "Fashion Designer", skill: "Sewing", count: "1" },
  {
    id: "5008",
    job_category: "Fashion Designer",
    skill: "Fashion Merchandising",
    count: "1",
  },
  {
    id: "5009",
    job_category: "Fashion Designer",
    skill: "Fashion History",
    count: "1",
  },
  {
    id: "5017",
    job_category: "Film Editor",
    skill: "Film Editing",
    count: "1",
  },
  {
    id: "5018",
    job_category: "Film Editor",
    skill: "Video Editing Software (e.g. Adobe Premiere Final Cut Pro)",
    count: "1",
  },
  {
    id: "5038",
    job_category: "Fitness Trainer",
    skill: "Exercise Science",
    count: "1",
  },
  {
    id: "5039",
    job_category: "Fitness Trainer",
    skill: "Fitness Programming",
    count: "1",
  },
  {
    id: "5040",
    job_category: "Fitness Trainer",
    skill: "Nutrition Guidance",
    count: "2",
  },
  {
    id: "5041",
    job_category: "Fitness Trainer",
    skill: "Strength Training",
    count: "2",
  },
  {
    id: "5046",
    job_category: "Fitness Trainer",
    skill: "Group Fitness",
    count: "1",
  },
  {
    id: "5047",
    job_category: "Fitness Trainer",
    skill: "Health Coaching",
    count: "2",
  },
  { id: "5057", job_category: "Florist", skill: "Floral Design", count: "1" },
  {
    id: "5058",
    job_category: "Florist",
    skill: "Flower Arrangement",
    count: "1",
  },
  { id: "5060", job_category: "Florist", skill: "Plant Care", count: "1" },
  {
    id: "5063",
    job_category: "Florist",
    skill: "Wedding Arrangements",
    count: "1",
  },
  { id: "5064", job_category: "Florist", skill: "Event Design", count: "1" },
  {
    id: "5072",
    job_category: "Florist",
    skill: "Floral Supply Chain",
    count: "1",
  },
  {
    id: "5079",
    job_category: "Food Scientist",
    skill: "Nutrition Research",
    count: "2",
  },
  {
    id: "5080",
    job_category: "Food Scientist",
    skill: "Food Chemistry",
    count: "1",
  },
  {
    id: "5085",
    job_category: "Food Scientist",
    skill: "Sensory Evaluation",
    count: "1",
  },
  {
    id: "5086",
    job_category: "Food Scientist",
    skill: "Microbiology",
    count: "3",
  },
  {
    id: "5087",
    job_category: "Forensic Scientist",
    skill: "Forensic Chemistry",
    count: "1",
  },
  {
    id: "5089",
    job_category: "Forensic Scientist",
    skill: "Forensic Biology",
    count: "1",
  },
  {
    id: "5092",
    job_category: "Forensic Scientist",
    skill: "Ballistics",
    count: "1",
  },
  {
    id: "5093",
    job_category: "Forensic Scientist",
    skill: "DNA Analysis",
    count: "1",
  },
  {
    id: "5094",
    job_category: "Forensic Scientist",
    skill: "Toxicology",
    count: "1",
  },
  {
    id: "5095",
    job_category: "Forensic Scientist",
    skill: "Expert Witness",
    count: "1",
  },
  {
    id: "5105",
    job_category: "Freelance Artist",
    skill: "Visual Arts",
    count: "1",
  },
  {
    id: "5111",
    job_category: "Freelance Graphic Designer",
    skill: "Logo Design",
    count: "1",
  },
  {
    id: "5128",
    job_category: "Fundraiser",
    skill: "Donor Relations",
    count: "1",
  },
  {
    id: "5130",
    job_category: "Fundraiser",
    skill: "Nonprofit Management",
    count: "1",
  },
  {
    id: "5132",
    job_category: "Fundraiser",
    skill: "Prospect Research",
    count: "1",
  },
  { id: "5135", job_category: "Fundraiser", skill: "Major Gifts", count: "1" },
  {
    id: "5138",
    job_category: "Game Developer",
    skill: "Game Programming",
    count: "1",
  },
  { id: "5139", job_category: "Game Developer", skill: "Unity3D", count: "1" },
  { id: "5140", job_category: "Game Developer", skill: "C++", count: "2" },
  {
    id: "5141",
    job_category: "Game Developer",
    skill: "Game Engine Development",
    count: "1",
  },
  {
    id: "5142",
    job_category: "Game Developer",
    skill: "Mobile Game Development",
    count: "2",
  },
  {
    id: "5143",
    job_category: "Game Developer",
    skill: "Artificial Intelligence in Games",
    count: "2",
  },
  {
    id: "5145",
    job_category: "Game Developer",
    skill: "Multiplayer Game Development",
    count: "2",
  },
  {
    id: "5146",
    job_category: "Game Developer",
    skill: "Virtual Reality (VR) Development",
    count: "1",
  },
  {
    id: "5155",
    job_category: "General Manager",
    skill: "Performance Evaluation",
    count: "1",
  },
  {
    id: "5157",
    job_category: "Genetic Counselor",
    skill: "Genetic Counseling",
    count: "1",
  },
  {
    id: "5158",
    job_category: "Genetic Counselor",
    skill: "Medical Genetics",
    count: "1",
  },
  {
    id: "5160",
    job_category: "Genetic Counselor",
    skill: "Genetic Testing",
    count: "1",
  },
  {
    id: "5162",
    job_category: "Genetic Counselor",
    skill: "Family History Analysis",
    count: "1",
  },
  {
    id: "5163",
    job_category: "Genetic Counselor",
    skill: "Ethical Issues in Genetics",
    count: "1",
  },
  {
    id: "5165",
    job_category: "Genetic Counselor",
    skill: "Genomic Data Analysis",
    count: "1",
  },
  {
    id: "5172",
    job_category: "Geographer",
    skill: "Environmental Geography",
    count: "1",
  },
  {
    id: "5173",
    job_category: "Geographer",
    skill: "Population Studies",
    count: "1",
  },
  {
    id: "5174",
    job_category: "Geographer",
    skill: "Climate Change Modeling",
    count: "2",
  },
  {
    id: "5175",
    job_category: "Geographer",
    skill: "Geospatial Data Management",
    count: "1",
  },
  { id: "5177", job_category: "Geologist", skill: "Geology", count: "1" },
  { id: "5178", job_category: "Geologist", skill: "Mineralogy", count: "1" },
  { id: "5179", job_category: "Geologist", skill: "Sedimentology", count: "1" },
  { id: "5180", job_category: "Geologist", skill: "Stratigraphy", count: "1" },
  { id: "5181", job_category: "Geologist", skill: "Hydrogeology", count: "1" },
  {
    id: "5182",
    job_category: "Geologist",
    skill: "Petroleum Geology",
    count: "1",
  },
  {
    id: "5183",
    job_category: "Geologist",
    skill: "Environmental Geology",
    count: "1",
  },
  { id: "5185", job_category: "Geologist", skill: "Geochemistry", count: "1" },
  {
    id: "5187",
    job_category: "Hairdresser",
    skill: "Hair Cutting",
    count: "1",
  },
  {
    id: "5188",
    job_category: "Hairdresser",
    skill: "Hair Coloring",
    count: "1",
  },
  { id: "5189", job_category: "Hairdresser", skill: "Hairstyling", count: "1" },
  {
    id: "5190",
    job_category: "Hairdresser",
    skill: "Scalp Treatments",
    count: "1",
  },
  {
    id: "5191",
    job_category: "Hairdresser",
    skill: "Client Consultation",
    count: "1",
  },
  {
    id: "5192",
    job_category: "Hairdresser",
    skill: "Hair Extensions",
    count: "1",
  },
  {
    id: "5193",
    job_category: "Hairdresser",
    skill: "Salon Management",
    count: "1",
  },
  {
    id: "5198",
    job_category: "Health Educator",
    skill: "Public Health Education",
    count: "1",
  },
  {
    id: "5201",
    job_category: "Health Educator",
    skill: "Program Development",
    count: "1",
  },
  {
    id: "5203",
    job_category: "Health Educator",
    skill: "Behavior Change",
    count: "1",
  },
  {
    id: "5204",
    job_category: "Health Educator",
    skill: "Health Communication",
    count: "1",
  },
  {
    id: "5208",
    job_category: "Healthcare Administrator",
    skill: "Healthcare Policy",
    count: "1",
  },
  {
    id: "5211",
    job_category: "Healthcare Administrator",
    skill: "Quality Improvement",
    count: "1",
  },
  {
    id: "5213",
    job_category: "Healthcare Administrator",
    skill: "Patient Care Management",
    count: "1",
  },
  {
    id: "5215",
    job_category: "Healthcare Administrator",
    skill: "Health Informatics",
    count: "1",
  },
  {
    id: "5218",
    job_category: "Heating Ventilation And Air Conditioning (HVAC) Technician",
    skill: "HVAC Installation",
    count: "1",
  },
  {
    id: "5219",
    job_category: "Heating Ventilation And Air Conditioning (HVAC) Technician",
    skill: "HVAC Maintenance",
    count: "2",
  },
  {
    id: "5220",
    job_category: "Heating Ventilation And Air Conditioning (HVAC) Technician",
    skill: "Refrigeration",
    count: "1",
  },
  {
    id: "5221",
    job_category: "Heating Ventilation And Air Conditioning (HVAC) Technician",
    skill: "Air Quality Control",
    count: "1",
  },
  {
    id: "5233",
    job_category: "High School Teacher",
    skill: "Parent-Teacher Communication",
    count: "2",
  },
  {
    id: "5235",
    job_category: "High School Teacher",
    skill: "Student Motivation",
    count: "3",
  },
  {
    id: "5239",
    job_category: "Historian",
    skill: "Primary Source Analysis",
    count: "1",
  },
  {
    id: "5240",
    job_category: "Historian",
    skill: "Historiography",
    count: "1",
  },
  {
    id: "5241",
    job_category: "Historian",
    skill: "Digital History",
    count: "1",
  },
  {
    id: "5243",
    job_category: "Historian",
    skill: "Documentary Editing",
    count: "1",
  },
  {
    id: "5244",
    job_category: "Historian",
    skill: "Teaching History",
    count: "1",
  },
  {
    id: "5245",
    job_category: "Historian",
    skill: "Public History",
    count: "1",
  },
  {
    id: "5248",
    job_category: "Home Health Aide",
    skill: "Daily Living Assistance",
    count: "1",
  },
  {
    id: "5249",
    job_category: "Home Health Aide",
    skill: "Medication Management",
    count: "1",
  },
  {
    id: "5250",
    job_category: "Home Health Aide",
    skill: "Personal Hygiene",
    count: "1",
  },
  {
    id: "5252",
    job_category: "Home Health Aide",
    skill: "Client Relationship Building",
    count: "1",
  },
  {
    id: "5253",
    job_category: "Home Health Aide",
    skill: "Health Monitoring",
    count: "1",
  },
  {
    id: "5254",
    job_category: "Home Health Aide",
    skill: "Nutritional Support",
    count: "1",
  },
  {
    id: "5255",
    job_category: "Home Health Aide",
    skill: "Physical Therapy Assistance",
    count: "1",
  },
  {
    id: "5257",
    job_category: "Hotel Manager",
    skill: "Hospitality Management",
    count: "1",
  },
  {
    id: "5260",
    job_category: "Hotel Manager",
    skill: "Hotel Operations",
    count: "1",
  },
  {
    id: "5261",
    job_category: "Hotel Manager",
    skill: "Revenue Management",
    count: "1",
  },
  {
    id: "5265",
    job_category: "Hotel Manager",
    skill: "Budgeting and Financials",
    count: "2",
  },
  {
    id: "5266",
    job_category: "Hotel Manager",
    skill: "Property Maintenance",
    count: "1",
  },
  {
    id: "5275",
    job_category: "Human Resources Manager",
    skill: "HRIS Management",
    count: "1",
  },
  {
    id: "5278",
    job_category: "Human Resources Manager",
    skill: "Workforce Planning",
    count: "1",
  },
  {
    id: "5284",
    job_category: "Industrial Engineer",
    skill: "Production Planning",
    count: "3",
  },
  {
    id: "5285",
    job_category: "Industrial Engineer",
    skill: "Industrial Automation",
    count: "1",
  },
  {
    id: "5287",
    job_category: "Industrial Engineer",
    skill: "Cost Reduction Strategies",
    count: "1",
  },
  {
    id: "5288",
    job_category: "Industrial Engineer",
    skill: "Root Cause Analysis",
    count: "3",
  },
  {
    id: "5291",
    job_category: "Information Technology (IT) Manager",
    skill: "System Administration",
    count: "1",
  },
  {
    id: "5300",
    job_category: "Instructional Coordinator",
    skill: "Teacher Training",
    count: "1",
  },
  {
    id: "5301",
    job_category: "Instructional Coordinator",
    skill: "Education Policy",
    count: "2",
  },
  {
    id: "5305",
    job_category: "Instructional Coordinator",
    skill: "Technology Integration",
    count: "1",
  },
  {
    id: "5307",
    job_category: "Instructional Coordinator",
    skill: "Program Evaluation",
    count: "1",
  },
  {
    id: "5308",
    job_category: "Instructional Coordinator",
    skill: "Educational Leadership",
    count: "2",
  },
  {
    id: "5309",
    job_category: "Insurance Agent",
    skill: "Insurance Products",
    count: "1",
  },
  {
    id: "5313",
    job_category: "Insurance Agent",
    skill: "Policy Underwriting",
    count: "1",
  },
  {
    id: "5314",
    job_category: "Insurance Agent",
    skill: "Claims Management",
    count: "1",
  },
  {
    id: "5317",
    job_category: "Insurance Agent",
    skill: "Compliance and Regulations",
    count: "1",
  },
  {
    id: "5329",
    job_category: "Interpreter",
    skill: "Bilingual Proficiency",
    count: "1",
  },
  {
    id: "5330",
    job_category: "Interpreter",
    skill: "Consecutive Interpreting",
    count: "1",
  },
  {
    id: "5331",
    job_category: "Interpreter",
    skill: "Simultaneous Interpreting",
    count: "1",
  },
  {
    id: "5335",
    job_category: "Interpreter",
    skill: "Translation Skills",
    count: "1",
  },
  {
    id: "5336",
    job_category: "Interpreter",
    skill: "Remote Interpreting Technology",
    count: "1",
  },
  {
    id: "5338",
    job_category: "Interpreter",
    skill: "Interpretation Ethics",
    count: "1",
  },
  {
    id: "5341",
    job_category: "Investment Banker",
    skill: "Equity Research",
    count: "1",
  },
  {
    id: "5342",
    job_category: "Investment Banker",
    skill: "Debt Financing",
    count: "1",
  },
  {
    id: "5343",
    job_category: "Investment Banker",
    skill: "Valuation Analysis",
    count: "1",
  },
  {
    id: "5346",
    job_category: "Investment Banker",
    skill: "Capital Markets",
    count: "1",
  },
  {
    id: "5349",
    job_category: "Java Developer",
    skill: "Java Programming",
    count: "1",
  },
  {
    id: "5353",
    job_category: "Java Developer",
    skill: "Spring Framework",
    count: "2",
  },
  {
    id: "5355",
    job_category: "Java Developer",
    skill: "Git Version Control",
    count: "1",
  },
  {
    id: "5356",
    job_category: "Java Developer",
    skill: "Agile Methodology",
    count: "2",
  },
  {
    id: "5359",
    job_category: "Judge",
    skill: "Judicial Decision Making",
    count: "1",
  },
  {
    id: "5360",
    job_category: "Judge",
    skill: "Courtroom Procedure",
    count: "1",
  },
  {
    id: "5363",
    job_category: "Judge",
    skill: "Constitutional Law",
    count: "1",
  },
  { id: "5366", job_category: "Judge", skill: "Trial Advocacy", count: "1" },
  {
    id: "5372",
    job_category: "Laboratory Technician",
    skill: "Instrument Calibration",
    count: "1",
  },
  {
    id: "5374",
    job_category: "Laboratory Technician",
    skill: "Biosafety Procedures",
    count: "1",
  },
  {
    id: "5375",
    job_category: "Laboratory Technician",
    skill: "Research Documentation",
    count: "1",
  },
  {
    id: "5377",
    job_category: "Laboratory Technician",
    skill: "Lab Equipment Maintenance",
    count: "1",
  },
  {
    id: "5378",
    job_category: "Laboratory Technician",
    skill: "Compliance Standards",
    count: "1",
  },
  {
    id: "5379",
    job_category: "Land Surveyor",
    skill: "Land Surveying",
    count: "1",
  },
  { id: "5380", job_category: "Land Surveyor", skill: "Geomatics", count: "1" },
  {
    id: "5383",
    job_category: "Land Surveyor",
    skill: "Boundary Surveying",
    count: "1",
  },
  {
    id: "5384",
    job_category: "Land Surveyor",
    skill: "Construction Surveying",
    count: "1",
  },
  {
    id: "5386",
    job_category: "Land Surveyor",
    skill: "Legal Descriptions",
    count: "1",
  },
  {
    id: "5388",
    job_category: "Land Surveyor",
    skill: "Site Planning",
    count: "1",
  },
  {
    id: "5389",
    job_category: "Landscape Architect",
    skill: "Landscape Design",
    count: "1",
  },
  {
    id: "5391",
    job_category: "Landscape Architect",
    skill: "Planting Design",
    count: "1",
  },
  {
    id: "5392",
    job_category: "Landscape Architect",
    skill: "Urban Design",
    count: "1",
  },
  {
    id: "5393",
    job_category: "Landscape Architect",
    skill: "Construction Documents",
    count: "1",
  },
  {
    id: "5394",
    job_category: "Landscape Architect",
    skill: "Environmental Planning",
    count: "1",
  },
  {
    id: "5397",
    job_category: "Landscape Architect",
    skill: "Sustainability Practices",
    count: "1",
  },
  {
    id: "5400",
    job_category: "Magazine Editor",
    skill: "Publication Planning",
    count: "1",
  },
  {
    id: "5405",
    job_category: "Magazine Editor",
    skill: "Digital Publishing",
    count: "1",
  },
  {
    id: "5410",
    job_category: "Maintenance Worker",
    skill: "Equipment Repair",
    count: "1",
  },
  {
    id: "5427",
    job_category: "Management Analyst",
    skill: "Organizational Design",
    count: "1",
  },
  {
    id: "5435",
    job_category: "Manufacturing Engineer",
    skill: "Six Sigma",
    count: "1",
  },
  {
    id: "5439",
    job_category: "Marine Biologist",
    skill: "Marine Ecology",
    count: "1",
  },
  {
    id: "5440",
    job_category: "Marine Biologist",
    skill: "Oceanography",
    count: "1",
  },
  {
    id: "5441",
    job_category: "Marine Biologist",
    skill: "Aquatic Conservation",
    count: "1",
  },
  {
    id: "5443",
    job_category: "Marine Biologist",
    skill: "Marine Mammals",
    count: "1",
  },
  {
    id: "5444",
    job_category: "Marine Biologist",
    skill: "Fisheries Biology",
    count: "1",
  },
  {
    id: "5445",
    job_category: "Marine Biologist",
    skill: "Environmental Monitoring",
    count: "1",
  },
  {
    id: "5448",
    job_category: "Marine Biologist",
    skill: "Marine Policy",
    count: "1",
  },
  {
    id: "5451",
    job_category: "Marketing Analyst",
    skill: "Consumer Behavior",
    count: "1",
  },
  {
    id: "5455",
    job_category: "Marketing Analyst",
    skill: "Campaign Analytics",
    count: "1",
  },
  {
    id: "5456",
    job_category: "Marketing Analyst",
    skill: "CRM Systems",
    count: "1",
  },
  {
    id: "5457",
    job_category: "Marketing Analyst",
    skill: "Segmentation",
    count: "1",
  },
  {
    id: "5458",
    job_category: "Marketing Analyst",
    skill: "ROI Analysis",
    count: "1",
  },
  {
    id: "5469",
    job_category: "Massage Therapist",
    skill: "Massage Techniques",
    count: "1",
  },
  {
    id: "5473",
    job_category: "Massage Therapist",
    skill: "Pathology",
    count: "1",
  },
  {
    id: "5475",
    job_category: "Massage Therapist",
    skill: "Self-Care Techniques",
    count: "1",
  },
  {
    id: "5476",
    job_category: "Massage Therapist",
    skill: "Ethics and Regulations",
    count: "1",
  },
  {
    id: "5479",
    job_category: "Material Scientist",
    skill: "Materials Research",
    count: "1",
  },
  {
    id: "5480",
    job_category: "Material Scientist",
    skill: "Material Testing",
    count: "1",
  },
  {
    id: "5482",
    job_category: "Material Scientist",
    skill: "Polymers",
    count: "1",
  },
  {
    id: "5483",
    job_category: "Material Scientist",
    skill: "Metallurgy",
    count: "1",
  },
  {
    id: "5484",
    job_category: "Material Scientist",
    skill: "Composites",
    count: "1",
  },
  {
    id: "5485",
    job_category: "Material Scientist",
    skill: "Material Characterization",
    count: "1",
  },
  {
    id: "5486",
    job_category: "Material Scientist",
    skill: "Electronics Materials",
    count: "1",
  },
  {
    id: "5491",
    job_category: "Mathematician",
    skill: "Algorithm Development",
    count: "1",
  },
  {
    id: "5493",
    job_category: "Mathematician",
    skill: "Numerical Analysis",
    count: "1",
  },
  {
    id: "5494",
    job_category: "Mathematician",
    skill: "Computational Mathematics",
    count: "1",
  },
  {
    id: "5495",
    job_category: "Mathematician",
    skill: "Mathematical Logic",
    count: "1",
  },
  {
    id: "5496",
    job_category: "Mathematician",
    skill: "Number Theory",
    count: "1",
  },
  {
    id: "5497",
    job_category: "Mathematician",
    skill: "Operations Research",
    count: "1",
  },
  {
    id: "5499",
    job_category: "Mathematician",
    skill: "Game Theory",
    count: "1",
  },
  {
    id: "5502",
    job_category: "Medical Laboratory Technician",
    skill: "Clinical Chemistry",
    count: "1",
  },
  {
    id: "5503",
    job_category: "Medical Laboratory Technician",
    skill: "Hematology",
    count: "1",
  },
  {
    id: "5505",
    job_category: "Medical Laboratory Technician",
    skill: "Immunology",
    count: "2",
  },
  {
    id: "5506",
    job_category: "Medical Laboratory Technician",
    skill: "Serology",
    count: "1",
  },
  {
    id: "5510",
    job_category: "Medical Records Technician",
    skill: "Health Information Management",
    count: "1",
  },
  {
    id: "5513",
    job_category: "Medical Records Technician",
    skill: "Privacy Laws and Regulations",
    count: "1",
  },
  {
    id: "5516",
    job_category: "Medical Records Technician",
    skill: "Patient Information Systems",
    count: "1",
  },
  {
    id: "5518",
    job_category: "Medical Records Technician",
    skill: "Workflow Management",
    count: "1",
  },
  {
    id: "5521",
    job_category: "Medical Transcriptionist",
    skill: "Transcription Software",
    count: "1",
  },
  {
    id: "5524",
    job_category: "Medical Transcriptionist",
    skill: "Accuracy and Attention to Detail",
    count: "1",
  },
  {
    id: "5529",
    job_category: "Medical Transcriptionist",
    skill: "Data Security",
    count: "1",
  },
  {
    id: "5530",
    job_category: "Mental Health Counselor",
    skill: "Counseling Techniques",
    count: "1",
  },
  {
    id: "5532",
    job_category: "Mental Health Counselor",
    skill: "Assessment and Diagnosis",
    count: "1",
  },
  {
    id: "5539",
    job_category: "Mental Health Counselor",
    skill: "Ethics in Counseling",
    count: "1",
  },
  {
    id: "5541",
    job_category: "Microbiologist",
    skill: "Bacteriology",
    count: "1",
  },
  { id: "5542", job_category: "Microbiologist", skill: "Virology", count: "1" },
  { id: "5543", job_category: "Microbiologist", skill: "Mycology", count: "1" },
  {
    id: "5544",
    job_category: "Microbiologist",
    skill: "Parasitology",
    count: "1",
  },
  {
    id: "5548",
    job_category: "Microbiologist",
    skill: "Genetic Engineering",
    count: "1",
  },
  {
    id: "5549",
    job_category: "Microbiologist",
    skill: "Environmental Microbiology",
    count: "1",
  },
  {
    id: "5570",
    job_category: "Mortgage Loan Officer",
    skill: "Mortgage Lending",
    count: "1",
  },
  {
    id: "5573",
    job_category: "Mortgage Loan Officer",
    skill: "Loan Origination",
    count: "1",
  },
  {
    id: "5574",
    job_category: "Mortgage Loan Officer",
    skill: "Underwriting",
    count: "1",
  },
  {
    id: "5575",
    job_category: "Mortgage Loan Officer",
    skill: "Real Estate Finance",
    count: "1",
  },
  {
    id: "5580",
    job_category: "Museum Curator",
    skill: "Artifact Conservation",
    count: "1",
  },
  {
    id: "5581",
    job_category: "Museum Curator",
    skill: "Exhibit Design",
    count: "1",
  },
  {
    id: "5582",
    job_category: "Museum Curator",
    skill: "Collections Management",
    count: "1",
  },
  {
    id: "5585",
    job_category: "Museum Curator",
    skill: "Museum Administration",
    count: "1",
  },
  {
    id: "5586",
    job_category: "Museum Curator",
    skill: "Cultural Heritage Preservation",
    count: "1",
  },
  {
    id: "5587",
    job_category: "Museum Curator",
    skill: "Public Outreach",
    count: "1",
  },
  {
    id: "5588",
    job_category: "Museum Curator",
    skill: "Educational Programming",
    count: "1",
  },
  {
    id: "5590",
    job_category: "Music Director",
    skill: "Music Theory",
    count: "2",
  },
  {
    id: "5591",
    job_category: "Music Director",
    skill: "Instrumental Performance",
    count: "1",
  },
  {
    id: "5592",
    job_category: "Music Director",
    skill: "Choral Conducting",
    count: "1",
  },
  {
    id: "5593",
    job_category: "Music Director",
    skill: "Score Arrangement",
    count: "1",
  },
  {
    id: "5594",
    job_category: "Music Director",
    skill: "Rehearsal Techniques",
    count: "1",
  },
  {
    id: "5596",
    job_category: "Music Director",
    skill: "Recording Studio Management",
    count: "1",
  },
  {
    id: "5600",
    job_category: "Music Teacher",
    skill: "Music Education",
    count: "1",
  },
  {
    id: "5601",
    job_category: "Music Teacher",
    skill: "Instrumental Instruction",
    count: "1",
  },
  {
    id: "5602",
    job_category: "Music Teacher",
    skill: "Vocal Instruction",
    count: "1",
  },
  {
    id: "5605",
    job_category: "Music Teacher",
    skill: "Pedagogical Techniques",
    count: "1",
  },
  {
    id: "5607",
    job_category: "Music Teacher",
    skill: "Performance Coaching",
    count: "1",
  },
  {
    id: "5608",
    job_category: "Music Teacher",
    skill: "Ensemble Direction",
    count: "1",
  },
  {
    id: "5610",
    job_category: "Network Administrator",
    skill: "Network Infrastructure",
    count: "1",
  },
  {
    id: "5613",
    job_category: "Network Administrator",
    skill: "LAN/WAN Technologies",
    count: "1",
  },
  {
    id: "5614",
    job_category: "Network Administrator",
    skill: "Firewall Configuration",
    count: "2",
  },
  {
    id: "5616",
    job_category: "Network Administrator",
    skill: "DNS/DHCP Management",
    count: "1",
  },
  {
    id: "5618",
    job_category: "Network Administrator",
    skill: "Backup and Recovery",
    count: "2",
  },
  {
    id: "5619",
    job_category: "Network Administrator",
    skill: "IT Documentation",
    count: "1",
  },
  {
    id: "5620",
    job_category: "Network Architect",
    skill: "Network Design",
    count: "1",
  },
  {
    id: "5621",
    job_category: "Network Architect",
    skill: "LAN/WAN Architecture",
    count: "1",
  },
  {
    id: "5625",
    job_category: "Network Architect",
    skill: "IP Addressing and Subnetting",
    count: "1",
  },
  {
    id: "5626",
    job_category: "Network Architect",
    skill: "Routing and Switching",
    count: "1",
  },
  {
    id: "5628",
    job_category: "Network Architect",
    skill: "Load Balancing",
    count: "1",
  },
  {
    id: "5629",
    job_category: "Network Architect",
    skill: "Network Monitoring",
    count: "1",
  },
  {
    id: "5630",
    job_category: "Nuclear Engineer",
    skill: "Nuclear Physics",
    count: "1",
  },
  {
    id: "5631",
    job_category: "Nuclear Engineer",
    skill: "Radiation Protection",
    count: "1",
  },
  {
    id: "5632",
    job_category: "Nuclear Engineer",
    skill: "Nuclear Reactor Design",
    count: "1",
  },
  {
    id: "5633",
    job_category: "Nuclear Engineer",
    skill: "Nuclear Fuel Cycle",
    count: "1",
  },
  {
    id: "5634",
    job_category: "Nuclear Engineer",
    skill: "Radiation Measurement",
    count: "1",
  },
  {
    id: "5635",
    job_category: "Nuclear Engineer",
    skill: "Health Physics",
    count: "1",
  },
  {
    id: "5636",
    job_category: "Nuclear Engineer",
    skill: "Safety Analysis",
    count: "1",
  },
  {
    id: "5642",
    job_category: "Nurse Practitioner",
    skill: "Diagnosis and Treatment",
    count: "2",
  },
  {
    id: "5644",
    job_category: "Nurse Practitioner",
    skill: "Chronic Disease Management",
    count: "1",
  },
  {
    id: "5645",
    job_category: "Nurse Practitioner",
    skill: "Prescriptive Authority",
    count: "2",
  },
  {
    id: "5646",
    job_category: "Nurse Practitioner",
    skill: "Collaborative Practice",
    count: "2",
  },
  {
    id: "5649",
    job_category: "Nurse Practitioner",
    skill: "Evidence-Based Practice",
    count: "2",
  },
  {
    id: "5650",
    job_category: "Nutritionist",
    skill: "Nutritional Counseling",
    count: "1",
  },
  {
    id: "5651",
    job_category: "Nutritionist",
    skill: "Dietary Analysis",
    count: "1",
  },
  {
    id: "5656",
    job_category: "Nutritionist",
    skill: "Sports Nutrition",
    count: "1",
  },
  {
    id: "5657",
    job_category: "Nutritionist",
    skill: "Food Allergies and Intolerances",
    count: "1",
  },
  {
    id: "5660",
    job_category: "Occupational Therapist",
    skill: "Occupational Therapy",
    count: "1",
  },
  {
    id: "5661",
    job_category: "Occupational Therapist",
    skill: "Patient Evaluation",
    count: "2",
  },
  {
    id: "5662",
    job_category: "Occupational Therapist",
    skill: "Therapeutic Interventions",
    count: "1",
  },
  {
    id: "5663",
    job_category: "Occupational Therapist",
    skill: "Rehabilitation Programs",
    count: "1",
  },
  {
    id: "5664",
    job_category: "Occupational Therapist",
    skill: "Assistive Devices",
    count: "1",
  },
  {
    id: "5665",
    job_category: "Occupational Therapist",
    skill: "Activities of Daily Living (ADL)",
    count: "1",
  },
  {
    id: "5668",
    job_category: "Occupational Therapist",
    skill: "Workplace Injury Prevention",
    count: "1",
  },
  {
    id: "5669",
    job_category: "Occupational Therapist",
    skill: "Sensory Integration",
    count: "1",
  },
  {
    id: "5671",
    job_category: "Office Clerk",
    skill: "File Management",
    count: "1",
  },
  {
    id: "5677",
    job_category: "Office Clerk",
    skill: "Written Communication",
    count: "1",
  },
  {
    id: "5680",
    job_category: "Office Manager",
    skill: "Office Administration",
    count: "1",
  },
  {
    id: "5681",
    job_category: "Office Manager",
    skill: "Staff Supervision",
    count: "1",
  },
  {
    id: "5682",
    job_category: "Office Manager",
    skill: "Workflow Coordination",
    count: "1",
  },
  {
    id: "5684",
    job_category: "Office Manager",
    skill: "Purchasing",
    count: "1",
  },
  { id: "5690", job_category: "Optometrist", skill: "Optometry", count: "1" },
  {
    id: "5691",
    job_category: "Optometrist",
    skill: "Eye Examination",
    count: "1",
  },
  {
    id: "5692",
    job_category: "Optometrist",
    skill: "Prescription Eyewear",
    count: "1",
  },
  {
    id: "5693",
    job_category: "Optometrist",
    skill: "Contact Lenses",
    count: "1",
  },
  {
    id: "5694",
    job_category: "Optometrist",
    skill: "Vision Therapy",
    count: "1",
  },
  {
    id: "5695",
    job_category: "Optometrist",
    skill: "Ocular Disease Management",
    count: "1",
  },
  {
    id: "5696",
    job_category: "Optometrist",
    skill: "Low Vision Rehabilitation",
    count: "1",
  },
  {
    id: "5701",
    job_category: "Orthodontist",
    skill: "Dental Braces",
    count: "1",
  },
  {
    id: "5702",
    job_category: "Orthodontist",
    skill: "Orthognathic Surgery",
    count: "1",
  },
  {
    id: "5703",
    job_category: "Orthodontist",
    skill: "Craniofacial Anomalies",
    count: "1",
  },
  {
    id: "5704",
    job_category: "Orthodontist",
    skill: "Malocclusion Treatment",
    count: "1",
  },
  {
    id: "5705",
    job_category: "Orthodontist",
    skill: "Dental Impression Techniques",
    count: "1",
  },
  {
    id: "5711",
    job_category: "Paramedic",
    skill: "Advanced Life Support",
    count: "1",
  },
  {
    id: "5713",
    job_category: "Paramedic",
    skill: "Medical Emergencies",
    count: "1",
  },
  {
    id: "5714",
    job_category: "Paramedic",
    skill: "Prehospital Care",
    count: "1",
  },
  {
    id: "5718",
    job_category: "Paramedic",
    skill: "Patient Transport",
    count: "1",
  },
  {
    id: "5719",
    job_category: "Park Ranger",
    skill: "Environmental Conservation",
    count: "1",
  },
  {
    id: "5721",
    job_category: "Park Ranger",
    skill: "Wilderness Survival",
    count: "1",
  },
  {
    id: "5722",
    job_category: "Park Ranger",
    skill: "Interpretive Programming",
    count: "1",
  },
  {
    id: "5725",
    job_category: "Park Ranger",
    skill: "Trail Maintenance",
    count: "1",
  },
  {
    id: "5726",
    job_category: "Park Ranger",
    skill: "Wildlife Management",
    count: "1",
  },
  {
    id: "5727",
    job_category: "Park Ranger",
    skill: "Visitor Services",
    count: "1",
  },
  {
    id: "5728",
    job_category: "Park Ranger",
    skill: "Park Regulations",
    count: "1",
  },
  {
    id: "5734",
    job_category: "Personal Trainer",
    skill: "Cardiovascular Exercise",
    count: "1",
  },
  {
    id: "5737",
    job_category: "Personal Trainer",
    skill: "Motivational Coaching",
    count: "1",
  },
  {
    id: "5742",
    job_category: "Pharmacy Technician",
    skill: "Medication Dispensing",
    count: "1",
  },
  {
    id: "5743",
    job_category: "Pharmacy Technician",
    skill: "Pharmacy Law and Ethics",
    count: "1",
  },
  {
    id: "5745",
    job_category: "Pharmacy Technician",
    skill: "Healthcare Software",
    count: "1",
  },
  {
    id: "5746",
    job_category: "Pharmacy Technician",
    skill: "Medication Safety",
    count: "1",
  },
  {
    id: "5748",
    job_category: "Pharmacy Technician",
    skill: "Billing and Insurance",
    count: "1",
  },
  {
    id: "5754",
    job_category: "Physician",
    skill: "Healthcare Leadership",
    count: "1",
  },
  {
    id: "5756",
    job_category: "Physician",
    skill: "Evidence-Based Medicine",
    count: "1",
  },
  {
    id: "5759",
    job_category: "Physician Assistant",
    skill: "Primary Care",
    count: "1",
  },
  {
    id: "5765",
    job_category: "Physician Assistant",
    skill: "Health Counseling",
    count: "1",
  },
  {
    id: "5770",
    job_category: "Physicist",
    skill: "Quantum Mechanics",
    count: "1",
  },
  {
    id: "5771",
    job_category: "Physicist",
    skill: "Theoretical Physics",
    count: "1",
  },
  {
    id: "5772",
    job_category: "Physicist",
    skill: "Experimental Physics",
    count: "1",
  },
  {
    id: "5775",
    job_category: "Physicist",
    skill: "Scientific Research",
    count: "1",
  },
  {
    id: "5777",
    job_category: "Physicist",
    skill: "Computational Physics",
    count: "1",
  },
  { id: "5780", job_category: "Plumber", skill: "Pipefitting", count: "1" },
  {
    id: "5781",
    job_category: "Plumber",
    skill: "Drainage Systems",
    count: "1",
  },
  {
    id: "5782",
    job_category: "Plumber",
    skill: "Water Supply Systems",
    count: "1",
  },
  { id: "5783", job_category: "Plumber", skill: "Gas Systems", count: "1" },
  { id: "5784", job_category: "Plumber", skill: "Pipe Welding", count: "1" },
  { id: "5785", job_category: "Plumber", skill: "Septic Systems", count: "1" },
  { id: "5786", job_category: "Plumber", skill: "Plumbing Codes", count: "1" },
  {
    id: "5787",
    job_category: "Plumber",
    skill: "Safety Practices",
    count: "1",
  },
  {
    id: "5789",
    job_category: "Political Scientist",
    skill: "Political Theory",
    count: "1",
  },
  {
    id: "5790",
    job_category: "Political Scientist",
    skill: "Comparative Politics",
    count: "1",
  },
  {
    id: "5791",
    job_category: "Political Scientist",
    skill: "International Relations",
    count: "1",
  },
  {
    id: "5793",
    job_category: "Political Scientist",
    skill: "Governmental Systems",
    count: "1",
  },
  {
    id: "5794",
    job_category: "Political Scientist",
    skill: "Public Administration",
    count: "1",
  },
  {
    id: "5795",
    job_category: "Political Scientist",
    skill: "Research Methodology",
    count: "1",
  },
  {
    id: "5796",
    job_category: "Political Scientist",
    skill: "Political Sociology",
    count: "1",
  },
  {
    id: "5797",
    job_category: "Political Scientist",
    skill: "Public Policy Evaluation",
    count: "1",
  },
  {
    id: "5800",
    job_category: "Principal",
    skill: "School Administration",
    count: "1",
  },
  {
    id: "5802",
    job_category: "Principal",
    skill: "Teacher Supervision",
    count: "1",
  },
  {
    id: "5803",
    job_category: "Principal",
    skill: "Student Discipline",
    count: "1",
  },
  {
    id: "5807",
    job_category: "Principal",
    skill: "School Improvement",
    count: "1",
  },
  {
    id: "5811",
    job_category: "Private Investigator",
    skill: "Background Checks",
    count: "1",
  },
  {
    id: "5817",
    job_category: "Private Investigator",
    skill: "Courtroom Testimony",
    count: "1",
  },
  {
    id: "5823",
    job_category: "Production Manager",
    skill: "Workflow Optimization",
    count: "1",
  },
  {
    id: "5830",
    job_category: "Property Manager",
    skill: "Tenant Relations",
    count: "1",
  },
  {
    id: "5831",
    job_category: "Property Manager",
    skill: "Lease Agreements",
    count: "1",
  },
  {
    id: "5832",
    job_category: "Property Manager",
    skill: "Maintenance Coordination",
    count: "1",
  },
  {
    id: "5833",
    job_category: "Property Manager",
    skill: "Budgeting and Finance",
    count: "1",
  },
  { id: "5839", job_category: "Psychiatrist", skill: "Psychiatry", count: "1" },
  {
    id: "5840",
    job_category: "Psychiatrist",
    skill: "Psychopharmacology",
    count: "1",
  },
  {
    id: "5843",
    job_category: "Psychiatrist",
    skill: "Mental Health Assessment",
    count: "1",
  },
  {
    id: "5852",
    job_category: "Psychologist",
    skill: "Therapeutic Techniques",
    count: "1",
  },
  {
    id: "5853",
    job_category: "Psychologist",
    skill: "Behavioral Assessment",
    count: "1",
  },
  {
    id: "5854",
    job_category: "Psychologist",
    skill: "Research Methods",
    count: "1",
  },
  {
    id: "5855",
    job_category: "Psychologist",
    skill: "Psychological Testing",
    count: "1",
  },
  {
    id: "5857",
    job_category: "Psychologist",
    skill: "Intervention Strategies",
    count: "1",
  },
  {
    id: "5858",
    job_category: "Psychologist",
    skill: "Ethics in Psychology",
    count: "1",
  },
  {
    id: "5861",
    job_category: "Public Relations Specialist",
    skill: "Crisis Communication",
    count: "2",
  },
  {
    id: "5865",
    job_category: "Public Relations Specialist",
    skill: "Strategic Communications",
    count: "1",
  },
  {
    id: "5877",
    job_category: "Publicist",
    skill: "Relationship Management",
    count: "1",
  },
  {
    id: "5884",
    job_category: "Quality Assurance Manager",
    skill: "Document Control",
    count: "1",
  },
  {
    id: "5886",
    job_category: "Quality Assurance Manager",
    skill: "Supplier Quality Management",
    count: "1",
  },
  {
    id: "5889",
    job_category: "Radiologic Technologist",
    skill: "Radiologic Technology",
    count: "1",
  },
  {
    id: "5892",
    job_category: "Radiologic Technologist",
    skill: "Radiation Safety",
    count: "1",
  },
  {
    id: "5893",
    job_category: "Radiologic Technologist",
    skill: "Image Analysis",
    count: "1",
  },
  {
    id: "5897",
    job_category: "Radiologic Technologist",
    skill: "Healthcare Ethics",
    count: "1",
  },
  {
    id: "5899",
    job_category: "Real Estate Appraiser",
    skill: "Real Estate Appraisal",
    count: "1",
  },
  {
    id: "5900",
    job_category: "Real Estate Appraiser",
    skill: "Property Valuation",
    count: "1",
  },
  {
    id: "5903",
    job_category: "Real Estate Appraiser",
    skill: "Appraisal Software",
    count: "1",
  },
  {
    id: "5904",
    job_category: "Real Estate Appraiser",
    skill: "Property Inspection",
    count: "1",
  },
  {
    id: "5908",
    job_category: "Real Estate Appraiser",
    skill: "Ethics and Standards",
    count: "1",
  },
  {
    id: "5909",
    job_category: "Recruiter",
    skill: "Talent Acquisition",
    count: "1",
  },
  {
    id: "5910",
    job_category: "Recruiter",
    skill: "Candidate Sourcing",
    count: "1",
  },
  {
    id: "5916",
    job_category: "Recruiter",
    skill: "Job Advertising",
    count: "1",
  },
  {
    id: "5917",
    job_category: "Recruiter",
    skill: "Candidate Assessment",
    count: "1",
  },
  {
    id: "5925",
    job_category: "Software Engineer",
    skill: "Integration Testing",
    count: "2",
  },
  {
    id: "5926",
    job_category: "Software Engineer",
    skill: "Continuous Integration / Continuous Deployment (CI/CD)",
    count: "1",
  },
  {
    id: "5930",
    job_category: "Software Engineer",
    skill: "Hibernate",
    count: "1",
  },
  { id: "5931", job_category: "Software Engineer", skill: "Maven", count: "1" },
  { id: "5932", job_category: "Software Engineer", skill: "JUnit", count: "1" },
  {
    id: "5933",
    job_category: "Software Engineer",
    skill: "Jenkins",
    count: "1",
  },
  {
    id: "5934",
    job_category: "Software Engineer",
    skill: "Apache Kafka",
    count: "1",
  },
  {
    id: "5935",
    job_category: "Software Engineer",
    skill: "Thymeleaf",
    count: "1",
  },
  {
    id: "5936",
    job_category: "Software Engineer",
    skill: "JSP (JavaServer Pages)",
    count: "1",
  },
  {
    id: "5937",
    job_category: "Software Engineer",
    skill: "Servlets",
    count: "1",
  },
  {
    id: "5939",
    job_category: "Software Engineer",
    skill: "Django",
    count: "1",
  },
  { id: "5940", job_category: "Software Engineer", skill: "Flask", count: "1" },
  {
    id: "5941",
    job_category: "Software Engineer",
    skill: "Pyramid",
    count: "1",
  },
  {
    id: "5942",
    job_category: "Software Engineer",
    skill: "SQLAlchemy",
    count: "1",
  },
  {
    id: "5943",
    job_category: "Software Engineer",
    skill: "Celery",
    count: "1",
  },
  {
    id: "5944",
    job_category: "Software Engineer",
    skill: "Pandas",
    count: "1",
  },
  { id: "5945", job_category: "Software Engineer", skill: "NumPy", count: "1" },
  {
    id: "5946",
    job_category: "Software Engineer",
    skill: "Matplotlib",
    count: "1",
  },
  {
    id: "5949",
    job_category: "Software Engineer",
    skill: "React.js",
    count: "1",
  },
  {
    id: "5953",
    job_category: "Software Engineer",
    skill: "Express.js",
    count: "1",
  },
  {
    id: "5954",
    job_category: "Software Engineer",
    skill: "jQuery",
    count: "1",
  },
  {
    id: "5955",
    job_category: "Software Engineer",
    skill: "TypeScript",
    count: "1",
  },
  { id: "5956", job_category: "Software Engineer", skill: "D3.js", count: "1" },
  {
    id: "5959",
    job_category: "Software Engineer",
    skill: "Ruby on Rails",
    count: "1",
  },
  { id: "5960", job_category: "Software Engineer", skill: "RSpec", count: "1" },
  {
    id: "5961",
    job_category: "Software Engineer",
    skill: "Sidekiq",
    count: "1",
  },
  { id: "5962", job_category: "Software Engineer", skill: "Gems", count: "1" },
  {
    id: "5963",
    job_category: "Software Engineer",
    skill: "Sinatra",
    count: "1",
  },
  {
    id: "5964",
    job_category: "Software Engineer",
    skill: "ActiveRecord",
    count: "1",
  },
  {
    id: "5965",
    job_category: "Software Engineer",
    skill: "Capybara",
    count: "1",
  },
  { id: "5966", job_category: "Software Engineer", skill: "Puma", count: "1" },
  { id: "5967", job_category: "Software Engineer", skill: "Sass", count: "1" },
  {
    id: "5969",
    job_category: "Software Engineer",
    skill: "Laravel",
    count: "1",
  },
  {
    id: "5970",
    job_category: "Software Engineer",
    skill: "Symfony",
    count: "1",
  },
  {
    id: "5971",
    job_category: "Software Engineer",
    skill: "CodeIgniter",
    count: "1",
  },
  {
    id: "5972",
    job_category: "Software Engineer",
    skill: "PHPUnit",
    count: "1",
  },
  {
    id: "5973",
    job_category: "Software Engineer",
    skill: "Composer",
    count: "1",
  },
  { id: "5974", job_category: "Software Engineer", skill: "Twig", count: "1" },
  {
    id: "5975",
    job_category: "Software Engineer",
    skill: "Doctrine",
    count: "1",
  },
  {
    id: "5976",
    job_category: "Software Engineer",
    skill: "Zend Framework",
    count: "1",
  },
  {
    id: "5977",
    job_category: "Software Engineer",
    skill: "CakePHP",
    count: "1",
  },
  { id: "5978", job_category: "Software Engineer", skill: "C#", count: "1" },
  {
    id: "5979",
    job_category: "Software Engineer",
    skill: ".NET Framework",
    count: "1",
  },
  {
    id: "5980",
    job_category: "Software Engineer",
    skill: "ASP.NET",
    count: "1",
  },
  {
    id: "5981",
    job_category: "Software Engineer",
    skill: "Entity Framework",
    count: "1",
  },
  { id: "5982", job_category: "Software Engineer", skill: "LINQ", count: "1" },
  { id: "5983", job_category: "Software Engineer", skill: "WPF", count: "1" },
  {
    id: "5984",
    job_category: "Software Engineer",
    skill: "WinForms",
    count: "1",
  },
  {
    id: "5985",
    job_category: "Software Engineer",
    skill: "ASP.NET Core",
    count: "1",
  },
  {
    id: "5988",
    job_category: "Software Engineer",
    skill: "STL (Standard Template Library)",
    count: "1",
  },
  { id: "5989", job_category: "Software Engineer", skill: "Boost", count: "1" },
  { id: "5990", job_category: "Software Engineer", skill: "Qt", count: "1" },
  {
    id: "5991",
    job_category: "Software Engineer",
    skill: "MFC (Microsoft Foundation Classes)",
    count: "1",
  },
  { id: "5992", job_category: "Software Engineer", skill: "CUDA", count: "1" },
  {
    id: "5993",
    job_category: "Software Engineer",
    skill: "OpenCV",
    count: "1",
  },
  {
    id: "5994",
    job_category: "Software Engineer",
    skill: "DirectX",
    count: "1",
  },
  {
    id: "5995",
    job_category: "Software Engineer",
    skill: "OpenGL",
    count: "1",
  },
  {
    id: "5996",
    job_category: "Software Engineer",
    skill: "Compiler Design",
    count: "1",
  },
  { id: "5997", job_category: "Software Engineer", skill: "Go", count: "1" },
  { id: "5998", job_category: "Software Engineer", skill: "Gin", count: "1" },
  { id: "5999", job_category: "Software Engineer", skill: "Echo", count: "1" },
  { id: "6000", job_category: "Software Engineer", skill: "Beego", count: "1" },
  { id: "6001", job_category: "Software Engineer", skill: "GORM", count: "1" },
  {
    id: "6002",
    job_category: "Software Engineer",
    skill: "Buffalo",
    count: "1",
  },
  {
    id: "6003",
    job_category: "Software Engineer",
    skill: "Go Modules",
    count: "1",
  },
  {
    id: "6004",
    job_category: "Software Engineer",
    skill: "Concurrency in Go",
    count: "1",
  },
  {
    id: "6005",
    job_category: "Software Engineer",
    skill: "Error Handling in Go",
    count: "1",
  },
  {
    id: "6006",
    job_category: "Software Engineer",
    skill: "RESTful APIs in Go",
    count: "1",
  },
  { id: "6007", job_category: "Software Engineer", skill: "Swift", count: "1" },
  {
    id: "6009",
    job_category: "Software Engineer",
    skill: "SwiftUI",
    count: "1",
  },
  {
    id: "6010",
    job_category: "Software Engineer",
    skill: "Core Data",
    count: "1",
  },
  { id: "6011", job_category: "Software Engineer", skill: "UIKit", count: "1" },
  {
    id: "6012",
    job_category: "Software Engineer",
    skill: "Combine Framework",
    count: "1",
  },
  {
    id: "6013",
    job_category: "Software Engineer",
    skill: "Swift Package Manager",
    count: "1",
  },
  {
    id: "6014",
    job_category: "Software Engineer",
    skill: "Metal (API)",
    count: "1",
  },
  {
    id: "6015",
    job_category: "Software Engineer",
    skill: "SpriteKit",
    count: "1",
  },
  { id: "6016", job_category: "Software Engineer", skill: "ARKit", count: "1" },
  {
    id: "6017",
    job_category: "Software Engineer",
    skill: "Kotlin",
    count: "1",
  },
  {
    id: "6019",
    job_category: "Software Engineer",
    skill: "Coroutines",
    count: "1",
  },
  {
    id: "6020",
    job_category: "Software Engineer",
    skill: "Android Studio",
    count: "1",
  },
  {
    id: "6021",
    job_category: "Software Engineer",
    skill: "MVVM Architecture",
    count: "1",
  },
  {
    id: "6022",
    job_category: "Software Engineer",
    skill: "Room Persistence Library",
    count: "1",
  },
  {
    id: "6023",
    job_category: "Software Engineer",
    skill: "Retrofit",
    count: "1",
  },
  { id: "6024", job_category: "Software Engineer", skill: "Ktor", count: "1" },
  {
    id: "6025",
    job_category: "Software Engineer",
    skill: "Jetpack Compose",
    count: "1",
  },
  {
    id: "6026",
    job_category: "Software Engineer",
    skill: "Firebase",
    count: "1",
  },
  { id: "6027", job_category: "Software Engineer", skill: "Rust", count: "1" },
  { id: "6028", job_category: "Software Engineer", skill: "Cargo", count: "1" },
  { id: "6029", job_category: "Software Engineer", skill: "Actix", count: "1" },
  {
    id: "6030",
    job_category: "Software Engineer",
    skill: "Rocket",
    count: "1",
  },
  { id: "6031", job_category: "Software Engineer", skill: "Tokio", count: "1" },
  { id: "6032", job_category: "Software Engineer", skill: "Hyper", count: "1" },
  {
    id: "6033",
    job_category: "Software Engineer",
    skill: "WebAssembly (Wasm)",
    count: "1",
  },
  {
    id: "6034",
    job_category: "Software Engineer",
    skill: "Error Handling in Rust",
    count: "1",
  },
  {
    id: "6035",
    job_category: "Software Engineer",
    skill: "Concurrency in Rust",
    count: "1",
  },
  {
    id: "6036",
    job_category: "Software Engineer",
    skill: "Memory Management in Rust",
    count: "1",
  },
  { id: "6037", job_category: "Software Engineer", skill: "Perl", count: "1" },
  {
    id: "6038",
    job_category: "Software Engineer",
    skill: "CGI Programming",
    count: "1",
  },
  {
    id: "6039",
    job_category: "Software Engineer",
    skill: "Mojolicious",
    count: "1",
  },
  {
    id: "6040",
    job_category: "Software Engineer",
    skill: "Dancer",
    count: "1",
  },
  {
    id: "6041",
    job_category: "Software Engineer",
    skill: "Perl DBI",
    count: "1",
  },
  {
    id: "6042",
    job_category: "Software Engineer",
    skill: "Catalyst",
    count: "1",
  },
  {
    id: "6043",
    job_category: "Software Engineer",
    skill: "Template Toolkit",
    count: "1",
  },
  { id: "6044", job_category: "Software Engineer", skill: "Plack", count: "1" },
  { id: "6045", job_category: "Software Engineer", skill: "Moose", count: "1" },
  {
    id: "6046",
    job_category: "Software Engineer",
    skill: "Perl Testing (e.g. Test::More)",
    count: "1",
  },
  {
    id: "6048",
    job_category: "Software Engineer",
    skill: "Bash Scripting",
    count: "1",
  },
  {
    id: "6049",
    job_category: "Software Engineer",
    skill: "Linux Command Line",
    count: "1",
  },
  {
    id: "6050",
    job_category: "Software Engineer",
    skill: "SED and AWK",
    count: "1",
  },
  {
    id: "6051",
    job_category: "Software Engineer",
    skill: "Shell Variables",
    count: "1",
  },
  {
    id: "6052",
    job_category: "Software Engineer",
    skill: "Shell Scripting for Automation",
    count: "1",
  },
  {
    id: "6053",
    job_category: "Software Engineer",
    skill: "Regular Expressions in Shell",
    count: "1",
  },
  {
    id: "6054",
    job_category: "Software Engineer",
    skill: "Error Handling in Shell Scripts",
    count: "1",
  },
  {
    id: "6055",
    job_category: "Software Engineer",
    skill: "System Administration with Shell",
    count: "1",
  },
  {
    id: "6056",
    job_category: "Software Engineer",
    skill: "Shell Scripting Best Practices",
    count: "1",
  },
  {
    id: "6057",
    job_category: "Software Engineer",
    skill: "Assembly Language",
    count: "1",
  },
  {
    id: "6058",
    job_category: "Software Engineer",
    skill: "x86 Assembly",
    count: "1",
  },
  {
    id: "6059",
    job_category: "Software Engineer",
    skill: "ARM Assembly",
    count: "1",
  },
  {
    id: "6060",
    job_category: "Software Engineer",
    skill: "MIPS Assembly",
    count: "1",
  },
  {
    id: "6061",
    job_category: "Software Engineer",
    skill: "Debugging Assembly Code",
    count: "1",
  },
  {
    id: "6062",
    job_category: "Software Engineer",
    skill: "Optimizing Assembly Code",
    count: "1",
  },
  {
    id: "6063",
    job_category: "Software Engineer",
    skill: "Memory Management in Assembly",
    count: "1",
  },
  {
    id: "6064",
    job_category: "Software Engineer",
    skill: "Low-Level Programming",
    count: "1",
  },
  {
    id: "6065",
    job_category: "Software Engineer",
    skill: "Embedded Systems Development",
    count: "1",
  },
  {
    id: "6066",
    job_category: "Software Engineer",
    skill: "Real-Time Systems",
    count: "1",
  },
  {
    id: "6067",
    job_category: "Software Engineer",
    skill: "Version Control Systems",
    count: "1",
  },
  {
    id: "6069",
    job_category: "Software Engineer",
    skill: "GitHub",
    count: "1",
  },
  {
    id: "6070",
    job_category: "Software Engineer",
    skill: "GitLab",
    count: "1",
  },
  {
    id: "6071",
    job_category: "Software Engineer",
    skill: "Bitbucket",
    count: "1",
  },
  {
    id: "6072",
    job_category: "Software Engineer",
    skill: "SVN (Subversion)",
    count: "1",
  },
  {
    id: "6073",
    job_category: "Software Engineer",
    skill: "Mercurial",
    count: "1",
  },
  {
    id: "6074",
    job_category: "Software Engineer",
    skill: "Version Control Best Practices",
    count: "1",
  },
  {
    id: "6075",
    job_category: "Software Engineer",
    skill: "Branching and Merging Strategies",
    count: "1",
  },
  {
    id: "6076",
    job_category: "Software Engineer",
    skill: "Collaborative Development",
    count: "1",
  },
  {
    id: "6077",
    job_category: "Software Engineer",
    skill: "Containerization",
    count: "2",
  },
  {
    id: "6078",
    job_category: "Software Engineer",
    skill: "Docker",
    count: "1",
  },
  {
    id: "6079",
    job_category: "Software Engineer",
    skill: "Kubernetes",
    count: "1",
  },
  {
    id: "6080",
    job_category: "Software Engineer",
    skill: "Container Orchestration",
    count: "1",
  },
  {
    id: "6082",
    job_category: "Software Engineer",
    skill: "Docker Compose",
    count: "1",
  },
  {
    id: "6083",
    job_category: "Software Engineer",
    skill: "Container Security",
    count: "1",
  },
  {
    id: "6084",
    job_category: "Software Engineer",
    skill: "Container Networking",
    count: "1",
  },
  {
    id: "6085",
    job_category: "Software Engineer",
    skill: "Container Management Tools",
    count: "1",
  },
  {
    id: "6086",
    job_category: "Software Engineer",
    skill: "Containerization Patterns",
    count: "1",
  },
  {
    id: "6088",
    job_category: "Software Engineer",
    skill: "AWS (Amazon Web Services)",
    count: "1",
  },
  {
    id: "6089",
    job_category: "Software Engineer",
    skill: "Microsoft Azure",
    count: "1",
  },
  {
    id: "6090",
    job_category: "Software Engineer",
    skill: "Google Cloud Platform",
    count: "1",
  },
  {
    id: "6091",
    job_category: "Software Engineer",
    skill: "Serverless Architecture",
    count: "1",
  },
  {
    id: "6092",
    job_category: "Software Engineer",
    skill: "Infrastructure as Code (IaC)",
    count: "2",
  },
  {
    id: "6093",
    job_category: "Software Engineer",
    skill: "Cloud Storage Solutions",
    count: "1",
  },
  {
    id: "6094",
    job_category: "Software Engineer",
    skill: "Cloud Security Best Practices",
    count: "1",
  },
  {
    id: "6095",
    job_category: "Software Engineer",
    skill: "Cloud DevOps",
    count: "1",
  },
  {
    id: "6096",
    job_category: "Software Engineer",
    skill: "Cloud Networking",
    count: "1",
  },
  {
    id: "6097",
    job_category: "Software Engineer",
    skill: "Database Systems",
    count: "1",
  },
  {
    id: "6098",
    job_category: "Software Engineer",
    skill: "SQL (Structured Query Language)",
    count: "1",
  },
  {
    id: "6099",
    job_category: "Software Engineer",
    skill: "Relational Database Management Systems (RDBMS)",
    count: "1",
  },
  {
    id: "6100",
    job_category: "Software Engineer",
    skill: "NoSQL Databases",
    count: "1",
  },
  {
    id: "6105",
    job_category: "Software Engineer",
    skill: "Database Scaling Strategies",
    count: "1",
  },
  {
    id: "6108",
    job_category: "Software Engineer",
    skill: "HTML (HyperText Markup Language)",
    count: "1",
  },
  {
    id: "6109",
    job_category: "Software Engineer",
    skill: "CSS (Cascading Style Sheets)",
    count: "1",
  },
  {
    id: "6110",
    job_category: "Software Engineer",
    skill: "JavaScript (JS)",
    count: "1",
  },
  {
    id: "6112",
    job_category: "Software Engineer",
    skill: "Frontend Frameworks (e.g. React Angular Vue)",
    count: "1",
  },
  {
    id: "6113",
    job_category: "Software Engineer",
    skill: "Backend Frameworks (e.g. Node.js Django Flask)",
    count: "1",
  },
  {
    id: "6116",
    job_category: "Software Engineer",
    skill: "Web Performance Optimization",
    count: "1",
  },
  {
    id: "6117",
    job_category: "Software Engineer",
    skill: "Web Security",
    count: "1",
  },
  {
    id: "6122",
    job_category: "Software Engineer",
    skill: "Native App Development",
    count: "1",
  },
  {
    id: "6123",
    job_category: "Software Engineer",
    skill: "Mobile UI/UX Design",
    count: "1",
  },
  {
    id: "6124",
    job_category: "Software Engineer",
    skill: "Mobile App Testing",
    count: "1",
  },
  {
    id: "6125",
    job_category: "Software Engineer",
    skill: "Mobile Analytics",
    count: "1",
  },
  {
    id: "6126",
    job_category: "Software Engineer",
    skill: "Mobile Security",
    count: "1",
  },
  {
    id: "6127",
    job_category: "Software Engineer",
    skill: "Mobile Performance Optimization",
    count: "1",
  },
  {
    id: "6131",
    job_category: "Software Engineer",
    skill: "System Testing",
    count: "1",
  },
  {
    id: "6132",
    job_category: "Software Engineer",
    skill: "Acceptance Testing",
    count: "1",
  },
  {
    id: "6133",
    job_category: "Software Engineer",
    skill: "Test Automation",
    count: "1",
  },
  {
    id: "6134",
    job_category: "Software Engineer",
    skill: "Continuous Testing",
    count: "1",
  },
  {
    id: "6135",
    job_category: "Software Engineer",
    skill: "Load Testing",
    count: "1",
  },
  {
    id: "6136",
    job_category: "Software Engineer",
    skill: "Security Testing",
    count: "1",
  },
  {
    id: "6137",
    job_category: "Software Engineer",
    skill: "Performance Testing",
    count: "1",
  },
  {
    id: "6139",
    job_category: "Software Engineer",
    skill: "Continuous Integration (CI)",
    count: "1",
  },
  {
    id: "6140",
    job_category: "Software Engineer",
    skill: "Continuous Deployment (CD)",
    count: "1",
  },
  {
    id: "6141",
    job_category: "Software Engineer",
    skill: "Configuration Management",
    count: "1",
  },
  {
    id: "6143",
    job_category: "Software Engineer",
    skill: "Monitoring and Logging",
    count: "1",
  },
  {
    id: "6145",
    job_category: "Software Engineer",
    skill: "Cloud Platforms",
    count: "1",
  },
  {
    id: "6146",
    job_category: "Software Engineer",
    skill: "Deployment Automation",
    count: "1",
  },
  {
    id: "6148",
    job_category: "Software Engineer",
    skill: "Collaborative Development Tools",
    count: "1",
  },
  {
    id: "6149",
    job_category: "Software Engineer",
    skill: "DevOps Best Practices",
    count: "1",
  },
  {
    id: "6157",
    job_category: "Software Engineer",
    skill: "Accessibility Standards",
    count: "1",
  },
  {
    id: "6158",
    job_category: "Software Engineer",
    skill: "Graphic Design Tools",
    count: "1",
  },
  {
    id: "6159",
    job_category: "Software Engineer",
    skill: "Design Systems",
    count: "2",
  },
  {
    id: "6162",
    job_category: "Software Engineer",
    skill: "Web Application Security",
    count: "1",
  },
  {
    id: "6163",
    job_category: "Software Engineer",
    skill: "Encryption Algorithms",
    count: "1",
  },
  {
    id: "6164",
    job_category: "Software Engineer",
    skill: "Secure Coding Practices",
    count: "1",
  },
  {
    id: "6167",
    job_category: "Software Engineer",
    skill: "Security Audits",
    count: "1",
  },
  {
    id: "6168",
    job_category: "Software Engineer",
    skill: "Identity and Access Management (IAM)",
    count: "1",
  },
  {
    id: "6169",
    job_category: "Software Engineer",
    skill: "Security Standards (e.g. OWASP)",
    count: "1",
  },
  {
    id: "6170",
    job_category: "Software Engineer",
    skill: "AI and Machine Learning",
    count: "1",
  },
  {
    id: "6171",
    job_category: "Software Engineer",
    skill: "Machine Learning Algorithms",
    count: "1",
  },
  {
    id: "6177",
    job_category: "Software Engineer",
    skill: "Reinforcement Learning",
    count: "1",
  },
  {
    id: "6178",
    job_category: "Software Engineer",
    skill: "Model Deployment",
    count: "1",
  },
  {
    id: "6179",
    job_category: "Software Engineer",
    skill: "AI Ethics",
    count: "1",
  },
  { id: "6183", job_category: "Software Engineer", skill: "Hive", count: "1" },
  { id: "6184", job_category: "Software Engineer", skill: "Kafka", count: "1" },
  {
    id: "6185",
    job_category: "Software Engineer",
    skill: "Big Data Analytics",
    count: "1",
  },
  {
    id: "6187",
    job_category: "Software Engineer",
    skill: "Data Lakes",
    count: "1",
  },
  {
    id: "6189",
    job_category: "Software Engineer",
    skill: "Real-Time Data Processing",
    count: "1",
  },
  {
    id: "6190",
    job_category: "Software Engineer",
    skill: "Blockchain",
    count: "1",
  },
  {
    id: "6191",
    job_category: "Software Engineer",
    skill: "Smart Contracts",
    count: "1",
  },
  {
    id: "6192",
    job_category: "Software Engineer",
    skill: "Decentralized Applications (DApps)",
    count: "1",
  },
  {
    id: "6193",
    job_category: "Software Engineer",
    skill: "Cryptocurrency",
    count: "1",
  },
  {
    id: "6194",
    job_category: "Software Engineer",
    skill: "Hyperledger",
    count: "1",
  },
  {
    id: "6195",
    job_category: "Software Engineer",
    skill: "Ethereum",
    count: "1",
  },
  {
    id: "6196",
    job_category: "Software Engineer",
    skill: "Blockchain Security",
    count: "1",
  },
  {
    id: "6197",
    job_category: "Software Engineer",
    skill: "Consensus Algorithms",
    count: "1",
  },
  {
    id: "6198",
    job_category: "Software Engineer",
    skill: "Blockchain Development Platforms",
    count: "1",
  },
  {
    id: "6199",
    job_category: "Software Engineer",
    skill: "Distributed Ledger Technology (DLT)",
    count: "1",
  },
  {
    id: "6201",
    job_category: "Software Engineer",
    skill: "Quantum Algorithms",
    count: "1",
  },
  {
    id: "6202",
    job_category: "Software Engineer",
    skill: "Quantum Cryptography",
    count: "1",
  },
  {
    id: "6203",
    job_category: "Software Engineer",
    skill: "Quantum Simulation",
    count: "1",
  },
  {
    id: "6204",
    job_category: "Software Engineer",
    skill: "Quantum Error Correction",
    count: "1",
  },
  {
    id: "6205",
    job_category: "Software Engineer",
    skill: "Quantum Machine Learning",
    count: "1",
  },
  {
    id: "6206",
    job_category: "Software Engineer",
    skill: "Quantum Networking",
    count: "1",
  },
  {
    id: "6207",
    job_category: "Software Engineer",
    skill: "Quantum Cloud Computing",
    count: "1",
  },
  {
    id: "6208",
    job_category: "Software Engineer",
    skill: "Quantum Development Languages (e.g. Qiskit Cirq)",
    count: "1",
  },
  {
    id: "6209",
    job_category: "Software Engineer",
    skill: "Quantum Hardware",
    count: "1",
  },
  {
    id: "6210",
    job_category: "Software Engineer",
    skill: "AR and VR Development",
    count: "1",
  },
  {
    id: "6211",
    job_category: "Software Engineer",
    skill: "Augmented Reality (AR)",
    count: "1",
  },
  {
    id: "6212",
    job_category: "Software Engineer",
    skill: "Virtual Reality (VR)",
    count: "1",
  },
  {
    id: "6213",
    job_category: "Software Engineer",
    skill: "Mixed Reality",
    count: "1",
  },
  {
    id: "6214",
    job_category: "Software Engineer",
    skill: "AR/VR Hardware",
    count: "1",
  },
  {
    id: "6215",
    job_category: "Software Engineer",
    skill: "AR/VR Software Development Kits (SDKs)",
    count: "1",
  },
  {
    id: "6217",
    job_category: "Software Engineer",
    skill: "Spatial Computing",
    count: "1",
  },
  {
    id: "6218",
    job_category: "Software Engineer",
    skill: "Immersive User Interfaces",
    count: "1",
  },
  {
    id: "6219",
    job_category: "Software Engineer",
    skill: "AR/VR Content Creation",
    count: "1",
  },
  {
    id: "6222",
    job_category: "Software Engineer",
    skill: "Game Engines (e.g. Unity Unreal Engine)",
    count: "1",
  },
  {
    id: "6223",
    job_category: "Software Engineer",
    skill: "Game Physics",
    count: "1",
  },
  {
    id: "6226",
    job_category: "Software Engineer",
    skill: "Game Networking",
    count: "1",
  },
  {
    id: "6227",
    job_category: "Software Engineer",
    skill: "Game Testing",
    count: "1",
  },
  {
    id: "6228",
    job_category: "Software Engineer",
    skill: "Game Monetization",
    count: "1",
  },
  {
    id: "6229",
    job_category: "Software Engineer",
    skill: "Game Development Platforms",
    count: "1",
  },
  {
    id: "6231",
    job_category: "Software Engineer",
    skill: "iOS Game Development",
    count: "1",
  },
  {
    id: "6232",
    job_category: "Software Engineer",
    skill: "Android Game Development",
    count: "1",
  },
  {
    id: "6233",
    job_category: "Software Engineer",
    skill: "Cross-Platform Game Development",
    count: "1",
  },
  {
    id: "6234",
    job_category: "Software Engineer",
    skill: "Game UI/UX Design",
    count: "1",
  },
  {
    id: "6235",
    job_category: "Software Engineer",
    skill: "Game Audio Engineering",
    count: "1",
  },
  {
    id: "6236",
    job_category: "Software Engineer",
    skill: "Game Performance Optimization",
    count: "1",
  },
  {
    id: "6237",
    job_category: "Software Engineer",
    skill: "Game Asset Management",
    count: "1",
  },
  {
    id: "6238",
    job_category: "Software Engineer",
    skill: "Augmented Reality Games (AR)",
    count: "1",
  },
  {
    id: "6239",
    job_category: "Software Engineer",
    skill: "Virtual Reality Games (VR)",
    count: "1",
  },
  {
    id: "6240",
    job_category: "Software Engineer",
    skill: "IoT (Internet of Things)",
    count: "1",
  },
  {
    id: "6242",
    job_category: "Software Engineer",
    skill: "IoT Protocols (e.g. MQTT CoAP)",
    count: "1",
  },
  {
    id: "6243",
    job_category: "Software Engineer",
    skill: "IoT Security",
    count: "1",
  },
  {
    id: "6244",
    job_category: "Software Engineer",
    skill: "Sensor Networks",
    count: "1",
  },
  {
    id: "6245",
    job_category: "Software Engineer",
    skill: "IoT Data Analytics",
    count: "1",
  },
  {
    id: "6246",
    job_category: "Software Engineer",
    skill: "Edge Computing",
    count: "1",
  },
  {
    id: "6247",
    job_category: "Software Engineer",
    skill: "Industrial IoT (IIoT)",
    count: "1",
  },
  {
    id: "6248",
    job_category: "Software Engineer",
    skill: "IoT Device Management",
    count: "1",
  },
  {
    id: "6249",
    job_category: "Software Engineer",
    skill: "IoT Cloud Platforms",
    count: "1",
  },
  {
    id: "6250",
    job_category: "Software Engineer",
    skill: "Cloud-Native Development",
    count: "1",
  },
  {
    id: "6252",
    job_category: "Software Engineer",
    skill: "Serverless Computing",
    count: "1",
  },
  {
    id: "6253",
    job_category: "Software Engineer",
    skill: "Container Orchestration (e.g. Kubernetes)",
    count: "1",
  },
  {
    id: "6254",
    job_category: "Software Engineer",
    skill: "API Design and Management",
    count: "1",
  },
  {
    id: "6255",
    job_category: "Software Engineer",
    skill: "Event-Driven Architecture",
    count: "1",
  },
  {
    id: "6256",
    job_category: "Software Engineer",
    skill: "Distributed Systems",
    count: "1",
  },
  {
    id: "6257",
    job_category: "Software Engineer",
    skill: "Scalability and Performance",
    count: "1",
  },
  {
    id: "6258",
    job_category: "Software Engineer",
    skill: "Fault Tolerance",
    count: "1",
  },
  {
    id: "6259",
    job_category: "Software Engineer",
    skill: "Chaos Engineering",
    count: "1",
  },
  {
    id: "6260",
    job_category: "Software Engineer",
    skill: "Fintech (Financial Technology)",
    count: "1",
  },
  {
    id: "6261",
    job_category: "Software Engineer",
    skill: "Payment Gateways",
    count: "1",
  },
  {
    id: "6262",
    job_category: "Software Engineer",
    skill: "Blockchain in Fintech",
    count: "1",
  },
  {
    id: "6263",
    job_category: "Software Engineer",
    skill: "Financial APIs",
    count: "1",
  },
  {
    id: "6264",
    job_category: "Software Engineer",
    skill: "Robo-Advisors",
    count: "1",
  },
  {
    id: "6265",
    job_category: "Software Engineer",
    skill: "Digital Banking Platforms",
    count: "1",
  },
  {
    id: "6266",
    job_category: "Software Engineer",
    skill: "Fraud Detection Systems",
    count: "1",
  },
  {
    id: "6269",
    job_category: "Software Engineer",
    skill: "Algorithmic Trading",
    count: "1",
  },
  {
    id: "6270",
    job_category: "Software Engineer",
    skill: "Healthcare IT",
    count: "1",
  },
  {
    id: "6272",
    job_category: "Software Engineer",
    skill: "Healthcare Data Standards (e.g. HL7)",
    count: "1",
  },
  {
    id: "6273",
    job_category: "Software Engineer",
    skill: "Health Information Exchange (HIE)",
    count: "1",
  },
  {
    id: "6274",
    job_category: "Software Engineer",
    skill: "Medical Imaging Software",
    count: "1",
  },
  {
    id: "6275",
    job_category: "Software Engineer",
    skill: "Telemedicine Platforms",
    count: "1",
  },
  {
    id: "6276",
    job_category: "Software Engineer",
    skill: "Healthcare Analytics",
    count: "1",
  },
  {
    id: "6277",
    job_category: "Software Engineer",
    skill: "Clinical Decision Support Systems",
    count: "1",
  },
  {
    id: "6278",
    job_category: "Software Engineer",
    skill: "Healthcare Cybersecurity",
    count: "1",
  },
  {
    id: "6279",
    job_category: "Software Engineer",
    skill: "Patient Engagement Platforms",
    count: "1",
  },
  {
    id: "6280",
    job_category: "Software Engineer",
    skill: "EdTech (Education Technology)",
    count: "1",
  },
  {
    id: "6282",
    job_category: "Software Engineer",
    skill: "E-Learning Platforms",
    count: "1",
  },
  {
    id: "6283",
    job_category: "Software Engineer",
    skill: "Educational Content Management",
    count: "1",
  },
  {
    id: "6284",
    job_category: "Software Engineer",
    skill: "Adaptive Learning Technology",
    count: "1",
  },
  {
    id: "6285",
    job_category: "Software Engineer",
    skill: "Online Assessment Tools",
    count: "1",
  },
  {
    id: "6286",
    job_category: "Software Engineer",
    skill: "Virtual Classroom Solutions",
    count: "1",
  },
  {
    id: "6287",
    job_category: "Software Engineer",
    skill: "Education Data Analytics",
    count: "1",
  },
  {
    id: "6288",
    job_category: "Software Engineer",
    skill: "Student Information Systems (SIS)",
    count: "1",
  },
  {
    id: "6289",
    job_category: "Software Engineer",
    skill: "EdTech Mobile Apps",
    count: "1",
  },
  {
    id: "6290",
    job_category: "Software Engineer",
    skill: "Legal Tech",
    count: "1",
  },
  {
    id: "6291",
    job_category: "Software Engineer",
    skill: "Contract Management Software",
    count: "1",
  },
  {
    id: "6292",
    job_category: "Software Engineer",
    skill: "Legal Document Automation",
    count: "1",
  },
  {
    id: "6293",
    job_category: "Software Engineer",
    skill: "eDiscovery Solutions",
    count: "1",
  },
  {
    id: "6294",
    job_category: "Software Engineer",
    skill: "Case Management Software",
    count: "1",
  },
  {
    id: "6295",
    job_category: "Software Engineer",
    skill: "Compliance Management Software",
    count: "1",
  },
  {
    id: "6296",
    job_category: "Software Engineer",
    skill: "Legal Research Platforms",
    count: "1",
  },
  {
    id: "6297",
    job_category: "Software Engineer",
    skill: "Intellectual Property Management",
    count: "1",
  },
  {
    id: "6298",
    job_category: "Software Engineer",
    skill: "Legal Analytics",
    count: "1",
  },
  {
    id: "6299",
    job_category: "Software Engineer",
    skill: "Legal AI",
    count: "1",
  },
  {
    id: "6300",
    job_category: "Software Engineer",
    skill: "Agriculture Tech (AgriTech)",
    count: "1",
  },
  {
    id: "6301",
    job_category: "Software Engineer",
    skill: "Precision Agriculture",
    count: "1",
  },
  {
    id: "6302",
    job_category: "Software Engineer",
    skill: "Farm Management Software",
    count: "1",
  },
  {
    id: "6303",
    job_category: "Software Engineer",
    skill: "Crop Monitoring Systems",
    count: "1",
  },
  {
    id: "6304",
    job_category: "Software Engineer",
    skill: "Livestock Monitoring Solutions",
    count: "1",
  },
  {
    id: "6305",
    job_category: "Software Engineer",
    skill: "Climate Data Analytics",
    count: "1",
  },
  {
    id: "6306",
    job_category: "Software Engineer",
    skill: "Supply Chain Management (Agri)",
    count: "1",
  },
  {
    id: "6307",
    job_category: "Software Engineer",
    skill: "IoT in Agriculture",
    count: "1",
  },
  {
    id: "6308",
    job_category: "Software Engineer",
    skill: "Agri Drones",
    count: "1",
  },
  {
    id: "6309",
    job_category: "Software Engineer",
    skill: "Food Traceability Systems",
    count: "1",
  },
  {
    id: "6310",
    job_category: "Software Engineer",
    skill: "Cyber-Physical Systems (CPS)",
    count: "1",
  },
  {
    id: "6311",
    job_category: "Software Engineer",
    skill: "Industrial Control Systems (ICS)",
    count: "1",
  },
  {
    id: "6312",
    job_category: "Software Engineer",
    skill: "Smart Grid Technologies",
    count: "1",
  },
  {
    id: "6313",
    job_category: "Software Engineer",
    skill: "Automated Manufacturing Systems",
    count: "1",
  },
  {
    id: "6314",
    job_category: "Software Engineer",
    skill: "Smart Cities Infrastructure",
    count: "1",
  },
  {
    id: "6315",
    job_category: "Software Engineer",
    skill: "Connected Vehicles",
    count: "1",
  },
  {
    id: "6316",
    job_category: "Software Engineer",
    skill: "Smart Home Automation",
    count: "1",
  },
  {
    id: "6317",
    job_category: "Software Engineer",
    skill: "Robotics and Automation",
    count: "1",
  },
  {
    id: "6318",
    job_category: "Software Engineer",
    skill: "Predictive Maintenance",
    count: "1",
  },
  {
    id: "6319",
    job_category: "Software Engineer",
    skill: "Intelligent Transportation Systems",
    count: "1",
  },
  {
    id: "6320",
    job_category: "Software Engineer",
    skill: "Environmental Tech (EnviroTech)",
    count: "1",
  },
  {
    id: "6321",
    job_category: "Software Engineer",
    skill: "Environmental Monitoring Systems",
    count: "1",
  },
  {
    id: "6322",
    job_category: "Software Engineer",
    skill: "Air Quality Monitoring",
    count: "1",
  },
  {
    id: "6323",
    job_category: "Software Engineer",
    skill: "Water Management Systems",
    count: "1",
  },
  {
    id: "6324",
    job_category: "Software Engineer",
    skill: "Renewable Energy Technologies",
    count: "1",
  },
  {
    id: "6325",
    job_category: "Software Engineer",
    skill: "Waste Management Solutions",
    count: "1",
  },
  {
    id: "6327",
    job_category: "Software Engineer",
    skill: "GIS (Geographic Information Systems)",
    count: "1",
  },
  {
    id: "6328",
    job_category: "Software Engineer",
    skill: "Remote Sensing Technologies",
    count: "1",
  },
  {
    id: "6330",
    job_category: "Software Engineer",
    skill: "Sustainability Analytics",
    count: "1",
  },
  {
    id: "6331",
    job_category: "Software Engineer",
    skill: "Robotics and AI Ethics",
    count: "1",
  },
  {
    id: "6332",
    job_category: "Software Engineer",
    skill: "Ethical AI Design",
    count: "1",
  },
  {
    id: "6333",
    job_category: "Software Engineer",
    skill: "Bias and Fairness in AI",
    count: "1",
  },
  {
    id: "6334",
    job_category: "Software Engineer",
    skill: "AI Transparency",
    count: "1",
  },
  {
    id: "6335",
    job_category: "Software Engineer",
    skill: "Privacy in AI",
    count: "1",
  },
  {
    id: "6336",
    job_category: "Software Engineer",
    skill: "Accountability in AI Systems",
    count: "1",
  },
  {
    id: "6337",
    job_category: "Software Engineer",
    skill: "Human-AI Collaboration",
    count: "1",
  },
  {
    id: "6338",
    job_category: "Software Engineer",
    skill: "Regulation and Policy in AI",
    count: "1",
  },
  {
    id: "6339",
    job_category: "Software Engineer",
    skill: "AI Governance",
    count: "1",
  },
  {
    id: "6341",
    job_category: "Software Engineer",
    skill: "Startup Experience",
    count: "1",
  },
  {
    id: "6343",
    job_category: "Software Engineer",
    skill: "Lean Startup Methodology",
    count: "1",
  },
  {
    id: "6344",
    job_category: "Software Engineer",
    skill: "Fundraising for Startups",
    count: "1",
  },
  {
    id: "6345",
    job_category: "Software Engineer",
    skill: "Business Model Canvas",
    count: "1",
  },
  {
    id: "6346",
    job_category: "Software Engineer",
    skill: "Pitching and Presenting",
    count: "1",
  },
  {
    id: "6347",
    job_category: "Software Engineer",
    skill: "Customer Development",
    count: "1",
  },
  {
    id: "6348",
    job_category: "Software Engineer",
    skill: "Product-Market Fit",
    count: "1",
  },
  {
    id: "6349",
    job_category: "Software Engineer",
    skill: "Scaling Startups",
    count: "1",
  },
  {
    id: "6350",
    job_category: "Software Engineer",
    skill: "Pivoting in Startups",
    count: "1",
  },
  {
    id: "6354",
    job_category: "Freelance UX/UI Designer",
    skill: "Persona Creation",
    count: "1",
  },
  {
    id: "6357",
    job_category: "Freelance UX/UI Designer",
    skill: "Prototyping Tools (e.g. Sketch Adobe XD)",
    count: "1",
  },
  {
    id: "6358",
    job_category: "Freelance UX/UI Designer",
    skill: "Interactive Prototypes",
    count: "1",
  },
  {
    id: "6360",
    job_category: "Freelance UX/UI Designer",
    skill: "User Flow Design",
    count: "1",
  },
  {
    id: "6361",
    job_category: "Freelance UX/UI Designer",
    skill: "Responsive Design Principles",
    count: "1",
  },
  {
    id: "6362",
    job_category: "Freelance UX/UI Designer",
    skill: "Mobile UX/UI Design",
    count: "1",
  },
  {
    id: "6363",
    job_category: "Freelance UX/UI Designer",
    skill: "Visual Design Principles",
    count: "1",
  },
  {
    id: "6366",
    job_category: "Freelance UX/UI Designer",
    skill: "Grid Systems",
    count: "1",
  },
  {
    id: "6367",
    job_category: "Freelance UX/UI Designer",
    skill: "Iconography",
    count: "1",
  },
  {
    id: "6368",
    job_category: "Freelance UX/UI Designer",
    skill: "Motion Design",
    count: "1",
  },
  {
    id: "6370",
    job_category: "Freelance UX/UI Designer",
    skill: "UI Component Libraries",
    count: "1",
  },
  {
    id: "6371",
    job_category: "Freelance UX/UI Designer",
    skill: "UI Animation",
    count: "1",
  },
  {
    id: "6372",
    job_category: "Freelance UX/UI Designer",
    skill: "Illustration in UI/UX Design",
    count: "1",
  },
  {
    id: "6376",
    job_category: "Freelance UX/UI Designer",
    skill: "Project Management Tools (e.g. Asana Trello)",
    count: "1",
  },
  {
    id: "6377",
    job_category: "Freelance UX/UI Designer",
    skill: "Collaborative Design Tools (e.g. Figma)",
    count: "1",
  },
  {
    id: "6378",
    job_category: "Freelance UX/UI Designer",
    skill: "Design Handoff Tools (e.g. Zeplin)",
    count: "1",
  },
  {
    id: "6379",
    job_category: "Freelance UX/UI Designer",
    skill: "Version Control for Design (e.g. Abstract)",
    count: "1",
  },
  {
    id: "6380",
    job_category: "Freelance UX/UI Designer",
    skill: "Brand Identity Integration",
    count: "1",
  },
  {
    id: "6382",
    job_category: "Freelance UX/UI Designer",
    skill: "Accessibility in Design",
    count: "1",
  },
  {
    id: "6383",
    job_category: "Freelance UX/UI Designer",
    skill: "Cross-Platform Design",
    count: "1",
  },
  {
    id: "6384",
    job_category: "Freelance UX/UI Designer",
    skill: "AR/VR UX/UI Design",
    count: "1",
  },
  {
    id: "6385",
    job_category: "Freelance UX/UI Designer",
    skill: "Growth-Driven Design",
    count: "1",
  },
  {
    id: "6386",
    job_category: "Freelance UX/UI Designer",
    skill: "E-commerce UX/UI Design",
    count: "1",
  },
  {
    id: "6387",
    job_category: "Freelance UX/UI Designer",
    skill: "Enterprise UX/UI Design",
    count: "1",
  },
  {
    id: "6388",
    job_category: "Freelance UX/UI Designer",
    skill: "Design Leadership",
    count: "1",
  },
  {
    id: "6389",
    job_category: "Freelance UX/UI Designer",
    skill: "Design Portfolio Development",
    count: "1",
  },
  {
    id: "6390",
    job_category: "Freelance UX/UI Designer",
    skill: "Freelance Contracting",
    count: "1",
  },
  {
    id: "6392",
    job_category: "Freelance UX/UI Designer",
    skill: "Client Presentation Skills",
    count: "1",
  },
  {
    id: "6393",
    job_category: "Freelance UX/UI Designer",
    skill: "User Interface Optimization",
    count: "1",
  },
  {
    id: "6394",
    job_category: "Freelance UX/UI Designer",
    skill: "UI/UX Trends Awareness",
    count: "1",
  },
  {
    id: "6395",
    job_category: "Freelance UX/UI Designer",
    skill: "User Psychology in Design",
    count: "1",
  },
  {
    id: "6396",
    job_category: "Freelance UX/UI Designer",
    skill: "Design Evaluation",
    count: "1",
  },
  {
    id: "6397",
    job_category: "Freelance UX/UI Designer",
    skill: "UI/UX Design Documentation",
    count: "1",
  },
  {
    id: "6398",
    job_category: "Freelance UX/UI Designer",
    skill: "Design for Conversion",
    count: "1",
  },
  {
    id: "6399",
    job_category: "Freelance UX/UI Designer",
    skill: "Prototyping for Multiple Devices",
    count: "1",
  },
  {
    id: "6400",
    job_category: "Freelance UX/UI Designer",
    skill: "User Interface Testing",
    count: "1",
  },
  {
    id: "6401",
    job_category: "Freelance UX/UI Designer",
    skill: "User Experience Metrics",
    count: "1",
  },
  {
    id: "6402",
    job_category: "Freelance UX/UI Designer",
    skill: "Design Critique",
    count: "1",
  },
  {
    id: "6403",
    job_category: "Freelance UX/UI Designer",
    skill: "SEO Principles for Design",
    count: "1",
  },
  {
    id: "6404",
    job_category: "Freelance UX/UI Designer",
    skill: "Content Strategy in Design",
    count: "1",
  },
  {
    id: "6405",
    job_category: "Freelance UX/UI Designer",
    skill: "Design Iteration",
    count: "1",
  },
  {
    id: "6406",
    job_category: "Freelance UX/UI Designer",
    skill: "UI/UX Design Workflows",
    count: "1",
  },
  {
    id: "6407",
    job_category: "Freelance UX/UI Designer",
    skill: "Visual Brand Strategy",
    count: "1",
  },
  {
    id: "6408",
    job_category: "Freelance UX/UI Designer",
    skill: "Design Sprint Facilitation",
    count: "1",
  },
  {
    id: "6409",
    job_category: "Freelance UX/UI Designer",
    skill: "Remote Collaboration Tools",
    count: "1",
  },
  {
    id: "6410",
    job_category: "Freelance UX/UI Designer",
    skill: "Freelance Business Development",
    count: "1",
  },
  {
    id: "6411",
    job_category: "Freelance UX/UI Designer",
    skill: "Inclusive Design Practices",
    count: "1",
  },
  {
    id: "6412",
    job_category: "Freelance UX/UI Designer",
    skill: "Ethical Design Principles",
    count: "1",
  },
  { id: "6413", job_category: "Web Developer", skill: "WordPress", count: "1" },
  {
    id: "6414",
    job_category: "Web Developer",
    skill: "WordPress Plugin",
    count: "1",
  },
];
