import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllJobDetails,
  sendMessage,
  shareJobAsMessage,
  submitJobApplication,
  submitJobBookmark,
} from "../../db";
import app from "../../config/app";
import Responce from "../components/Responce";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import JobList from "../components/JobList";
import SelectUsers from "../modals/SelectUsers";
import Loader from "../components/Loader";

function JobDetailsComponent({ local, id, viewAs = "default" }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  let { focus, tab, page } = useParams();
  // alert(page);

  const [tabContent, setTabContent] = useState("");
  const [jobDetails, setJobDetails] = useState({
    application_status: 0,
    job_details: [],
    job_posted: [],
    job_reviews: [],
    bookmark_status: 0,
    qualification: 0,
  });

  const [jobApplicationModalOpen, setJobApplicationModalOpen] = useState(false);
  const [formData, setFormData] = useState({ title: "" });
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(""); // Added state for base64 encoded file
  const [fileType, setFileType] = useState(""); // Added state for file type

  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [applyStatus, setApplyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [seeAllSkills, setSeeAllSkills] = useState(false);
  const [bookmarkStatus, setBookmarStatus] = useState(false);

  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showHubsModal, setShowHubsModal] = useState(false);

  const [jobLink, setJobLink] = useState("");

  const tabObj = [
    {
      label: "About",
      name: "about-job",
      path: viewAs == "admin" ? "" : "/jobs/" + id + "/about-job",
      show: true,
    },
    {
      label: "Reviews",
      name: "job-reviews",
      path: viewAs == "admin" ? "" : "/jobs/" + id + "/job-reviews",
      show: true,
    },
    {
      label: "Job posted",
      name: "related-job",
      path: viewAs == "admin" ? "" : "/jobs/" + id + "/related-job",
      show: viewAs == "admin" ? false : true,
    },
  ];

  useEffect(() => {
    fetchData();
    // setLoading(false);
  }, [focus, id, local]);

  const fetchData = async () => {
    setSeeAllSkills(false);
    setApplyStatus(false);
    try {
      const data = await getAllJobDetails(id);
      console.log("Details - > ", data);

      setJobDetails({
        application_status: data.application_status,
        job_details: data.job_details,
        job_posted: data.job_posted,
        job_reviews: data.job_reviews,
        bookmark_status: data.bookmark_status,
        qualification: data.qualification,
      });
      setBookmarStatus(data.bookmark_status);
      setApplyStatus(jobDetails.application_status > 0 ? true : false);
      updateTabContent();

      const link = app.share_link + "job/" + id;
      setJobLink(link);
    } catch (error) {
      console.error("Details:", error);
    }
    setLoading(false);
  };

  const addJobToBookMark = async (job_id) => {
    const responce = await submitJobBookmark(job_id);
    console.log("Bookmark responce ", responce);
    bookmarkStatus ? setBookmarStatus(false) : setBookmarStatus(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);

    // Convert file to base64
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileBase64(reader.result);
    };
    reader.readAsDataURL(file);

    // Get file type
    setFileType(file.type);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newAttachment = {
      id: Date.now().toString() + "@" + app.current_user,
      title: formData.title.trim(),
      fileLink: fileBase64, // Use base64 encoded file here
      fileType: fileType, // Store file type
    };

    // console.log("Saving new attachment to IndexedDB:", newAttachment); // Debug log
    const responce = await submitJobApplication(id, newAttachment);
    console.log("Application responce ", responce);
    if (responce) {
      setApplyStatus(true);
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      fetchData();
      // console.log(responce_card);
    }

    setFormData({ title: "" }); // Clear title input
    setFile(null); // Clear selected file
    setFileBase64(""); // Clear base64 encoded file
    setFileType(""); // Clear file type
    closeModal(); // Close modal
  };

  const closeModal = () => {
    setJobApplicationModalOpen(false);
  };

  const updateTabContent = () => {
    // if (!focus) {
    //   focus = "about-job";
    // }

    if (focus == "about-job") {
      setTabContent(
        <div className="about_job">
          {jobDetails?.job_details[0]?.row?.about}
        </div>
      );
    } else if (focus == "job-reviews") {
      let reviews = () => {
        return jobDetails.job_reviews.map((item, index) => (
          <>
            <div className="border-1 rounded-xl p-2 my-2">
              <div className="rounded-xl">
                <Link
                  to={`/profile/${item.user_profile[0].username}`}
                  className="flex my-2 px-2 animate__animated animate__zoomIn animate_fast py-2 rounded-xl hover"
                >
                  <div className="h-12 min-w-12 bg-gray-200 rounded-full">
                    <img
                      src={item.user_profile[0].profile_image}
                      className="h-12 min-w-12 min-w-12 w-12 rounded-full object-cover"
                    />
                  </div>
                  <div className="w-full px-3">
                    <div className="w-full rounded-full flex align-center justify-between">
                      <div className="">{item.user_profile[0].fullname}</div>
                      <div className="text-sm">{item.row.date}</div>
                    </div>
                    <div className="-mt-1 rounded-full text-mute">
                      {item.user_profile[0].username}
                    </div>
                  </div>
                </Link>
                <div className="flex gap-3 animate__animated animate__zoomIn animate_fast">
                  <div className="h-auto w-full bg-gray-200 rounded-xl p-3 py-4">
                    {item.row.about}
                  </div>
                </div>
              </div>
            </div>
          </>
        ));
      };
      setTabContent(reviews);
    } else if (focus == "related-job") {
      let jobs = () => {
        return jobDetails.job_posted.map((item, index) => (
          <div className="my-2">
            <JobList data={item} local={local} />
            <hr className="line_sm" />
          </div>
        ));
      };
      setTabContent(jobs);
    } else {
      // setTabContent(
      //   <div className="about_job">{jobDetails?.job_details[0]?.row?.about}</div>
      // );
    }
  };

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(jobLink)
        .then(() => {
          // alert("Link copied to clipboard!");
          setShowResponceCard(true);
          setTimeout(() => {
            setShowResponceCard(false);
          }, 3000);
          setResponceCard(
            <Responce data={{ text: "Link copied", status: true }} />
          );
        })
        .catch((error) => {
          console.error("Error copying link: ", error);
        });
    } else {
      console.error("Clipboard API not supported in this browser.");
    }
  };

  const onSelectUser = async (chatids) => {
    const data_res = await shareJobAsMessage(chatids, jobLink, id);

    if (data_res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: data_res.responce, status: data_res.r_status }}
        />
      );
      // console.log(responce_card);
    }

    // also save this messsage to the device and broadcast it to relevant devices
    for (let index = 0; index < chatids.length; index++) {
      const element = chatids[index];
      const messageObj = {
        user_id: app.current_user,
        activity_id: Date.now().toString() + "@" + app.current_user,
        status: false,
        parent: "",
        last_modified: Date.now(),
        date_time: Date.now(),
        sender: app.current_user,
        chat_body: jobLink.trim(),
        reciever: chatids[index].user_id,
        contain_media: false,
        conversation_id: chatids[index].chatid,
        send_status: true,
        read_status: false,
        post_shared: null,
        time: Date.now(),
        uploads: {
          images: [],
          videos: [],
          audios: [],
          documents: [],
        },
        draft_status: false,
        options: {},
      };
      const res = await sendMessage(messageObj, false);
    }

    console.log("Share responce", data_res);
  };

  const moreShareOptions = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this!",
          text: "Here is something really interesting!",
          url: jobLink,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((error) => {
          console.error("Error sharing:", error);
        });
    } else {
      console.error("Web Share API not supported in this browser.");
    }
  };

  const shareAsMessage = () => {
    // action
    setShowUsersModal(true);
  };

  const share_menu = (
    <Menu>
      <MenuButton className="w-10 h-10 rounded-full bg-gray hover flex align-center justify-center">
        <span class="material-icons-round">share</span>
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom end"
        className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
      >
        <MenuItem
          as="div"
          onClick={() => shareAsMessage()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">send</span>
          <div className="Upload an audio">Share as message </div>
        </MenuItem>
        <MenuItem
          as="div"
          onClick={() => copyLink()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">content_copy</span>
          <div className="Upload an audio">Copy job link </div>
        </MenuItem>
        {/* <MenuItem
          as="div"
          onClick={() => shareVibeOnHub()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">group</span>
          <div className="Upload an audio">Share on Hub </div>
        </MenuItem> */}
        <MenuItem
          as="div"
          onClick={() => moreShareOptions()}
          className="group cursor-pointer flex w-full items-center justify-start gap-5 rounded-lg py-2 px-3.5 data-[focus]:bg-white/10"
        >
          <span class="material-symbols-rounded">more_horiz</span>
          <div className="Upload an audio">More options </div>
        </MenuItem>
      </MenuItems>
    </Menu>
  );

  return (
    <>
      <div
        className={`min-w-60 jobs_details_right relative ${
          viewAs != "admin" && "border-left"
        } flex-1 px-6 py-3`}
      >
        {loading && (
          <div className="w-full min-h-96 flex align-center justify-center pt-20">
            <Loader />
          </div>
        )}

        {!loading && (
          <>
            {!loading &&
              jobDetails?.job_details[0]?.row?.application_status !=
                "active" && (
                <div class="alert danger">
                  <div>This job is already archived </div>
                  <span class="material-icons-round">info</span>
                </div>
              )}

            {jobDetails.application_status > 0 && (
              <div className="alert success">
                <div>You have already applied for this job</div>
                <span class="material-icons-round">info</span>
              </div>
            )}

            {!loading && (
              <div className="align-start">
                <div className="flex align-start gap-3 py-3">
                  <div className="w-28 h-28 min-w-28 min-h-28 rounded-full bg-gray-100">
                    <img
                      src={
                        jobDetails?.job_details[0]?.business_profile[0]
                          .profile_image == ""
                          ? "/images/business.png"
                          : app.media_url +
                            "/thumb/small/" +
                            jobDetails?.job_details[0]?.business_profile[0]
                              .profile_image
                      }
                      className="w-28 h-28 min-w-28 min-h-28 rounded-full"
                    />
                  </div>
                  <div className=" py-3 pt-0">
                    <div className="text-mute">
                      {
                        jobDetails?.job_details[0]?.business_profile[0]
                          .business_name
                      }
                    </div>
                    <div className="text-xl font-semibold">
                      {jobDetails?.job_details[0]?.row?.job_name}
                    </div>
                    <div className="text font-semibold">
                      {jobDetails?.job_details[0]?.row?.category}
                    </div>
                    <div className="flex align-center jsutify-center gap-2 -ml-1">
                      <span class="material-icons-round">event</span>
                      <div>
                        Posted - {jobDetails?.job_details[0]?.row?.date_time}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex align-center gap-4 mb-0">
                    <div className="flex align-center gap-2">
                      <span class="material-icons-round">hourglass_top</span>
                      <div>
                        Deadline -{" "}
                        {jobDetails?.job_details[0]?.row?.applicant_deadline}
                      </div>
                    </div>
                    <div className="flex align-center gap-2">
                      {share_menu}

                      <div
                        className="w-10 h-10 rounded-full bg-gray hover flex align-center justify-center"
                        onClick={() => addJobToBookMark(id)}
                      >
                        {bookmarkStatus ? (
                          <span className="material-icons-round">bookmark</span>
                        ) : (
                          <span className="material-symbols-outlined">
                            bookmark
                          </span>
                        )}
                      </div>
                      {viewAs != "admin" && (
                        <div className="">
                          {jobDetails.application_status > 0 ? (
                            <button
                              className="btn text-mute"
                              style={{ fontSize: 14 }}
                            >
                              Already applied
                            </button>
                          ) : (
                            <button
                              className="btn"
                              onClick={() => setJobApplicationModalOpen(true)}
                              style={{ fontSize: 14 }}
                            >
                              Apply now
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {jobDetails?.job_details[0]?.row?.skills.length > 0 && (
                    <div className="mt-3 text-xl font-semibold mb-3">
                      Required skills
                    </div>
                  )}

                  <div className="flex align-center gap-1 flex-wrap">
                    {jobDetails?.job_details[0]?.row?.skills.length > 0 &&
                      jobDetails?.job_details[0]?.row?.skills.map(
                        (item, index) => (
                          <>
                            {index < 15 && !seeAllSkills && item != "" && (
                              <div className="w-auto bg-gray-100 rounded-full px-4 flex align-center justify-center h-7 text-sm">
                                {item}
                              </div>
                            )}
                          </>
                        )
                      )}

                    {jobDetails?.job_details[0]?.row?.skills.length > 0 &&
                      jobDetails?.job_details[0]?.row?.skills.map(
                        (item, index) => (
                          <>
                            {seeAllSkills && (
                              <div className="w-auto bg-gray-100 rounded-full px-4 flex align-center justify-center h-7 text-sm">
                                {item}
                              </div>
                            )}
                          </>
                        )
                      )}

                    {jobDetails?.job_details[0]?.row?.skills.length > 15 && (
                      <div
                        className="hover flex align-center justify-center px-4 text-sm h-7 rounded-full"
                        onClick={() =>
                          seeAllSkills
                            ? setSeeAllSkills(false)
                            : setSeeAllSkills(true)
                        }
                      >
                        {seeAllSkills
                          ? "See less"
                          : "See all " +
                            jobDetails?.job_details[0]?.row?.skills.length +
                            " skill"}
                      </div>
                    )}
                  </div>
                  {viewAs != "admin" && (
                    <>
                      <div className="mt-3 text-xl font-semibold  mb-0 mt-5">
                        Qualification
                      </div>
                      <div className="">
                        <div className="flex align-center justify-between mb-2">
                          <div className="">
                            You are{" "}
                            <span className="text-xl px-1 font-bold">
                              {
                                jobDetails?.job_details[0].row
                                  ?.user_qualification
                              }
                              %
                            </span>
                            qualified for this job
                          </div>
                          {/* <div className="px-4 h-8 rounded-full hover flex align-center justify-center">
                        See details
                      </div> */}
                        </div>

                        {!loading && (
                          <div
                            className="progress relative overflow-hidden"
                            style={{ height: 5, position: "relative" }}
                          >
                            <div
                              className="progress_inn animate__animated animate__slideInLeft"
                              style={{
                                width:
                                  jobDetails?.job_details[0].row
                                    ?.user_qualification + "%",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <hr className="line" />
                <div className="tabs">
                  <div className="tabs_title_cont flex align-center gap-2">
                    {tabObj.map((item, index) => (
                      <>
                        {item.show && (
                          <Link
                            to={item.path}
                            className={`flex align-center justify-center rounded-full no-wrap px-3 h-10 hover ${
                              item.name == focus ? "bg-gray-100" : "border-1"
                            }`}
                          >
                            {item.label}
                          </Link>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div className="tabContent">
                  {tabContent == "" && (
                    <div className="about_job my-4">
                      {jobDetails?.job_details[0]?.row?.about}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* job application modal */}

      <Dialog
        className="relative z-10"
        open={jobApplicationModalOpen}
        onClose={() => setJobApplicationModalOpen(false)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg animate__animated animate__zoomIn animate_fast"
            >
              <div className="bg-white">
                <div className="sm:flex sm:items-start w-full">
                  <div className="text-left w-full">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 mb-0 border-bottom p-2 px-5 flex items-center justify-between"
                    >
                      <div>Make a job application</div>
                      <div
                        className="w-10 h-10 rounded-full bg-gray-100 hover flex items-center justify-center"
                        onClick={() => setJobApplicationModalOpen(false)}
                      >
                        <span className="material-icons-round">close</span>
                      </div>
                    </DialogTitle>
                    <form
                      onSubmit={handleSubmit}
                      className="p-5 py-3"
                      encType="multipart/form-data"
                    >
                      <div className="mt-6 rounded-md">
                        <div className="form-check p-0 mt-5">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="attachmentTitle"
                          >
                            Add a note
                          </label>
                          <div className="flex items-center relative mt-3">
                            <textarea
                              className="form-control w-full relative min-h-28"
                              type="text"
                              name="title"
                              id="attachmentTitle"
                              value={formData.title}
                              onChange={handleChange}
                              placeholder="Add a name"
                              cols={10}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-10">
                        <label
                          htmlFor="file"
                          className="w-full h-auto py-7 bg-gray-100 hover rounded-xl cursor-pointer flex align-center justify-center"
                        >
                          <div className="flex flex-col items-center justify-center">
                            <span className="material-icons-round">
                              attachment
                            </span>
                            <div className="mt-3">
                              Attach cover letter (Optional)
                            </div>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*, application/pdf"
                            id="file"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>

                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-full w-full bg-black px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-full"
                        >
                          Submit application
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {showUsersModal && (
        <SelectUsers
          onCloseModal={() => setShowUsersModal(false)}
          onSelectUser={(user_id) => onSelectUser(user_id)}
          submitTitle={"Share job"}
        />
      )}

      {showResponceCard && responceCard}
    </>
  );
}

export default JobDetailsComponent;
