import React from "react";

function Videos() {
  const images = [
    {
      src: "/images/max.jpeg",
      alt: "",
      mediaId: "",
    },
    {
      src: "/images/max.jpeg",
      alt: "",
      mediaId: "",
    },
    {
      src: "/images/max.jpeg",
      alt: "",
      mediaId: "",
    },
    {
      src: "/images/max.jpeg",
      alt: "",
      mediaId: "",
    },
    {
      src: "/images/max.jpeg",
      alt: "",
      mediaId: "",
    },
    {
      src: "/images/max.jpeg",
      alt: "",
      mediaId: "",
    },
  ];
  return (
    <div>
      <div className="p-4">
        <div className="grid grid-cols-3 w-full align-start justify-between gap-2 flex-wrap rounded-xl overflow-hidden">
          {images.map((item, index) => (
            <div className="hover w-full animate__animated animate__zoomIn animate_fast">
              <img src={item.src} className="w-full h-full object-cover" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Videos;
