import React from "react";
import { Link } from "react-router-dom";

function GeneralMidHeader({ onClickTab }) {
  return (
    <div className="px-4 flex align-center headers justify-center p-0 overflow-x-hidden">
      <div
        className="feed_list_cont flex px-4 align-center justify-center w-full m-0 h-full gap-2"
        style={{ width: "100%" }}
      >
        <Link to="/home" onClick={() => onClickTab("trending")}>
          <div className="h-10 no-wrap px-4 bg-gray-100 hover mr-0 rounded-full align-center flex justify-center font-medium">
            Trending vibes
          </div>
        </Link>
        <Link to="/home/following" onClick={() => onClickTab("following")}>
          <div className="h-10 no-wrap px-4 bg-gray-100 hover mr-0 rounded-full align-center flex justify-center font-medium">
            Following
          </div>
        </Link>
        <Link to="/home/hubs" onClick={() => onClickTab("hubs")}>
          <div className="h-10 no-wrap px-4 bg-gray-100 hover mr-0 rounded-full align-center flex justify-center font-medium">
            From Hubs
          </div>
        </Link>
      </div>
    </div>
  );
}

export default GeneralMidHeader;
