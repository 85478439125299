import React, { useEffect, useState } from "react";
import HubSlide from "../components/HubSlide";
import HubSliderDummy from "../components/Dummies/HubSliderDummy";
import { getPageToJoinListGrouped } from "../../db"; // Make sure this function returns the correct data
import BusinessSlide from "../components/BusinessSlide";
import Slider from "../components/Slider";
import { Link } from "react-router-dom";

function DiscoverBusiness() {
  const [recentPages, setRecentPages] = useState([]);
  const [popularPages, setPopularPages] = useState([]);
  const [schoolPages, setSchoolPages] = useState([]);
  const [agencyPages, setAgencyPages] = useState([]);
  const [myPages, setMyPages] = useState([]);

  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if there's more data to load

  useEffect(() => {
    fetchHubsListData(); // Fetch initial data
  }, [offset]);

  const fetchHubsListData = async () => {
    try {
      const data = await getPageToJoinListGrouped(10, 0);
      console.log("Business grouped - > ", data);
      setRecentPages(data.all);
      setPopularPages(data.popularpages);
      setSchoolPages(data.schoolpages);
      setAgencyPages(data.agencypages);
      setMyPages(data.mypages);

      // setFromPeopleListHubs(data.followlisthubs);
      setLoading(false);
    } catch (error) {
      console.error("Vibe:", error);
    }
  };

  return (
    <div className="overflow-hidden pb-4">
      {/* Render hubs if available */}
      {loading && (
        <div className="flex gap-0 pt-5">
          <HubSliderDummy />
          <HubSliderDummy />
          <HubSliderDummy />
        </div>
      )}

      {recentPages.length > 0 && (
        <div className="flex align-center justify-between px-4">
          <div className="text-xl font-bold">Recent on VibeIn</div>
          <Link
            to={`/pagestaxonimy/recentpages`}
            className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
          >
            View more
          </Link>
        </div>
      )}

      {!loading && recentPages.length > 0 && (
        <Slider slideWidth={340} slidesVisible={3}>
          {recentPages.length > 0 &&
            recentPages.map((item, index) => (
              <BusinessSlide simple={true} data={item} />
            ))}
          <div
            style={{
              minWidth: 100,
              height: 100,
            }}
          ></div>
        </Slider>
      )}

      {popularPages.length > 0 && (
        <div className="flex align-center justify-between px-4 mt-6">
          <div className="text-xl font-bold">Popular on VibeIn</div>
          <Link
            to={`/pagestaxonimy/popularpages`}
            className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
          >
            View more
          </Link>
        </div>
      )}

      {!loading && popularPages.length > 0 && (
        <Slider slideWidth={340} slidesVisible={3}>
          {popularPages.length > 0 &&
            popularPages.map((item, index) => (
              <BusinessSlide simple={true} data={item} />
            ))}
          <div
            style={{
              minWidth: 100,
              height: 100,
            }}
          ></div>
        </Slider>
      )}

      {schoolPages.length > 0 && (
        <div className="flex align-center justify-between px-4 mt-6">
          <div className="text-xl font-bold">Schools</div>
          <Link
            to={`/discover/schools`}
            className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
          >
            View more
          </Link>
        </div>
      )}

      {!loading && schoolPages.length > 0 && (
        <Slider slideWidth={340} slidesVisible={3}>
          {schoolPages.length > 0 &&
            schoolPages.map((item, index) => (
              <BusinessSlide simple={true} data={item} />
            ))}
          <div
            style={{
              minWidth: 100,
              height: 100,
            }}
          ></div>
        </Slider>
      )}

      {agencyPages.length > 0 && (
        <div className="flex align-center justify-between px-4 mt-6">
          <div className="text-xl font-bold">Agencies</div>
          <Link
            to={`/discover/agencies`}
            className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
          >
            View more
          </Link>
        </div>
      )}

      {!loading && agencyPages.length > 0 && (
        <Slider slideWidth={340} slidesVisible={3}>
          {agencyPages.length > 0 &&
            agencyPages.map((item, index) => (
              <BusinessSlide simple={true} data={item} />
            ))}
          <div
            style={{
              minWidth: 100,
              height: 100,
            }}
          ></div>
        </Slider>
      )}

      {myPages.length > 0 && (
        <div className="flex align-center justify-between px-4 mt-6">
          <div className="text-xl font-bold">Managing</div>
          <Link
            to={`/discover/agencies`}
            className="h-8 flex align-center justify-center px-2 hover rounded-full text-center break-keep"
          >
            View more
          </Link>
        </div>
      )}

      {!loading && myPages.length > 0 && (
        <Slider slideWidth={340} slidesVisible={3}>
          {myPages.length > 0 &&
            myPages.map((item, index) => (
              <BusinessSlide simple={true} data={item} />
            ))}
          <div
            style={{
              minWidth: 100,
              height: 100,
            }}
          ></div>
        </Slider>
      )}
    </div>
  );
}

export default DiscoverBusiness;
