import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import ProfileCard from "./components/ProfileCard";
import FollowList from "./components/FollowList";
import JoinHubList from "./components/JoinHubList";
import {
  getVibeComments,
  getVibeDetails,
  socket,
  submitVibeComment,
} from "../db";
import VibeDummy from "./components/Dummies/VibeDummy";
import Feed from "./components/Feed";
import Comment from "./components/Comment";
import FollowListReused from "./components/FollowListReused";
import JoinHubsListReused from "./components/JoinHubsListReused";
import CopyRight from "./components/CopyRight";

function VibePopup({ local, vibeid, onCommentSubmit }) {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();
  if (!id) {
    id = vibeid;
  }
  console.log("Id - >", vibeid);
  const [vibeDetails, setVibeDetails] = useState();
  const [vibeComments, setVibeComments] = useState([]);
  const [commentContent, setCommentContent] = useState("");
  const [commentCounts, setCommentCounts] = useState(0);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    comment: "",
    hasMedia: false,
  });

  useEffect(() => {
    fetchDetails();
    fetchComments();
    document.body.classList.add("hide_overflow");
    return () => {
      document.body.classList.remove("hide_overflow");
    };
    // [id, local, vibeid, vibeDetails]
  }, [local]);

  useEffect(() => {
    const handleNewComment = (data) => {
      // setVibeComments((prevComments) => [data, ...prevComments]);
      // alert("New comment");
    };

    // socket.on("newUpdateData", () => {
    //   alert("New data");
    // });

    // socket.on("newUpdateData", handleNewComment);

    return () => {
      socket.off("newUpdateData", handleNewComment);
    };
  }, [id, local, vibeid]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        socket.connect();
      } else {
        socket.disconnect();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const fetchDetails = async () => {
    try {
      const data = await getVibeDetails(id);
      setVibeDetails(data.details[0]);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const fetchComments = async () => {
    try {
      const data = await getVibeComments(id, 10, 0);
      setVibeComments(data.comments);
      setCommentCounts(data.comments.length);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.comment.trim()) {
      return;
    }
    try {
      const data = await submitVibeComment(id, formData);
      setFormData({
        comment: "",
        hasMedia: false,
      });
      fetchComments();
      fetchDetails();
      onCommentSubmit(vibeComments.length);
    } catch (error) {
      console.error("Comment:", error);
    }
  };

  return (
    <>
      <div className="min-h-screen flex-1">
        <div className="single_vibe feed_list_cont flex flex-row">
          <div className="p-4 px-0 pb-3 flex-1 left_post_content">
            {/* {loading && <VibeDummy />} */}
            {!loading && (
              <Feed
                data={vibeDetails}
                allowVibeLinks={false}
                onCloseVibePopup={() => navigate(-1)}
                onCommentSubmit={vibeComments.length}
              />
            )}
          </div>

          <div className="comment_right flex flex-col">
            <div className="font-bold text-xl px-3 py-4">
              <span>Comments</span>
              {vibeComments?.length > 0 && (
                <span className="text-sm ml-2">({vibeComments.length})</span>
              )}
            </div>
            <div
              className="vibe_comments relative px-1 flex-1 overflow-y-auto"
              style={{ maxHeight: "calc(100vh - 188px)" }}
            >
              {vibeComments?.length == 0 && (
                <div className="flex flex-col align-center justify-center align-center px-10 pt-24">
                  <img
                    src="/images/3d.png"
                    className="w-24 mb-4 objcet-contain"
                  />
                  <h3 className="align-center justify-center text-center text-sm">
                    No comments
                    <div>Start by posting a comment</div>
                  </h3>
                </div>
              )}
              {vibeComments?.length > 0 && <div className="v_timeline"></div> &&
                vibeComments.map((item, index) => (
                  <Comment key={item.id} data={item} local={local} />
                ))}
            </div>
            <form
              onSubmit={handleSubmit}
              className="px-3 py-4 pb-0 relative sm-p-1 comment_input_cont"
              style={{ marginBottom: 30 }}
            >
              <div className="flex align-start gap-3" style={{ zIndex: 9 }}>
                <div className="w-full min-h-16 rounded-2xl">
                  <textarea
                    placeholder="Post a reply"
                    className="form-control min-h-24 rounded-xl w-full"
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div
                className="flex align-center px-1 py-3 justify-end comment_submit_cont"
                style={{ position: "absolute", bottom: 0, right: 20 }}
              >
                <button
                  type="submit"
                  className="rounded-full hover border-1 px-4 py-1 btn"
                >
                  Post
                </button>
              </div>
            </form>
          </div>

          {/* <div className="flex align-center px-2 my-10">
            <div
              className="rounded-full hover border-1 px-4 py-1"
              onClick={() => fetchComments()}
            >
              Load more
            </div>
          </div> */}
        </div>
      </div>
      {!vibeid && (
        <div className="min-h-screen flex right_cont">
          <TopUserMenu />
          <div className="p-4">
            <FollowListReused />
            <hr className="line" />
            <JoinHubsListReused />
            <hr className="line" />
            <div className="mt-7 mb-16">
              <CopyRight />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VibePopup;
