import { businessCategories } from "./businessCategories";
import { countries } from "./countries";
import { emojis } from "./emojis";
import { interests } from "./interests";
import { jobCategories } from "./jobCategories";
import languages from "./languages";
import { skills } from "./skills";
import { timezone } from "./timezone";
import Cookies from "js-cookie";

let user = "";
const sysUser = Cookies.get("sys_user");
const theme = Cookies.get("enabledDarkmode");

if (sysUser) {
  user = sysUser;
}

// api_request: "https://web.vibein.africa/",
// auth_request_url: "https://web.vibein.africa/",
// media_url: "https://web.vibein.africa/data/",
// share_link: "https://web.vibein.africa/share/",
// base_url: "https://vibein.africa/",

// api_request: "http://localhost:5000/api/",
// auth_request_url: "http://localhost/api/",
// media_url: "http://localhost/api/data/",
// share_link: "http://localhost:3000/share/",
// base_url: "http://localhost:3000/",

const app = {
  api_request: "https://web.vibein.africa/",
  auth_request_url: "https://web.vibein.africa/",
  media_url: "https://web.vibein.africa/data/",
  share_link: "https://web.vibein.africa/share/",
  base_url: "https://vibein.africa/",
  current_user: user,
  businessCategories: businessCategories,
  countries: countries,
  jobCategories: jobCategories,
  languages: languages,
  interests: interests,
  timezone: timezone,
  skills: skills,
  emojis: emojis,
  mobile_width: 800,
  enabledDarkmode: theme ? theme : false,
};

export default app;
