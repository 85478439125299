import React, { useEffect, useState } from "react";
import app from "../../../config/app";
import { PencilIcon, XMarkIcon } from "@heroicons/react/16/solid";
import Responce from "../../components/Responce";
import { getHubAccessList, getHubProfile, updateHubRules } from "../../../db";
import { useParams } from "react-router-dom";

function Rules({ local }) {
  const [selectedPlaces, setSelectedPlaces] = useState("");
  const [filter, setFilter] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [rules, setRules] = useState([]);
  const [newRule, setNewRule] = useState("");
  const system_places = app.businessCategories;
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { id, page, focus } = useParams();

  useEffect(() => {
    fetchData();
  }, [page, id, local]);

  const fetchData = async () => {
    try {
      const info = await getHubProfile(id);
      console.log("Hub info - > ", info.hub_info[0]);
      if (info.r_status) {
        setRules(info.hub_info[0].rules);
      }
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const addRule = () => {
    if (newRule.trim()) {
      setRules([...rules, newRule.trim()]);
      setNewRule("");
      // Here, you would also save the rule to the database
      saveRulesToDatabase([...rules, newRule.trim()]);
    }
  };

  const saveRulesToDatabase = async (updatedRules) => {
    // setIsSaving(true);
    const res = await updateHubRules(id, updatedRules);
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      // console.log(responce_card);
    }
    // update local data list
    await getHubAccessList(app.current_user, 30);
    console.log(updatedRules);
  };

  const deleteRule = (index) => {
    const updatedRules = rules.filter((_, i) => i !== index);
    setRules(updatedRules);
    // Also update the database
    saveRulesToDatabase(updatedRules);
  };

  const filteredPlaces = system_places.filter((places) =>
    places.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="p-0 pt-3">
      <div className="mb-4 flex align-center justify-between">
        <div className="text-xl font-semibold">Community rules</div>
      </div>
      <div className="px-0 py-3 pt-0">
        Every member joining this hub will have to accept these rules in your
        community.
      </div>
      <div className="places_list_selected flex align-start justify-start flex-wrap gap-3">
        {rules.map((rule, index) => (
          <div
            key={index}
            className="border-1 rounded-full flex align-center justify-between w-full"
          >
            <div className="px-1 py-1 flex align-center justify-center">
              <div className="w-10 h-10 rounded-full flex align-center justify-center font-bold text-xl">
                {index + 1}
              </div>
              <div className="px-3">{rule}</div>
            </div>
            <div className="flex align-center">
              <div
                className="remove_place w-10 h-10 mr-1 bg-gray-100 hover:shadow-lg hover rounded-full flex align-center justify-center hover"
                onClick={() => deleteRule(index)}
              >
                <XMarkIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
      <hr className="line" />
      <div className="sticky tags_filter py-2 flex align-center justify-center gap-3 mb-4">
        <input
          type="text"
          placeholder="Something simple ..."
          className="p-2 form-control rounded-xl w-full px-6 flex-1"
          value={newRule}
          onChange={(e) => setNewRule(e.target.value)}
        />
        <div
          className="min-w-14 min-h-14 rounded-xl bg-gray-50 px-5 gap-3 flex align-center justify-center hover cursor-pointer"
          onClick={addRule}
        >
          <div>Add rule</div>
          <div className="material-icons-round">add</div>
        </div>
      </div>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Rules;
