import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  saveEducationToIndexedDB,
  getEducationFromIndexedDB,
  removeEducationFromIndexedDB,
} from "../../db"; // Import IndexedDB functions
import Responce from "../components/Responce";
import app from "../../config/app";

function Education() {
  const [newEducationModalOpen, setNewEducationModalOpen] = useState(false);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [formData, setFormData] = useState({
    institutionName: "",
    startDate: "",
    endDate: "",
    description: "",
    isCurrentlyStudying: false,
  });
  const [educationList, setEducationList] = useState([]);

  useEffect(() => {
    fetchEducation();
  }, []);

  const fetchEducation = async () => {
    const education = await getEducationFromIndexedDB();
    console.log("Retrieved education from IndexedDB:", education); // Debug log
    setEducationList(education || []);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const removeEducation = async (id) => {
    console.log("Removing education with id:", id); // Debug log
    const responce = await removeEducationFromIndexedDB(id);
    setEducationList(educationList.filter((item) => item.id !== id));
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.institutionName.trim() || !formData.startDate.trim()) {
      alert("Please provide the institution name and start date.");
      return;
    }

    const newEducation = {
      id: Date.now().toString() + "@" + app.current_user,
      institutionName: formData.institutionName.trim(),
      startDate: formData.startDate.trim(),
      endDate: formData.isCurrentlyStudying
        ? "Present"
        : formData.endDate.trim(),
      description: formData.description.trim(),
      isCurrentlyStudying: formData.isCurrentlyStudying,
    };

    console.log("Saving new education to IndexedDB:", newEducation); // Debug log
    const responce = await saveEducationToIndexedDB(newEducation);
    console.log(responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }

    setEducationList([...educationList, newEducation]);
    setFormData({
      institutionName: "",
      startDate: "",
      endDate: "",
      description: "",
      isCurrentlyStudying: false,
    });
    setNewEducationModalOpen(false); // Close modal
  };

  return (
    <div className="p-6">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-4 sm:truncate sm:text-2xl sm:tracking-tight">
            Education
          </h2>
        </div>
        <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <span className="hidden sm:block">
            <button
              type="button"
              onClick={() => setNewEducationModalOpen(true)}
              className="inline-flex items-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Add education
            </button>
          </span>
        </div>
      </div>

      {educationList.length === 0 ? (
        <div className="mt-4 text-gray-500">
          <div className="flex align-center">
            <img
              src="/images/juicy-web-designer-girl-making-landing-page-with-video-color-palette-and-site-blocks.gif"
              className="sm_image w-28 mr-3"
              width={100}
              alt="No details"
            />
            <div className="px-3">
              <div className="font-semibold text-lg">
                Add education details.
              </div>
              <div>
                Have any qualifications from a learning institution? Add them
                below.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <div className="flex flex-wrap gap-3 py-3">
            {educationList.map((item) => (
              <div
                key={item.id}
                className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex items-start justify-start gap-6 relative hover"
              >
                <div className="text-lg font-semibold flex-1">
                  <div>{item.institutionName}</div>
                  <div className="text-sm">{item.description}</div>
                </div>
                <div className="text-lg border-left">
                  {item.startDate} - {item.endDate}
                </div>
                <div
                  onClick={() => removeEducation(item.id)}
                  className="w-10 h-10 rounded-full bg-gray-100 shadow-lg hover:shadow-2xl right-2 top-2 flex items-center justify-center select-none hover"
                >
                  <span className="material-icons-round">close</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Dialog
        className="relative z-10"
        open={newEducationModalOpen}
        onClose={() => setNewEducationModalOpen(false)}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg animate__animated animate__zoomIn"
            >
              <div className="bg-white">
                <div className="sm:flex sm:items-start w-full">
                  <div className="text-left w-full">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 mb-6 border-bottom p-2 px-5 flex items-center justify-between"
                    >
                      <div>Add education details</div>
                      <div
                        className="w-10 h-10 rounded-full bg-gray-100 hover flex items-center justify-center"
                        onClick={() => setNewEducationModalOpen(false)}
                      >
                        <span className="material-icons-round">close</span>
                      </div>
                    </DialogTitle>
                    <form onSubmit={handleSubmit} className="p-5 py-3">
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="institutionName"
                          >
                            Learning Institution Name
                          </label>
                          <div className="flex items-center relative mt-3">
                            <input
                              className="form-control w-full"
                              type="text"
                              name="institutionName"
                              value={formData.institutionName}
                              onChange={handleChange}
                              placeholder="Add a name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="startDate"
                          >
                            Start Date
                          </label>
                          <div className="flex items-center relative mt-3">
                            <input
                              className="form-control w-full"
                              type="date"
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label className="form-check-label mb-4">
                            <input
                              type="checkbox"
                              name="isCurrentlyStudying"
                              checked={formData.isCurrentlyStudying}
                              onChange={handleChange}
                            />{" "}
                            I am currently studying here
                          </label>
                        </div>
                      </div>
                      {!formData.isCurrentlyStudying && (
                        <div className="mt-2 rounded-md">
                          <div className="form-check p-0 mt-2">
                            <label
                              className="form-check-label mb-4"
                              htmlFor="endDate"
                            >
                              End Date
                            </label>
                            <div className="flex items-center relative mt-3">
                              <input
                                className="form-control w-full"
                                type="date"
                                name="endDate"
                                value={formData.endDate}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="description"
                          >
                            Description
                          </label>
                          <div className="flex items-center relative mt-3">
                            <textarea
                              name="description"
                              className="form-control w-full min-h-20"
                              value={formData.description}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-full w-full bg-black px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-full"
                        >
                          Save details
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Education;
