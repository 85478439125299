import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import ProfileCard from "./components/ProfileCard";
import FollowList from "./components/FollowList";
import JoinHubList from "./components/JoinHubList";
import {
  getVibeComments,
  getVibeDetails,
  socket,
  submitVibeComment,
} from "../db";
import VibeDummy from "./components/Dummies/VibeDummy";
import Feed from "./components/Feed";
import Comment from "./components/Comment";
import FollowListReused from "./components/FollowListReused";
import JoinHubsListReused from "./components/JoinHubsListReused";
import CopyRight from "./components/CopyRight";

function Vibe({ local, vibeid }) {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();
  if (!id) {
    id = vibeid;
  }
  console.log("Id - >", vibeid);
  const [vibeDetails, setVibeDetails] = useState();
  const [vibeComments, setVibeComments] = useState([]);
  const [commentContent, setCommentContent] = useState("");
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    comment: "",
    hasMedia: false,
  });

  useEffect(() => {
    fetchDetails();
    fetchComments();
  }, [id, local, vibeid]);

  useEffect(() => {
    const handleNewComment = (data) => {
      // setVibeComments((prevComments) => [data, ...prevComments]);
      // alert("New comment");
    };

    // socket.on("newUpdateData", () => {
    //   alert("New data");
    // });

    // socket.on("newUpdateData", handleNewComment);

    return () => {
      socket.off("newUpdateData", handleNewComment);
    };
  }, [id, local, vibeid]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        socket.connect();
      } else {
        socket.disconnect();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const fetchDetails = async () => {
    try {
      const data = await getVibeDetails(id);
      setVibeDetails(data.details[0]);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const fetchComments = async () => {
    try {
      const data = await getVibeComments(id, 10, 0);
      setVibeComments(data.comments);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.comment.trim()) {
      return;
    }
    try {
      const data = await submitVibeComment(id, formData);
      setFormData({
        comment: "",
        hasMedia: false,
      });
      fetchComments();
    } catch (error) {
      console.error("Comment:", error);
    }
  };

  return (
    <>
      <div className="min-h-screen flex-1">
        <div className="headers w-full p-0">
          <div
            className="feed_list_cont flex px-4 align-center w-full m-0 h-full"
            style={{ width: "100%" }}
          >
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Vibe</div>
            </div>
          </div>
        </div>

        <div className="single_vibe feed_list_cont">
          <div className="p-4 px-0 pb-3">
            {loading && <VibeDummy />}
            {!loading && <Feed data={vibeDetails} allowVibeLinks={false} />}
          </div>
          <hr className="line" />
          <form onSubmit={handleSubmit} className="px-3 py-4 pb-0 relative">
            <div className="flex align-start gap-3" style={{ zIndex: 9 }}>
              <div className="w-10 min-w-10 min-h-10 h-10 rounded-full bg-gray-100">
                <img
                  src="/images/user.png"
                  className="w-10 min-w-10 min-h-10 h-10 object-cover rounded-full"
                />
              </div>
              <div className="w-full min-h-10 rounded-2xl">
                <textarea
                  placeholder="Post a reply"
                  className="form-control min-h-24 rounded-xl w-full"
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="flex align-center px-1 py-3 justify-end">
              <button
                type="submit"
                className="rounded-full hover border-1 px-4 py-1"
              >
                Post
              </button>
            </div>
          </form>
          <hr className="line" style={{ margin: 0 }} />
          <div className="vibe_comments relative px-1">
            <div className="v_timeline"></div>
            {vibeComments?.length > 0 &&
              vibeComments.map((item, index) => (
                <Comment key={item.id} data={item} local={local} />
              ))}
          </div>
          {/* <div className="flex align-center px-2 my-10">
            <div
              className="rounded-full hover border-1 px-4 py-1"
              onClick={() => fetchComments()}
            >
              Load more
            </div>
          </div> */}
        </div>
      </div>
      {!vibeid && (
        <div className="min-h-screen flex right_cont">
          <TopUserMenu />
          <div className="p-4">
            <FollowListReused />
            <hr className="line" />
            <JoinHubsListReused />
            <hr className="line" />
            <div className="mt-7 mb-16">
              <CopyRight />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Vibe;
