import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  saveAttachmentToIndexedDB,
  getAttachmentsFromIndexedDB,
  removeAttachmentFromIndexedDB,
} from "../../db";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Responce from "../components/Responce";
import app from "../../config/app";

function Attachments() {
  const [newAttachmentModalOpen, setNewAttachmentModalOpen] = useState(false);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { modal } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const [formData, setFormData] = useState({ title: "" });
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(""); // Added state for base64 encoded file
  const [fileType, setFileType] = useState(""); // Added state for file type
  const [attachmentList, setAttachmentList] = useState([]);

  useEffect(() => {
    if (modal) {
      setNewAttachmentModalOpen(true);
    } else {
      setNewAttachmentModalOpen(false);
    }
    fetchAttachments();
  }, [currentPath]);

  const fetchAttachments = async () => {
    const attachments = await getAttachmentsFromIndexedDB();
    console.log("Retrieved attachments from IndexedDB:", attachments); // Debug log
    setAttachmentList(attachments || []);
  };

  const closeModal = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);

    // Convert file to base64
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileBase64(reader.result);
    };
    reader.readAsDataURL(file);

    // Get file type
    setFileType(file.type);
  };

  const removeAttachment = async (id) => {
    // console.log("Removing attachment with id:", id); // Debug log
    const responce = await removeAttachmentFromIndexedDB(id);
    setAttachmentList(attachmentList.filter((item) => item.id !== id));
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.title.trim() || !file) {
      alert("Please provide both title and file.");
      return;
    }

    const newAttachment = {
      id: Date.now().toString() + "@" + app.current_user,
      title: formData.title.trim(),
      fileLink: fileBase64, // Use base64 encoded file here
      fileType: fileType, // Store file type
    };

    // console.log("Saving new attachment to IndexedDB:", newAttachment); // Debug log
    const responce = await saveAttachmentToIndexedDB(newAttachment);
    console.log(responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }

    setAttachmentList([...attachmentList, newAttachment]);
    setFormData({ title: "" }); // Clear title input
    setFile(null); // Clear selected file
    setFileBase64(""); // Clear base64 encoded file
    setFileType(""); // Clear file type
    closeModal(); // Close modal
  };

  return (
    <div className="p-6">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-4 sm:truncate sm:text-2xl sm:tracking-tight">
            Attachments
          </h2>
        </div>
        <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <Link to="/Professional/attachments/new" className="hidden sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-full bg-white text-gray-900 px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Upload
            </button>
          </Link>
        </div>
      </div>

      {attachmentList.length === 0 ? (
        <div className="mt-0 text-gray-500">
          <div className="flex align-center">
            <img
              src="/images/colors-online-meeting-via-group-call.gif"
              className="sm_image w-28 mr-3"
              width={100}
              alt="No attachments illustration"
            />
            <div className="px-3">
              <div className="font-semibold text-lg">
                No attachments available.
              </div>
              <div>Let potential employers find you faster.</div>
              <div>Upload a CV that showcases your professional profile.</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-0">
          <div className="flex flex-wrap gap-3 py-3">
            {attachmentList.map((item) => (
              <div
                key={item.id}
                className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex items-center justify-start gap-6 relative hover"
              >
                <div className="bg-white rounded-full w-10 h-10 overflow-hidden">
                  <img
                    src="/images/doc2.png"
                    className="w-10 h-10 object-contain p-1 rounded-sm"
                    alt="Attachment icon"
                  />
                </div>
                <div>
                  <div className="text-lg font-semibold">{item.title}</div>
                  <a
                    href={item.fileLink}
                    download={item.title}
                    className="flex items-center rounded-full gap-3"
                  >
                    <div className="text-sm px-2 bg-orange-200 rounded-xl">
                      {item.fileType &&
                        item.fileType.substring(
                          item.fileType.lastIndexOf("/") + 1
                        )}
                    </div>
                    <div>Download</div>
                    <span className="material-icons-round text-sm">
                      file_download
                    </span>
                  </a>
                </div>
                <div
                  onClick={() => removeAttachment(item.id)}
                  className="absolute w-10 h-10 rounded-full bg-gray-100 shadow-lg hover:shadow-2xl right-2 top-2 flex items-center justify-center select-none hover"
                >
                  <span className="material-icons-round">close</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Dialog
        className="relative z-10"
        open={newAttachmentModalOpen}
        onClose={() => closeModal()}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg animate__animated animate__zoomIn animate_fast"
            >
              <div className="bg-white">
                <div className="sm:flex sm:items-start w-full">
                  <div className="text-left w-full">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 mb-0 border-bottom p-2 px-5 flex items-center justify-between"
                    >
                      <div>Upload new attachment</div>
                      <div
                        className="w-10 h-10 rounded-full bg-gray-100 hover flex items-center justify-center"
                        onClick={() => closeModal()}
                      >
                        <span className="material-icons-round">close</span>
                      </div>
                    </DialogTitle>
                    <form
                      onSubmit={handleSubmit}
                      className="p-5 py-3"
                      encType="multipart/form-data"
                    >
                      <div className="mt-6 rounded-md">
                        <div className="form-check p-0 mt-5">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="attachmentTitle"
                          >
                            Attachment title
                          </label>
                          <div className="flex items-center relative mt-3">
                            <div className="absolute top-0 bottom-0 m-auto h-full w-8 z-10 flex items-center justify-center">
                              <span className="material-icons-round">
                                attach_file
                              </span>
                            </div>
                            <input
                              className="form-control w-full relative has_icon"
                              type="text"
                              name="title"
                              id="attachmentTitle"
                              value={formData.title}
                              onChange={handleChange}
                              placeholder="Add a name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-10">
                        <label
                          htmlFor="file"
                          className="w-full h-16 bg-gray-200 hover rounded-xl cursor-pointer flex items-center justify-center"
                        >
                          <div className="flex flex-col items-center justify-center">
                            <span className="material-icons-round">
                              palette
                            </span>
                            <div className="mt-3">Select media files</div>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*, application/pdf"
                            id="file"
                            onChange={handleFileChange}
                            required
                          />
                        </label>
                      </div>

                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-full w-full bg-black px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-full"
                        >
                          Save attachment
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Attachments;
