import React from 'react'
import { useNavigate } from 'react-router-dom';
import TopUserMenu from './components/TopUserMenu';
import ProfileCard from './components/ProfileCard';
import FollowList from './components/FollowList';
import JoinHubList from './components/JoinHubList';

function SendFeedback() {
    const navigate = useNavigate();
    return (
      <>
          <div className="min-h-screen flex-1">
            <div className="px-4 flex align-center headers">
                <div className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center" onClick={() => navigate(-1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z"/></svg>
                </div>
                <div className="h-10 w-full mr-3 rounded-full align-center flex">
                  <div className='font-bold text-xl'>Send us a Feedback</div>
                </div>
            </div>
            <div className="p-4">
  
              {/* all settings goes here */}
            </div>
          </div>
  
          <div className="min-h-screen flex bg-white" class="right_cont">
            <TopUserMenu/>
            <div className="p-4">
              <FollowList/>
  
              <hr className="line" />
  
              <JoinHubList/>
            </div>
          </div>
      </>
    )
}

export default SendFeedback