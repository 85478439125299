import React, { useState } from "react";
import { countries } from "../../../config/countries";

function JobSettings() {
  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit} className="">
        <div className="text-xl font-semibold">Job settings</div>
        <div className="rounded-2xl mt-4 pb-5">
          <div className="form-group mt-6 rounded-2xl p-6 border-1">
            <div>
              <h3>Application status</h3>
            </div>
            <div className="form-check p-0 mt-5 flex align-center">
              <input
                className="form-check-input -ml-1"
                type="radio"
                name="applicationStatus"
                id="availability1"
                value="active"
                checked={formData.applicationStatus === "active"}
                onChange={handleChange}
              />
              <label className="form-check-label ml-5" htmlFor="availability1">
                Active
              </label>
            </div>
            <div className="form-check p-0 mt-5 flex align-center">
              <input
                className="form-check-input -ml-1"
                type="radio"
                name="applicationStatus"
                id="availability2"
                value="inactive"
                checked={formData.applicationStatus === "inactive"}
                onChange={handleChange}
              />
              <label className="form-check-label ml-5" htmlFor="availability2">
                Inactive
              </label>
            </div>
          </div>

          <div className="form-group mt-3 rounded-2xl p-6 border-1">
            <div>
              <h3>Who can apply jobs that you post?</h3>
            </div>
            <div className="form-check p-0 mt-5 flex align-center">
              <input
                className="form-check-input -ml-1"
                type="radio"
                name="whoCanApply"
                id="availability4"
                value="everyone"
                checked={formData.whoCanApply === "everyone"}
                onChange={handleChange}
              />
              <label className="form-check-label ml-5" htmlFor="availability4">
                Everyone
              </label>
            </div>
            <div className="form-check p-0 mt-5 flex align-center">
              <input
                className="form-check-input -ml-1"
                type="radio"
                name="whoCanApply"
                id="availability5"
                value="followers"
                checked={formData.whoCanApply === "followers"}
                onChange={handleChange}
              />
              <label className="form-check-label ml-5" htmlFor="availability5">
                Page followers
              </label>
            </div>
          </div>

          <button className="mt-5 btn" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default JobSettings;
