export const businessCategories = [
    "Accountant",
    "Acupuncture",
    "Advertising/Marketing",
    "Agricultural Service",
    "Agriculture",
    "Agency",
    "Airport",
    "Airport Shuttle Service",
    "Alternative & Holistic Health Service",
    "Apartment & Condo Building",
    "Appliances",
    "Arts & Crafts Store",
    "Arts & Entertainment",
    "Asian Fusion Restaurant",
    "Athlete",
    "ATV Dealership",
    "Auction House",
    "Audiologist",
    "Auto Body Shop",
    "Auto Detailing Service",
    "Auto Glass Service",
    "Auto Parts & Accessories",
    "Auto Rental",
    "Auto Repair Shop",
    "Automated Teller Machine (ATM)",
    "Automation Service",
    "Automotive Consultant",
    "Automotive Customization Shop",
    "Automotive Manufacturer",
    "Automotive Wholesaler",
    "Automotive Window Tinting Service",
    "Aviation School",
    "Baby & Children's Clothing Store",
    "Baby Goods/Kids Goods",
    "Bagel Shop",
    "Bags/Luggage",
    "Bail Bondsmen",
    "Bakery",
    "Bank",
    "Bank/Financial Institution",
    "Bankruptcy Lawyer",
    "Bar",
    "Bar & Grill",
    "Barbecue Restaurant",
    "Barber Shop",
    "Bartending Service",
    "Bathroom Supply Store",
    "Beach",
    "Beauty",
    "Beauty Salon",
    "Beauty Store",
    "Bed and Breakfast",
    "Beer Garden",
    "Beer, Wine & Spirits Store",
    "Bike Rental",
    "Bike Shop",
    "Biotechnology Company",
    "Blinds & Curtains Store",
    "Boat Dealership",
    "Boat Rental",
    "Boat Service",
    "Boat Tour Agency",
    "Book",
    "Book & Magazine Distributor",
    "Bookstore",
    "Botanical Garden",
    "Bottled Water Company",
    "Boutique Store",
    "Bowling Alley",
    "Boxing Studio",
    "Brand",
    "Brazilian Restaurant",
    "Breakfast & Brunch Restaurant",
    "Brewery",
    "Bridal Shop",
    "Bridge",
    "British Restaurant",
    "Broadcasting & Media Production Company",
    "Brokerage Firm",
    "Bubble Tea Shop",
    "Buffet Restaurant",
    "Building Material Store",
    "Building Materials",
    "Building Supply Store",
    "Burger Restaurant",
    "Bus Line",
    "Business",
    "Business & Economy Website",
    "Business Consultant",
    "Business Service",
    "Business Supply Service",
    "Cabinet & Countertop Store",
    "Cable & Satellite Company",
    "Cafe",
    "Cajun & Creole Restaurant",
    "Camera Store",
    "Camera/Photo",
    "Campground",
    "Candy Store",
    "Car Dealership",
    "Car Rental",
    "Car Stereo Store",
    "Car Wash",
    "Career Counselor",
    "Cargo & Freight Company",
    "Caribbean Restaurant",
    "Carpenter",
    "Carpet & Flooring Store",
    "Carpet Cleaner",
    "Casino",
    "Caterer",
    "Cause",
    "Cemetery",
    "Charity Organization",
    "Charter Bus Service",
    "Cheese Shop",
    "Chef",
    "Chemical Company",
    "Chicken Joint",
    "Child Care Service",
    "Child Protective Service",
    "Children's Museum",
    "Chinese Restaurant",
    "Chiropractor",
    "Chocolate Shop",
    "Church",
    "Church of Christ",
    "Circus",
    "City",
    "Cleaning Service",
    "Clinic",
    "Clothing (Brand)",
    "Clothing Store",
    "Coach",
    "Coffee Shop",
    "Collectibles Store",
    "College & University",
    "Comedian",
    "Comedy Club",
    "Comfort Food Restaurant",
    "Comic Bookstore",
    "Commercial & Industrial",
    "Commercial & Industrial Equipment Supplier",
    "Commercial Bank",
    "Commercial Equipment",
    "Commercial Real Estate Agency",
    "Commercial Truck Dealership",
    "Commercial Truck Repair Shop",
    "Community",
    "Community Center",
    "Community College",
    "Community Museum",
    "Community Organization",
    "Company",
    "Computer Company",
    "Computer Repair Service",
    "Computer Store",
    "Computer Training School",
    "Computers & Internet Website",
    "Concert Tour",
    "Concrete Contractor",
    "Construction Company",
    "Consulate & Embassy",
    "Consulting Agency",
    "Contractor",
    "Convenience Store",
    "Cooking School",
    "Copywriting Service",
    "Corporate Office",
    "Cosmetic Dentist",
    "Cosmetics Store",
    "Cosmetology School",
    "Costume Shop",
    "Counselor",
    "Country",
    "County",
    "Courier Service",
    "Court",
    "Crane Service",
    "Credit Counseling Service",
    "Credit Union",
    "Crêperie",
    "Criminal Lawyer",
    "Cruise Agency",
    "Cruise Line",
    "Cuban Restaurant",
    "Cultural Gifts Store",
    "Cupcake Shop",
    "Currency Exchange",
    "Customs Broker",
    "Cycling Studio",
    "DJ",
    "Dance & Night Club",
    "Dance School",
    "Dance Studio",
    "Dating Service",
    "Day Care",
    "Day Spa",
    "Deck & Patio Builder",
    "Deli",
    "Dentist & Dental Office",
    "Department Store",
    "Dermatologist",
    "Designer",
    "Diagnostic Center",
    "Dietician",
    "Digital/Online Marketing Agency",
    "Dim Sum Restaurant",
    "Diner",
    "Disc Golf Course",
    "Discount Store",
    "Distillery",
    "Dive Bar",
    "Diving Spot",
    "Divorce & Family Lawyer",
    "Doctor",
    "Documentary",
    "Dog Day Care Center",
    "Dog Trainer",
    "Dog Walker",
    "Domain Name Service",
    "Donut Shop",
    "Drafting Service",
    "Driving School",
    "Drug Addiction Treatment Center",
    "Dry Cleaner",
    "Dui Lawyer",
    "E-Cigarette Store",
    "E-commerce Website",
    "Editor",
    "Education",
    "Education Company",
    "Education Website",
    "Educational Consultant",
    "Educational Research Center",
    "Educational Supply Store",
    "Electrician",
    "Electronics",
    "Electronics Company",
    "Electronics Store",
    "Elementary School",
    "Elevator Service",
    "Emergency Rescue Service",
    "Employment Agency",
    "Endodontist",
    "Energy Company",
    "Engineering Service",
    "Entertainment",
    "Entertainment Website",
    "Entrepreneur",
    "Environmental Conservation Organization",
    "Environmental Consultant",
    "Environmental Service",
    "Episode",
    "Equestrian Center",
    "Escape Game Room",
    "Espresso Bar",
    "Estate Planning Lawyer",
    "Estate Agent",
    "European Restaurant",
    "Evangelical Church",
    "Event",
    "Event Planner",
    "Event Videographer",
    "Excavation Company",
    "Executive Coach",
    "Eye Care Center",
    "Eye Doctor",
    "Fabric Store",
    "Family Doctor",
    "Family Medicine Practice",
    "Family Style Restaurant",
    "Farm",
    "Farmers Market",
    "Fashion",
    "Fashion Company",
    "Fashion Designer",
    "Fashion Model",
    "Fast Food Restaurant",
    "Fence & Gate Contractor",
    "Fertility Doctor",
    "Festival",
    "Field of Study",
    "Filipino Restaurant",
    "Finance",
    "Finance Company",
    "Financial Aid Service",
    "Financial Consultant",
    "Financial Planner",
    "Financial Service",
    "Fire Protection Service",
    "Fireplace Store",
    "Fish & Chips Restaurant",
    "Fish Market",
    "Fishing Spot",
    "Fitness Boot Camp",
    "Fitness Model",
    "Fitness Trainer",
    "Flight School",
    "Florist",
    "Food & Beverage",
    "Food & Beverage Company",
    "Food Consultant",
    "Food Delivery Service",
    "Food Stand",
    "Food Truck",
    "Foodservice Distributor",
    "Forestry Service",
    "Franchising Service",
    "French Restaurant",
    "Fried Chicken Joint",
    "Frozen Yogurt Shop",
    "Fruit & Vegetable Store",
    "Furniture",
    "Furniture Repair & Upholstery Service",
    "Furniture Store",
    "Games/Toys",
    "Gaming Video Creator",
    "Garage Door Service",
    "Garden Center",
    "Gas Station",
    "Gastroenterologist",
    "Gay Bar",
    "Gelato Shop",
    "General Dentist",
    "General Hospital",
    "General Practice Lawyer",
    "General Surgery",
    "Geographical Place",
    "Geological Service",
    "Gift Shop",
    "Glass Service",
    "Golf Course & Country Club",
    "Golf Instructor",
    "Gourmet Shop",
    "Government Building",
    "Government Official",
    "Government Organization",
    "Government Website",
    "Graphic Designer",
    "Greek Restaurant",
    "Grocery Store",
    "Gun Store",
    "Gym/Physical Fitness Center",
    "Hair Extensions Service",
    "Hair Removal Service",
    "Hair Replacement Service",
    "Hair Salon",
    "Hairpieces & Extensions",
    "Halal Restaurant",
    "Handbag Designer",
    "Hardware Store",
    "Hat Store",
    "Hairstylist",
    "Hardware Store",
    "Health & Wellness Website",
    "Health Food Restaurant",
    "Health Food Store",
    "Health Spa",
    "Health/Beauty",
    "Healthcare Administrator",
    "Healthcare Clinic",
    "Healthcare Company",
    "Healthcare Distributor",
    "Heating, Ventilating & Air Conditioning Service",
    "High School",
    "Highway",
    "Hiking Trail",
    "Hindu Temple",
    "Historical Place",
    "Historical Tour Agency",
    "History Museum",
    "Hobby Store",
    "Hockey Field",
    "Holding Company",
    "Home",
    "Home & Garden Website",
    "Home & Garden Store",
    "Home Decor",
    "Home Goods Store",
    "Home Health Care Service",
    "Home Improvement",
    "Home Inspection Service",
    "Home Mover",
    "Home Security Company",
    "Home Staging Service",
    "Home Theater Store",
    "Home Window Service",
    "Homebrew Supply Store",
    "Hookah Lounge",
    "Horse Trainer",
    "Horseback Riding Center",
    "Hospital",
    "Hospitality Service",
    "Hostel",
    "Hot Dog Joint",
    "Hotel",
    "Hotel & Lodging",
    "Hotel Resort",
    "Hotel Services Company",
    "House Painting",
    "House Sitter",
    "Household Supplies",
    "Housing Assistance Service",
    "Housing & Homeless Shelter",
    "Hungarian Restaurant",
    "HR",
    "Human Resources Agency",
    "Ice Cream Shop",
    "Image Consultant",
    "Imaging & Photocopying",
    "Indian Restaurant",
    "Indo Chinese Restaurant",
    "Indonesian Restaurant",
    "Industrial Company",
    "Industrial Designer",
    "Information Technology Company",
    "Inn",
    "Insurance Agent",
    "Insurance Broker",
    "Insurance Company",
    "Intellectual Property Lawyer",
    "Interest",
    "Interior Design Studio",
    "Internet Cafe",
    "Internet Company",
    "Internet Marketing Service",
    "Internet Service Provider",
    "Internist (Internal Medicine)",
    "Investing Service",
    "Italian Restaurant",
    "Janitorial Service",
    "Japanese Restaurant",
    "Jazz & Blues Club",
    "Jet Ski Rental",
    "Jewelry & Watches Company",
    "Jewelry & Watches Store",
    "Jewelry Wholesaler",
    "Journalist",
    "Juvenile Law Attorney",
    "Karaoke",
    "Kennel",
    "Kidney Dialysis Center",
    "Kitchen & Bath Contractor",
    "Kitchen/Cooking",
    "Korean Restaurant",
    "Labor Union",
    "Landscape Architect",
    "Landscape Company",
    "Landscaping Supply Store",
    "Language School",
    "Laser Hair Removal Service",
    "Lasik/Laser Eye Surgeon",
    "Latin American Restaurant",
    "Law Enforcement Agency",
    "Lawyer & Law Firm",
    "Lebanese Restaurant",
    "Legal",
    "Legal Service",
    "Legal/Law",
    "Library",
    "Lifestyle Service",
    "Lifestyle Services",
    "Lighting Store",
    "Limousine Service",
    "Lingerie & Underwear Store",
    "Literary Editor",
    "Live Music Venue",
    "Loan Service",
    "Local & Travel Website",
    "Local Business",
    "Local Service",
    "Locksmith",
    "Lounge",
    "Luggage Service",
    "Magazine",
    "Magician",
    "Makeup Artist",
    "Malaysian Restaurant",
    "Management Service",
    "Marina",
    "Marine",
    "Marine Supply Store",
    "Market",
    "Marketing Agency",
    "Martial Arts School",
    "Masonry Contractor",
    "Massage Service",
    "Massage Therapist",
    "Maternity & Nursing Clothing Store",
    "Mattress Store",
    "Meat Wholesaler",
    "Media",
    "Media/News Company",
    "Medical & Health",
    "Medical Center",
    "Medical Company",
    "Medical Equipment Manufacturer",
    "Medical Equipment Supplier",
    "Medical Lab",
    "Medical Procedure",
    "Medical Service",
    "Medical Spa",
    "Medical Supply Store",
    "Meeting Room",
    "Men's Clothing Store",
    "Mental Health Service",
    "Middle Eastern Restaurant",
    "Middle School",
    "Military Base",
    "Military Branch",
    "Military Service",
    "Mining Company",
    "Miniature Golf Course",
    "Miscellaneous",
    "Mission",
    "Mobile Home Dealer",
    "Mobile Phone Shop",
    "Modeling Agency",
    "Modern European Restaurant",
    "Monarch",
    "Monument",
    "Moroccan Restaurant",
    "Mortgage Brokers",
    "Mosque",
    "Motel",
    "Motor Vehicle Company",
    "Motorcycle Dealership",
    "Motorcycle Manufacturer",
    "Motorcycle Repair Shop",
    "Motorcycle Shop",
    "Motorsports Store",
    "Mountain",
    "Mountain Biking Shop",
    "Mover",
    "Movie",
    "Movie & Music Store",
    "Movie Character",
    "Movie Theater",
    "Moving & Storage Service",
    "Museum",
    "Music",
    "Music Award",
    "Music Chart",
    "Music Lessons & Instruction School",
    "Music Production Studio",
    "Music Video",
    "Musical Genre",
    "Musical Instrument",
    "Musical Instrument Store",
    "Musician",
    "Musician/Band",
    "Nail Salon",
    "Nanny",
    "National Park",
    "Nature Preserve",
    "Naturopath",
    "Neighborhood",
    "Neurologist",
    "New American Restaurant",
    "News & Media Website",
    "News Personality",
    "Newspaper",
    "Night Market",
    "Nightlife",
    "Nondenominational Church",
    "Notary Public",
    "Nurseries & Gardening Store",
    "Nursing Agency",
    "Nutritionist",
    "OB-GYN",
    "Observatory",
    "Occupational Safety and Health Service",
    "Occupational Therapist",
    "Ocean",
    "Office Equipment Store",
    "Office Supplies",
    "Opera House",
    "Ophthalmologist",
    "Optician",
    "Optometrist",
    "Oral Surgeon",
    "Orchestra",
    "Organization",
    "Orthodontist",
    "Orthopedic Surgeon",
    "Outdoor & Sporting Goods Company",
    "Outdoor Equipment Store",
    "Outdoor Recreation",
    "Outlet Store",
    "Paddleboarding Center",
    "Paintball Center",
    "Pakistani Restaurant",
    "Pantry",
    "Park",
    "Party Entertainment Service",
    "Party Supply & Rental Shop",
    "Passport & Visa Service",
    "Patrol & Security",
    "Paving & Asphalt Service",
    "Pawn Shop",
    "Pediatrician",
    "Pentecostal Church",
    "Performance & Event Venue",
    "Performance Art",
    "Performance Art Theatre",
    "Performance Venue",
    "Periodontist",
    "Personal Blog",
    "Personal Chef",
    "Personal Coach",
    "Personal Injury Lawyer",
    "Personal Trainer",
    "Personal Website",
    "Peruvian Restaurant",
    "Pest Control Service",
    "Pet",
    "Pet Adoption Service",
    "Pet Breeder",
    "Pet Cafe",
    "Pet Groomer",
    "Pet Service",
    "Pet Sitter",
    "Pet Store",
    "Pet Supplies",
    "Petroleum Service",
    "Pharmaceutical Company",
    "Pharmacy / Drugstore",
    "Pho Restaurant",
    "Phone/Tablet",
    "Photographer",
    "Photography Museum",
    "Photography Videography",
    "Physical Fitness",
    "Physical Therapist",
    "Physical Therapy",
    "Physical Therapy Clinic",
    "Piano",
    "Piano Bar",
    "Picnic Ground",
    "Pizza Place",
    "Planetarium",
    "Plastic Company",
    "Plastic Manufacturer",
    "Plastic Surgeon",
    "Playground",
    "Plumber",
    "Podiatrist",
    "Police Station",
    "Polish Restaurant",
    "Political Candidate",
    "Political Organization",
    "Political Party",
    "Politician",
    "Polling Station",
    "Pool & Billiard Hall",
    "Pop-Up Shop",
    "Portable Building Service",
    "Portuguese Restaurant",
    "Post Office",
    "Pregnancy Care Center",
    "Preschool",
    "Printing Service",
    "Private Investigator",
    "Private Members Club",
    "Private Plane Charter",
    "Private School",
    "Proctologist",
    "Producer",
    "Product/Service",
    "Professional Service",
    "Professional Sports Team",
    "Promenade",
    "Property",
    "Property Developer",
    "Property Investment Firm",
    "Property Management Company",
    "Psychiatric Hospital",
    "Psychotherapist",
    "Pub",
    "Public & Government Service",
    "Public Figure",
    "Public Relations Agency",
    "Public School",
    "Public Service",
    "Public Swimming Pool",
    "Public Toilet",
    "Publisher",
    "Race Track",
    "Radio Station",
    "Rafting/Kayaking Center",
    "Railroad Company",
    "Railroad Industry",
    "Ramen Restaurant",
    "Real Estate",
    "Real Estate Agent",
    "Real Estate Appraiser",
    "Real Estate Company",
    "Real Estate Developer",
    "Real Estate Investment Firm",
    "Real Estate Lawyer",
    "Real Estate Service",
    "Real Estate Title & Development",
    "Record Label",
    "Recreation & Sports Website",
    "Recreation Center",
    "Recreation Spot",
    "Recreational Vehicle Dealership",
    "Recruiter",
    "Recycling & Waste Management",
    "Recycling Center",
    "Reflexologist",
    "Refrigeration Service",
    "Regional Website",
    "Religious Bookstore",
    "Religious Center",
    "Religious Organization",
    "Rental Shop",
    "Reproductive Service",
    "Residence",
    "Residence Inn",
    "Residential Building (Apartment / Condo)",
    "Resort",
    "Restaurant",
    "Restaurant Supply Store",
    "Restaurant Wholesaler",
    "Retail Company",
    "Recruitment Company",
    "Recruitment Agency",
    "Retirement & Assisted Living Facility",
    "Rideshare Service",
    "Rock Climbing Gym",
    "Rodeo",
    "Roller Skating Rink",
    "Roofing Service",
    "Rugby Pitch",
    "RV Park",
    "RV Repair Shop",
    "Race Track",
    "Sake Bar",
    "Salad Bar",
    "Salon",
    "Sandwich Shop",
    "Sauna",
    "School",
    "School Fundraiser",
    "School Sports Team",
    "Science",
    "Science Museum",
    "Science Website",
    "Scientist",
    "Scooter Rental",
    "Screen Printing & Embroidery",
    "Scuba Diving Center",
    "Seafood Restaurant",
    "Seasonal Store",
    "Secretarial Service",
    "Security Guard Service",
    "Security Service",
    "Self-Catering Accommodation",
    "Self-Storage Facility",
    "Senior Center",
    "Septic Tank Service",
    "Service Apartments",
    "Sewer Service",
    "Sewing & Alterations",
    "Sex Therapist",
    "Shaved Ice Shop",
    "Shipping Supply & Service",
    "Shopping & Retail",
    "Shopping District",
    "Shopping Mall",
    "Shopping Service",
    "Shopping & Retail",
    "Show",
    "Sightseeing Tour Agency",
    "Signs & Banner Service",
    "Singaporean Restaurant",
    "Skateboard Park",
    "Ski & Snowboard School",
    "Ski Resort",
    "Skin Care Service",
    "Skydiving Center",
    "Sledding Spot",
    "Small Business",
    "Smoothie & Juice Bar",
    "Soccer Field",
    "Soccer Stadium",
    "Social Club",
    "Social Media Agency",
    "Social Service",
    "Society & Culture Website",
    "Software",
    "Software Company",
    "Solar Energy Company",
    "Solar Energy Equipment Supplier",
    "Song",
    "Sorority & Fraternity",
    "Soul Food Restaurant",
    "Soup Restaurant",
    "South American Restaurant",
    "Southern Restaurant",
    "Souvenir Shop",
    "Spanish Restaurant",
    "Specialty Grocery Store",
    "Specialty School",
    "Speech Pathologist",
    "Sport",
    "Sporting Goods Store",
    "Sports",
    "Sports & Fitness Instruction",
    "Sports & Recreation",
    "Sports Bar",
    "Sports Club",
    "Sports Event",
    "Sports League",
    "Sports Team",
    "Sportswear Store",
    "Stadium, Arena & Sports Venue",
    "State",
    "State Park",
    "Statue & Fountain",
    "Steakhouse",
    "Storage Facility",
    "Structural Engineer",
    "Subway Station",
    "Sugar Bush",
    "Summer Camp",
    "Supermarket",
    "Surf Shop",
    "Surveyor",
    "Sushi Restaurant",
    "Swimming Instructor",
    "Swimming Pool & Hot Tub Service",
    "Swimming Pool Cleaner",
    "Swimwear Store",
    "Symphony",
    "Synagogue",
    "TV Channel",
    "TV Network",
    "TV Show",
    "TV/Movie Award",
    "TV/Movie Award",
    "Tabloid",
    "Tanning Salon",
    "Tapas Bar & Restaurant",
    "Tattoo & Piercing Shop",
    "Tattoo Removal Service",
    "Tax Preparation Service",
    "Taxi Service",
    "Taxidermist",
    "Tea Room",
    "Teacher",
    "Teens & Kids Website",
    "Teeth Whitening Service",
    "Telecommunication Company",
    "Telemarketing Service",
    "Tennis Court",
    "Tennis Instructor",
    "Tex-Mex Restaurant",
    "Thai Restaurant",
    "Theatrical Play",
    "Thrift & Consignment Store",
    "Ticket Sales",
    "Tiling Service",
    "Timber & Forest Company",
    "Tire Dealer & Repair Shop",
    "Tobacco Company",
    "Tobacco Store",
    "Towing Service",
    "Toy Store",
    "Trade School",
    "Trading Card Game",
    "Traffic School",
    "Trailer Dealership",
    "Train Station",
    "Transit Stop",
    "Transit System",
    "Translator",
    "Transportation",
    "Transportation Service",
    "Travel & Transportation",
    "Travel Agency",
    "Travel Company",
    "Travel Service",
    "Tree Cutting Service",
    "Tree Service",
    "Trophies & Engraving Shop",
    "Tutor/Teacher",
    "Tutoring Center",
    "Upholstery Service",
    "Urban Farm",
    "Urologist",
    "Vacation Home Rental",
    "Vegetarian/Vegan Restaurant",
    "Veterinarian",
    "Video",
    "Video Game",
    "Video Game Store",
    "Vietnamese Restaurant",
    "Vintage Store",
    "Visual Arts",
    "Vitamins/Supplements",
    "Waste Management Company",
    "Water Heater Installation & Repair Service",
    "Water Park",
    "Water Treatment Service",
    "Water Utility Company",
    "Waxing Service",
    "Web Designer",
    "Wedding Planning Service",
    "Wedding Venue",
    "Wholesale & Supply Store",
    "Wig Store",
    "Wildlife Sanctuary",
    "Window Installation Service",
    "Wine Bar",
    "Wine, Beer & Spirits Store",
    "Wine/Spirits",
    "Winery/Vineyard",
    "Women's Clothing Store",
    "Work Position",
    "Workplace & Office",
    "Writer",
    "Yoga Studio",
    "Youth Organization",
    "Zoo",
    "Zoo/Aquarium"]; 
