import axios from "axios";
import React, { useState, useEffect } from "react";
import app from "../../config/app";
import CopyRight from "../components/CopyRight";
import {
  savePrivacySettingsToIndexedDB,
  getPrivacySettingsFromIndexedDB,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function Privacy({ local }) {
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [formData, setFormData] = useState({
    profileVisibility: "",
    profileImageUpdate: "",
    coverImageUpdate: "",
    onlineStatusPosts: "",
    onlineStatusChats: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const savedSettings = await getPrivacySettingsFromIndexedDB();
      if (savedSettings) {
        setFormData(savedSettings);
      }
    };
    fetchSettings();
  }, [local]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    const responce = await savePrivacySettingsToIndexedDB(formData);
    updateLocalStorage({ type: "updateProf", data: formData });
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  return (
    <div className="p-7">
      <form onSubmit={handleSubmit}>
        <div className="text-xl font-semibold">Account privacy</div>
        <div className="form-group mt-6 rounded-2xl p-6 border-1">
          <div>
            <h3>Profile visibility on search results</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="profileVisibility"
              id="profileVisibilityShow"
              value="on"
              checked={formData.profileVisibility === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="profileVisibilityShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="profileVisibility"
              id="profileVisibilityHide"
              value="off"
              checked={formData.profileVisibility === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="profileVisibilityHide"
            >
              Hide
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Publish profile image update</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="profileImageUpdate"
              id="profileImageUpdateOn"
              value="on"
              checked={formData.profileImageUpdate === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="profileImageUpdateOn"
            >
              On
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="profileImageUpdate"
              id="profileImageUpdateOff"
              value="off"
              checked={formData.profileImageUpdate === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="profileImageUpdateOff"
            >
              Off
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Publish cover image update</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="coverImageUpdate"
              id="coverImageUpdateOn"
              value="on"
              checked={formData.coverImageUpdate === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="coverImageUpdateOn"
            >
              On
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="coverImageUpdate"
              id="coverImageUpdateOff"
              value="off"
              checked={formData.coverImageUpdate === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="coverImageUpdateOff"
            >
              Off
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Online status on posts</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="onlineStatusPosts"
              id="onlineStatusPostsShow"
              value="on"
              checked={formData.onlineStatusPosts === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="onlineStatusPostsShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="onlineStatusPosts"
              id="onlineStatusPostsHide"
              value="off"
              checked={formData.onlineStatusPosts === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="onlineStatusPostsHide"
            >
              Hide
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Online status on chats</h3>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="onlineStatusChats"
              id="onlineStatusChatsShow"
              value="on"
              checked={formData.onlineStatusChats === "on"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="onlineStatusChatsShow"
            >
              Show
            </label>
          </div>
          <div className="form-check p-0 mt-4 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="onlineStatusChats"
              id="onlineStatusChatsHide"
              value="off"
              checked={formData.onlineStatusChats === "off"}
              onChange={handleChange}
            />
            <label
              className="form-check-label ml-5"
              htmlFor="onlineStatusChatsHide"
            >
              Hide
            </label>
          </div>
        </div>

        <button className="mt-16 btn" type="submit">
          Submit
        </button>
      </form>
      <div className="mt-10">
        <hr className="line" />
        <CopyRight />
        {showResponceCard && responceCard}
      </div>
    </div>
  );
}

export default Privacy;
