import React, { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "../components/Loader";
import { getDiscoverFollowList } from "../../db";
import UsersSlide from "../components/UsersSlide";

export default function AuthFollowPeople() {
  const [open, setOpen] = useState(true);
  const [user, setUser] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [close, setClose] = useState(true);
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [followList, setFollowList] = useState([]);

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const sysUser = Cookies.get("sys_user");
    setUser(sysUser);
    fetchPagesListData();
  }, []);

  const finishFollowing = () => {
    // redirect to home
    if (followList.length > 0) {
      setTimeout(() => {
        window.location = "/";
      }, 10);
    }
  };

  const onFollow = (user) => {
    // add to follow list
    setFollowList((prevUsers) => [...prevUsers, ...user]);
  };

  const onUnFollow = (user) => {
    // remove from follow list
  };

  const fetchPagesListData = async () => {
    setLoading(true);
    try {
      const data = await getDiscoverFollowList(20, 0); // Ensure this returns the correct data structure
      // Append new pages to the list
      setUsersList((prevPages) => [...prevPages, ...(data.users || [])]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pages:", error);
      setLoading(false);
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  return (
    <Dialog className="relative z-10 auth_modal" open={open} onClose={setClose}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in modal_backdrop"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full p-4 justify-center sm:p-0 bg-white modal_container">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white pb-3 pt-4">
              <div className="sm:flex sm:items-start w-full">
                <div className="text-left w-full">
                  <div className=" text-center flex align-center justify-between gap-10 px-5 pr-2">
                    <div className="font-bold text-xl">
                      Start following someone
                    </div>
                    <Link
                      className="font-semibold text-normal opacity-90 hover rounded-full px-3 py-1 flex align-center gap-2"
                      to={`/discover/people`}
                    >
                      <span>See more</span>
                      <span class="material-symbols-outlined">east</span>
                    </Link>
                  </div>
                  {loading && (
                    <div className="absolute w-full flex align-center justify-center h-full top-0 auth_loader_cont">
                      <Loader />
                    </div>
                  )}

                  <div className="grid gap-3 p-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2">
                    {usersList.map((item, index) => (
                      <UsersSlide
                        data={item}
                        allowLink={false}
                        onFollow={onFollow}
                        onUnFollow={onUnFollow}
                      />
                    ))}
                  </div>
                  {/* start following here */}
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
      <div className="footer_details_auth bg-white" style={{ zIndex: 9999 }}>
        <button
          type="button"
          onClick={finishFollowing}
          className={`inline-flex justify-center rounded-full bg-black px-12 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 w-auto ${
            followList.length == 0 && "opacity-70 hover:bg-black"
          }`}
        >
          Finish
        </button>
      </div>
    </Dialog>
  );
}
