import React from 'react'
import HubSlide from '../components/HubSlide'
import { Link } from 'react-router-dom'

function BooksMarkHub(){
  return (
    <div className='overflow-hidden'>
        <div className='flex align-center justify-between mb-3'>
          <div className='text-xl font-bold'>Saved Hubs</div>
        </div>
        <div className='auto_fit flex flex-wrap align-center gap-4 py-5 align-center justify-center last-child:justify-self-start'>
          <HubSlide/>
          <HubSlide/>
          <HubSlide/>
          <HubSlide/>
          <HubSlide/>
          <HubSlide/>
          <HubSlide/>
        </div>
    </div>
  )
}

export default BooksMarkHub