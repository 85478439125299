import React, { useEffect, useState } from "react";
import BasicJobInfo from "./ManageJobDeatils/BasicJobInfo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skills from "./ManageJobDeatils/Skills";
import MoreInfo from "./ManageJobDeatils/MoreInfo";

function CreateJob({ local, business }) {
  const navigate = useNavigate();
  let { id, page, focus, focus_id } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;

  if (focus) {
    if (focus === "basicjobinfo") {
      component = <BasicJobInfo local={local} />;
    } else if (focus === "skills") {
      component = <Skills local={local} />;
    } else if (focus === "moreinfo") {
      component = <MoreInfo local={local} />;
    }
  } else {
    component = <BasicJobInfo local={local} />;
  }

  const getTabClassName = (tabName) => {
    return focus === tabName
      ? "hover w-auto px-4 rounded-full py-2 border-none bg-gray-100 active"
      : "hover w-auto px-4 rounded-full py-2 border-1";
  };

  return (
    <div>
      <div>
        <div className="text-xl font-semibold py-3">
          {!focus_id ? "Create new job" : "Edit job"}
        </div>
        <div className="wizard w-full flex align-center justify-start mt-5 gap-3 sm-mt-2 no-wrap sm-p-0">
          <div
            onClick={() =>
              focus_id
                ? navigate(`/admin/${id}/createjob/basicjobinfo/${focus_id}`)
                : null
            }
            className={getTabClassName("basicjobinfo")}
          >
            <div className="inn_title">Basic info</div>
          </div>
          <div
            onClick={() =>
              focus_id
                ? navigate(`/admin/${id}/createjob/skills/${focus_id}`)
                : null
            }
            className={
              focus_id
                ? getTabClassName("skills")
                : "w-auto px-4 rounded-full py-2 border-1 disabled text-mute"
            }
          >
            <div className="inn_title">Skills required</div>
          </div>
          <div
            onClick={() =>
              focus_id
                ? navigate(`/admin/${id}/createjob/moreinfo/${focus_id}`)
                : null
            }
            className={
              focus_id
                ? getTabClassName("moreinfo")
                : "w-auto px-4 rounded-full py-2 border-1 disabled text-mute"
            }
          >
            <div className="inn_title">More info</div>
          </div>
        </div>
      </div>
      {component}
    </div>
  );
}

export default CreateJob;
