import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatRelativeTime } from "../../db";

function Comment({ data, local }) {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const updateRelativeTime = () => {
      const timeElapsed = Date.now() - data.row.date_time * 1000; // assuming date_time is in seconds
      setTimeAgo(formatRelativeTime(timeElapsed));
    };

    updateRelativeTime();
    const intervalId = setInterval(updateRelativeTime, 1000); // update every second

    return () => clearInterval(intervalId); // cleanup on unmount
  }, [data]);

  return (
    <div className="rounded-xl my-0 hover py-0">
      <div className="rounded-xl">
        <div className="flex my-0 px-2 animate__animated animate__slideInUp animate_fast py-2 pb-0 rounded-xl">
          <Link
            to={`/profile/${data.user_profile[0].username}`}
            className="h-8 min-w-8 bg-gray-200 rounded-full"
          >
            <img
              src={data.user_profile[0].profile_image}
              className="h-8 min-w-8 min-w-8 w-8 rounded-full object-cover"
            />
          </Link>
          <div className="w-full px-3">
            <Link
              to={`/profile/${data.user_profile[0].username}`}
              className="w-full rounded-full flex align-center justify-between"
            >
              <div className="">{data.user_profile[0].fullname}</div>
              <div className="text-sm">{timeAgo}</div>
            </Link>
            <div className="-mt-1 rounded-full text-mute">
              {data.user_profile[0].username}
            </div>
            <div className="flex gap-3 animate__animated animate__slideInUp animate_fast">
              <div className="h-auto w-full rounded-xl py-0">
                {data.row.comment}
              </div>
            </div>
            <div className="flex align-center gap-3 py-1">
              {/* <div className="flex align-center gap-2 -ml-1 text-sm hover rounded-full justify-center px-1 min-6">
                <div className="">10</div>
                <div className="">Reply</div>
              </div> */}
              {/* <div className="flex align-center gap-2 text-sm hover rounded-full justify-center px-1 min-6 -ml-1">
                <div className="">10</div>
                <div className="">Likes</div>
              </div> */}
            </div>
            <hr className="line_sm" style={{ margin: 0, marginTop: 0 }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comment;
