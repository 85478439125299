import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { searchPages } from "../../db";
import Loader from "../components/Loader";

export default function SelectPages({
  onCloseModal,
  onSelectPage,
  submitTitle = "Submit",
}) {
  const [open, setOpen] = useState(true);
  const [searchPage, setSearchPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]); // State for selected users (chatid + user_id)
  const [selectAll, setSelectAll] = useState(false); // State for "Select All"
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    searchRemotePages("");
  }, []);

  const searchRemotePages = async (text) => {
    setLoading(true); // Show loading while fetching data
    const data = await searchPages(text, 30, 0);
    console.log(data.pages);
    setSearchPages(data.pages);
    setLoading(false); // Hide loading once data is fetched
  };

  // Close modal and call onCloseModal
  const handleClose = () => {
    setOpen(false); // Close the modal
    if (onCloseModal) {
      onCloseModal(); // Call the passed onCloseModal function
    }
  };

  const clickedSubmit = () => {
    console.log("Selected users (chatid + user_id):", selectedPage);
    setLoading(true);
    onSelectPage(selectedPage);
    setSelectedPage([]);
    setSelectAll(false);
    setLoading(false);
    handleClose();
  };

  const pageSelect = (hub) => {
    onSelectPage(hub);
    handleClose();
  };

  return (
    <Dialog
      className="relative z-10"
      open={open}
      onClose={handleClose} // Use the handleClose function
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in modal_backdrop"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm-p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 animate__animated animate__zoomIn modal_animated modal_content"
          >
            <div className="bg-white pb-3 pt-4 sm:pb-4">
              <div className="sm:flex sm:items-start w-full">
                <div className="text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900 mb-0 border-bottom p-3 flex align-center justify-between"
                  >
                    <div className="flex-1 pr-3">
                      <input
                        id="search_text"
                        name="search_text"
                        type="search_text"
                        autoComplete="search_text"
                        placeholder="Search for pages"
                        className="bg-gray-100 w-full py-3 rounded-full px-5 outline-none"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          searchRemotePages(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className="w-10 h-10 rounded-full bg-gray-100 hover flex align-center justify-center"
                      onClick={handleClose}
                    >
                      <span className="material-icons-round">close</span>
                    </div>
                  </DialogTitle>
                  {/* list */}

                  <div className="flex-1 allow_overflow_list">
                    {loading && (
                      <div className="w-full flex align-center justify-center h-full min-h-72 mt-24">
                        <Loader />
                      </div>
                    )}

                    {searchPage.length > 0 &&
                      !loading &&
                      searchPage.map((page, index) => (
                        <div
                          key={index}
                          onClick={() => pageSelect(page)}
                          className={`flex align-center px-2 gap-1 hover py-2 my-1 mx-2 rounded-xl overflow-hidden inbox_item`}
                        >
                          <div className="flex flex-1">
                            <div className="h-12 min-w-12 rounded-full">
                              <img
                                src={page.profile_image}
                                className="h-12 min-w-12 rounded-full object-cover"
                              />
                            </div>
                            <div className="w-full px-3 flex-1">
                              <div className="w-full rounded-full">
                                {page.business_name}
                              </div>
                              <div className="w-1/3 rounded-full text-sm">
                                @{page.username}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
