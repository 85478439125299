import axios from "axios";
import React, { useState, useEffect } from "react";
import app from "../../config/app";
import CopyRight from "../components/CopyRight";
import {
  saveProfessionalSettingsToIndexedDB,
  getProfessionalSettingsFromIndexedDB,
  updateLocalStorage,
} from "../../db";
import Responce from "../components/Responce";

function Professional({ local }) {
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [formData, setFormData] = useState({
    availabilityStatus: "",
    downloadMyCv: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      const savedSettings = await getProfessionalSettingsFromIndexedDB();
      if (savedSettings) {
        setFormData(savedSettings);
      }
    };
    fetchSettings();
  }, [local]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    const responce = await saveProfessionalSettingsToIndexedDB(formData);
    updateLocalStorage({ type: "updateProf", data: formData });
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
  };

  return (
    <div className="p-7">
      <form onSubmit={handleSubmit}>
        <div className="text-xl font-semibold">Professional settings</div>
        <div className="form-group mt-6 rounded-2xl p-6 border-1">
          <div>
            <h3>Availability status</h3>
          </div>
          <div className="form-check p-0 mt-5 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="availabilityStatus"
              id="availability1"
              value="available"
              checked={formData.availabilityStatus === "available"}
              onChange={handleChange}
            />
            <label className="form-check-label ml-5" htmlFor="availability1">
              Available for work
            </label>
          </div>
          <div className="form-check p-0 mt-5 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="availabilityStatus"
              id="availability2"
              value="notAvailable"
              checked={formData.availabilityStatus === "notAvailable"}
              onChange={handleChange}
            />
            <label className="form-check-label ml-5" htmlFor="availability2">
              Not available
            </label>
          </div>
        </div>

        <div className="form-group mt-3 rounded-2xl p-6 border-1">
          <div>
            <h3>Who can download your CV?</h3>
          </div>
          <div className="form-check p-0 mt-5 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="downloadMyCv"
              id="availability4"
              value="private"
              checked={formData.downloadMyCv === "private"}
              onChange={handleChange}
            />
            <label className="form-check-label ml-5" htmlFor="availability4">
              Private download only
            </label>
          </div>
          <div className="form-check p-0 mt-5 flex align-center">
            <input
              className="form-check-input -ml-1"
              type="radio"
              name="downloadMyCv"
              id="availability5"
              value="appliedJobs"
              checked={formData.downloadMyCv === "appliedJobs"}
              onChange={handleChange}
            />
            <label className="form-check-label ml-5" htmlFor="availability5">
              Where I have applied for a Job
            </label>
          </div>
        </div>

        <button className="mt-16 btn" type="submit">
          Submit
        </button>
      </form>
      <div className="mt-10">
        <hr className="line" />
        <CopyRight />
      </div>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Professional;
