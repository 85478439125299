import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { openDB } from "idb";

// Initialize IndexedDB
const initDB = async () => {
  return openDB("skills-db", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("skills")) {
        db.createObjectStore("skills", { keyPath: "id", autoIncrement: true });
      }
    },
  });
};

const fetchSkillsFromDB = async () => {
  const db = await initDB();
  const tx = db.transaction("skills", "readonly");
  const store = tx.objectStore("skills");
  const savedSkills = await store.getAll();
  return savedSkills.map((item) => item.skill);
};

function MySkills() {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const loadSkills = async () => {
      const savedSkills = await fetchSkillsFromDB();
      setSkills(savedSkills);
    };

    loadSkills();
  }, []);

  return (
    <div className="p-7">
      <div className="py-2 pb-0 sticky tags_filter">
        <div className="lg:flex lg:items-center lg:justify-between align-center">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-bold leading-4 sm:truncate sm:text-2xl sm:tracking-tight">
              My professional skills
            </h2>
          </div>

          <div className="mt-5 flex lg:ml-4 lg:mt-0">
            <Link
              to="/Professional/manageskills"
              className="ml-3 hidden sm:block"
            >
              <button
                type="button"
                className="inline-flex items-center rounded-full bg-white px-3 h-10 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 px-8"
              >
                Edit
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* List all my skills here */}
      <div className="mt-4">
        {skills.length === 0 ? (
          <div>No skills found. Please add your skills.</div>
        ) : (
          <div className="flex flex-wrap">
            {skills.map((skill, index) => (
              <>
                {skill != "" && (
                  <div
                    key={index}
                    className="w-auto h-8 px-4 m-1 rounded-full flex items-center justify-center bg-blue-500 text-white"
                  >
                    {skill}
                  </div>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MySkills;
