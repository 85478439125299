import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { saveUserMetaIndexedDB, searchFriends } from "../../db";
import Loader from "../components/Loader";

export default function SelectUsers({
  onCloseModal,
  onSelectUser,
  submitTitle = "Submit",
}) {
  const [open, setOpen] = useState(true);
  const [usersSearchProfile, setUsersSearchProfile] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // State for selected users (chatid + user_id)
  const [selectAll, setSelectAll] = useState(false); // State for "Select All"
  const [searchPeopleText, setSearchPeopleText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    searchRemoteUsers("");
  }, []);

  const searchRemoteUsers = async (text) => {
    setLoading(true); // Show loading while fetching data
    const data = await searchFriends(text);
    setUsersSearchProfile(data.users);
    // Save user in meta database for quick access
    data.users.forEach((user) => {
      if (user.user_info && user.user_info[0]) {
        saveMeta(user.user_info[0]);
      }
    });
    setLoading(false); // Hide loading once data is fetched
  };

  const saveMeta = async (profile) => {
    await saveUserMetaIndexedDB(profile);
  };

  // Handle closing the modal
  const handleClose = () => {
    setOpen(false);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  // Toggle user selection based on chatid and user_id
  const toggleUserSelection = (user) => {
    const chatId = user.chatid;
    const userId = user.user_info[0].user_id;
    const isSelected = selectedUsers.some(
      (selectedUser) => selectedUser.chatid === chatId
    );

    if (isSelected) {
      // Remove user from selected
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.chatid !== chatId)
      );
    } else {
      // Add user to selected (chatid + user_id)
      setSelectedUsers([...selectedUsers, { chatid: chatId, user_id: userId }]);
    }
  };

  // Toggle Select All functionality
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]); // Deselect all
    } else {
      setSelectedUsers(
        usersSearchProfile.map((user) => ({
          chatid: user.chatid,
          user_id: user.user_info[0].user_id,
        }))
      ); // Select all users with chatid and user_id
    }
    setSelectAll(!selectAll);
  };

  const clickedSubmit = () => {
    console.log("Selected users (chatid + user_id):", selectedUsers);
    setLoading(true);
    onSelectUser(selectedUsers); // Pass selected users with chatid + user_id
    setSelectedUsers([]);
    setSelectAll(false);
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog className="relative z-10" open={open} onClose={handleClose}>
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-0 pb-3 sm:pb-4 min_height_popup">
              <div className="sm:flex sm:items-start w-full">
                <div className="text-left w-full flex flex-col">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900 mb-0 border-bottom p-3 flex align-center justify-between"
                  >
                    <div className="flex-1 pr-3">
                      <input
                        id="search_text"
                        name="search_text"
                        type="search_text"
                        autoComplete="search_text"
                        placeholder="Search for friends"
                        className="bg-gray-100 w-full py-3 rounded-full px-5 outline-none font-semibold"
                        value={searchPeopleText}
                        onChange={(e) => {
                          setSearchPeopleText(e.target.value);
                          searchRemoteUsers(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className="w-10 h-10 rounded-full bg-gray-100 hover flex align-center justify-center"
                      onClick={handleClose}
                    >
                      <span className="material-icons-round">close</span>
                    </div>
                  </DialogTitle>

                  <div className="flex-1 allow_overflow_list">
                    <div className="flex items-center justify-between p-3">
                      <span className="text-sm font-semibold">
                        Selected Users ({selectedUsers.length})
                      </span>
                      <button
                        className="text-blue-500 text-sm font-semibold"
                        onClick={toggleSelectAll}
                      >
                        {selectAll ? "Deselect All" : "Select All"}
                      </button>
                    </div>

                    {usersSearchProfile.length === 0 && !loading && (
                      <div className="flex align-center justify-center flex-col">
                        <img
                          src="/images/coworking-woman-doing-online-shopping-at-home.gif"
                          className="w-56 object-cover"
                        />
                        <div className="my-4 w-56 text-center">
                          Search for friends that you follow or follow you
                        </div>
                      </div>
                    )}

                    {loading && (
                      <div className="flex align-center justify-center flex-col py-28">
                        <Loader />
                      </div>
                    )}

                    {usersSearchProfile.length > 0 &&
                      !loading &&
                      usersSearchProfile.map((user, index) => {
                        if (!user || !user.user_info || !user.user_info[0])
                          return null; // Skip if user info is undefined
                        const userInfo = user.user_info[0];
                        const isSelected = selectedUsers.some(
                          (selectedUser) => selectedUser.chatid === user.chatid
                        ); // Check selection by chatid
                        return (
                          <div
                            key={index}
                            onClick={() => toggleUserSelection(user)} // Enable user box click
                            className={`flex align-center px-2 gap-1 hover py-2 my-1 mx-2 rounded-xl overflow-hidden inbox_item ${
                              isSelected
                                ? "bg-gray-100" // Highlight selected users
                                : ""
                            }`}
                          >
                            <div className="flex flex-1">
                              <div className="h-12 min-w-12 rounded-full">
                                <img
                                  src={userInfo.profile_image}
                                  className="h-12 min-w-12 rounded-full object-cover"
                                />
                              </div>
                              <div className="w-full px-3 flex-1">
                                <div className="w-full rounded-full">
                                  {userInfo.fullname}
                                </div>
                                <div className="w-1/3 rounded-full text-sm">
                                  @{userInfo.username}
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() => toggleUserSelection(user)}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="py-1 px-3 flex align-center justify-center">
                    <button
                      className={`btn flex-1 ${
                        selectedUsers.length > 0 ? "opacity-100" : "opacity-50"
                      }`}
                      disabled={selectedUsers.length === 0}
                      onClick={() => clickedSubmit()}
                    >
                      {submitTitle}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
