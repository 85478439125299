import React from "react";

function EmotionPicker() {
  const emoji_cat = [
    {
      image: "/images/emojis/smile.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "Smileys and emotions",
      icon_code: "1f600",
    },
    {
      image: "/images/emojis/animal.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "",
      icon_code: "1f995",
    },
    {
      image: "/images/emojis/food.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "",
      icon_code: "1f35c",
    },
    {
      image: "/images/emojis/rocket.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "",
      icon_code: "1f680",
    },
    {
      image: "/images/emojis/event.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "",
      icon_code: "1f388",
    },
    {
      image: "/images/emojis/object.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "",
      icon_code: "1f4b8",
    },
    {
      image: "/images/emojis/symbol.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "",
      icon_code: "264e",
    },
    {
      image: "/images/emojis/flag.webp",
      staticImage: "/images/emojis/smile.webp",
      category: "",
      icon_code: "1f3c1",
    },
  ];
  return (
    <div className="min-w-60">
      <div className="w-full flex align-center">
        <input
          className="w-full bg-gray-100 rounded-lg focus:outline-none px-4 h-10"
          placeholder="Search emotions"
        />
      </div>
      <div className="flex align-center gap-3 py-2 relative">
        {emoji_cat.map((item, index) => (
          <div className="w-8 h-8 single_emoji relative rounded-md smileys_people">
            <img src={item.image} className="w-10 h-10 object-contain" />
          </div>
        ))}
      </div>
      <div className="py-3 px-2"></div>
      <hr className="line" />
    </div>
  );
}

export default EmotionPicker;
