import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
export default function ReportVibe({ onCloseModal }) {
  const [open, setOpen] = useState(true);

  // Close modal and call onCloseModal
  const handleClose = () => {
    setOpen(false); // Close the modal
    if (onCloseModal) {
      onCloseModal(); // Call the passed onCloseModal function
    }
  };

  return (
    <Dialog
      className="relative z-10"
      open={open}
      onClose={handleClose} // Use the handleClose function
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in modal_backdrop"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 animate__animated animate__zoomIn modal_animated"
          >
            <div className="bg-white px-4 pb-3 pt-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start w-full">
                <div className="text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900 mb-6 border-bottom pb-5 flex align-center justify-between"
                  >
                    <div>Report vibe</div>
                    <div
                      className="w-10 h-10 rounded-full bg-gray-100 hover flex align-center justify-center"
                      onClick={() => handleClose()} // Call handleClose on click
                    >
                      <span class="material-icons-round">close</span>
                    </div>
                  </DialogTitle>
                  <div className="w-full">
                    <div className="mt-2">
                      <input
                        id="search_text"
                        name="search_text"
                        type="search_text"
                        autoComplete="search_text"
                        required
                        className="form-control"
                      />
                    </div>
                  </div>
                  {/* list */}
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
