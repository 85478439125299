export const countries = {
    'AD': {name: 'ANDORRA', code: '376', currency: 'Euro', symbol: '€'},
    'AE': {name: 'UNITED ARAB EMIRATES', code: '971', currency: 'United Arab Emirates Dirham', symbol: 'د.إ'},
    'AF': {name: 'AFGHANISTAN', code: '93', currency: 'Afghan Afghani', symbol: '؋'},
    'AG': {name: 'ANTIGUA AND BARBUDA', code: '1268', currency: 'East Caribbean Dollar', symbol: '$'},
    'AI': {name: 'ANGUILLA', code: '1264', currency: 'East Caribbean Dollar', symbol: '$'},
    'AL': {name: 'ALBANIA', code: '355', currency: 'Albanian Lek', symbol: 'L'},
    'AM': {name: 'ARMENIA', code: '374', currency: 'Armenian Dram', symbol: '֏'},
    'AN': {name: 'NETHERLANDS ANTILLES', code: '599', currency: 'Netherlands Antillean Guilder', symbol: 'ƒ'},
    'AO': {name: 'ANGOLA', code: '244', currency: 'Angolan Kwanza', symbol: 'Kz'},
    'AQ': {name: 'ANTARCTICA', code: '672', currency: 'N/A', symbol: 'N/A'},
    'AR': {name: 'ARGENTINA', code: '54', currency: 'Argentine Peso', symbol: '$'},
    'AS': {name: 'AMERICAN SAMOA', code: '1684', currency: 'United States Dollar', symbol: '$'},
    'AT': {name: 'AUSTRIA', code: '43', currency: 'Euro', symbol: '€'},
    'AU': {name: 'AUSTRALIA', code: '61', currency: 'Australian Dollar', symbol: '$'},
    'AW': {name: 'ARUBA', code: '297', currency: 'Aruban Florin', symbol: 'ƒ'},
    'AZ': {name: 'AZERBAIJAN', code: '994', currency: 'Azerbaijani Manat', symbol: '₼'},
    'BA': {name: 'BOSNIA AND HERZEGOVINA', code: '387', currency: 'Bosnia and Herzegovina Convertible Mark', symbol: 'KM'},
    'BB': {name: 'BARBADOS', code: '1246', currency: 'Barbadian Dollar', symbol: '$'},
    'BD': {name: 'BANGLADESH', code: '880', currency: 'Bangladeshi Taka', symbol: '৳'},
    'BE': {name: 'BELGIUM', code: '32', currency: 'Euro', symbol: '€'},
    'BF': {name: 'BURKINA FASO', code: '226', currency: 'West African CFA Franc', symbol: 'CFA'},
    'BG': {name: 'BULGARIA', code: '359', currency: 'Bulgarian Lev', symbol: 'лв'},
    'BH': {name: 'BAHRAIN', code: '973', currency: 'Bahraini Dinar', symbol: '.د.ب'},
    'BI': {name: 'BURUNDI', code: '257', currency: 'Burundian Franc', symbol: 'FBu'},
    'BJ': {name: 'BENIN', code: '229', currency: 'West African CFA Franc', symbol: 'CFA'},
    'BL': {name: 'SAINT BARTHELEMY', code: '590', currency: 'Euro', symbol: '€'},
    'BM': {name: 'BERMUDA', code: '1441', currency: 'Bermudian Dollar', symbol: '$'},
    'BN': {name: 'BRUNEI DARUSSALAM', code: '673', currency: 'Brunei Dollar', symbol: '$'},
    'BO': {name: 'BOLIVIA', code: '591', currency: 'Bolivian Boliviano', symbol: 'Bs.'},
    'BR': {name: 'BRAZIL', code: '55', currency: 'Brazilian Real', symbol: 'R$'},
    'BS': {name: 'BAHAMAS', code: '1242', currency: 'Bahamian Dollar', symbol: '$'},
    'BT': {name: 'BHUTAN', code: '975', currency: 'Bhutanese Ngultrum', symbol: 'Nu.'},
    'BW': {name: 'BOTSWANA', code: '267', currency: 'Botswana Pula', symbol: 'P'},
    'BY': {name: 'BELARUS', code: '375', currency: 'Belarusian Ruble', symbol: 'Br'},
    'BZ': {name: 'BELIZE', code: '501', currency: 'Belize Dollar', symbol: '$'},
    'CA': {name: 'CANADA', code: '1', currency: 'Canadian Dollar', symbol: '$'},
    'CC': {name: 'COCOS (KEELING) ISLANDS', code: '61', currency: 'Australian Dollar', symbol: '$'},
    'CD': {name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE', code: '243', currency: 'Congolese Franc', symbol: 'FC'},
    'CF': {name: 'CENTRAL AFRICAN REPUBLIC', code: '236', currency: 'Central African CFA Franc', symbol: 'CFA'},
    'CG': {name: 'CONGO', code: '242', currency: 'Central African CFA Franc', symbol: 'CFA'},
    'CH': {name: 'SWITZERLAND', code: '41', currency: 'Swiss Franc', symbol: 'CHF'},
    'CI': {name: 'COTE D IVOIRE', code: '225', currency: 'West African CFA Franc', symbol: 'CFA'},
    'CK': {name: 'COOK ISLANDS', code: '682', currency: 'New Zealand Dollar', symbol: '$'},
    'CL': {name: 'CHILE', code: '56', currency: 'Chilean Peso', symbol: '$'},
    'CM': {name: 'CAMEROON', code: '237', currency: 'Central African CFA Franc', symbol: 'CFA'},
    'CN': {name: 'CHINA', code: '86', currency: 'Chinese Yuan', symbol: '¥'},
    'CO': {name: 'COLOMBIA', code: '57', currency: 'Colombian Peso', symbol: '$'},
    'CR': {name: 'COSTA RICA', code: '506', currency: 'Costa Rican Colón', symbol: '₡'},
    'CU': {name: 'CUBA', code: '53', currency: 'Cuban Peso', symbol: '$'},
    'CV': {name: 'CAPE VERDE', code: '238', currency: 'Cape Verdean Escudo', symbol: '$'},
    'CX': {name: 'CHRISTMAS ISLAND', code: '61', currency: 'Australian Dollar', symbol: '$'},
    'CY': {name: 'CYPRUS', code: '357', currency: 'Euro', symbol: '€'},
    'CZ': {name: 'CZECH REPUBLIC', code: '420', currency: 'Czech Koruna', symbol: 'Kč'},
    'DE': {name: 'GERMANY', code: '49', currency: 'Euro', symbol: '€'},
    'DJ': {name: 'DJIBOUTI', code: '253', currency: 'Djiboutian Franc', symbol: 'Fdj'},
    'DK': {name: 'DENMARK', code: '45', currency: 'Danish Krone', symbol: 'kr'},
    'DM': {name: 'DOMINICA', code: '1767', currency: 'East Caribbean Dollar', symbol: '$'},
    'DO': {name: 'DOMINICAN REPUBLIC', code: '1809', currency: 'Dominican Peso', symbol: '$'},
    'DZ': {name: 'ALGERIA', code: '213', currency: 'Algerian Dinar', symbol: 'د.ج'},
    'EC': {name: 'ECUADOR', code: '593', currency: 'United States Dollar', symbol: '$'},
    'EE': {name: 'ESTONIA', code: '372', currency: 'Euro', symbol: '€'},
    'EG': {name: 'EGYPT', code: '20', currency: 'Egyptian Pound', symbol: '£'},
    'ER': {name: 'ERITREA', code: '291', currency: 'Eritrean Nakfa', symbol: 'Nfk'},
    'ES': {name: 'SPAIN', code: '34', currency: 'Euro', symbol: '€'},
    'ET': {name: 'ETHIOPIA', code: '251', currency: 'Ethiopian Birr', symbol: 'Br'},
    'FI': {name: 'FINLAND', code: '358', currency: 'Euro', symbol: '€'},
    'FJ': {name: 'FIJI', code: '679', currency: 'Fijian Dollar', symbol: '$'},
    'FK': {name: 'FALKLAND ISLANDS (MALVINAS)', code: '500', currency: 'Falkland Islands Pound', symbol: '£'},
    'FM': {name: 'MICRONESIA, FEDERATED STATES OF', code: '691', currency: 'United States Dollar', symbol: '$'},
    'FO': {name: 'FAROE ISLANDS', code: '298', currency: 'Danish Krone', symbol: 'kr'},
    'FR': {name: 'FRANCE', code: '33', currency: 'Euro', symbol: '€'},
    'GA': {name: 'GABON', code: '241', currency: 'Central African CFA Franc', symbol: 'CFA'},
    'GB': {name: 'UNITED KINGDOM', code: '44', currency: 'Pound Sterling', symbol: '£'},
    'GD': {name: 'GRENADA', code: '1473', currency: 'East Caribbean Dollar', symbol: '$'},
    'GE': {name: 'GEORGIA', code: '995', currency: 'Georgian Lari', symbol: '₾'},
    'GH': {name: 'GHANA', code: '233', currency: 'Ghanaian Cedi', symbol: '₵'},
    'GI': {name: 'GIBRALTAR', code: '350', currency: 'Gibraltar Pound', symbol: '£'},
    'GL': {name: 'GREENLAND', code: '299', currency: 'Danish Krone', symbol: 'kr'},
    'GM': {name: 'GAMBIA', code: '220', currency: 'Gambian Dalasi', symbol: 'D'},
    'GN': {name: 'GUINEA', code: '224', currency: 'Guinean Franc', symbol: 'FG'},
    'GQ': {name: 'EQUATORIAL GUINEA', code: '240', currency: 'Central African CFA Franc', symbol: 'CFA'},
    'GR': {name: 'GREECE', code: '30', currency: 'Euro', symbol: '€'},
    'GT': {name: 'GUATEMALA', code: '502', currency: 'Guatemalan Quetzal', symbol: 'Q'},
    'GU': {name: 'GUAM', code: '1671', currency: 'United States Dollar', symbol: '$'},
    'GW': {name: 'GUINEA-BISSAU', code: '245', currency: 'West African CFA Franc', symbol: 'CFA'},
    'GY': {name: 'GUYANA', code: '592', currency: 'Guyanese Dollar', symbol: '$'},
    'HK': {name: 'HONG KONG', code: '852', currency: 'Hong Kong Dollar', symbol: '$'},
    'HN': {name: 'HONDURAS', code: '504', currency: 'Honduran Lempira', symbol: 'L'},
    'HR': {name: 'CROATIA', code: '385', currency: 'Euro', symbol: '€'},
    'HT': {name: 'HAITI', code: '509', currency: 'Haitian Gourde', symbol: 'G'},
    'HU': {name: 'HUNGARY', code: '36', currency: 'Hungarian Forint', symbol: 'Ft'},
    'ID': {name: 'INDONESIA', code: '62', currency: 'Indonesian Rupiah', symbol: 'Rp'},
    'IE': {name: 'IRELAND', code: '353', currency: 'Euro', symbol: '€'},
    'IL': {name: 'ISRAEL', code: '972', currency: 'Israeli New Shekel', symbol: '₪'},
    'IN': {name: 'INDIA', code: '91', currency: 'Indian Rupee', symbol: '₹'},
    'IQ': {name: 'IRAQ', code: '964', currency: 'Iraqi Dinar', symbol: 'ع.د'},
    'IR': {name: 'IRAN, ISLAMIC REPUBLIC OF', code: '98', currency: 'Iranian Rial', symbol: '﷼'},
    'IS': {name: 'ICELAND', code: '354', currency: 'Icelandic Króna', symbol: 'kr'},
    'IT': {name: 'ITALY', code: '39', currency: 'Euro', symbol: '€'},
    'JM': {name: 'JAMAICA', code: '1876', currency: 'Jamaican Dollar', symbol: '$'},
    'JO': {name: 'JORDAN', code: '962', currency: 'Jordanian Dinar', symbol: 'د.ا'},
    'JP': {name: 'JAPAN', code: '81', currency: 'Japanese Yen', symbol: '¥'},
    'KE': {name: 'KENYA', code: '254', currency: 'Kenyan Shilling', symbol: 'Sh'},
    'KG': {name: 'KYRGYZSTAN', code: '996', currency: 'Kyrgyzstani Som', symbol: 'лв'},
    'KH': {name: 'CAMBODIA', code: '855', currency: 'Cambodian Riel', symbol: '៛'},
    'KI': {name: 'KIRIBATI', code: '686', currency: 'Australian Dollar', symbol: '$'},
    'KM': {name: 'COMOROS', code: '269', currency: 'Comorian Franc', symbol: 'CF'},
    'KN': {name: 'SAINT KITTS AND NEVIS', code: '1869', currency: 'East Caribbean Dollar', symbol: '$'},
    'KP': {name: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF', code: '850', currency: 'North Korean Won', symbol: '₩'},
    'KR': {name: 'KOREA REPUBLIC OF', code: '82', currency: 'South Korean Won', symbol: '₩'},
    'KW': {name: 'KUWAIT', code: '965', currency: 'Kuwaiti Dinar', symbol: 'د.ك'},
    'KY': {name: 'CAYMAN ISLANDS', code: '1345', currency: 'Cayman Islands Dollar', symbol: '$'},
    'KZ': {name: 'KAZAKSTAN', code: '7', currency: 'Kazakhstani Tenge', symbol: 'лв'},
    'LA': {name: 'LAO PEOPLES DEMOCRATIC REPUBLIC', code: '856', currency: 'Lao Kip', symbol: '₭'},
    'LB': {name: 'LEBANON', code: '961', currency: 'Lebanese Pound', symbol: '£'},
    'LC': {name: 'SAINT LUCIA', code: '1758', currency: 'East Caribbean Dollar', symbol: '$'},
    'LI': {name: 'LIECHTENSTEIN', code: '423', currency: 'Swiss Franc', symbol: 'CHF'},
    'LK': {name: 'SRI LANKA', code: '94', currency: 'Sri Lankan Rupee', symbol: 'Rs'},
    'LR': {name: 'LIBERIA', code: '231', currency: 'Liberian Dollar', symbol: '$'},
    'LS': {name: 'LESOTHO', code: '266', currency: 'Lesotho Loti', symbol: 'L'},
    'LT': {name: 'LITHUANIA', code: '370', currency: 'Euro', symbol: '€'},
    'LU': {name: 'LUXEMBOURG', code: '352', currency: 'Euro', symbol: '€'},
    'LV': {name: 'LATVIA', code: '371', currency: 'Euro', symbol: '€'},
    'LY': {name: 'LIBYAN ARAB JAMAHIRIYA', code: '218', currency: 'Libyan Dinar', symbol: 'ل.د'},
    'MA': {name: 'MOROCCO', code: '212', currency: 'Moroccan Dirham', symbol: 'د.م.'},
    'MC': {name: 'MONACO', code: '377', currency: 'Euro', symbol: '€'},
    'MD': {name: 'MOLDOVA, REPUBLIC OF', code: '373', currency: 'Moldovan Leu', symbol: 'L'},
    'ME': {name: 'MONTENEGRO', code: '382', currency: 'Euro', symbol: '€'},
    'MF': {name: 'SAINT MARTIN', code: '590', currency: 'Euro', symbol: '€'},
    'MG': {name: 'MADAGASCAR', code: '261', currency: 'Malagasy Ariary', symbol: 'Ar'},
    'MH': {name: 'MARSHALL ISLANDS', code: '692', currency: 'United States Dollar', symbol: '$'},
    'MK': {name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF', code: '389', currency: 'Macedonian Denar', symbol: 'ден'},
    'ML': {name: 'MALI', code: '223', currency: 'West African CFA Franc', symbol: 'CFA'},
    'MM': {name: 'MYANMAR', code: '95', currency: 'Burmese Kyat', symbol: 'Ks'},
    'MN': {name: 'MONGOLIA', code: '976', currency: 'Mongolian Tögrög', symbol: '₮'},
    'MO': {name: 'MACAO', code: '853', currency: 'Macanese Pataca', symbol: 'P'},
    'MP': {name: 'NORTHERN MARIANA ISLANDS', code: '1670', currency: 'United States Dollar', symbol: '$'},
    'MR': {name: 'MAURITANIA', code: '222', currency: 'Mauritanian Ouguiya', symbol: 'UM'},
    'MS': {name: 'MONTSERRAT', code: '1664', currency: 'East Caribbean Dollar', symbol: '$'},
    'MT': {name: 'MALTA', code: '356', currency: 'Euro', symbol: '€'},
    'MU': {name: 'MAURITIUS', code: '230', currency: 'Mauritian Rupee', symbol: '₨'},
    'MV': {name: 'MALDIVES', code: '960', currency: 'Maldivian Rufiyaa', symbol: 'ރ'},
    'MW': {name: 'MALAWI', code: '265', currency: 'Malawian Kwacha', symbol: 'MK'},
    'MX': {name: 'MEXICO', code: '52', currency: 'Mexican Peso', symbol: '$'},
    'MY': {name: 'MALAYSIA', code: '60', currency: 'Malaysian Ringgit', symbol: 'RM'},
    'MZ': {name: 'MOZAMBIQUE', code: '258', currency: 'Mozambican Metical', symbol: 'MT'},
    'NA': {name: 'NAMIBIA', code: '264', currency: 'Namibian Dollar', symbol: '$'},
    'NC': {name: 'NEW CALEDONIA', code: '687', currency: 'CFP Franc', symbol: '₣'},
    'NE': {name: 'NIGER', code: '227', currency: 'West African CFA Franc', symbol: 'CFA'},
    'NF': {name: 'NORFOLK ISLAND', code: '672', currency: 'Australian Dollar', symbol: '$'},
    'NG': {name: 'NIGERIA', code: '234', currency: 'Nigerian Naira', symbol: '₦'},
    'NI': {name: 'NICARAGUA', code: '505', currency: 'Nicaraguan Córdoba', symbol: 'C$'},
    'NL': {name: 'NETHERLANDS', code: '31', currency: 'Euro', symbol: '€'},
    'NO': {name: 'NORWAY', code: '47', currency: 'Norwegian Krone', symbol: 'kr'},
    'NP': {name: 'NEPAL', code: '977', currency: 'Nepalese Rupee', symbol: '₨'},
    'NR': {name: 'NAURU', code: '674', currency: 'Australian Dollar', symbol: '$'},
    'NU': {name: 'NIUE', code: '683', currency: 'New Zealand Dollar', symbol: '$'},
    'NZ': {name: 'NEW ZEALAND', code: '64', currency: 'New Zealand Dollar', symbol: '$'},
    'OM': {name: 'OMAN', code: '968', currency: 'Omani Rial', symbol: 'ر.ع.'},
    'PA': {name: 'PANAMA', code: '507', currency: 'Panamanian Balboa', symbol: 'B/.'},
    'PE': {name: 'PERU', code: '51', currency: 'Peruvian Sol', symbol: 'S/.'},
    'PF': {name: 'FRENCH POLYNESIA', code: '689', currency: 'CFP Franc', symbol: '₣'},
    'PG': {name: 'PAPUA NEW GUINEA', code: '675', currency: 'Papua New Guinean Kina', symbol: 'K'},
    'PH': {name: 'PHILIPPINES', code: '63', currency: 'Philippine Peso', symbol: '₱'},
    'PK': {name: 'PAKISTAN', code: '92', currency: 'Pakistani Rupee', symbol: '₨'},
    'PL': {name: 'POLAND', code: '48', currency: 'Polish Zloty', symbol: 'zł'},
    'PM': {name: 'SAINT PIERRE AND MIQUELON', code: '508', currency: 'Euro', symbol: '€'},
    'PN': {name: 'PITCAIRN', code: '64', currency: 'New Zealand Dollar', symbol: '$'},
    'PR': {name: 'PUERTO RICO', code: '1787', currency: 'United States Dollar', symbol: '$'},
    'PT': {name: 'PORTUGAL', code: '351', currency: 'Euro', symbol: '€'},
    'PW': {name: 'PALAU', code: '680', currency: 'United States Dollar', symbol: '$'},
    'PY': {name: 'PARAGUAY', code: '595', currency: 'Paraguayan Guarani', symbol: '₲'},
    'QA': {name: 'QATAR', code: '974', currency: 'Qatari Riyal', symbol: 'ر.ق'},
    'RE': {name: 'REUNION', code: '262', currency: 'Euro', symbol: '€'},
    'RO': {name: 'ROMANIA', code: '40', currency: 'Romanian Leu', symbol: 'lei'},
    'RS': {name: 'SERBIA', code: '381', currency: 'Serbian Dinar', symbol: 'дин. or din.'},
    'RU': {name: 'RUSSIAN FEDERATION', code: '7', currency: 'Russian Ruble', symbol: '₽'},
    'RW': {name: 'RWANDA', code: '250', currency: 'Rwandan Franc', symbol: 'FRw'},
    'SA': {name: 'SAUDI ARABIA', code: '966', currency: 'Saudi Riyal', symbol: 'ر.س'},
    'SB': {name: 'SOLOMON ISLANDS', code: '677', currency: 'Solomon Islands Dollar', symbol: '$'},
    'SC': {name: 'SEYCHELLES', code: '248', currency: 'Seychellois Rupee', symbol: '₨'},
    'SD': {name: 'SUDAN', code: '249', currency: 'Sudanese Pound', symbol: '£'},
    'SE': {name: 'SWEDEN', code: '46', currency: 'Swedish Krona', symbol: 'kr'},
    'SG': {name: 'SINGAPORE', code: '65', currency: 'Singapore Dollar', symbol: '$'},
    'SH': {name: 'SAINT HELENA', code: '290', currency: 'Saint Helena Pound', symbol: '£'},
    'SI': {name: 'SLOVENIA', code: '386', currency: 'Euro', symbol: '€'},
    'SJ': {name: 'SVALBARD AND JAN MAYEN', code: '47', currency: 'Norwegian Krone', symbol: 'kr'},
    'SK': {name: 'SLOVAKIA', code: '421', currency: 'Euro', symbol: '€'},
    'SL': {name: 'SIERRA LEONE', code: '232', currency: 'Sierra Leonean Leone', symbol: 'Le'},
    'SM': {name: 'SAN MARINO', code: '378', currency: 'Euro', symbol: '€'},
    'SN': {name: 'SENEGAL', code: '221', currency: 'West African CFA Franc', symbol: 'CFA'},
    'SO': {name: 'SOMALIA', code: '252', currency: 'Somali Shilling', symbol: 'Sh'},
    'SR': {name: 'SURINAME', code: '597', currency: 'Surinamese Dollar', symbol: '$'},
    'SS': {name: 'SOUTH SUDAN', code: '211', currency: 'South Sudanese Pound', symbol: '£'},
    'ST': {name: 'SAO TOME AND PRINCIPE', code: '239', currency: 'São Tomé and Príncipe Dobra', symbol: 'Db'},
    'SV': {name: 'EL SALVADOR', code: '503', currency: 'United States Dollar', symbol: '$'},
    'SX': {name: 'SINT MAARTEN', code: '1721', currency: 'Netherlands Antillean Guilder', symbol: 'ƒ'},
    'SY': {name: 'SYRIAN ARAB REPUBLIC', code: '963', currency: 'Syrian Pound', symbol: '£'},
    'SZ': {name: 'SWAZILAND', code: '268', currency: 'Swazi Lilangeni', symbol: 'L'},
    'TC': {name: 'TURKS AND CAICOS ISLANDS', code: '1649', currency: 'United States Dollar', symbol: '$'},
    'TD': {name: 'CHAD', code: '235', currency: 'Central African CFA Franc', symbol: 'CFA'},
    'TF': {name: 'FRENCH SOUTHERN TERRITORIES', code: '262', currency: 'Euro', symbol: '€'},
    'TG': {name: 'TOGO', code: '228', currency: 'West African CFA Franc', symbol: 'CFA'},
    'TH': {name: 'THAILAND', code: '66', currency: 'Thai Baht', symbol: '฿'},
    'TJ': {name: 'TAJIKISTAN', code: '992', currency: 'Tajikistani Somoni', symbol: 'ЅМ'},
    'TK': {name: 'TOKELAU', code: '690', currency: 'New Zealand Dollar', symbol: '$'},
    'TL': {name: 'TIMOR-LESTE', code: '670', currency: 'United States Dollar', symbol: '$'},
    'TM': {name: 'TURKMENISTAN', code: '993', currency: 'Turkmenistan Manat', symbol: 'T'},
    'TN': {name: 'TUNISIA', code: '216', currency: 'Tunisian Dinar', symbol: 'د.ت'},
    'TO': {name: 'TONGA', code: '676', currency: 'Tongan Paʻanga', symbol: 'T$'},
    'TR': {name: 'TURKEY', code: '90', currency: 'Turkish Lira', symbol: '₺'},
    'TT': {name: 'TRINIDAD AND TOBAGO', code: '1868', currency: 'Trinidad and Tobago Dollar', symbol: '$'},
    'TV': {name: 'TUVALU', code: '688', currency: 'Australian Dollar', symbol: '$'},
    'TZ': {name: 'TANZANIA, UNITED REPUBLIC OF', code: '255', currency: 'Tanzanian Shilling', symbol: 'Sh'},
    'UA': {name: 'UKRAINE', code: '380', currency: 'Ukrainian Hryvnia', symbol: '₴'},
    'UG': {name: 'UGANDA', code: '256', currency: 'Ugandan Shilling', symbol: 'Sh'},
    'US': {name: 'UNITED STATES', code: '1', currency: 'United States Dollar', symbol: '$'},
    'UY': {name: 'URUGUAY', code: '598', currency: 'Uruguayan Peso', symbol: '$'},
    'UZ': {name: 'UZBEKISTAN', code: '998', currency: 'Uzbekistani Soʻm', symbol: 'лв'},
    'VA': {name: 'HOLY SEE (VATICAN CITY STATE)', code: '379', currency: 'Euro', symbol: '€'},
    'VC': {name: 'SAINT VINCENT AND THE GRENADINES', code: '1784', currency: 'East Caribbean Dollar', symbol: '$'},
    'VE': {name: 'VENEZUELA', code: '58', currency: 'Venezuelan Bolívar', symbol: 'Bs'},
    'VG': {name: 'VIRGIN ISLANDS, BRITISH', code: '1284', currency: 'United States Dollar', symbol: '$'},
    'VI': {name: 'VIRGIN ISLANDS, U.S.', code: '1340', currency: 'United States Dollar', symbol: '$'},
    'VN': {name: 'VIET NAM', code: '84', currency: 'Vietnamese Dong', symbol: '₫'},
    'VU': {name: 'VANUATU', code: '678', currency: 'Vanuatu Vatu', symbol: 'VT'},
    'WF': {name: 'WALLIS AND FUTUNA', code: '681', currency: 'CFP Franc', symbol: '₣'},
    'WS': {name: 'SAMOA', code: '685', currency: 'Samoan Tala', symbol: 'WS$'},
    'YE': {name: 'YEMEN', code: '967', currency: 'Yemeni Rial', symbol: '﷼'},
    'YT': {name: 'MAYOTTE', code: '262', currency: 'Euro', symbol: '€'},
    'ZA': {name: 'SOUTH AFRICA', code: '27', currency: 'South African Rand', symbol: 'R'},
    'ZM': {name: 'ZAMBIA', code: '260', currency: 'Zambian Kwacha', symbol: 'ZK'},
    'ZW': {name: 'ZIMBABWE', code: '263', currency: 'Zimbabwean Dollar', symbol: '$'},
    'AX': {name: 'ALAND ISLANDS', code: '358', currency: 'Euro', symbol: '€'}
};
