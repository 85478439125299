export const get_menus = (id, menu_type = "", search_term = "") => {
  let item_list = [];

  const admin_general = [
    {
      id: 0,
      title: "Overview",
      path: "/hubadmin/" + id + "/overview",
      search_visibale: true,
      search_icon: "stacked_bar_chart",
      icon: "stacked_bar_chart",
      has_sub: false,
    },
    {
      id: 1,
      title: "Members",
      path: "/hubadmin/" + id + "/members",
      search_visibale: true,
      search_icon: "people_alt",
      icon: "people_alt",
      has_sub: false,
    },
    {
      id: 3,
      title: "Followers",
      path: "/hubadmin/" + id + "/followers",
      search_visibale: true,
      search_icon: "interpreter_mode",
      icon: "interpreter_mode",
      has_sub: false,
    },
    {
      id: 4,
      title: "Settings",
      path: "/hubadmin/" + id + "/settings",
      search_visibale: true,
      search_icon: "settings",
      icon: "settings",
      has_sub: true,
    },
  ];

  const settings_menu = [
    {
      id: -1,
      title: "Profile & Cover",
      path: "/hubadmin/" + id + "/settings/profileimage",
      search_visibale: true,
      search_icon: "photo_camera",
      icon: "photo_camera",
      has_sub: false,
    },
    {
      id: 0,
      title: "General profile",
      path: "/hubadmin/" + id + "/settings/profile",
      search_visibale: true,
      search_icon: "store",
      icon: "store",
      has_sub: false,
    },
    {
      id: 1,
      title: "Manage username",
      path: "/hubadmin/" + id + "/settings/username",
      search_visibale: true,
      search_icon: "alternate_email",
      icon: "alternate_email",
      has_sub: false,
    },
    {
      id: 2,
      title: "Contact info",
      path: "/hubadmin/" + id + "/settings/contactinfo",
      search_visibale: true,
      search_icon: "location_on",
      icon: "location_on",
      has_sub: false,
    },
    {
      id: 4,
      title: "Hub access",
      path: "/hubadmin/" + id + "/settings/hubaccess",
      search_visibale: true,
      search_icon: "admin_panel_settings",
      icon: "admin_panel_settings",
      has_sub: false,
    },
    {
      id: 6,
      title: "Categories",
      path: "/hubadmin/" + id + "/settings/categoryandtype",
      search_visibale: true,
      search_icon: "restaurant",
      icon: "restaurant",
      has_sub: false,
    },
    {
      id: 7,
      title: "Manage rules",
      path: "/hubadmin/" + id + "/settings/rules",
      search_visibale: true,
      search_icon: "gavel",
      icon: "gavel",
      has_sub: false,
    },
  ];

  if (menu_type === "admin_general") {
    item_list = item_list.concat(admin_general);
  }

  if (menu_type === "settings_menu") {
    item_list = item_list.concat(settings_menu);
  }

  if (search_term) {
    item_list = item_list.concat(admin_general);
    item_list = item_list.concat(settings_menu);
    item_list = item_list.filter((item) =>
      item.title.toLowerCase().includes(search_term.toLowerCase())
    );
  }

  return item_list;
};
