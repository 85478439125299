import React, { useEffect, useState } from "react";
import MediaListDummy from "../../components/Dummies/MediaListDummy";

function Documents({ conversationData }) {
  const [visibleDocuments, setVisibleDocuments] = useState(3); // Initially show 3 documents
  const [loadingMedia, setLoadingMedia] = useState(true);
  // Aggregate all documents with their respective date_time

  const allDocuments = conversationData
    .flatMap((item) =>
      item.uploads.documents.map((doc) => ({
        ...doc,
        date_time: item.date_time,
      }))
    )
    .sort((a, b) => new Date(b.date_time) - new Date(a.date_time)); // Sort by date_time in descending order
  useEffect(() => {
    setLoadingMedia(false);
  }, []);

  const loadMoreDocuments = () => {
    setVisibleDocuments((prev) => prev + 3); // Load 3 more documents at a time
  };

  return (
    <div>
      <div className="py-4">
        {allDocuments.slice(0, visibleDocuments).map((doc, index) => (
          <div key={index} className="hover px-4 py-1 flex align-start gap-3">
            <div className="">
              <img
                src="/images/doc2.png"
                className="w-12 h-12 object-contain p-1 rounded-sm"
                alt="Attachment icon"
              />
            </div>
            <div className="flex-1 w-fill">
              <div className="font-semibold">You</div>
              <div className="text-mute">{doc.name}</div>
              <div className="text-sm mt-2 flex align-center justify-between">
                <div className="flex align-center gap-2">
                  <span className="material-icons-round">schedule</span>
                  <div className="text-sm">
                    {new Date(doc.date_time).toLocaleDateString()}{" "}
                    <small>at</small>{" "}
                    {new Date(doc.date_time).toLocaleTimeString()}
                  </div>
                </div>
                <span className="material-icons-round text-sm">
                  file_download
                </span>
              </div>
            </div>
          </div>
        ))}

        {loadingMedia && <MediaListDummy />}
        {allDocuments.length == 0 && (
          <div className=" rounded p-1 cursor-pointer mt-3 px-4 py-2 select-none text-sm mx-10 text-center">
            There is no document shared at the moment.
          </div>
        )}

        {visibleDocuments < allDocuments.length && (
          <div
            onClick={loadMoreDocuments}
            className="hover rounded p-1 cursor-pointer mt-3 px-4 select-none text-sm"
          >
            See more
          </div>
        )}
      </div>
    </div>
  );
}

export default Documents;
