import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  savePortfolioImageToIndexedDB,
  getImagesFromIndexedDB,
  removePortfolioImageFromIndexedDB,
} from "../../db"; // Import IndexedDB functions
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Responce from "../components/Responce";
import app from "../../config/app";

function Portfolio() {
  const [newPortfolioModalOpen, setNewPortfolioModalOpen] = useState(false);
  const [formData, setFormData] = useState({ title: "" });
  const [imageFiles, setImageFiles] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [slideshowOpen, setSlideshowOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);

  let { modal } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  useEffect(() => {
    const fetchImages = async () => {
      const images = await getImagesFromIndexedDB();
      setPortfolioList(images || []);
    };

    if (modal) {
      setNewPortfolioModalOpen(true);
    } else {
      setNewPortfolioModalOpen(false);
    }

    fetchImages();
  }, [currentPath]);

  const closeModal = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
  };

  const removePortfolio = async (id) => {
    const responce = await removePortfolioImageFromIndexedDB(id);
    setPortfolioList(portfolioList.filter((item) => item.id !== id));
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPortfolio = {
      id: Date.now() + "@" + app.current_user,
      title: formData.title,
      images: await Promise.all(
        imageFiles.map(async (file) => {
          const reader = new FileReader();
          return new Promise((resolve) => {
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(file);
          });
        })
      ),
    };

    setPortfolioList([...portfolioList, newPortfolio]);
    const responce = await savePortfolioImageToIndexedDB(newPortfolio);
    console.log(responce);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
    }
    closeModal();
  };

  const openSlideshow = (images, index) => {
    setCurrentImages(images);
    setCurrentImageIndex(index);
    setSlideshowOpen(true);
  };

  const closeSlideshow = () => {
    setSlideshowOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === currentImages.length - 1 ? 0 : currentIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === 0 ? currentImages.length - 1 : currentIndex - 1
    );
  };

  return (
    <div className="p-6">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-4 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
            Portfolio
          </h2>
        </div>
        <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <Link to="/Professional/portfolio/new" className="hidden sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Add new
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-0">
        <div className="gap-3 py-3">
          {portfolioList.map((item) => (
            <div key={item.id} className="h-auto rounded-xl relative gap-5">
              <div className="flex align-center justify-between">
                <div className="text-xl font-semibold flex-1 w-full py-4">
                  {item.title}
                </div>
                <div
                  onClick={() => removePortfolio(item.id)}
                  className="w-10 h-10 rounded-full bg-gray-100 shadow-lg hover:shadow-2xl right-2 top-2 flex align-center justify-center select-none hover"
                >
                  <span className="material-icons-round">close</span>
                </div>
              </div>
              <div className="overflow-auto">
                <div className="flex align-center gap-5 flex-wrap">
                  {item.images.map((image, index) => (
                    <div className="hover w-40 h-40 rounded-xl">
                      <img
                        key={index}
                        src={image}
                        className="object-cover w-40 h-40 rounded-xl cursor-pointer portfolio_image"
                        alt="Portfolio"
                        onClick={() => openSlideshow(item.images, index)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <hr className="line" />
            </div>
          ))}
        </div>
      </div>

      <Dialog
        className="relative z-10"
        open={newPortfolioModalOpen}
        onClose={() => closeModal()}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div className="bg-white">
                <div className="sm:flex sm:items-start w-full">
                  <div className="text-left w-full">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 mb-0 border-bottom p-2 px-5 flex align-center justify-between"
                    >
                      <div>Create a portfolio</div>
                      <div
                        className="w-10 h-10 rounded-full bg-gray-100 hover flex align-center justify-center"
                        onClick={() => closeModal()}
                      >
                        <span className="material-icons-round">close</span>
                      </div>
                    </DialogTitle>
                    <form onSubmit={handleSubmit} className="">
                      <div className="p-5 py-3">
                        <div className="mt-6 rounded-md">
                          <div className="form-check p-0 mt-5">
                            <label
                              className="form-check-label mb-4"
                              htmlFor="portfolioTitle"
                            >
                              Portfolio title
                            </label>
                            <div className="flex align-center relative mt-3">
                              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                                <span className="material-icons-round">
                                  palette
                                </span>
                              </div>
                              <input
                                className="form-control w-full relative has_icon"
                                type="text"
                                name="title"
                                id="portfolioTitle"
                                value={formData.title}
                                onChange={handleChange}
                                placeholder="Add a name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-10">
                          <label
                            htmlFor="file"
                            className="w-full h-40 bg-gray-200 hover rounded-xl cursor-pointer flex align-center justify-center"
                          >
                            <div className="flex flex-column align-center justify-center">
                              <span className="material-icons-round">
                                palette
                              </span>
                              <div className="mt-3">Select media files</div>
                            </div>
                          </label>
                        </div>
                        <input
                          type="file"
                          className="form-control w-full mt-2 hidden"
                          accept="image/*"
                          id="file"
                          multiple
                          onChange={handleImageChange}
                        />
                        <div className="mt-4 flex flex-wrap gap-2">
                          {imageFiles.map((file, index) => (
                            <div key={index} className="w-24 h-24">
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`Preview ${index}`}
                                className="w-full h-full object-cover rounded"
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-full w-full bg-black px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-full"
                        >
                          Save portfolio
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {slideshowOpen && (
        <Dialog
          className="relative z-10"
          open={slideshowOpen}
          onClose={closeSlideshow}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl"
              >
                <div className="bg-white">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="text-left w-full">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 mb-6 border-bottom p-2 px-5 flex align-center justify-between"
                      >
                        <div>Portfolio</div>
                        <div
                          className="w-10 h-10 rounded-full bg-gray-100 hover flex align-center justify-center"
                          onClick={closeSlideshow}
                        >
                          <span className="material-icons-round">close</span>
                        </div>
                      </DialogTitle>
                      <div className="p-5 py-3 flex justify-center">
                        <img
                          src={currentImages[currentImageIndex]}
                          alt={`Slideshow ${currentImageIndex}`}
                          className="max-h-96 max-w-full object-cover"
                        />
                      </div>
                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6 flex justify-center gap-2">
                        <button
                          onClick={prevImage}
                          className="inline-flex justify-center rounded-full bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 sm:ml-3 sm:w-1/5"
                        >
                          Previous
                        </button>
                        {/* <div className="flex-grow"></div> */}
                        <button
                          onClick={nextImage}
                          className="inline-flex justify-center rounded-full bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 sm:mr-3 sm:w-1/5"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
          {showResponceCard && responceCard}
        </Dialog>
      )}
    </div>
  );
}

export default Portfolio;
