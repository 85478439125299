import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApplications } from "../../db";
import PostContent from "../components/PostContent";

function Applications() {
  const navigate = useNavigate();
  const [applicationList, setApplicationList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadApplications = async () => {
      const data = await getApplications();
      setApplicationList(data.applications);
      setLoading(false);
      // console.log("applicationList", data.applications);
    };

    loadApplications();
  }, []);

  return (
    <div className="p-6">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-4 sm:truncate sm:text-2xl sm:tracking-tight">
            Job applications
          </h2>
        </div>
      </div>

      <div className="overflow-hidden">
        {/* all settings goes here */}
        <div className="applicants">
          <div className="table-content">
            <div className="wrap-applicants table-responsive  bg-gray-100 rounded-xl mt-5">
              {applicationList.length == 0 && !loading && (
                <div className="mt-2 mb-2">
                  <div className="flex align-center">
                    <img
                      src="/images/beam-woman-meditating-in-lotus-position.gif"
                      className="w-36 h-auto sm_image"
                      with="50"
                    />
                    <div className="px-3">
                      <div className="font-bold text-lg">No applications</div>
                      <div>When you apply for a job it will be shown here.</div>
                    </div>
                  </div>
                </div>
              )}
              <table className="table-auto sm_table table-bordered">
                <thead>
                  <tr>
                    <th>Job name</th>
                    <th>Status</th>
                    <th className="center">Date applied</th>
                  </tr>
                </thead>
                {applicationList.length > 0 && (
                  <tbody>
                    {applicationList.length > 0 &&
                      applicationList.map((item, index) => (
                        <tr>
                          <td>
                            <div className="flex align-start gap-2">
                              <div style={{ marginTop: 3 }}>{index + 1}.</div>
                              <div>
                                <div className="text-lg font-semibold">
                                  {item.job_details[0].row.job_name}
                                </div>
                                <div className="text-sm">
                                  <PostContent
                                    text={item.row.about}
                                    maxLength={100}
                                    allowSpacing={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {item.row.status == "false"
                              ? "Pending"
                              : "Accepted"}
                          </td>
                          <td>{item.row.date_time}</td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Applications;
