import React, { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/16/solid";

const Slider = ({ children, slideWidth = 200, slidesVisible = 3 }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const totalSlides = React.Children.count(children);
  const maxSlideIndex = totalSlides - slidesVisible;

  const handleSlideRight = () => {
    setSlideIndex((prevIndex) =>
      prevIndex < maxSlideIndex ? prevIndex + 1 : prevIndex
    );
  };

  const handleSlideLeft = () => {
    setSlideIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  return (
    <div className="relative overflow-hidden rounded-xl pt-3">
      <div className="absolute arrow_prev" onClick={handleSlideLeft}>
        <ArrowLeftIcon />
      </div>
      <div
        className="slider flex align-start gap-4 py-0 px-5"
        style={{
          transform: `translateX(-${slideIndex * slideWidth}px)`,
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {children}
      </div>
      <div className="absolute arrow_next" onClick={handleSlideRight}>
        <ArrowRightIcon />
      </div>
    </div>
  );
};

export default Slider;
