import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import { useState } from "react";
import {
  getPageProfileByUsername,
  submitFollowPage,
  submitJoinHub,
} from "../db";
import app from "../config/app";
import PageVibes from "./PageAdmin/Profile/PageVibes";
import PageMedia from "./PageAdmin/Profile/PageMedia";
import PagePinned from "./PageAdmin/Profile/PagePinned";
import PageFollowers from "./PageAdmin/Profile/PageFollowers";
import PageAbout from "./PageAdmin/Profile/PageAbout";
import Loader from "./components/Loader";
import CopyRight from "./components/CopyRight";

function Page({ local }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageProfile, setPageProfile] = useState();
  const [followers, setFollowers] = useState([]);
  const [followStatus, setFollowStatus] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);
  const [totalFollowers, setTotalFollowers] = useState(0);
  const [canEdit, setCanEdit] = useState(false);
  const [userProfile, setUserProfile] = useState();

  let currentPath = location.pathname;
  const { focus, username } = useParams();
  const [loading, setLoading] = useState(true);
  let component = <PageVibes local={local} username={username} />;

  useEffect(() => {
    fetchData();
  }, [username]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const info = await getPageProfileByUsername(username);
      console.log("Page info - > ", info);
      setPageProfile(info.profile[0]);
      setFollowers(info.followers);
      setFollowStatus(info.follow_status);
      setTotalFollowers(info.total_follower);
      setSaveStatus(info.save_status);
      setCanEdit(info.can_edit);
      setUserProfile(info.user_info[0]);
    } catch (error) {
      console.error("Details:", error);
    }
    setLoading(false);
  };

  const profile_paths = [
    {
      id: 1,
      title: "Vibes",
      path: "/page/" + username + "/vibes",
    },
    // {
    //   id: 3,
    //   title: "Pinned",
    //   path: "/page/" + username + "/pinned",
    // },
    {
      id: 4,
      title: "Media",
      path: "/page/" + username + "/media",
    },
    {
      id: 5,
      title: "Followers (" + totalFollowers + ")",
      path: "/page/" + username + "/followers",
    },
    {
      id: 6,
      title: "About business",
      path: "/page/" + username + "/about",
    },
  ];

  if (!focus) {
    currentPath = "/page/" + username + "/vibes";
  }

  if (focus === "vibes") {
    component = <PageVibes local={local} username={username} />;
  }

  if (focus === "media") {
    component = <PageMedia local={local} username={username} />;
  }

  if (focus === "followers") {
    component = <PageFollowers local={local} username={username} />;
  }

  if (focus === "pinned") {
    component = <PagePinned local={local} username={username} />;
  }

  if (focus === "about") {
    component = (
      <PageAbout
        local={local}
        username={username}
        data={pageProfile}
        user={userProfile}
      />
    );
  }

  const handleFollow = async (page_id) => {
    if (followStatus) {
      setFollowStatus(false);
      setTotalFollowers(totalFollowers - 1);
    } else {
      setFollowStatus(true);
      setTotalFollowers(totalFollowers + 1);
    }
    try {
      const data = await submitFollowPage(page_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <>
      <div className="min-h-screen flex-1 ">
        <div className="px-4 headers p-0 pl-1">
          <div
            className=" h-full w-full flex align-center"
            style={{ width: "100%" }}
          >
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center ml-2"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">About</div>
            </div>
          </div>
          {window.innerWidth > app.mobile_width && <TopUserMenu />}
        </div>
        <div className="relative pr-2 sm-p-0">
          {loading && (
            <div className="absolute w-full flex align-center justify-center h-full rounded-2xl top-0 left-0 auth_loader_cont">
              <Loader />
            </div>
          )}
          <div className="w-full relative">
            <div className="w-full h-72 bg-gray-100 rounded-xl -mt-16 relative">
              <img
                src={
                  pageProfile?.cover_image == ""
                    ? "/images/hubcover.png"
                    : app.media_url + "/thumb/" + pageProfile?.cover_image
                }
                className="w-full h-full min-w-full object-cover rounded-2xl"
              />
              <div className="absolute top-20 right-20">
                <button className="w-10 h-10 flex align-center justify-center hover rounded-full absolute -right-14">
                  <span class="material-symbols-outlined">more_horiz</span>
                </button>
              </div>
            </div>
            <div className="-mt-24 flex align-center gap-0 w-full justify-center flex-col relative z-8">
              <div className="w-36 h-36 rounded-full bg-gray-200 rounded-xl border-4">
                <img
                  src={
                    pageProfile?.profile_image == ""
                      ? "/images/hubuser.png"
                      : app.media_url +
                        "/thumb/small/" +
                        pageProfile?.profile_image
                  }
                  className="w-full h-full min-w-full rounded-full object-cover"
                />
              </div>
              <div className="mb-3">
                <div className="flex align-center gap-3 relative justify-center">
                  <div className="font-bold text-3xl text-center">
                    {pageProfile?.business_name}
                  </div>
                </div>
                <div className="font-semibold text-center">
                  @{pageProfile?.username}
                </div>
                <div className="align-center justify-center w-full flex mt-2 gap-2">
                  <div
                    className={`h-14 w-40 rounded-full font-normal align-center justify-center text-center flex follow_btn ${
                      followStatus ? "following" : "follow"
                    }`}
                    onClick={() => handleFollow(pageProfile?.page_id)}
                    style={{ height: 36, fontWeight: "500", fontSize: 16 }}
                  >
                    {followStatus ? "Following" : "Follow"}
                  </div>

                  {canEdit && (
                    <Link
                      to={`/admin/${pageProfile.page_id}/settings`}
                      className="btn"
                    >
                      Manage Page
                    </Link>
                  )}
                </div>
              </div>
              <div className="flex align-center gap-0">
                {followers.length > 1 &&
                  followers.map((item, index) => (
                    <Link
                      key={index}
                      to={`/profile/${item.user_profile[0]?.username}/vibes`}
                    >
                      <img
                        src={item.user_profile[0]?.profile_image}
                        className="w-10 h-10 min-w-10 min-h-10 object-cover rounded-full bg-gray-100 flex align-center justify-center -ml-2 hover"
                      />
                    </Link>
                  ))}
                {totalFollowers > 10 && (
                  <div className="w-10 h-10 rounded-full bg-gray-200 flex align-center justify-center -ml-2 hover z-10">
                    {totalFollowers > 10
                      ? "+" + totalFollowers - 10
                      : totalFollowers}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="px-4 flex align-center headers w-full profile_header justify-center sm-justify-start">
            {profile_paths.map((item, index) => (
              <Link
                key={item.id}
                to={item.path}
                className={currentPath === item.path ? "active_tab" : ""}
              >
                <div className="h-10 px-4 hover mr-4 rounded-full align-center flex justify-center font-medium tab_title">
                  {item.title}
                </div>
              </Link>
            ))}
          </div>
          <div className="">{component}</div>
        </div>
        <div className="border-top pt-5 mt-5 text-center px-10 sm-p-1 mb-2">
          <CopyRight />
        </div>
      </div>
    </>
  );
}

export default Page;
