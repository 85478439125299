import React, { useEffect } from "react";
import ProfileCard from "./components/ProfileCard";
import FollowList from "./components/FollowList";
import JoinHubList from "./components/JoinHubList";
import TopUserMenu from "./components/TopUserMenu";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Overview from "./Professional/Overview";
import Attachments from "./Professional/Attachments";
import Experience from "./Professional/Experience";
import Education from "./Professional/Education";
import ManageSkills from "./Professional/ManageSkills";
import Portfolio from "./Professional/Portfolio";
import MySkills from "./Professional/MySkills";
import Applications from "./Professional/Applications";

function Professional() {
  const navigate = useNavigate();
  let { name } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    document.body.classList.add("wide_body");
    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove("wide_body");
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  if (!name) {
    name = "overview";
  }

  if (name == "overview") {
    component = <Overview />;
  }

  if (name == "manageskills") {
    component = <ManageSkills />;
  }

  if (name == "myskills") {
    component = <MySkills />;
  }

  if (name == "attachments") {
    component = <Attachments />;
  }

  if (name == "experience") {
    component = <Experience />;
  }

  if (name == "education") {
    component = <Education />;
  }

  if (name == "portfolio") {
    component = <Portfolio />;
  }

  if (name == "applications") {
    component = <Applications />;
  }

  const paths = [
    // {
    //   id: 0,
    //   title: "Overview",
    //   path: "/Professional/overview",
    //   icon: "lightbulb",
    // },
    {
      id: 1,
      title: "My job applications",
      path: "/Professional/applications",
      icon: "lightbulb",
    },
    {
      id: 2,
      title: "All my skills",
      path: "/Professional/myskills",
      icon: "plumbing",
    },
    {
      id: 3,
      title: "Attachments",
      path: "/Professional/attachments",
      icon: "history_edu",
    },
    {
      id: 4,
      title: "Portfolio",
      path: "/Professional/portfolio",
      icon: "palette",
    },
    {
      id: 5,
      title: "Experience",
      path: "/Professional/experience",
      icon: "engineering",
    },
    {
      id: 6,
      title: "Education",
      path: "/Professional/education",
      icon: "school",
    },
  ];

  const settings_paths = [
    {
      id: 10,
      title: "Manage skills",
      path: "/Professional/manageskills",
      icon: "build",
    },
  ];

  return (
    <>
      <div className="flex-1 border-left">
        <div className="px-4 flex align-center headers justify-between">
          <div className="flex align-center">
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Professional</div>
            </div>
          </div>
          <TopUserMenu />
        </div>
        <div className="flex align-start mid_container_inn">
          <div className="p-4 w-full max-w-80 min-w-80 has_border_right left_side_settings">
            {/* {name} */}
            {/* <div className='my-3 px-4 mt-2'>Manage your professional profile</div> */}

            {paths.map((item, index) => (
              <Link key={item.id} to={item.path} className="py-0.5 block">
                <div
                  className={
                    currentPath == item.path
                      ? "flex align-center hover p-2 px-3 rounded-md sidebar_item active"
                      : "flex align-center hover p-2 px-3 rounded-md sidebar_item"
                  }
                >
                  <div className="flex align-center w-10 h-10">
                    <span
                      className={
                        currentPath == item.path
                          ? "material-icons-round"
                          : "material-symbols-outlined"
                      }
                    >
                      {item.icon}
                    </span>
                  </div>
                  <div className="flex align-center flex-1">{item.title}</div>
                  <div className="flex align-center">
                    <span class="material-icons-round">chevron_right</span>
                  </div>
                </div>
              </Link>
            ))}

            <hr className="line" />
            <div className="px-5 my-4">Profile settings</div>
            {settings_paths.map((item, index) => (
              <Link key={item.id} to={item.path}>
                <div
                  className={
                    currentPath == item.path
                      ? "flex align-center hover p-2 px-3 rounded-md sidebar_item active"
                      : "flex align-center hover p-2 px-3 rounded-md sidebar_item"
                  }
                >
                  <div className="flex align-center w-10 h-10">
                    <span class="material-icons-round">{item.icon}</span>
                  </div>
                  <div className="flex align-center flex-1">{item.title}</div>
                  <div className="flex align-center">
                    <span class="material-icons-round">chevron_right</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="w-full">{component}</div>
        </div>
      </div>
    </>
  );
}

export default Professional;
