import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  saveExperienceToIndexedDB,
  getExperienceFromIndexedDB,
  removeExperienceFromIndexedDB,
} from "../../db"; // Import IndexedDB functions
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Responce from "../components/Responce";
import app from "../../config/app";

function Experience() {
  const [newExperienceModalOpen, setNewExperienceModalOpen] = useState(false);
  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  let { modal } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [formData, setFormData] = useState({
    companyName: "",
    startDate: "",
    endDate: "",
    description: "",
    isCurrentlyWorking: false,
  });

  const [experienceList, setExperienceList] = useState([]);

  useEffect(() => {
    if (modal) {
      setNewExperienceModalOpen(true);
    } else {
      setNewExperienceModalOpen(false);
    }
    fetchEducation();
  }, [currentPath]);

  const fetchEducation = async () => {
    const education = await getExperienceFromIndexedDB();
    console.log("Retrieved education from IndexedDB:", education); // Debug log
    setExperienceList(education || []);
  };

  const closeModal = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const removeEducation = async (id) => {
    console.log("Removing education with id:", id); // Debug log
    const responce = await removeExperienceFromIndexedDB(id);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
    }
    setExperienceList(experienceList.filter((item) => item.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.companyName.trim() || !formData.startDate.trim()) {
      alert("Please provide the institution name and start date.");
      return;
    }

    const newEducation = {
      id: Date.now().toString() + "@" + app.current_user,
      companyName: formData.companyName.trim(),
      startDate: formData.startDate.trim(),
      endDate: formData.isCurrentlyWorking
        ? "Present"
        : formData.endDate.trim(),
      description: formData.description.trim(),
      isCurrentlyWorking: formData.isCurrentlyWorking,
    };

    console.log("Saving new education to IndexedDB:", newEducation); // Debug log
    const responce = await saveExperienceToIndexedDB(newEducation);
    if (responce) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce
          data={{ text: responce.responce, status: responce.r_status }}
        />
      );
      // console.log(responce_card);
    }
    setExperienceList([...experienceList, newEducation]);
    setFormData({
      companyName: "",
      startDate: "",
      endDate: "",
      description: "",
      isCurrentlyWorking: false,
    });
    closeModal(); // Close modal
  };

  return (
    <div className="p-6">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-4 sm:truncate sm:text-2xl sm:tracking-tight">
            Experience
          </h2>
        </div>
        <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <Link to="/Professional/experience/new" className="hidden sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Add Experience
            </button>
          </Link>
        </div>
      </div>

      {experienceList.length === 0 ? (
        <div className="mt-4 text-gray-500">
          <div className="flex align-center">
            <img
              src="/images/vr-glasses.png"
              className="sm_image w-28 mr-3"
              width={100}
              alt="No details"
            />
            <div className="px-3">
              <div className="font-semibold text-lg">
                Worked or working somewhere?
              </div>
              <div>Showcase your work experience by adding details here.</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <div className="flex flex-wrap gap-3 py-3">
            {experienceList.map((item) => (
              <div
                key={item.id}
                className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex items-start justify-start gap-6 relative hover"
              >
                <div className="text-lg font-semibold flex-1">
                  <div>{item.companyName}</div>
                  <div className="text-sm">{item.description}</div>
                </div>
                <div className="text-lg border-left">
                  {item.startDate} - {item.endDate}
                </div>
                <div
                  onClick={() => removeEducation(item.id)}
                  className="w-10 h-10 rounded-full bg-gray-100 shadow-lg hover:shadow-2xl right-2 top-2 flex items-center justify-center select-none hover"
                >
                  <span className="material-icons-round">close</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Dialog
        className="relative z-10"
        open={newExperienceModalOpen}
        onClose={() => closeModal()}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg animate__animated animate__zoomIn"
            >
              <div className="bg-white">
                <div className="sm:flex sm:items-start w-full">
                  <div className="text-left w-full">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 mb-0 border-bottom p-2 px-5 flex items-center justify-between"
                    >
                      <div>Add education details</div>
                      <div
                        className="w-10 h-10 rounded-full bg-gray-100 hover flex items-center justify-center"
                        onClick={() => closeModal()}
                      >
                        <span className="material-icons-round">close</span>
                      </div>
                    </DialogTitle>
                    <form onSubmit={handleSubmit} className="p-5 py-3">
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="companyName"
                          >
                            Company name
                          </label>
                          <div className="flex items-center relative mt-3">
                            <input
                              className="form-control w-full"
                              type="text"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                              placeholder="Add a name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="startDate"
                          >
                            Start Date
                          </label>
                          <div className="flex items-center relative mt-3">
                            <input
                              className="form-control w-full"
                              type="date"
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label className="form-check-label mb-4">
                            <input
                              type="checkbox"
                              name="isCurrentlyWorking"
                              checked={formData.isCurrentlyWorking}
                              onChange={handleChange}
                            />{" "}
                            I am currently studying here
                          </label>
                        </div>
                      </div>
                      {!formData.isCurrentlyWorking && (
                        <div className="mt-2 rounded-md">
                          <div className="form-check p-0 mt-2">
                            <label
                              className="form-check-label mb-4"
                              htmlFor="endDate"
                            >
                              End Date
                            </label>
                            <div className="flex items-center relative mt-3">
                              <input
                                className="form-control w-full"
                                type="date"
                                name="endDate"
                                value={formData.endDate}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-2 rounded-md">
                        <div className="form-check p-0 mt-2">
                          <label
                            className="form-check-label mb-4"
                            htmlFor="description"
                          >
                            Description
                          </label>
                          <div className="flex items-center relative mt-3">
                            <textarea
                              name="description"
                              className="form-control w-full min-h-20"
                              value={formData.description}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-full w-full bg-black px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-full"
                        >
                          Save details
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {showResponceCard && responceCard}
    </div>
  );
}

export default Experience;
