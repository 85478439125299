import React, { useEffect, useState } from "react";
import Feed from "./Feed";
import { Link, useNavigate, useParams } from "react-router-dom";
import MediaListDummy from "./Dummies/MediaListDummy";
import { getUserProfessionalInfo } from "../../db";
import app from "../../config/app";
import VibePopup from "../VibePopup";
import Loader from "./Loader";
import PostContent from "./PostContent";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

function UserProfessional({ local }) {
  const [mediaList, setMediaList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);

  const [slideshowOpen, setSlideshowOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const navigate = useNavigate();
  let { dataid, username } = useParams();
  useEffect(() => {
    const fetchDetails = async () => {
      if (username) {
        const res = await getUserProfessionalInfo(username);
        setEducationList(res.education);
        setExperienceList(res.experience);
        setPortfolioList(res.portfolio);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [local, username]);

  const openSlideshow = (images, index) => {
    setCurrentImages(images);
    setCurrentImageIndex(index);
    setSlideshowOpen(true);
  };

  const closeSlideshow = () => {
    setSlideshowOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === currentImages.length - 1 ? 0 : currentIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((currentIndex) =>
      currentIndex === 0 ? currentImages.length - 1 : currentIndex - 1
    );
  };

  return (
    <div className="">
      <div className="p-4 px-6 timeline_profile shadow-sm sm-p-1">
        {loading && (
          <div className="flex align-center justify-center my-10">
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            <div className="items-ce justify-between  mt-4 pt-4 border-tp">
              <h3 className="font-bold text-lg">Education</h3>
              {educationList.length == 0 && !loading && (
                <ul className=" space-y-4 mt-2 text-sm">
                  <div className="mt-2 mb-2">
                    <div className="flex align-center">
                      <img
                        src="/images/willowy-woman-on-screen-doing-online-educational-video-1.png"
                        className="w-48 h-auto object-contain"
                        with={50}
                      />
                      <div className="px-3">
                        <div>No education details added yet.</div>
                      </div>
                    </div>
                  </div>
                </ul>
              )}

              {educationList.length > 0 && !loading && (
                <div className="flex flex-wrap gap-3 py-3">
                  {educationList.map((item) => (
                    <div
                      key={item.id}
                      className="w-full h-auto bg-gray-50 rounded-xl px-3 py-4 flex mt-2 items-start justify-start gap-6 relative hover"
                    >
                      <div className="relative top-1">
                        <span class="material-symbols-outlined">school</span>
                      </div>
                      <div className="text-lg font-semibold flex-1">
                        <div>{item.institutionName}</div>
                        <div className="text-sm description_pnone">
                          <PostContent
                            text={item.description}
                            contentStyle={{}}
                          />
                        </div>
                      </div>
                      <div className="text-sm border-left pl-2">
                        {item.startDate} - {item.endDate}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <hr className="line" />
            <div className="items-ce justify-between  mt-4 pt-4 border-tp">
              <h3 className="font-bold text-lg">Experience</h3>
              {experienceList.length == 0 && !loading && (
                <ul className=" space-y-4 mt-2 text-sm">
                  <div className="mt-2 mb-2">
                    <div className="flex align-center">
                      <img
                        src="/images/willowy-teamwork-with-papers-at-the-office-1.png"
                        className="w-48 h-auto object-contain"
                        with={50}
                      />
                      <div className="px-3">
                        <div>No experience details added yet.</div>
                      </div>
                    </div>
                  </div>
                </ul>
              )}

              {experienceList.length > 0 &&
                experienceList.map((item) => (
                  <div
                    key={item.id}
                    className="w-full h-auto bg-gray-50 mt-2 rounded-xl px-3 py-4 flex items-start justify-start gap-6 relative hover"
                  >
                    <div className="relative top-1">
                      <span class="material-symbols-outlined">apartment</span>
                    </div>
                    <div className="text-lg font-semibold flex-1">
                      <div>{item.companyName}</div>
                      <div>{item.institutionName}</div>
                      <div className="text-sm description_pnone">
                        <PostContent
                          text={item.description}
                          contentStyle={{}}
                        />
                      </div>
                    </div>
                    <div className="text-sm border-left pl-2">
                      {item.startDate} - {item.endDate}
                    </div>
                  </div>
                ))}
            </div>
            <hr className="line" />
            <div className="items-ce justify-between  mt-4 pt-4 border-tp">
              <h3 className="font-bold text-lg mb-2">Portfolio</h3>
              {portfolioList.length == 0 && !loading && (
                <div className="flex-1 xl:space-y-6 space-y-3 gap-2.5 uk-active grid_top_parent">
                  <div className="mt-2 mb-2">
                    <div className="flex align-center">
                      <img
                        src="/images/willowy-people-chatting-on-zoom-meeting-1.png"
                        className="w-48 h-auto object-contain"
                        with={50}
                      />
                      <div className="px-3 w-full">
                        <div>There is no portfolio added yet.</div>
                        <div>
                          When they are created or added, they will appear here
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {portfolioList.map((item) => (
                <div key={item.id} className="h-auto rounded-xl relative gap-5">
                  <div className="flex align-center justify-between">
                    <div className="text-md flex-1 w-full py-0 pb-2 text-mute">
                      {item.title}
                    </div>
                  </div>
                  <div className="overflow-auto">
                    <div className="flex align-center gap-5 flex-wrap">
                      {item.images.map((image, index) => (
                        <div className="hover w-40 h-40 rounded-xl">
                          <img
                            key={index}
                            src={image}
                            className="object-cover w-40 h-40 rounded-xl cursor-pointer portfolio_image"
                            alt="Portfolio"
                            onClick={() => openSlideshow(item.images, index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="line" />
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {slideshowOpen && (
        <Dialog
          className="relative z-10"
          open={slideshowOpen}
          onClose={closeSlideshow}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl"
              >
                <div className="bg-white">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="text-left w-full">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 mb-6 border-bottom p-2 px-5 flex align-center justify-between"
                      >
                        <div>Portfolio</div>
                        <div
                          className="w-10 h-10 rounded-full bg-gray-100 hover flex align-center justify-center"
                          onClick={closeSlideshow}
                        >
                          <span className="material-icons-round">close</span>
                        </div>
                      </DialogTitle>
                      <div className="p-5 py-3 flex justify-center">
                        <img
                          src={currentImages[currentImageIndex]}
                          alt={`Slideshow ${currentImageIndex}`}
                          className="max-h-96 max-w-full object-cover"
                        />
                      </div>
                      <div className="px-2 py-3 sm:flex sm:px-6 w-full mt-6 flex justify-center gap-2">
                        <button
                          onClick={prevImage}
                          className="inline-flex  gap-2 justify-center rounded-full hover bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 sm:ml-3 sm:w-1/5"
                        >
                          <span class="material-symbols-outlined">west</span>
                          <span>Previous</span>
                        </button>
                        {/* <div className="flex-grow"></div> */}
                        <button
                          onClick={nextImage}
                          className="inline-flex gap-2 justify-center rounded-full hover bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 sm:mr-3 sm:w-1/5"
                        >
                          <span>Next</span>
                          <span class="material-symbols-outlined">east</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default UserProfessional;
