import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import app from "../../config/app";
import {
  saveBusinessAccessToIndexedDB,
  saveContactInfoToIndexedDB,
  saveHubAccessToIndexedDB,
  saveInterestsToIndexedDB,
  saveLanguageToIndexedDB,
  savePrivacySettingsToIndexedDB,
  saveProfessionalSettingsToIndexedDB,
  saveProfileImageAndCoverSettingsToIndexedDB,
  saveProfileToIndexedDB,
  saveTimezoneToIndexedDB,
} from "../../db";
import CopyRight from "../components/CopyRight";
import Loader from "../components/Loader";

export default function Login() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [close, setClose] = useState(true);
  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSignIn = async () => {
    setErrorMsg("");
    setLoading(true);
    try {
      const { data } = await axios.post(`${app.auth_request_url}AUTH_LOGIN`, {
        email,
        password,
      });

      if (data.r_status) {
        Cookies.set("sys_user", data.user_activity_id, {
          expires: 365,
        }); // Save the token as a cookie for 365 days

        const profile = data.user_settings.profile[0];
        const {
          business_access_list,
          hub_access_list,
          user_settings: {
            profile: [profile_responce],
          },
        } = data;

        // Prepare IndexedDB operations in parallel
        const saveProfileOps = [
          saveProfileToIndexedDB({
            fullName: profile.fullname,
            username: profile.username,
            bio: profile.bio,
            jobTitle: profile.current_job_title,
            dateOfBirth: profile.date_of_birth,
          }),
          savePrivacySettingsToIndexedDB({
            coverImageUpdate: profile.privacyHideCoverImageUpdate,
            onlineStatusChats: profile.privacyHideOnlineStatusOnChats,
            onlineStatusPosts: profile.privacyHideOnlineStatusOnPosts,
            profileImageUpdate: profile.privacyHideProfilePictureUpdate,
            profileVisibility: profile.privacyHideProfileSearchResults,
          }),
          saveContactInfoToIndexedDB({
            address: profile.address,
            country: profile.country_phone,
            email: profile.email,
            phoneNumber: profile.phone,
          }),
          saveProfessionalSettingsToIndexedDB({
            availabilityStatus: profile.availabilityStatus,
            downloadMyCv: profile.downloadMyCv,
          }),
          saveProfileImageAndCoverSettingsToIndexedDB({
            profile_image: profile.profile_image,
            cover_image: profile.cover_image,
          }),
          saveLanguageToIndexedDB(profile.language || "English"),
          saveTimezoneToIndexedDB(profile.timezone || "Africa/Nairobi"),
          saveInterestsToIndexedDB(profile.interest),
          // saveSkillsToIndexedDB(profile.skills), // assuming skills was meant to be saved
          saveBusinessAccessToIndexedDB(business_access_list),
          saveHubAccessToIndexedDB(hub_access_list),
        ];

        // Execute all IndexedDB operations in parallel
        await Promise.all(saveProfileOps);

        // Handle navigation after sign-in

        // if (currentPath === "/auth/signin" || currentPath === "/Auth/signin") {
        setTimeout(() => {
          window.location = "/";
        }, 10);
        // } else {
        //   setOpen(false);
        // }
        setLoading(false);
      } else {
        setErrorMsg(data.responce);
        setLoading(false);
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      // alert("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Dialog className="relative z-10 auth_modal" open={open} onClose={setClose}>
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in modal_backdrop" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 modal_container bg-white">
          <DialogPanel
            className="relative transform overflow-hidden rounded-lg bg-white text-left  transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            style={{ marginTop: -10, borderRadius: 20 }}
          >
            <div className="bg-white px-0 pb-3 pt-2">
              <div className="sm:flex sm:items-start w-full">
                <div className="text-left w-full">
                  <DialogTitle
                    as="h3"
                    className="px-6 text-base font-semibold justify-center leading-6 text-gray-900 mb-6 border-bottom pb-5 flex align-center justify-between"
                  >
                    <div className="flex align-center flex-col w-full">
                      <div className="h-16 w-16 mr-3 rounded-full mb-3">
                        <img src="/images/icon.png" alt="Icon" />
                      </div>
                      <div>Login to your VibeIn account</div>
                    </div>
                  </DialogTitle>

                  {loading && (
                    <div className="absolute w-full flex align-center justify-center h-full top-0 auth_loader_cont">
                      <Loader />
                    </div>
                  )}
                  <div className="w-full  px-6">
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="w-full form-control"
                        placeholder="Enter email or username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className=" px-6">
                    <div className="mt-2 relative">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        className="w-full form-control"
                        placeholder="Your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        type="div"
                        className="absolute right-3 top-4 text-gray-500 bg-transparent"
                        style={{ background: "transparent !important" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <span class="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span class="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {errorMsg != "" && (
              <div className="px-4 sm:flex sm:px-6 w-full mb-3">{errorMsg}</div>
            )}
            <div className="px-4 py-3 sm:flex sm:px-6 w-full">
              <button
                type="button"
                className="inline-flex justify-center rounded-xl w-full bg-black px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-orange-500  sm:w-full"
                onClick={handleSignIn}
              >
                Log in
              </button>
            </div>
            <div className="flex align-center justify-center mt-2  px-6">
              <div className="text-sm">
                <Link
                  to="/auth/resetpassword"
                  className="font-semibold text-gray-600 hover:text-indigo-500"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="px-4 py-2 sm:flex sm:px-6 w-full text-center justify-center flex flex-wrap">
              Not registered yet?
              <Link to="/auth/signup" className="font-bold ml-2">
                <div>Create an account</div>
              </Link>
            </div>
            <div
              className="px-4 py-0 sm:flex sm:px-6 w-full text-center mb-6 justify-center"
              style={{ width: "100%" }}
            >
              By signing in you agree to VibeIn's Terms of Service.
            </div>
          </DialogPanel>
        </div>
      </div>
      <div className="footer_details_auth">
        <CopyRight showLess={true} />
      </div>
    </Dialog>
  );
}
