import React, { useEffect } from "react";
import Slider from "../components/Slider";
import JobList from "../components/JobList";
import { Link } from "react-router-dom";

function MainJobsListPage({ jobs, local }) {
  useEffect(() => {}, [jobs]);
  return (
    <div className="py-4 align-center justify-center mid_container_inn">
      {/* all settings goes here */}
      <div className="w-full h-auto">
        {/* list serached jobs above */}

        {/* <div className="flex align-center justify-between my-4 px-4">
          <div className="text-xl font-bold">Recent jobs</div>
          <Link
            to="/discover/hubs"
            className="h-8 w-32 flex align-center justify-center hover rounded-full text-center break-keep font-medium"
          >
            See more
          </Link>
        </div>
        <div className="">
          <div className="px-2 overflow-hidden rounded-xl">
            <Slider slideWidth={500} slidesVisible={2}>
              {jobs.map((item, index) => (
                <JobList data={item} />
              ))}
            </Slider>
          </div>
        </div> */}

        <div className="flex align-center justify-between my-4 mb-0 mt-0 px-7">
          <div className="text-xl font-bold">Jobs near you</div>
        </div>
        <div className="grid grid-cols-2 py-6 gap-3 grid_jobs px-7">
          {jobs.map((item, index) => (
            <JobList data={item} local={local} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MainJobsListPage;
