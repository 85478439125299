import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopUserMenu from "./components/TopUserMenu";
import Settings from "./Hub/Settings";
import CopyRight from "./components/CopyRight";
import Members from "./Hub/Members";
import Preview from "./Hub/Preview";
import { get_menus } from "./Hub/menus_config";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Overview from "./Hub/Overview";
import Followers from "./Hub/Followers";
import { getHubFromIndexedDB, getHubProfileFromIndexedDB } from "../db";
import app from "../config/app";

function HubAdmin({ local }) {
  const navigate = useNavigate();
  let { id, page, focus } = useParams();
  let component = "";
  const location = useLocation();
  const currentPath = location.pathname;
  const [showSearch, setShowSearch] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [hubUsername, setHubUsername] = useState("");
  const [hubList, setHubList] = useState([]);
  const [hubProfile, setHubProfile] = useState();

  const paths = get_menus(id, "admin_general");

  useEffect(() => {
    document.body.classList.add("wide_body");
    document.body.classList.add("message_body");
    document.body.classList.add("admin_body");
    // Cleanup function to remove the class when the component unmounts

    return () => {
      document.body.classList.remove("wide_body");
      document.body.classList.remove("message_body");
      document.body.classList.remove("admin_body");
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  // get this page info
  useEffect(() => {
    const fetchHublist = async () => {
      try {
        const list = await getHubFromIndexedDB();
        console.log(list);
        setHubList(list);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchHubProfile = async () => {
      const dt = await getHubProfileFromIndexedDB(id);
      setHubProfile(dt);

      console.log("Hub profile", dt[0].hub_info[0].username);
      setHubUsername(dt[0]?.hub_info[0]?.username);
    };

    fetchHubProfile();

    fetchHublist();
  }, [page, id, local]);

  if (page) {
    if (page == "overview") {
      component = <Overview local={local} />;
    } else if (page == "preview") {
      component = <Preview local={local} />;
    } else if (page == "members") {
      component = <Members local={local} />;
    } else if (page == "settings") {
      component = <Settings local={local} />;
    } else if (page == "followers") {
      component = <Followers local={local} />;
    }
  } else {
    page = "overview";
    component = <Overview local={local} />;
  }

  const handleSearchUpdate = (value) => {
    let searches = get_menus(id, "", value);
    setShowSearch(true);
    setSearchResults(searches);
  };

  return (
    <>
      <div className="min-h-screen mainadmin_pane flex-1">
        <div className="px-2 py-1 rounded-xl flex align-center justify-between headers pr-0 top_header_cont">
          <div className="flex align-center justifty-start">
            <div
              className="h-10 min-w-10 hover mr-3 rounded-full align-center flex justify-center"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
              </svg>
            </div>
            <div className="h-10 w-full mr-3 rounded-full align-center flex">
              <div className="font-bold text-xl">Manage community</div>
            </div>
          </div>
          <TopUserMenu />
        </div>

        <div className="flex align-start">
          <div className="p-4 w-full max-w-80 min-w-80 has_border_right left_side_settings">
            {/* search features and settings */}
            <div className="relative w-full flex align-center gap-3">
              <div className="w-full relative">
                <span class="material-icons-round absolute top-3 left-4 m-auto h-auto">
                  search
                </span>
                <input
                  className="form-control search_input w-full rounded-xl pl-4 has_icon"
                  placeholder="Search features"
                  onFocus={() => setShowSearch(true)}
                  //   onFocusCapture={() => alert()}
                  onBlur={() => {
                    setTimeout(() => setShowSearch(false), 200);
                  }}
                  onChange={(e) => handleSearchUpdate(e.target.value)}
                />
              </div>

              {/* switch business */}
              <Menu>
                <MenuButton className="align-center justify-center flex">
                  <div className="w-10 h-10 min-w-10 min-h-10 bg-gray-100 rounded-xl hover cursor-pointer flex align-center justify-center">
                    <span class="material-icons-round">people_alt</span>
                  </div>
                </MenuButton>

                <MenuItems
                  transition
                  anchor="bottom start"
                  className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container account_dropdown_container"
                >
                  <div className="my-3 px-3">Switch hub</div>
                  <hr className="line" />

                  {hubList.length > 0 &&
                    hubList.map((item, index) => (
                      <MenuItem>
                        <Link
                          to={`/hubadmin/${item.hub_info[0].hub_id}/${page}`}
                          className="group flex h-auto my-1 w-full items-center gap-3 rounded-lg py-2.5 px-3 pl-1 data-[focus]:bg-white/10"
                        >
                          <div className="flex align-center arrow_more">
                            <img
                              src={
                                item.hub_info[0].profile_image == ""
                                  ? "/images/hubuser.png"
                                  : app.media_url +
                                    "/thumb/" +
                                    item.hub_info[0].profile_image
                              }
                              className="w-10 h-10 min-w-10 min-h-10 rounded-full object-cover"
                            />
                          </div>
                          <div className="w-full">
                            <div className="w-full text-left text-lg font-semibold">
                              {item.hub_info[0].hub_name}
                            </div>
                            <div className="-mt-1 text-uppercase text-sm">
                              {item.hub_info[0].type}
                            </div>
                          </div>

                          <div className="flex align-center arrow_more">
                            <span className="material-icons-round">
                              chevron_right
                            </span>
                          </div>
                        </Link>
                      </MenuItem>
                    ))}
                </MenuItems>
              </Menu>

              <div
                className={
                  showSearch && searchResults.length > 0
                    ? "absolute top-12 -mt-1 search_resutls_drop_down w-full min-h-20 rounded-xl bg-gray-200 z-10 shadow-xl"
                    : "absolute top-12 -mt-1 display-none search_resutls_drop_down w-full min-h-20 rounded-xl bg-gray-200 z-10 shadow-xl"
                }
              >
                <div className="p-5">Search results</div>
                <div className="result_list p-1">
                  {searchResults.map((item, index) => (
                    <Link
                      to={item.path}
                      className="w-full hover h-10 flex align-center justify-between rounded-xl gap-3 pl-2"
                    >
                      <div className="w-8 h-8 rounded-full flex align-center justify-center">
                        <span class="material-icons-round">{item.icon}</span>
                      </div>
                      <div className="w-full h-8 flex align-center pl-1">
                        {item.title}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-4">
              {/* {name} */}
              {/* <div className='my-3 px-4 mt-2'>Manage your professional profile</div> */}
              <Link to={`/communities/${hubUsername}`} className="py-0.5 block">
                <div className="flex align-center hover p-2 px-3 rounded-md sidebar_item">
                  <div className="flex align-center w-10 h-10">
                    <span className="material-icons-round">layers</span>
                  </div>
                  <div className="flex align-center flex-1">Preview hub</div>
                  <div className="flex align-center">
                    <span class="material-icons-round">chevron_right</span>
                  </div>
                </div>
              </Link>

              {paths.map((item, index) => (
                <Link key={item.id} to={item.path} className="py-0.5 block">
                  <div
                    className={
                      currentPath == item.path
                        ? "flex align-center hover p-2 px-3 rounded-md sidebar_item active"
                        : "flex align-center hover p-2 px-3 rounded-md sidebar_item"
                    }
                  >
                    <div className="flex align-center w-10 h-10">
                      <span
                        className={
                          currentPath == item.path
                            ? "material-icons-round"
                            : "material-symbols-outlined"
                        }
                      >
                        {item.icon}
                      </span>
                    </div>
                    <div className="flex align-center flex-1">{item.title}</div>
                    {item.has_sub ? (
                      <div className="flex align-center">
                        <span class="material-icons-round">chevron_right</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>
              ))}
            </div>

            <hr className="line" />
            <a href="">
              <div class="px-2 py-1 hover rounded-xl flex align-center">
                <div class="icon_cont h-8 w-8 mr-3 rounded-full flex align-center text-normal justify-center text-center ">
                  <span class="material-icons-round">help</span>
                </div>
                <div class="h-10 rounded-full flex align-center px-4 py-2 text-normal flex-1 w-full">
                  VibeIn communities
                </div>
                <div class="flex align-center">
                  <span class="material-icons-round">arrow_outward</span>
                </div>
              </div>
            </a>
            <a href="">
              <div class="px-2 py-1 hover rounded-xl flex align-center">
                <div class="icon_cont h-8 w-8 mr-3 rounded-full flex align-center text-normal justify-center text-center ">
                  <span class="material-icons-round">info</span>
                </div>
                <div class="h-10 rounded-full flex align-center px-4 py-2 text-normal flex-1 w-full">
                  Communities policy
                </div>
                <div class="flex align-center">
                  <span class="material-icons-round">arrow_outward</span>
                </div>
              </div>
            </a>
          </div>
          <div className="w-full right_admin_content">
            <div className="pannel_component p-4 pt-0">{component}</div>
            <div className="mt-4 p-4">
              <CopyRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubAdmin;
