import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import app from "../../config/app";
import { submitJoinHub } from "../../db";

function HubHorizontal({ data, simple }) {
  const [joinStatus, setJoinStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setJoinStatus(data.join_status);
    setLoading(false);
  }, []);

  const handleJoin = async (hub_id) => {
    joinStatus ? setJoinStatus(false) : setJoinStatus(true);
    // alert(username);
    try {
      const data = await submitJoinHub(hub_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <div className="flex align-start py-2 master_hover relative my-1">
      <div className="h-10 min-w-10 bg-gray-200 mr-3 rounded-full">
        <a href="/Profile/Master">
          <img
            src={data?.profile_image}
            className="h-10 min-w-10 rounded-full object-cover"
          />
        </a>
      </div>
      <div className="w-full">
        <div className="flex align-center w-full">
          <div className="h-auto w-full mr-4 rounded-full">
            <a href="/Profile/Master">
              <div className="font-semibold false">
                <div className="flex align-center gap-1">{data?.hub_name}</div>
              </div>
              <div className="text relative  text-mute text-sm">
                @{data.username}
              </div>
            </a>
          </div>
          <div
            className={`h-10 w-40 rounded-full font-normal align-center justify-center text-center flex follow_btn ${
              joinStatus ? "following" : "follow"
            }`}
            onClick={() => handleJoin(data.hub_id)}
            // style={{ width: 90 }}
          >
            {joinStatus ? "Joined" : "Join"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HubHorizontal;
