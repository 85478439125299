import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { submitFollowPage, submitPageBookmark } from "../../db";

function BusinessSlide({ data, simple }) {
  const [followStatus, setFollowtatus] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [followersCount, setFollowersCount] = useState(0);
  const [followersList, setFollowersList] = useState([]);

  useEffect(() => {
    setFollowtatus(data?.follow_status);
    setSaveStatus(data?.save_status);
    setFollowersCount(data?.followers);
    setFollowersList(data?.follow_list);
    setLoading(false);
  }, []);

  const handleFollow = async (page_id) => {
    if (followStatus) {
      setFollowtatus(false);
      setFollowersCount(followersCount - 1);
    } else {
      setFollowtatus(true);
      setFollowersCount(followersCount + 1);
    }
    // alert(username);
    try {
      const data = await submitFollowPage(page_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleSave = async (page_id) => {
    saveStatus ? setSaveStatus(false) : setSaveStatus(true);
    // alert(username);
    try {
      const data = await submitPageBookmark(page_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <div className="w-auto border-solid py-0 pb-3 hub_card_follow text-left">
      <Link to={`/page/@${data?.username}`} className="relative pb-3 w-full">
        <img
          src={data?.cover_image}
          className="w-full h-44 object-cover hub_cover"
        />
        {data?.job_openings > 0 && (
          <div className="bg-green-600 p-3 flex align-center justify-center absolute right-4 top-4 rounded-full text-sm px-2 py-1 shadow-sm">
            Hiring
          </div>
        )}
      </Link>
      <div className="flex align-start w-full px-2">
        <img
          src={data?.profile_image}
          className="w-14 h-14 min-w-14 w-14 max-h-14 min-h-14 w-14 max-h-14 rounded-full object-cover"
        />
        <div className="w-full px-3">
          <Link to={`/page/@${data?.username}`}>
            <div className="font-semibold">{data?.business_name}</div>
            <div className="text-sm text-mute">@{data?.username}</div>
            <div className="w-full  flex align-center">{data.category}</div>
            {followersCount > 0 && (
              <div className="flex align-center justify-start">
                <div className="flex py-1">
                  {followersList.length > 0 &&
                    followersList.map((item, index) => (
                      <img
                        src={item.user_profile[0]?.profile_image}
                        className="w-5 h-5 min-w-5 w-5 max-h-5 min-h-5 w-5 max-h-5 rounded-full object-cover -ml-1"
                      />
                    ))}
                </div>
                <div className="text-sm ml-2">{followersCount} Followers</div>
              </div>
            )}
          </Link>
          <div className="w-full my-0">
            <div className="flex w-full gap-2 -ml-2">
              <div
                onClick={() => handleSave(data.page_id)}
                className="border-1 flex align-center rounded-full px-3 py-1.5 mt-3 w-full text-center justify-center text-sm hover"
              >
                {saveStatus ? "Saved" : "Save"}
              </div>
              <div
                className={`h-10 w-40 rounded-full font-normal align-center justify-center text-center flex  mt-3 follow_btn ${
                  followStatus ? "following" : "follow"
                }`}
                onClick={() => handleFollow(data.page_id)}
                // style={{ width: 90 }}
              >
                {followStatus ? "Following" : "Follow"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessSlide;
