import React, { useEffect, useState } from "react";
import FollowHorizontal from "./FollowHorizontal";
import { getDiscoverFollowList } from "../../db";
import { Link } from "react-router-dom";

function FollowListReused({ more }) {
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);

  const MemoFollowHorizontal = React.memo(FollowHorizontal);
  useEffect(() => {
    fetchFollowListData();
  }, []);

  const fetchFollowListData = async () => {
    try {
      const data = await getDiscoverFollowList(5, 0);
      console.log("Users - > ", data);
      setUsersList(data.users);
      setLoading(false);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <div>
      <div className="flex align-center justify-center mb-4">
        <div className="w-full rounded-full mr-10 align-center justify-center font-semibold">
          Who to follow
        </div>
        <Link
          to={`${more ? more : "/discover/people"}`}
          className="h-8 flex align-center justify-center w-40 hover rounded-full text-center break-keep text-sm"
        >
          See more
        </Link>
      </div>
      {usersList.length > 0 &&
        usersList.map((item, index) => (
          <MemoFollowHorizontal simple={true} data={item} />
        ))}
    </div>
  );
}

export default FollowListReused;
