import React from "react";
import PostContent from "../../../components/PostContent";

function About({ data }) {
  return (
    <div>
      {data?.bio != "" ? (
        <div className="flex align-start gap-3 py-3 px-4">
          <div className="py-0">
            <div className="h-16 w-16 bg-gray-100 rounded-full flex align-center justify-center">
              <span class="material-symbols-outlined">lightbulb</span>
            </div>
          </div>
          <div style={{ maxWidth: 400 }} className="bg-gray-100 rounded-xl">
            <PostContent text={data?.bio} contentStyle={{}} />
          </div>
        </div>
      ) : (
        <div className="mt-0 text-gray-500">
          <div className="flex align-center">
            <img
              src="/images/colors-online-meeting-via-group-call.gif"
              className="w-48 h-auto object-contain"
              width={100}
            />
            <div className="px-3">
              <div className="">No details for this candidate.</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default About;
