import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { submitHubBookmark, submitJoinHub } from "../../db";

function HubSlide({ data, simple }) {
  const [joinStatus, setJoinStatus] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [membersCount, setMembersCount] = useState(0);
  const [membersList, setMembersList] = useState([]);

  useEffect(() => {
    setJoinStatus(data?.join_status);
    setSaveStatus(data?.save_status);
    setMembersCount(data?.members);
    setMembersList(data?.members_list);
    setLoading(false);
  }, []);

  const handleJoin = async (hub_id) => {
    if (joinStatus) {
      setJoinStatus(false);
      setMembersCount(membersCount - 1);
    } else {
      setJoinStatus(true);
      setMembersCount(membersCount + 1);
    }
    // alert(username);
    try {
      const data = await submitJoinHub(hub_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  const handleSave = async (hub_id) => {
    saveStatus ? setSaveStatus(false) : setSaveStatus(true);
    // alert(username);
    try {
      const data = await submitHubBookmark(hub_id);
      console.log("Follow - > ", data);
    } catch (error) {
      console.error("Details:", error);
    }
  };

  return (
    <div className="w-auto border-solid py-0 pb-6 hub_card_follow text-left relative">
      <Link
        to={`/communities/${data?.username}`}
        className="relative pb-3 w-full"
      >
        <img
          src={data?.cover_image}
          className="w-full h-44 object-cover hub_cover"
        />
      </Link>

      {data?.can_edit && (
        <Link
          to={`/hubadmin/${data.hub_id}/settings`}
          className="absolute top right-2 top-2 bg-gray-100 rounded-full px-3 py-1 text-sm flex align-center justify-center gap-2"
        >
          <span class="material-symbols-outlined" style={{ fontSize: 15 }}>
            edit
          </span>
          <span>Edit</span>
        </Link>
      )}

      <div className="flex align-start w-full px-2">
        <img
          src={data?.profile_image}
          className="w-14 h-14 min-w-14 w-14 max-h-14 min-h-14 w-14 max-h-14 rounded-full object-cover"
        />
        <div className="w-full px-3">
          <Link to={`/communities/${data?.username}`}>
            <div className="font-semibold">{data?.hub_name}</div>
            <div className="text-sm text-mute">@{data?.username}</div>
            <div className="flex align-center justify-start">
              <div className="flex py-1">
                {membersList.length > 0 &&
                  membersList.map((item, index) => (
                    <img
                      src={item.user_profile[0]?.profile_image}
                      className="w-5 h-5 min-w-5 w-5 max-h-5 min-h-5 w-5 max-h-5 rounded-full object-cover -ml-1"
                    />
                  ))}
              </div>
              <div className="text-sm ml-2">
                {membersCount} Member{membersCount > 1 && "s"}
              </div>
            </div>
          </Link>
          <div className="w-full my-0 mb-4">
            <div className="flex w-full gap-2">
              <div
                onClick={() => handleSave(data.hub_id)}
                className="border-1 flex align-center rounded-full px-3 py-1.5 mt-3 w-full text-center justify-center text-sm hover"
              >
                {saveStatus ? "Saved" : "Save"}
              </div>
              <div
                className={`h-10 w-40 rounded-full font-normal align-center justify-center text-center flex  mt-3 follow_btn ${
                  joinStatus ? "following" : "follow"
                }`}
                onClick={() => handleJoin(data.hub_id)}
                // style={{ width: 90 }}
              >
                {joinStatus ? "Joined" : "Join"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.category != "" && (
        <>
          <hr className="line" />
          <div className="flex align-center mt-2">
            <div className="w-full h-6 flex align-center">{data.category}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default HubSlide;
