import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import app from "../../config/app";
import ChatTextContent from "../components/ChatTextContent";
import { extractLinks } from "../../db";
import LinkPreview from "../components/LinkPreview";
import MessagesItem from "./MessagesItem";

function SearchMessages({ conversationData, user }) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); // State to manage search input

  // Helper function to extract URLs from a string
  const extractUrls = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex);
  };

  // Sort and filter conversation data based on search term
  const sortedMessages = conversationData
    .sort((a, b) => new Date(b.date_time) - new Date(a.date_time)) // Sort by date descending
    .filter((item) =>
      item.chat_body.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div>
      <div className="flex align-center justify-between gap-3 px-4 py-1 mt-2">
        <div
          className="w-10 h-10 min-w-10 bg-gray-100 rounded-full flex align-center justify-center hover cursor-pointer animate__animated animate__zoomIn animate_fast"
          onClick={() => navigate(-1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5f6368"
          >
            <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z"></path>
          </svg>
        </div>
        <div className="w-full relative p-3">
          <span className="material-icons-round absolute top-5 right-5 m-auto h-auto z-10">
            search
          </span>
          <input
            className="form-control search_input w-full rounded-full pr-14 pl-4 animate__animated animate__slideInRight animate_fast"
            placeholder="Search messages"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
          />
        </div>
      </div>
      <hr className="line_sm" />
      <div className="px-4 py-2">
        {sortedMessages.length > 0 ? "Search results" : "No messages found"}
      </div>
      <div className="search_message_cont">
        {sortedMessages.length > 0 &&
          sortedMessages.map((item, index) => {
            const urls = extractUrls(item.chat_body);

            return (
              <>
                {item.chat_body != "" && (
                  <div key={index} className="hover px-4 py-3 pb-0">
                    <div className="flex align-center justify-between">
                      <div className="font-semibold">
                        {app.current_user === item.sender
                          ? "You"
                          : user.fullname}
                      </div>
                      <div className="text-sm mt-2">
                        <div className="flex align-center gap-2 text-sm">
                          <small>
                            {new Date(item.date_time).toLocaleDateString()} -{" "}
                            {new Date(item.date_time).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="text-mute">
                      <MessagesItem item={item} />
                    </div>

                    <hr
                      className="line_sm"
                      style={{ margin: 0, marginTop: 10 }}
                    />
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
}

export default SearchMessages;
