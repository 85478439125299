import React, { useState } from "react";
import { parseContent } from "../../db";

const ChatTextContent = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const content = text;
  if (!content) return null;

  const isLongContent = content.length > 200;

  // Only add "..." if the content is not expanded and is longer than 200 characters
  const displayText = isExpanded
    ? content
    : isLongContent
    ? `${content.slice(0, 200)}...`
    : content;

  const parsedContent = parseContent(displayText);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: parsedContent }} />
      {isLongContent && (
        <button
          onClick={toggleReadMore}
          className="text-sm mt-2"
          style={{ opacity: 0.9 }}
        >
          {isExpanded ? "Show Less" : "Read More"}
        </button>
      )}
    </>
  );
};

export default ChatTextContent;
