import React, { useEffect, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/16/solid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getJobInfo, submitOtherJobInfo } from "../../../../db";
import app from "../../../../config/app";
import Responce from "../../../components/Responce";

function MoreInfo({ local }) {
  const navigate = useNavigate();
  let { id, page, focus, focus_id } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;

  const [responceCard, setResponceCard] = useState("");
  const [showResponceCard, setShowResponceCard] = useState(false);
  const [jobInfo, setJobInfo] = useState();
  const [jobId, setJobId] = useState(
    Date.now().toString() + "@" + app.current_user
  );

  useEffect(() => {
    const fetchDetails = async () => {
      if (focus_id) {
        const res = await getJobInfo(focus_id, id);
        setJobInfo(res);
        setJobId(res.job_info[0].row.activity_id);
        setFormData({
          ...formData,
          job_name: res.job_info[0].row.job_name,
          about: res.job_info[0].row.about,
          applicant_deadline: res.job_info[0].row.applicant_deadline,
          experience_level: res.job_info[0].row.experience_level,
          job_type: res.job_info[0].row.job_type,
          minimum_salary: res.job_info[0].row.minimum_salary,
          maximum_salary: res.job_info[0].row.maximum_salary,
          year_of_experience: res.job_info[0].row.year_of_experience,
          qualification: res.job_info[0].row.qualification,
          gender: res.job_info[0].row.gender,
          salary_type: res.job_info[0].row.salary_type,
          application_link: res.job_info[0].row.application_link,
        });
        console.log("Job details", res);
      }
    };
    fetchDetails();
  }, [focus, currentPath, focus_id, id, page]);

  const [formData, setFormData] = useState({
    job_name: "",
    applicant_deadline: "",
    about: "",
    experience_level: "",
    job_type: "",
    minimum_salary: "",
    maximum_salary: "",
    year_of_experience: "",
    qualification: "",
    gender: "",
    salary_type: "",
    application_link: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let job_id = "";
    if (focus_id) {
      job_id = focus_id;
    } else {
      job_id = jobId;
    }

    const res = await submitOtherJobInfo({
      business: id,
      job_id: job_id,
      formData: formData,
    });
    if (res) {
      setShowResponceCard(true);
      setTimeout(() => {
        setShowResponceCard(false);
      }, 3000);
      setResponceCard(
        <Responce data={{ text: res.responce, status: res.r_status }} />
      );
      setJobInfo(res.job_info);
      console.log("Jobinfo", res.job_info);
      if (res.r_status === true) {
        // navigate(`/admin/${id}/createjob/skills/${job_id}`);
        // alert("Job details saved");
      }
    }
  };

  return (
    <div className="py-3">
      <form
        onSubmit={handleSubmit}
        className="border-1 mt-3 px-8 py-5 rounded-xl min-h-60 flex flex-column align-start justify-center"
      >
        <div className=" py-3 text-xl font-medium">Add more job details</div>
        <div className="w-full grid grid-cols-3 align-center  gap-3">
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Experience level</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">person</span>
              </div>
              <select
                name="experience_level"
                className="form-control has_icon w-full c_select"
                value={formData.experience_level}
                onChange={handleChange}
              >
                <optgroup label="">
                  <option value="">Select level</option>
                  <option value="Attachment">Attachment</option>
                  <option value="Internship">Internship</option>
                  <option value="Internal">Internal</option>
                  <option value="Junior">Junior</option>
                  <option value="Mid Level">Mid Level</option>
                  <option value="Senior">Senior</option>
                </optgroup>
              </select>
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Job Type</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">engineering</span>
              </div>
              <select
                name="job_type"
                className="form-control has_icon w-full c_select"
                value={formData.job_type}
                onChange={handleChange}
              >
                <optgroup label="">
                  <option value="">Select below</option>
                  <option value="On-site">On-site</option>
                  <option value="Remote fulltime">Remote fulltime</option>
                  <option value="Freelance">Freelance</option>
                  <option value="Seasonal">Seasonal</option>
                </optgroup>
              </select>
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Minimum Payout/Salary</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">payments</span>
              </div>
              <input
                type="text"
                className="form-control has_icon w-full"
                required=""
                name="minimum_salary"
                placeholder="Make it simple"
                value={formData.minimum_salary}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Maximum Payout/Salary</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">payments</span>
              </div>
              <input
                type="text"
                className="form-control has_icon w-full"
                required=""
                name="maximum_salary"
                placeholder="Make it simple"
                value={formData.maximum_salary}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Years of experience</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">event</span>
              </div>
              <select
                name="year_of_experience"
                className="form-control has_icon w-full c_select"
                value={formData.year_of_experience}
                onChange={handleChange}
              >
                <optgroup label="">
                  <option value="">Select below</option>
                  <option value="Less than 1 year">Less than 1 year</option>
                  <option value="1 Years">1 Years</option>
                  <option value="2 Years">2 Years</option>
                  <option value="3 Years">3 Years</option>
                  <option value="4 Years">4 Years</option>
                  <option value="5 Years">5 Years</option>
                  <option value="Plus 5 Years">Plus 5 Years</option>
                </optgroup>
              </select>
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Qualification</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">school</span>
              </div>
              <select
                name="qualification"
                className="form-control has_icon w-full c_select"
                value={formData.qualification}
                onChange={handleChange}
              >
                <optgroup label="">
                  <option value="">Select below</option>
                  <option value="O Level">O Level</option>
                  <option value="Form 4 Leaver">Form 4 Leaver</option>
                  <option value="Certificate Level">Certificate Level</option>
                  <option value="Diploma Level">Diploma Level</option>
                  <option value="Bachelor Level">Bachelor Level</option>
                  <option value="Master Degree Level">
                    Master Degree Level
                  </option>
                  <option value="PHD Level">PHD Level</option>
                </optgroup>
              </select>
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Gender</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">wc</span>
              </div>
              <select
                name="gender"
                className="form-control has_icon w-full c_select"
                value={formData.gender}
                onChange={handleChange}
              >
                <optgroup label="">
                  <option value="">Select below</option>
                  <option value="All">All</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </optgroup>
              </select>
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="text-normal my-2 mt-5">Salary Type</div>
            <div className="flex align-center relative mt-3">
              <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
                <span className="material-icons-round">currency_exchange</span>
              </div>
              <select
                name="salary_type"
                className="form-control has_icon w-full c_select"
                value={formData.salary_type}
                onChange={handleChange}
              >
                <optgroup label="">
                  <option value="">Select below</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div className="w-full flex-1">
          <div className="text-normal my-2 mt-5">
            Application link (Optional)
          </div>
          <div className="flex align-center relative mt-3">
            <div className="absolute top-0 bottom-0 m-auto h-full w-8 flex align-center justify-center group_input_icon">
              <span className="material-icons-round">link</span>
            </div>
            <input
              type="url"
              className="form-control has_icon w-full"
              name="application_link"
              placeholder="Add a link"
              value={formData.application_link}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mt-3 flex align-center gap-3 mt-6">
          <button type="submit" className="btn">
            <div className="w-full">Publish</div>
          </button>
        </div>
      </form>
      {showResponceCard && responceCard}
    </div>
  );
}

export default MoreInfo;
