import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import MessagesItem from "./MessagesItem";

function MessagesList({
  onReplay,
  selectMessages,
  activeChatid,
  conversationData,
}) {
  const [selectMsg, setSelectMsg] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [currentConversationData, setCurrentConversationData] = useState([]);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [allMedia, setAllMedia] = useState([]);

  useEffect(() => {
    setSelectMsg(selectMessages);
    setCurrentConversationData(conversationData);

    // Aggregate all media from the conversation
    const media = [];
    conversationData.forEach((item) => {
      if (item.uploads.images.length > 0) {
        item.uploads.images.forEach((image) => {
          media.push({
            type: "image",
            src:
              image.base64 ||
              image.blobUrl ||
              image?.upload_path + "/posts/" + image?.row?.filename,
          });
        });
      }
      if (item.uploads.videos.length > 0) {
        item.uploads.videos.forEach((video) => {
          media.push({
            type: "video",
            thumbnail:
              video.thumbnail ||
              video?.upload_path + "/posts/" + video?.row?.thumb_filename,
            src:
              video.base64 ||
              video.blobUrl ||
              video?.upload_path + "/posts/" + video?.row?.filename,
          });
        });
      }
    });
    setAllMedia(media);
  }, [selectMessages, activeChatid, conversationData]);

  const handleSelect = (id) => {
    if (selectedMessages.includes(id)) {
      setSelectedMessages((prev) =>
        prev.filter((messageId) => messageId !== id)
      );
    } else {
      setSelectedMessages((prev) => [...prev, id]);
    }
  };

  const handleMediaClick = (index) => {
    setSelectedMediaIndex(index);
  };

  const closeModal = () => {
    setSelectedMediaIndex(null);
  };

  const showNextMedia = () => {
    setSelectedMediaIndex((prevIndex) =>
      prevIndex < allMedia.length - 1 ? prevIndex + 1 : 0
    );
  };

  const showPreviousMedia = () => {
    setSelectedMediaIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : allMedia.length - 1
    );
  };

  const selectMediaFromThumbnail = (index) => {
    setSelectedMediaIndex(index);
  };

  return (
    <>
      {selectedMessages.length > 0 && (
        <div
          className="w-full border-bottom sticky top-0 headers flex align-center justify-between px-3 animate__animated animate__slideInDown animate_fast"
          style={{ zIndex: 12 }}
        >
          <div className="flex align-center gap-1 justift-start">
            <div
              className="w-8 h-8 flex align-center justify-center rounded-full hover"
              onClick={() => (setSelectedMessages([]), setSelectMsg(false))}
            >
              <span className="material-symbols-outlined">close</span>
            </div>
            <div className="text-lg font-semibold">
              Selected {selectedMessages.length}
            </div>
          </div>
          <div className="flex align-center justify-center gap-2">
            <div className="w-8 h-8 flex align-center justify-center rounded-full hover">
              <span className="material-symbols-outlined">forward</span>
            </div>
            <div className="w-8 h-8 flex align-center justify-center rounded-full hover">
              <span className="material-symbols-outlined">keep</span>
            </div>
            <div className="w-8 h-8 flex align-center justify-center rounded-full hover">
              <span className="material-symbols-outlined">delete</span>
            </div>
          </div>
        </div>
      )}

      {currentConversationData.map((item, index) => (
        <div key={index} className="relative flex align-start flex-row w-full">
          {selectedMessages.includes(item.activity_id) && (
            <div className="absolute w-full h-full left-0 select_msg_overlay bg-gray-200 opacity-50"></div>
          )}

          {selectMsg == true && (
            <div className="px-2 mt-3 z-1 relative left-3">
              <div
                className={`border-2 border-red-100 h-8 w-8 rounded-full align-center relative overflow-hidden justify-center flex cursor-pointer ${
                  selectedMessages.includes(item.activity_id)
                    ? "bg-gray-100"
                    : "bg-white"
                }`}
                onClick={() => handleSelect(item.activity_id)}
              >
                {selectedMessages.includes(item.activity_id) && (
                  <span className="material-symbols-outlined text-white animate__animated animate__zoomIn animate_fast">
                    check
                  </span>
                )}
              </div>
            </div>
          )}

          <div
            className="py-3 px-5 w-full"
            onClick={() => selectMsg == true && handleSelect(item.activity_id)}
          >
            <div
              className={`${
                item.user === "you"
                  ? "left_message_cont justify-start justify-content-start"
                  : "right_message_cont justify-end justify-content-end flex-row-reverse"
              } flex align-start gap-3`}
            >
              {/* <div className="w-8 min-w-8 h-8 min-h-8 bg-gray-100 rounded-full profile_image_chat_message">
                <img
                  className="w-8 min-w-8 h-8 min-h-8 rounded-full"
                  src="/images/user.png"
                />
              </div> */}
              <div
                className={`flex message_item ${
                  item.user === "you" ? "align-start" : "align-end"
                } flex-col z-10`}
              >
                {item.uploads.images.length > 0 && (
                  <div className="flex align-center gap-1 w-full flex-row flex-wrap media_cont_chat chat">
                    {item.uploads.images.map((image, imgIndex) => (
                      <>
                        {imgIndex < 4 ? (
                          <>
                            <div
                              key={imgIndex}
                              className={`bg-gray-100 item relative ${
                                item.uploads.images.length > 1 ? "half" : "full"
                              } hover cursor-pointer`}
                              onClick={() =>
                                handleMediaClick(
                                  allMedia.findIndex(
                                    (media) =>
                                      media.src ===
                                      (image.base64 ||
                                        image.blobUrl ||
                                        image?.upload_path +
                                          "/posts/" +
                                          image?.row?.filename)
                                  )
                                )
                              }
                            >
                              <img
                                className="w-full h-full object-cover"
                                src={
                                  image.base64 ||
                                  image.blobUrl ||
                                  image?.upload_path +
                                    "/posts/" +
                                    image?.row?.filename
                                }
                                alt={`Image ${imgIndex}`}
                              />
                              {item.uploads.images.length > 4 &&
                                imgIndex == 3 && (
                                  <div className="absolute top-0 left-0 right-0 bottom-0 m-auto">
                                    <div className="bg-black opacity-70 absolute top-0 left-0 right-0 bottom-0 m-auto"></div>
                                    <div className="absolute top-0 left-0 right-0 bottom-0 m-auto text-5xl align-center justify-center w-full h-full flex font-semibold">
                                      +{item.uploads.images.length - 4}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </div>
                )}

                {item.uploads.documents.length > 0 && (
                  <div className="flex align-center gap-1 w-full flex-row flex-wrap media_cont_chat chat">
                    {item.uploads.documents.map((document, imgIndex) => (
                      <>
                        <div
                          key={imgIndex}
                          className="bg-gray-100 item relative w-full hover cursor-pointer px-3 py-2"
                        >
                          <div className="w-full h-full object-cover flex align-center gap-3">
                            <a
                              href={document.base64}
                              download={document.name}
                              className="align-center justify-center"
                            >
                              <img
                                src="/images/doc2.png"
                                className="w-12 h-12 object-contain p-1 rounded-sm"
                                alt="Attachment icon"
                              />
                            </a>
                            <div className="flex-1 w-fill border-left pl-5">
                              <div className="text-mute">{document.name}</div>
                              <div className="text-sm mt-2 flex align-center justify-between">
                                <div className="flex align-center gap-2">
                                  <span
                                    className="material-icons-round"
                                    style={{ fontSize: 15 }}
                                  >
                                    schedule
                                  </span>
                                  <div
                                    className="text-sm"
                                    style={{ fontSize: 14 }}
                                  >
                                    {new Date(
                                      item.date_time
                                    ).toLocaleTimeString()}
                                  </div>
                                </div>
                                <a
                                  href={document.base64}
                                  download={document.name}
                                >
                                  <span className="material-icons-round text-sm">
                                    file_download
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}

                {item.uploads.audios.length > 0 && (
                  <div className="flex align-center gap-1 w-full flex-row flex-wrap media_cont_chat chat">
                    {item.uploads.audios.map((audio, audIndex) => (
                      <>
                        <div
                          key={audIndex}
                          className="bg-gray-100 item relative w-full hover cursor-pointer px-3 py-2"
                        >
                          <div className="w-full h-full object-cover flex align-center gap-3">
                            <div className="align-center justify-center w-12 h-12 flex">
                              <span
                                class="material-symbols-outlined"
                                style={{ fontSize: 37 }}
                              >
                                play_arrow
                              </span>
                            </div>
                            <div className="flex-1 w-fill border-left pl-5">
                              <div className="text-mute">{audio.name}</div>
                              <div className="text-sm mt-2 flex align-center justify-between">
                                <div className="flex align-center gap-2">
                                  <span
                                    className="material-icons-round"
                                    style={{ fontSize: 15 }}
                                  >
                                    schedule
                                  </span>
                                  <div
                                    className="text-sm"
                                    style={{ fontSize: 14 }}
                                  >
                                    {new Date(
                                      item.date_time
                                    ).toLocaleTimeString()}
                                  </div>
                                </div>
                                <a href={audio.base64} download={audio.name}>
                                  <span className="material-icons-round text-sm">
                                    file_download
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}

                {item.uploads.videos.length > 0 && (
                  <div className="flex align-center gap-1 w-full flex-row flex-wrap media_cont_chat chat">
                    {item.uploads.videos.map((video, vidIndex) => (
                      <div
                        key={vidIndex}
                        className={`bg-gray-100 item ${
                          item.uploads.videos.length > 1 ? "half" : "full"
                        } hover cursor-pointer relative`}
                        onClick={() =>
                          handleMediaClick(
                            allMedia.findIndex(
                              (media) =>
                                media.src ===
                                (video.base64 ||
                                  video.blobUrl ||
                                  video?.upload_path +
                                    "/posts/" +
                                    video?.row?.filename)
                            )
                          )
                        }
                      >
                        <div className="play_video flex align-center justify-center rounded-full absolute top-0 bottom-0 left-0 right-0 m-auto">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            className="play_icon"
                            fill="#e8eaed"
                          >
                            <g>
                              <rect fill="none" height="24" width="24" />
                            </g>
                            <g>
                              <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M9.5,14.67V9.33c0-0.79,0.88-1.27,1.54-0.84 l4.15,2.67c0.61,0.39,0.61,1.29,0,1.68l-4.15,2.67C10.38,15.94,9.5,15.46,9.5,14.67z" />
                            </g>
                          </svg>
                        </div>
                        <img
                          className="w-full h-full"
                          style={{ maxHeight: "70vh" }}
                          src={
                            video.thumbnail ||
                            video?.upload_path +
                              "/posts/" +
                              video?.row?.thumb_filename
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
                <MessagesItem item={item} />
              </div>

              <Menu>
                <MenuButton className="chat_menu w-8 min-w-8 h-8 min-h-8 bg-gray-100 rounded-full options align-center justify-center flex">
                  <span className="material-symbols-outlined">
                    keyboard_arrow_down
                  </span>
                </MenuButton>

                <MenuItems
                  transition
                  anchor="bottom start"
                  className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 dropdwon_container"
                >
                  <MenuItem>
                    <div
                      onClick={() => onReplay()}
                      className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item"
                    >
                      Reply
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item">
                      Forward
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item">
                      Delete chat
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="group cursor-pointer flex w-full items-center gap-2 rounded-lg py-2.5 text-md px-3 data-[focus]:bg-white/10 menu_item">
                      Pin message
                    </div>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>
      ))}

      {selectedMediaIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-between">
          <div
            className="relative max-w-full max-h-full w-full rounded-lg"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 hover rounded-full p-2 shadow-lg z-50 w-10 h-10 bg-white"
            >
              <span className="material-symbols-outlined">close</span>
            </button>

            <div className="flex justify-center items-center h-full">
              {allMedia[selectedMediaIndex].type === "image" ? (
                <img
                  className="max-h-full max-w-full animate__animated animate__zoomIn animate_fast"
                  src={allMedia[selectedMediaIndex].src}
                  alt={`Image ${selectedMediaIndex}`}
                />
              ) : (
                <ReactPlayer
                  url={allMedia[selectedMediaIndex].src}
                  controls
                  width="100%"
                  autoplay={true}
                  height="100%"
                  className="animate__animated animate__zoomIn animate_fast"
                />
              )}
            </div>

            <button
              onClick={showPreviousMedia}
              className="absolute left-2 w-10 h-10 hover flex align-center justify-center top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-lg z-50"
            >
              <span
                className="material-symbols-outlined"
                style={{ left: 3, position: "relative" }}
              >
                arrow_back_ios
              </span>
            </button>
            <button
              onClick={showNextMedia}
              className="absolute right-2 w-10 h-10 hover flex align-center justify-center top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-lg z-50"
            >
              <span className="material-symbols-outlined">
                arrow_forward_ios
              </span>
            </button>

            <div
              className="flex justify-center gap-2 align-center w-full rev_bg"
              style={{ height: 100 }}
            >
              {allMedia.map((media, index) => (
                <div
                  key={index}
                  className={`cursor-pointer rounded-xl overflow-hidden ${
                    index === selectedMediaIndex ? "thumbs active" : "thumbs"
                  }`}
                  onClick={() => selectMediaFromThumbnail(index)}
                >
                  {media.type === "image" ? (
                    <img
                      className="w-16 h-16 object-cover"
                      src={media.src}
                      alt={`Thumbnail ${index}`}
                    />
                  ) : (
                    <div className="w-16 h-16 bg-gray-200 flex items-center justify-center relative">
                      <img
                        className="w-16 h-16 object-cover"
                        src={media.thumbnail}
                      />
                      <span className="material-symbols-outlined text-gray-500 absolute top-0 bottom-0 left-0 right-0 m-auto w-8 h-8 flex align-center justify-center">
                        play_circle
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MessagesList;
