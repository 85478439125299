import React from 'react'
import FollowList from '../components/FollowList'

function BookMarkPeople() {
  return (
    <div>
        <div className='text-xl font-bold mb-6'>Save people</div>
        <FollowList/>
    </div>
  )
}

export default BookMarkPeople