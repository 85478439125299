import React from "react";

function TopicDummy() {
  return (
    <>
      <div className="">
        <div className="flex animate__animated animate__slideInUp animate_fast mt-4 ">
          <div className="h-72 w-full bg-gray-200 loader rounded-xl"></div>
        </div>
        <div className="flex align-start mb-4 animate__animated animate__slideInUp animate_fast gap-1 mt-4">
          <div className="h-12 min-w-12 bg-gray-200 loader rounded-full"></div>
          <div className="w-full px-2">
            <div className="h-8 w-full bg-gray-200 loader rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-200 loader mt-1 rounded-full"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopicDummy;
